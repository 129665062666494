
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;">THIRD-PARTY MOTOR INSURANCE VS. COMPREHENSIVE MOTOR INSURANCE </p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/dec/Motor.jpeg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>People often get confused about the difference between a third-party motor insurance and a comprehensive motor insurance. This article will help you understand the difference and enable you to make a choice that best suits you.
                 </p>
                 <p></p>
                 <p>In Nigeria, it is compulsory that every owner of a car, motorcycle and other kinds of vehicles, whether private or commercial must have at least a third-party insurance. </p>
                 <p></p>


              </div>
           </div>


          <div class="row justify-content-between">


              <div class="col-md-12">


                 <p><strong>Third-Party Motor Insurance</strong>
                 </p>
                 <p></p>

                 <p>A Third-Party motor insurance offers financial coverage to the policyholder against any legal liability arising due to loss or damage caused to a third-party person or property. </p>

                <p></p>

                <p>It covers death or bodily injury to a third party, damage to a third-party vehicle and damage to third-party property. One major limitation of third-party motor insurance is that it does not cover the damages done to the insured's car. As the name implies, it focuses on compensating the third-party for damages caused by the policyholder.</p>

                <p></p>
                <p></p>
                <p>Taking up a third-party motor insurance policy enables you to drive legally and protects you from unexpected accidental losses. It is also important to state that it is not as expensive as comprehensive motor insurance</p>

                <p><strong>Comprehensive Motor Insurance </strong></p>


               <p></p>
               <p>Comprehensive motor insurance offers financial coverage to the policyholder including compensation for third-party damage or loss.</p>

              <p></p>
               <p>It covers theft of the insured car, damage due to fire to the insured car, damage caused by natural and man-made disasters, and third-party damage or loss.</p>
              <p></p>
              <p>Purchasing a comprehensive motor insurance policy enables you to drive legally and it will never allow the insured to end up with losses as it covers for both the insured's property and third-party damages or losses,</p>
              <p></p>
              <p>Click <a href="https://nsiainsurance.com/motorinsurance">Here</a> or call customer service on 09048418896 to get started today!</p>
                            </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>