import { Component, OnInit } from '@angular/core';
import { Beneficiaries, DDInstructions, MedicalDetails, NextOfKin, ProposalForm } from 'src/app/Classes/proposal-form';
import { FormserviceService } from 'src/app/Services/formservice.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-life-proposal-form',
  templateUrl: './life-proposal-form.component.html',
  styleUrls: ['./life-proposal-form.component.css']
})
export class LifeProposalFormComponent implements OnInit {
  dateTemp = new Date();
  step:number = 1;
  diseases: string[] = [];
  otherPolicy: string[] = [];
  ddEnabled:number = 0;
  countries:any;
  states:any;
  banks:any;
  termAgreed:boolean = false;
  showBeneficiaryTable: boolean = false
  ddModel = new DDInstructions("","","",null);
  beneficiaryModel = new Beneficiaries("", "", "", null, this.dateTemp);
  nextOfKinModel = new NextOfKin("","","","");
  productType:number = 0;
  medicalModel =  new MedicalDetails("","","","","",this.dateTemp,null,"","","",null,null,this.dateTemp, [],null,"",null,"");
 proposalModel = new  ProposalForm(null,null,"","",null,"",null,"","","","","","","",this.dateTemp,null,"",
  "","","","","","","",null,this.medicalModel,"","",null,null,[],"",[],this.beneficiaryModel,this.nextOfKinModel,"","",this.ddModel);
  constructor(private _formservice: FormserviceService) { }

  ngOnInit(): void {
    this._formservice.GetCountries()
      .subscribe(
        (data) => {
          this.countries = data
        },
        (error) => {
          console.log(error);
        }
      );
      this._formservice.GetStates()
        .subscribe(
          (data) => {
            this.states = data
          },
          (error) => {
            console.log(error);
          }
        );
        this._formservice.GetBanks()
        .subscribe(
          (data) => {
            this.banks = data
          },
          (error) => {
            console.log(error);
          }
        );
  }

  assignProductType(){
    this.productType = this.proposalModel.ProductType;
    if (this.productType > 0) {
      console.log(this.productType);
    this.step = 2;
    }

  }
  Previous(){
    if (this.step == 2) {
      this.proposalModel.ProductType = null;
      this.productType = null;
    }
    this.step--;

    window.scrollTo(0, 0);
  }
  Next(){
    if (this.step == 2) {

      if (this.proposalModel.PremiumType <= 0) {
        Swal.fire("error", "Premium Type is Required", "info");
        return;
      }
      if (this.proposalModel.Surname == "") {
        Swal.fire("error", "Surname/Scheme name is required", "info");
        return;
      }
      if (this.proposalModel.ResidentialAddress == "") {
        Swal.fire("error", "Residential/office address is required", "info");
        return;
      }

      if (this.proposalModel.EmailAddress == "") {
        Swal.fire("error", "Email address is required", "info");
        return;
      }
      if (this.proposalModel.Telephone == "") {
        Swal.fire("error", "Telephone is required", "info");
        return;
      }
      if (this.proposalModel.PaymentFrequency <= 0) {
        Swal.fire("error", "Frequency of payment /Annuity is required", "info");
        return;
      }
      if (this.productType == 1) {
        if (this.proposalModel.OtherNames == "") {
          Swal.fire("error", "Other names is required", "info");
          return;
        }
        if (this.proposalModel.Sex  <= 0) {
          Swal.fire("error", "Gender is required", "info");
          return;
        }
        if (this.proposalModel.MaritalStatus  <= 0) {
          Swal.fire("error", "Marital is required", "info");
          return;
        }


        if (!this.proposalModel.DateOfBirth) {
          Swal.fire("error", "Date of birth is required", "info");
          return;
        }
        if (this.proposalModel.Age  <= 0) {
          Swal.fire("error", "Age is required", "info");
          return;
        }
        if (this.proposalModel.StateOfOrigin == "") {
          Swal.fire("error", "State of origin is required", "info");
          return;
        }
        if (this.proposalModel.Nationality == "") {
          Swal.fire("error", "Nationality is required", "info");
          return;
        }
        if (this.proposalModel.Occupation == "") {
          Swal.fire("error", "Occupation is required", "info");
          return;
        }
        if (this.proposalModel.EmployerName == "") {
          Swal.fire("error", "Employer name is required", "info");
          return;
        }
        // if (this.proposalModel.Position == "") {
        //   Swal.fire("error", "Position is required", "info");
        //   return;
        // }

        // if (this.proposalModel.EmployersAddress == "") {
        //   Swal.fire("error", "Employers Address is required", "info");
        //   return;
        // }

      } else {
        if (this.proposalModel.RICNumber == "") {
          Swal.fire("error", "RIC Number is required", "info");
          return;
        }
      }




    }
    if (this.step == 3) {
      // this.proposalModel.MedicalData.RecurringDesease.filter(opt => opt['checked']).map(opt => opt);
      // console.log(this.proposalModel);
      // if (this.medicalModel.Name == "") {
      //   Swal.fire("Error", "Hospital name is required", "info");
      //   return;
      // }
      // if (this.medicalModel.Address == "") {
      //   Swal.fire("Error", "Hospital address is required", "info");
      //   return;
      // }
      // if (this.medicalModel.PhoneNumber == "") {
      //   Swal.fire("Error", "Hospital Phone number is required", "info");
      //   return;
      // }
      // if (this.medicalModel.EmailAddress == "") {
      //   Swal.fire("Error", "Hostpital Email Address is required", "info");
      //   return;
      // }
      // if (!this.proposalModel.ComencementDate) {
      //   Swal.fire("Error", "Commencement date is required", "info");
      //   return;
      // }
      // if (this.proposalModel.Duration == "") {
      //   Swal.fire("Error", "Duration  is required", "info");
      //   return;
      // }
      // if (this.proposalModel.SumAssurred <= 0) {
      //   Swal.fire("Error", "Sum assurred  is required", "info");
      //   return;
      // }
      // if (this.productType == 1) {
      //   if (this.medicalModel.AttendanceLifeSpan == "") {
      //     Swal.fire("Error", "Attendance life span is required", "info");
      //     return;
      //   }
      //   if (!this.medicalModel.DateLastAttended) {
      //     Swal.fire("Error", "Date last attended is required", "info");
      //     return;
      //   }
      //   if (this.medicalModel.AttendanceReason <= 0) {
      //     Swal.fire("Error", "Attendance reason is required", "info");
      //     return;
      //   }
        // if (this.medicalModel.Height == "") {
        //   Swal.fire("Error", "Height  is required", "info");
        //   return;
        // }
        // if (this.medicalModel.Weight == "") {
        //   Swal.fire("Error", "Weight  is required", "info");
        //   return;
        // }
        // if (this.medicalModel.BloodPressure == "") {
        //   Swal.fire("Error", "Blood pressure  is required", "info");
        //   return;
        // }
        // if (this.medicalModel.AlchoholConsumption <= 0) {
        //   Swal.fire("Error", "Alcohol consumption is required", "info");
        //   return;
        // }
      //   if (this.medicalModel.IsPregnant <= 0) {
      //     Swal.fire("Error", "Pregnancy indication is required", "info");
      //     return;
      //   }
      //   if (this.medicalModel.IsPregnant == 1 && !this.medicalModel.Edd) {
      //     Swal.fire("Error", "EDD is required", "info");
      //     return;
      //   }
      //   if (this.medicalModel.TerminalDesease <= 0) {
      //     Swal.fire("Error", "Terminal desease option is required", "info");
      //     return;
      //   }
      //   if (this.medicalModel.TerminalDesease == 1 && this.medicalModel.DiseaseDetails == "") {
      //     Swal.fire("Error", "Terminal desease details  is required", "info");
      //     return;
      //   }
      //   if (this.medicalModel.ISGoodHealth <= 0) {
      //     Swal.fire("Error", "Good Health option is required", "info");
      //     return;
      //   }
      //   if (this.medicalModel.ISGoodHealth == 2 && this.medicalModel.HealthReason == "") {
      //     Swal.fire("Error", "Health reason is required", "info");
      //     return;
      //   }
      // } else {
      //   if (this.proposalModel.NumberOfMembers <= 0) {
      //     Swal.fire("Error", "Number of Members", "info");
      //     return;
      //   }
      // }
    }
    if (this.step == 4) {
       if (this.proposalModel.BeneficiariesData.length == 0) {
          Swal.fire("Error", "You need to add atleast one beneficiary", "info");
          return;
       }

    }
    if (this.step == 5) {
      if (this.productType == 1) {
        if (this.nextOfKinModel.FullName == "") {
          Swal.fire("Error", "Next of kin name is required", "info");
          return;
        }
        if (this.nextOfKinModel.Address == "") {
          Swal.fire("Error", "Next of kin address is required", "info");
          return;
        }
        if (this.nextOfKinModel.Relationship == "") {
          Swal.fire("Error", "Next of kin relationship is required", "info");
          return;
        }
        if (this.nextOfKinModel.PhoneNumber == "") {
          Swal.fire("Error", "Next of kin phone number is required", "info");
          return;
        }
        if (this.proposalModel.PolicyHolder == "") {
          Swal.fire("Error", "Policy holder is required", "info");
          return;
        }
        if (this.nextOfKinModel.Relationship == "") {
          Swal.fire("Error", "Policy holder relationship is required", "info");
          return;
        }
      }

    }
    if (this.step == 6) {
      if (this.ddEnabled <= 0) {
        Swal.fire("Error", "Direct debit option is required", "info");
        return;
      }
      if (this.ddEnabled == 1) {
        if (this.ddModel.AccountName == "") {
          Swal.fire("Error", "Direct debit account name is required", "info");
          return;
        }
        if (this.ddModel.AccountNumber == "") {
          Swal.fire("Error", "Direct debit account number is required", "info");
          return;
        }
        if (this.ddModel.Bank == "") {
          Swal.fire("Error", "Direct debit bank name is required", "info");
          return;
        }
        if (this.ddModel.AccountType <= 0) {
          Swal.fire("Error", "Direct debit account type is required", "info");
          return;
        }
      }
    }
    if (this.step == 3) {
      if (this.productType == 2) {
        this.step = 6;
      }
    } else {
      this.step++;
    }


    window.scrollTo(0, 0);
  }
  addPolicy(e){
    let value = e.srcElement.value;
    // let type = true;
    if (e.srcElement.checked) {
      this.otherPolicy.push(value);
    }else{
      const index: number = this.otherPolicy.indexOf(value);
      if (index !== -1) {
        this.otherPolicy.splice(index, 1);
      }
    }
  }
  addDisease(e){
    let value = e.srcElement.value;
    // let type = true;
    if (e.srcElement.checked) {
      this.diseases.push(value);
    }else{
      const index: number = this.diseases.indexOf(value);
      if (index !== -1) {
        this.diseases.splice(index, 1);
      }
    }

  //  console.log(e);


  }
  submitForm(){
    if (!this.termAgreed) {
      Swal.fire("Error", "You need to agree to the terms and agreement", "info");
      return;
    }
    this._formservice.startLoading();
    this._formservice.sendProposalForm(this.proposalModel)
      .subscribe(
        (data) => {
          if (data.statusCode == "00") {
            Swal.fire("Success", "Your form is submitted", "success");
          } else {
            Swal.fire("error", data.statusMessage, "info");
          }
          this._formservice.stopLoading();
        },
        (error) => {
          console.log(error);
          Swal.fire("fatal", "internal server error occurred", "error");
          this._formservice.stopLoading();
        }
      )

  }
  testThis(){
    console.log(this.termAgreed);

  }
  Add(){
    if (this.beneficiaryModel.BenefitProportion == "") {
      Swal.fire("Error", "Beneficiary proportion is required", "info");
      return;
    }
    if (this.beneficiaryModel.Relationship == "") {
      Swal.fire("Error", "Beneficiary relationship is required", "info");
      return;
    }
    if (this.beneficiaryModel.FullName == "") {
      Swal.fire("Error", "Beneficiary full name is required", "info");
      return;
    }
    if (this.beneficiaryModel.Sex <= 0) {
      Swal.fire("Error", "Beneficiary gender is required", "info");
      return;
    }
    if (!this.beneficiaryModel.DateOfBirth) {
      Swal.fire("Error", "Beneficiary Date of birth is required", "info");
      return;
    }
    this.proposalModel.BeneficiariesData.push(this.beneficiaryModel);
    this.beneficiaryModel = new Beneficiaries("", "", "", null, this.dateTemp);
    if (this.proposalModel.BeneficiariesData.length > 0) {
      this.showBeneficiaryTable = true;
    }
  }
  editBen(i){
    this.beneficiaryModel.BenefitProportion = this.proposalModel.BeneficiariesData[i].BenefitProportion
    this.beneficiaryModel.DateOfBirth = this.proposalModel.BeneficiariesData[i].DateOfBirth
    this.beneficiaryModel.FullName = this.proposalModel.BeneficiariesData[i].FullName
    this.beneficiaryModel.Relationship = this.proposalModel.BeneficiariesData[i].Relationship
    this.beneficiaryModel.Sex = this.proposalModel.BeneficiariesData[i].Sex
    this.proposalModel.BeneficiariesData.splice(i, 1);
    if (this.proposalModel.BeneficiariesData.length > 0) {
      this.showBeneficiaryTable = true;
    }else{
      this.showBeneficiaryTable = false;
    }
    // console.log(i);


  }
  deleteBen(i){
    this.proposalModel.BeneficiariesData.splice(i, 1);
    if (this.proposalModel.BeneficiariesData.length > 0) {
      this.showBeneficiaryTable = true;
    }else{
      this.showBeneficiaryTable = false;
    }
  }

}
