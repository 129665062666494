export class CustomerServiceWeekForm {
  constructor(
    public Question1: string,
    public Question2:string,
    public Question3:string,
    public Question4:string,
    public Question5:string,
    public fullname:string,
    public EmailAddress:string,
    public PhoneNumber:string,
    public Policy:string,
    public staffcode:string,

  ) {

  }
}
