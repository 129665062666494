<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
     <ul class="d-flex justify-content-between u-list-inline">
        <li class="list-inline-item g-mr-15">
           <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
           <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
        </li>
        <li class="list-inline-item g-color-primary">
           <span>Blog</span>
        </li>
        <li class="list-inline-item ml-auto">

        </li>
     </ul>
  </div>
</section>
<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
<!-- Heading -->
<div class="g-max-width-550 text-center mx-auto g-mb-70">
  <h1 class="h3 mb-5">
     <p>Blog</p>
  </h1>
</div>
<!-- End Heading -->



<div class="row">
  <div class="col-lg-12 g-mb-30">
     <div class="row g-mx-minus-25 g-mb-50">

      <div class="col-lg-6 g-px-25 g-mb-50">
         <!-- Blog Grid Modern Blocks -->
         <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

            <div class="col-sm-6 g-bg-white g-rounded-left-5">
               <div class="g-pa-35">
                  <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                     <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/effective-social-media-strategies-for-brands">
                        <p>EFFECTIVE SOCIAL MEDIA STRATEGIES FOR BRANDS<br/><span style="font-size:12px;">22/01/2021</span></p>
                     </a>
                  </h2>
                  <p class="">
                     Social media provides an essential bridge between businesses and their audience. It is the platform that businesses and consumer marketers believe is most crucial to building brand trust. By creating a strong brand presence on social media, you can reach a broader audience and improve your sales.
                   </p>

                   <br/>
                  <ul class="list-inline g-font-size-12 mb-0">
                     <li class="list-inline-item g-mb-10">

                        <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                           routerLink="/effective-social-media-strategies-for-brands">Read more</a>
                     </li>
                  </ul>
               </div>
            </div>
            <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
               data-bg-img-src="assets/img/SOCIAL_MEDIA_STRATEGIES.jpeg"></div>
         </article>
         
         <!-- End Blog Grid Modern Blocks -->
      </div>


      <div class="col-lg-6 g-px-25 g-mb-50">
         <!-- Blog Grid Modern Blocks -->
         <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

            <div class="col-sm-6 g-bg-white g-rounded-left-5">
               <div class="g-pa-35">
                  <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                     <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/insurance-in-2021">
                        <p>INSURANCE IN 2021 – WHAT TO EXPECT<br/><span style="font-size:12px;">20/01/2021</span></p>
                     </a>
                  </h2>
                  <p class="">
                     A crisis like Covid-19 affects all business sectors; while some businesses and industries were impacted by COVID-19 more than others, all had to adapt one way or another. Lockdown regulations...
                   </p>

                   <br/>
                  <ul class="list-inline g-font-size-12 mb-0">
                     <li class="list-inline-item g-mb-10">

                        <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                           routerLink="/insurance-in-2021">Read more</a>
                     </li>
                  </ul>
               </div>
            </div>
            <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
               data-bg-img-src="assets/img/INSURANCE_IN_2021.jpg"></div>
         </article>
         
         <!-- End Blog Grid Modern Blocks -->
      </div>



      <div class="col-lg-6 g-px-25 g-mb-50">
         <!-- Blog Grid Modern Blocks -->
         <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

            <div class="col-sm-6 g-bg-white g-rounded-left-5">
               <div class="g-pa-35">
                  <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                     <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/do-it-today-not-tomorrow">
                        <p>DO IT TODAY NOT TOMORROW!<br/><span style="font-size:12px;">18/01/2021</span></p>
                     </a>
                  </h2>
                  <p class="">
                     Procrastination is often confused with laziness, but they are very different. It is an active process – you choose to do something else instead of the task that you know you should be doing.
                   </p>

                   <br/>
                  <ul class="list-inline g-font-size-12 mb-0">
                     <li class="list-inline-item g-mb-10">

                        <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                           routerLink="/do-it-today-not-tomorrow">Read more</a>
                     </li>
                  </ul>
               </div>
            </div>
            <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
               data-bg-img-src="assets/img/DO_IT_TODAY.jpeg"></div>
         </article>
         
         <!-- End Blog Grid Modern Blocks -->
      </div>


      <div class="col-lg-6 g-px-25 g-mb-50">
         <!-- Blog Grid Modern Blocks -->
         <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

            <div class="col-sm-6 g-bg-white g-rounded-left-5">
               <div class="g-pa-35">
                  <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                     <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/three-money-rules">
                        <p>THREE MONEY RULES THAT WILL INCREASE YOUR NET WORTH<br/><span style="font-size:12px;">15/01/2021</span></p>
                     </a>
                  </h2>
                  <p class="">
                     What’s your personal finance strategy? If you find that you cannot answer this question confidently, this article is for you. Enjoy!
                   </p>


                   <br/>
                  <ul class="list-inline g-font-size-12 mb-0">
                     <li class="list-inline-item g-mb-10">

                        <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                           routerLink="/three-money-rules">Read more</a>
                     </li>
                  </ul>
               </div>
            </div>
            <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
               data-bg-img-src="assets/img/three_money_rules.jpeg"></div>
         </article>
         
         <!-- End Blog Grid Modern Blocks -->
      </div>

      <div class="col-lg-6 g-px-25 g-mb-50">
         <!-- Blog Grid Modern Blocks -->
         <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

            <div class="col-sm-6 g-bg-white g-rounded-left-5">
               <div class="g-pa-35">
                  <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                     <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/what-to-do-if-you-get-in-a-car-accident">
                        <p>THINGS YOU SHOULD DO IN THE NEW YEAR FOR YOUR INSURANCE<br/><span style="font-size:12px;">13/01/2021</span></p>
                     </a>
                  </h2>
                  <p class="">
                     The start of a New Year always energizes us to start off on the right note. Whether...
                   </p>


                   <br/>
                  <ul class="list-inline g-font-size-12 mb-0">
                     <li class="list-inline-item g-mb-10">

                        <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                           routerLink="/new-year-for-your-insurance">Read more</a>
                     </li>
                  </ul>
               </div>
            </div>
            <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
               data-bg-img-src="assets/img/things-you-should-do.jpeg"></div>
         </article>

         <!-- End Blog Grid Modern Blocks -->
      </div>

      <div class="col-lg-6 g-px-25 g-mb-50">
         <!-- Blog Grid Modern Blocks -->
         <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

            <div class="col-sm-6 g-bg-white g-rounded-left-5">
               <div class="g-pa-35">
                  <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                     <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/what-to-do-if-you-get-in-a-car-accident">
                        <p>INSURANCE TIPS FOR A SAFE  SMART NEW YEAR<br/><span style="font-size:12px;">11/01/2021</span></p>
                     </a>
                  </h2>
                  <p class="">
                     1. Now is a good time to compare the car’s value to what you pay for auto insurance and determine if your policy still fits your needs.
                   </p>


                   <br/>
                  <ul class="list-inline g-font-size-12 mb-0">
                     <li class="list-inline-item g-mb-10">

                        <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                           routerLink="/insurance-tips-for-save-smart">Read more</a>
                     </li>
                  </ul>
               </div>
            </div>
            <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
               data-bg-img-src="assets/img/insurance-tips.jpeg"></div>
         </article>

         <!-- End Blog Grid Modern Blocks -->
      </div>

      <div class="col-lg-6 g-px-25 g-mb-50">
         <!-- Blog Grid Modern Blocks -->
         <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

            <div class="col-sm-6 g-bg-white g-rounded-left-5">
               <div class="g-pa-35">
                  <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                     <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/what-to-do-if-you-get-in-a-car-accident">
                        <p>AUTO POLICY NONRENEWAL VS CANCELLATION<br/><span style="font-size:12px;">08/01/2021</span></p>
                     </a>
                  </h2>
                  <p class="">
                     At the end of your car insurance contract, your insurance provider may make changes to your insurance policy. It has the option to renew the policy as it stands,
                   </p>

                   <br/>
                  <ul class="list-inline g-font-size-12 mb-0">
                     <li class="list-inline-item g-mb-10">

                        <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                           routerLink="/auto-policy-nonrenewal-vs-cancellation">Read more</a>
                     </li>
                  </ul>
               </div>
            </div>
            <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
               data-bg-img-src="assets/img/auto-policy-renewal.jpeg"></div>
         </article>

         <!-- End Blog Grid Modern Blocks -->
      </div>
      <div class="col-lg-6 g-px-25 g-mb-50">
         <!-- Blog Grid Modern Blocks -->
         <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

            <div class="col-sm-6 g-bg-white g-rounded-left-5">
               <div class="g-pa-35">
                  <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                     <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/what-to-do-if-you-get-in-a-car-accident">
                        <p>WHY IS RENEWING YOUR INSURANCE POLICY IMPORTANT<br/><span style="font-size:12px;">06/01/2021</span></p>
                     </a>
                  </h2>
                  <p class="">
                     Failure to renew your insurance policy after maturity has a negative impact on your ability to make claims in the event of an unfortunate occurrence.
                   </p>

                   <br/>
                  <ul class="list-inline g-font-size-12 mb-0">
                     <li class="list-inline-item g-mb-10">

                        <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                           routerLink="/why-renewing-your-insurance-policy-important">Read more</a>
                     </li>
                  </ul>
               </div>
            </div>
            <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
               data-bg-img-src="assets/img/renewal-insurance-important.jpeg"></div>
         </article>
         <!-- assets/img/auto-policy-renewal.jpeg -->
         <!-- End Blog Grid Modern Blocks -->
      </div>

      <div class="col-lg-6 g-px-25 g-mb-50">
         <!-- Blog Grid Modern Blocks -->
         <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

            <div class="col-sm-6 g-bg-white g-rounded-left-5">
               <div class="g-pa-35">
                  <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                     <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/what-to-do-if-you-get-in-a-car-accident">
                        <p>RENEWAL IN THE NEW YEAR<br/><span style="font-size:12px;">04/01/2021</span></p>
                     </a>
                  </h2>
                  <p class="">
                     The start of a new year is a great time to reevaluate and renew your insurance policies, as changing circumstances may lead to different insurance needs.
                   </p>

                   <br/>
                  <ul class="list-inline g-font-size-12 mb-0">
                     <li class="list-inline-item g-mb-10">

                        <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                           routerLink="/renewal-in-the-new-year">Read more</a>
                     </li>
                  </ul>
               </div>
            </div>
            <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
               data-bg-img-src="assets/img/renewal.jpeg"></div>
         </article>
         <!-- End Blog Grid Modern Blocks -->
      </div>
      <div class="col-lg-6 g-px-25 g-mb-50">
         <!-- Blog Grid Modern Blocks -->
         <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

            <div class="col-sm-6 g-bg-white g-rounded-left-5">
               <div class="g-pa-35">
                  <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                     <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/what-to-do-if-you-get-in-a-car-accident">
                        <p>HOW TO MANAGE NEW YEAR ANXIETY<br/><span style="font-size:12px;">01/01/2021</span></p>
                     </a>
                  </h2>
                  <p class="">
                     The start of a New Year can sometimes make us anxious because it’s the start of a whole year and even though technically, it is not any different from any other day,
                   </p>

                   <br/>
                  <ul class="list-inline g-font-size-12 mb-0">
                     <li class="list-inline-item g-mb-10">

                        <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                           routerLink="/anxiety">Read more</a>
                     </li>
                  </ul>
               </div>
            </div>
            <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
               data-bg-img-src="assets/img/anxiety.jpeg"></div>
         </article>
         <!-- End Blog Grid Modern Blocks -->
      </div>

        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/what-to-do-if-you-get-in-a-car-accident">
                          <p>WHAT TO DO IF YOU GET IN A CAR ACCIDENT<br/><span style="font-size:12px;">30/12/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       We take safety precautions on the road, like using seatbelts, keeping a safe distance from other vehicles and maintaining our vehicles regularly, but sometimes the unexpected happens.
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/what-to-do-if-you-get-in-a-car-accident">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/dec/Accident.jpeg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>



        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/simple-ways-to-be-more-productive">
                          <p>SIMPLE WAYS TO BE MORE PRODUCTIVE EVERYDAY<br/><span style="font-size:12px;">28/12/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       Step 1: Manage your energy, not your time.
                       If you take a moment to think about it, you will probably realize that you are better at doing certain tasks at certain times.
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/simple-ways-to-be-more-productive">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/dec/Productivity.jpeg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>

        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/holiday-fire-saftey-tips">
                          <p>HOLIDAY FIRE SAFETY TIPS<br/><span style="font-size:12px;">25/12/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       We know you’re ready for the festivities with your holiday spirit on full blast but before you begin celebrations, take a few minutes to help ensure a safe season.
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/holiday-fire-saftey-tips">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/dec/Fire.jpeg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>



        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/tips-to-prevent-home-burglary">
                          <p>TIPS TO PREVENT HOME BURGLARY<br/><span style="font-size:12px;">23/12/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       Burglary is the worst nightmare of many homeowners. The highest percentage of break-ins happen while people are away on vacation, or begin to leave their windows and doors open more often.
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/tips-to-prevent-home-burglary">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/dec/Burglary.jpeg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>

        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/travel-safety-tips">
                          <p>TRAVEL SAFETY TIPS<br/><span style="font-size:12px;">21/12/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       Most popular destinations offer safe environments for tourists, but it is always smart to take precautions, no matter where you’re going.
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/travel-safety-tips">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/dec/Travel.jpeg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>



        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/third-party-motor-vs-comprehensive-motor">
                          <p>THIRD-PARTY MOTOR INSURANCE VS. COMPREHENSIVE MOTOR INSURANCE<br/><span style="font-size:12px;">18/12/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       People often get confused about the difference between a third-party motor insurance and a comprehensive motor insurance.
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/third-party-motor-vs-comprehensive-motor">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/dec/Motor.jpeg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>



        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/nsia-insurance-go-paperless">
                          <p>NSIA INSURANCE GOES PAPERLESS!<br/><span style="font-size:12px;">16/12/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       At NSIA Insurance, we remain committed to improving your experience with us and providing you with quality service. It is for this reason that we are consistently.
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/nsia-insurance-go-paperless">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/dec/Paperless.jpeg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>



        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/ways-to-retain-more-of-every-book">
                          <p>4 WAYS TO RETAIN MORE OF EVERY BOOK YOU READ<br/><span style="font-size:12px;">14/12/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       A good book can give you a new way to interpret your past experiences. Gaining knowledge is not the only reason to read. Reading for pleasure or entertainment can be a wonderful use of time...
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/ways-to-retain-more-of-every-book">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/dec/Book.jpeg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>




        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/cybersecurity-best-practices">
                          <p>CYBERSERCURITY BEST PRACTICES<br/><span style="font-size:12px;">2/12/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       Due to Covid-19, the increase of employees working from home has gotten up to 70% as employers have discovered that when employees work remotely, they remain productive and less time is spent commuting.
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/cybersecurity-best-practices">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/Cybersecurity.jpeg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>



        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/brain-exercises-that-boost-memory">
                          <p>BRAIN EXERCISES THAT BOOST MEMORY<br/><span style="font-size:12px;">30/11/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       While you might know that you need to exercise your body, did you know that it might also be important to exercise your mind? You've probably heard the old adage "use it or lose it."
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/brain-exercises-that-boost-memory">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/Brain_Exercises.jpeg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>


        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/nsia-education-protection-plan">
                          <p>NSIA Education Protection Plan<br/><span style="font-size:12px;">27/11/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       One major benefit of life insurance is to ensure the future well-being of our loved ones. Children are the most vulnerable when unfortunate events occur since...
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/nsia-education-protection-plan">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/EPP.jpeg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>

        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/nsia-deferred-annuity-plan">
                          <p>NSIA Deferred Annuity Plan<br/><span style="font-size:12px;">25/11/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       Sometimes we worry about our future and one major concern is life after retirement. If you’re looking for regular and guaranteed income in your sunset years...
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/nsia-deferred-annuity-plan">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/DAP.jpeg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>

        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/nsia-family-protection-plan">
                          <p>NSIA FAMILY PROTECTION PLAN<br/><span style="font-size:12px;">23/11/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       Funeral expenses aren’t something that most people like to think about, but having a suitable policy in place can give you peace of mind that your family will have the money...
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/nsia-family-protection-plan">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/FPP.jpeg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>

        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/quick-guide-to-homeowners">
                          <p>A QUICK GUIDE TO HOMEOWNERS’ INSURANCE FOR FIRST-TIME HOME BUYERS<br/><span style="font-size:12px;">20/11/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       In this article, we’re going to tackle some Frequently Asked Questions (FAQs) about homeowners’ insurance for first-time home buyers. Enjoy!
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/quick-guide-to-homeowners">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/homeowners.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>



        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/career-advancement-tips">
                          <p>CAREER ADVANCEMENT TIPS<br/><span style="font-size:12px;">16/11/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       Career advancement refers to the upward progression of one's career. An individual can advance by moving from...
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/career-advancement-tips">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/career_advancement.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>



        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/always-read-your-policy-documents">
                          <p>ALWAYS READ YOUR POLICY DOCUMENTS ALL THE WAY THROUGH! HERE’S WHY<br/><span style="font-size:12px;">11/11/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       As far as reading materials go, insurance policies will probably never make it onto the top of the reading list for most people.
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/always-read-your-policy-documents">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/always.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>


        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/what-happens-if-a-guest-causes-damage-to-my-home">
                          <p>WHAT HAPPENS IF A GUEST CAUSES DAMAGE TO MY HOME<br/><span style="font-size:12px;">02/11/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       A lot of people wonder if homeowners’ insurance is really worth it. Homeowners’ insurance can be expensive...
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/what-happens-if-a-guest-causes-damage-to-my-home">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/interior.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>


        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/seven-interesting-facts-about-insurance">
                          <p>7 INTERESTING FACTS ABOUT INSURANCE<br/><span style="font-size:12px;">30/10/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       Usually when you bring up the word ‘insurance’ to anyone but a broker or agent, you might get a yawn or two. Sure, it’s not the most exciting subject on the planet...
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/seven-interesting-facts-about-insurance">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/friday_october_30.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>


        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/working-remotely">
                          <p>WORKING REMOTELY? HERE ARE 4 WAYS TO BOOST PRODUCTIVITY <br/><span style="font-size:12px;">28/10/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       It is a known saying that you should not bring your work home with you — but it is a little different when your work is at home.
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/working-remotely">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/wednesday_october_28.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>

        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/tips-on-improving-team-dynamics">
                          <p>TIPS ON IMPROVING TEAM DYNAMICS  <br/><span style="font-size:12px;">26/10/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       A positive team dynamic tends to have team members who trust each other. They usually work collectively when making decisions and they are held accountable for the outcomes.
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/tips-on-improving-team-dynamics">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/monday_october_26.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>


        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/how-to-prevent-distractions">
                          <p>HOW TO PREVENT DISTRACTIONS WHILE DRIVING <br/><span style="font-size:12px;">23/10/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       The number one reason for collisions is distracted driving. The advancements in technology over the years has made it increasingly harder to stay off the phone.
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/how-to-prevent-distractions">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/image_october_23.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>


        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/ten-tips-for-working-with-your-insurance-company">
                          <p>10	TIPS FOR WORKING WITH YOUR INSURANCE COMPANY <br/><span style="font-size:12px;">21/10/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       1.	Make sure you have an up-to-date copy of your plan's basic information (sometimes called a summary plan description). This will tell you how your plan works, its benefits, and how to file a claim.
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/ten-tips-for-working-with-your-insurance-company">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/image_October_21.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>

        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/breast-cancer-health-insurance">
                          <p>BREAST CANCER & HEALTH INSURANCE <br/><span style="font-size:12px;">19/10/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       It is true that the incident rate of cancer has decreased over the past two decades, and early diagnosis have helped reduce the death toll associated with breast cancer.
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/breast-cancer-health-insurance">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/oct19.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>



        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/tips-to-improve-quality-of-life">
                          <p>TIPS TO IMPROVE QUALITY OF LIFE <br/><span style="font-size:12px;">16/10/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       Just as everyone has their own definition of success, we all have slightly different ideas of what makes for a high-quality life. Nevertheless, there are some steps everyone can take to make life more pleasurable.
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/tips-to-improve-quality-of-life">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/qualityoflife.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>


        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/understanding-the-excess-on-your-vehicle-insurance-policy">
                          <p>UNDERSTANDING THE EXCESS ON YOUR VEHICLE INSURANCE POLICY<br/><span style="font-size:12px;">14/10/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       I was driving on the highway, in terrible traffic when a motorbike from nowhere rode carelessly past me and broke my side mirror in the process.
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/understanding-the-excess-on-your-vehicle-insurance-policy">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/INSURANCE_POLICY.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>

        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/reasons_your_business_needs_keyman_assurance">
                          <p>3 REASONS YOUR BUSINESS NEEDS KEYMAN ASSURANCE<br/><span style="font-size:12px;">12/10/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       Most of us are familiar with how the life insurance policy of a family's breadwinner helps out in case of an unexpected occurrence.However, the fact that life insurance...
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/reasons_your_business_needs_keyman_assurance">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/keymanassurance.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>

        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/things_to_consider_before_taking_that_trip">
                          <p>THINGS TO CONSIDER BEFORE TAKING THAT TRIP<br/><span style="font-size:12px;">09/10/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       Since the outbreak of the novel coronavirus, the Nigeria Center for Disease Control (NCDC) has provided necessary guidelines on how best to stay safe during the...
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/things_to_consider_before_taking_that_trip">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/trips.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>



        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/basic_insurance_terminologies">
                          <p>BASIC INSURANCE TERMINOLOGIES AND THEIR MEANING<br/><span style="font-size:12px;">07/10/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       Recently, we ran a poll on our social media platforms with a view to understanding why people are sometimes skeptical or flat-out uninterested in purchasing insurance policies.
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/basic_insurance_terminologies">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/BASIC_INSURANCE_TERMINOLGIES.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>



        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/benefits-of-a-term-assurance">
                          <p>BENEFITS OF A TERM ASSURANCE PLAN<br/><span style="font-size:12px;">05/10/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       People across society are slowly realizing the necessity of having a life insurance cover. As life increases its pace and uncertainties rise by the day, every family needs a life cover...
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/benefits-of-a-term-assurance">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/term_assurance.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>



        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/satisfying-the-21-century-customer">
                          <p>SATISFYING THE 21ST CENTURY CUSTOMER<br/><span style="font-size:12px;">02/10/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       Customer service and the customer experience is becoming an important competitive differentiator in the insurance industry. To establish a successful relationship between insurers and customers...
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/satisfying-the-21-century-customer">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/satisfying_customer.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>


        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/house-holder-insurance-information">
                          <p>THINGS TO KNOW BEFORE BUYING A HOMEOWNER<br/> /HOUSEHOLDER INSURANCE<br/><span style="font-size:12px;">30/09/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       Generally, this kind of insurance is not the first choice on most people's list, nevertheless it acts as an important backup in case things go wrong.
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/house-holder-insurance-information">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/things_to_know_before_buying_homeowner_householder_insurance.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>




        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/General-Insurance">
                          <p>GENERAL INSURANCE<br/><span style="font-size:12px;">28/09/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       General insurance, also known as non-life insurance, covers financial loss suffered due to the loss or damage of an asset. This category of insurance covers all forms of insurance except life.
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/General-Insurance">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/General_insurance.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>

        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/you-don-hit-my-car">
                          <p>“YOU DON HIT MY CAR!” What now?<br/><span style="font-size:12px;">25/09/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       “I recall my experience on my way to the office earlier this year, where the Lagos State Traffic Management Authority (LASTMA) officials were trying to arrest a Danfo...
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/you-don-hit-my-car">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/you_don_hit.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>



        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/importance-of-data-privacy">
                          <p>THE IMPORTANCE OF DATA PRIVACY IN YOUR BUSINESS<br/><span style="font-size:12px;">23/09/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       Data privacy relates to the rights and obligations of individuals and organizations with respect to the collection, use, retention, disclosure and disposal of personal information.
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/importance-of-data-privacy">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/privacy.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>


        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/insurance-policy-help-you-save-money">
                          <p>DOES HAVING AN INSURANCE POLICY HELP YOU SAVE MONEY?<br/><span style="font-size:12px;">21/09/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       Insurance is an important tool that helps protect us from a number of risks, or more tangible assets like our homes, cars, and possessions.
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/insurance-policy-help-you-save-money">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/money_nsiai.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>

        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/How-to-maintain-your-car">
                          <p>TIPS ON HOW TO MAINTAIN YOUR CAR<br/><span style="font-size:12px;">18/09/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       "Another flaw in human character is that everybody wants to build and own good things but nobody wants to do maintenance." - Kurt Vonnegut
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/How-to-maintain-your-car">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/car_maintenace.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>


        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/understanding-third-party-insurance">
                          <p>UNDERSTANDING THIRD PARTY LIABILITY INSURANCE<br/><span style="font-size:12px;">16/09/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       Recently, on my way back from the office, I endured very slow traffic for about 30 minutes. I later realized that we were being held up by two motorists parked in the middle of the road, arguing about scratches and dents to their cars.
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/understanding-third-party-insurance">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/third_party_insurance.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>

        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/why-is-it-important-to-insure-your-business">
                          <p>WHY IS IT IMPORTANT TO INSURE YOUR BUSINESS?<br/><span style="font-size:12px;">14/09/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       A lot of business owners start off their businesses thinking that they do not need an insurance policy. This conception is not only wrong, it is also very risky. Business insurance is purchased by businesses to cover potential damages...
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/why-is-it-important-to-insure-your-business">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/insure_important.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>


        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/workout-fitness">
                          <p>WORKOUT FITNESS TIPS POST LOCKDOWN<br/><span style="font-size:12px;">11/09/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       Most of us can relate to the decrease in our physical and fitness activities during this time of restricted movements and partial closure of businesses. Undeniably, there has been a drop in the physical activities over this period.
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/workout-fitness">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/workout_fitness.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>

        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/insurance-value">
                          <p>THE VALUE OF INSURANCE TO SOCIETY<br/><span style="font-size:12px;">09/09/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       Insurance is of value to society in many ways but the general awareness of the contributions of insurance to the overall economy and society is low.
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/insurance-value">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/insurance_value.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>


        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/insurance-agriculture">
                          <p>INSURANCE AS A MEANS TO PROTECT AGRICULTURE IN NIGERIA<br/><span style="font-size:12px;">07/09/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       The importance of agriculture cannot be overemphasized as more than 80% of Nigerians rely on food from the local markets, which are highly sourced from the local farms.
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/insurance-agriculture">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/insurance_agric.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>


        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/common-mistake-in-insurance">
                          <p>COMMON INSURANCE MISTAKES TO AVOID<br/><span style="font-size:12px;">04/09/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       Sometimes the best things in life come with risks, which is why it is important to purchase insurance cover to protect you from such risks. It is also important to ensure that you have the right type and amount of coverage to cover for financial losses...
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/common-mistake-in-insurance">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/insurance_education.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>


        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/insurance-education-sector">
                          <p>INSURANCE AND THE EDUCATION SECTOR<br/><span style="font-size:12px;">02/09/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       Growing up as a child I was privileged to be exposed to teachers who used popular quotes while teaching. They would say things like: “Education is the passport to the future, for tomorrow belongs to those who prepare for it today.”
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/insurance-education-sector">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/insurance_education.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>

        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/innovations-in-insurance-due-to-covid19">
                          <p>INNOVATIONS IN INSURANCE DUE TO COVID-19<br/><span style="font-size:12px;">31/08/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       The global insurance market is experiencing a transformation to ‘digital-first’ business models following the outbreak of the COVID 19 pandemic.
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/innovations-in-insurance-due-to-covid19">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/innovations.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>


        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/debunking-life-insurance-myths">
                          <p>DEBUNKING LIFE INSURANCE MYTHS<br/><span style="font-size:12px;">28/08/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       There are a lot of misconceptions about insurance, especially in this part of the world. This article will throw some light on these myths and pave a way to better living.
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/debunking-life-insurance-myths">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/debunking.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>

        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/validate-autoinsurance-certificate">
                          <p>HOW TO VALIDATE YOUR AUTO INSURANCE CERTIFICATE<br/><span style="font-size:12px;">26/08/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       I was on my way to my sister’s house for tête-à-tête on a Saturday morning. As I made a U-turn at the first Lekki roundabout, a saloon car hit my bumper from behind and broke it.
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/validate-autoinsurance-certificate">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/validate-insurance.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>

        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/risk-management">
                          <p>RISK MANAGEMENT<br/><span style="font-size:12px;">24/08/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       International Organisation for Standardization (ISO 31000), defines risk as “the effect of uncertainty on objectives”; this means the deviation from the expected objective which can be either positive or negative.
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/risk-management">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/risk_management.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>

        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/fostering-health">
                          <p>FOSTERING HEALTHY RELATIONSHIPS IN THE WORK PLACE<br/><span style="font-size:12px;">19/08/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       Life experiences through time have led us to believe that the type of relationship an individual maintains goes a long way to determine the extent of our network of influence.
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/fostering-health">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/team.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>

        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/nsia-insurance-plan">
                          <p>ESSENTIAL PLANS GUARANTEED TO GIVE YOU PEACE OF MIND <br/><span style="font-size:12px;">17/08/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       It is totally understandable that the thought of passing away is terrifying, but have we ever stopped to ask ourselves from where the real fear comes? Could it be that you're the breadwinner of your family...
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/nsia-insurance-plan">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/nsia_insurance_plan.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>

        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/i-am-young-health-insurance">
                          <p>I’M YOUNG AND HEALTHY, WHY DO I NEED LIFE INSURANCE?” <br/><span style="font-size:12px;">15/08/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       The general rule is that if someone else relies on your income to live, then you need a life insurance policy. Perhaps you fall into the category of people who often wonder: “Why do I need life insurance?
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/i-am-young-health-insurance">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/am_too_young.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>

        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/auto-insurance">
                          <p>HERE’S WHAT YOU NEED TO KNOW ABOUT AUTO INSURANCE <span style="font-size:12px;">13/08/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       Experts often tell you to buy the car insurance you can afford, but that's not helpful unless you know the basics of how auto insurance works. Awareness of the insurance-buying process will save you time, effort...
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/auto-insurance">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/auto_insurance.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>

        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/beirut-explosion">
                          <p>THE BEIRUT EXPLOSION AND THE IMPORTANCE OF INSURANCE <br/><span style="font-size:12px;">11/08/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       The catastrophic nature a bomb explosion leaves much to be desired. Such dastardly acts give us reason to worry about what the future holds. It also begs the question; do the perpetrators of these cowardly and inhumane acts...
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/beirut-explosion">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/Beirut.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>

        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/nsia-appoints-directors">
                          <p>NSIA INSURANCE APPOINTS THREE NEW DIRECTORS <br/><span style="font-size:12px;">04/08/2020</span></p>
                       </a>
                    </h2>
                    <p class="">
                       The Board of Directors of NSIA Insurance Limited is pleased to announce the appointment of Mansan Dominique Diagou Epse Ehilé, Apollos Ikpobe and Hélène Konian as Non-Executive Directors of the company with effect from February 2020.
                     </p>

                     <br/>
                    <ul class="list-inline g-font-size-12 mb-0">
                       <li class="list-inline-item g-mb-10">

                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/nsia-appoints-directors">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/mansan.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>

        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/nsia-insurance-holds-agm">
                          <p>NSIA INSURANCE HOLDS ANNUAL GENERAL MEETING, GROWS GROSS PREMIUM INCOME TO N9.19BN <br/><span style="font-size:12px;">24/07/2020</span></p>

                       </a>
                    </h2>
                    <p class="">We successfully concluded our Annual General Meeting (AGM) on Tuesday, June 30, 2020 via teleconferencing.<br/>
                         </p>

                    <ul class="list-inline g-font-size-12 mb-0">

                       <li class="list-inline-item g-mb-10">
                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/nsia-insurance-holds-agm">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/chairman.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>

        <div class="col-lg-6 g-px-25 g-mb-50">
           <!-- Blog Grid Modern Blocks -->
           <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
              <div class="col-sm-6 g-bg-white g-rounded-left-5">
                 <div class="g-pa-35">
                    <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                       <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/nsia-plan-life-insurance-savings">
                          <p>THE NSIA PLAN THAT GIVES YOU LIFE INSURANCE AND SAVINGS IN ONE PACKAGE  <br/><span style="font-size:12px;">24/07/2020</span> <br/></p>
                       </a>
                    </h2>
                    <p class="">
                       Do you indulge in impulse buying and then find yourself unable to save for your future needs? If that is the case, we have the right product for you.
                     </p>
                        <!-- <p>The <strong>NSIA Endowment Assurance Plan</strong> takes care of two needs - life insurance protection against early death and saving towards a lump sum.
                        </p>
                         -->
                    <ul class="list-inline g-font-size-12 mb-0">

                       <li class="list-inline-item g-mb-10">
                          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                             routerLink="/nsia-plan-life-insurance-savings">Read more</a>
                       </li>
                    </ul>
                 </div>
              </div>
              <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
                 data-bg-img-src="assets/img/life_saving.jpg"></div>
           </article>
           <!-- End Blog Grid Modern Blocks -->
        </div>


     </div>
  </div>
</div>
</div>
