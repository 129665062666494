import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-benefits-of-a-term-assurance-plan',
  templateUrl: './benefits-of-a-term-assurance-plan.component.html',
  styleUrls: ['./benefits-of-a-term-assurance-plan.component.css']
})
export class BenefitsOfATermAssurancePlanComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
