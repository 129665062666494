import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import { NgForm } from '@angular/forms'
import { CustomerServiceWeekForm } from 'src/app/Classes/customer-service-week-form';
import { FormserviceService } from 'src/app/Services/formservice.service';

@Component({
  selector: 'app-customer-service-week',
  templateUrl: './customer-service-week.component.html',
  styleUrls: ['./customer-service-week.component.css']
})
export class CustomerServiceWeekComponent implements OnInit {
  proposalModel = new  CustomerServiceWeekForm(null,null,"","",null,"","","","","");
  constructor(private _formservice: FormserviceService) { }

  ngOnInit(): void {
  }
  submitForm(myForm: NgForm){

    console.log(this.proposalModel);

    var email = this.proposalModel.EmailAddress;
    var regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if(!regex.test(email)) {
      Swal.fire("Error", "Your email is not correct", "error");
      this._formservice.stopLoading();
      return;
    }
    var telLength = $('#PhoneNumber').val().toString().length;


    if(telLength < 10){
      Swal.fire("Error", "Your telephone number is not correct", "error");
      this._formservice.stopLoading();
      return;
    }

    if (this.proposalModel.Policy == "") {
      Swal.fire("Error", "Policy is required", "info");
      this._formservice.stopLoading();
      return;
    }
    this._formservice.startLoading();
    this._formservice.sendCustomerServiceWeek(this.proposalModel)
      .subscribe(
        (data) => {
          if (data.statusCode == "00") {
            Swal.fire("Success", "Your form is submitted.", "success");


            myForm.resetForm();
          } else {
            Swal.fire("error", data.statusMessage, "info");
          }
          this._formservice.stopLoading();
        },
        (error) => {
          console.log(error);
          Swal.fire("fatal", "internal server error occurred", "error");
          this._formservice.stopLoading();
        }
      )


}

}
