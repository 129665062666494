import { NaicomUniqueIdComponent } from './Web/naicom-unique-id/naicom-unique-id.component';
import { CampaignAdsComponent } from './campaign-ads/campaign-ads.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PlantAllRiskProposalForm } from './Classes/plant-all-risk-proposal-form';
import { BlogPostComponent } from './custom-blogs/blog-post/blog-post.component';
import { BlogsDetailsComponent } from './custom-blogs/blogs-details/blogs-details.component';
import { BlogsComponent } from './custom-blogs/blogs/blogs.component';
import { InsuranceWhatToExpectComponent } from './Web/insurance-what-to-expect/insurance-what-to-expect.component';
import { QuoteComponent } from './Templates/quote/quote.component';
import { AboutusComponent } from './Web/aboutus/aboutus.component';
import { AccidentInsuranceComponent } from './Web/accident-insurance/accident-insurance.component';
import { AgricInsuranceComponent } from './Web/agric-insurance/agric-insurance.component';
import { AllRiskProposalFormComponent } from './Web/all-risk-proposal-form/all-risk-proposal-form.component';
import { AnnuityComponent } from './Web/annuity/annuity.component';
import { AnticipatedComponent } from './Web/anticipated/anticipated.component';
import { AutoInsuranceComponent } from './Web/auto-insurance/auto-insurance.component';
import { AwardsComponent } from './Web/awards/awards.component';

import { BeneficiaryFormComponent } from './Web/beneficiary-form/beneficiary-form.component';
import { AlwaysReadYourPolicyDocumentsComponent } from './Web/Blog/always-read-your-policy-documents/always-read-your-policy-documents.component';
import { AnxietyComponent } from './Web/Blog/anxiety/anxiety.component';
import { AutoPolicyNonrenewalVsCancellationComponent } from './Web/Blog/auto-policy-nonrenewal-vs-cancellation/auto-policy-nonrenewal-vs-cancellation.component';
import { BasicInsuranceTerminologiesAndTheirMeaningComponent } from './Web/Blog/basic-insurance-terminologies-and-their-meaning/basic-insurance-terminologies-and-their-meaning.component';
import { BenefitsOfATermAssurancePlanComponent } from './Web/Blog/benefits-of-a-term-assurance-plan/benefits-of-a-term-assurance-plan.component';
import { BlogComponent } from './Web/blog/blog.component';
import { BrainExercisesThatBoostMemoryComponent } from './Web/Blog/brain-exercises-that-boost-memory/brain-exercises-that-boost-memory.component';
import { BreastCancerHealthInsuranceComponent } from './Web/Blog/breast-cancer-health-insurance/breast-cancer-health-insurance.component';
import { CareerAdvancementTipsComponent } from './Web/Blog/career-advancement-tips/career-advancement-tips.component';
import { CommonMistakeInInsuranceComponent } from './Web/Blog/common-mistake-in-insurance/common-mistake-in-insurance.component';
import { CybersecurityBestPracticesComponent } from './Web/Blog/cybersecurity-best-practices/cybersecurity-best-practices.component';
import { DebunkingLifeInsuranceMythsComponent } from './Web/Blog/debunking-life-insurance-myths/debunking-life-insurance-myths.component';
import { DistractionDrivingComponent } from './Web/Blog/distraction-driving/distraction-driving.component';
import { EventComponent } from './Web/Events/events.component';
import { FosteringHealthRelationshipsComponent } from './Web/Blog/fostering-health-relationships/fostering-health-relationships.component';
import { HolidayFireSafteyTipsComponent } from './Web/Blog/holiday-fire-saftey-tips/holiday-fire-saftey-tips.component';
import { InsurancePolicyHelpYouSaveMoneyComponent } from './Web/Blog/insurance-policy-help-you-save-money/insurance-policy-help-you-save-money.component';
import { InsuranceTipsForSaveSmartComponent } from './Web/Blog/insurance-tips-for-save-smart/insurance-tips-for-save-smart.component';
import { NewYearForYourInsuranceComponent } from './Web/Blog/new-year-for-your-insurance/new-year-for-your-insurance.component';
import { NollyWoodConnectComponent } from './Web/Blog/nolly-wood-connect/nolly-wood-connect.component';
import { NsiaAppointsDirectorsComponent } from './Web/Blog/nsia-appoints-directors/nsia-appoints-directors.component';
import { NsiaDeferredAnnuityPlanComponent } from './Web/Blog/nsia-deferred-annuity-plan/nsia-deferred-annuity-plan.component';
import { NsiaInsuranceGoPaperlessComponent } from './Web/Blog/nsia-insurance-go-paperless/nsia-insurance-go-paperless.component';
import { NsiaInsuranceHoldsAgmComponent } from './Web/Blog/nsia-insurance-holds-agm/nsia-insurance-holds-agm.component';
import { QuickGuideToHomeownersComponent } from './Web/Blog/quick-guide-to-homeowners/quick-guide-to-homeowners.component';
import { ReasonsYourBusinessNeedsKeymanAssuranceComponent } from './Web/Blog/reasons-your-business-needs-keyman-assurance/reasons-your-business-needs-keyman-assurance.component';
import { RenewalInTheNewYearComponent } from './Web/Blog/renewal-in-the-new-year/renewal-in-the-new-year.component';
import { SatisfyingThe21centuryCustomerComponent } from './Web/Blog/satisfying-the21century-customer/satisfying-the21century-customer.component';
import { SevenInterestingFacetsAboutInsuranceComponent } from './Web/Blog/seven-interesting-facets-about-insurance/seven-interesting-facets-about-insurance.component';
import { SimpleWaysToBeMoreProductiveComponent } from './Web/Blog/simple-ways-to-be-more-productive/simple-ways-to-be-more-productive.component';
import { TenTipsForWorkingWithYourInsuranceCompanyComponent } from './Web/Blog/ten-tips-for-working-with-your-insurance-company/ten-tips-for-working-with-your-insurance-company.component';
import { TheBeirutExplosionImportanceOfInsuranceComponent } from './Web/Blog/the-beirut-explosion-importance-of-insurance/the-beirut-explosion-importance-of-insurance.component';
import { ThingsToConsiderBeforeTakingThatTripComponent } from './Web/Blog/things-to-consider-before-taking-that-trip/things-to-consider-before-taking-that-trip.component';
import { ThirdPartyMotorVsComprehensiveMotorComponent } from './Web/Blog/third-party-motor-vs-comprehensive-motor/third-party-motor-vs-comprehensive-motor.component';
import { TipsOnImprovingTeamDynamicsComponent } from './Web/Blog/tips-on-improving-team-dynamics/tips-on-improving-team-dynamics.component';
import { TipsToImproveQualityOfLifeComponent } from './Web/Blog/tips-to-improve-quality-of-life/tips-to-improve-quality-of-life.component';
import { TipsToPreventHomeBurglaryComponent } from './Web/Blog/tips-to-prevent-home-burglary/tips-to-prevent-home-burglary.component';
import { TravelSafetyTipsComponent } from './Web/Blog/travel-safety-tips/travel-safety-tips.component';
import { UnderstandingTheExcessOnYourVehicleInsurancePolicyComponent } from './Web/Blog/understanding-the-excess-on-your-vehicle-insurance-policy/understanding-the-excess-on-your-vehicle-insurance-policy.component';
import { UnderstandingThirdPartyInsuranceComponent } from './Web/Blog/understanding-third-party-insurance/understanding-third-party-insurance.component';
import { WaysToRetainMoreOfEveryBookComponent } from './Web/Blog/ways-to-retain-more-of-every-book/ways-to-retain-more-of-every-book.component';
import { WhatHappensIfAGuestCausesDamageToMyHomeComponent } from './Web/Blog/what-happens-if-a-guest-causes-damage-to-my-home/what-happens-if-a-guest-causes-damage-to-my-home.component';
import { WhatToDoIfYouGetInACarAccidentComponent } from './Web/Blog/what-to-do-if-you-get-in-a-car-accident/what-to-do-if-you-get-in-a-car-accident.component';
import { WhyIsItImportantToInsureYourBusinessComponent } from './Web/Blog/why-is-it-important-to-insure-your-business/why-is-it-important-to-insure-your-business.component';
import { WhyRenewingYourInsurancePolicyImportantComponent } from './Web/Blog/why-renewing-your-insurance-policy-important/why-renewing-your-insurance-policy-important.component';
import { WorkOutFitnessTipsComponent } from './Web/Blog/work-out-fitness-tips/work-out-fitness-tips.component';
import { WorkingRemotelyComponent } from './Web/Blog/working-remotely/working-remotely.component';
import { YouDonHitMyCarComponent } from './Web/Blog/you-don-hit-my-car/you-don-hit-my-car.component';
import { BoardMembersComponent } from './Web/board-members/board-members.component';
import { BurglaryInsuranceProposalFormComponent } from './Web/burglary-insurance-proposal-form/burglary-insurance-proposal-form.component';
import { BurglaryInsuranceComponent } from './Web/burglary-insurance/burglary-insurance.component';
import { BusinessInsuranceComponent } from './Web/business-insurance/business-insurance.component';
import { BuyMotorComponent } from './Web/buy-motor/buy-motor.component';
import { CareerComponent } from './Web/career/career.component';
import { CertificationComponent } from './Web/certification/certification.component';
import { ClaimProcessMadeEasierComponent } from './Web/claim-process-made-easier/claim-process-made-easier.component';
import { ClaimsComponent } from './Web/claims/claims.component';
import { ComplaintFormComponent } from './Web/complaint-form/complaint-form.component';
import { ContactFormComponent } from './Web/contact-form/contact-form.component';
import { ContactUsComponent } from './Web/contact-us/contact-us.component';
import { CreditLifeComponent } from './Web/credit-life/credit-life.component';
import { DeferredAnnuityPlanComponent } from './Web/deferred-annuity-plan/deferred-annuity-plan.component';
import { DoItTodayNotTomorrowComponent } from './Web/do-it-today-not-tomorrow/do-it-today-not-tomorrow.component';
import { DownloadableComponent } from './Web/downloadable/downloadable.component';
import { DstvThanksComponent } from './Web/dstv-thanks/dstv-thanks.component';
import { EducationPlanComponent } from './Web/education-plan/education-plan.component';
import { EndowmentComponent } from './Web/endowment/endowment.component';
import { EnergyInsuranceComponent } from './Web/energy-insurance/energy-insurance.component';
import { EppComponent } from './Web/epp/epp.component';
import { FamilyProtectionComponent } from './Web/family-protection/family-protection.component';
import { FaqComponent } from './Web/faq/faq.component';
import { FidelityComponent } from './Web/fidelity/fidelity.component';
import { FinancialReportComponent } from './Web/financial-report/financial-report.component';
import { FireInsuranceComponent } from './Web/fire-insurance/fire-insurance.component';
import { FireComponent } from './Web/fire/fire.component';
import { ContractorAllRiskProposalFormComponent } from './Web/Forms/contractor-all-risk-proposal-form/contractor-all-risk-proposal-form.component';
import { CustomerFormComponent } from './Web/Forms/customer-form/customer-form.component';
import { DebitMandateFormComponent } from './Web/Forms/debit-mandate-form/debit-mandate-form.component';
import { FireSpecialProposalFormComponent } from './Web/Forms/fire-special-proposal-form/fire-special-proposal-form.component';
import { GeneralBusinessProposalFormComponent } from './Web/Forms/general-business-proposal-form/general-business-proposal-form.component';
import { GroupLifeProposalFormComponent } from './Web/Forms/group-life-proposal-form/group-life-proposal-form.component';
import { IndividualProposalFormComponent } from './Web/Forms/individual-proposal-form/individual-proposal-form.component';
import { MarineCargoProposalFormComponent } from './Web/Forms/marine-cargo-proposal-form/marine-cargo-proposal-form.component';
import { MoneyProposalFormComponent } from './Web/Forms/money-proposal-form/money-proposal-form.component';
import { MotorInsuranceProposalFormComponent } from './Web/Forms/motor-insurance-proposal-form/motor-insurance-proposal-form.component';
import { PersonalAccidentProposalFormComponent } from './Web/Forms/personal-accident-proposal-form/personal-accident-proposal-form.component';
import { ProfessionalIndemityProposalFormComponent } from './Web/Forms/professional-indemity-proposal-form/professional-indemity-proposal-form.component';
import { WorkmenInsuranceProposalFormComponent } from './Web/Forms/workmen-insurance-proposal-form/workmen-insurance-proposal-form.component';
import { GeneralInsuranceComponent } from './Web/general-insurance/general-insurance.component';
import { GoodsProposalComponent } from './Web/goods-proposal/goods-proposal.component';
import { GroupLifeComponent } from './Web/group-life/group-life.component';
import { GroupPersonalAccidentProposalComponent } from './Web/group-personal-accident-proposal/group-personal-accident-proposal.component';
import { HealthComponent } from './Web/health/health.component';
import { HomeInsuranceComponent } from './Web/home-insurance/home-insurance.component';
// import { HouseHolderInsuranceInformationComponent } from './Web/house-holder-insurance-information/house-holder-insurance-information.component';
import { HouseHolderInsuranceComponent } from './Web/house-holder-insurance/house-holder-insurance.component';
import { HouseholdInsuranceComponent } from './Web/household-insurance/household-insurance.component';
import { HowToMaintainYourCarComponent } from './Web/how-to-maintain-your-car/how-to-maintain-your-car.component';
import { ImmediateAnnuityComponent } from './Web/immediate-annuity/immediate-annuity.component';
import { ImportanceOfDataPrivacyInYourBusinessComponent } from './Web/importance-of-data-privacy-in-your-business/importance-of-data-privacy-in-your-business.component';
import { IndexBodyComponent } from './Web/index-body/index-body.component';
import { InnovationsInInsuranceComponent } from './Web/innovations-in-insurance/innovations-in-insurance.component';
import { InsuranceAgricultureComponent } from './Web/insurance-agriculture/insurance-agriculture.component';
import { InsuranceEducationSectorComponent } from './Web/insurance-education-sector/insurance-education-sector.component';
import { InsuranceValueSocietyComponent } from './Web/insurance-value-society/insurance-value-society.component';
import { InvestmentPlanComponent } from './Web/investment-plan/investment-plan.component';
import { KeymanComponent } from './Web/keyman/keyman.component';
import { LiabilityInsuranceComponent } from './Web/liability-insurance/liability-insurance.component';
import { LifeInsuranceComponent } from './Web/life-insurance/life-insurance.component';
import { LifeProposalFormComponent } from './Web/life-proposal-form/life-proposal-form.component';
import { LifeQuoteComponent } from './Web/life-quote/life-quote.component';
import { ManagementTeamComponent } from './Web/management-team/management-team.component';
import { MarineComponent } from './Web/marine/marine.component';
import { MortgageComponent } from './Web/mortgage/mortgage.component';
import { MotorInsuranceComponent } from './Web/motor-insurance/motor-insurance.component';
import { MotorResponseComponent } from './Web/motor-response/motor-response.component';
import { NsiaEducationProtectionPlanComponent } from './Web/nsia-education-protection-plan/nsia-education-protection-plan.component';
import { NsiaFamilyProtectionPlanComponent } from './Web/nsia-family-protection-plan/nsia-family-protection-plan.component';
import { NsiaInsurancePlanComponent } from './Web/nsia-insurance-plan/nsia-insurance-plan.component';
import { NsiaPlanLifeInsuranceSavingsComponent } from './Web/nsia-plan-life-insurance-savings/nsia-plan-life-insurance-savings.component';
import { PersonalAccidentComponent } from './Web/personal-accident/personal-accident.component';
import { PrivacyComponent } from './Web/privacy/privacy.component';
import { PromoComponent } from './Web/promo/promo.component';
import { PropertyInsuranceComponent } from './Web/property-insurance/property-insurance.component';
import { PublicLiabilityInsuranceProposalComponent } from './Web/public-liability-insurance-proposal/public-liability-insurance-proposal.component';
import { ReportClaimComponent } from './Web/report-claim/report-claim.component';
import { RetailProductComponent } from './Web/retail-product/retail-product.component';
import { RiskManagementComponent } from './Web/risk-management/risk-management.component';
import { SavingsPlanComponent } from './Web/savings-plan/savings-plan.component';
import { TermAssuranceComponent } from './Web/term-assurance/term-assurance.component';
import { ThreeMoneyRulesComponent } from './Web/three-money-rules/three-money-rules.component';
import { TransportationComponent } from './Web/transportation/transportation.component';
import { TravelInsuranceComponent } from './Web/travel-insurance/travel-insurance.component';
import { ValidateAutoInsuranceCertificateComponent } from './Web/validate-auto-insurance-certificate/validate-auto-insurance-certificate.component';
import { WhistleBlowerComponent } from './Web/whistle-blower/whistle-blower.component';
import { WholeLifeComponent } from './Web/whole-life/whole-life.component';
import { WonderComponent } from './Web/wonder-woman/wonder-woman.component';
import { YoungHealthInsuranceComponent } from './Web/young-health-insurance/young-health-insurance.component';
import { NsiaAdsCampaignComponent } from './nsia-ads-campaign/nsia-ads-campaign.component';
import { PolicyDetailsComponent } from './payment-integration/policy-details/policy-details.component';
import { HouseHolderInsuranceInformationComponent } from './Web/household-insurance/house-holder-insurance-information/house-holder-insurance-information.component';
import { CampignPlanBComponent } from './campign-plan-b/campign-plan-b.component';
import { AddmanagementComponent } from './Web/addmanagement/addmanagement.component';
import { AccessmanagementComponent } from './Web/accessmanagement/accessmanagement.component';
import { AddboardComponent } from './Web/addboard/addboard.component';
import { EditboardComponent } from './Web/editboard/editboard.component';
import { AccessboardComponent } from './Web/accessboard/accessboard.component';
import { EditmanagementComponent } from './Web/editmanagement/editmanagement.component';
import { OptionComponent } from './Web/option/option.component';
import { LoginprivateComponent } from './Web/loginprivate/loginprivate.component';
import { AddSlideComponent } from './Web/add-slide/add-slide.component';
import { AccessSlideComponent } from './Web/access-slide/access-slide.component';
import { EditSliderComponent } from './Web/edit-slider/edit-slider.component';
import { CampaignComponent } from './Web/campaign/campaign.component';
const routes: Routes = [
  { path: '', component: IndexBodyComponent },
  { path: 'blogs/post', component: BlogPostComponent },
  { path: 'blogs/list', component: BlogsComponent },
  { path: 'blog/:id/blog-details', component: BlogsDetailsComponent },
  { path: 'home-insurance-company-in-lagos-nigeria', component: HomeInsuranceComponent },
  { path: 'business-insurance-company-in-lagos-nigeria', component: BusinessInsuranceComponent },
  { path: 'motor-insurance-company-in-lagos-nigeria', component: MotorInsuranceComponent },
  { path: 'life-insurance-company-in-lagos-nigeria', component: LifeInsuranceComponent },
  { path: 'term-assurance-insurance-company-in-lagos-nigeria', component: TermAssuranceComponent },
  { path: 'credit-life-insurance-company-in-lagos-nigeria', component: CreditLifeComponent },
  { path: 'anticipated-insurance-company-in-lagos-nigeria', component: AnticipatedComponent },
  { path: 'annuity-insurance-company-in-lagos-nigeria', component: AnnuityComponent },
  // {path:'education',component:EducationComponent},
  { path: 'education-plan-insurance-company-in-lagos-nigeria', component: EducationPlanComponent },
  { path: 'events', component: EventComponent },
  { path: 'transportation-insurance-company-in-lagos-nigeria', component: TransportationComponent },
  { path: 'group-life-insurance-company-in-lagos-nigeria', component: GroupLifeComponent },
  { path: 'keyman-insurance-company-in-lagos-nigeria', component: KeymanComponent },
  { path: 'mortgage-insurance-company-in-lagos-nigeria', component: MortgageComponent },
  { path: 'marine-insurance-company-in-lagos-nigeria', component: MarineComponent },
  { path: 'fire-insurance-company-in-lagos-nigeria', component: FireComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'claims', component: ClaimsComponent },
  { path: 'aboutus', component: AboutusComponent },
  { path: 'board', component: BoardMembersComponent },
  { path: 'beneficiary-form', component: BeneficiaryFormComponent },
  { path: 'proposal-form', component: LifeProposalFormComponent },
  { path: 'downloadable', component: DownloadableComponent },
  { path: 'management', component: ManagementTeamComponent },
  { path: 'financialreport', component: FinancialReportComponent },
  { path: 'career', component: CareerComponent },
  { path: 'contactus', component: ContactUsComponent },
  { path: 'contact-form', component: ContactFormComponent },
  { path: 'tips-to-prevent-home-burglary', component: TipsToPreventHomeBurglaryComponent },
  { path: 'complaint-form', component: ComplaintFormComponent },
  { path: 'buymotor', component: BuyMotorComponent },
  { path: 'purchaseresponse', component: MotorResponseComponent },
  { path: 'ways-to-retain-more-of-every-book', component: WaysToRetainMoreOfEveryBookComponent },
  { path: 'reportclaim', component: ReportClaimComponent },
  { path: 'nsia-insurance-go-paperless', component: NsiaInsuranceGoPaperlessComponent },
  { path: 'getquote', component: QuoteComponent },
  { path: 'lifequote', component: LifeQuoteComponent },
  { path: 'whole-life-insurance-company-in-lagos-nigeria', component: WholeLifeComponent },
  { path: 'family-protection-insurance-company-in-lagos-nigeria', component: FamilyProtectionComponent },
  { path: 'savings-plan-insurance-company-in-lagos-nigeria', component: SavingsPlanComponent },
  { path: 'investment-plan-insurance-company-in-lagos-nigeria', component: InvestmentPlanComponent },
  { path: 'travel-safety-tips', component: TravelSafetyTipsComponent },
  { path: 'third-party-motor-vs-comprehensive-motor', component: ThirdPartyMotorVsComprehensiveMotorComponent },
  { path: 'epp-insurance-company-in-lagos-nigeria', component: EppComponent },
  { path: 'deferred-annuity-insurance-company-in-lagos-nigeria', component: DeferredAnnuityPlanComponent },
  { path: 'immediate-annuity-insurance-company-in-lagos-nigeria', component: ImmediateAnnuityComponent },
  { path: 'promos', component: PromoComponent },
  { path: 'health-insurance-company-in-lagos-nigeria', component: HealthComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'award', component: AwardsComponent },
  { path: 'womens-month-2023', component: WonderComponent },
  { path: 'itsitimecampaign', component: CampaignComponent },
  { path: 'access-to-management', component: AccessmanagementComponent },
  { path: 'access-to-board', component: AccessboardComponent },
  { path: 'edit-board/:id', component: EditboardComponent },
  { path: 'add-board', component: AddboardComponent },
  { path: 'edit-management/:id', component: EditmanagementComponent },
  { path: 'option-edit', component: OptionComponent },
  { path: 'login-to-edit', component: LoginprivateComponent },
  { path: 'add-management', component: AddmanagementComponent },
  { path: 'add-slider', component: AddSlideComponent },
  { path: 'access-to-slider', component: AccessSlideComponent },
  { path: 'edit-slider/:id', component: EditSliderComponent },
  { path: 'certification', component: CertificationComponent },
  { path: 'dstv-thanks', component: DstvThanksComponent },
  { path: 'whistle-blower', component: WhistleBlowerComponent },
  { path: 'agric-insurance-company-in-lagos-nigeria', component: AgricInsuranceComponent },
  { path: 'property-insurance-company-in-lagos-nigeria', component: PropertyInsuranceComponent },
  { path: 'liability-insurance-company-in-lagos-nigeria', component: LiabilityInsuranceComponent },
  { path: 'accident-insurance-company-in-lagos-nigeria', component: AccidentInsuranceComponent },
  { path: 'burglary-insurance-company-in-lagos-nigeria', component: BurglaryInsuranceComponent },
  { path: 'household-insurance-company-in-lagos-nigeria', component: HouseholdInsuranceComponent },
  { path: 'insurance-education-sector', component: InsuranceEducationSectorComponent },
  { path: 'common-mistake-in-insurance', component: CommonMistakeInInsuranceComponent },
  { path: 'energy-insurance-company-in-lagos-nigeria', component: EnergyInsuranceComponent },
  { path: 'fire-insurance-company-in-lagos-nigeria', component: FireInsuranceComponent },
  { path: 'personal-accident-insurance-company-in-lagos-nigeria', component: PersonalAccidentComponent },
  { path: 'travel-insurance-company-in-lagos-nigeria', component: TravelInsuranceComponent },
  { path: 'nollywood-connect', component: NollyWoodConnectComponent },
  { path: 'nsia-insurance-holds-agm', component: NsiaInsuranceHoldsAgmComponent },
  { path: 'nsia-plan-life-insurance-savings', component: NsiaPlanLifeInsuranceSavingsComponent },
  { path: 'nsia-appoints-directors', component: NsiaAppointsDirectorsComponent },
  { path: 'beirut-explosion', component: TheBeirutExplosionImportanceOfInsuranceComponent },
  { path: 'auto-insurance-company-in-lagos-nigeria', component: AutoInsuranceComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'things_to_consider_before_taking_that_trip', component: ThingsToConsiderBeforeTakingThatTripComponent },
  { path: 'basic_insurance_terminologies', component: BasicInsuranceTerminologiesAndTheirMeaningComponent },
  { path: 'benefits-of-a-term-assurance', component: BenefitsOfATermAssurancePlanComponent },
  { path: 'marine-cargo-proposal-form', component: MarineCargoProposalFormComponent },
  { path: 'what-to-do-if-you-get-in-a-car-accident', component: WhatToDoIfYouGetInACarAccidentComponent },
  { path: 'house-holder-insurance', component: HouseHolderInsuranceComponent },
  { path: 'nsia-insurance-plan-', component: NsiaInsurancePlanComponent },
  { path: 'holiday-fire-saftey-tips', component: HolidayFireSafteyTipsComponent },
  { path: 'simple-ways-to-be-more-productive', component: SimpleWaysToBeMoreProductiveComponent },
  { path: 'i-am-young-health-insurance', component: YoungHealthInsuranceComponent },
  { path: 'retail-products', component: RetailProductComponent },
  { path: 'Individual-life-proposal-form', component: IndividualProposalFormComponent },
  { path: 'group-life-proposal-form', component: GroupLifeProposalFormComponent },
  { path: 'importance-of-data-privacy', component: ImportanceOfDataPrivacyInYourBusinessComponent },
  { path: 'customer-form', component: CustomerFormComponent },
  { path: 'insurance-policy-help-you-save-money', component: InsurancePolicyHelpYouSaveMoneyComponent },
  { path: 'How-to-maintain-your-car', component: HowToMaintainYourCarComponent },
  { path: 'general-proposal-form', component: GeneralBusinessProposalFormComponent },
  { path: 'fostering-health', component: FosteringHealthRelationshipsComponent },
  { path: 'public-liability-proposal-form', component: PublicLiabilityInsuranceProposalComponent },
  { path: 'endowment-insurance-company-in-lagos-nigeria', component: EndowmentComponent },
  { path: 'General-Insurance-company-in-lagos-nigeria', component: GeneralInsuranceComponent },
  { path: 'career-advancement-tips', component: CareerAdvancementTipsComponent },
  { path: 'nsia-deferred-annuity-plan-insurance-company-in-lagos-nigeria', component: NsiaDeferredAnnuityPlanComponent },
  { path: 'cybersecurity-best-practices', component: CybersecurityBestPracticesComponent },
  { path: 'brain-exercises-that-boost-memory', component: BrainExercisesThatBoostMemoryComponent },
  { path: 'nsia-education-protection-plan', component: NsiaEducationProtectionPlanComponent },
  { path: 'nsia-family-protection-plan', component: NsiaFamilyProtectionPlanComponent },
  { path: 'quick-guide-to-homeowners', component: QuickGuideToHomeownersComponent },
  { path: 'money-proposal-form', component: MoneyProposalFormComponent },
  { path: 'burglary-proposal-form', component: BurglaryInsuranceProposalFormComponent },
  { path: 'plant-all-risk-proposal-form', component: PlantAllRiskProposalForm },
  { path: 'workmen-insurance-proposal-form', component: WorkmenInsuranceProposalFormComponent },
  // {path:'employers-all-risk-proposal-form',component:EmployersLiabilityInsuranceProposalFormComponent},
  { path: 'insurance-agriculture', component: InsuranceAgricultureComponent },
  { path: 'risk-management', component: RiskManagementComponent },
  { path: 'always-read-your-policy-documents', component: AlwaysReadYourPolicyDocumentsComponent },
  { path: 'satisfying-the-21-century-customer', component: SatisfyingThe21centuryCustomerComponent },
  { path: 'house-holder-insurance-information', component: HouseHolderInsuranceInformationComponent },
  { path: 'fidelity-gurantee-proposal-form', component: FidelityComponent },
  { path: 'you-don-hit-my-car', component: YouDonHitMyCarComponent },
  { path: 'workout-fitness', component: WorkOutFitnessTipsComponent },
  { path: 'insurance-value', component: InsuranceValueSocietyComponent },
  { path: 'understanding-third-party-insurance', component: UnderstandingThirdPartyInsuranceComponent },
  { path: 'why-is-it-important-to-insure-your-business', component: WhyIsItImportantToInsureYourBusinessComponent },
  { path: 'validate-autoinsurance-certificate', component: ValidateAutoInsuranceCertificateComponent },
  { path: 'debunking-life-insurance-myths', component: DebunkingLifeInsuranceMythsComponent },
  { path: 'innovations-in-insurance-due-to-covid19', component: InnovationsInInsuranceComponent },
  { path: 'contractor-all-risk-proposal-form', component: ContractorAllRiskProposalFormComponent },
  { path: 'professional-indemity-proposal-form', component: ProfessionalIndemityProposalFormComponent },
  { path: 'group-personal-accident-proposal-form', component: GroupPersonalAccidentProposalComponent },
  { path: 'all-risk-proposal-form', component: AllRiskProposalFormComponent },
  { path: 'claim-process-made-easier', component: ClaimProcessMadeEasierComponent },
  { path: 'debit-mandate-form', component: DebitMandateFormComponent },
  { path: 'reasons_your_business_needs_keyman_assurance', component: ReasonsYourBusinessNeedsKeymanAssuranceComponent },
  { path: 'fire-special-peril-proposal-form', component: FireSpecialProposalFormComponent },
  { path: 'personal-accident-proposal-form', component: PersonalAccidentProposalFormComponent },
  { path: 'goods-in-transit-proposal-form', component: GoodsProposalComponent },
  { path: 'ten-tips-for-working-with-your-insurance-company', component: TenTipsForWorkingWithYourInsuranceCompanyComponent },
  { path: 'understanding-the-excess-on-your-vehicle-insurance-policy', component: UnderstandingTheExcessOnYourVehicleInsurancePolicyComponent },
  { path: 'tips-to-improve-quality-of-life', component: TipsToImproveQualityOfLifeComponent },
  { path: 'how-to-to-improve-quality-of-life', component: TipsToImproveQualityOfLifeComponent },
  { path: 'tips-on-improving-team-dynamics', component: TipsOnImprovingTeamDynamicsComponent },
  { path: 'working-remotely', component: WorkingRemotelyComponent },
  { path: 'seven-interesting-facts-about-insurance', component: SevenInterestingFacetsAboutInsuranceComponent },
  { path: 'what-happens-if-a-guest-causes-damage-to-my-home', component: WhatHappensIfAGuestCausesDamageToMyHomeComponent },
  { path: 'motor-proposal-form', component: MotorInsuranceProposalFormComponent },
  { path: 'how-to-prevent-distractions', component: DistractionDrivingComponent },
  { path: 'breast-cancer-health-insurance', component: BreastCancerHealthInsuranceComponent },
  { path: 'anxiety', component: AnxietyComponent },
  { path: 'auto-policy-nonrenewal-vs-cancellation', component: AutoPolicyNonrenewalVsCancellationComponent },
  { path: 'insurance-tips-for-save-smart', component: InsuranceTipsForSaveSmartComponent },
  { path: 'new-year-for-your-insurance', component: NewYearForYourInsuranceComponent },
  { path: 'why-renewing-your-insurance-policy-important', component: WhyRenewingYourInsurancePolicyImportantComponent },
  { path: 'renewal-in-the-new-year', component: RenewalInTheNewYearComponent },
  { path: 'three-money-rules', component: ThreeMoneyRulesComponent },
  { path: 'do-it-today-not-tomorrow', component: DoItTodayNotTomorrowComponent },
  { path: 'insurance-in-2021', component: InsuranceWhatToExpectComponent },
  { path: 'effective-social-media-strategies-for-brands', component: InsuranceWhatToExpectComponent },
  // {path: 'NSIAvaluecampaign', component: NsiaAdsCampaignComponent },
  // {path: 'NSIAvaluecampaign', component: CampaignAdsComponent},
  { path: 'NSIAPlanICampaign', component: CampignPlanBComponent },
  { path: 'flutter-pay', component: PolicyDetailsComponent },
  { path: 'naicom', component: NaicomUniqueIdComponent },
  ///insurance-in-2021

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
