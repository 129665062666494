<div class="container g-pt-100 g-pb-70">
  <div class="row">
    <div class="col-lg-12 g-mb-30">
      <section class="g-bg-gray-light-v5 g-py-20">
        <form novalidate (ngSubmit)="submitForm()" #beneficiaryForm="ngForm" novalidate>
          <div class="container g-py-100" style="margin-top:-50px">
            <div class="u-shadow-v19 g-brd-around g-brd-gray-light-v4  rounded mx-auto g-pa-30 g-pa-50--md">

              <div class="mb-6">
                <h2 class="mb-4">
                  <p>Grouplife Beneficiary Form</p>
                </h2>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group g-mb-20">
                      <label>
                        Surname
                      </label>
                      <input id="Surname" name="Surname" class="form-control form-control-md rounded-0" type="text"
                        placeholder="Required" [(ngModel)]="formData.Surname" required>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group g-mb-20">
                      <label>
                        Firstname
                      </label>
                      <input id="Firstname" name="Firstname" class="form-control form-control-md rounded-0" type="text"
                        placeholder="Required" [(ngModel)]="formData.Firstname" required>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group g-mb-20">
                      <label>
                        Other Name
                      </label>
                      <input id="Othername" name="Othername" class="form-control form-control-md rounded-0" type="text"
                        placeholder="Required" [(ngModel)]="formData.Othername" required>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group g-mb-20">
                      <label>
                        Staff/Member Number
                      </label>
                      <input id="StaffNumber" name="StaffNumber" class="form-control form-control-md rounded-0"
                        type="text" placeholder="Required" [(ngModel)]="formData.StaffNumber" required>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group g-mb-20">
                      <label>
                        Date of Birth
                      </label>
                      <div class="input-group">
                        <ngb-datepicker #dp name="DateOfBirth" [(ngModel)]="formData.DateOfBirth"
                          (navigate)="formData.DateOfBirth = $event.next"></ngb-datepicker>

                      </div>
                    </div>


                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group g-mb-20">
                    <label>
                      Phone Number
                    </label>
                    <input id="PhoneNumber" name="PhoneNumber" class="form-control form-control-md rounded-0"
                      type="text" placeholder="Required" [(ngModel)]="formData.PhoneNumber" required>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group g-mb-20">
                    <label>
                      Email Address
                    </label>
                    <input id="EmailAddress" name="EmailAddress" class="form-control form-control-md rounded-0"
                      type="text" placeholder="Required" [(ngModel)]="formData.EmailAddress" required>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group g-mb-20">
                    <label>
                      Gender
                    </label>
                    <select name="Gender" [(ngModel)]="formData.Gender" class="form-control form-control-md rounded-0"
                      id="Gender" required>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>

                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group g-mb-20">
                    <label>
                      Employer's Name
                    </label>
                    <input id="EmployerName" name="EmployerName" class="form-control form-control-md rounded-0"
                      type="text" placeholder="Required" [(ngModel)]="formData.EmployerName" required>

                  </div>
                </div>
              </div>
              <div class="next-of-kin">
                <h4>Next of Kin</h4>
                <div class="next-of-kin-form">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Surname
                        </label>
                        <input id="NkSurname" name="NkSurname" class="form-control form-control-md rounded-0"
                          type="text" placeholder="Required" [(ngModel)]="formData.NkSurname" required>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          First Name
                        </label>
                        <input id="NkFirstname" name="NkFirstname" class="form-control form-control-md rounded-0"
                          type="text" placeholder="Required" [(ngModel)]="formData.NkFirstname" required>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Relationship
                        </label>
                        <input id="NkRelationship" name="NkRelationship" class="form-control form-control-md rounded-0"
                          type="text" placeholder="Required" [(ngModel)]="formData.NkRelationship" required>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Proportion
                        </label>
                        <input id="NkProportion" name="NkProportion" class="form-control form-control-md rounded-0"
                          type="text" placeholder="Required" [(ngModel)]="formData.NkProportion" required>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Date of Birth
                        </label>
                        <div class="input-group">
                          <ngb-datepicker #dp1 name="NkDateOfBirth" [(ngModel)]="formData.NkDateOfBirth"
                            (navigate)="formData.NkDateOfBirth = $event.next"></ngb-datepicker>

                        </div>


                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Phone Number
                        </label>
                        <input id="NkPhoneNumber" name="NkPhoneNumber" class="form-control form-control-md rounded-0"
                          type="text" placeholder="Required" [(ngModel)]="formData.NkPhoneNumber" required>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Email Address
                        </label>
                        <input id="NkEmailAddress" name="NkEmailAddress" class="form-control form-control-md rounded-0"
                          type="text" placeholder="Required" [(ngModel)]="formData.NkEmailAddress" required>
                      </div>
                    </div>
                    <div class="col-lg-6 g-mt-30">
                      <button (click)="addNOK()"
                        class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                        type="button">
                        Add </button>
                    </div>
                  </div>
                </div>
                <div class="nok-table" *ngIf="ShowNextofKin">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>Surname</th>
                        <th>First Name</th>
                        <th>Relationship</th>
                        <th>Proportion %</th>
                        <th>Date of Birth</th>
                        <th>Phone Number/Email Address</th>

                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let kin of userModel.NextofKins; let i = index">
                        <!-- <th scope="row">#</th> -->
                        <td>{{kin.NkSurname}}</td>
                        <td>{{kin.NkFirstname}}</td>
                        <td>{{kin.NkRelationship}}</td>
                        <td>{{kin.NkProportion}}</td>
                        <td>{{kin.NkDateOfBirth}}</td>
                        <td>{{kin.PhoneEmail}}</td>
                      </tr>

                    </tbody>

                  </table>
                </div>


              </div>
              <div class="minors">
                <h4>Minors</h4>
                <div class="minors-form">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Surname
                        </label>
                        <input id="MnSurname" name="MnSurname" class="form-control form-control-md rounded-0"
                          type="text" placeholder="Required" [(ngModel)]="formData.MnSurname" required>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          First Name
                        </label>
                        <input id="MnFirstname" name="MnFirstname" class="form-control form-control-md rounded-0"
                          type="text" placeholder="Required" [(ngModel)]="formData.MnFirstname" required>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Trustee
                        </label>
                        <input id="MnTrustee" name="MnTrustee" class="form-control form-control-md rounded-0"
                          type="text" placeholder="Required" [(ngModel)]="formData.MnTrustee" required>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Date of Birth
                        </label>
                        <div class="input-group">
                          <ngb-datepicker #dp2 name="MnDateOfBirth" [(ngModel)]="formData.MnDateOfBirth"
                            (navigate)="formData.MnDateOfBirth = $event.next"></ngb-datepicker>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Phone Number
                        </label>
                        <input id="MnPhoneNumber" name="MnPhoneNumber" class="form-control form-control-md rounded-0"
                          type="text" placeholder="Required" [(ngModel)]="formData.MnPhoneNumber" required>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Email Address
                        </label>
                        <input id="MnEmailAddress" name="MnEmailAddress" class="form-control form-control-md rounded-0"
                          type="text" placeholder="Required" [(ngModel)]="formData.MnEmailAddress" required>
                      </div>
                    </div>
                    <div class="col-lg-6 g-mt-30">
                      <button (click)="addMinor()"
                        class="g-mb-20 g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                        type="button">
                        Add </button>
                    </div>
                  </div>
                </div>
                <div class="minors-table" *ngIf="ShowMinorTable">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>Minor’s Surname</th>
                        <th>Minor’s First Name</th>
                        <th>Trustee or Guardian</th>
                        <th>Date of Birth</th>
                        <th>Phone Number/Email Address</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let min of userModel.Minors; let i = index">
                        <!-- <th scope="row">#</th> -->
                        <td>{{min.MnSurname}}</td>
                        <td>{{min.MnFirstname}}</td>
                        <td>{{min.MnTrustee}}</td>
                        <td>{{min.MnDateOfBirth}}</td>
                        <td>{{min.MnPhoneEmail}}</td>
                      </tr>

                    </tbody>
                  </table>
                </div>

              </div>






            </div>

            <button
              class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
              type="submit"> Submit
            </button>
          </div>
        </form>
      </section>
    </div>

    <!-- End Sidebar -->
  </div>
</div>
