<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;">THINGS YOU SHOULD DO IN THE NEW YEAR FOR YOUR INSURANCE</p>
              </strong>
           </h3>
           <br/>
           <div class="row justify-content-between">
              <!-- About Image -->
              
              
              
             
              
              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/renewal.jpeg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>
                     The start of a New Year always energizes us to start off on the right note. Whether it’s committing to spend more time with family, exercising more or saving money, New Year resolutions are common for everyone. What about your insurance policy? Is there something you can do at the start of every year to save money or keep yourself fully protected? We have shared three things you should do in the new year for your insurance.

                </p>

                 <p>
                   
                    <strong>
                        <p> Complete a home inventory checklist</p>
                     </strong>
                     Sure, this is always easier said than done but it doesn’t take as long as you may think. You can start by taking pictures of each room in your house. Always include your big-ticket items like jewelry, art, electronics, antiques, furniture and anything else you highly value. Get into the habit of taking a picture of your receipts for big ticket items too. To keep your home inventory list safe, store it on the cloud or save your documents on a zip drive.
              
                </p>

                <p>
                    
                    <strong>
                        <p> Keep in touch with us</p>
                     </strong>
                     Always keep us up-to-date with your life changes. Are you driving less? Did you remodel your home to add value? Did you purchase big ticket items? By keeping us in the loop, we’ll keep your insurance policy updated, make sure you are fully covered and may be able to find additional discounts.
              
                </p>

                <p>
                    
                    <strong>
                        <p>  Ask us questions</p>
                     </strong>
                     Do you know exactly what your insurance covers? If you want the cheapest insurance plan, you may not realize what risks you’re taking by opting for less coverage. We never want you to be surprised if you have to file a claim and find out you’re not covered. Did you know if your home experiences a flood or an earthquake, you would need a separate policy to be covered? Continuously ask us questions. You should always know what you are and aren’t covered for with your insurance policy.
             
                </p>

                <br/>
                <br/>
                <br/>
               
                Have a great year!
                
                
                 <p></p>

              </div>
           </div>
           <br/>
           <br/>
           <br/>

          <div class="row justify-content-between">
            
              <div class="col-md-12">
            
        </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>