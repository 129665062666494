import { Component, OnInit } from '@angular/core';
import employeeData from "../../../../db.json";

import { ManageService } from 'src/app/manage/manage.service';
import { Manage } from 'src/app/manage/manage';
import { AngularFireDatabase } from '@angular/fire/database';




@Component({
  selector: 'app-management-team',
  templateUrl: './management-team.component.html',
  styleUrls: ['./management-team.component.css']
})
export class ManagementTeamComponent {
  allManage: any = [];
  constructor(private db: AngularFireDatabase) {

  }

  ngOnInit(): void {
    this.get()
  }
  get() {
    const ref = this.db.list("management");
    ref.valueChanges().subscribe((data) => {
      this.allManage = data;
    })
  }

}
