export class DebitMandateModel {
  constructor (
    public id: number,
    public surName: string,
    public firstName: string,
    public otherName: string,
    public emailAddress: string,
    public telephone: string,
    public accountNumber: string,
    public accountName: string,
    public policy: string,
    public amountInFigure: number,
    public amountInWords: string,
    public PaymentFrequency: string,
    public from: string,
    public to: string,
    public branch: string,
    public accountType: string,
    public agentName :string,
    public bankName: string,
    public termAgreed: boolean,
    public policyNumber: string,
    public filenameId:string,
    public filetypeId:string,
    public base64textStringId:string,
    public processedImagesId: any = [],
    public filestringId: string,
    public src:string,
    public Address:string

    ) {

    }
}
