export class WhistleClass {
    constructor(
        public fullname: string,
        public email: string,
        public phoneno: string,
        public officer: string,
        public conduct: string,
        public remarks: string,
        public complaintType: string,
      ) {

      }
}
