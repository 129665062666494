
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;"><strong>10	TIPS FOR WORKING WITH YOUR INSURANCE COMPANY </strong></p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/image_October_21.jpg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>1.	Make sure you have an up-to-date copy of your plan's basic information (sometimes called a summary plan description). This will tell you how your plan works, its benefits, and how to file a claim.</p>
                 <p></p>
                 <p>
                  2.	If you buy your own insurance rather than receiving it as a benefit of your spouse's employment, make sure you pay your premiums on time
                 </p>

                 <p></p>
                  <p>
                    3.	Ask your insurance company if you can be assigned a case manager so you talk to the same person each time you call.
                  </p>
              </div>
           </div>

          <div class="row justify-content-between">


              <div class="col-md-12">


              <p></p>
                 <p>4.	Know which procedures require a co-payment and/or co-insurance and how much it will be.
                 </p>

                 <p></p>
                 <p>5.	Keep detailed records of all the claims you submit and note when they were submitted, whether they're still pending, and when they were paid.
                 </p>
                 <p>6.	In the case of a health insurance claim, keep copies of any and all paperwork related to your treatment, diagnosis, and insurance claims, including: <br/>
                  -	requests for sick leave <br/>
                  -	receipts <br/>
                  -	letters from your doctor, employer, and insurance company <br/>
                  -	letters you write to your doctor, employer, and insurance company <br/>
                  -	bills <br/>
                  -	summaries of phone calls to your insurance company, including the date, time, and the name of the person to whom you spoke <br/>

                </p>
                 <p>7.	Know how you should pay for treatment. Does your insurance provider pay the bill first? Or do you pay the bill and then get reimbursed?</p>
                <p></p>
                 <p>8.	If you're required to submit bills to your insurance company, try to send them in as soon as you get them. </p>
                <p></p>
                <p>9.	If your insurance company denies a claim, talk to your case manager to find out why. Ask if and how you can file an appeal. Keep records of all your communications as you go through the process. </p>
                <p></p>
                <p>10.	Ask about coverage for services such as home health visits, palliative care, physical therapy, rehabilitation, mental health care and counseling. These may be important to your recovery, so it makes sense to ask whether your insurance plan covers them.</p>
                <p></p>
                <p>We know it can be frustrating, but stay calm in any dealings with your insurance provider, as this can help increase your odds of success!

                </p>
                <p></p>
                <p>If you would like to talk to us today about health insurance or any other kind of insurance, please call 09048418896 or send an email to <a href="mailto:enquiry@nsiainsurance.com">enquiry@nsiainsurance.com</a>. </p>
              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>