import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Slider } from 'src/app/slider/slider';
import { SliderService } from 'src/app/slider/slider.service';
@Component({
  selector: 'app-access-slide',
  templateUrl: './access-slide.component.html',
  styleUrls: ['./access-slide.component.css']
})
export class AccessSlideComponent implements OnInit {
  p: number = 1;
  Slider: Slider[];
  hideWhenNoStudent: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;
  constructor(public toastr: ToastrService, public sliderService: SliderService) {

  }
  ngOnInit(): void {
    this.dataState();
    let s = this.sliderService.GetStudentsList();
    s.snapshotChanges().subscribe(data => {
      this.Slider = [];
      data.forEach(item => {
        let a = item.payload.toJSON();
        a['$key'] = item.key;
        this.Slider.push(a as Slider);
      })
    })
  }

  dataState() {
    this.sliderService.GetStudentsList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if (data.length <= 0) {
        this.hideWhenNoStudent = false;
        this.noData = true;
      } else {
        this.hideWhenNoStudent = true;
        this.noData = false;
      }
    })
  }



  deletee(slider) {
    this.sliderService.DeleteStudent(slider.$key)
  }

}
