<div class="g-min-height-300 g-flex-centered g-bg-img-hero g-bg-pos-top-center g-bg-size-cover g-pt-80"
  style="background-image: url(assets/img/banners/Inset_Business.jpg);">
  <div class="container g-pos-rel g-z-index-1">
    <h2 class="h1 text-uppercase g-color-primary g-font-size-40--lg mb-0"><b>Accident Insurance</b></h2>
    <span class="d-block  g-color-white g-font-weight-1000 g-font-size-20 mb-4"> Protecting the key assets of your
      livelihood.</span>

  </div>
</div>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item g-color-primary">
        <span>Accident Insurance</span>
      </li>

      <li class="list-inline-item ml-auto">
       <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
           routerLink="/getquote">Get quote</a>
      </li>
    </ul>
  </div>
</section>


<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
    <div class="col-lg-9 g-mb-30">

      <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
        <!-- <h3 class="mb-0"><strong><p>BUSINESS INSURANCE</p></strong></h3><br/> -->
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white;">Money Insurance</h5>
        </div>
        <p></p>
        <p class="mb-0">The objective of money insurance is to indemnify the Insured against all risks of loss of money while in transit, whilst in the insured safe or premises during and after business.
        </p>
        <p>This policy can also be extended to cover loss of cash while in insured premises or personal custody of trusted employees of the Insured.</p>

        <br />
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white;">Fidelity Guarantee Insurance</h5>
        </div>
        <p></p>
        <p class="mb-0">
          Fidelity Guarantee Insurance provides indemnity for loss incurred following dishonesty, fraudulent conversion of money, embezzlement etc of the insured staff. This policy covers losses from every employee indicated in the policy.
        </p>


        <p></p>
        <br />
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white">Group Personal Accident</h5>
        </div>
        <p></p>
        <p class="mb-0">
          This class of business provides compensation for the insured persons in respect of injuries, death and disablement resulting from accidents physical. It can be extended to cover the medical expenses for treatment of injuries.
        </p>
        <p>It is a 24 hour worldwide cover and may be used as welfare package for employees, students, clubs and associations. The benefits payable are for death, Permanent Disability, Temporary Disability and Medical Expenses.</p>
        <br />
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white">Employee Compensation Insurance</h5>
        </div>
        <p></p>
        <p class="mb-0">
          The policy provides compensation to an employee for death or injury as a result of accident sustained in the course of their usual employment.
        </p>
        <br />

      </div>
    </div>

    <!-- Sidebar -->
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <app-sidebar></app-sidebar>
      <!-- End Fast Facts -->
    </div>
    <!-- End Sidebar -->
  </div>
</div>
