
<div class="container g-pt-100 g-pb-70">
  <div class="row">
    <div class="col-lg-12 g-mb-30">
      <section class="g-bg-gray-light-v5 g-py-20">
        <form novalidate (ngSubmit)="submitForm()" #beneficiaryForm="ngForm" novalidate>
          <div class="container g-py-100 pad-top-50" style="margin-top:-50px">
            <div class="u-shadow-v19 g-brd-around g-brd-gray-light-v4  rounded mx-auto g-pa-30 g-pa-50--md">

              <div class="mb-6">
                <h2 class="mb-4 main-heading">
                  BURGLARY INSURANCE PROPOSAL FORM
                </h2>

                <section class="step-two" >
                  <div class="form-sub-title">
                    <h4>Personal Information</h4>
                  </div>
                  <div class="row">

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>Name of Proposer</label>
                        <input id="NameOfProposal" name="NameOfProposal" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.NameOfProposal" required>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Address of Proposer
                        </label>
                        <input id="AddressOfProposal" name="AddressOfProposal" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.AddressOfProposal" required >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Business
                        </label>
                        <input id="TradeOrBusiness" name="TradeOrBusiness" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.TradeOrBusiness" required >
                      </div>
                    </div>


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          E- mail address
                        </label>
                        <input id="EmailAddress" name="EmailAddress" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.EmailAddress" required type="text" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" >

                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Mobile No
                        </label>
                        <input id="Telephone" name="Telephone" class="form-control form-control-md rounded-0" type="number"
                        placeholder="Required" [(ngModel)]="proposalModel.Telephone" required="required" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==11) return false; return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57));" >

                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Alternate number
                        </label>
                        <input id="SecondTelephone" name="SecondTelephone" class="form-control form-control-md rounded-0" type="number"
                         [(ngModel)]="proposalModel.SecondTelephone"  pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==11) return false; return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57));" >

                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                         <label>
                         Start Date
                         </label>
                         <input id="startDate" name="startDate" class="form-control form-control-md rounded-0" type="date"
                          placeholder="Required" [(ngModel)]="proposalModel.StartDate" >

                      </div>
                   </div>
                   <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                         <label>
                          End Date
                         </label>
                         <input id="EndDate" name="EndDate" class="form-control form-control-md rounded-0" type="date"
                          placeholder="Required" [(ngModel)]="proposalModel.EndDate"  >
                      </div>
                   </div>

                   <div class="col-lg-6">
                    <div class="form-group g-mb-20">
                       <label>
                       How long have you occupied the premises?
                       </label>
                       <input id="occuppiedPremisses" name="occuppiedPremisses" class="form-control form-control-md rounded-0" type="number"
                        placeholder="Required" [(ngModel)]="proposalModel.occuppiedPremisses"  >
                    </div>
                 </div>
                  </div>
                </section>

                <section class="step-three">
                  <div class="form-sub-title">
                    <h4>General Questions</h4>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Give description of the building
                        </label>
                        <select name="proposalModel.BuildingDescription" id="proposalModel.BuildingDescription" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.BuildingDescription">
                            <option >Select your option</option>
                            <option value="Warehouse ">Warehouse </option>
                            <option value="Shop">Shop</option>
                            <option value="Residential">Residential</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                           Are you the only occupier of the premises?
                        </label>
                        <select name="proposalModel.OCCUPIER" id="proposalModel.OCCUPIER" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.OCCUPIER">
                            <option >Select your option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-6" >
                      <div class="form-group g-mb-20">
                        <label>
                          Do you live on the premises?
                        </label>


                         <select name="proposalModel.liveonthepremises" id="proposalModel.liveonthepremises" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.liveonthepremises">
                            <option >Select your option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Will the premises be occupied at all time?
                        </label>
                        <input id="premisesbeoccupied" name="premisesbeoccupied" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.premisesbeoccupied" >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Private dwelling or business occupancy
                        </label>
                        <br/><br/>
                        <input id="Privatedwelling" name="Privatedwelling" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.Privatedwelling" >
                      </div>
                    </div>



                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Are  you are sole occupant of the Premises? If not,what other occupants are there?
                        </label>
                        <input id="soleoccupant" name="soleoccupant" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.soleoccupant" >

                      </div>
                    </div>


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Will the premises be left uninhabited at any time? If so, how often and for how long?
                        </label>
                        <input id="premisesbeleftuninhabited" name="premisesbeleftuninhabited" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.premisesbeleftuninhabited" >

                      </div>
                    </div>
                  </div>
                </section>


                <section class="step-three">
                  <div class="">
                    <h6><strong>On content or Stock ? </strong></h6>
                  </div>
                  <div class="row">

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Do you take stock regularly
                        </label>
                        <input id="stockregularly" name="stockregularly" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.stockregularly" >

                      </div>
                    </div>


                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Are stock books regularly posted?
                        </label>
                        <input id="booksregularly" name="booksregularly" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.booksregularly" >

                      </div>
                    </div>


                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Give details of any property to be insured
                        </label>
                        <input id="propertytobeinsured" name="propertytobeinsured" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.propertytobeinsured" >

                      </div>
                    </div>

                  </div>
                </section>

                <section class="step-three">

                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Have thieves entered any premises occupied by you?

                        </label>
                        <br/><br/>
                         <select name="proposalModel.Havethievesentered" id="proposalModel.Havethievesentered" class="form-control form-control-md rounded-0"
                         placeholder="Required" [(ngModel)]="proposalModel.Havethievesentered">
                             <option >Select your option</option>
                             <option value="Yes">Yes</option>
                             <option value="No">No</option>
                         </select>

                        </div>
                    </div>

                    <!-- *ngIf="proposalModel.Havethievesentered==Yes" -->
                    <div class="col-lg-6" >
                      <div class="form-group g-mb-20">
                        <label>
                          When and how did they
                          gain entry?

                        </label>
                        <input id="gainentry" name="gainentry" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.gainentry" >


                      </div>
                    </div>

                    <!-- *ngIf="proposalModel.Havethievesentered==Yes" -->
                    <div class="col-lg-6"  >
                      <div class="form-group g-mb-20">
                        <label>
                             What was the extent of loss?
                        </label>
                        <input id="extentofloss" name="extentofloss" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.extentofloss" >

                      </div>
                    </div>
                    <!-- *ngIf="proposalModel.Havethievesentered==Yes" -->
                    <div class="col-lg-6"  >
                      <div class="form-group g-mb-20">
                        <label>
                          What extra precautions have been taken to prevent such a recurrence?
                        </label>
                        <input id="extraprecautions" name="extraprecautions" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.extraprecautions" >

                      </div>
                    </div>

<!--
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Has any proposal by you for Public Liability Insurance ever been declined or cancelled (if so give details):
                        </label>
                        <input id="ProposalDecline" name="ProposalDecline" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.ProposalDecline" >
                      </div>
                    </div> -->

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                        Give details of anti-theft devices installed on the premises
                        </label>
                        <input id="antitheftdevices" name="antitheftdevices" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.antitheftdevices" >
                      </div>
                    </div>

                 </div>
                </section>





                <section class="step-three">
                  <div class="form-sub-title">
                    <h4>Insurance History</h4>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Are there any insurance in force on any of the property insured?
                        </label>
                        <input id="anyinsuranceinforce" name="anyinsuranceinforce" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.anyinsuranceinforce" >
                      </div>
                    </div>

                    <!-- <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Has any attempt ever been made to enter the premises occupied by you?
                        </label>
                        <input id="hasanyattemptyeverbeenmade" name="hasanyattemptyeverbeenmade" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.hasanyattemptyeverbeenmade" >
                      </div>
                    </div> -->



                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Have you ever made a claim from any Insurer for theft or burglary?
                        </label>
                        <input id="evermadeaclaim" name="evermadeaclaim" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.evermadeaclaim" >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Has any company decline your proposal for burglary or theft risk?
                        </label>
                        <input id="companydecline" name="companydecline" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.companydecline" >
                      </div>
                    </div>



                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Cancelled or refused to renew your policy?
                        </label>
                        <input id="cancelledorrefused" name="cancelledorrefused" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.cancelledorrefused" >

                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Imposed any special terms?
                        </label>

                        <input id="imposedanyspecialterms" name="imposedanyspecialterms" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.imposedanyspecialterms" >
                      </div>
                    </div>



                  </div>
                </section>


               <section class="step-seven" >

                 <div class="container">
                   <h4>Declaration</h4>
                   <p>I/We hereby declare that to the best of my/our knowledge and belief the information herein provided is correct and complete.</p>

                   <!-- <div class="form-group">
                    <input type="checkbox" [(ngModel)]="termAgreed" name="termAgreed" > By checking the box , I agree with the above terms
                  </div> -->

                      <p style="font-size:10px; text-align: justify;">In compliance with NDPR requirements, by completing and submitting this form, you have given consent to NSIA Insurance to receive your data, including your bio data, to enable NSIA Insurance maintain effective communication, send promotional updates, contact you for research purposes and use the data in line with the policy terms.
                      </p>
                      <p style="font-size:10px; text-align: justify;">The data collection may be via hard copies or online forms, validated via JSON WEB TOKEN (JWT). The data will not be accessed by any third party without your consent and any breach of this policy can be addressed legally within the year the breach was reported.</p>

                      <div class="col-lg-4">
                        <div class="form-group g-mb-0">
                          <label style="visibility: hidden;">Frequency</label>
                          <button  type="submit" onclick="return confirm('Are you sure you want to submit this information?')" class="g-brd-main btn-block g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" > Submit
                          </button>
                        </div>

                      </div>

                 </div>

               </section>

              </div>


            </div>
          </div>
        </form>
      </section>
    </div>

    <!-- End Sidebar -->
  </div>
</div>
