<!-- <section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px">
  <div class="container">
    <div class="d-sm-flex text-center">
      <div class="align-self-center">
        <h2 class="h3 g-font-weight-300 w-100 g-mb-10 g-mb-0--md">
          <p>Certification </p>
        </h2>
      </div>


    </div>
  </div>
</section> -->
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>

      <li class="list-inline-item g-color-primary">
        <span>Certification</span>
      </li>


    </ul>
  </div>
</section>
<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
    <div class="col-lg-9 g-mb-30">
      <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
        <h3 class="mb-0"><strong><p>Certification</p></strong></h3><br/>
        <h4><strong>
            <p>ISO 9001:2015 QUALITY MANAGEMENT SYSTEMS CERTIFICATION</p>
          </strong> </h4>

       <p>NSIA Insurance is proudly certified to the ISO 9001:2015 Quality Management Systems Standard. The Company achieved this certification due to its successful implementation of a quality management system and priority for customer satisfaction. This has ensured that all processes within the organization are standardized to meet best practices. The certification aligns with NSIA’s drive for excellence.</p>
       <p>NSIA became ISO certified in April 2018 and continues to sustain the standard and quality of service across all of its branches to provide clients with excellent service delivery.</p>
       <p>ISO 9001:2015 is the latest and most advanced international standards for quality management systems and our prompt adoption of this standard is evidence of our commitment to quality in all of our business processes and products.</p>
        <p></p>



      </div>
    </div>


    <!-- Sidebar -->
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <app-sidebar></app-sidebar>
      <!-- End Fast Facts -->
    </div>
    <!-- End Sidebar -->
  </div>
</div>
