<div class="g-min-height-300 g-flex-centered g-bg-img-hero g-bg-pos-top-center g-bg-size-cover g-pt-80"
  style="background-image: url(assets/img/banners/Inset_Business.jpg);">
  <div class="container g-pos-rel g-z-index-1">
    <h2 class="h1 text-uppercase g-color-primary g-font-size-40--lg mb-0"><b>NSIA Business Insurance</b></h2>
    <span class="d-block  g-color-white g-font-weight-1000 g-font-size-20 mb-4"> Protecting the key assets of your
      livelihood.</span>

  </div>
</div>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item g-color-primary">
        <span>Business Insurance</span>
      </li>

      <li class="list-inline-item ml-auto">
       <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
           routerLink="/getquote">Get quote</a>
      </li>
    </ul>
  </div>
</section>


<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
    <div class="col-lg-9 g-mb-30">
        <p>Your business is an important source of income for you and your employees,
          so it makes sense that you take measures to protect yourself against loss. For this reason, NSIA business insurance products provide financial protection for business-related losses or accidents
          </p>
          <p></p>
          <i>Requesting a quote is easy. Just click on the get a quote button or Call us now on 01-280-5378-9.</i>
          <p></p>
          <br/>
      <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
        <!-- <h3 class="mb-0"><strong><p>BUSINESS INSURANCE</p></strong></h3><br/> -->
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white;">Personnel Insurance </h5>
        </div>
        <p></p>
        <p class="mb-0">Workers are the backbone of a business, so our Personnel Insurance product is tailored towards protecting your business and employees against financial loss in events of workplace accidents.
            Personnel insurance protects your workers and safeguards the future of their families.</p>
        <!-- <p></p>
        <p class="mb-0"> If an accident leads to death or bodily injury that impairs ability to work, the worker and/or
          his family will suffer a financial loss as well as a personal one. Personnel insurance protects your workers
          and safeguards the future of their families.</p> -->
        <br />
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white;">Engineering Insurance</h5>
        </div>
        <p></p>
        <p class="mb-0">
          <!-- If you’re in the engineering field, you understand the complexity that is involved with projects. It’s
          essential that you’re protected against all risks related to contractors, construction, erection, plant(s),
          machinery, computers and electronics and boilers. -->
          Engineering work is routinely hazardous. It is essential that you’re protected against all risks related to contractors, construction, plant(s), machinery, computers and electronics and boilers.
        </p>

        <p class="mb-0">Our Engineering Insurance is assembled to help you mitigate risk against potential loss or work
          interruption caused by damage, theft, or other hazards related to these areas.</p>
        <p></p>
        <br />
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white">Office Comprehensive</h5>
        </div>
        <p></p>
        <p class="mb-0">
            Your workplace is an important foundation of your business. Our Office Comprehensive Insurance covers the integrity of your workplace and the tools needed to operate your business.
        </p>
        <p class="mb-0">This policy can also be extended to cover Public Liability and Workmen’s Compensation.</p>
        <br />
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white">Goods-In-Transit </h5>
        </div>
        <p></p>
        <p class="mb-0">If your company invests in physicals goods that have to be transported, you face the risk of damage to those goods when they are in transit.
        Our Goods-In-Transit policy insures them against fire, theft or accidental damage that can occur during loading, unloading or storage.
        </p>

        <p class="mb-0">Our goods-in-transit policy insures them against fire, theft or accidental damage that can occur
          during loading, unloading or storage.</p>
        <br />
        <div style="padding: 10px 0px 0.05px 10px ; background-color:#efb225;">
          <h5 style="color:white">Public/Product Liability </h5>
        </div>
        <p></p>
        <p class="mb-0">Wrongful use of your products by unsupervised persons may make you liable for accidental results. Our Public/Product Liability policy covers or indemnifies the insured for legal liabilities that could emanate from the following causes:</p>

        <!-- <p class="mb-0">•	Accidental bodily injury to or illness of any person (whether or not death results).</p> -->

        <ul>
          <li style="color:#bbb">
            <p>Accidental bodily injury to or illness of any person (whether or not death results)</p>
          </li>
          <li style="color:#bbb">
            <p>Accidental loss or damage to property occurring during the period of insurance and in connection with the
              business carried on at any place described in the schedule.</p>
          </li>

        </ul>
      </div>
    </div>

    <!-- Sidebar -->
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <app-sidebar></app-sidebar>
      <!-- End Fast Facts -->
    </div>
    <!-- End Sidebar -->
  </div>
</div>
