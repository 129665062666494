import { Component, OnInit } from '@angular/core';
import { NsiaServicesService } from 'src/app/Services/nsia-services.service';

@Component({
  selector: 'app-promo',
  templateUrl: './promo.component.html',
  styleUrls: ['./promo.component.css']
})
export class PromoComponent implements OnInit {

  constructor(private nsiaService: NsiaServicesService) { }
  title: string;
  date: string;
  content: string;
  response: string;
  ngOnInit(): void {

    this.nsiaService.getNewPromo().subscribe(
      result => {
        this.response = '';
        this.title = result['title'];
        this.date = result['date'];
        this.content = result['content'];
        },
      error => {
        console.log(error);
        this.response = error.error.message;
      });
  }

}
