
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row" *ngIf="blogDetails !=null">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;">{{blogDetails?.data.title}}</p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" [src]="blogDetails?.data.image" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <!-- <p innerHTML="blogDetails.data.content">
                     </p> --> <p [innerHTML]="blogDetails.data.content"></p>

                 <p></p>

              </div>
           </div>

          <div class="row justify-content-between">

              <div class="col-md-12" >
                <!-- <p [innerHTML]="blogDetails?.data.content.slice(551,1000000)"> -->
                  <!-- The start of a New Year can sometimes make us anxious because it’s the start of a whole year and even though technically, it is not any different from any other day, it still can feel like you’re ending one chapter in your life and preparing to pen the next. When you look at it that way, that’s a lot of pressure to put on yourself. Here are a few small steps you can take during this new year. -->
               <!-- </p> -->
              </div>

          </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>
