import { ClamsModel } from './../../Classes/blogs-model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.css']
})
export class CareerComponent implements OnInit {
  onClick: boolean = false;
  myId: number | undefined;
  constructor() { }
  claims: ClamsModel[] = [
    // {id: 1, name: "HEAD, UNDERWRITING ",
    // jobDetails: 'Can you establish and implement sound underwriting policies and procedures, evaluating and making good decisions on risk acceptance in line with our underwriting strategy? ',
    // location: 'Victoria Island, Lagos.',
    // jobCode: 'HUW422',
    // applicationDeadline: 'Friday, May 20, 2022',
    // requirements:[
    //   "BSC, PGD in Insurance/ Actuarial Sciences an added advantage.",
    //   "Minimum of 10 years relevant experience with at least 3 years in a supervisory role ",
    //   "Professional Qualification: ACII is an added advantage "
    // ],
    // imageUrl: "../../../assets/img/articles/1.png"},
    // {id: 2, name: "BANK OPERATIONS COLLECTIONS ",
    // jobDetails: 'Can you effectively monitor account receivables for Life & Non-Life Operations to ensure accuracy and prompt premium collection? ',
    // location: 'Victoria Island, Lagos.',
    // jobCode: 'BOC422',
    // applicationDeadline: 'Friday, May 20, 2022',
    // requirements:[
    //   "Minimum of HND, B. Degree in Accounting, Finance or any related field",
    //   "0 – 1year experience post completion of NYSC",
    //   "Professional Qualification: ACCA/CIIN"
    // ],
    // imageUrl: "../../../assets/img/articles/2.jpg"},
    {id: 3, name: "Enterprise Application Support (GIBS)",
    jobDetails: 'You will be responsible for the development, operations, and maintenance of NSIA’s core Insurance application (GIBs) to ensure seamless access.',
    location: 'Victoria Island, Lagos.',
    jobCode: 'EAS422',
    applicationDeadline: 'Friday, May 20, 2022',
    requirements:[
      "BSC, in information Technology, Computer Science, Engineering or any related field",
      "Minimum of 5 years relevant experience preferably in a similar IT role ",
      "Professional Qualification: MCSA/MCSD"
    ],
    imageUrl: "../../../assets/img/articles/3.jpg"},
   {id: 4, name: "CORPORATE MARKETING (HEALTH)",
     jobDetails: 'To support the achievement of NSIA’s Sales/Marketing strategy through the development of new businesses and retention of existing businesses.',
      location: 'Lagos (Victoria Island)',
       jobCode: 'CMH422',
        applicationDeadline: 'Friday, May 20, 2022',
         requirements:[
           "First Degree in any discipline ",
           "Two (2) years post NYSC experience in a corporate sales function",
           "Membership in CIIN ",
           "Bancassurance Certification ",
           "Agency proficiency Certification"
         ],
          imageUrl: "../../../assets/img/articles/6.png"},
    {id: 5, name: "REGIONAL MARKETING",
    jobDetails:'Are you smart, resilient, confident and can fit into our Sales/Marketing team?',
     location: 'Onitsha',
      jobCode: 'RMON322',
      applicationDeadline: 'Friday, May 20, 2022',
      requirements:[
        "BSC in any discipline",
        "Professional Membership: CIIN and the Agency Proficiency Certificate ",
        "Resident in Onitsha or very close environs",
        "Must be fluent in the native language"
      ],
      imageUrl: "../../../assets/img/articles/7.png"},
    {id: 6, name: "REGIONAL MARKETING",
     location: 'Ibadan, Oyo State',
     jobDetails: 'Are you smart and confident professional team player with up to 2 years’ work experience in Sales/Marketing?',
      jobCode: '',
     applicationDeadline: 'Friday, May 20, 2022',
      requirements:[
        "BSC in any discipline",
        "Membership of CIIN and the Agency Proficiency Certificate will be an added advantage",
        "Resident in Ibadan or very close environs" ],  imageUrl: "../../../assets/img/articles/8.png"},
        {
     id: 7,
     name: "AGRIC INSURANCE SPECIALIST",
     location: 'Victoria Island, Lagos.',
     jobDetails: 'You will be responsible for assessing Agric and Micro insurance proposals to determine suitability and terms for accepting same into the company risk portfolio.',
      jobCode: 'TMAU422',
     applicationDeadline: 'Friday, May 20, 2022',
      requirements:[
        "•	Minimum of BSC, PGD in Agriculture, Animal/Crop/Soil Science, Risk Management.",
        "•	Minimum of 1-year relevant experience in the insurance industry ",
        "•	Professional Qualification:(ACII)  " ],  imageUrl: "../../../assets/img/articles/8.png"},

  ]
  ngOnInit(): void {
  }
  readMoreClick(value, id) {
   if(id!==null) {
    this.onClick = true;
    console.log(id);
    this.myId = id;

   }

  }

}
