import { BlogDetails } from './../../Classes/blogs-model';
import { BlogsService } from './../../Services/blog.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-blogs-details',
  templateUrl: './blogs-details.component.html',
  styleUrls: ['./blogs-details.component.css']
})
export class BlogsDetailsComponent implements OnInit {
  blogId: number;
  blogDetails: BlogDetails;
  constructor(private _blogService: BlogsService,private activateRoute: ActivatedRoute) { }

  ngOnInit(): void {

    this.blogId = +this.activateRoute.snapshot.params['id'];
    console.log(this.blogId);
    console.log(this.activateRoute);

    this.getBlogById(this.blogId);
  }


  getBlogById(id: number) {
    this._blogService.getBlogById(id).subscribe(response => {
      console.log(response);
      this.blogDetails = response;
    })
  }

}
