
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;">WHY IS RENEWING YOUR INSURANCE POLICY IMPORTANT?</p>
              </strong>
           </h3>
           <br/>
           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/renewal-insurance-important.jpeg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>
                    Failure to renew your insurance policy after maturity has a negative impact on your ability to make claims in the event of an unfortunate occurrence. Every policyholder wants to be able to make claims especially when they are facing severe issues. 
                </p>
                 <p></p>

              </div>
           </div>
           <br/>
           <br/>
           <br/>

          <div class="row justify-content-between">
            
              <div class="col-md-12">
            <p><strong>DISADVANTAGES OF NON-RENEWAL OF POLICY 
                <br/>
                </strong> 
                <strong>
                     1. It defeats the purpose of insurance:</strong> The purpose of having an insurance policy is to be indemnified from unfortunate events.  When there’s a lapse in the renewal of your policy, it becomes invalid. In a situation where one is diagnosed with a severe illness, all the medical bills will be paid out of pocket. Similarly, with regards to life insurance, in a case of your sudden demise, your loved ones will not be entitled to any claims.  
                     <br/>
                     <br/>
                     <strong>
                         2. You may be unable to enjoy certain tax benefits:</strong> The purpose of having an insurance policy is to be indemnified from unfortunate events.  When there’s a lapse in the renewal of your policy, it becomes invalid. In a situation where one is diagnosed with a severe illness, all the medical bills will be paid out of pocket. Similarly, with regards to life insurance, in a case of your sudden demise, your loved ones will not be entitled to any claims.  
                         <br/>
                         <br/> Certain life insurance policies have tax deductibles. Section 33(4d) of the Personal Income Tax Act (PITA) 2011 provides for tax to be deducted from premium on life insurance on a person or their spouse, subject to certain conditions. Therefore, failure to renew your insurance policy indicates that you may no longer be entitled to enjoy such tax benefits. 
Some policyholders complain that they forget to renew their insurance policies and other say that the process is burdensome. However, an automatic direct debit system has been provided to make life easier for you. If you are in these categories, all you have to do is provide your payment details and whenever your policy is due, it will be automatically renewed without you lifting a finger.
                    <br/>
                     <br/>
                     <br/>
                     <br/>
Kindly call us today on 09048418896 for more information. 

        </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>