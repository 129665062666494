<div class="g-min-height-300 g-flex-centered g-bg-img-hero g-bg-pos-top-center g-bg-size-cover g-pt-80"
  style="background-image: url(assets/img/banners/Inset_Home.jpg);">
  <div class="container g-pos-rel g-z-index-1">
    <h2 class="h1 text-uppercase g-color-primary g-font-size-40--lg mb-0"><b>Our Downloads</b></h2>
    <!-- <span class="d-block  g-color-white g-font-weight-1000 g-font-size-20 mb-4"> We are here to support you.</span> -->
  </div>
</div>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item g-color-primary">
        <span>Downloads</span>
      </li>

      <li class="list-inline-item ml-auto">
        <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10"
          routerLink="/getquote">Get quote</a> -->
      </li>
    </ul>
  </div>
</section>


<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
    <div class="col-lg-9 g-mb-30">

      <div class="downloads u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
        <!-- <h3 class="mb-0"><strong><p>Home Insurance</p></strong></h3><br/> -->


        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <strong>
            <h5 style="color: white; font-weight: 600;"><a target="_blank" style="color: white"
                href="/assets/upload-forms/burglary-claim-form.doc">BURGLARY CLAIM FORM</a> </h5>
          </strong>
        </div>
        <p></p>
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <strong>
            <h5 style="color: white; font-weight: 600;"><a target="_blank" style="color: white"
                href="/assets/upload-forms/fire-claim-form.docx">FIRE CLAIM FORM</a> </h5>
          </strong>
        </div>
        <p></p>
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <strong>
            <h5 style="color: white; font-weight: 600;"><a target="_blank" style="color: white"
                href="/assets/upload-forms/marine-cargo-claim-form.docx">MARINE CARGO CLAIM FORM</a> </h5>
          </strong>
        </div>
        <p></p>
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <strong>
            <h5 style="color: white; font-weight: 600;"><a target="_blank" style="color: white"
                href="/assets/upload-forms/motor-claim-form.docx">MOTOR CLAIM FORM</a> </h5>
          </strong>
        </div>
        <p></p>
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <strong>
            <h5 style="color: white; font-weight: 600;"><a target="_blank" style="color: white"
                href="/assets/upload-forms/mortgate-term-credit-life-proposal.pdf">Mortgage Term Credit Life Proposal Form</a> </h5>
          </strong>
        </div>
        <p></p>
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <strong>
            <h5 style="color: white; font-weight: 600;"><a target="_blank" style="color: white"
                href="/assets/upload-forms/nsia-group-life-employer-proposal-form.pdf">NSIA Group Life Employer Proposal Form</a>
            </h5>
          </strong>
        </div>
        <p></p>
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <strong>
            <h5 style="color: white; font-weight: 600;"><a target="_blank" style="color: white"
                href="/assets/upload-forms/nsia-individual-proposal-form.pdf">NSIA Individual Proposal form</a>
            </h5>
          </strong>
        </div>
        <p></p>

        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <strong>
            <h5 style="color: white; font-weight: 600;"><a target="_blank" style="color: white"
                href="/assets/upload-forms/DATA_PROTECTION_IMPACT_ASSESSMENT_POLICY.pdf">Data Protection Impact Assessment Policy</a>
            </h5>
          </strong>
        </div>
        <p></p>

        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <strong>
            <h5 style="color: white; font-weight: 600;"><a target="_blank" style="color: white"
                href="/assets/upload-forms/DATA_SUBJECT_ACCESS_REQUEST_FORM.doc">Data Subject Access Request Form</a>
            </h5>
          </strong>
        </div>
        <p></p>


        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <strong>
            <h5 style="color: white; font-weight: 600;"><a target="_blank" style="color: white"
                href="/assets/upload-forms/DATA_SUBJECT_ACCESS_REQUEST_PROCEDURE.pdf">Data Subject Access Procedure</a>
            </h5>
          </strong>
        </div>
        <p></p>








      </div>
    </div>

    <!-- Sidebar -->
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <app-sidebar></app-sidebar>
      <!-- End Fast Facts -->
    </div>
    <!-- End Sidebar -->
  </div>
</div>
