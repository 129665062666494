import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AllRiskProposalForm } from '../Classes/all-risk-proposal-form';
import { BurglaryInsuranceProposalForm } from '../Classes/burglary-insurance-proposal-form';
import { ContractorAllRiskProposalForm } from '../Classes/contractor-all-risk-proposal-form';
import { CustomerClass } from '../Classes/customer-class';
import { CustomerServiceWeekForm } from '../Classes/customer-service-week-form';
import { DebitMandateModel } from '../Classes/debit-mandate-model';
import { EmployersProposalForm } from '../Classes/employers-proposal-form';
import { FidelityProposalForm } from '../Classes/fidelity-proposal-form';
import { FireSpecialPerilProposalForm } from '../Classes/fire-special-peril-proposal-form';
import { GoodsInTransitProposalForm } from '../Classes/goods-in-transit-proposal-form';
import { GroupPersonalAccidentProposalForm } from '../Classes/group-personal-accident-proposal-form';
import { HouseHolderForm } from '../Classes/house-holder-form';
import { MoneyProposalForm } from '../Classes/money-proposal-form';
import { NewMarineCargoProposalForm } from '../Classes/new-marine-cargo-proposal-form';
import { NewMotorProposalForm } from '../Classes/new-motor-proposal-form';
import { NewProposalForm } from '../Classes/new-proposal-form';
import { NewsletterClass } from '../Classes/newsletter-class';
import { PersonalAccidentProposalForm } from '../Classes/personal-accident-proposal-form';
import { PlantAllRiskProposalForm } from '../Classes/plant-all-risk-proposal-form';
import { ProfessionalIndemityProposalForm } from '../Classes/professional-indemity-proposal-form';
import { ProposalForm } from '../Classes/proposal-form';
import { PublicLiabilityProposalForm } from '../Classes/public-liability-proposal-form';
import { QuoteData } from '../Classes/quote-data';
import { UserClass } from '../Classes/user-class';
import { Userdata } from '../Classes/userdata';
import { WhistleClass } from '../Classes/whistle-class';
import { WorkmenInsuranceProposalForm } from '../Classes/workmen-insurance-proposal-form';

@Injectable({
  providedIn: 'root'
})
export class FormserviceService {
  private _loading: boolean = false;
  loadingStatus: any = new Subject();

  get loading(): boolean {
    return this._loading;
  }

  set loading(value) {
    this._loading = value;
    this.loadingStatus.next(value);
  }

  startLoading() {
    this.loading = true;
  }

  stopLoading() {
    this.loading = false;
  }
  private baseUrl = environment.baseUrl + "/NSIAMobile/api";
  constructor(private http: HttpClient) { }

  sendForm(form: Userdata) {
    return this.http.post<any>(this.baseUrl + "/SendForm", form);
  }
  sendProposalForm(form: ProposalForm) {
    return this.http.post<any>(this.baseUrl + "/SendProposalForm", form);
  }
  sendProposalFormIndividualForm(form: NewProposalForm) {
    return this.http.post<any>(this.baseUrl + "/SendProposalIndividualForm", form);
  }
  sendCustomerServiceWeek(form: CustomerServiceWeekForm) {
    return this.http.post<any>(this.baseUrl + "/sendCustomerServiceWeek", form);
  }
  sendMotorProposalForm(form: NewMotorProposalForm) {
    return this.http.post<any>(this.baseUrl + "/sendMotorProposalForm", form);
  }
  sendMarineCargoProposalForm(form: NewMarineCargoProposalForm) {
    return this.http.post<any>(this.baseUrl + "/sendMarineCargoProposalForm", form);
  }
  sendHouseHolderForm(form: HouseHolderForm) {
    return this.http.post<any>(this.baseUrl + "/sendHouseHolderForm", form);
  }

  sendAllRiskProposalForm(form: AllRiskProposalForm) {
    return this.http.post<any>(this.baseUrl + "/sendAllRiskProposalForm", form);
  }

  sendEmployersProposalForm(form: EmployersProposalForm) {
    return this.http.post<any>(this.baseUrl + "/sendEmployersProposalForm", form);
  }
  sendWorkMenInsuranceForm(form: WorkmenInsuranceProposalForm) {
    return this.http.post<any>(this.baseUrl + "/sendWorkMenInsuranceForm", form);
  }
  sendPersonalAccidentProposalForm(form: PersonalAccidentProposalForm) {
    return this.http.post<any>(this.baseUrl + "/sendPersonalAccidentProposalForm", form);
  }
  sendPlantAllRiskProposalForm(form: PlantAllRiskProposalForm) {
    return this.http.post<any>(this.baseUrl + "/sendPlantAllRiskProposalForm", form);
  }
  sendFireandSpecialPerils(form: FireSpecialPerilProposalForm) {
    return this.http.post<any>(this.baseUrl + "/sendFireandSpecialPerils", form);
  }
  sendFidelityProposalForm(form: FidelityProposalForm) {
    return this.http.post<any>(this.baseUrl + "/sendFidelityProposalForm", form);
  }

  sendGoodsIntransitProposalForm(form: GoodsInTransitProposalForm) {
    return this.http.post<any>(this.baseUrl + "/sendGoodsIntransitProposalForm", form);
  }
  sendContractorAllRiskProposalForm(form: ContractorAllRiskProposalForm) {
    return this.http.post<any>(this.baseUrl + "/sendContractorAllRiskProposalForm", form);
  }

  sendGroupPersonalAccidentProposalForm(form: GroupPersonalAccidentProposalForm) {
    return this.http.post<any>(this.baseUrl + "/sendGroupPersonalAccidentProposalForm", form);
  }

  sendMoneyProposalForm(form: MoneyProposalForm) {
    return this.http.post<any>(this.baseUrl + "/sendMoneyProposalForm", form);
  }
  //sendBurglaryInsuranceProposalForm
  sendBurglaryInsuranceProposalForm(form: BurglaryInsuranceProposalForm) {
    return this.http.post<any>(this.baseUrl + "/sendBurglaryInsuranceProposalForm", form);
  }
  sendProfessionalIndemityProposalForm(form: ProfessionalIndemityProposalForm) {
    return this.http.post<any>(this.baseUrl + "/sendProfessionalIndemityProposalForm", form);
  }
  sendProposalFormForPublicLiability(form: PublicLiabilityProposalForm) {
    return this.http.post<any>(this.baseUrl + "/SendProposalPublicLiabilityForm", form);
  }
  sendContact(form: UserClass) {
    return this.http.post<any>(this.baseUrl + "/SubmitContact", form);
  }
  blowWhistle(form: WhistleClass) {
    return this.http.post<any>(this.baseUrl + "/BlowWhistle", form);
  }
  sendLifeQuote(form: QuoteData) {
    return this.http.post<any>(this.baseUrl + "/SendQuote", form);
  }
  GetCountries() {
    return this.http.get<any>("assets/json/countries.json");
  }
  GetStates() {
    return this.http.get<any>("assets/json/states.json");
  }
  GetBanks() {
    return this.http.get<any>("assets/json/banks.json");
  }
  sendDebitMandateForm(form: DebitMandateModel) {
    return this.http.post<any>(this.baseUrl + "/DebitMandateForm", form);
  }
  sendNewsletterForm(form: NewsletterClass) {
    return this.http.post<any>(this.baseUrl + "/newsletter", form);
  }
  sendCustomerForm(form: CustomerClass) {
    return this.http.post<any>(this.baseUrl + "/customerform", form);
  }
}
