export class Userdata {
  constructor(
    public EmployerName: string,
    public Surname: string,
    public Firstname: string,
    public Othername: string,
    public StaffNumber: string,
    public DateOfBirth: string,
    public PhoneEmail: string,
    public Gender: string,
    public Minors: Minor[],
    public NextofKins: NextofKin[]
  ) {}
}
export class Minor {
  constructor(
    public MnSurname: string,
    public MnFirstname: string,
    public MnTrustee: string,
    public MnDateOfBirth: string,
    public MnPhoneEmail: string,
  ) { }
}
export class NextofKin {
  constructor(
    public NkSurname: string,
    public NkFirstname: string,
    public NkRelationship: string,
    public NkProportion: string,
    public NkDateOfBirth: string,
    public PhoneEmail: string,
  ) { }
}
