import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { LoginService } from 'src/app/login/login.service';
@Component({
  selector: 'app-loginprivate',
  templateUrl: './loginprivate.component.html',
  styleUrls: ['./loginprivate.component.css']
})
export class LoginprivateComponent {
  public loginForm!: FormGroup
  private _url: string = "../../../assets/login.json"
  constructor(private formbuilder: FormBuilder, private http: HttpClient, private router: Router, private loginServe: LoginService) { }

  ngOnInit(): void {
    this.loginForm = this.formbuilder.group({
      username: [''],
      password: ['', Validators.required]
    })
  }

  login() {
    this.loginServe.get()
      .subscribe(res => {
        const user = res.find((a: any) => {
          return a.username === this.loginForm.value.username && a.password === this.loginForm.value.password
        });
        if (user) {
          alert('Login Succesful');
          this.loginForm.reset()
          this.router.navigate(["/option-edit"])
        } else {
          alert("Invalid Login Details")
        }
      }, err => {
        alert("Something went wrong")
      })
  }
}
