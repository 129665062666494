

<!-- <section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px">
        <div class="container" >
          <div class="d-sm-flex text-center">
            <div class="align-self-center">
              <h2 class="h3 g-font-weight-300 w-100 g-mb-10 g-mb-0--md" ><p>Career</p></h2>
            </div>


          </div>
        </div>
      </section> -->
      <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
        <div class="container">
          <ul class="d-flex justify-content-between u-list-inline">
            <li class="list-inline-item g-mr-15">
              <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
              <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
            </li>
            <li class="list-inline-item g-color-primary">
                    <span>NollyWood Connect</span>
                  </li>

            <li class="list-inline-item ml-auto">
              <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
            </li>
          </ul>
        </div>
      </section>


      <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
            <div class="row">
              <div class="col-lg-9 g-mb-30">
                    <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
                      <div class="col-md-3 col-lg-3">
                        <img class="img-fluid" src="assets/img/logo/nc.png" alt="Image Description">
                      </div>
                      <br/><br/>
                            <h3 class="mb-0"><strong><p>Nollywood Connect </p></strong></h3><br/>

                            <!-- Clients Block-->

                                    <p>We are glad to announce our strategic partnership with Nollywood Connect, to offer NSIA Value Deal to their subscribers. The NSIA Value Deal is a product combination that will provide insurance covers for Nollywood Connect subscribers at affordable rates.</p>
                                    <p></p>
                                    <p>Nollywood Connect and NSIA Insurance share a common goal of providing first-class services that enable customers and subscribers take constructive steps to secure their future and that of their loved ones.</p>
                                    <p></p>
                                    <p>Through this partnership, Nollywood Connect subscribers can enjoy specific NSIA Insurance products like: <strong>NSIA Group Life Insurance, NSIA Personal Accident Insurance, NSIA Comprehensive Motor Insurance, NSIA Motor Insurance- Third Party Fire & Theft and NSIA Householder Insurance</strong>, guaranteed to bring value to them.</p>
                                    <p></p>
                                    <p>Nollywood Connect is a new and innovative online registry that allows filmmakers conduct casting calls online by linking Actors, Directors, Screenwriters, and Producers. The online registry allows creatives upload their portfolio on the platform in a bid to expand traditional scouting methods and reduce lengthy casting lineups, as well as time consuming open auditions</p>
                                    <p></p>
                                    <p>By registering with Nollywood Connect, subscribers will also have access to exclusive benefits including a variety of trainings, online resources and funding opportunities.</p>
                                    <p></p>
                                    <p>To register, visit <a href="https://www.nollywoodconnect.com" target="_blank">www.nollywoodconnect.com</a>:</p>
                                    <p></p>

                                    <ul>
                                      <li>Select your category (actor, director, producer, writer, crew) </li>
                                      <li>Confirm identity via email</li>
                                      <li>Complete profile –edit your profile and upload clips</li>
                                      <li>Activate your registration</li>

                                    </ul>

                                    <p></p>
                                    <p>This is an avenue for more talents to be discovered and now it is only a click away with Nollywood Connect!</p>

                        </div>
                            </div>
              <!-- Sidebar -->
              <div class="col-lg-3 g-mb-30">
                <!-- Links -->
                <app-sidebar></app-sidebar>
                <!-- End Fast Facts -->
              </div>
              <!-- End Sidebar -->
            </div>
          </div>















