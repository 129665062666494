import { Injectable } from '@angular/core';
import {throwError as observableThrowError, Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MakeModelService {
  private baseUrl = environment.baseUrl + "/NSIAMobile/api";
  constructor(private http:HttpClient) { }

  getModelold():Observable<any>
{
  return this.http.get("/assets/make-model.json").pipe(
        map(this.onSuccess),
        catchError(this.onError),)
}
getMake()
{
  let headers = new HttpHeaders({
    'content-type': 'application/json'
  });
  let requestOptions = {
    headers:headers
  };

  // return this.http.get(this.url.getUrl() + "api/GetMake", requestOptions)
  return this.http.get(this.baseUrl + "/GetMake",requestOptions).pipe(
        map(this.onSuccess),
        catchError(this.onError),)
}
getModel(makeid)
{
  let headers = new HttpHeaders({
    'content-type': 'application/json'
  });
  let requestOptions = {
    headers:headers
  };
  let serviceBody=
  {
    id:makeid
  }
   let body = JSON.stringify(serviceBody);
        // return this.http.post(this.url.getUrl() + "api/GetModel",body,requestOptions)
        return this.http.post(this.baseUrl + "/GetModel",body,requestOptions).pipe(
        map(this.onSuccess),
        catchError(this.onError),)
}
getstate()
{
  let headers = new HttpHeaders({
    'content-type': 'application/json'
  });
  let requestOptions = {
    headers:headers
  };
  headers.append('content-type', 'application/json');

        // return this.http.get(this.url.getUrl() + "api/GetState",requestOptions)
        return this.http.get(this.baseUrl + "/GetState",requestOptions).pipe(
        map(this.onSuccess),
        catchError(this.onError),)
}
getLGA(stateid)
{
  let headers = new HttpHeaders({
    'content-type': 'application/json'
  });
  let requestOptions = {
    headers:headers
  };
  let serviceBody=
  {
    stateid:stateid
  }
   let body = JSON.stringify(serviceBody);
        // return this.http.post(this.url.getUrl() + "api/GetLga",body,requestOptions)
        return this.http.post(this.baseUrl + "/GetLga",body,requestOptions).pipe(
        map(this.onSuccess),
        catchError(this.onError),)
}
private onSuccess(res: Response) {
  return res.json();
}

private onError(res:Response){
  return observableThrowError(res.json() || 'Server Error');
}
}
