import { Component, OnInit,ViewContainerRef,ViewChild } from '@angular/core';
import {NgForm} from '@angular/forms'
import { NgProgressComponent } from 'ngx-progressbar';
import { MainserviceService } from 'src/app/Services/mainservice.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.css']
})
export class QuoteComponent implements OnInit {
  quoteData=
  {
   email:'',
   phoneno:'',
   bizclass:'',
  }
  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;
  constructor(public mainService:MainserviceService,public alert:ToastrService,viewContainerRef:ViewContainerRef) { }

  ngOnInit(): void {
  }

  Onquotesubmit(myForm:NgForm)
  {
  this.progressBar.start();
  if(myForm.valid)
  {

  let quotedetails=
    {
    email:this.quoteData.email,
    phoneno:this.quoteData.phoneno,
    businessclass:this.quoteData.bizclass,
    }
    this.mainService.Postquote(quotedetails).
    subscribe(result=>
    {
      result.then(
        (data) => {
          console.log(result);
          if(data.statusCode == "00")
          {
           this.alert.success("Sucessfully Submitted","Success")
           this.progressBar.complete();
          }

        }
      )


     },
     error=>
      {// console.log(result);
        //this.router.navigate(['/'])
        this.alert.error(error.responseDescription,"Error!")
        this.progressBar.complete();
        //this.disableButton=false;
     },
    )}
  }

}
