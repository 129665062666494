<br>
<br>
<br>
<br>
<br>
<mat-carousel class="mat-carousel" timings="1000ms ease-in" [autoplay]="true" interval="5000" color="accent"
  maxWidth="auto" proportion="25" [loop]="true" [hideArrows]="false" [hideIndicators]="true" [useKeyboard]="true"
  [useMouseWheel]="false" orientation="ltr">
  <mat-carousel-slide class="mat-carousel" #matCarouselSlide *ngFor="let slide of Slider; let i = index"
    [image]="slide.image" overlayColor="#00000040" [hideOverlay]="false"></mat-carousel-slide>
</mat-carousel>
<!-- END REVOLUTION SLIDER -->
<section class="h-e-p-50">
  <div class="container">
    <div class="row no-gutters">
      <div class="col-lg-3 cta-item g-px-40 g-mb-50 g-mb-0--lg">
        <!-- Icon Blocks -->
        <div class="text-center">
          <span
            class="d-inline-block u-icon-v3 u-icon-size--xl u-icon-effect-v3--hover g-bg-primary g-color-white rounded-circle g-mb-30">
            <i class="fa fa-shopping-cart"></i>
          </span>
          <h3 class="h5 g-color-gray-dark-v2 g-font-weight-600 text-uppercase mb-3">
            ONLINE SHOP
          </h3>
          <p class="mb-0">
            Visit our online shop to buy any of our retail products on the go.
          </p>
          <br />
          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
            href="https://onlineshop.nsiainsurance.com" target="_blank">
            Visit now
          </a>
        </div>
        <!-- End Icon Blocks -->
      </div>
      <div class="col-lg-3 cta-item g-brd-left--lg g-brd-gray-light-v4 g-px-40 g-mb-50 g-mb-0--lg">
        <!-- Icon Blocks -->
        <div class="text-center">
          <span
            class="d-inline-block u-icon-v3 u-icon-size--xl u-icon-effect-v3--hover g-bg-primary g-color-white rounded-circle g-mb-30">
            <i class="fa fa-folder-open"></i>
          </span>
          <h3 class="h5 g-color-gray-dark-v2 g-font-weight-600 text-uppercase mb-3">
            Report claim
          </h3>
          <p class="mb-0">Take easy steps to report a claim today.</p>
          <br />
          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
            routerLink="/reportclaim">
            Report Claim
          </a>
        </div>
        <!-- End Icon Blocks -->
      </div>
      <div class="col-lg-3 cta-item g-brd-left--lg g-brd-gray-light-v4 g-px-40">
        <!-- Icon Blocks -->
        <div class="text-center">
          <span
            class="d-inline-block u-icon-v3 u-icon-size--xl u-icon-effect-v3--hover g-bg-primary g-color-white rounded-circle g-mb-30">
            <i class="fa fa-cc-mastercard"></i>
          </span>
          <h3 class="h5 g-color-gray-dark-v2 g-font-weight-600 text-uppercase mb-3">
            Renew Existing Policy
          </h3>
          <p class="mb-0">You can now renew your existing policy online.</p>
          <br />
          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
            routerLink="/flutter-pay">
            click here to Renew
          </a>
        </div>
        <!-- End Icon Blocks -->
      </div>

      <div class="col-lg-3 cta-item g-brd-left--lg g-brd-gray-light-v4 g-px-40">
        <div class="login-home-wrapper">
          <div class="login-header">Customer Portal</div>
          <app-tabs>
            <app-tab [tabTitle]="'Life Business'">
              <div class="login-portal">
                <a href="https://portal.nsiainsurance.com/Account/LifeLogin" target="_blank"
                  class="btn btn-block btn-login">Login</a>
              </div>
            </app-tab>
            <app-tab tabTitle="General Business">
              <div class="login-portal">
                <a href="https://portal.nsiainsurance.com/Account/NonLifeLogin" target="_blank"
                  class="btn btn-block btn-login">Login</a>
              </div>
            </app-tab>
          </app-tabs>
        </div>
        <!-- Icon Blocks -->
        <!-- <div class="text-center"> -->
        <!-- <span
               class="d-inline-block u-icon-v3 u-icon-size--xl u-icon-effect-v3--hover g-bg-primary g-color-white rounded-circle g-mb-30">
               <i class="fa fa-certificate"></i>
               </span>
               <h3 class="h5 g-color-gray-dark-v2 g-font-weight-600 text-uppercase mb-3">Get certificate</h3>
               <p class="mb-0">You can download your motor certificate now.</p>
               <br />
               <a  class="disabled g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
               >
               Download

               </a> -->
        <!-- <div class="login-home-wrapper">
               <div class="login-header">
                 Customer Portal
               </div>
               <div class="login-portal">
                 <a href="#" class="btn btn-block btn-login">Login</a>
               </div>
               </div>

                   </div> -->
        <!-- End Icon Blocks -->
      </div>

    </div>
  </div>
</section>
<div class="g-bg-secondary">
  <div class="container g-py-100">
    <!-- Heading -->
    <div class="text-center g-mb-70">
      <h2 class="g-font-weight-400">
        <p>Our Products</p>
      </h2>
      <p class="g-font-size-16">
        Our products are the best and we are the preferred insurance providers.
      </p>
    </div>
    <div class="js-carousel g-mb-40" data-autoplay="false" data-infinite="true" data-slides-show="4"
      data-arrows-classes="u-arrow-v1 g-pos-abs g-top-100x g-width-50 g-height-50 g-rounded-50x g-font-size-12 g-theme-color-gray-light-v11 g-theme-bg-gray-light-v5 g-theme-bg-gray-light-v12--hover g-mt-40"
      data-arrow-left-classes="fa fa-chevron-left g-left-0" data-arrow-right-classes="fa fa-chevron-right g-right-0"
      data-responsive='[{
         "breakpoint": 1200,
         "settings": {
         "slidesToShow": 4
         }
         }, {
         "breakpoint": 992,
         "settings": {
         "slidesToShow": 2
         }
         }, {
         "breakpoint": 576,
         "settings": {
         "slidesToShow": 1
         }
         }]'>
      <div class="js-slide g-px-7">
        <!-- Article -->
        <article class="u-shadow-v28 g-bg-white">
          <img class="img-fluid w-100"
            src="https://res.cloudinary.com/heazyconcept/image/upload/v1613638431/Nsia/CarInsurance2_obyvdg.jpg"
            alt="Image Description" />
          <div class="g-pos-rel">
            <!-- SVG Background -->
            <svg class="g-pos-abs g-left-0 g-right-0" version="1.1" preserveAspectRatio="none" width="100%"
              height="140px" viewBox="20 -20 300 100" style="top: -35%">
              <path d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729
                        c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z" opacity="0.4" fill="#f0f1f3" />
              <path d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729
                        c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z" opacity="0.4"
                fill="#f0f1f3" />
              <path d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716
                        H42.401L43.415,98.342z" opacity="0" fill="#fff" />
              <path d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
                        c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z"
                fill="#fff" />
            </svg>
            <!-- End SVG Background -->
            <div class="g-pos-rel g-z-index-1 g-pa-30">
              <h3 class="h5 mb-3">
                <a class="u-link-v5 g-color-main g-color-primary--hover"
                  routerLink="/motor-insurance-company-in-lagos-nigeria">Car Insurance</a>
              </h3>
              <!-- <p class="g-color-gray-dark-v4">Car accidents happen even to the safest drivers. Car repair is costly, and
                        in a total loss, you can be facing financial disaster. Car insurance protects your investment and keeps
                        you moving so you can get on...</p> -->
              <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                routerLink="/motor-insurance-company-in-lagos-nigeria">
                Read more
              </a>
            </div>
          </div>
        </article>
      </div>
      <div class="js-slide g-px-7">
        <article class="u-shadow-v28 g-bg-white">
          <img class="img-fluid w-100"
            src="https://res.cloudinary.com/heazyconcept/image/upload/v1613638418/Nsia/marine2_ckeam9.jpg"
            alt="Image Description" />
          <div class="g-pos-rel">
            <!-- SVG Background -->
            <svg class="g-pos-abs g-left-0 g-right-0" version="1.1" preserveAspectRatio="none" width="100%"
              height="140px" viewBox="20 -20 300 100" style="top: -35%">
              <path d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729
                        c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z" opacity="0.4" fill="#f0f1f3" />
              <path d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729
                        c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z" opacity="0.4"
                fill="#f0f1f3" />
              <path d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716
                        H42.401L43.415,98.342z" opacity="0" fill="#fff" />
              <path d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
                        c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z"
                fill="#fff" />
            </svg>
            <!-- End SVG Background -->
            <div class="g-pos-rel g-z-index-1 g-pa-30">
              <h3 class="h5 mb-3">
                <a class="u-link-v5 g-color-main g-color-primary--hover"
                  routerLink="/marine-insurance-company-in-lagos-nigeria">Marine Insurance</a>
              </h3>
              <!-- <p class="g-color-gray-dark-v4">Marine Cargo Insurance Indemnifies the Insured against financial loss
                        suffered as a result of loss or damage to goods being transported from the point of origin to final
                        destination either by sea or air.</p> -->
              <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                routerLink="/marine-insurance-company-in-lagos-nigeria">
                Read more
              </a>
            </div>
          </div>
        </article>
      </div>
      <div class="js-slide g-px-7">
        <article class="u-shadow-v28 g-bg-white">
          <img class="img-fluid w-100"
            src="https://res.cloudinary.com/heazyconcept/image/upload/v1613638429/Nsia/business2_jnbakz.jpg"
            alt="Image Description" />
          <div class="g-pos-rel">
            <!-- SVG Background -->
            <svg class="g-pos-abs g-left-0 g-right-0" version="1.1" preserveAspectRatio="none" width="100%"
              height="140px" viewBox="20 -20 300 100" style="top: -35%">
              <path d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729
                        c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z" opacity="0.4" fill="#f0f1f3" />
              <path d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729
                        c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z" opacity="0.4"
                fill="#f0f1f3" />
              <path d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716
                        H42.401L43.415,98.342z" opacity="0" fill="#fff" />
              <path d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
                        c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z"
                fill="#fff" />
            </svg>
            <!-- End SVG Background -->
            <div class="g-pos-rel g-z-index-1 g-pa-30">
              <h3 class="h5 mb-3">
                <a class="u-link-v5 g-color-main g-color-primary--hover"
                  routerLink="/business-insurance-company-in-lagos-nigeria">Business Insurance</a>
              </h3>
              <!-- <p class="g-color-gray-dark-v4">Your company is your livelihood, so protecting it is smart business. Find
                        out how to put our risk-mitigation experience and products to work for you with NSIA Business Insurance
                        Service.</p> -->
              <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                routerLink="/business-insurance-company-in-lagos-nigeria">
                Read more
              </a>
            </div>
          </div>
        </article>
      </div>
      <div class="js-slide g-px-7">
        <article class="u-shadow-v28 g-bg-white">
          <img class="img-fluid w-100"
            src="https://res.cloudinary.com/heazyconcept/image/upload/v1613638416/Nsia/lifeinsured2_w28ws3.jpg"
            alt="Image Description" />
          <div class="g-pos-rel">
            <!-- SVG Background -->
            <svg class="g-pos-abs g-left-0 g-right-0" version="1.1" preserveAspectRatio="none" width="100%"
              height="140px" viewBox="20 -20 300 100" style="top: -35%">
              <path d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729
                        c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z" opacity="0.4" fill="#f0f1f3" />
              <path d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729
                        c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z" opacity="0.4"
                fill="#f0f1f3" />
              <path d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716
                        H42.401L43.415,98.342z" opacity="0" fill="#fff" />
              <path d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
                        c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z"
                fill="#fff" />
            </svg>
            <!-- End SVG Background -->
            <div class="g-pos-rel g-z-index-1 g-pa-30">
              <h3 class="h5 mb-3">
                <a class="u-link-v5 g-color-main g-color-primary--hover"
                  routerLink="/life-insurance-company-in-lagos-nigeria">Life Insurance</a>
              </h3>
              <!-- <p class="g-color-gray-dark-v4">Life insurance isn’t a luxury—it’s a necessity. Experience the peace of
                        mind that comes from knowing your family would still be protected if you weren’t there to care for them
                        financially.</p> -->
              <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                routerLink="/life-insurance-company-in-lagos-nigeria">
                Read more
              </a>
            </div>
          </div>
        </article>
      </div>
      <div class="js-slide g-px-7">
        <article class="u-shadow-v28 g-bg-white">
          <img class="img-fluid w-100"
            src="https://res.cloudinary.com/heazyconcept/image/upload/v1613638407/Nsia/fireimg2_kpgfvx.jpg"
            alt="Image Description" />
          <div class="g-pos-rel">
            <svg class="g-pos-abs g-left-0 g-right-0" version="1.1" preserveAspectRatio="none" width="100%"
              height="140px" viewBox="20 -20 300 100" style="top: -35%">
              <path d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729
                        c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z" opacity="0.4" fill="#f0f1f3" />
              <path d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729
                        c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z" opacity="0.4"
                fill="#f0f1f3" />
              <path d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716
                        H42.401L43.415,98.342z" opacity="0" fill="#fff" />
              <path d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
                        c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z"
                fill="#fff" />
            </svg>
            <div class="g-pos-rel g-z-index-1 g-pa-30">
              <h3 class="h5 mb-3">
                <a class="u-link-v5 g-color-main g-color-primary--hover"
                  routerLink="/fire-insurance-company-in-lagos-nigeria">Fire Insurance</a>
              </h3>
              <!-- <p class="g-color-gray-dark-v4">The Fire policy is design to provide financial compensation to the Insured
                        as a result of loss or damage to an insured building or stock by fire and some other named perils like
                        riot and bush burning. </p> -->
              <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                routerLink="/fire-insurance-company-in-lagos-nigeria">
                Read more
              </a>
            </div>
          </div>
        </article>
      </div>
    </div>
    <div class="js-slide g-px-7">
      <article class="u-shadow-v28 g-bg-white">
        <img class="img-fluid w-100"
          src="https://res.cloudinary.com/heazyconcept/image/upload/v1613638407/Nsia/fireimg2_kpgfvx.jpg"
          alt="Image Description" />
        <div class="g-pos-rel">
          <svg class="g-pos-abs g-left-0 g-right-0" version="1.1" preserveAspectRatio="none" width="100%" height="140px"
            viewBox="20 -20 300 100" style="top: -35%">
            <path d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729
                    c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z" opacity="0.4" fill="#f0f1f3" />
            <path d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729
                    c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z" opacity="0.4" fill="#f0f1f3" />
            <path d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716
                    H42.401L43.415,98.342z" opacity="0" fill="#fff" />
            <path d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
                    c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z" fill="#fff" />
          </svg>
          <div class="g-pos-rel g-z-index-1 g-pa-30">
            <h3 class="h5 mb-3">
              <a class="u-link-v5 g-color-main g-color-primary--hover"
                routerLink="/fire-insurance-company-in-lagos-nigeria">Fire Insurance</a>
            </h3>
            <!-- <p class="g-color-gray-dark-v4">The Fire policy is design to provide financial compensation to the Insured
                    as a result of loss or damage to an insured building or stock by fire and some other named perils like
                    riot and bush burning. </p> -->
            <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
              routerLink="/fire-insurance-company-in-lagos-nigeria">
              Read more
            </a>
          </div>
        </div>
      </article>
    </div>
  </div>
</div>
<div id="news-section" class="g-bg-secondary g-py-500">
  <div class="container">
    <!-- Heading -->
    <div class="g-max-width-550 text-center mx-auto g-mb-70">
      <h3 class="h3 mb-5">
        <p>Learn more on our life insurance products.</p>
      </h3>
    </div>
    <!-- End Heading -->
    <div class="row g-mx-minus-25 g-mb-50">
      <div class="col-lg-6 g-px-25 g-mb-50">
        <!-- Blog Grid Modern Blocks -->
        <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
          <div class="col-sm-6 g-bg-white g-rounded-left-5">
            <div class="g-pa-35">
              <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer"
                  routerLink="/education-plan-insurance-company-in-lagos-nigeria">
                  <p>NSIA Education Endowment</p>
                </a>
              </h2>
              <p class="g-color-gray-dark-v4 g-line-height-1_8 mb-4">
                NSIA Education Endowment Product serves as a vehicle for saving
                towards future education of the children. The policy also
                supports the child in the event of early death of the parent. It
                is the desire of every parent to provide their children with
                quality education...
              </p>
              <ul class="list-inline g-font-size-12 mb-0">
                <li class="list-inline-item g-mb-10">
                  <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                    routerLink="/education-plan-insurance-company-in-lagos-nigeria">Read more</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
            data-bg-img-src="https://res.cloudinary.com/heazyconcept/image/upload/v1613638533/Nsia/education_d93div.jpg">
          </div>
        </article>
        <!-- End Blog Grid Modern Blocks -->
      </div>
      <div class="col-lg-6 g-px-25 g-mb-50">
        <!-- Blog Grid Modern Blocks -->
        <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
          <div class="col-sm-6 g-bg-white g-rounded-left-5">
            <div class="g-pa-35">
              <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer"
                  routerLink="/anticipated-insurance-company-in-lagos-nigeria">
                  <p>NSIA Anticipated Endowment</p>
                </a>
              </h2>
              <p class="g-color-gray-dark-v4 g-line-height-1_8 mb-4">
                NSIA Anticipated Endowment Plan is a unique product that
                provides a combination of life insurance protection and savings
                in a flexible manner. The policyholder withdraws certain
                percentage of the guaranteed benefit (sum assured) during the
                currency
                <!-- of the policy and still collects 100% of the original sum assured at the end of the policy term-->...
              </p>
              <ul class="list-inline g-font-size-12 mb-0">
                <li class="list-inline-item g-mb-10">
                  <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                    routerLink="/anticipated-insurance-company-in-lagos-nigeria">Read more</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
            data-bg-img-src="https://res.cloudinary.com/heazyconcept/image/upload/v1613638531/Nsia/anticipated_skqiac.jpg">
          </div>
        </article>
        <!-- End Blog Grid Modern Blocks -->
      </div>
      <div class="w-100"></div>
      <div class="col-lg-6 g-px-25 g-mb-50">
        <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
          <div class="col-sm-6 g-bg-white g-rounded-left-5">
            <div class="g-pa-35">
              <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer"
                  routerLink="/health-insurance-company-in-lagos-nigeria">
                  <p>NSIA Health Insurance Plan</p>
                </a>
              </h2>
              <p class="g-color-gray-dark-v4 g-line-height-1_8 mb-4">
                NSIA Health Insurance is in collaboration with leading African
                insurance companies and one of the largest health insurers in
                the world....
              </p>
              <ul class="list-inline g-font-size-12 mb-0">
                <li class="list-inline-item g-mb-10">
                  <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                    routerLink="/health-insurance-company-in-lagos-nigeria">Read more</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
            data-bg-img-src="https://res.cloudinary.com/heazyconcept/image/upload/v1613638532/Nsia/doctor_k62lfm.jpg">
          </div>
        </article>
      </div>
      <div class="col-lg-6 g-px-25 g-mb-50">
        <!-- Blog Grid Modern Blocks -->
        <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">
          <div class="col-sm-6 g-bg-white g-rounded-left-5">
            <div class="g-pa-35">
              <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer"
                  routerLink="/mortgage-insurance-company-in-lagos-nigeria">NSIA Mortgage Protection Assurance</a>
              </h2>
              <p class="g-color-gray-dark-v4 g-line-height-1_8 mb-4">
                Everyone wishes to have a home. While a few manage to save
                towards a new home, majority obtain mortgage loans to meet their
                needs. NSIA Family Shield is a Mortgage Protection Product that
                shields away the dependants from
                <!--the hassles of mortgage debts. It keeps the home in the family-->...
              </p>
              <ul class="list-inline g-font-size-12 mb-0">
                <li class="list-inline-item g-mb-10">
                  <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                    routerLink="/mortgage-insurance-company-in-lagos-nigeria">Read More</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"
            data-bg-img-src="https://res.cloudinary.com/heazyconcept/image/upload/v1613638530/Nsia/protection2_t36cwt.jpg">
          </div>
        </article>
        <!-- End Blog Grid Modern Blocks -->
      </div>
    </div>
    <!-- Begin Mailchimp Signup Form -->
    <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css" />
    <style type="text/css">
      #mc_embed_signup {
        background: #fff;
        clear: left;
        font: 14px Helvetica, Arial, sans-serif;
      }

      /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
         We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
    </style>
    <div id="mc_embed_signup">
      <form novalidate #f="ngForm" (ngSubmit)="OnContactSubmit()">
        <div id="mc_embed_signup_scroll">
          <h2>Subscribe to our newsletter</h2>
          <div class="indicates-required">
            <span class="asterisk">*</span> indicates required
          </div>
          <div class="mc-field-group">
            <label for="mce-EMAIL">Email Address <span class="asterisk">*</span>
            </label>
            <!-- <input id="email" name="email" class="form-control form-control-md rounded-0" type="email"
                  placeholder="Required" [(ngModel)]="userData.email" required> -->
            <input type="email" value="" name="EMAIL" [(ngModel)]="userData.email" class="required email"
              id="mce-EMAIL" />
          </div>
          <div class="mc-field-group">
            <label for="mce-FNAME">First Name </label>
            <input type="text" value="" name="FNAME" [(ngModel)]="userData.fname" class="" id="mce-FNAME" />
          </div>
          <div id="mce-responses" class="clear">
            <div class="response" id="mce-error-response" style="display: none"></div>
            <div class="response" id="mce-success-response" style="display: none"></div>
          </div>
          <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
          <div style="position: absolute; left: -5000px" aria-hidden="true">
            <input type="text" name="b_66e763194a167dbd985b3893f_75a11cad7f" tabindex="-1" value="" />
          </div>
          <div class="clear">
            <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button" />
          </div>
        </div>
      </form>
    </div>
    <!--End mc_embed_signup-->
    <!-- <div class="text-center">
         <a class="btn u-btn-outline-primary g-font-weight-600 g-font-size-12 g-rounded-30 g-py-15 g-px-35" routerLink="/buymotor">Buy motor insurance</a>
         </div> -->
  </div>
</div>
<!-- <section class="g-bg-secondary g-py-100">
   <div class="container">
       <header class="text-center g-width-60x--md mx-auto g-mb-60">
           <div class="u-heading-v2-3--bottom g-brd-primary g-mb-20">
             <h2 class="h3 u-heading-v2__title g-color-gray-dark-v2 text-uppercase g-font-weight-600">Testimonials</h2>
           </div>

         </header>
     <div class="row">
       <div class="col-md-6 g-mb-50 g-mb-0--lg">
          Testimonials -->
<!-- <blockquote class="lead u-blockquote-v1 rounded g-pl-60 g-pr-30 g-py-30 g-mb-40"><p>NSIA does a great job providing comprehensive cover on vehicles,they pay claims. I know because i have been a client since 2014 and have recommended a number of clients who have enjoyed excellent services.</p></blockquote>
   <div class="media">
       <div class="media-body align-self-center">
           <h4 class="h6 g-color-gray-dark-v2 g-font-weight-600 g-mb-0">Funsho Omole</h4>

         </div>

   </div> -->
<!-- End Testimonials -->
<!-- </div>
   <div class="col-md-6"> -->
<!-- Testimonials -->
<!-- <blockquote class="lead u-blockquote-v1 rounded g-pl-60 g-pr-30 g-py-30 g-mb-40"><p>A lovely building and serene environment to get all your various classes of insurance products, with state of the art facilities.</p></blockquote>
   <div class="media">
       <div class="media-body align-self-center">
           <h4 class="h6 g-color-gray-dark-v2 g-font-weight-600 g-mb-0">EBOA A</h4>

         </div>
   </div> -->
<!-- End Testimonials -->
<!-- </div>
   </div>
   </div>
   </section> -->
<!-- <div class="g-bg-img-hero g-bg-pos-top-center g-bg-size-cover g-bg-cover g-bg-white-gradient-opacity-v5--after" style="background-image: url(assets/img-temp/1920x800/sideimage.jpg);">
   <div class="container g-pos-rel g-z-index-1">
     <div class="row justify-content-between">
       <div class="col-md-5 g-py-100">
         <div class="mb-5">
         <h2 class="mb-4">More about NSIA</h2>
         <p class="g-font-size-16 g-line-height-2">At NSIA, we believe that providing the best risk-management for companies and individuals comes from our expert knowledge of the industries in which we operate combined with our dedication to looking out for our clients’ interests, always.</p>
         </div>
         <a class="btn u-btn-black g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover g-font-weight-600 g-font-size-12 text-uppercase g-px-25 g-py-13" routerLink="/aboutus">
          Read More
           <i class="g-pos-rel g-top-minus-1 ml-2 fa fa-angle-right"></i>
         </a>
       </div>
     </div>
   </div>
   </div> -->