import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-to-maintain-your-car',
  templateUrl: './how-to-maintain-your-car.component.html',
  styleUrls: ['./how-to-maintain-your-car.component.css']
})
export class HowToMaintainYourCarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
