

   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;"><strong>WHAT TO DO IF YOU GET IN A CAR ACCIDENT
                </strong></p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/dec/Accident.jpeg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
            <p></p>
                 <p>We take safety precautions on the road, like using seatbelts, keeping a safe distance from other vehicles and maintaining our vehicles regularly, but sometimes the unexpected happens. If you or someone you know is involved in an accident, or if a car breaks down, use these tips to stay safe and manage the situation. </p>

              </div>
           </div>

          <div class="row justify-content-between">


              <div class="col-md-12">
                <p><strong>8 steps to follow if you’ve been in a car accident</strong></p>
                <p></p>

                  <p><strong>Stop</strong><br/>
                     Never leave the scene of an accident. If the other driver leaves, write down their license plate number so the authorities can locate them. Take a picture or video, if necessary.
                  </p>
                  <p></p>
                 <p><strong>Stay calm</strong> <br/>
                  Confirm no one is hurt, then check for damage. You might be pressured at the scene to make snap decisions, but don’t let that happen. Take the time you need to assess the situation.
                 </p>

                 <p></p>
                 <p><strong>Check surroundings. </strong> <br/>
                  Watch for oncoming traffic and turn on your hazard lights to warn them.

                 </p>
                 <p></p>

                 <p><strong>Address injuries.</strong> <br/>
                  Keep injured people warm and don’t move them unless they are in immediate danger.

                 </p>
                 <p></p>
                <p><strong>Gather information.</strong>
                  <br/>
                  Once you know everyone’s okay, or emergency help is on the way, gather information to file an accident report using the collision checklist. Collect information, including:

                </p>
                <ul>
                  <li>•	Each driver’s name, license plate number, insurance company name and policy number</li>
                  <li>•	The types of vehicles involved</li>
                  <li>•	The location of each vehicle</li>
                  <li>•	Names of passengers and witnesses</li>

              </ul>

                <p></p>
                <p><strong>Take pictures</strong> <br/>
                  Include damage to vehicles, all vehicles in the accident, the scene of the accident, the other driver(s) and passengers.

                </p>

                <p> <strong>Move your vehicle.</strong>
                   <br/>
                   If you can do so safely, get it out of the flow of traffic. If it’s too damaged to drive, have it towed.

                </p>

                <p></p>
                <p><strong>Call your insurance provider.</strong> <br/>
                  Inform your insurance provider as soon as possible with the necessary documentation you need to file a claims report.
                </p>

<p></p>
                 <p>Don’t have a motor insurance policy yet? Click here or call 09048418896 to get started today. </p>



              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>