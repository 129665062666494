<div class="g-min-height-300 g-flex-centered g-bg-img-hero g-bg-pos-top-center g-bg-size-cover g-pt-80"
  style="background-image: url(assets/img/banners/Inset_Home.jpg);">
  <div class="container g-pos-rel g-z-index-1">
    <h2 class="h1 text-uppercase g-color-primary g-font-size-40--lg mb-0"><b>NSIA Personal Insurance</b></h2>
    <span class="d-block  g-color-white g-font-weight-1000 g-font-size-20 mb-4"> We are here to support you.</span>
  </div>
</div>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item g-color-primary">
        <span>Personal Insurance</span>
      </li>

      <li class="list-inline-item ml-auto">
        <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
          routerLink="/getquote">Get quote</a>
      </li>
    </ul>
  </div>
</section>


<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
    <div class="col-lg-9 g-mb-30">

      <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
        <!-- <h3 class="mb-0"><strong><p>BUSINESS INSURANCE</p></strong></h3><br/> -->
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white;">House Holder’s Comprehensive </h5>
        </div>
        <p></p>
        <p class="mb-0">Comprehensive house holder’s insurance covers all contents of the building against damage or loss by fire and special perils, theft etc.</p>
        <!-- <p></p>
        <p class="mb-0"> If an accident leads to death or bodily injury that impairs ability to work, the worker and/or
          his family will suffer a financial loss as well as a personal one. Personnel insurance protects your workers
          and safeguards the future of their families.</p> -->
        <br />
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white;">House Owners Comprehensive</h5>
        </div>
        <p></p>
        <p class="mb-0">
          The policy covers the building and accessory components against damage by fire or designated “special perils” as well as theft of the accessories/contents. The sum of the building should be clearly stated, distinct from the sum on the contents to be endorsed to the policy. A schedule of the insured contents must be obtained as part of the policy, and a preliminary survey report should accompany each proposal form.
        </p>
        <p></p>
        <br />
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white">Personal Accident Insurance</h5>
        </div>
        <p></p>
        <p class="mb-0">
          This class of business provides compensation for the insured persons in respect of injuries, death and disablement resulting from accident physical. It can be extended to cover the medical expenses for treatment of injuries.
        </p>

        <br />
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white">Fire & Special Perils Insurance </h5>
        </div>
        <p></p>
        <p class="mb-0">Fire & Special Perils insurance policy provides indemnity for losses or damage resulting from fire or Special perils such as Storm, Flood, Riots and Strikes, Malicious damage, Impact damage and falling aircrafts or aerial devices.
        </p>

        <br />
        <div style="padding: 10px 0px 0.05px 10px ; background-color:#efb225;">
          <h5 style="color:white">Burglary Insurance</h5>
        </div>
        <p></p>
        <p class="mb-0">This class of insurance provides indemnity to the Insured for loss of or damage to insured property following from theft accompanied with forcible or violent entry or exit. It provides compensation for misfortune suffered as a result of theft of any of the insured items.</p>

        <!-- <p class="mb-0">•	Accidental bodily injury to or illness of any person (whether or not death results).</p> -->


      </div>
    </div>

    <!-- Sidebar -->
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <app-sidebar></app-sidebar>
      <!-- End Fast Facts -->
    </div>
    <!-- End Sidebar -->
  </div>
</div>
