import { Component, OnInit, ViewChild } from '@angular/core';
import { Utilities } from 'src/app/Helpers/utilities';
import { ToastrService } from 'ngx-toastr';
import { MainserviceService } from 'src/app/Services/mainservice.service';
import { MakeModelService } from 'src/app/Services/make-model.service';
@Component({
  selector: 'app-buy-motor',
  templateUrl: './buy-motor.component.html',
  styleUrls: ['./buy-motor.component.css']
})
export class BuyMotorComponent implements OnInit {
  @ViewChild('fileinput1') filevariable1: any
  @ViewChild('fileinput1') filevariable2: any
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  vehiclemake: any
  vehicleModel: any
  carMakeTemp: any
  state: any
  lga: any
  docId: string
  myCover: number
  motordetails: any
  disableButton: boolean = false
  chasisErrorMessage: string;
  chasisLengthErrorMessage: string;

  reference: string;
  promoCodeErrorMessage: string;
  promoCode: string;
  OutputData =
    {
      cust_name: '',
      vehicledetail: '',
      amount: 0,
      premiumstring: '',
      cust_id: '',
      txn_ref: '',
      BusinessClass: '',
      hash: '',
      product_id: 6205,
      pay_item_id: 101,
      // site_redirect_url:"https://ips.nsiainsurance.com/NSIAMobile/api/paymentresponse/",
      site_redirect_url: "https://nsiaapi.azurewebsites.net/NSIAMobile/api/paymentresponse",
      currency: 566
    }
  MotorData =
    {
      surname: '',
      firstname: '',
      email: '',
      usertype: '',
      usagetype: '',
      covertype: '',
      duration: '',
      vehiclevalue: '0.0',
      vehicletype: '',
      tracker: false,
      totalprice: 0.00,
      rate: 0.0,
      selectedMake: '',
      selectedMakeText: '',
      selectedModel: '',
      selectedyear: '',
      regno: '',
      chasisno: '',
      engineno: '',
      address: '',
      state: '',
      lga: '',
      phoneNo: '',
      filestringId: '',
      filetypeId: '',
      filenameId: '',
      filestringdoc: '',
      filetypedoc: '',
      filenamedoc: '',

      purchaseDiscount: 0,
      reference: '',
      promoCode: ''


    }
  filenameId: string;
  filetypeId: string;
  base64textStringId: string
  processedImagesId: any = [];
  filestringId: string;
  step: number = 1
  src: string = ""


  constructor(public MakeModel: MakeModelService,
    public mainService: MainserviceService,
    public alert: ToastrService, private _utils: Utilities) { }

  ngOnInit(): void {
  }
  selected(files: FileList) {
    const ext = ["image/jpeg", "image/jpg", "image/png", "image/gif", "image/bmp"]
    let theFile = files[0];
    if (ext.indexOf(theFile.type) == -1) {
      this.alert.error("File format is not accepted", "Error!")
      this.filevariable1.nativeElement.value = ''
      return false;
    }
    if (theFile.size > 4000000) {
      this.alert.error("File size must not be greater than 4mb", "Error!")
      this.filevariable1.nativeElement.value = ''
      return false;
    }
    this._utils.ResizeImage(theFile, 128, 128)
    .subscribe(
      (file : File) =>{
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event) => {
          this.src= reader.result.toString()
          this.MotorData.filenameId= file.name
          this.MotorData.filetypeId= file.type
          this.MotorData.filestringId=this.src.substr(this.src.indexOf(',')+ 1)
        }
      }
    )
  }
  selectedV(files: FileList) {
    const ext = ["image/jpeg", "image/jpg", "image/png", "image/gif", "image/bmp"];
    let theFile = files[0];
    if (ext.indexOf(theFile.type) == -1) {
      this.alert.error("File format is not accepted", "Error!")
      this.filevariable2.nativeElement.value = ''
      return false;
    }
    if (theFile.size > 4000000) {
      this.alert.error("File size must not be greater than 4mb", "Error!")
      this.filevariable2.nativeElement.value = ''
      return false;
    }
    this._utils.ResizeImage(theFile, 128, 128)
    .subscribe(
      (file : File) =>{
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event) => {
          this.src= reader.result.toString()
          this.MotorData.filenamedoc= file.name
          this.MotorData.filetypedoc= file.type
          this.MotorData.filestringdoc=this.src.substr(this.src.indexOf(',')+ 1)
        }
      }
    )

  }

}
