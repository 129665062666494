import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-the-beirut-explosion-importance-of-insurance',
  templateUrl: './the-beirut-explosion-importance-of-insurance.component.html',
  styleUrls: ['./the-beirut-explosion-importance-of-insurance.component.css']
})
export class TheBeirutExplosionImportanceOfInsuranceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
