import { Component, OnInit } from '@angular/core';
import { BoardService } from 'src/app/board/board.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-editboard',
  templateUrl: './editboard.component.html',
  styleUrls: ['./editboard.component.css']
})
export class EditboardComponent implements OnInit {
  editForm: FormGroup;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private boardService: BoardService, private fb: FormBuilder) { }

  ngOnInit() {
    this.updateStudentData();
    const id = this.route.snapshot.paramMap.get('id');
    this.boardService
      .GetStudent(id)
      .valueChanges()
      .subscribe((data) => {
        this.editForm.setValue(data);
      });
  }

  get image() {
    return this.editForm.get('image')
  }
  get name() {
    return this.editForm.get('name')
  }
  get title() {
    return this.editForm.get('title')
  }
  get abouta() {
    return this.editForm.get('abouta')
  }
  get aboutb() {
    return this.editForm.get('aboutb')
  }
  get aboutc() {
    return this.editForm.get('aboutc')
  }
  get aboutd() {
    return this.editForm.get('aboutd')
  }
  get aboute() {
    return this.editForm.get('aboute')
  }


  updateStudentData() {
    this.editForm = this.fb.group({
      image: "",
      name: "",
      title: "",
      abouta: "",
      aboutb: "",
      aboutc: "",
      aboutd: "",
      aboute: ""
    });
  }
  updateForm() {
    this.boardService.editBo(this.editForm.value);

    this.router.navigate(['/access-to-board']);
  }
}

