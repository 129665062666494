import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';;
import { SliderService } from 'src/app/slider/slider.service';

@Component({
  selector: 'app-add-slide',
  templateUrl: './add-slide.component.html',
  styleUrls: ['./add-slide.component.css']
})
export class AddSlideComponent implements OnInit {
  public sliderForm: FormGroup;

  constructor(private router: Router, private sliderService: SliderService, public fb: FormBuilder,) {
  }

  ngOnInit(): void {
    this.sliderService.GetStudentsList();

    this.slidForm()
  }

  slidForm() {

    this.sliderForm = this.fb.group({
      image: "",

    })
  }
  get image() {
    return this.sliderForm.get('image')
  }
  create() {

    this.sliderService.addMa(this.sliderForm.value)

    this.router.navigate(["/access-to-slider"])

  }


}
