
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;"><strong>TIPS ON HOW TO MAINTAIN YOUR CAR</strong></p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/car_maintenace.jpg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>"Another flaw in human character is that everybody wants to build and own good things but nobody wants to do maintenance." - Kurt Vonnegut </p>
                 <p></p>
                 <p>These words are indeed true because for most of us, our cars are one of the most expensive assets we own and we rely on them on a daily basis, but we fail to inculcate a habit of carrying out simple maintenance procedures as often as possible. It is important that we know the basics of car maintenance to save us a lot of money on repairs and replacement of parts.</p>
                 <p></p>
              </div>
           </div>

          <div class="row justify-content-between">


              <div class="col-md-12">


               <p><strong>1. Check the oil: </strong><br/>It important to check that your car has the right type and amount of oil required. A frequent check is important because an oil and filter change helps to keep away particles from the engine. You can check the oil level using the dipstick and please ensure that your car is turned off while doing it.
               </p>

               <p></p>
                <p><strong>2. Test your car battery</strong><br/>
                  The average life of a car battery is about three years if well maintained. To sustain and even extend your battery life, you should test the battery voltage using a car battery tester. If it's below an optimal level, make sure to consult your mechanic.
                </p>
              <p></p>
                 <p><strong>3. Top up your car fluids</strong> <br/>
                   It's important to check the level of your car's water or coolant to avoid overheating.
                 </p>

                 <p></p>
                 <p><strong>4. Check the tyre pressure: </strong> <br/>
                  Adjust tyre pressure accordingly. Improperly inflated tyres can lower your gas mileage, increase wear and tear or even cause a blown tyre. Make sure to know the right amount of pressure required for your car tyres.
                 </p>
                 <p></p>

                 <p><strong>5. Inspect your car brakes:  </strong> <br/>
                  The best way to care for car brakes is by sticking to the suggested maintenance schedule from your car’s manual. Following a routine car maintenance schedule can prevent brake failure and expensive repairs.
                 </p>
                 <p></p>

                 <p><strong>6. Have an auto insurance policy</strong> <br/>
                  An auto insurance policy can protect you, your family, your passengers and other drivers in the event of an accident. It can also be extended to cover medical costs incurred from bodily injury resulting from the accident. For more information, please visit <a href="https://nsiainsurance.com/motorinsurance">https://nsiainsurance.com/motorinsurance</a> or call 09048418896.
                 </p>
                 <p></p>


                <p>You have invested a lot of money in your vehicle, so you need to be sure to maintain it properly. Vehicle maintenance might cost you a few thousands while replacements and emergency repairs can cost a lot more. This one factor highlights the importance of vehicle maintenance. </p>


              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>
