import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nsia-insurance-go-paperless',
  templateUrl: './nsia-insurance-go-paperless.component.html',
  styleUrls: ['./nsia-insurance-go-paperless.component.css']
})
export class NsiaInsuranceGoPaperlessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
