
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;"><strong>DO IT TODAY, NOT TOMORROW!</strong></p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/DO_IT_TODAY.jpeg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>Procrastination is often confused with laziness, but they are very different. It is an active process – you choose to do something else instead of the task that you know you should be doing. In contrast, laziness suggests indifference, inactivity and an unwillingness to act.</p>
                 <p></p>
                 <p>Procrastination usually involves ignoring an unpleasant, but likely more important task, in favor of one that is more enjoyable or easier. Giving in to this impulse can have serious consequences, leading to reduced productivity and can cause us to miss out on achieving our goals.</p>
                <p></p>
              </div>
           </div>

          <div class="row justify-content-between">


              <div class="col-md-12">

                <p>As with most habits, it is possible to overcome procrastination. Follow the steps below to help you to deal with and prevent procrastination:</p>
              <p></p>
                <p><strong>1.	Recognize that you're procrastinating</strong><br/>You might be putting off a task because you've had to re-prioritize your workload. If you're briefly delaying an important task for a genuinely good reason, then you aren't necessarily procrastinating. However, if you start to put things off indefinitely, or switch focus because you want to avoid doing something, then you probably are.
               </p>
               <p></p>

                <p><strong>2.	Work out why you're procrastinating</strong><br/>
                    You need to understand the reasons why you are procrastinating before you can begin to tackle it. For instance, are you avoiding a particular task because you find it boring or unpleasant? If so, take steps to get it out of the way quickly, so that you can focus on the aspects of your job that you find more enjoyable
                </p>
                <p></p>
                 <p><strong>3.	Adopt anti-procrastination strategies</strong> <br/>
                    Procrastination is a habit – a deeply ingrained pattern of behavior. This means that you probably can't break it overnight. Habits only stop being habits when you avoid practicing them, so try the strategies below to give yourself the best possible chance of succeeding.
                 </p>
                 <p></p>
                <p>
                    -	Prioritize your To-Do List<br/>
                    -	Promise yourself a reward<br/>
                    -	Minimize distractions<br/>
                    -	Be accountable to someone other than yourself<br/>
                    -	Tackle the hardest tasks at your peak times<br/>

                </p>
                <p></p>
                <p>We hope this article has been helpful.</p>
            <p></p>
             </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>
