import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-group-life',
  templateUrl: './group-life.component.html',
  styleUrls: ['./group-life.component.css']
})
export class GroupLifeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
