export class AllRiskProposalForm {
  constructor(
    public NameOfProposal: string,
    public AddressOfProposal:string,
    public TradeOrBusiness:string,
    public long_established:string,
    public EmailAddress:string,
    public Telephone:string,
    public OfficeTelephone:string,
    public contact_person:string,
    public AddressOfPropertyToBeInsured:string,
    public SecurityMeasure:string,
    public LockedSafeMobileGadgets:string,
    public FireBurglaryInsurance:string,
    public claimfromanyinsurer:string,
    public companydecline:string,
    public RenewPolicy:string,
    public ImposedSpecialTerms:string,
    public twelveMonths:string,
    public DetailDescription:string



  ) {

  }
}
