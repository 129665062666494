<!-- <section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px">
  <div class="container">
    <div class="d-sm-flex text-center">
      <div class="align-self-center">
        <h2 class="h3 g-font-weight-300 w-100 g-mb-10 g-mb-0--md">
          <p>Awards </p>
        </h2>
      </div>


    </div>
  </div>
</section> -->
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>

      <li class="list-inline-item g-color-primary">
        <span>Women</span>
      </li>


    </ul>
  </div>
</section>
<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
    <div class="col-lg-9 g-mb-30 colll">
      <div class=" container-fluid d-flex flex-column align-items-center justify-content-center text-black div1">
        <h1 class="fw-bolder wm">Women's Month 2023</h1>
        <img src="../../../assets/svgs/wm1.svg" alt="Image 1 goes here">
        <h1>#NSIAWonderWoman</h1>
        <h4 class="fw-light">An unconquerable warrior who stands strong regardless of what life's challenges.</h4>
        <h4 class="fw-light">She is fully equipped to fulfill all her roles and does them excellently.</h4>
        <div class="container">
          <h2>This month of March, we're celebrating in two folds to honour our #NSIAwomen and women all over the world.
          </h2>
          <h2>First, we will be marking International Women's Day (Wednesday, March 8) in style, followed by Mother's
            Day (Sunday, March 19).</h2>
          <h2>As usual, we have a series of activities for you to participate in, and win awesome prizes!</h2>
          <h2>
            <li>International Women's Day</li>
          </h2>


          <h2>This year's theme encourages us to Embrace Equity. When we embrace equity, we embrace diversity, and we
            embrace inclusion. We embrace equity to forge harmony and unity, and to help drive success for all. Equality
            is the goal, and equity is the means to get there.</h2>
        </div>
        <img src="../../../assets/svgs/wm2.svg" alt="Image 2 goes here">
        <p class="text-light">#EmbraceEquity pose</p>
      </div>

      <div class=" container-fluid d-flex flex-column div2">
        <h2>Wonder Woman Challenge</h2>

        <h2 class="fw-light htp">How to participate:</h2>
        <ul class="lis">
          <li>Tag a wonder woman in the comment section and tell us why she is your Wonder Woman.</li>
          <li>Use the hashtags <span class="fw-bold text-black">#embraceequity #IWD2023 #NSIAWonderWoman2023</span>
          </li>
          <li>The comment with the highest number of engagements (likes and replies) wins.</li>
        </ul>
        <p>Note: You must be following all our social media platforms (See links below).</p>
        <img src="../../../assets/svgs/wm3.svg" alt="Image 3 goes here" class="thirdimg">
        <h2>
          <li>Mother's Day</li>
        </h2>

        <h2>Wonder Mum Challenge</h2>

        <h2 class="fw-light htp">How to participate:</h2>

        <ul class="lis">
          <li>Tag us in a 30-sec video telling us an unforgettable time when you experienced your mum as a wonder woman.
          </li>
          <li>Use the hashtags: <span class="fw-bold text-black">#MothersDay #NSIAWonderMum2023</span>
          </li>
          <li>The video with the highest engagements (likes and comments), wins a gift.</li>
        </ul>
        <p>Note: You must be following all our social media platforms (See links below).</p>

        <h2 class="fw-bold">Be among the first few people to be notified when our activities kick off!</h2>
        <ul class="alis">
          <li><a href="https://www.instagram.com/nsia_insure/">Instagram</a></li>
          <li><a href="https://www.facebook.com/NSIAInsuranceLimited/">Facebook</a></li>
          <li><a href="https://twitter.com/nsia_insure">Twitter</a></li>
          <li><a href="https://www.linkedin.com/company/nsia-insurance-limited/">LinkedIn</a></li>
          <li><a href="https://www.youtube.com/channel/UC2RRGT5hYGRJ3IECV7m3HWw">YouTube</a></li>
        </ul>

        <h4 class="my-5 fw-bold" style=" font-style: italic;">Terms & Conditions apply</h4>
      </div>

    </div>


    <!-- Sidebar -->
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <app-sidebar></app-sidebar>
      <!-- End Fast Facts -->
    </div>
    <!-- End Sidebar -->
  </div>
</div>