import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ways-to-retain-more-of-every-book',
  templateUrl: './ways-to-retain-more-of-every-book.component.html',
  styleUrls: ['./ways-to-retain-more-of-every-book.component.css']
})
export class WaysToRetainMoreOfEveryBookComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
