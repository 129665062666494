
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;"><strong>WHAT HAPPENS IF A GUEST CAUSES DAMAGE TO MY HOME?</strong></p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/interior.jpg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>A lot of people wonder if homeowners’ insurance is really worth it. Homeowners’ insurance can be expensive, and you might wonder why it is necessary to pay that premium when you’ve got plenty of other expenses to meet. </p>
                 <p></p>
                 <p>Having the right homeowner’s insurance, could help you get your life back to normal. You wouldn’t have to bear the costs of rebuilding your home. If your home is insured for its replacement cost, your insurance can help rebuild your home from the ground up.
                  </p>
              </div>
           </div>

          <div class="row justify-content-between">


              <div class="col-md-12">
               <p>If you have your own place, you would probably want to entertain guests, show them around the house, giving them the grand tour and all that. It’s one of the best parts of having your own home. But there’s a small catch – what if a guest causes damage to your precious home?
               </p>

               <p></p>
                <p>Ideally, the guest would apologize profusely and offer to cover the costs of repairs without hesitating. Sometimes, that your friend may not offer to pay for the damage, so now you’re in the “does homeowners’ insurance cover it” boat – and you’re not very happy.
                </p>

              <p></p>
                 <p>In general, yes, home insurance could cover you if you have a guest who causes damage, but you could end up facing higher insurance rates because of it. You might want to really think hard before using your insurance to repair damage caused by a guest.</p>

                 <p></p>
                 <p>Anyways, you also have to consider what your home insurance would cover. Does it only cover accidental damage? Does it only cover the structure of the home – like the floor and walls? These are important questions.
                 </p>
                 <p>As earlier stated, your guest may say, “I’m so sorry! Let me pay for that,” but unfortunately, such is not always the case. These situations can be super awkward, of course, so proceed with caution.</p>

                 <p>If you need homeowners’ insurance, we can help with that. All you have to do is give us a call on 09048418896 or visit our website at <a href="https://www.nsiainsurance.com">www.nsiainsurance.com</a> to get a quote today.</p>
                <p></p>

              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>