<div class="g-min-height-300 g-flex-centered g-bg-img-hero g-bg-pos-top-center g-bg-size-cover g-pt-80"
  style="background-image: url(assets/img/banners/Inset_Business.jpg);">
  <div class="container g-pos-rel g-z-index-1">
    <h2 class="h1 text-uppercase g-color-primary g-font-size-40--lg mb-0"><b>Liability Insurance</b></h2>
    <span class="d-block  g-color-white g-font-weight-1000 g-font-size-20 mb-4"> Protecting the key assets of your
      livelihood.</span>

  </div>
</div>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item g-color-primary">
        <span>Liability Insurance</span>
      </li>

      <li class="list-inline-item ml-auto">
       <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
           routerLink="/getquote">Get quote</a>
      </li>
    </ul>
  </div>
</section>


<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
    <div class="col-lg-9 g-mb-30">

      <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
        <!-- <h3 class="mb-0"><strong><p>BUSINESS INSURANCE</p></strong></h3><br/> -->
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white;">Public Liability Insurance</h5>
        </div>
        <p></p>
        <p class="mb-0">This policy is designed to protect the insured against accidental loss of or damage to
          third-party property or bodily injury including death or illness to a member of the public including legal
          costs incurred with the prior consent of the insurer in connection with the insured’s business.
        </p>
        <p>This policy can also be extended to cover loss of cash while in insured premises or personal custody of
          trusted employees of the Insured.</p>

        <br />
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white;">Product Liability Insurance</h5>
        </div>
        <p></p>
        <p class="mb-0">
          Product Liability insurance covers the insured's legal liability for death, bodily injury, or loss, or damage
          caused by the Insured's products to the third party.
        </p>


        <p></p>
        <br />
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white">Professional Indemnity Insurance</h5>
        </div>
        <p></p>
        <p class="mb-0">
          The policy protects the insured against legal liabilities that may arise by reason of any negligence , error
          or omission committed in a professional capacity by the person insured or by any partner, director, person who
          may be in the insured’s employment.
        </p>

        <br />
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white">Directors & Officers Liability Insurance</h5>
        </div>
        <p></p>
        <p class="mb-0">
          Directors and Officers insurance policies offer liability cover for company managers to protect them from
          claims which may arise from the decisions and actions taken within the scope of their regular duties.
        </p>
        <p>Directors and Officers (D&O) Liability Insurance — a type of liability insurance covering directors and
          officers for claims made against them while serving on a board of directors and/or as an officer. D&O
          liability insurance can be written to cover the directors and officers of for-profit businesses, privately
          held firms, not-for-profit organizations, and educational institutions. In effect, the policies function as
          "management errors and omissions liability insurance," covering claims resulting from managerial decisions
          that have adverse financial consequences. </p>
        <br />

      </div>
    </div>

    <!-- Sidebar -->
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <app-sidebar></app-sidebar>
      <!-- End Fast Facts -->
    </div>
    <!-- End Sidebar -->
  </div>
</div>
