
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;">THINGS TO CONSIDER BEFORE TAKING THAT TRIP</p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/trips.jpg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>Since the outbreak of the novel coronavirus, the Nigeria Center for Disease Control (NCDC) has provided necessary guidelines on how best to stay safe during the pandemic. However, with the ease of the lockdown and opening of international airports, travel has become more feasible and this further emphasizes the need to adhere to safety guidelines in order stop the spread of COVID-19. Here are some things to keep in mind before you go on that trip, at this time:
                 </p>
                 <p></p>
              </div>
           </div>


          <div class="row justify-content-between">


              <div class="col-md-12">

                <p></p>

            <p><strong>1.	Evaluate the risk: </strong><br/>Reports have shown that the elderly, and people with certain medical conditions, are at a higher risk of contracting the Coronavirus. If you must travel, contact your doctor and ask about any additional precaution you may need to take. It is also important to take into consideration your means of traveling, whether it's by plane or car. This will give you an idea of the number of people to whom you will be exposed. If you are staying in a hotel, it's important to ensure that the hotel is taking the necessary precautions to curb the spread of COVID-19. </p>

            <p></p>

            <p><strong>2.	Stay clean: </strong>It important to keep your hands clean at all times. If you do not have access to water at the time, sanitize your hands with an alcohol-based hand sanitizer. Avoid touching your face, especially your eyes, nose and mouth. Always wear a face mask. Make it a habit to disinfect areas that you touch frequently such as your car door handles, luggage handles, phones, light switch, etc.</p>

            <p></p>

            <p><strong>3.	Pack ‘the essentials’: </strong> Make sure to pack an alcohol-based hand sanitizer with at least 60% alcohol content, face masks, disinfecting wipes and a first aid kit. Also, to avoid unnecessary stops at supermarkets, petrol station restaurant, pack some of your favourite snacks and lots of water during your trip.  </p>

            <p></p>

            <p><strong>4.	Be aware of a nearby hospital around you: </strong>  It is important to find a nearby hospital when you arrive at your destination, in case of an emergency or need for medical care. </p>

            <p></p>

            <p><strong> 5.	Check state, local travel guidelines:  </strong> Before you travel, you should check the state and local travel guidelines of your destination. It is important to do so because they might have specific COVID-19 precautionary measures and requirements that must be met. It is always better to stay informed.</p>


                <p></p>
                <p>Your travel experience is made easier when you have peace of mind with NSIA travel insurance plan to cover medical costs that may arise and issues relating to loss of baggage. For more information, please visit <a href="https://nsiainsurance.com/travel-insurance">https://nsiainsurance.com/travel-insurance</a> or call customer service on 09048418896.</p>

              <p>Stay Safe</p>
              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>