import { Component, Input, OnInit, OnChanges } from '@angular/core';

@Component({
  selector: 'app-nav-bar-tab-view',
  templateUrl: './nav-bar-tab-view.component.html',
  styleUrls: ['./nav-bar-tab-view.component.css']
})
export class NavBarTabViewComponent implements OnInit, OnChanges {
  @Input() name;
  constructor() {
    this.lifeModel ="Life Business";
    this.generalModel ="General Business";
    console.log(this.lifeModel);
    this.isLifehidden = false;
    this.isGeneralhidden = true;

  }
  lifeModel: string;
  generalModel: string;
  isLifehidden: boolean = false;
  isGeneralhidden: boolean = true;
  ngOnInit(): void {
    this.isLifehidden = false;
    this.isGeneralhidden = true;
    // this.lifeClick();
    // this.generalClick();
  }
  ngOnChanges(changes): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    console.log(changes);
  }


  changeFn(changes) {
    console.log(changes);
  }

  lifeClick() {
    alert("test")
    this.isLifehidden = true;
    this.isGeneralhidden = false;
    console.log(this.isLifehidden);
  }

  generalClick() {
    alert("test")
    this.isLifehidden = false;
    this.isGeneralhidden = true;
    console.log(this.isLifehidden);
  }

}
