import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { PaymentIntegrationService } from "src/app/Services/payment-integration.service";
import { NaicomModel } from "../../Classes/naicom.model";

@Component({
  selector: "app-naicom-unique-id",
  templateUrl: "./naicom-unique-id.component.html",
  styleUrls: ["./naicom-unique-id.component.css"],
})
export class NaicomUniqueIdComponent implements OnInit {
  riskLifeList: any;
  riskList: any;
  subRiskLifeList: any;
  subRiskList: any;
  lifeUniqueID: any;
  uniqueId: any;
  isLifeSuccess: boolean =false;
  isGeneralSuccess: boolean =false;
  lifeBusienssFormGroup: FormGroup;
  generalBusinessFormGroup: FormGroup;
  constructor(
    private service: PaymentIntegrationService,
    private fb: FormBuilder
  ) {
    this.lifeBusienssFormGroup = fb.group({
      subRiskId: ["", Validators.required],
      policyNo: ["", Validators.required],
    });

    this.generalBusinessFormGroup = fb.group({
      subRiskId1: ["", Validators.required],
      policyNo1: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.getLifeRiskDetails();
    this.getRiskDetails();
  }
  selectLifeRisk(event) {
    console.log(event.target.value);
    this.getLifeSubRisk(event.target.value);
  }

  selectRisk(event) {
    console.log(event.target.value)
    this.getSubRisk(event.target.value);

  }

  getLifeRiskDetails() {
    this.service.getLifeRiskList().subscribe((response) => {
      this.riskLifeList = response;
      console.log(response);
    });
  }

  getRiskDetails() {
    this.service.getRiskList().subscribe(response => {
      this.riskList = response;
      console.log(response);
    })
  }

  get subRiskId() {
    return this.lifeBusienssFormGroup.get("subRiskId");
  }

  get policyNo() {
    return this.lifeBusienssFormGroup.get("policyNo");
  }
  get subRiskId1() {
    return this.generalBusinessFormGroup.get("subRiskId1");
  }

  get policyNo1() {
    return this.generalBusinessFormGroup.get("policyNo1");
  }
  getLifeSubRisk(riskId: any) {
    this.service.getLifeSubRisk(riskId).subscribe((response) => {
      console.log(response);
      this.subRiskLifeList = response.subRisk;
    });
  }

  getSubRisk(riskId: any) {
    this.service.getSubRisk(riskId).subscribe(response => {
      console.log(response);
      this.subRiskList = response.subRisk;
    })
  }


  submitLife() {
    const policyNo = this.policyNo.value;
    this.service.getLifeNaicomUniqueID(policyNo).subscribe((response) => {
      console.log(JSON.parse(response));
      if (response) {
        console.log(response);
        this.isLifeSuccess = true;
        this.lifeUniqueID = response.naicomid;
      } else {
        alert("The policy No has no Unique ID");
      }
    });
  }

  submitGeneralBusiness() {
    const model: NaicomModel = {
      subRiskId: this.subRiskId1.value,
      policyNo: this.policyNo1.value,
    };
    console.log(model);

    this.service.getNaicomUniqueID(model).subscribe((response) => {
      if (response) {
        console.log(response);
        this.isGeneralSuccess = true;
        this.uniqueId = response.naicomid;
      } else {
        alert("The policy No has no Unique ID");
      }
    });
  }
}
