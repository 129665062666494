<ng-progress [color]="'green'"></ng-progress>



<div class="container g-pt-100 g-pb-70">
  <div class="row">
    <div class="col-lg-9 g-mb-30">
      <section class="g-bg-gray-light-v5 g-py-20">
        <form novalidate [formGroup]="claimFormGroup">
          <div class="container g-py-100" style="margin-top:-50px">
            <div
              class="u-shadow-v19 g-max-width-645 g-brd-around g-brd-gray-light-v4 text-center rounded mx-auto g-pa-30 g-pa-50--md">
              <span class="u-icon-v3 u-icon-size--lg g-color-white g-bg-primary rounded-circle g-pa-15 mb-5">
                <svg width="30" height="30" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 497.5 497.5"
                  style="enable-background:new 0 0 497.5 497.5;" xml:space="preserve">
                  <g>
                    <path
                      d="M487.75,78.125c-13-13-33-13-46,0l-272,272l-114-113c-13-13-33-13-46,0s-13,33,0,46l137,136
                                                     c6,6,15,10,23,10s17-4,23-10l295-294C500.75,112.125,500.75,91.125,487.75,78.125z"
                      fill="#fff" />
                  </g>
                </svg>
              </span>

              <div class="mb-6">
                <h2 class="mb-4">
                  <p>Report Claim!</p>
                </h2>
                <div class="row">

                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group g-mb-20">
                      <label class="col-form-label">
                        <p>Product Type</p>
                      </label>
                      <select class="form-control border-input" formControlName="productType" 
                        required>
                        <option value="" selected disabled>Select Product Type</option>
                        <option [value]="sub.id" *ngFor="let sub of subRisks" >{{sub.name}}</option>

                      </select>
                    </div>
                  </div>
                  <div class="col-lg-6">

                    <div class="form-group g-mb-20"
                      >
                      <label class="g-mb-10">
                        <p>Policy Number</p>
                      </label>
                      <input formControlName="policyId" class="form-control form-control-md rounded-0" type="text"
                        placeholder="Required"  required width="150px">
                    </div>
                  </div>

                </div>

                <div class="row">
                 
                </div>
              </div>


              <div class="form-group g-mb-20">
                <label class="g-mb-10">
                  <p>Brief summary how the accident/incident occured</p>
                </label>
                <textarea class="form-control form-control-md rounded-0" formControlName="description" rows="6"
                  [(ngModel)]="claimData.description"></textarea>

              </div>
            </div>
          <p></p>
          <p></p>
            
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group g-mb-20">
                  <label>
                    <p>Upload All document</p>
                  </label>
                  <input multiple type="file" (change)="onFileChange($event)" required />
                </div>
              </div>
            </div>
            <p style="font-size:15px;">In compliance with NDPR requirements, by completing and submitting this form, you
              have expressed your consent to NSIA Insurance to receive your data which includes bio data, to enable NSIA
              maintain effective communication, send promotional update, contact you for research purpose and use the
              data in line with the policy terms.
              <br />The data collection may be via hard copies or web forms inputs, validated via JWT and the data will
              not be accessed by any third party without your consent.
              <br />
              Any breach of this policy can be addressed legally within the year the breach was reported.
            </p>
            <button class="btn u-btn-primary g-font-size-12 text-uppercase g-py-12 g-px-25" type="submit" [disabled]="!claimFormGroup"
              (click)="OnclaimSubmit()"> Submit </button>
          </div>
        </form>
      </section>
    </div>
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <app-sidebar></app-sidebar>
      <!-- End Fast Facts -->
    </div>
    <!-- End Sidebar -->
  </div>
</div>
