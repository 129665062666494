import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-validate-auto-insurance-certificate',
  templateUrl: './validate-auto-insurance-certificate.component.html',
  styleUrls: ['./validate-auto-insurance-certificate.component.css']
})
export class ValidateAutoInsuranceCertificateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
