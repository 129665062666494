import { Component, OnInit } from '@angular/core';
import { UserClass } from 'src/app/Classes/user-class';
import { FormserviceService } from 'src/app/Services/formservice.service';
import swal from 'sweetalert2';
@Component({
  selector: 'app-complaint-form',
  templateUrl: './complaint-form.component.html',
  styleUrls: ['./complaint-form.component.css']
})
export class ComplaintFormComponent implements OnInit {

  userData = new UserClass("", "", "", "");
  constructor(private _formService: FormserviceService) { }
  ngOnInit(): void {
  }
  OnContactSubmit() {
    this._formService.startLoading();
   this._formService.sendContact(this.userData)
   .subscribe(
     (data) => {
       console.log(data);
       if (data.statusCode == "00") {
         swal.fire("Success", "Your message is submitted. We will contact you shortly", "success");
       } else {
         swal.fire("Error", data.statusMessage, "info");
       }
       this._formService.stopLoading();
       this.userData = new UserClass("", "", "", "");
     },
     (error) => {
       console.log(error);
       swal.fire("Fatal", "Internal server error occurred", "error");
       this._formService.stopLoading();
     }
   )
  }
}
