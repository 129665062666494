export class QuoteData {

  constructor(
    public EmailAddress: string,
    public PhoneNumber: string,
    public ProductType: string,
    public Tenor: string,
    public DateOfBirth: Date,
    public FormattedDateOfBirth: Date,
    public SumAssured: string

  ) {

  }
}


