<div class="g-min-height-450 g-flex-centered g-bg-img-hero g-bg-pos-top-center g-bg-size-cover g-pt-80"
  style="background-image: url(assets/img/newcontact.png);">
  <div class="container g-pos-rel g-z-index-1">
    <h2 class="h1 text-uppercase g-color-white g-font-size-40--lg mb-0">Contact Us</h2>
    <span class="d-block  g-color-white g-font-weight-1000 g-font-size-20 mb-4"></span>
  </div>
</div>


<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item g-color-primary">
        <span>Contact Us</span>
      </li>

      <li class="list-inline-item ml-auto">
        <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10"
          routerLink="/getquote">Get quote</a>
      </li>
    </ul>
  </div>
</section>
<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
    <div class="col-lg-9 g-mb-30">
      <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
        <!-- <h3 class="mb-0"><strong><p>Contact Us</p></strong></h3><br/> -->

        <div class="row justify-content-between">
          <!-- About Image -->
          <div class="col-md-3 col-lg-5">
            <img class="img-fluid" src="assets/img/sliders/office.jpg" alt="Image Description">
          </div>
          <!-- End About Image -->
          <div class="col-md-8 col-lg-6">
            <!-- About Info -->
            <h3>
              <p>Corporate Head Office:</p>
            </h3>

            <p>3, Elsie Femi Pearse Street,</p>
            <p>Victoria Island. Lagos.</p>
            <p>P. O. Box 5061, Lagos – Nigeria.</p>
            <p>Tel: +234 809 720 9218</p>
            <p>Whatsapp: +234 904 841 8896</p>
            <p>Fax: 01 – 279 8258</p>
            <p>E Mail: enquiry@nsiainsurance.com</p>
          </div>
        </div>
        <div class="row justify-content-between">
          <div class="col-md-6">
            <div class="card card-outline-warning rounded-0 contact-card">
              <h3 class="card-header h5 text-white bg-warning g-brd-transparent rounded-0">
                <i class="fa fa-tasks g-font-size-default g-mr-5"></i>
                Abuja Office
              </h3>

              <div class="card-block">

                <p>18, Djibouti Crescent, Off Freetown Street, Wuse II, Abuja.</p>
                <p> +234 803 281 7700</p>
              </div>
            </div>


          </div>
          <div class="col-md-6">
            <div class="card card-outline-warning  rounded-0 contact-card">
              <h3 class="card-header h5 text-white bg-warning g-brd-transparent rounded-0">
                <i class="fa fa-tasks g-font-size-default g-mr-5"></i>
                Ikeja Office
              </h3>

              <div class="card-block">
                <p>161, Awolowo Road, Ikeja, Lagos State.</p>
                <p>+234 905 3886125</p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="row justify-content-between">
            <div class="col-md-6">
                <div class="card card-outline-warning rounded-0 contact-card">
                  <h3 class="card-header h5 text-white bg-warning g-brd-transparent rounded-0">
                    <i class="fa fa-tasks g-font-size-default g-mr-5"></i>
                    Ibadan Office
                  </h3>
                  <div class="card-block">
                    <p>Last floor, Oxford building,
                     Beside UBA regional office, Lebanon Street, Dugbe, Ibadan, Oyo State.</p>
                    <p>+234 805 800 5020</p>
                  </div>
                </div>


              </div>
          <!-- <div class="col-md-6">
                               <div class="card card-outline-warning rounded-0 contact-card">
                                       <h3 class="card-header h5 text-white bg-warning g-brd-transparent rounded-0">
                                         <i class="fa fa-tasks g-font-size-default g-mr-5"></i>
                                         Enugu Office
                                       </h3>
                                       <div class="card-block">
                                            <p>Access Bank Building
                                            32E, Okpara Avenue, Enugu.</p>
                                            <p>+234 803 710 3868</p>
                                       </div>
                                     </div>


                           </div> -->
          <div class="col-md-6">
            <div class="card card-outline-warning rounded-0 contact-card">
              <h3 class="card-header h5 text-white bg-warning g-brd-transparent rounded-0">
                <i class="fa fa-tasks g-font-size-default g-mr-5"></i>
                Port Harcourt Office
              </h3>

              <div class="card-block">
                <p>1B, Stadium Road, Port Harcourt, Rivers State.</p>
                <p>+234 805 800 5090</p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="row justify-content-between">
          <div class="col-md-6">
            <div class="card card-outline-warning rounded-0 contact-card">
              <h3 class="card-header h5 text-white bg-warning g-brd-transparent rounded-0">
                <i class="fa fa-tasks g-font-size-default g-mr-5"></i>
                Kaduna Office
              </h3>
              <div class="card-block">
                <p>Suite PP10 Mazangari Investment Building,
                  17F, Independence Way, Kaduna.</p>
                <p>+234 803 596 9606</p>
              </div>
            </div>


          </div>
          <div class="col-md-6">
            <div class="card card-outline-warning rounded-0 contact-card">
              <h3 class="card-header h5 text-white bg-warning g-brd-transparent rounded-0">
                <i class="fa fa-tasks g-font-size-default g-mr-5"></i>
                Kano Office
              </h3>
              <div class="card-block">
                <p>375, Civic Centre Road, J.B.S Plaza, Kano State.</p>
                <p>+234 806 611 1916</p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="row justify-content-between">


        </div>
        <br />
        <div class="row justify-content-between">
            <div class="col-md-6">

                <div class="card card-outline-warning rounded-0 contact-card">
                  <h3 class="card-header h5 text-white bg-warning g-brd-transparent rounded-0">
                    <i class="fa fa-tasks g-font-size-default g-mr-5"></i>
                    Warri Office
                  </h3>




                  <div class="card-block">
                    <p>57, Effurun/Sapele road, Effurun, Delta state, Nigeria.</p>
                    <p>+234 803 772 1803</p>
                  </div>
                </div>
              </div>
          <div class="col-md-6">
                                                  <div class="card card-outline-warning rounded-0 contact-card">
                                                    <h3 class="card-header h5 text-white bg-warning g-brd-transparent rounded-0">
                                                      <i class="fa fa-tasks g-font-size-default g-mr-5"></i>
                                                      Onitsha  Office
                                                    </h3>

                                                    <div class="card-block">
                                                      <p>46, Iweka Road, Onitsha,
                                                        Anambra State.
                                                        </p>
                                                      <p>+234 803 710 3868</p>
                                                    </div>
                                                  </div>
                                           </div>

        </div>
        <div class="row justify-content-between">
          <div class="col-md-12">
            <h5>For complaints please <a routerLink="/complaint-form">Click Here</a> and for enquiries please <a routerLink="/contact-form">Click Here</a></h5>
          </div>
        </div>
      </div>
      <!-- End About Info -->
    </div>

    <!-- Sidebar -->
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <app-sidebar></app-sidebar>
      <!-- End Fast Facts -->
    </div>
    <!-- End Sidebar -->
  </div>
</div>
