<section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px">
  <div class="container">
    <div class="d-sm-flex text-center">
      <div class="align-self-center">
        <h2 class="h3 g-font-weight-300 w-100 g-mb-10 g-mb-0--md">
          <p>Credit Life (NSIA Super Protector)</p>
        </h2>
      </div>
    </div>
  </div>
</section>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/lifeinsurance">Life Insurance</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item g-color-primary">
        <span>Credit Life (NSIA Super Protector)</span>
      </li>

      <li class="list-inline-item ml-auto">
        <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10"
          routerLink="/getquote">Get quote</a>
      </li>
    </ul>
  </div>
</section>
<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
    <div class="col-lg-9 g-mb-30">
      <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
        <h4><strong>
            <p>Credit Life (NSIA Super Protector)</p>
          </strong> </h4>
        <p>Most financial institutions now give short term loans for acquisition of consumer durable goods such as
          televisions, cars, fridges, furniture etc. Typically, the duration of such loans will be between 3 months and
          5 years.</p>
        <p></p>
        <p>NSIA Super Protector (NSP) is a credit life insurance product designed to protect financial institutions
          against the risk of death of short term borrowers. It also serves the purpose of protecting policyholder’s
          dependants from the financial consequences of an outstanding loan standing against the breadwinner. Should
          death occur, the financial institution (eg bank) recoups its loan from the insurance proceeds and avoid the
          awkward situation of asset repossession or sale. Similarly, policyholder’s dependants also keep the asset in
          the family thereby reducing the agony of breadwinner’s untimely death.</p>
        <p><strong>Key Features</strong></p>
        <p></p>
        <ul>
          <li>
            <p>Cover is usually arranged for a maximum duration of 5 years</p>
          </li>
          <li>
            <p>Maximum age at entry is 65 years</p>
          </li>
          <li>
            <p>Payment of a guaranteed sum of money (sum assured) if death of the life assured occurs within a specific
              period of time</p>
          </li>
          <li>
            <p>Payment also made in the event of total permanent disability or diagnosis of a critical illness</p>
          </li>
          <li>
            <p>The guaranteed sum assured will be the original loan amount (or, balance)</p>
          </li>
          <li>
            <p>Medical examination is not required where the sum assured is below a certain amount.</p>
          </li>
          <li>
            <p>Benefit serves as collateral for loan</p>
          </li>
          <li>
            <p>It is a very cheap way of acquiring assets</p>
          </li>
          <li>
            <p>As the cover is very cheap and it is arranged for short periods, Policyholder pays a single premium
              (purchase price) to NSIA Life Assurance Limited to secure the guaranteed benefit</p>
          </li>
        </ul>
        <p></p>
        <p><strong>Suitability</strong></p>
        <p></p>
        <p>NSP is suitable for:</p>
        <ul>
          <li>
            <p>An individual who wants to take a short term loan from banks etc.</p>
          </li>
          <li>
            <p>A financial institution that wants to protect itself against the risk of death of a medium to short term
              borrower</p>
          </li>
          <li>
            <p>Other creditors who want to protect themselves against the risk of death of those who owe them medium to
              short term loans.</p>
          </li>


        </ul>

        <p></p>
        <p><strong>Benefits</strong></p>
        <p></p>
        <p> On Death or Disability</p>

        <p></p>

        <ul>
          <li>
            <p>In the event of death or total permanent disability, NSIA Life pays the sum assured (as at the date of
              death).</p>
          </li>
          <li>
            <p>If for any reason the sum assured at death exceeds the outstanding loan, the difference is paid to the
              policyholder’s dependants or next of kin.</p>
          </li>

        </ul>
        <p></p>
        <p><i class="fa fa-angle-right color-green"></i> On Maturity/Discontinuation</p>

        <p></p>

        <p>On discontinuation or survival of the policyholder till the end of the specified period of cover, no payment
          is made.</p>
        <p></p>
        <p><i class="fa fa-angle-right color-green"></i>Loss of Job Cover</p>

        <p></p>

        <p>NSIA Life also provides a Loss of Job Cover as a rider (supplementary) to the main Credit Life Insurance
          cover. In the event of retrenchment or disability of the life assured which prevents him from meeting loan
          repayment obligations, NSIA takes over the responsibility of such loan repayments for a specific period
          depending on the loan tenor</p>

          <p><strong>Waiting Period</strong> </p>

          <p></p>

          <p>
            Varies based on the loan tenor</p>
          <p></p>


      </div>
    </div>


    <!-- Sidebar -->
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <app-sidebar></app-sidebar>
      <!-- End Fast Facts -->
    </div>
    <!-- End Sidebar -->
  </div>
</div>
