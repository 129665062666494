
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;">BASIC INSURANCE TERMINOLOGIES AND THEIR MEANING</p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/BASIC_INSURANCE_TERMINOLGIES.jpg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>
                  Recently, we ran a poll on our social media platforms with a view to understanding why people are sometimes skeptical or flat-out uninterested in purchasing insurance policies. The results indicated that a lot of people struggle to understand basic insurance terminologies and therefore, cannot make informed decisions about what policies to buy or whether to buy at all. This article provides a glossary of insurance terms that are commonly used, with definitions that represent a general use of the terms.
                 </p>
                 <p></p>

              </div>
           </div>

          <div class="row justify-content-between">


              <div class="col-md-12">
               <p><strong>Adjuster:</strong> A person who investigates a loss and negotiates settlement with the claimant on the company’s behalf.
               </p>

               <p></p>
                <p><strong>Agent:</strong> A qualified expert that’s licensed to sell insurance on behalf of an insurance company.</p>
                <p></p>
                <p><strong>All-Risk:</strong> Also known as open peril, this type of policy covers a broad range of losses.</p>
                <p></p>

                 <p><strong>Broker:</strong> An intermediary, who acts on behalf of a person who is applying for insurance. They earn a commission from the insurer; however, they have a responsibility to obtain cover appropriate to the needs of the insured.
                 </p>
                 <p></p>

                 <p><strong>Claim:</strong> An insurance claim is when you make a request to your insurance company to pay you after a loss has occurred.</p>
                <p></p>

                <p><strong>Coverage: </strong>The protection against financial loss provided by an insurance contract.</p>
                <p></p>
                <p><strong>Deductible:</strong> The amount of the damage or loss that you’re responsible for before your insurance provider pays on a claim. (Sometimes called an “excess”)</p>

                <p></p>
                <p><strong>Fortuitous loss: </strong>An unforeseen loss is termed a fortuitous loss. Insurers will only insure fortuitous losses. </p>
               <p></p>
              <p><strong>Indemnity insurance:</strong> Type of insurance that restores the individual as close as possible to the financial position that they enjoyed before the loss.</p>
               <p></p>
               <p>
               <strong>Insured:</strong>  This is the policyholder; the person(s) protected in case of a loss or claim.
               </p>

               <p></p>
               <p><strong>Insurer:</strong> The insurance company that provides insurance coverage and services.</p>
              <p></p>
               <p><strong>Lapse:</strong> The termination of a policy due to failure to pay the required renewal premium.</p>
              <p></p>
              <p><strong>Premium:</strong> The amount of money an insurance company charges to provide coverage.</p>


              <p></p>

              <p><strong>Quote:</strong> An estimate of the cost of insurance, based on information supplied to the insurance company by the applicant.</p>

              <p></p>
              <p><strong>Rebate:</strong> A refund of part or all of a premium payment.</p>
              <p></p>
              <p><strong>Rider: </strong>Usually known as an endorsement, a rider is an amendment to the policy used to add or delete coverage</p>

            <p></p>
            <p><strong>Subrogation:</strong> A situation where an insurer, on behalf of the insured, has a legal right to bring a liability suit against a third party who caused losses to the insured.</p>
          <p></p>
          <p><strong>Sum insured:</strong> The maximum liability of the insurer’s liability under an insurance contract.</p>
          <p></p>
          <p><strong>Surcharge:</strong> When an insurance company increases your rate due to a factor (like your driving record, or the distance from your house to the fire department) that increases your likelihood of suffering a loss.</p>

          <p></p>
          <p><strong>Third party: </strong>A person or entity who is not a party to an insurance contract but who has an alleged or actual right of action for injury or damages against an insured under a contract of insurance.</p>
          <p></p>
          <p><strong>Waiting period:</strong> A period of time set forth in a policy that must pass before some or all coverage begins.</p>
          <p></p>
          <p><strong>Waiver:</strong> A provision or rider agreeing to forego premium payment during a period of disability. It may be effected by the agent, adjuster, or insurance company.</p>
          <p></p>
          <p>Simply having the basic understanding of insurance definitions give you a deeper understanding of the coverage in your policies and helps you make better decisions for the future. </p>

        </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>