
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;"><strong>TIPS TO IMPROVE QUALITY OF LIFE </strong></p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/qualityoflife.jpg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>Just as everyone has their own definition of success, we all have slightly different ideas of what makes for a high-quality life. Nevertheless, there are some steps everyone can take to make life more pleasurable. Just taking these few steps can improve your personal well-being and make each day more meaningful.</p>
                 <p></p>
                 <p><strong>1.	Maintain Healthy Relationships</strong><br/>
                  The quality of relationships we keep has a direct effect on our mental health. Research shows that not only can healthy and supportive relationships increase your happiness, and psychological well-being; they also reduce the risks of depression.
                 </p>

                 <p></p>
                  <p><strong>2.	Get a good night’s sleep </strong><br/>
                    Whether you're staying up late because of your job or to catch up on episodes of your favourite TV shows, there is so much going on in our lives that seem to take precedence over a good night's sleep. Sleep is a vital part of a healthy lifestyle, and no other activity delivers so many benefits with so little effort.
                  </p>
              </div>
           </div>

          <div class="row justify-content-between">


              <div class="col-md-12">


              <p></p>
                 <p><strong>3.	Exercise  </strong> <br/>
                  Regular exercise has a profoundly positive impact on your physical and mental health. Not only does it trim your waistline, but it can also relieve anxiety, improve your memory, and help you sleep better. You don't have to be a fitness fanatic to reap these benefits. Research indicates that even 30 minutes of exercise a day can make a difference.
                 </p>

                 <p></p>
                 <p><strong>4.	Make time for leisure</strong> <br/>
                  Do you ever feel like there is never enough time in the day to do the things you want to do? Yes, life is busy, but if you're not allowing yourself time to relax and have some fun, you could be doing yourself a big disservice. Research shows that engaging in a leisure activity not only lowers your stress levels and improves your mood, but it also enhances your problem-solving skills. Indulge yourself in a pleasurable activity and watch your mood and productivity improve. You might even find yourself more energized and excited about doing those things you have to do—or at least not dreading them.

                 </p>
                 <p>In the long run, it is always a good idea for you to make positive choices. Love yourself, take chances, and monitor your thoughts. After all, thoughts become words, words will lead to actions, actions then become habits.</p>
                 <p></p>

                 <p></p>

              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>