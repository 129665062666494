import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessmanagementComponent } from '../Web/accessmanagement/accessmanagement.component';
import { EditmanagementComponent } from '../Web/editmanagement/editmanagement.component';
const routes: Routes = [
  {
    path: '../Web/accessmanagement',
    component: AccessmanagementComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageRoutingModule { }
