import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { NgForm } from '@angular/forms'
import { GroupPersonalAccidentProposalForm } from 'src/app/Classes/group-personal-accident-proposal-form';
import { FormserviceService } from 'src/app/Services/formservice.service';


@Component({
  selector: 'app-group-personal-accident-proposal',
  templateUrl: './group-personal-accident-proposal.component.html',
  styleUrls: ['./group-personal-accident-proposal.component.css']
})
export class GroupPersonalAccidentProposalComponent implements OnInit {
  month:any;
  day:any;
  year:any;
  maxDate:any;

  telephone :string;
  dateTemp = new Date();
  proposalModel = new  GroupPersonalAccidentProposalForm("","","","","","",
  "",this.dateTemp,this.dateTemp,"","","","",null,"", "","","","","", "","");
  constructor(private _formservice: FormserviceService) { }

  ngOnInit(): void {
    var dtToday = new Date();
    this.month = dtToday.getMonth() + 1;
    this.day = dtToday.getDate();
    this.year = dtToday.getFullYear();
    if(this.month < 10)
    this.month = '0' + this.month.toString();
    if(this.day < 10)
    this.day = '0' + this.day.toString();
  }


  submitForm(myForm: NgForm){
    var email = this.proposalModel.EmailAddress;

    var regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;


    if(!regex.test(email)) {
     alert("Your email is not correct");
      this._formservice.stopLoading();
      return;
    }
    console.log($('#Telephone').val());
    var telLength = $('#Telephone').val().toString().length;
    //var secTel =$("#AlternativeTel").val().toString().length;

    if(telLength < 10){
     alert("Your telephone number is not correct");
      this._formservice.stopLoading();
      return;
    }

    this._formservice.startLoading();
    this._formservice.sendGroupPersonalAccidentProposalForm(this.proposalModel)
      .subscribe(
        (data) => {
          if (data.statusCode == "00") {
           alert("Your form is submitted");
            myForm.resetForm();
          } else {
           alert( data.statusMessage);
          }
          this._formservice.stopLoading();
        },
        (error) => {
          console.log(error);
          alert("internal server error occurred");
          this._formservice.stopLoading();
        }
      )

  }

}
