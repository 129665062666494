import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-family-protection',
  templateUrl: './family-protection.component.html',
  styleUrls: ['./family-protection.component.css']
})
export class FamilyProtectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
