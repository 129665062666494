<!-- <ng-progress [color]="'green'"></ng-progress> -->



<div class="container g-pt-100 g-pb-70">
  <div class="row">
    <div class="col-lg-9 g-mb-30">
      <section class="g-bg-gray-light-v5 g-py-20">
        <form novalidate [formGroup]="policyDetailsFormGroup">
          <div class="container g-py-100" style="margin-top:-50px">
            <div
              class="u-shadow-v19 g-max-width-645 g-brd-around g-brd-gray-light-v4 text-center rounded mx-auto g-pa-30 g-pa-50--md">
              <span class="u-icon-v3 u-icon-size--lg g-color-white g-bg-primary rounded-circle g-pa-15 mb-5">
                <svg width="30" height="30" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 497.5 497.5"
                  style="enable-background:new 0 0 497.5 497.5;" xml:space="preserve">
                  <g>
                    <path
                      d="M487.75,78.125c-13-13-33-13-46,0l-272,272l-114-113c-13-13-33-13-46,0s-13,33,0,46l137,136
                                                     c6,6,15,10,23,10s17-4,23-10l295-294C500.75,112.125,500.75,91.125,487.75,78.125z"
                      fill="#fff" />
                  </g>
                </svg>
              </span>

              <div class="mb-6">
                <h1 class="mb-4">
                  <p>Renew your Insured Properties using Policy Details</p>
                </h1>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group g-mb-20">
                      <label class="g-mb-10">
                        <p>Select Product Type </p>
                      </label>
                      <select formControlName="productType" class="form-control form-control-md"
                      >
                        <option [value]="sub.id" *ngFor="let sub of subRisks" >{{sub.name}}</option>
                      </select>
                    </div>
                    <div class="form-group g-mb-20">
                      <label class="g-mb-10">
                        <p>Policy Number </p>
                      </label>
                      <input formControlName="policyId" class="form-control form-control-md rounded-0" type="email" placeholder="NSIA-78692937"
                        required>
                    </div>
                  </div>
                </div>

              </div>


              <!-- <p style="font-size:10px; text-align: justify;">In compliance with NDPR requirements, by completing and submitting this form, you have given consent to NSIA Insurance to receive your data, including your bio data, to enable NSIA Insurance maintain effective communication, send promotional updates, contact you for research purposes and use the data in line with the policy terms.
              </p>
              <p style="font-size:10px; text-align: justify;">The data collection may be via hard copies or online forms, validated via JSON WEB TOKEN (JWT). The data will not be accessed by any third party without your consent and any breach of this policy can be addressed legally within the year the breach was reported.</p> -->
              <!-- <button class="btn u-btn-primary g-font-size-12 text-uppercase g-py-12 g-px-25">Check Policy </button> -->
              <button [disabled]="!policyDetailsFormGroup" type="submit" (click)="onPolicySubmit()" class="btn u-btn-primary g-font-size-12 text-uppercase g-py-12 g-px-25"
                > Check Policy </button>
            </div>
            <div class="mb-6">
              <div class="card" *ngIf="policyDetails!=null">
                <div class="upper"> <img src="https://i.imgur.com/Qtrsrk5.jpg" class="img-fluid"> </div>
                <div class="user text-center">
                    <div class="profile"> <img src="../../../assets/img/annonymous.png" class="rounded-circle" width="80"> </div>
                </div>
                <div class="mt-5 text-center">
                    <h4 *ngIf="policyDetails.lastName!=null" class="mb-0">{{ policyDetails.lastName +"  " + policyDetails.firstName}}</h4>
                    <h4   *ngIf="policyDetails.lastName==null" class="mb-0">{{  policyDetails.insuredName}}</h4>
                    <h3   *ngIf="policyDetails.lastName==null" class="mb-0">{{ "₦" + policyDetails.grossPremium}}</h3>
                    <span  class="text-muted d-block mb-2">{{policyDetails.email}}</span>
                     <button  *ngIf="expiredDate <= 180" (click)="makePayment()"  class="btn btn-primary btn-sm follow">Renew Policy</button>
                    <div class="d-flex justify-content-between align-items-center mt-4 px-4">
                        <div class="stats">
                            <h6 class="mb-0">Policy Start Date</h6> <span class="mb-0">{{startDate | date: "shortDate" }}</span>
                        </div>
                        <div class="stats">
                            <h6 class="mb-0">Policy End Date</h6> <span class="mb-0">{{endDate | date: "shortDate"}}</span>
                        </div>
                        <div class="stats">
                            <h6 class="mb-0">Number of Day Policy Expire</h6> <span class="mb-0">{{expiredDate}}</span>
                        </div>
                    </div>
                </div>
            </div>
            </div>

            <!-- <div class="container2 d-flex justify-content-center align-items-center">

          </div> -->
          </div>
        </form>
      </section>
    </div>
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <!-- <app-sidebar></app-sidebar> -->
      <!-- End Fast Facts -->
    </div>
    <!-- End Sidebar -->
  </div>
</div>
