import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { PublicLiabilityProposalForm } from 'src/app/Classes/public-liability-proposal-form';
import { FormserviceService } from 'src/app/Services/formservice.service';

@Component({
  selector: 'app-public-liability-insurance-proposal',
  templateUrl: './public-liability-insurance-proposal.component.html',
  styleUrls: ['./public-liability-insurance-proposal.component.css']
})
export class PublicLiabilityInsuranceProposalComponent implements OnInit {
  dateTemp = new Date();
  step:number = 1;
  equipments: string[] = [];

  proposalModel = new  PublicLiabilityProposalForm("","","","","","",null,null,"","","","",null,this.equipments,"","","","");
  constructor(private _formservice: FormserviceService) { }

  ngOnInit(): void {
  }
  addCont(e){
    let value = e.srcElement.value;
    // let type = true;
    if (e.srcElement.checked) {
      this.equipments.push(value);
    }else{
      const index: number = this.equipments.indexOf(value);
      if (index !== -1) {
        this.equipments.splice(index, 1);
      }
    }
  }

  submitForm(myForm: NgForm){
    this._formservice.startLoading();
    console.log(this.proposalModel);
    this._formservice.sendProposalFormForPublicLiability(this.proposalModel)
      .subscribe(
        (data) => {
          if (data.statusCode == "00") {
           alert("Your form is submitted");
           myForm.resetForm();
          } else {
            alert(data.statusMessage);
           // Swal.fire("error", data.statusMessage, "info");
           //Swal.fire("Success", "Your form is submitted", "success");
          }
          this._formservice.stopLoading();
        },
        (error) => {
          console.log(error);
          //Swal.fire("fatal", "internal server error occurred", "error");
          //Swal.fire("Success", "Your form is submitted", "success");
          this._formservice.stopLoading();
        }
      )
  }

}
