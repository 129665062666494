import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-you-don-hit-my-car',
  templateUrl: './you-don-hit-my-car.component.html',
  styleUrls: ['./you-don-hit-my-car.component.css']
})
export class YouDonHitMyCarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
