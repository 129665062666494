
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;"><strong>THINGS TO KNOW BEFORE BUYING A HOMEOWNER/HOUSEHOLDER INSURANCE</strong></p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/things_to_know_before_buying_homeowner_householder_insurance.jpg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>Generally, this kind of insurance is not the first choice on most people's list, nevertheless it acts as an important backup in case things go wrong. It is an agreement with your insurance provider that protects you, your belongings, and your house in the event of a sudden event, which causes damage to your house or personal property inside the building.

                </p>

                <p></p>
                <p>It is a necessity for most people, especially home owners, and there are two types; house owner’s insurance and house holder’s insurance. </p>

              </div>
           </div>

          <div class="row justify-content-between">


              <div class="col-md-12">
              <p></p>

              <p><strong>House holder’s insurance</strong>  covers all contents of the building against damage or loss by fire and special perils, theft etc., while house owner’s insurance covers the building and its components against damage by fire or designated “special perils” as well as theft of the accessories/contents.</p>
              <p></p>
              <p>Like any other insurance policy, you will either know, or be informed of the most important questions that you need to ask before you buy it. However, do not think twice before asking questions that may seem uncommon.</p>
              <p></p>
              <p><strong>Here’s what you should know…</strong></p>
              <p></p>
            <p>1.<strong>Make a decision based on your type of house</strong> It is important that you match your home type to suit your policy to ensure you have enough coverage. Being underinsured can cost you a lot in the future. You can consider buying enough coverage for the cost of a complete rebuild.
               </p>

               <p></p>
                <p>
                  2.	<strong>Know the history of your house.</strong> Knowing the history of your house can help you to learn about past events that occurred to enable you to plan better for the future. For example, if the area where the house is situated is prone to flooding, you may want to extend your coverage to include damages caused by flood.
                </p>
              <p></p>
                 <p>
                  3.	<strong>Know your deductibles.</strong> When taking up a policy, there are deductibles involved. This is the portion of a claim that you are responsible for. So, it’s important to have accurate knowledge of the deductibles on your policy to enable you factor it into your budget.
                 </p>

                 <p></p>
                 <p>
                  4.	<strong>Be as detailed as possible:</strong>If you have expensive items in your house such as expensive jewelries, one-of-a-kind artwork, etc., you should state the value and factor the replacement cost of these items into your policy.
                 </p>
                 <p></p>

                 <p>Let us help you get started. Visit our website today at <a href="https://nsiainsurance.com/">https://nsiainsurance.com/</a>" for more information or give us a call on 09048418896. </p>


                <p></p>


              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>
