
<div class="container g-pt-100 g-pb-70">
  <div class="row">
    <div class="col-lg-12 g-mb-30">
      <section class="g-bg-gray-light-v5 g-py-20">
        <form novalidate (ngSubmit)="submitForm(beneficiaryForm)" #beneficiaryForm="ngForm" novalidate>
          <div class="container g-py-100 pad-top-50" style="margin-top:-50px">
            <div class="u-shadow-v19 g-brd-around g-brd-gray-light-v4  rounded mx-auto g-pa-30 g-pa-50--md">

              <div class="mb-6">
                <h2 class="mb-4 main-heading">
                  FIRE SPECIAL & PERIL PROPOSAL FORM
                </h2>

                <section class="step-two" >
                  <div class="form-sub-title">
                    <h4>Personal Information</h4>
                  </div>
                  <div class="row">

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>Name of Proposer</label>
                        <input id="NameOfProposal" name="NameOfProposal" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.NameOfProposal" required>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Address of Proposer
                        </label>
                        <input id="AddressOfProposal" name="AddressOfProposal" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.AddressOfProposal" required >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Type Of Business
                        </label>
                        <input id="business_type" name="business_type" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.business_type" required >
                      </div>
                    </div>



                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Means of Identification (CAC, Int’l Passport, etc)
                        </label>
                        <input id="means_of_id" name="means_of_id" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.means_of_id" required >
                      </div>
                    </div>


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                        RC Number/ID Number
                        </label>
                        <input id="rcnumber" name="rcnumber" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.rcnumber" required >
                      </div>
                    </div>



                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          E- mail address
                        </label>
                        <input id="EmailAddress" name="EmailAddress" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.EmailAddress" required type="text" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" >

                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Mobile No
                        </label>
                        <input id="Telephone" name="Telephone" class="form-control form-control-md rounded-0" type="number"
                        placeholder="Required" [(ngModel)]="proposalModel.Telephone" required="required" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==11) return false; return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57));" >

                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                         Alternate Mobile No
                        </label>
                        <input id="AltTelephone" name="AltTelephone" class="form-control form-control-md rounded-0" type="number"
                        placeholder="Required" [(ngModel)]="proposalModel.AltTelephone" required="required" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==11) return false; return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57));" >

                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                         <label>
                          Start Date  (Period of Insurance)
                         </label>
                         <input id="StartDate" name="StartDate" class="form-control form-control-md rounded-0" type="date"
                          placeholder="yyyy-mm-dd" [(ngModel)]="proposalModel.StartDate" >

                      </div>
                   </div>
                   <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                         <label>
                          End Date (Period of Insurance)
                         </label>
                         <input id="EndDate" name="EndDate" class="form-control form-control-md rounded-0" type="date"
                          placeholder="yyyy-mm-dd" [(ngModel)]="proposalModel.EndDate"  >
                      </div>
                   </div>
                  </div>
                </section>

                <section class="step-three">
                  <div class="">
                    <h4>General Questions</h4>
                  </div>
                  <div class="row">

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Risk location/Address (Address of the property to be insured):
                        </label>
                        <input id="Risklocation" name="Risklocation" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.Risklocation" />
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                         Are you the owner of the property(YES or NO):

                        </label>
                        <select name="property_owner" id="property_owner" class="form-control form-control-md rounded-0"
                         [(ngModel)]="proposalModel.property_owner">
                            <option >Select your option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-6" >
                      <div class="form-group g-mb-20">
                        <label>
                          Full description of the property (FLAT, BUNGALOW, DUPLEX ETC):
                        </label>
                        <input id="property_description" name="property_description" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.property_description" >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          If not residential, what is the property used for

                        </label>
                        <input id="property_used_for" name="property_used_for" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.property_used_for" >

                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Is there an exisitng insurance on the property? (Yes or No):
                        </label>
                        <select name="existing_insurance" id="existing_insurance" class="form-control form-control-md rounded-0"
                         [(ngModel)]="proposalModel.existing_insurance">
                            <option >Select your option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          If yes, by which insurance company:

                        </label>
                        <input id="by_which_insurance_company" name="by_which_insurance_company" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.by_which_insurance_company" >

                      </div>
                    </div>


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>

                          Year property was built
                        </label>
                        <input id="YearPropertyWasbuilt" name="YearPropertyWasbuilt" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.YearPropertyWasbuilt" >

                      </div>
                    </div>



                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                        Sum Insured/amount to be insured (Which should be the full reinstatement value)
                        </label>
                        <input id="SumInsured" name="SumInsured" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.SumInsured" >

                      </div>
                    </div>




                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Is the building built of stones, bricks or concrete block walls and roofed with Slates, tiles, asbestos or aluminium?
                        </label>
                        <select name="building_built_of_stones" id="building_built_of_stones" class="form-control form-control-md rounded-0"
                         [(ngModel)]="proposalModel.building_built_of_stones">
                            <option >Select your option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                      </div>
                    </div>


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Have you ever suffered loss by fire?
                        </label>
                        <select name="suffered_loss_by_fire" id="suffered_loss_by_fire" class="form-control form-control-md rounded-0"
                         [(ngModel)]="proposalModel.suffered_loss_by_fire">
                            <option >Select your option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Risk location/address where the contents are located:
                        </label>
                        <input id="Crisklocation" name="Crisklocation" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.Crisklocation" >

                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>

                          Limit per location
                        </label>
                        <input id="limitperlocation" name="limitperlocation" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.limitperlocation" >

                      </div>
                    </div>


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Do you have fire extinguishers? (Yes Or No):
                       </label>

                       <input id="firWxt" name="firWxt" class="form-control form-control-md rounded-0" type="text"
                       [(ngModel)]="proposalModel.firWxt" >

                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          If Yes, How many at the location?
                       </label>

                       <input id="howmanylocationswithfireexistingusher" name="howmanylocationswithfireexistingusher" class="form-control form-control-md rounded-0" type="text"
                       [(ngModel)]="proposalModel.howmanylocationswithfireexistingusher" >

                      </div>
                    </div>
                   <div style="float: left; margin-left: 20px;">
                      <p> Kindly list the contents and the individual insured value of each</p>
                   </div>
                    <div class="col-lg-12">
                      <div class="form-group g-mb-20">
                        <label>
                            Item 1
                       </label>

                       <input id="item1" name="item1" class="form-control form-control-md rounded-0" type="text"
                       [(ngModel)]="proposalModel.item1" >

                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                            Item 2
                       </label>

                       <input id="item2" name="item2" class="form-control form-control-md rounded-0" type="text"
                       [(ngModel)]="proposalModel.item2" >

                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                            Item 3
                       </label>

                       <input id="item3" name="item3" class="form-control form-control-md rounded-0" type="text"
                       [(ngModel)]="proposalModel.item3" >

                      </div>
                    </div>


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                            Item 4
                       </label>

                       <input id="item4" name="item4" class="form-control form-control-md rounded-0" type="text"
                       [(ngModel)]="proposalModel.item4" >

                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                            Item 5
                       </label>

                       <input id="item5" name="item5" class="form-control form-control-md rounded-0" type="text"
                       [(ngModel)]="proposalModel.item5" >

                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                            Item 6
                       </label>

                       <input id="item6" name="item6" class="form-control form-control-md rounded-0" type="text"
                       [(ngModel)]="proposalModel.item6" >

                      </div>
                    </div>


                    <div class="col-lg-12">
                      <div class="form-group g-mb-20">
                        <label>

                          Total sum insured value: N
                       </label>

                       <input id="totalsuminsured" name="totalsuminsured" class="form-control form-control-md rounded-0" type="text"
                       [(ngModel)]="proposalModel.totalsuminsured" >

                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="form-group g-mb-20">
                        <label>
                          LIEN
                       </label>

                       <input id="total_Lien" name="total_Lien" class="form-control form-control-md rounded-0" type="text"
                       [(ngModel)]="proposalModel.total_Lien" >

                      </div>
                    </div>
                          <p style="color:red;">NOTE: THE SUMS INSURED/INSURED VALUES ARE SUBJECT TO AVERAGE. THIS MEANS THAT SHOULD THESE PROVE TO BE INADEQUATE AT THE TIME OF LOSS YOU WILL BE RESPONSIBLE FOR A PROPORTIONATE SHARE OF THE LOSS. IT IS THEREFORE IMPORTANT, IN YOUR OWN INTEREST, THAT YOU INSURE FOR FULL VALUE.

                  </p>

                  </div>
                </section>

               <section class="step-seven" >

                 <div class="container">
                   <h4>Declaration</h4>
                   <p>I/We hereby declare that to the best of my/our knowledge and belief the information herein provided is correct and complete.</p>

                   <!-- <div class="form-group">
                    <input type="checkbox" [(ngModel)]="termAgreed" name="termAgreed" > By checking the box , I agree with the above terms
                  </div> -->

                      <p style="font-size:10px; text-align: justify;">In compliance with NDPR requirements, by completing and submitting this form, you have given consent to NSIA Insurance to receive your data, including your bio data, to enable NSIA Insurance maintain effective communication, send promotional updates, contact you for research purposes and use the data in line with the policy terms.
                      </p>
                      <p style="font-size:10px; text-align: justify;">The data collection may be via hard copies or online forms, validated via JSON WEB TOKEN (JWT). The data will not be accessed by any third party without your consent and any breach of this policy can be addressed legally within the year the breach was reported.</p>

                      <div class="col-lg-4">
                        <div class="form-group g-mb-0">
                          <label style="visibility: hidden;">Frequency</label>
                          <button  type="submit" onclick="return confirm('Are you sure you want to submit this information?')" class="g-brd-main btn-block g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" > Submit
                          </button>
                        </div>

                      </div>

                 </div>

               </section>

              </div>


            </div>
          </div>
        </form>
      </section>
    </div>

    <!-- End Sidebar -->
  </div>
</div>
