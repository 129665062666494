import { Component, OnInit } from '@angular/core';
import { BoardService } from 'src/app/board/board.service';
import { Board } from 'src/app/board/board';
import { AngularFireDatabase } from '@angular/fire/database';
@Component({
  selector: 'app-board-members',
  templateUrl: './board-members.component.html',
  styleUrls: ['./board-members.component.css']
})
export class BoardMembersComponent implements OnInit {
  allBoard: any = [];
  constructor(private db: AngularFireDatabase) {

  }

  ngOnInit(): void {
    this.get()
  }
  get() {
    const ref = this.db.list("board");
    ref.valueChanges().subscribe((data) => {
      this.allBoard = data;
    })
  }
}
