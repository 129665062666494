import { Component, OnInit } from '@angular/core';
import { WhistleClass } from 'src/app/Classes/whistle-class';
import { FormserviceService } from 'src/app/Services/formservice.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-whistle-blower',
  templateUrl: './whistle-blower.component.html',
  styleUrls: ['./whistle-blower.component.css']
})
export class WhistleBlowerComponent implements OnInit {

  formData = new WhistleClass("", "", "", "", "", "", "");

  constructor(private _formService: FormserviceService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    debugger;
  }
  OnFormSubmit(){
    this._formService.startLoading();
   this._formService.blowWhistle(this.formData)
   .subscribe(
     (data) => {
       console.log(data);
       if (data.statusCode == "00") {
         swal.fire("Success", "Your message is submitted. We will contact you shortly", "success");
       } else {
         swal.fire("Error", data.statusMessage, "info");
       }
       this._formService.stopLoading();
       this.formData = new WhistleClass("", "", "", "", "", "", "");
     },
     (error) => {
       console.log(error);
       swal.fire("Fatal", "Internal server error occurred", "error");
       this._formService.stopLoading();
     }
   )

  }
}

