import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-insurance-tips-for-save-smart',
  templateUrl: './insurance-tips-for-save-smart.component.html',
  styleUrls: ['./insurance-tips-for-save-smart.component.css']
})
export class InsuranceTipsForSaveSmartComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
