import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cybersecurity-best-practices',
  templateUrl: './cybersecurity-best-practices.component.html',
  styleUrls: ['./cybersecurity-best-practices.component.css']
})
export class CybersecurityBestPracticesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
