<!-- <div class="g-min-height-450 g-flex-centered g-bg-img-hero g-bg-pos-top-center g-bg-size-cover g-pt-80" style="background-image: url(assets/img/faq1.jpg);">
  <div class="container g-pos-rel g-z-index-1">
      <h2 class="h1 text-uppercase g-color-white g-font-size-40--lg mb-0">FAQ</h2>
      <span class="d-block  g-color-white g-font-weight-1000 g-font-size-20 mb-4"></span>
  </div>
</div> -->
<section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px">
  <div class="container" >
    <div class="d-sm-flex text-center">
      <div class="align-self-center">
        <h2 class="h3 g-font-weight-300 w-100 g-mb-10 g-mb-0--md" ><p>FAQ</p></h2>
      </div>


    </div>
  </div>
</section>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item g-color-primary">
              <span>FAQ</span>
            </li>

      <li class="list-inline-item ml-auto">
        <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a>
      </li>
    </ul>
  </div>
</section>


<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
      <div class="row">
        <div class="col-lg-9 g-mb-30">
              <div id="accordion-12" class="u-accordion u-accordion-color-primary" role="tablist" aria-multiselectable="true">
                      <!-- Card -->
                      <div class="card g-brd-none rounded-0 g-mb-15">
                        <div id="accordion-12-heading-01" class="u-accordion__header g-pa-0" role="tab">
                          <h5 class="mb-0">
                            <a class="d-flex g-color-main g-text-underline--none--hover g-brd-around g-brd-gray-light-v4 g-rounded-5 g-pa-10-15" href="#accordion-12-body-01" data-toggle="collapse" data-parent="#accordion-12" aria-expanded="true" aria-controls="accordion-12-body-01">
                              <span class="u-accordion__control-icon g-mr-10">
                                <i class="fa fa-angle-down"></i>
                                <i class="fa fa-angle-up"></i>
                              </span>
                             <p> What is a premium?</p>
                            </a>
                          </h5>
                        </div>
              <div id="accordion-12-body-01" class="collapse show" role="tabpanel" aria-labelledby="accordion-12-heading-01" data-parent="#accordion-12">
                      <div class="u-accordion__body g-color-gray-dark-v5">
                             <p>This is a consideration paid by the insured to the insurance company for cover to be provided.</p>
                      </div>
                    </div>
                  </div>
                  <!-- End Card -->

                  <!-- Card -->
                  <div class="card g-brd-none rounded-0 g-mb-15">
                    <div id="accordion-12-heading-02" class="u-accordion__header g-pa-0" role="tab">
                      <h5 class="mb-0">
                        <a class="collapsed d-flex g-color-main g-text-underline--none--hover g-brd-around g-brd-gray-light-v4 g-rounded-5 g-pa-10-15" href="#accordion-12-body-02" data-toggle="collapse" data-parent="#accordion-12" aria-expanded="false" aria-controls="accordion-12-body-02">
                          <span class="u-accordion__control-icon g-mr-10">
                            <i class="fa fa-angle-down"></i>
                            <i class="fa fa-angle-up"></i>
                          </span>
                          <p> What is a Renewal?</p>
                        </a>
                      </h5>
                    </div>
                    <div id="accordion-12-body-02" class="collapse" role="tabpanel" aria-labelledby="accordion-12-heading-02" data-parent="#accordion-12">
                      <div class="u-accordion__body g-color-gray-dark-v5">
                              <p>  A renewal constitutes a new contract of insurance, which comes up after the expiration of an existing policy.</p>
                      </div>
                    </div>
                  </div>
                  <!-- End Card -->

                  <!-- Card -->
                  <div class="card g-brd-none rounded-0 g-mb-15">
                    <div id="accordion-12-heading-03" class="u-accordion__header g-pa-0" role="tab">
                      <h5 class="mb-0">
                        <a class="collapsed d-flex g-color-main g-text-underline--none--hover g-brd-around g-brd-gray-light-v4 g-rounded-5 g-pa-10-15" href="#accordion-12-body-03" data-toggle="collapse" data-parent="#accordion-12" aria-expanded="false" aria-controls="accordion-12-body-03">
                          <span class="u-accordion__control-icon g-mr-10">
                            <i class="fa fa-angle-down"></i>
                            <i class="fa fa-angle-up"></i>
                          </span>
                          <p> What is a Policy?</p>
                        </a>
                      </h5>
                    </div>
                    <div id="accordion-12-body-03" class="collapse" role="tabpanel" aria-labelledby="accordion-12-heading-03" data-parent="#accordion-12">
                      <div class="u-accordion__body g-color-gray-dark-v5">
                             <p>This is a document which contains the terms of contract for a cover purchased.</p>>
                      </div>
                    </div>
                  </div>
                  <div class="card g-brd-none rounded-0 g-mb-15">
                          <div id="accordion-12-heading-04" class="u-accordion__header g-pa-0" role="tab">
                            <h5 class="mb-0">
                              <a class="collapsed d-flex g-color-main g-text-underline--none--hover g-brd-around g-brd-gray-light-v4 g-rounded-5 g-pa-10-15" href="#accordion-12-body-04" data-toggle="collapse" data-parent="#accordion-12" aria-expanded="false" aria-controls="accordion-12-body-04">
                                <span class="u-accordion__control-icon g-mr-10">
                                  <i class="fa fa-angle-down"></i>
                                  <i class="fa fa-angle-up"></i>
                                </span>
                               <p> What is a proposal form?</p>
                              </a>
                            </h5>
                          </div>
                          <div id="accordion-12-body-04" class="collapse" role="tabpanel" aria-labelledby="accordion-12-heading-04" data-parent="#accordion-12">
                            <div class="u-accordion__body g-color-gray-dark-v5">
                                <p> It is a form that the insured completes about the a risk/danger he/she wants to buy cover for.</p>
                            </div>
                          </div>
                        </div>

                        <div class="card g-brd-none rounded-0 g-mb-15">
                              <div id="accordion-12-heading-05" class="u-accordion__header g-pa-0" role="tab">
                                <h5 class="mb-0">
                                  <a class="collapsed d-flex g-color-main g-text-underline--none--hover g-brd-around g-brd-gray-light-v4 g-rounded-5 g-pa-10-15" href="#accordion-12-body-05" data-toggle="collapse" data-parent="#accordion-12" aria-expanded="false" aria-controls="accordion-12-body-05">
                                    <span class="u-accordion__control-icon g-mr-10">
                                      <i class="fa fa-angle-down"></i>
                                      <i class="fa fa-angle-up"></i>
                                    </span>
                                   <p>Who is the insured?</p>
                                  </a>
                                </h5>
                              </div>
                              <div id="accordion-12-body-05" class="collapse" role="tabpanel" aria-labelledby="accordion-12-heading-05" data-parent="#accordion-12">
                                <div class="u-accordion__body g-color-gray-dark-v5">
                                    <p>The individual opting/buying a cover from the insurance company.</p>
                                </div>
                              </div>
                            </div>

                            <div class="card g-brd-none rounded-0 g-mb-15">
                                  <div id="accordion-12-heading-06" class="u-accordion__header g-pa-0" role="tab">
                                    <h5 class="mb-0">
                                      <a class="collapsed d-flex g-color-main g-text-underline--none--hover g-brd-around g-brd-gray-light-v4 g-rounded-5 g-pa-10-15" href="#accordion-12-body-06" data-toggle="collapse" data-parent="#accordion-12" aria-expanded="false" aria-controls="accordion-12-body-06">
                                        <span class="u-accordion__control-icon g-mr-10">
                                          <i class="fa fa-angle-down"></i>
                                          <i class="fa fa-angle-up"></i>
                                        </span>
                                       <p> What are the benefits of insurance?</p>
                                      </a>
                                    </h5>
                                  </div>
                                  <div id="accordion-12-body-06" class="collapse" role="tabpanel" aria-labelledby="accordion-12-heading-06" data-parent="#accordion-12">
                                    <div class="u-accordion__body g-color-gray-dark-v5">
                                       <p>  To restore the individual to his/her original state before the occurrence of the loss, It gives a feeling of security knowing that you are covered in the case of any eventual loss/Mishap. Examples of risk that can be insured: Theft, motor accident, fire.</p>
                                    </div>
                                  </div>
                                </div>

                                <div class="card g-brd-none rounded-0 g-mb-15">
                                      <div id="accordion-12-heading-07" class="u-accordion__header g-pa-0" role="tab">
                                        <h5 class="mb-0">
                                          <a class="collapsed d-flex g-color-main g-text-underline--none--hover g-brd-around g-brd-gray-light-v4 g-rounded-5 g-pa-10-15" href="#accordion-12-body-07" data-toggle="collapse" data-parent="#accordion-12" aria-expanded="false" aria-controls="accordion-12-body-07">
                                            <span class="u-accordion__control-icon g-mr-10">
                                              <i class="fa fa-angle-down"></i>
                                              <i class="fa fa-angle-up"></i>
                                            </span>
                                           <p>What kind of risk/danger can be insured?</p>
                                          </a>
                                        </h5>
                                      </div>
                                      <div id="accordion-12-body-07" class="collapse" role="tabpanel" aria-labelledby="accordion-12-heading-07" data-parent="#accordion-12">
                                        <div class="u-accordion__body g-color-gray-dark-v5">
                                           <p> A loss that can be quatified in terms of financial loss.</p>
                                        </div>
                                      </div>
                                    </div>


                                <div class="card g-brd-none rounded-0 g-mb-15">
                                      <div id="accordion-12-heading-08" class="u-accordion__header g-pa-0" role="tab">
                                        <h5 class="mb-0">
                                          <a class="collapsed d-flex g-color-main g-text-underline--none--hover g-brd-around g-brd-gray-light-v4 g-rounded-5 g-pa-10-15" href="#accordion-12-body-08" data-toggle="collapse" data-parent="#accordion-12" aria-expanded="false" aria-controls="accordion-12-body-08">
                                            <span class="u-accordion__control-icon g-mr-10">
                                              <i class="fa fa-angle-down"></i>
                                              <i class="fa fa-angle-up"></i>
                                            </span>
                                           <p>What is Insurance?</p>
                                          </a>
                                        </h5>
                                      </div>
                                      <div id="accordion-12-body-08" class="collapse" role="tabpanel" aria-labelledby="accordion-12-heading-08" data-parent="#accordion-12">
                                        <div class="u-accordion__body g-color-gray-dark-v5">
                                           <p>   Insurance is an effective method of handling risks/dangers that people are exposed to on daily basis, e.g. loss from fire, motor accident or any other type of accident.</p>
                                        </div>
                                      </div>
                                    </div>
                  <!-- End Card -->
                </div>



                      </div>


        <!-- Sidebar -->
        <div class="col-lg-3 g-mb-30">
          <!-- Links -->
          <app-sidebar></app-sidebar>
          <!-- End Fast Facts -->
        </div>
        <!-- End Sidebar -->
      </div>
    </div>





