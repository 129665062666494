
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0" style="text-align: center;">
              <strong>
                 <p>NSIA INSURANCE APPOINTS THREE NEW DIRECTORS</p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <!-- <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/chairman.jpg" alt="Image Description">
              </div> -->
              <!-- End About Image -->
              <div class="col-md-12 col-lg-12">
                 <!-- About Info -->
                 <p>The Board of Directors of NSIA Insurance Limited is pleased to announce the appointment of Mansan Dominique Diagou Epse Ehilé, Apollos Ikpobe and Hélène Konian as Non-Executive Directors of the company with effect from February 2020.
                 </p>
                 <p></p>
              </div>
           </div>
           <br/><br/>
           <div class="row justify-content-between">
            <div class="col-md-3 col-lg-4">
              <img class="img-fluid" src="assets/img/Board_Profile_Pics/Dominique_DIAGOU.png" alt="Image Description">
           </div>
              <div class="col-md-8 col-lg-8">

                 <p><strong>Mansan Dominique</strong> currently serves as the Central Operating Director at NSIA Participations and is a professional with over fifteen (15) years of experience in Finance, Business Development, Human Resources and Company Management.
                 </p>
                 <p></p>

                 <p>Dominique joined the NSIA Group in June 2004 as a Business Development Director at NSIA Vie Côte d’Ivoire and rose through the ranks to become the Managing Director of NSIA Vie Assurance (Côte d’Ivoire) in May 2012.</p>
                <p></p>

                <p>She holds a Bachelor in Business Administration from the Institut Franco-Americain De Management (IFAM), France, and a Master’s in Business Administration from the Bryant University, USA.</p>

              </div>

           </div>

           <br/><br/>
           <div class="row justify-content-between">
            <!-- About Image -->

            <div class="col-md-3 col-lg-4">
               <img class="img-fluid" src="assets/img/Board_Profile_Pics/Director_Apollos.png" alt="Image Description">
            </div>
            <!-- End About Image -->
            <div class="col-md-8 col-lg-8">
               <!-- About Info -->
               <p>
                <strong>Apollos Ikpobe</strong> is a business-minded professional with over twenty-five (25) years of experience in Accounting, Taxation, Credit Administration, General Management and Audit.
               </p>
               <p></p>
               <p>
                Apollos was the Branch Manager at Zenith Bank in 1996 where he turned around the Marina branch from a loss-making branch to the 4th most profitable branch in 7 months. He rose through the ranks during his time in Zenith Bank Plc to become an Executive Director in 2005 up until he left to join United Bank for Africa in 2013 as the Deputy Managing Director.
               </p>
               <p></p>
               <p>He is a Fellow of the Institute of Chartered Accountants of Nigeria (ICAN), the Institute of Credit Administration of Nigeria (ICA) and the Chartered Institute of Taxation of Nigeria (CITN).</p>
               <p></p>
               <p>He holds a Master’s degree in Banking & Finance from the University of Lagos.</p>
            </div>
          </div>

          <br/><br/>
           <div class="row justify-content-between">
            <div class="col-md-3 col-lg-4">
              <img class="img-fluid" src="assets/img/Board_Profile_Pics/photo.png" alt="Image Description">
           </div>
              <div class="col-md-8 col-lg-8">

                 <p><strong>Hélène Konian</strong> is a seasoned professional with over thirty (30) years of experience in Legal, Banking and General Management with a focus on Corporate Governance and promotion of an ethical culture in the corporate world.
                 </p>
                 <p></p>

                 <p>Hélène started her career as a legal officer in BIAO, a commercial bank in Abidjan, in 1983 and rose through her career ladder to become the Company Secretary/Legal Manager of Ecobank Group’s Retail Bank division from 2009 to 2016.</p>
                <p></p>

                <p>She has served on a number of boards including those of GEPEX (Association of Ivorian Coffee & Cocoa Exporters, Abidjan), OCTIDE (Financial services, Paris), and LOCAFIRQUE (Leasing, Dakar).</p>

                <p></p>
                <p>Hélène has a Master’s degree in Business Law from the University of Paris and a post graduate degree (D.E.S.S) in Banking Law & Economy from the University of Paris.</p>

                <p></p>
                <p>We are excited to have the new directors on the team as we progress towards our vision of being the trusted and preferred nancial services partner in every home and enterprise in Nigeria.</p>
              </div>

           </div>

        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>