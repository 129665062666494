import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-satisfying-the21century-customer',
  templateUrl: './satisfying-the21century-customer.component.html',
  styleUrls: ['./satisfying-the21century-customer.component.css']
})
export class SatisfyingThe21centuryCustomerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
