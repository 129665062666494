
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;">CYBERSERCURITY BEST PRACTICES</p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/Cybersecurity.jpeg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>Due to Covid-19, the increase of employees working from home has gotten up to 70% as employers have discovered that when employees work remotely, they remain productive and less time is spent commuting. However, despite the benefits of remote working, there are cyber security risks that an organization may suffer if proper planning is not done to ensure protection from cyber-attacks.
                 </p>
                 <p></p>
                 <p>One important thing to bear in mind is that your personal devices, company's devices, customer sensitive data are all as secure as the weakest link. Below will provide you with ways to protect your devices, customer's sensitive data, etc. from cyber-attacks.
                 </p>

                  <p></p>

                  <p><strong>How to protect your devices from cyberattacks:</strong></p>

              </div>
           </div>


          <div class="row justify-content-between">


              <div class="col-md-12">

                <p></p>
            <p><strong>1. Secure your home router</strong><br/>
            </p>
               <p></p>
              <p>The simple act of changing your router's password from the default one to a strong and exclusive one, can protect your home network from cyber attacks. Also, make sure to download firmware updates so that known vulnerabilities are not exploited.</p>
              <p></p>
              <p><strong>2. Use strong passwords and invest in a password manager</strong><br/>
              </p>
                <p></p>
                <p>Try not to use easy or repetitive passwords. Don't use anything that is related to you, such as your address, date of birth, etc. Invest in a password manager as they help with creating strong passwords and remember them. They also make it easy to use a unique password for each website you use. This means that if you reuse the same password and it is exposed to a cyber attack, your other accounts will remain safe.</p>
                <p></p>
                <p><strong>3. Two-factor authentication and use an authenticator app</strong></p>
                <p></p>
                <p>Two-factor authentication is an authentication method where access is granted only after successfully presenting two pieces of evidence to an authentication mechanism.  It can dramatically reduce the risk of successful malware infections because even if the attacker is able to get your password, they are unable to login because they do not have the second piece of evidence.</p>
              <p></p>
              <p><strong>4. Beware of Covid-19 related scams</strong></p>
              <p></p>
              <p>Cyber attackers have largely taken advantage of the Covid-19 outbreaks. It has been used as topics of phishing and scam links. Phishing is a cyber attack that uses disguised emails as a weapon to its targets. Employers should ensure that they train their employees on how to identify suspicious links or attachment files related to Covid-19 and how to get rid of them.</p>


              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>