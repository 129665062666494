import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { NgForm } from '@angular/forms'
import { GoodsInTransitProposalForm } from 'src/app/Classes/goods-in-transit-proposal-form';
import { FormserviceService } from 'src/app/Services/formservice.service';
@Component({
  selector: 'app-goods-proposal',
  templateUrl: './goods-proposal.component.html',
  styleUrls: ['./goods-proposal.component.css']
})
export class GoodsProposalComponent implements OnInit {
  model;
  proposalModel = new  GoodsInTransitProposalForm("","","","","",null,
  null,"","","","",null,"","",null, "","", "","","","","","","");
  constructor(private _formservice: FormserviceService) { }

  ngOnInit(): void {
  }
  submitForm(myForm: NgForm){

    console.log($('#Telephone').val());
    var telLength = $('#Telephone').val().toString().length;

    if(telLength < 10){
      alert("Your telephone number is not correct");
      this._formservice.stopLoading();
      return;
    }

    this._formservice.startLoading();
    this._formservice.sendGoodsIntransitProposalForm(this.proposalModel)
      .subscribe(
        (data) => {
          if (data.statusCode == "00") {
            alert( "Your form is submitted");
            myForm.resetForm();
          } else {
           alert(data.statusMessage);
          }
          this._formservice.stopLoading();
        },
        (error) => {
          console.log(error);
          //Swal.fire("fatal", "internal server error occurred", "error");
          alert("Your form is submitted");
          this._formservice.stopLoading();
        }
      )

  }
}
