import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-effective-social-media-strategies-for-brands',
  templateUrl: './effective-social-media-strategies-for-brands.component.html',
  styleUrls: ['./effective-social-media-strategies-for-brands.component.css']
})
export class EffectiveSocialMediaStrategiesForBrandsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
