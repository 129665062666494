import { NavBarTabViewComponent } from './../nav-bar-tab-view/nav-bar-tab-view.component';
import { Component, ViewChild, OnInit, Renderer2, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

// @Component({
//   selector: 'ngbd-modal-content',
//   templateUrl:'../nav-bar-tab-view.component.html'
// })
// export class NgbdModalContent {
//   @Input() name;

//   constructor(public activeModal: NgbActiveModal) {}
// }

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {
  isCollapsed: boolean = true;
  baseUrl: any;
  @ViewChild('navbarToggler') navbarToggler: ElementRef;
  @Input() name;
  constructor(private router: Router, private el: ElementRef, private modalService: NgbModal, private renderer: Renderer2) { }
  faBars = faBars;
  faTimes = faTimes;
  ngOnInit(): void {
    this.baseUrl = window.location.origin + "";
  }


  toggleMenu() {
    const menuDiv = this.el.nativeElement.querySelector('.menu');
    const isMenuOpen = menuDiv.classList.contains('open');

    if (isMenuOpen) {

      setTimeout(() => {
        this.renderer.removeClass(menuDiv, 'open');
      }, 0);
    } else {
      setTimeout(() => {
        this.renderer.addClass(menuDiv, 'open');
      }, 0);
    }
  }

  toggleMenuA() {
    const menuDiv = this.el.nativeElement.querySelector('.prD');
    const isMenuOpen = menuDiv.classList.contains('open');

    if (isMenuOpen) {
      this.renderer.removeClass(menuDiv, 'open');
    } else {
      this.renderer.addClass(menuDiv, 'open');
    }
  }
  toggleMenuB() {
    const menuDiv = this.el.nativeElement.querySelector('.aBou');
    const isMenuOpen = menuDiv.classList.contains('open');
    if (isMenuOpen) {
      this.renderer.removeClass(menuDiv, 'open');
    } else {
      this.renderer.addClass(menuDiv, 'open');
    }
  }
  toggleMenuC() {
    const menuDiv = this.el.nativeElement.querySelector('.coNT');
    const isMenuOpen = menuDiv.classList.contains('open');
    if (isMenuOpen) {
      this.renderer.removeClass(menuDiv, 'open');
    } else {
      this.renderer.addClass(menuDiv, 'open');
    }
  }
  toggleMenuD() {
    const menuDiv = this.el.nativeElement.querySelector('.fBus');
    const isMenuOpen = menuDiv.classList.contains('open');
    if (isMenuOpen) {
      this.renderer.removeClass(menuDiv, 'open');
    } else {
      this.renderer.addClass(menuDiv, 'open');
    }
  }
  navBarTogglerIsVisible() {
    return this.navbarToggler.nativeElement.offsetParent !== null;
  }
  collapseNav() {
    if (this.navBarTogglerIsVisible()) {
      this.navbarToggler.nativeElement.click()
    }
  }
  open() {
    const modalRef = this.modalService.open(NavBarTabViewComponent, { centered: true });
    modalRef.componentInstance.name = 'World';
  }

  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
  }
  loadScripts() {

    const node = document.createElement('script');
    node.src = "assets/js/nav.js";
    node.type = 'text/javascript';
    node.async = false;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);

  }

  goToUrl() {
    // this.router.navigate(['/'])
    document.location.href = "https://ips.nsiainsurance.com/nsiaweb/"
  }
  onclickLink() {
    this.router.navigateByUrl("/")
  }

  goToCareer() {
    window.open("https://careers.nsiainsurance.com/");
  }


}
