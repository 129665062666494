export class PersonalAccidentProposalForm {
  constructor(
    public NameOfProposal:string,
    public AddressOfProposal:string,
    public TradeOrBusiness:string,
    public EmailAddress:string,
    public Telephone:string,
    public StartDate: Date,
    public EndDate: Date,
    public means_of_id:string,
    public NatureOfContract:string,
    public TenantOrOwner:string,
    public TenantExtent:string,
    public dob:Date,
    public occupier : string,
    public WorkAtPremises: string,
    public EstimatedAnnualValue: string,
    public LimitLiaibility: string,
    public PreviousLosses: string,
    public ProposalDecline: string,
    public activities_business: string,
    public typeOfSport:string,
    public Engaged:string,
    public payingpassenger:string,
    public DeathBenefit:string,
    public PermanentDisability:string,
    public TemporaryTotalDisablement: string,
    public MedicalExpenses: string,
    public applicant_principal_association_company:string,
    public insured_death:string,
    public estimatedannualincome:string,
    public preexisting:string,
    public similarpolicy:string,
    public Name:string,
    public Relationship:string

  ) {

  }
}

