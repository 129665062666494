
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;"><strong>ALWAYS READ YOUR POLICY DOCUMENTS ALL THE WAY THROUGH! HERE’S WHY…</strong></p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/always.jpg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>As far as reading materials go, insurance policies will probably never make it onto the top of the reading list for most people. Apart from being lengthy, the language of policies makes them a bit difficult for individuals without any background in insurance to understand. We wrote an article to help you understand basic insurance terminologies and their meanings, read </p>
                 <p></p>

              </div>
           </div>

          <div class="row justify-content-between">


              <div class="col-md-12">

                <p>
                  It is essential that you read and most importantly understand what your policy says because it has important implications for whether or not the insurance you bought does what you expect it to do for you. That being said, here are four good reasons to read your policy documents:
                 </p>

                 <p></p>
                  <p><strong>1.	Your policy contains important dates</strong><br/>
                    Insurance policies can be time sensitive. Often, they contain dates by which something must be done.  For instance, there is a section that mentions the time-frame within which you have to file a claim after an accident. If you don’t read your policy and are not even aware of such a timeline the chances of your claim being rejected are quite high.
                  </p>
              <p></p>
                 <p><strong>2.	Verify personal details</strong> <br/>
                  Personal data provided to the insurance company forms a very important part for settlement of all claims. Make sure that all personal details, such as your name, age, etc., are mentioned correctly and also that all aspects related to personal habits or health details are mentioned correctly and honestly. In cases where it is not declared or is erroneous on policy documents, the insurer may refuse to honour the claim.
                 </p>

                 <p></p>
                 <p><strong>3.	Reading your policy helps you stay on top of modifications</strong> <br/>
                  The ‘Endorsement’ section in your policy offers you options to add coverage or increase coverage limits. It’s important that you keep track of any changes made under the endorsement so you are not caught by surprise.
                 </p>
                 <p></p>

                 <p><strong>4.	Your policy sets out important conditions</strong> <br/>
                  This is the section that tells you what the insurer’s responsibilities are and what your responsibilities are as the customer.  It would also include procedures to follow after a loss. If the policy conditions are not met the insurer can reject any claim you make under the policy.
                 </p>
                 <p></p>

                 <p>Don’t take that risk. Carefully read your policy documents and know what’s required of you. </p>
                 <p></p>
                 <p>Need more details? Visit <a href="https://www.nsiainsurance.com">www.nsiainsurance.com</a> or call 09048418896.</p>

              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>
