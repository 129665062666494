import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-insurance-agriculture',
  templateUrl: './insurance-agriculture.component.html',
  styleUrls: ['./insurance-agriculture.component.css']
})
export class InsuranceAgricultureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
