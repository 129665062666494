
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;"><strong>SIMPLE WAYS TO BE MORE PRODUCTIVE EVERYDAY
                </strong></p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/dec/Productivity.jpeg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
              <p> <strong>Step 1: Manage your energy, not your time.</strong></p>
                 <p>If you take a moment to think about it, you will probably realize that you are better at doing certain tasks at certain times. What type of energy do you have in the morning? Afternoon? Evening? Determine what tasks each energy level and time of day are best suited for, and match them for maximum output.</p>

              </div>
           </div>

          <div class="row justify-content-between">


              <div class="col-md-12">
                <p></p>

                  <p><strong>Step 2: Prepare the night before.</strong><br/>
                    If you only do one thing each day then spend a few minutes each night organizing your to–do list for tomorrow. It takes 10 minutes that night and saves you time the next day.
                  </p>
                  <p></p>
                 <p><strong>Step 3: Limit the use of your mobile phone</strong> <br/>
                  This eliminates the urge to check text messages, Facebook, Twitter, and so on. This simple strategy eliminates the likelihood of slipping into half–work where you waste time dividing your attention among meaningless tasks.
                 </p>

                 <p></p>
                 <p><strong>Step 4: Work in a cool place.</strong> <br/>
                  Have you ever noticed how you feel slow and inactive in a hot room? Turning the temperature down or moving to a cooler place is an easy way to focus your mind and body.
                 </p>
                 <p></p>

                 <p><strong>Step 5: Sit up or stand up.</strong> <br/>
                  When you sit hunched over, your chest is in a collapsed position where your diaphragm is pressing against the bottom of your lungs. This hinders your ability to breathe easily and deeply. Sit up straight or stand up and you’ll find that you can breathe easier. As a result, your brain will get more oxygen and you’ll be able to concentrate better.
                 </p>
                 <p></p>
                <p><strong>Step 6: Develop a “pre–game routine” to start your day.</strong>
                  <br/>
                  Some people kick off their day with ten minutes of meditation. Similarly, you should have a sequence that starts your morning ritual. This tiny routine signals to your brain that it’s time to get into work mode or exercise mode or whatever mode you need to be in to accomplish your task. Additionally, a pre–game routine helps you overcome a lack of motivation and get things done even when you don’t feel like it.
                </p>

<p></p>




              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>