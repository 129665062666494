export class ContractorAllRiskProposalForm {
  constructor(
    public NameOfProposal: string,
    public AddressOfProposal:string,
    public TradeOrBusiness:string,
    public EmailAddress:string,
    public Telephone:string,
    public Rcnumber:string,
    public idnumber:string,
    public dateofincorporation: Date,
    public theprincipal:string,
    public SubContractor:string,
    public locationofcontractsite:string,
    public decriptionofcontract:string,
   // public HEIGHTANDDEPTH:string,
    public CONTRACTSITEPRONE:string,
    public CONTRACTOR_HAVE_EXPERIENCE:string,
    public constructionPeriodStart:Date,
    public constructionPeriodEnd:Date,
    public maintenancePeriodStart:Date,
    public maintenancePeriodEnd:Date,
    public Permanentworks:string,
    public TemporaryWorks:string,
    public RemovalofDebris:string,
    public Architects:string,
    public ConstructionalPlant:string,
    public ExistingBuilding:string,
    public COVERAGE_FOR_THIRD_PARTY_LIABILITY:string,
    public MinimumDistanceOfNeigboring:string,
    public POSSIBILITY_OF_A_THIRD_CATASTROPHE:string,

  //  public WITH_REGARDS_TO_PROPERTY_DAMAGE:string,
   // public WITH_REGARDS_TO_BODILY_INJURY:string,
    // public FOR_PROPERTY_DAMAGE:string,
    // public FOR_BODILY_INJURY:string,
    public existingathirdparty:string,
    public CLAIMS_EXPERIENCE:string,
    public INSURER_DECLINED_TO_RENEW:string,
    // public DIRECTORS_OR_PARTNERS_EVER_BEEN:string,
    // public DIRECTOR_BEEN_INVOLVED:string,
    // public DATE_FROM_WHICH_INSURANCE_IS_REQUIRED:Date,
    public StartDate:Date,
    public EndDate:Date,
    public AltTelephone:String,
    public Tunnelling:string
  ) {

  }
}
