<!-- <ckeditor [editor]="Editor" data="<p>Hello, world!</p>"></ckeditor> -->
<!-- <ckeditor [editor]="Editor" data="<p>Hello, world!</p>" (ready)="onReady($event)"></ckeditor> -->



<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
     <ul class="d-flex justify-content-between u-list-inline">
        <li class="list-inline-item g-mr-15">
           <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
           <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
        </li>
        <li class="list-inline-item g-color-primary">
           <span>Blog</span>
        </li>
        <li class="list-inline-item ml-auto">
           <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
        </li>
     </ul>
  </div>
</section>

<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                <!-- {{blogDetails.length >100 ? (blogDetails: slice: 100) + '...' : blogDetails}} -->
                 <p style="text-align:center;"><strong>{{blogDetails}}</strong></p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                <!-- <img [src]="url" height="200"> <br/> -->
                 <img  ng-if="url!=null" class="img-fluid" [src]="url">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                <div [innerHTML]="comment"></div>
                 <!-- About Info -->
                 <!-- <p>As far as reading materials go, insurance policies will probably never make it onto the top of the reading list for most people. Apart from being lengthy, the language of policies makes them a bit difficult for individuals without any background in insurance to understand. We wrote an article to help you understand basic insurance terminologies and their meanings, read </p>
                 <p></p> -->

              </div>
           </div>

          <div class="row justify-content-between">


              <div class="col-md-12">
                <form autocomplete="off" novalidate [formGroup]="blogForm">
                  <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
                    <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
                      <div>
                        <input type='file' (change)="onSelectFile($event)">
                      </div>

                      <div   >
                        <label>Blog Title </label>
                        <br/>
                        <p>
                          <input type="text" (keydown.enter)="saverange(myInput.value)" #myInput  formControlName="title"/>
                        </p>
                      </div>

                    </div>

                        <ckeditor [editor]="Editor"  (change)="onChange($event)" data="" (ready)="onReady($event)"></ckeditor>
                  </div>
                  <div class='box-footer'>
                    <button (click)="saveBlog()" btn btn-success pull-right>Save Blog</button>
                  </div>
                </form>


              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>
