import {
  Component,
  ContentChildren,
  QueryList,
  AfterContentInit,
  ViewChild,
  ComponentFactoryResolver,
  ViewContainerRef,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { TabComponent } from '../tab/tab.component';
import { Router} from '@angular/router';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css']
})
export class TabsComponent implements AfterContentInit, OnChanges {
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  lifeModel: string;
  generalModel: string;
  constructor() {

  // console.log(this.lifeModel);

  }
  ngAfterContentInit() {
    this.lifeModel ="Life Business";
    this.generalModel ="General Business";
    // get all active tabs
    let activeTabs = this.tabs.filter((tab) =>tab.active);
    console.log(this.tabs);
    console.log(activeTabs);

    // if there is no active tab set, activate the first
    if(activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
      console.log(this.tabs.first);
    }
    else {
      this.selectTab(this.tabs.last);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    console.log(changes);
    console.log(this.tabs);

  }
  selectTab(tab){
    console.log(tab);
    console.log(this.tabs);
    if(tab.tabTitle === "Life Business") {
      // activate the tab the user has clicked on.
      tab.active = true;

      // // deactivate all tabs
    // this.tabs.toArray().forEach(tab => tab.active === false);
    // console.log(this.tabs.toArray().forEach(tab => tab.active === false));
    this.tabs.forEach(x=> {
      console.log(x);
      if(x.tabTitle === tab.tabTitle && x.active === tab.active) {

      } else {
        x.active = false;
      }
    });
    }
    else {
      tab.active = true;

      this.tabs.forEach(x=> {
        console.log(x);
        if(x.tabTitle === tab.tabTitle && x.active === tab.active) {

        } else {
          x.active = false;
        }
      });
    }
    // // deactivate all tabs
    // this.tabs.toArray().forEach(tab => tab.active === false);
    // console.log(this.tabs.toArray().forEach(tab => tab.active === false));
  }


}
