export class FireSpecialPerilProposalForm {
  constructor(

    public NameOfProposal:string,
    public AddressOfProposal:string,
    public business_type:string,
    public EmailAddress:string,
    public Telephone:string,
    public StartDate: Date,
    public EndDate: Date,
    public means_of_id:string,
    public rcnumber:string,
    public Risklocation:string,
    public property_owner:string,
    public property_description:string,
    public property_used_for : string,
    public existing_insurance: string,
    public by_which_insurance_company: string,
    public fireexisitn: string,
    public howmanylocation: string,
    public YearPropertyWasbuilt: string,
    public SumInsured: string,
    public building_built_of_stones:string,
    public suffered_loss_by_fire:string,
    public Crisklocation:string,
    public limitperlocation:string,
    public firWxt:string,
    public howmanylocationswithfireexistingusher: string,
    public estimated_annual_carrying: string,
    public item1:string,
    public item2:string,
    public item3:string,
    public item4:string,
    public item5:string,
    public item6:string,
    public totalsuminsured:string,
    public total_Lien:string,
    public AltTelephone: string

  ) {

  }
}
