
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;"><strong>TRAVEL SAFETY TIPS</strong></p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/dec/Travel.jpeg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>Most popular destinations offer safe environments for tourists, but it is always smart to take precautions, no matter where you’re going. Here are tips to keep you safe when you visit another country.</p>
                 <p></p>


                 <p></p>

              </div>
           </div>

          <div class="row justify-content-between">


              <div class="col-md-12">
                <p><strong>Personal safety</strong></p>
                <p>
                 Research your destination beforehand. When you arrive, check with local tourism officials for advice on safe and unsafe areas. Here are a few other tips to keep in mind while exploring the sights:
                </p>
                <ul>
                  <li>
                   -	Avoid actions that make you look like a visitor (e.g. carrying a camera, guidebook, maps or large backpack).
                   </li>
                   <li>
                   -	Be discreet when handling money and avoid using ATMs at night.
                   </li>
                   <li>
                   -	If you are in a foreign country, always carry the contact information of your embassy or consulate.


                  </li>
                </ul>
                <p><strong>Immunizations</strong><br/>
                  If you are going to an exotic location, minimize your chance of illness by getting the necessary immunizations from your family doctor. Some countries actually require that you have certain immunizations before you are allowed to enter. Check the country's official website to make sure your shots are up to date and find out what documentation you need as proof of immunization.
                 </p>

                 <p></p>
                  <p><strong>Travel documents</strong><br/>
                    Before you leave, write down the details of your passport and visa (if applicable), credit and debit card details, travel insurance information, and driver’s license. Keep this information in a secure location. If anything gets stolen, like your wallet, you’ll have a record and be able to quickly take action.
                  </p>
              <p></p>
                 <p><strong>Cyber safety</strong> <br/>
                  People often use social media to ask for sightseeing and dining recommendations before they travel, but you should exercise caution when posting details about your travel plans. It leaves your home vulnerable to break-ins. Try to make your home look lived-in while you’re away. When travelling, avoid using public Wi-Fi to access confidential information like credit card and banking websites.
                 </p>

                 <p></p>
                 <p><strong>Check your travel coverage</strong> <br/>
                  Traveling, whether for work, education or leisure comes with associated risks. NSIA Insurance provides International Travel Insurance to protect you from those risks, ensuring you have a stress-free trip. Medical emergencies such as sudden sickness, accidents and dental emergencies or trip cancellations and loss of baggage may ruin a vacation, but all these will be covered under your travel insurance policy.
                 </p>
                 <p></p>
<p>Give us a call on 09048418896 or click <a href="https://nsiainsurance.com/travel-insurance">here</a> to learn more.</p>
              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>