<div
  class="g-min-height-300 g-flex-centered g-bg-img-hero g-bg-pos-top-center g-bg-size-cover g-pt-80"
>
  <div class="container g-pos-rel g-z-index-1">
    <!-- <img
      style="display: block; max-width: 82%; margin: auto; margin-top: 50px"
      src="../../../assets/img/banners/campaign-plan-b.jpg"
      alt=""
    /> -->
    <section class="h-e-p-50">
      <div class="container">
        <div class="naicom-div">
          <div class="login-home-wrapper">
            <div class="login-header">NIACOM Unique ID Generator</div>
            <app-tabs>
              <app-tab [tabTitle]="'Life Business'">
                <div class="login-portal">
                  <div  class="form-group-new">
                    <form novalidate [formGroup]="lifeBusienssFormGroup">

                   
                    <div>
                      <label class="labels">Risk</label>
                      <select (change)="selectLifeRisk($event)">
                        <option >Select Life Risk</option>
                        <option *ngFor="let risk of riskLifeList" [value]="risk.riskId">{{risk.risk1}}</option>
                      </select>
                    </div>
                    <div>
                        <label class="labels">SuRisk</label>
                        <select formControlName="subRiskId">
                          <option>Select Life SubRisk</option>
                          <option *ngFor="let subRisk of subRiskLifeList" [value]="subRisk.subRiskId">{{subRisk.subRisk1}}</option>
                        </select>
                      </div>
                    <div >
                      <label>Policy No</label>
                      <input type="text" placeholder="Policy No" formControlName="policyNo" />
                    </div>
                    <br/>
                    <br/>
                    <div>
                      <button class="btn btn-block btn-login" (click)="submitLife()">Generate Unique ID</button>
                    </div>

                    <br/>
                    <br/>
                    <div *ngIf="isLifeSuccess">
                      <p>NAICOM UID: {{lifeUniqueID}}</p>
                    </div>
                  </form>
                  </div>
                </div>
              </app-tab>
              <app-tab tabTitle="General Business">
                <div class="login-portal">
                  <div  class="form-group-new">
                    <form novalidate [formGroup]="generalBusinessFormGroup">

                   
                    <div>
                      <label class="labels">Risk</label>
                      <select (change)="selectRisk($event)">
                        <option >Select General Business Risk</option>
                        <option *ngFor="let risk of riskList" [value]="risk.riskId">{{risk.risk1}}</option>
                      </select>
                    </div>
                    <div>
                        <label class="labels">SuRisk</label>
                        <select formControlName="subRiskId1">
                          <option>Select General Business SubRisk</option>
                          <option *ngFor="let subRisk of subRiskList" [value]="subRisk.subRiskId">{{subRisk.subRisk1}}</option>
                        </select>
                      </div>
                    <div >
                      <label>Policy No</label>
                      <input type="text" placeholder="Policy No" formControlName="policyNo1" />
                    </div>
                    <br/>
                    <br/>
                    <div>
                      <button class="btn btn-block btn-login" (click)="submitGeneralBusiness()">Generate Unique ID</button>
                    </div>


                    <br/>
                    <br/>
                    <div *ngIf="isGeneralSuccess">
                      <p class="naicom-unique">NAICOM UID: {{uniqueId}}</p>
                    </div>
                  </form>
                  </div>
                </div>
              </app-tab>
            </app-tabs>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
<!-- 
<div class="container g-pt-100 g-pb-70" style="margin-top: -50px">
   
</div> -->

<!-- END REVOLUTION SLIDER -->
