
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;">AUTO POLICY NONRENEWAL VS CANCELLATION </p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="/assets/img/auto-policy-renewal.jpeg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>
                    At the end of your car insurance contract, your insurance provider may make changes to your insurance policy. It has the option to renew the policy as it stands, adjust the rates it charges for your coverage or terminate the contract. There's a difference between an insurance company canceling a policy and choosing not to renew it. 
                 </p>
                 <p></p>

              </div>
           </div>
           <br/>
           <br/>
           <br/>

          <div class="row justify-content-between">
            
              <div class="col-md-12">
            <p><strong>What is car insurance nonrenewal?
                <br/>
                </strong> 
                If your insurance provider chooses not to renew your policy, it must send you a written notification a set number of days before your current policy ends. This is to give you enough time to find a new insurer. The written notice will generally also include a reason for nonrenewal, which is often required by law (the exact timeframes and rules will depend on the state in which you live).
                There are a number of reasons an insurance company may choose not to renew a policy, and it may have nothing to do with you personally. For example, your insurer may have decided to drop that particular type of insurance or to write fewer policies where you live.
                However, a nonrenewal can also be due to your record or your actions. Doing something to considerably raise the insurance company’s risk—like driving drunk, multiple insurance claims (especially for at-fault crashes), or perhaps you bought a new car.
                Note that nonrenewal at one insurer doesn't necessarily mean you'll be charged a higher premium at another insurance company.
                
            </p>
               
            <p><strong>How does it differ from cancellation?
                <br/>
                </strong>  
                An insurance company can choose to cancel your policy when:
                <br/>
                -	You fail to pay the premium
                <br/>
                -	You have made serious misrepresentations on your application
                <br/>
                -	Your driver’s license has been revoked or suspended.
                <br/>
                -	You have no vehicle registration
                <br/>
                -	You have made a fraudulent insurance claim
                <br/>
                Contact your insurance provider today to make sure your policies are up-to-date. 
                <br/>
                Happy new year!
                
            </p>

        </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>