<section style="margin-top:70px">
  <section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px" *ngIf="step === 1">
    <div class="container">
      <div class="media g-brd-around g-brd-gray-light-v4 g-pa-30 g-mb-20">
        <img class="d-flex g-width-50 g-height-50 rounded-circle g-mt-3 g-mr-15" src="assets/img-temp/100x100/agent.jpg"
          alt="Image Description">
        <div class="media-body">
          <div class="g-mb-15">
            <h5 class="d-flex justify-content-between align-items-center h5 g-color-gray-dark-v1 mb-0">
              <span class="d-block g-mr-10">
                <p>Hey! Welcome to NSIA Insurance. I'll get you an awesome price in seconds. Ready to go?</p>
              </span>

            </h5>

          </div>
          <form novalidate #f="ngForm" (ngSubmit)="OnPersonalSubmit(f)">
            <div class="row">
              <div class="col-lg-5">

                <div class="form-group g-mb-20">
                  <label class="g-mb-10">
                    <p>Surname</p>
                  </label>
                  <input id="surname" name="surname" class="form-control form-control-md rounded-0" type="text"
                    placeholder="Required" [(ngModel)]="MotorData.surname" required>
                </div>
              </div>
              <div class="col-lg-5">
                <div class="form-group g-mb-20">
                  <label class="g-mb-10">
                    <p>First name</p>
                  </label>
                  <input id="firstname" name="firstname" class="form-control form-control-md rounded-0" type="text"
                    placeholder="Required" [(ngModel)]="MotorData.firstname" required>
                </div>
              </div>
            </div>
            <div class="container">
              <nav class="text-center" aria-label="Page Navigation">
                <ul class="list-inline mb-0">
                  <li class="list-inline-item float-sm-right">
                    <button
                      class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                      type="submit" [disabled]="!f.valid">Next <i class="fa fa-angle-right"></i></button>
                  </li>
                </ul>
              </nav>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px" *ngIf="step === 2">
    <div class="container">
      <div class="media g-brd-around g-brd-gray-light-v4 g-pa-30 g-mb-20">
        <img class="d-flex g-width-50 g-height-50 rounded-circle g-mt-3 g-mr-15" src="assets/img-temp/100x100/agent.jpg"
          alt="Image Description">
        <div class="media-body">
          <div class="g-mb-15">
            <h5 class="d-flex justify-content-between align-items-center h5 g-color-gray-dark-v1 mb-0">
              <span class="d-block g-mr-10">
                <p>Great to meet you {{MotorData.firstname}}, Please choose if you are an individual or a corporate
                  client</p>
              </span>
            </h5>
          </div>
          <form novalidate #p="ngForm" (ngSubmit)="OnUserSubmit(p)">
            <div class="row" class="g-brd-around g-brd-gray-light-v4 g-pa-30 g-mb-30">
              <div class="row g-mb-30">
                <!-- Left Column -->
                <div class="col-md-12">
                  <div class="form-group g-mb-10">
                    <label class="u-check g-pl-25">
                      <input class="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" name="usertype" type="radio"
                        [(ngModel)]="MotorData.usertype" [value]="1" [checked]="MotorData.usertype==1" required>
                      <div class="u-check-icon-font g-absolute-centered--y g-left-0">
                        <i class="fa" data-check-icon="" data-uncheck-icon=""></i>
                      </div>
                      <span style="color:#555">Individual</span>
                    </label>
                  </div>

                  <div class="form-group g-mb-10">
                    <label class="u-check g-pl-25" style="color:#bbb">
                      <input class="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" name="usertype" type="radio"
                        [(ngModel)]="MotorData.usertype" [value]="2" [checked]="MotorData.usertype==2" required>
                      <div class="u-check-icon-font g-absolute-centered--y g-left-0">
                        <i class="fa" data-check-icon="" data-uncheck-icon=""></i>
                      </div>
                      <span style="color:#555">Corporate</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <nav class="text-center" aria-label="Page Navigation">
                <ul class="list-inline mb-0">
                  <li class="list-inline-item float-sm-left">
                    <button
                      class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                      type="button" (click)="UserPrevious()"><i class="fa fa-angle-left"></i>Previous</button>
                  </li>
                  <li class="list-inline-item float-sm-right">

                    <button
                      class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                      type="submit" [disabled]="!p.valid">Next <i class="fa fa-angle-right"></i></button>

                  </li>
                </ul>
              </nav>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>

  <section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px" *ngIf="step === 3">
    <div class="container">
      <div class="media g-brd-around g-brd-gray-light-v4 g-pa-30 g-mb-20">
        <img class="d-flex g-width-50 g-height-50 rounded-circle g-mt-3 g-mr-15" src="assets/img-temp/100x100/agent.jpg"
          alt="Image Description">
        <div class="media-body">
          <div class="g-mb-15">
            <h5 class="d-flex justify-content-between align-items-center h5 g-color-gray-dark-v1 mb-0">
              <span class="d-block g-mr-10">
                <p>Ok {{MotorData.firstname}}, Is your car for private or commercial use?</p>
              </span>

            </h5>
          </div>
          <form novalidate #u="ngForm" (ngSubmit)="OnUsageSubmit(u)">
            <div class="row" class="g-brd-around g-brd-gray-light-v4 g-pa-30 g-mb-30">
              <div class="row g-mb-30">.
                <!-- Left Column -->
                <div class="col-md-12">
                  <div class="form-group g-mb-10">
                    <label class="u-check g-pl-25">
                      <input class="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" name="usagetype" type="radio"
                        [(ngModel)]="MotorData.usagetype" [value]="1" [checked]="MotorData.usagetype==1" required>
                      <div class="u-check-icon-font g-absolute-centered--y g-left-0">
                        <i class="fa" data-check-icon="" data-uncheck-icon=""></i>
                      </div>
                      <span style="color:#555">Private</span>
                    </label>
                  </div>

                  <div class="form-group g-mb-10">
                    <label class="u-check g-pl-25" style="color:#bbb">
                      <input class="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" name="usagetype" type="radio"
                        [(ngModel)]="MotorData.usagetype" [value]="2" [checked]="MotorData.usagetype==2" required>
                      <div class="u-check-icon-font g-absolute-centered--y g-left-0">
                        <i class="fa" data-check-icon="" data-uncheck-icon=""></i>
                      </div>
                      <span style="color:#555">Commercial</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <nav class="text-center" aria-label="Page Navigation">
                <ul class="list-inline mb-0">
                  <li class="list-inline-item float-sm-left">
                    <button
                      class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                      type="button" (click)="usagePrevious()"><i class="fa fa-angle-left"></i>Previous</button>
                  </li>
                  <li class="list-inline-item float-sm-right">

                    <button
                      class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                      type="submit" [disabled]="!u.valid">Next <i class="fa fa-angle-right"></i></button>

                  </li>
                </ul>
              </nav>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>

  <section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px" *ngIf="step === 4">
    <div class="container">
      <div class="media g-brd-around g-brd-gray-light-v4 g-pa-30 g-mb-20">
        <img class="d-flex g-width-50 g-height-50 rounded-circle g-mt-3 g-mr-15" src="assets/img-temp/100x100/agent.jpg"
          alt="Image Description">
        <div class="media-body">
          <div class="g-mb-15">
            <h5 class="d-flex justify-content-between align-items-center h5 g-color-gray-dark-v1 mb-0">
              <span class="d-block g-mr-10">
                <p> Nice one {{MotorData.firstname}}, Which cover do you want to buy? </p>
              </span>

            </h5>

          </div>
          <form novalidate #o="ngForm" (ngSubmit)="OnCoverSubmit(o)">
            <div class="row" class="g-brd-around g-brd-gray-light-v4 g-pa-30 g-mb-30">
              <div class="row g-mb-30">
                <!-- Left Column -->
                <div class="col-md-12">

                  <!-- <div class="form-group g-mb-10">
                                <label class="u-check g-pl-25" >
                                  <input class="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" name="covertype" type="radio"  [(ngModel)]="MotorData.covertype" [value]="3" [checked]="MotorData.covertype==3" required>
                                  <div class="u-check-icon-font g-absolute-centered--y g-left-0">
                                    <i class="fa" data-check-icon="" data-uncheck-icon=""></i>
                                  </div>
                                <span style="color:#555">NSIA Super <b>(DSTV Subscriber only)</b> </span>
                                </label>
                                <span *ngIf = "MotorData.covertype==3">
                                  &nbsp;  Please enter your Dstv code to enjoy our great discount. &nbsp;
                                  <input type ="text" id="promoCode" name="promoCode"  placeholder="Required" [(ngModel)]="MotorData.promoCode" required />
                                &nbsp; {{promoCodeErrorMessage}}
                                </span>
                              </div>
                              <div class="form-group g-mb-10">
                                  <label class="u-check g-pl-25" >
                                    <input class="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" name="covertype" type="radio"  [(ngModel)]="MotorData.covertype" [value]="4" [checked]="MotorData.covertype==4" required>
                                    <div class="u-check-icon-font g-absolute-centered--y g-left-0">
                                      <i class="fa" data-check-icon="" data-uncheck-icon=""></i>
                                    </div>
                                  <span style="color:#555">Third Party Fire & Theft  <b>(DSTV Subscriber only)</b></span>
                                  </label>
                                  <span *ngIf = "MotorData.covertype==4">
                                    &nbsp;  Please enter your Dstv code to enjoy our great discount. &nbsp;
                                    <input type ="text" id="promoCode" name="promoCode"  placeholder="Required" [(ngModel)]="MotorData.promoCode" required />
                                    &nbsp; {{promoCodeErrorMessage}}
                                  </span>
                                </div> -->

                  <div class="form-group g-mb-10">
                    <label class="u-check g-pl-25">
                      <input class="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" name="covertype" type="radio"
                        [(ngModel)]="MotorData.covertype" [value]="1" [checked]="MotorData.covertype==1" required>
                      <div class="u-check-icon-font g-absolute-centered--y g-left-0">
                        <i class="fa" data-check-icon="" data-uncheck-icon=""></i>
                      </div>
                      <span style="color:#555">Comprehensive</span>
                    </label>
                  </div>

                  <div class="form-group g-mb-10">
                    <label class="u-check g-pl-25" style="color:#bbb">
                      <input class="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" name="covertype" type="radio"
                        [(ngModel)]="MotorData.covertype" [value]="2" [checked]="MotorData.covertype==2" required>
                      <div class="u-check-icon-font g-absolute-centered--y g-left-0">
                        <i class="fa" data-check-icon="" data-uncheck-icon=""></i>
                      </div>
                      <span style="color:#555">Third Party</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <nav class="text-center" aria-label="Page Navigation">
                <ul class="list-inline mb-0">
                  <li class="list-inline-item float-sm-left">
                    <button
                      class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                      type="button" (click)="coverPrevious()"><i class="fa fa-angle-left"></i>Previous</button>
                  </li>
                  <li class="list-inline-item float-sm-right">

                    <button
                      class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                      type="submit" [disabled]="!o.valid">Next <i class="fa fa-angle-right"></i></button>

                  </li>
                </ul>
              </nav>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px" *ngIf="step === 5">
    <div class="container">
      <div class="media g-brd-around g-brd-gray-light-v4 g-pa-30 g-mb-20">
        <img class="d-flex g-width-50 g-height-50 rounded-circle g-mt-3 g-mr-15" src="assets/img-temp/100x100/agent.jpg"
          alt="Image Description">
        <div class="media-body">
          <div class="g-mb-15">
            <h5 class="d-flex justify-content-between align-items-center h5 g-color-gray-dark-v1 mb-0">
              <span class="d-block g-mr-10">
                <p><i class="fa fa-thumbs-o-up"></i> Nice one {{MotorData.firstname}}! What duration do you want your
                  cover to last?</p>
              </span>

            </h5>

          </div>
          <form novalidate #d="ngForm" (ngSubmit)="OndurationSubmit(d)">
            <div class="row" class="g-brd-around g-brd-gray-light-v4 g-pa-30 g-mb-30">
              <div class="row g-mb-30">
                <!-- Left Column -->
                <div class="col-md-12">
                  <div class="form-group g-mb-10">
                    <label class="u-check g-pl-25">
                      <input class="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" name="duration" type="radio"
                        [(ngModel)]="MotorData.duration" [value]=1 [checked]="MotorData.duration==1" required>
                      <div class="u-check-icon-font g-absolute-centered--y g-left-0">
                        <i class="fa" data-check-icon="" data-uncheck-icon=""></i>
                      </div>
                      <span style="color:#555">One year</span>
                    </label>
                  </div>
                  <div class="form-group g-mb-10">
                    <label class="u-check g-pl-25" style="color:#bbb">
                      <input class="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" name="duration" type="radio"
                        [(ngModel)]="MotorData.duration" [value]=2 [checked]="MotorData.duration==2" required>
                      <div class="u-check-icon-font g-absolute-centered--y g-left-0">
                        <i class="fa" data-check-icon="" data-uncheck-icon=""></i>
                      </div>
                      <span style="color:#555">Half yearly</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <nav class="text-center" aria-label="Page Navigation">
                <ul class="list-inline mb-0">
                  <li class="list-inline-item float-sm-left">
                    <button
                      class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                      type="button" (click)="durationPrevious()"><i class="fa fa-angle-left"></i>Previous</button>
                  </li>
                  <li class="list-inline-item float-sm-right">

                    <button
                      class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                      type="submit" [disabled]="!d.valid">Next <i class="fa fa-angle-right"></i></button>

                  </li>
                </ul>
              </nav>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>

  <section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px" *ngIf="step === 7">
    <div class="container">
      <div class="media g-brd-around g-brd-gray-light-v4 g-pa-30 g-mb-20">
        <img class="d-flex g-width-50 g-height-50 rounded-circle g-mt-3 g-mr-15" src="assets/img-temp/100x100/agent.jpg"
          alt="Image Description">
        <div class="media-body">
          <div class="g-mb-15">
            <h5 class="d-flex justify-content-between align-items-center h5 g-color-gray-dark-v1 mb-0">
              <span class="d-block g-mr-10" *ngIf="myCover==2">
                <p> Good choice {{MotorData.firstname}}, but i would advise you go for comprehensive cover because it
                  covers more risk compared to third party cover. kindly click on the previous button to enable you
                  change your cover. if you are okay with third party cover you can go ahead to select your vehicle type
                </p>
              </span>
              <span class="d-block g-mr-10" *ngIf="myCover==1">
                <p><i class="fa fa-thumbs-o-up"></i> Great choice {{MotorData.firstname}}, Please choose your vehicle
                  type</p>
              </span>
              <span class="d-block g-mr-10" *ngIf="myCover==3 || myCover==4 ">
                <p><i class="fa fa-thumbs-o-up"></i> Thanks {{MotorData.firstname}} for choosing NSIA Insurance, .
                  Please choose your vehicle type</p>
              </span>
            </h5>

          </div>
          <form novalidate #v="ngForm" (ngSubmit)="OnvehicletypeSubmit(v)">
            <div class="row" class="g-brd-around g-brd-gray-light-v4 g-pa-30 g-mb-30">
              <div class="row g-mb-30">
                <!-- Left Column -->
                <div class="col-md-12">
                  <div class="form-group g-mb-10">
                    <label class="u-check g-pl-25">
                      <input class="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" name="vehicletype" type="radio"
                        [(ngModel)]="MotorData.vehicletype" [value]="1" [checked]="MotorData.vehicletype==1" required>
                      <div class="u-check-icon-font g-absolute-centered--y g-left-0">
                        <i class="fa" data-check-icon="" data-uncheck-icon=""></i>
                      </div>
                      <span style="color:#555">Saloon</span>
                    </label>
                  </div>

                  <div class="form-group g-mb-10">
                    <label class="u-check g-pl-25" style="color:#bbb">
                      <input class="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" name="vehicletype" type="radio"
                        [(ngModel)]="MotorData.vehicletype" [value]="2" [checked]="MotorData.vehicletype==2" required>
                      <div class="u-check-icon-font g-absolute-centered--y g-left-0">
                        <i class="fa" data-check-icon="" data-uncheck-icon=""></i>
                      </div>
                      <span style="color:#555">SUV</span>
                    </label>
                  </div>
                  <div class="form-group g-mb-10">
                    <label class="u-check g-pl-25" style="color:#bbb">
                      <input class="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" name="vehicletype" type="radio"
                        [(ngModel)]="MotorData.vehicletype" [value]="3" [checked]="MotorData.vehicletype==3" required>
                      <div class="u-check-icon-font g-absolute-centered--y g-left-0">
                        <i class="fa" data-check-icon="" data-uncheck-icon=""></i>
                      </div>
                      <span style="color:#555">Bus</span>
                    </label>
                  </div>
                  <div class="form-group g-mb-10">
                    <label class="u-check g-pl-25" style="color:#bbb">
                      <input class="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" name="vehicletype" type="radio"
                        [(ngModel)]="MotorData.vehicletype" [value]="4" [checked]="MotorData.vehicletype==4" required>
                      <div class="u-check-icon-font g-absolute-centered--y g-left-0">
                        <i class="fa" data-check-icon="" data-uncheck-icon=""></i>
                      </div>
                      <span style="color:#555">Truck</span>
                    </label>
                  </div>


                </div>
              </div>
            </div>
            <div class="container">
              <nav class="text-center" aria-label="Page Navigation">
                <ul class="list-inline mb-0">
                  <li class="list-inline-item float-sm-left">
                    <button
                      class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                      type="button" (click)="vehicletypePrevious()"><i class="fa fa-angle-left"></i>Previous</button>
                  </li>
                  <li class="list-inline-item float-sm-right">

                    <button
                      class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                      type="submit" [disabled]="!v.valid">Next <i class="fa fa-angle-right"></i></button>

                  </li>
                </ul>
              </nav>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>

  <section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px" *ngIf="step === 6">
    <div class="container">
      <div class="media g-brd-around g-brd-gray-light-v4 g-pa-30 g-mb-20">
        <img class="d-flex g-width-50 g-height-50 rounded-circle g-mt-3 g-mr-15" src="assets/img-temp/100x100/agent.jpg"
          alt="Image Description">
        <div class="media-body">
          <div class="g-mb-15">
            <h5 class="d-flex justify-content-between align-items-center h5 g-color-gray-dark-v1 mb-0">
              <span class="d-block g-mr-10">
                <p>Fantastic {{MotorData.firstname}},How much did you buy the vehicle</p>
              </span>
            </h5>

          </div>
          <form novalidate #v="ngForm" (ngSubmit)="comprehensiveSubmit(v)">
            <div class="row">
              <div class="col-lg-4">

                <div class="form-group g-mb-20">
                  <label class="g-mb-10">
                    <p>Vehicle Value</p>
                  </label>
                  <input id="vehiclevalue" name="vehiclevalue" class="form-control form-control-md rounded-0"
                    type="number" [min]="1000000" placeholder="Required" [(ngModel)]="MotorData.vehiclevalue" required
                    #field="ngModel">
                </div>
                <div *ngIf="field.errors">
                  <div *ngIf="field.errors?.min" style="color:red">
                    Minimum amount is 1,000,000.
                  </div>
                </div>
              </div>

            </div>

            <div class="container">
              <nav class="text-center" aria-label="Page Navigation">
                <ul class="list-inline mb-0">
                  <li class="list-inline-item float-sm-left">
                    <button
                      class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                      type="button" (click)="vehiclevaluePrevious()"><i class="fa fa-angle-left"></i>Previous</button>
                  </li>
                  <li class="list-inline-item float-sm-right">

                    <button
                      class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                      type="submit" [disabled]="!v.valid">Next <i class="fa fa-angle-right"></i></button>

                  </li>
                </ul>
              </nav>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>

  <section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px" *ngIf="step === 9">
    <div class="container">
      <div class="media g-brd-around g-brd-gray-light-v4 g-pa-30 g-mb-20">

        <div class="media-body">

          <form novalidate #tp="ngForm" (ngSubmit)="OnthirdpartySubmit(tp)">
            <div class="row align-items-center">
              <div class="col-lg-5 text-center g-mb-40 g-mb-0--lg g-pr-60--lg" style="margin-top:-50px">
                <img class="rounded-circle mx-auto g-mb-30" src="assets/img-temp/100x100/agent.jpg"
                  alt="Image Description">
                <blockquote class="u-blockquote-v3 g-font-size-16 g-mb-20" style="color:#555">Thanks
                  {{MotorData.firstname}},You can proceed to buy now.</blockquote>


                <div class="container">
                  <nav class="text-center" aria-label="Page Navigation">
                    <ul class="list-inline mb-0">
                      <li class="list-inline-item float-sm-left">
                        <button
                          class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                          type="button" (click)="ThirdPartyPrevious()"><i class="fa fa-angle-left"></i>Previous</button>
                      </li>

                    </ul>
                  </nav>
                </div>

              </div>

              <div class="col-lg-7">
                <div class="row no-gutters align-items-center justify-content-center">
                  <div class="col-md-6 g-mb-30 g-z-index-2">
                    <!-- Article -->
                    <article class="text-center g-brd-around g-color-gray g-brd-gray-light-v5 g-pa-10">
                      <div class="g-bg-gray-light-v5 g-px-15 g-py-50">
                        <!-- Article Title -->
                        <h4 class="text-uppercase g-color-gray-dark-v3 g-font-weight-500 g-mb-10">Third Party</h4>
                        <!-- End Article Title -->
                        <em class="g-font-style-normal">
                          <p>Motor Insurance</p>
                        </em>

                        <hr class="g-brd-gray-light-v4 g-my-20">


                        <div class="g-color-primary g-my-30">
                          <strong
                            class="d-block g-font-size-30 g-line-height-1_2">{{'#'}}{{MotorData.totalprice | number:'.2-2'}}</strong>
                          Yearly
                        </div>

                        <hr class="g-brd-gray-light-v4 g-mt-20 g-mb-10">

                        <ul class="list-unstyled g-mb-25">
                          <li class="g-brd-bottom g-brd-gray-light-v4 g-py-12">
                            <p>Third party insurance covers your vehicle for accident damage. </p>
                          </li>
                          <li class="g-brd-bottom g-brd-gray-light-v4 g-py-12">

                          </li>

                        </ul>
                        <a class="btn btn-md rounded-0 u-btn-primary" (click)="buynow()">Buy Now</a>
                      </div>
                    </article>
                    <!-- End Article -->
                  </div>


                </div>
              </div>
            </div>


          </form>
        </div>
      </div>
    </div>
  </section>

  <section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px" *ngIf="step === 8">
    <div class="container">
      <div class="media g-brd-around g-brd-gray-light-v4 g-pa-30 g-mb-20">

        <div class="media-body">

          <form novalidate #bc="ngForm" (ngSubmit)="buycomprehensiveSubmit(bc)">
            <div class="row align-items-center">
              <div class="col-lg-5 text-center g-mb-40 g-mb-0--lg g-pr-20--lg">
                <img class="rounded-circle mx-auto g-mb-30" src="assets/img-temp/100x100/agent.jpg"
                  alt="Image Description">
                <blockquote class="u-blockquote-v3 g-font-size-16 g-mb-20" style="color:#555">Thanks
                  {{MotorData.firstname}},This is your quote. You can proceed to buy now.</blockquote>
                <div class="container">
                  <nav class="text-center" aria-label="Page Navigation">
                    <ul class="list-inline mb-0">
                      <li class="list-inline-item float-sm-left">
                        <button
                          class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                          type="button" (click)="comprehensivePrevious()"><i
                            class="fa fa-angle-left"></i>Previous</button>
                      </li>

                    </ul>
                  </nav>
                </div>
              </div>

              <div class="col-lg-7">
                <div class="row no-gutters align-items-center justify-content-center">
                  <div class="col-md-6 g-mb-30 g-z-index-2">
                    <!-- Article -->
                    <article class="text-center g-brd-around g-color-gray g-brd-gray-light-v5 g-pa-10">
                      <div class="g-bg-gray-light-v5 g-px-15 g-py-50">
                        <!-- Article Title -->
                        <h4 class="text-uppercase g-color-gray-dark-v3 g-font-weight-500 g-mb-10">Comprehensive</h4>
                        <!-- End Article Title -->
                        <em class="g-font-style-normal">
                          <p>Motor Insurance</p>
                        </em>

                        <hr class="g-brd-gray-light-v4 g-my-20">

                        <div class="g-color-primary g-my-30">
                          <strong
                            class="d-block g-font-size-30 g-line-height-1_2">&#8358;{{MotorData.totalprice | number:'.2-2'}}</strong>
                          Yearly
                        </div>

                        <hr class="g-brd-gray-light-v4 g-mt-20 g-mb-10">
                        <p>This policy covers loss of or damage to the insured vehicle and /or its accessories due to:.
                        </p>

                        <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" (click)="buynow()">Buy Now</a>
                      </div>
                    </article>
                    <!-- End Article -->
                  </div>


                </div>
              </div>
            </div>


          </form>
        </div>
      </div>
    </div>
  </section>

  <section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px" *ngIf="step === 10">
    <div class="container">
      <div class="media g-brd-around g-brd-gray-light-v4 g-pa-30 g-mb-20">
        <img class="d-flex g-width-50 g-height-50 rounded-circle g-mt-3 g-mr-15" src="assets/img-temp/100x100/agent.jpg"
          alt="Image Description">
        <div class="media-body">
          <div class="g-mb-15">
            <h5 class="d-flex justify-content-between align-items-center h5 g-color-gray-dark-v1 mb-0">
              <span class="d-block g-mr-10">
                <p>Please fill in these details to enable you to pay</p>
              </span>

            </h5>

          </div>
          <form novalidate #f="ngForm" (ngSubmit)="OnfinalSubmit(f)">
            <h3 class="h4 mb-3">
              <p>Enter vehicle Information</p>
            </h3>
            <div class="row">
              <div class="col-lg-4">

                <div class="form-group g-mb-20">
                  <label class="g-mb-10">
                    <p>Vehicle Make</p>
                  </label>
                  <select class="form-control border-input" id="dpdmake" name="dpdmake"
                    [(ngModel)]="MotorData.selectedMake" (change)="OnchangeMake($event.target.value)" required>
                    <option value="" selected disabled>Select Make</option>
                    <option *ngFor="let sup of vehiclemake" [value]="sup.id">{{sup.title}}</option>
                  </select>
                </div>

              </div>

              <div class="col-lg-4">
                <div class="form-group g-mb-20">
                  <label class="g-mb-10">
                    <p>Vehicle Model</p>
                  </label>
                  <select class="form-control border-input" id="dpdmodel" name="dpdmodel" required
                    [(ngModel)]="MotorData.selectedModel">
                    <option value="" selected disabled>Select Model</option>
                    <option *ngFor="let sup of vehicleModel" [value]="sup.title">{{sup.title}}</option>
                  </select>
                </div>
              </div>

              <div class="col-lg-4">

                <div class="form-group g-mb-20">
                  <label class="g-mb-10">
                    <p>Vehicle year</p>
                  </label>
                  <select class="form-control border-input" id="dpdyear" name="dpdyear"
                    [(ngModel)]="MotorData.selectedyear" required>
                    <option value="" selected disabled>Select year</option>
                    <option value="1990" title="1990">1990</option>
                    <option value="1991" title="1991">1991</option>
                    <option value="1992" title="1992">1992</option>
                    <option value="1993" title="1993">1993</option>
                    <option value="1994" title="1994">1994</option>
                    <option value="1995" title="1995">1995</option>
                    <option value="1996" title="1996">1996</option>
                    <option value="1997" title="1997">1997</option>
                    <option value="1998" title="1998">1998</option>
                    <option value="1999" title="1999">1999</option>
                    <option value="2000" title="2000">2000</option>
                    <option value="2001" title="2001">2001</option>
                    <option value="2002" title="2002">2002</option>
                    <option value="2003" title="2003">2003</option>
                    <option value="2004" title="2004">2004</option>
                    <option value="2005" title="2005">2005</option>
                    <option value="2006" title="2006">2006</option>
                    <option value="2007" title="2007">2007</option>
                    <option value="2008" title="2008">2008</option>
                    <option value="2009" title="2009">2009</option>
                    <option value="2010" title="2010">2010</option>
                    <option value="2011" title="2011">2011</option>
                    <option value="2012" title="2012">2012</option>
                    <option value="2013" title="2013">2013</option>
                    <option value="2014" title="2014">2014</option>
                    <option value="2015" title="2015">2015</option>
                    <option value="2016" title="2016">2016</option>
                    <option value="2017" title="2017">2017</option>
                    <option value="2018" title="2018">2018</option>
                    <option value="2019" title="2019">2019</option>
                    <option value="2020" title="2020">2020</option>
                    <option value="2021" title="2021">2021</option>
                    <option value="2022" title="2022">2022</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4">

                <div class="form-group g-mb-20">
                  <label class="g-mb-10">
                    <p>Registration number</p>
                  </label>
                  <input id="regno" name="regno" class="form-control form-control-md rounded-0" type="text"
                    placeholder="Required" [(ngModel)]="MotorData.regno" required>
                </div>

              </div>

              <div class="col-lg-4">
                <div class="form-group g-mb-20">
                  <label class="g-mb-10">
                    <p>Chasis number</p>
                  </label>
                  <input id="chasisno" name="chasisno" class="form-control form-control-md rounded-0" type="text"
                    placeholder="Required" [(ngModel)]="MotorData.chasisno" (keyup)="validateChasis($event)"
                    (keyup)="validateChasisLength($event)" required>
                  <span style="color:red">{{chasisErrorMessage}}</span><br>
                  <span style="color:red">{{chasisLengthErrorMessage}}</span>
                </div>
              </div>

              <div class="col-lg-4">

                <div class="form-group g-mb-20">
                  <label class="g-mb-10">
                    <p>Engine number</p>
                  </label>
                  <input id="engineno" name="engineno" class="form-control form-control-md rounded-0" type="text"
                    placeholder="Required" [(ngModel)]="MotorData.engineno" required>
                </div>
              </div>
            </div>
            <h3 class="h4 mb-3">
              <p>Enter contact information</p>
            </h3>
            <div class="row">
              <div class="col-lg-4">

                <div class="form-group g-mb-20">
                  <label class="g-mb-10">
                    <p>Address</p>
                  </label>
                  <input id="address" name="address" class="form-control form-control-md rounded-0" type="text"
                    placeholder="Required" [(ngModel)]="MotorData.address" required>
                </div>

              </div>

              <div class="col-lg-4">

                <div class="form-group g-mb-20">
                  <label class="g-mb-10">
                    <p>State of residence</p>
                  </label>
                  <select class="form-control border-input" id="state" name="state" [(ngModel)]="MotorData.state"
                    (change)="OnstatechangeMake($event.target.value)" required>
                    <option value="" selected disabled>Select State</option>
                    <option *ngFor="let sup of state" [value]="sup.id">{{sup.stateName}}</option>
                  </select>
                </div>

              </div>

              <div class="col-lg-4">
                <div class="form-group g-mb-20">
                  <label class="g-mb-10">
                    <p>Local Govt</p>
                  </label>
                  <select class="form-control border-input" id="lga" name="lga" required [(ngModel)]="MotorData.lga">
                    <option value="" selected disabled>Select LGA</option>
                    <option *ngFor="let sup of lga" [value]="sup.id">{{sup.lgaName}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4">
                <div class="form-group g-mb-20">
                  <label class="g-mb-10">
                    <p>Email</p>
                  </label>
                  <input id="email" name="email" class="form-control form-control-md rounded-0" [pattern]="emailPattern"
                    placeholder="Required" [(ngModel)]="MotorData.email" required #offEmail="ngModel">
                </div>

                <div *ngIf="offEmail.errors">

                  <div *ngIf="offEmail.errors.pattern" style="color: red">
                    Email not valid.
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="form-group g-mb-20">
                  <label class="g-mb-10">
                    <p>Phone Number</p>
                  </label>
                  <input id="phoneNo" minlength=11 name="phoneNo" class="form-control form-control-md rounded-0"
                    type="text" placeholder="Required" [(ngModel)]="MotorData.phoneNo" required>
                </div>

              </div>
            </div>
            <h3 class="h4 mb-3">
              <p>Upload documents</p>
            </h3>
            <div class="row">
              <div class="col-lg-4">
                <div class="form-group g-mb-20">
                  <label>
                    <p>{{docId}}</p>
                  </label>
                  <input imageUpload type="file" (change)="selected($event.target.files)"  required  />
                </div>
              </div>

              <div class="col-lg-4">
                <div class="form-group g-mb-20">
                  <label>
                    <p>Vehicle document</p>
                  </label>
                  <input type="file"  (change)="selectedV($event.target.files)" required  />
                </div>

              </div>
            </div>
            <div class="container">
              <nav class="text-center" aria-label="Page Navigation">
                <ul class="list-inline mb-0">
                  <li class="list-inline-item float-sm-left">
                    <button
                      class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                      type="button" (click)="submitPrevious()"><i class="fa fa-angle-left"></i>Previous</button>
                  </li>
                  <li class="list-inline-item float-sm-right">
                    <button
                      class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                      type="submit" [disabled]="!f.valid || disableButton">Submit <i class="fa fa-plus"></i></button>
                  </li>
                </ul>
              </nav>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>



  <section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px" *ngIf="step === 11">

    <form ngNoForm method="post" action="https://sandbox.interswitchng.com/webpay/pay">
      <div class="container g-py-100">
        <div
          class="u-shadow-v19 g-max-width-645 g-brd-around g-brd-gray-light-v4 text-center rounded mx-auto g-pa-30 g-pa-50--md">
          <span class="u-icon-v3 u-icon-size--lg g-color-white g-bg-primary rounded-circle g-pa-15 mb-5">
            <svg width="30" height="30" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 497.5 497.5"
              style="enable-background:new 0 0 497.5 497.5;" xml:space="preserve">
              <g>
                <path
                  d="M487.75,78.125c-13-13-33-13-46,0l-272,272l-114-113c-13-13-33-13-46,0s-13,33,0,46l137,136
                                                    c6,6,15,10,23,10s17-4,23-10l295-294C500.75,112.125,500.75,91.125,487.75,78.125z"
                  fill="#fff" />
              </g>
            </svg>
          </span>

          <div class="mb-5">
            <div class="payment-confirmation">
              <h2>Congratulations</h2>
              <h4>transaction Completed</h4>
              <p>you will be contacted ver soon for payment instructions</p>
            </div>
            <h2 class="mb-4">
              <p>Below are your transaction details</p>
            </h2>
            <div class="form-group row g-mb-25">
              <label for="inputEmail3" class="col-sm-4 col-form-label">
                <p>Customer Name</p>
              </label>
              <div class="col-sm-6">
                <p>{{ OutputData.cust_name}}</p>
              </div>
            </div>
            <div class="form-group row g-mb-25">
              <label for="inputEmail3" class="col-sm-4 col-form-label">
                <p>Vehicle details</p>
              </label>
              <div class="col-sm-6">
                <p>{{ OutputData.vehicledetail}}</p>
              </div>
            </div>
            <div class="form-group row g-mb-25">
              <label for="inputEmail3" class="col-sm-4 col-form-label">
                <p>Premium</p>
              </label>
              <div class="col-sm-6">
                <p>{{ OutputData.premiumstring |currency:'NGN'}}</p>
              </div>
            </div>
            <div class="form-group row g-mb-25">
              <label for="inputEmail3" class="col-sm-4 col-form-label">
                <p>Transaction Reference</p>
              </label>
              <div class="col-sm-6">
                <p>{{ OutputData.txn_ref}}</p>
              </div>
            </div>
            <div class="form-group row g-mb-25">
              <label for="inputEmail3" class="col-sm-4 col-form-label">
                <p>Business class</p>
              </label>
              <div class="col-sm-6">
                <p>{{ OutputData.BusinessClass}}</p>
              </div>
            </div>

            <input name="product_id" type="hidden" id="product_id" [(ngModel)]="OutputData.product_id" />
            <input name="cust_id" type="hidden" id="cust_id" [(ngModel)]="OutputData.cust_id" />
            <input name="cust_name" type="hidden" id="cust_name" [(ngModel)]="OutputData.cust_name" />
            <input name="pay_item_id" type="hidden" id="pay_item_id" [(ngModel)]="OutputData.pay_item_id" />
            <input name="amount" type="hidden" id="amount" [(ngModel)]="OutputData.amount" />
            <input name="currency" type="hidden" id="currency" [(ngModel)]="OutputData.currency" />
            <input name="site_redirect_url" type="hidden" id="site_redirect_url"
              [(ngModel)]="OutputData.site_redirect_url" />
            <input name="txn_ref" type="hidden" id="txn_ref" [(ngModel)]="OutputData.txn_ref" />
            <input name="hash" type="hidden" id="hash" [(ngModel)]="OutputData.hash" />

          </div>

          <!-- <button class="btn u-btn-primary g-font-size-12 text-uppercase g-py-12 g-px-25" onclick="submit()"
            type="submit">Proceed to pay </button> -->
          <!-- <div style="padding-top: 10px">
            <img class="img-fluid w-100" src="assets/img/interswitchlogo.png" alt="Image Description">
          </div> -->

          <div class="container">
            <nav class="text-center" aria-label="Page Navigation">
              <ul class="list-inline mb-0">
                <li class="list-inline-item float-sm-left">
                  <!-- <button
                    class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                    type="button" (click)="onconfirmationPrevious()"><i class="fa fa-angle-left"></i>Previous</button> -->
                </li>

              </ul>
            </nav>
          </div>
        </div>

      </div>

    </form>

  </section>
</section>
