import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import { NgForm } from '@angular/forms'
import { FormserviceService } from 'src/app/Services/formservice.service';
import { MoneyProposalForm } from 'src/app/Classes/money-proposal-form';


@Component({
  selector: 'app-money-proposal-form',
  templateUrl: './money-proposal-form.component.html',
  styleUrls: ['./money-proposal-form.component.css']
})
export class MoneyProposalFormComponent implements OnInit {
  model;
  dateTemp = new Date();
  proposalModel = new  MoneyProposalForm("","","","","","",
  "",this.dateTemp,this.dateTemp,"","","","",null,"", "","","","","", "","","","");
  constructor(private _formservice: FormserviceService) { }

  ngOnInit(): void {
  }
  submitForm(myForm: NgForm){
    var email = this.proposalModel.EmailAddress;

    var regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;


    if(!regex.test(email)) {
      Swal.fire("Error", "Your email is not correct", "error");
      this._formservice.stopLoading();
      return;
    }
    console.log($('#Telephone').val());
    var telLength = $('#Telephone').val().toString().length;
    //var secTel =$("#AlternativeTel").val().toString().length;

    if(telLength < 10){
      Swal.fire("Error", "Your telephone number is not correct", "error");
      this._formservice.stopLoading();
      return;
    }

    this._formservice.startLoading();
    this._formservice.sendMoneyProposalForm(this.proposalModel)
      .subscribe(
        (data) => {
          if (data.statusCode == "00") {
            Swal.fire("Success", "Your form is submitted", "success");
            myForm.resetForm();
          } else {
            Swal.fire("error", data.statusMessage, "info");
          }
          this._formservice.stopLoading();
        },
        (error) => {
          console.log(error);
          Swal.fire("fatal", "internal server error occurred", "error");
          this._formservice.stopLoading();
        }
      )

  }

}
