import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NavBarTabViewComponent } from '../nav-bar-tab-view/nav-bar-tab-view.component';
// import { NgbdModalContent } from '../nav-bar/nav-bar.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {

  }
  open() {
    const modalRef = this.modalService.open(NavBarTabViewComponent, { centered: true });
    modalRef.componentInstance.name = 'World';
  }

}
