<div class="g-min-height-450 g-flex-centered g-bg-img-hero g-bg-pos-top-center g-bg-size-cover g-pt-80" style="background-image: url(assets/img/sideimage.jpg);">
  <div class="container g-pos-rel g-z-index-1">
      <h2 class="h1 text-uppercase g-color-white g-font-size-40--lg mb-0">Claims</h2>
      <span class="d-block  g-color-white g-font-weight-1000 g-font-size-20 mb-4"></span>
  </div>
</div>
<!-- <section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px">
      <div class="container" >
        <div class="d-sm-flex text-center">
          <div class="align-self-center">
            <h2 class="h3 g-font-weight-300 w-100 g-mb-10 g-mb-0--md" ><p>NSIA Claim Process</p></h2>
          </div>

        </div>
      </div>
    </section> -->

<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
      <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
              <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
              <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
              <span>Claims Process</span>
          </li>

          <li class="list-inline-item ml-auto">
              <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" routerLink="/reportclaim">Report Claims</a>
          </li>
      </ul>
  </div>
</section>

<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
      <div class="col-lg-9 g-mb-30">
          <p>NSIA Insurance  is a customer-focused company. We regard loss handling and claims as our ultimate purpose, and understand that a customer wants to be assured that their premium guarantees indemnity against all losses covered by their subscribed policy.</p>
          <p></p>
          <p>The goal of our Claims Unit is to promptly pay all properly documented claims covered by the policy within the set time limit. Consequently, we can confidently assure all that NSIA Insurance is able to meet its contractual promises to its customers.</p>
          <p></p>
          <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
              <!-- <h4><strong><p>Claims Process</p></strong> </h4> -->

              <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
                  <strong><h5 style="color:white">NSIA Insurance Claims Process</h5></strong>
              </div>
              <p></p>
              <p>We are available 365 days a year to attend to your claims. Our aim is to make the claims process as simple as possible. <br>
                  Our claims process involves the following simple steps.
                  </p>
              <p></p>
              <ul>
                  <li style="color:#bbb">
                      <p><strong>Please report your claim to us via the following channels:</strong> <br>
                          <strong>Phone </strong> call to the following numbers: <br>
                          09061237623 <br>
08188639774 <br>
012805378 – 9 <br><br>
<strong>Email to </strong><a href="mailto:claimsunit_ng@nsiainsurance.com">claimsunit_ng@nsiainsurance.com</a> <br/>
<strong>Letter</strong> addressed and delivered to the address below or any of our offices nationwide: <br>
The Managing Director <br>
NSIA Insurance Limited <br>
3, Elsie Femi Pearse Street <br>
Victoria Island <br>
Lagos <br> <br>
<strong>Online</strong> via our website or customer portal


</p>
<p>Kindly notify us immediately a loss occurs or as soon as possible as we may need to carry out an on-the-spot assessment depending on the nature and size of the claim.</p>
                  </li>

                  <li style="color:#bbb">
                      <p><strong> DOCUMENTATION </strong> </p>
                      <p>Once we receive notification of your claim, our team would contact you within one business day for further information and guide you through the process by answering any question that you may have. Kindly click below on the type of loss to see details of the document required for your claim.</p>
                      <p>You are responsible for providing documentation and evidence to prove the nature and amount of loss suffered. Kindly note that delays in providing the required documentation and evidence may lead to delays in the claims process.</p>
                      <p>We advise that you refer to the policy document to familiarize yourself with the terms and conditions of the policy so as to ensure a seamless process</p>
                  </li>
                  <li style="color:#bbb">
                      <p><strong> CLAIM ASSESSMENT </strong> </p>
                      <p>Depending on the type and size of your claim, we may appoint an adjuster to assess your loss and verify the circumstances surrounding the loss. </p>
                      <p>Once the claims verification and assessment is completed we would communicate our offer for settlement or a repudiation of the claim depending on the outcome of the process.</p>
                  </li>
                  <li style="color:#bbb">
                      <p><strong> CLAIM SETTLEMENT </strong> </p>
                      <p>An offer for settlement is communicated via a discharge voucher to be executed by the insured or their representatives. On receipt of the executed discharge voucher, we would settle the claim within stipulated timelines.</p>
                      <p>Please refer to our claims settlement link below for details of our settlement timelines.</p>
                  </li>

              </ul>
              <p></p>
              <p>Essential documents required for processing claims under several policies:</p>
              <p></p>
              <div id="accordion-12" class="u-accordion u-accordion-color-primary" role="tablist" aria-multiselectable="true">
                  <!-- Card  <div style="padding-left : 5px; background-color:#efb225;">-->
                  <div class="card g-brd-none rounded-0 g-mb-15">
                      <div id="accordion-12-heading-01" class="u-accordion__header g-pa-0" style=" background-color:#efb225;" role="tab">
                          <h6 class="mb-0">
                            <a class="d-flex g-color-main g-text-underline--none--hover g-brd-around g-brd-gray-light-v4 g-rounded-5 g-pa-10-15" href="#accordion-12-body-01" data-toggle="collapse" data-parent="#accordion-12" aria-expanded="true" aria-controls="accordion-12-body-01">
                              <span class="u-accordion__control-icon g-mr-10">
                                <i class="fa fa-angle-down"></i>
                                <i class="fa fa-angle-up"></i>
                              </span>
                             <p style="color:white"> Motor</p>
                            </a>
                          </h6>
                      </div>
                      <div id="accordion-12-body-01" class="collapse show" role="tabpanel" aria-labelledby="accordion-12-heading-01" data-parent="#accordion-12">
                          <div class="u-accordion__body g-color-gray-dark-v5">
                              <ol>
                                  <li>Duly completed Claims Form</li>
                                  <li>Repair/Replacement Estimate</li>
                                  <li>Police Report</li>
                                  <li>Copy of Certificate of Insurance</li>
                                  <li>For Total Loss – original vehicle registration documents & keys</li>
                              </ol>
                          </div>
                      </div>
                  </div>
                  <!-- End Card -->

                  <!-- Card -->
                  <div class="card g-brd-none rounded-0 g-mb-15">
                      <div id="accordion-12-heading-02" style=" background-color:#efb225;" class="u-accordion__header g-pa-0" role="tab">
                          <h6 class="mb-0">
                            <a class="collapsed d-flex g-color-main g-text-underline--none--hover g-brd-around g-brd-gray-light-v4 g-rounded-5 g-pa-10-15" href="#accordion-12-body-02" data-toggle="collapse" data-parent="#accordion-12" aria-expanded="false" aria-controls="accordion-12-body-02">
                              <span class="u-accordion__control-icon g-mr-10">
                                <i class="fa fa-angle-down"></i>
                                <i class="fa fa-angle-up"></i>
                              </span>
                              <p style="color:white">Fire</p>
                            </a>
                          </h6>
                      </div>
                      <div id="accordion-12-body-02" class="collapse" role="tabpanel" aria-labelledby="accordion-12-heading-02" data-parent="#accordion-12">
                          <div class="u-accordion__body g-color-gray-dark-v5">
                              <ol>
                                  <li>Duly completed Claims Form</li>
                                  <li>Repair/Reinstatement Estimate</li>
                                  <li>Statement of Claim</li>
                                  <li>Copy of Certificate of Insurance</li>
                                  <li>Photograph of Damaged / Destroyed Building / Items Insured</li>
                              </ol>
                          </div>
                      </div>
                  </div>
                  <!-- End Card -->

                  <!-- Card -->
                  <div class="card g-brd-none rounded-0 g-mb-15">
                      <div id="accordion-12-heading-03" style=" background-color:#efb225;" class="u-accordion__header g-pa-0" role="tab">
                          <h6 class="mb-0">
                            <a class="collapsed d-flex g-color-main g-text-underline--none--hover g-brd-around g-brd-gray-light-v4 g-rounded-5 g-pa-10-15" href="#accordion-12-body-03" data-toggle="collapse" data-parent="#accordion-12" aria-expanded="false" aria-controls="accordion-12-body-03">
                              <span class="u-accordion__control-icon g-mr-10">
                                <i class="fa fa-angle-down"></i>
                                <i class="fa fa-angle-up"></i>
                              </span>
                              <p style="color:white">Burglary</p>
                            </a>
                          </h6>
                      </div>
                      <div id="accordion-12-body-03" class="collapse" role="tabpanel" aria-labelledby="accordion-12-heading-03" data-parent="#accordion-12">
                          <div class="u-accordion__body g-color-gray-dark-v5">
                              <ol>
                                  <li>Duly completed Claims Form</li>
                                  <li>Police Report</li>
                                  <li>Estimate of Claim</li>
                                  <li>Purchase Invoice or Receipts of lost items</li>
                                  <li>Photographs where applicable</li>
                              </ol>
                          </div>
                      </div>
                  </div>
                  <div class="card g-brd-none rounded-0 g-mb-15">
                      <div id="accordion-12-heading-04" style=" background-color:#efb225;" class="u-accordion__header g-pa-0" role="tab">
                          <h6 class="mb-0">
                                  <a class="collapsed d-flex g-color-main g-text-underline--none--hover g-brd-around g-brd-gray-light-v4 g-rounded-5 g-pa-10-15" href="#accordion-12-body-04" data-toggle="collapse" data-parent="#accordion-12" aria-expanded="false" aria-controls="accordion-12-body-04">
                                    <span class="u-accordion__control-icon g-mr-10">
                                      <i class="fa fa-angle-down"></i>
                                      <i class="fa fa-angle-up"></i>
                                    </span>
                                   <p style="color:white">Money</p>
                                  </a>
                                </h6>
                      </div>
                      <div id="accordion-12-body-04" class="collapse" role="tabpanel" aria-labelledby="accordion-12-heading-04" data-parent="#accordion-12">
                          <div class="u-accordion__body g-color-gray-dark-v5">
                              <ol>
                                  <li>Duly completed Claims Form</li>
                                  <li>Police Report</li>
                                  <li>Estimate of Claim</li>
                                  <li>Copy of cash movement register prior to/after the incident</li>
                                  <li>Copy of Cash Analysis before and after the theft</li>

                              </ol>
                          </div>
                      </div>
                  </div>

                  <div class="card g-brd-none rounded-0 g-mb-15">
                      <div id="accordion-12-heading-05" style=" background-color:#efb225;" class="u-accordion__header g-pa-0" role="tab">
                          <h6 class="mb-0">
                                      <a class="collapsed d-flex g-color-main g-text-underline--none--hover g-brd-around g-brd-gray-light-v4 g-rounded-5 g-pa-10-15" href="#accordion-12-body-05" data-toggle="collapse" data-parent="#accordion-12" aria-expanded="false" aria-controls="accordion-12-body-05">
                                        <span class="u-accordion__control-icon g-mr-10">
                                          <i class="fa fa-angle-down"></i>
                                          <i class="fa fa-angle-up"></i>
                                        </span>
                                       <p style="color:white">Marine Hull</p>
                                      </a>
                                    </h6>
                      </div>
                      <div id="accordion-12-body-05" class="collapse" role="tabpanel" aria-labelledby="accordion-12-heading-05" data-parent="#accordion-12">
                          <div class="u-accordion__body g-color-gray-dark-v5">
                              <ol>
                                  <li>Duly completed Claims Form</li>
                                  <li>Marine Police Report [Interim & Final]</li>
                                  <li>Loss Engineer or Inspection Report</li>
                                  <li>Last record of servicing or overhauling of Boats</li>
                                  <li>Records of last trips carried out with the Boats</li>
                              </ol>
                          </div>
                      </div>
                  </div>

                  <div class="card g-brd-none rounded-0 g-mb-15">
                      <div id="accordion-12-heading-06" style=" background-color:#efb225;" class="u-accordion__header g-pa-0" role="tab">
                          <h6 class="mb-0">
                                          <a class="collapsed d-flex g-color-main g-text-underline--none--hover g-brd-around g-brd-gray-light-v4 g-rounded-5 g-pa-10-15" href="#accordion-12-body-06" data-toggle="collapse" data-parent="#accordion-12" aria-expanded="false" aria-controls="accordion-12-body-06">
                                            <span class="u-accordion__control-icon g-mr-10">
                                              <i class="fa fa-angle-down"></i>
                                              <i class="fa fa-angle-up"></i>
                                            </span>
                                           <p style="color:white">Marine</p>
                                          </a>
                                        </h6>
                      </div>
                      <div id="accordion-12-body-06" class="collapse" role="tabpanel" aria-labelledby="accordion-12-heading-06" data-parent="#accordion-12">
                          <div class="u-accordion__body g-color-gray-dark-v5">
                              <ol>
                                  <li>Duly completed Claims Form</li>
                                  <li>Marine Police Report [Interim & Final]</li>
                                  <li>Loss Engineer or Inspection Report</li>
                                  <li>Last record of servicing or overhauling of Boats</li>
                                  <li>Records of last trips carried out with the Boats</li>
                              </ol>
                          </div>
                      </div>
                  </div>
                  <div class="card g-brd-none rounded-0 g-mb-15">
                      <div id="accordion-12-heading-07" style=" background-color:#efb225;" class="u-accordion__header g-pa-0" role="tab">
                          <h6 class="mb-0">
                              <a class="collapsed d-flex g-color-main g-text-underline--none--hover g-brd-around g-brd-gray-light-v4 g-rounded-5 g-pa-10-15"
                                  href="#accordion-12-body-07" data-toggle="collapse" data-parent="#accordion-12" aria-expanded="false"
                                  aria-controls="accordion-12-body-07">
                                  <span class="u-accordion__control-icon g-mr-10">
                                      <i class="fa fa-angle-down"></i>
                                      <i class="fa fa-angle-up"></i>
                                  </span>
                                  <p style="color:white">Life</p>
                              </a>
                          </h6>
                      </div>
                      <div id="accordion-12-body-07" class="collapse" role="tabpanel" aria-labelledby="accordion-12-heading-07"
                          data-parent="#accordion-12">
                          <div class="u-accordion__body g-color-gray-dark-v5">

                              <p>The following documents are required depending on the type of Life claim:</p>
                              <ol>
                                  <li> <strong>Death</strong>
                                      <ul>
                                          <li>Medical Certificate of Cause of Death</li>
                                          <li>Police Report (if death is by accident)</li>
                                          <li>Means of identification of the beneficiary</li>
                                          <li>Utility Bill of the beneficiary</li>
                                      </ul>

                                  </li>
                                  <li> <strong>Surrender</strong>
                                      <ul>
                                          <li>Request letter </li>
                                          <li>Means of identification of the beneficiary</li>
                                      </ul>

                                  </li>
                                  <li> <strong>Total and Permanent Disability or Critical illness</strong>
                                      <ul>
                                          <li>Medical attendance Report</li>
                                      </ul>

                                  </li>

                              </ol>
                          </div>
                      </div>
                  </div>
                  <div class="card g-brd-none rounded-0 g-mb-15">
                      <div id="accordion-12-heading-08" style=" background-color:#efb225;" class="u-accordion__header g-pa-0" role="tab">
                          <h6 class="mb-0">
                              <a class="collapsed d-flex g-color-main g-text-underline--none--hover g-brd-around g-brd-gray-light-v4 g-rounded-5 g-pa-10-15"
                                  href="#accordion-12-body-08" data-toggle="collapse" data-parent="#accordion-12" aria-expanded="false"
                                  aria-controls="accordion-12-body-08">
                                  <span class="u-accordion__control-icon g-mr-10">
                                      <i class="fa fa-angle-down"></i>
                                      <i class="fa fa-angle-up"></i>
                                  </span>
                                  <p style="color:white">Life Claims Settlement</p>
                              </a>
                          </h6>
                      </div>
                      <div id="accordion-12-body-08" class="collapse" role="tabpanel" aria-labelledby="accordion-12-heading-08"
                          data-parent="#accordion-12">
                          <div class="u-accordion__body g-color-gray-dark-v5">
                             <table class="table table-striped table-bordered">
                                 <thead>
                                     <tr>
                                         <th>Type of Claims</th>
                                         <th>Performance Indicator</th>
                                         <th>Target Timeline</th>
                                         <th>Remarks</th>
                                     </tr>

                                 </thead>
                                 <tr>
                                     <td rowspan="2" >Partial and Full Maturity Claim</td>
                                     <td>Claims Process</td>
                                     <td>Within 48 hours</td>
                                     <td>Payment to be made on or before partial/full maturity date.</td>
                                 </tr>
                                 <tr>
                                  <td>Payment Process</td>
                                  <td>Within 48 hours</td>
                                  <td>From the time of receipt of Discharge Voucher</td>
                              </tr>
                              <tr>
                                  <td  rowspan="3">Individual Life death and Termination Claim</td>
                                  <td>Acknowledgement</td>
                                  <td>Within 24 hours</td>
                                  <td>From time of receipt of claim notification.</td>
                              </tr>
                              <tr>
                                  <td>Claims Process</td>
                                  <td>Within 72 hours</td>
                                  <td>From the time of receipt and confirmation of full documentation.</td>
                              </tr>
                              <tr>
                                  <td>Payment Process</td>
                                  <td>Within 48 hours</td>
                                  <td>From the time of receipt of discharge voucher and full documentation.</td>
                              </tr>
                              <tr>
                                  <td  rowspan="3">Group Life Claim</td>
                                  <td>Acknowledgement</td>
                                  <td>Within 24 hours</td>
                                  <td>From time of receipt of claim notification.</td>
                              </tr>
                              <tr>
                                  <td>Death Process</td>
                                  <td>Within 48 hours</td>
                                  <td>From the time of receipt and confirmation of full documentation.</td>
                              </tr>
                              <tr>
                                  <td>Payment Process</td>
                                  <td>Within 48 hours</td>
                                  <td>From the time of receipt of discharge voucher and full documentation.</td>
                              </tr>
                             </table>
                             <br>
                             <p><strong>NB: All hours are working days only (Monday – Friday) and do not include public holidays.</strong></p>
                          </div>
                      </div>
                  </div>
                  <div class="card g-brd-none rounded-0 g-mb-15">
                      <div id="accordion-12-heading-09" style=" background-color:#efb225;" class="u-accordion__header g-pa-0" role="tab">
                          <h6 class="mb-0">
                              <a class="collapsed d-flex g-color-main g-text-underline--none--hover g-brd-around g-brd-gray-light-v4 g-rounded-5 g-pa-10-15"
                                  href="#accordion-12-body-09" data-toggle="collapse" data-parent="#accordion-12" aria-expanded="false"
                                  aria-controls="accordion-12-body-09">
                                  <span class="u-accordion__control-icon g-mr-10">
                                      <i class="fa fa-angle-down"></i>
                                      <i class="fa fa-angle-up"></i>
                                  </span>
                                  <p style="color:white">Non-Life Claims Settlement</p>
                              </a>
                          </h6>
                      </div>
                      <div id="accordion-12-body-09" class="collapse" role="tabpanel" aria-labelledby="accordion-12-heading-09"
                          data-parent="#accordion-12">
                          <div class="u-accordion__body g-color-gray-dark-v5">
                              <table class="table table-striped table-bordered">
                                  <thead>
                                      <tr>
                                          <th>Classification</th>
                                          <th>Performance Indicator</th>
                                          <th>Target Timeline</th>
                                          <th>Remarks</th>
                                      </tr>

                                  </thead>
                                  <tr>
                                      <td rowspan="2" >Motor Claims</td>
                                      <td>Acknowledgement</td>
                                      <td>Within 24 hours</td>
                                      <td>Upon receipt of claim notification.</td>
                                  </tr>
                                  <tr>
                                   <td>Payment Process</td>
                                   <td>Within 48 hours</td>
                                   <td>Upon receipt of executed discharge voucher </td>
                               </tr>
                               <tr>
                                  <td rowspan="2" >Non-Motor Claims</td>
                                  <td>Acknowledgement</td>
                                  <td>Within 24 hours</td>
                                  <td>From time of receipt of claim notification.  </td>
                              </tr>
                              <tr>
                               <td>Payment Process</td>
                               <td>Within 48 hours</td>
                               <td>Upon receipt of executed discharge voucher </td>
                           </tr>

                              </table>
                              <br>
                              <p><strong>NB: All hours are working days only (Monday – Friday) and do not include public holidays.</strong></p>
                          </div>
                      </div>
                  </div>
                  <div class="card g-brd-none rounded-0 g-mb-15">
                      <div id="accordion-12-heading-10" style=" background-color:#efb225;" class="u-accordion__header g-pa-0" role="tab">
                          <h6 class="mb-0">
                              <a class="collapsed d-flex g-color-main g-text-underline--none--hover g-brd-around g-brd-gray-light-v4 g-rounded-5 g-pa-10-15"
                                  href="#accordion-12-body-10" data-toggle="collapse" data-parent="#accordion-12" aria-expanded="false"
                                  aria-controls="accordion-12-body-10">
                                  <span class="u-accordion__control-icon g-mr-10">
                                      <i class="fa fa-angle-down"></i>
                                      <i class="fa fa-angle-up"></i>
                                  </span>
                                  <p style="color:white">Procedure for Lodging Complaint</p>
                              </a>
                          </h6>
                      </div>
                      <div id="accordion-12-body-10" class="collapse" role="tabpanel" aria-labelledby="accordion-12-heading-10"
                          data-parent="#accordion-12">
                          <div class="u-accordion__body g-color-gray-dark-v5">
                              <p>We thank you for choosing to insure with us.</p>
                              <p>Do you have any unresolved issues or complaints? Kindly follow the following steps</p>
                              <div class="complaint-content">
                                  <ul>
                                      <li>Please contact our Customer Service Unit (CSU) on 01-2718199 or send an email to complaint@nsiainsurance.com  to ensure swift resolution.</li>
                                      <li>Where you are not satisfied with the resolution from our CSU or Complaint handling team, Please escalate via email to the Managing Director via enquiry@nsiainsurance.com </li>
                                      <li>In event that you are not satisfied with the outcome of our internal procedure, you may refer the issue to the National Insurance Commission through;
                                          <br> <br>
                                          The Commissioner for Insurance,<br>
                                          National Insurance Commission of Nigeria <br>
                                          Plot 1239, Ladoke Akintola Boulevard, <br>
                                          Garki II, Abuja. <br>
                                          Telephone No: (09)2915101

                                      </li>

                                  </ul>
                                  <p><strong>It is important to note that these procedures do not affect your legal rights. </strong></p>
                              </div>

                          </div>
                      </div>
                  </div>
                  <!-- End Card -->
              </div>

          </div>
      </div>

      <!-- Sidebar -->
      <div class="col-lg-3 g-mb-30">
          <!-- Links -->
          <app-sidebar></app-sidebar>
          <!-- End Fast Facts -->
      </div>
      <!-- End Sidebar -->
  </div>
</div>
