                                      <!-- <div class="container g-pt-100 g-pb-70">
                                        <div class="row">
                                          <div class="col-lg-9 g-mb-30">-->
                                            <section class="g-bg-gray-light-v5 g-py-20" >
                                              <form novalidate #f="ngForm" (ngSubmit)="Onquotesubmit(f)">
                                                 <div class="container g-py-100" style="margin-top:-50px">
                                                   <div class="u-shadow-v19 g-max-width-645 g-brd-around g-brd-gray-light-v4 text-center rounded mx-auto g-pa-30">
                                                     <span class="u-icon-v3 u-icon-size--lg g-color-white g-bg-primary rounded-circle g-pa-15 mb-5">
                                                       <svg width="30" height="30" version="1.1" id="Capa_1" x="0px" y="0px"
                                                         viewBox="0 0 497.5 497.5" style="enable-background:new 0 0 497.5 497.5;" xml:space="preserve">
                                                         <g>
                                                           <path d="M487.75,78.125c-13-13-33-13-46,0l-272,272l-114-113c-13-13-33-13-46,0s-13,33,0,46l137,136
                                                             c6,6,15,10,23,10s17-4,23-10l295-294C500.75,112.125,500.75,91.125,487.75,78.125z" fill="#fff"/>
                                                         </g>
                                                       </svg>
                                                     </span>

                                                     <div class="mb-5">
                                                       <h2 class="mb-4"><p>Get a quote!</p></h2>
                                                       <div >
                                                   <div class="form-group row" >

                                                    <select class="form-control border-input"  id="class" name="class" [(ngModel)]="quoteData.bizclass" required >
                                                        <option value="" selected disabled>Select Product Type</option>
                                                        <option value="Personal Insurance">Personal Insurance</option>
                                                        <option value="Personal Insurance">Business Insurance</option>
                                                        <option value="Personal Insurance">Motor Insurance</option>
                                                        <option value="Personal Insurance">Life Insurance</option>
                                                        <option value="Personal Insurance">Energy & Special Risk Insurance</option>
                                                        <option value="Personal Insurance">Fire & Special Perils Insurance</option>
                                                        <option value="Personal Insurance">Health Insurance</option>
                                                        <option value="Personal Insurance">Agric Insurance</option>
                                                        <option value="Personal Insurance">Travel Insurance</option>

                                                      </select>
                                                       </div>
                                                       <div class="form-group row g-mb-25">

                                                        <input style="width:300px" id="email" name="email" class="form-control form-control-md rounded-0" type="email" placeholder="Enter Email" [(ngModel)]="quoteData.email" required style="width:300px">
                                                       </div>
                                                       <div class="form-group row g-mb-25">

                                                        <input style="width:300px" id="phoneno" name="phoneno" class="form-control form-control-md rounded-0" type="text" placeholder="Enter phone number" [(ngModel)]="quoteData.phoneno" required style="width:300px">
                                                       </div>

                                                      </div>
                                                       <button class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" type="submit" [disabled]="!f.valid">submit </button>
                                                     </div>

                                                   </div>
                                                 </div>
                                                </form>
                                              </section>
                                                  <!-- </div>
                                                  <div class="col-lg-3 g-mb-30"> -->
                                                    <!-- Links -->
                                                    <!-- <app-sidebar></app-sidebar> -->
                                                    <!-- End Fast Facts -->
                                                  <!-- </div> -->
                                                  <!-- End Sidebar -->
                                                <!-- </div>
                                              </div> -->
