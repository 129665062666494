import { Component, OnInit } from '@angular/core';
import { dateFormat, Formclass } from 'src/app/Classes/formclass';
import { Minor, NextofKin,Userdata } from 'src/app/Classes/userdata';
import { FormserviceService } from 'src/app/Services/formservice.service';
import swal from 'sweetalert2';
@Component({
  selector: 'app-beneficiary-form',
  templateUrl: './beneficiary-form.component.html',
  styleUrls: ['./beneficiary-form.component.css']
})
export class BeneficiaryFormComponent implements OnInit {

  constructor(private _formservice: FormserviceService) { }
  datemodel = new dateFormat(1800, 1, 31);
  minorModel= new Minor("", "", "", "", "");
  nokModel = new NextofKin("", "", "", "", "", "");
  userModel = new Userdata("", "", "", "", "", "", "", "",[], []);
  formData = new Formclass("", "","","","",this.datemodel,"","","","","","",this.datemodel,"", "", "", "", "", "", this.datemodel, "", "");
  ShowMinorTable: boolean = false;
  ShowNextofKin: boolean = false;
  ngOnInit(): void {
  }
  addNOK(){
    this.nokModel.NkSurname = this.formData.NkSurname;
    this.nokModel.PhoneEmail = this.formData.NkPhoneNumber + "/" + this.formData.NkEmailAddress;
    this.nokModel.NkRelationship = this.formData.NkRelationship;
    this.nokModel.NkProportion = this.formData.NkProportion;
    this.nokModel.NkFirstname = this.formData.NkFirstname;
    this.nokModel.NkDateOfBirth = String(this.formData.NkDateOfBirth.day) + "/" + String(this.formData.NkDateOfBirth.month) + "/" + String(this.formData.NkDateOfBirth.year);
    this.userModel.NextofKins.push(this.nokModel);
    this.formData.NkSurname = "";
    this.formData.NkPhoneNumber = "";
    this.formData.NkEmailAddress = "";
    this.formData.NkRelationship = "";
    this.formData.NkFirstname = "";
    this.formData.NkDateOfBirth = this.datemodel;
    if(this.userModel.NextofKins.length > 0){
      this.ShowNextofKin = true;
    }


  }
  addMinor(){
    this.minorModel.MnDateOfBirth = String(this.formData.MnDateOfBirth.day) + "/" + String(this.formData.MnDateOfBirth.month) + "/" + String(this.formData.MnDateOfBirth.year);
    this.minorModel.MnFirstname = this.formData.MnFirstname;
    this.minorModel.MnPhoneEmail = this.formData.MnPhoneNumber + "/" + this.formData.MnEmailAddress;
    this.minorModel.MnSurname = this.formData.MnSurname;
    this.minorModel.MnTrustee = this.formData.MnTrustee;
    this.formData.MnDateOfBirth = this.datemodel;
    this.formData.MnFirstname = "";
    this.formData.MnPhoneNumber = "";
    this.formData.MnEmailAddress = "";
    this.formData.MnSurname = "";
    this.formData.MnTrustee = "";
    this.userModel.Minors.push(this.minorModel);
    if (this.userModel.Minors.length > 0) {
      this.ShowMinorTable= true;
    }
  }
  submitForm(){
    this._formservice.startLoading();
    this.userModel.DateOfBirth = String(this.formData.DateOfBirth.day) + "/" + String(this.formData.DateOfBirth.month) + "/" + String(this.formData.DateOfBirth.year);
    this.userModel.EmployerName = this.formData.EmployerName;
    this.userModel.Firstname = this.formData.Firstname;
    this.userModel.Gender = this.formData.Gender;
    this.userModel.Othername = this.formData.Othername;
    this.userModel.PhoneEmail = this.formData.PhoneNumber + "/" + this.formData.EmailAddress;
    this.userModel.StaffNumber = this.formData.StaffNumber;
    this.userModel.Surname = this.formData.Surname;
   this._formservice.sendForm(this.userModel)
   .subscribe(
     (data) => {
       console.log(data);

       if (data.statusCode == "00") {
         swal.fire("Success", "Your form is submitted", "success");
       } else {
         swal.fire("error", data.statusMessage, "info");
       }
       this._formservice.stopLoading();
       this.formData = new Formclass("", "", "", "", "", this.datemodel, "", "", "", "", "", "", this.datemodel, "", "", "", "", "", "", this.datemodel, "", "");
     },
     (error) => {
       console.log(error);
       swal.fire("fatal", "internal server error occurred", "error");
       this._formservice.stopLoading();
     }
   )


  }
}
