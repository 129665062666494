export class BurglaryInsuranceProposalForm {
  constructor(
    public NameOfProposal:string,
    public AddressOfProposal:string,
    public TradeOrBusiness:string,
    public EmailAddress:string,
    public Telephone:string,
    public SecondTelephone:string,
    public StartDate: Date,
    public EndDate: Date,
    public occuppiedPremisses: string,
    public BuildingDescription:string,
    public OCCUPIER:string,
    public liveonthepremises:string,
    public premisesbeoccupied : string,
    public Privatedwelling: string,
    public soleoccupant:string,
    public premisesbeleftuninhabited: string,
    public content: string,
    public stockregularly: string,
    public booksregularly: string,
    public propertytobeinsured: string,
    public Havethievesentered: string,
    public extentofloss: string,
    public extraprecautions: string,
    public antitheftdevices: string,
    public anyinsuranceinforce: string,
    public evermadeaclaim: string,
    public companydecline: string,
    public cancelledorrefused: string,
    public imposedanyspecialterms: string,
    public gainentry:string

  ) {

  }
}
