
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;">UNDERSTANDING THIRD PARTY LIABILITY INSURANCE </p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/third_party_insurance.jpg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>Recently, on my way back from the office, I endured very slow traffic for about 30 minutes. I later realized that we were being held up by two motorists parked in the middle of the road, arguing about scratches and dents to their cars. I was irritated and surprised that people still fight and argue over accidents on busy Lagos roads without parking off to ease traffic movement; an act that causes untold hardship to other road users, sometime even leading to multiple accidents and robbery attacks.
                 </p>
                 <p></p>
                 <p>It’s about time we understood that a motor vehicle insurance certificate is not just one of our vehicle papers. Every road user is expected to have at least a third party insurance cover which will take care of the liability of negligent road users to the third party. Third-party liability covers the insured against damages and losses incurred by another road user resulting from an accident. The first party is the party with the insurance contract, the second party is the insurance company and the third party is the other road user(s).</p>
                 <p></p>


              </div>
           </div>


          <div class="row justify-content-between">


              <div class="col-md-12">


                 <p><strong>Covers available under third party liability insurance policy</strong>
                 </p>
                 <p></p>

                 <p>There are two types of third-party liability coverage. First, bodily injury liability covers costs resulting from injuries to a person. This could include expenses like hospital bills, lost wages, compensation for pain and suffering due to the accident. Second, property damage liability covers costs resulting from damages to or loss of property. Examples of property damage liability include the payment to replace or repair a damaged structure.</p>

                <p></p>

                <p>There are two types of third-party liability coverage. First, bodily injury liability covers costs resulting from injuries to a person. This could include expenses like hospital bills, lost wages, compensation for pain and suffering due to the accident. Second, property damage liability covers costs resulting from damages to or loss of property. Examples of property damage liability include the payment to replace or repair a damaged structure.</p>

                <p></p>
                <p><strong>Steps to take when there is accident</strong></p>

               <p></p>
               <p>The minimum benefit of a third-party liability covers the repairs of the damaged property of the third party. So, in order to avoid unnecessary arguments on the road, here’s what you should do:</p>
               <ul>
                 <li>Accept your wrong</li>
                 <li>Take a picture of the incident</li>
                 <li>Get a copy of your insurance certificate and driver’s licence</li>
                 <li>Lodge a claim with your insurance provider</li>
               </ul>
              <p></p>
               <p>For more information on our auto insurance policies, please visit our website at <a href="https://nsiainsurance.com/motorinsurance">https://nsiainsurance.com/motorinsurance</a> or call us on 09048418896.</p>
            <p><strong>DRIVE SAFELY.</strong></p>
            <p></p>
            <p>*Feature story by Oluwafunmilayo Ogunbiyi*</p>
              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>