import { Component, OnInit } from '@angular/core';
import { QuoteData } from 'src/app/Classes/quote-data';
import { FormserviceService } from 'src/app/Services/formservice.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-life-quote',
  templateUrl: './life-quote.component.html',
  styleUrls: ['./life-quote.component.css']
})
export class LifeQuoteComponent implements OnInit {
  date: Date = new Date();
  settings = {
    bigBanner: false,
    timePicker: false,
    format: 'yyyy-MM-dd',
    defaultOpen: false
  }
  thisYear: number;
  formData = new QuoteData("", "", "", "", this.date, this.date, "");
  constructor(private _formservice: FormserviceService) { }

  ngOnInit(): void {
  }
  submitForm(){
    this._formservice.startLoading();
    this.thisYear = new Date().getFullYear();
    let difference = this.thisYear - new Date(this.formData.DateOfBirth).getFullYear();
    if (difference >= 18 && difference <= 65) {
      this.formData.FormattedDateOfBirth = new Date(this.formData.DateOfBirth);
       this._formservice.sendLifeQuote(this.formData)
       .subscribe(
         (data) => {
           if (data.statusCode == "00") {
             Swal.fire("Success", "We have received your request. we will contact you shortly", "success");
            }else{
              Swal.fire("Error", data.statusMessage, "error");
           }
           this._formservice.stopLoading();
         },
         (error) => {
           console.log(error);
           Swal.fire("fatal", "internal server error occurred");
           this._formservice.stopLoading();
         }
       )
    }else{
      Swal.fire("Error", "Age must be between 18yrs to 65yrs");
      this._formservice.stopLoading();
      return;
    }


  }
}
