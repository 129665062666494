import { Component, OnInit } from '@angular/core';
import { DebitMandateModel } from 'src/app/Classes/debit-mandate-model';
import { Utilities } from 'src/app/Helpers/utilities';
import { FormserviceService } from 'src/app/Services/formservice.service';


@Component({
  selector: 'app-debit-mandate-form',
  templateUrl: './debit-mandate-form.component.html',
  styleUrls: ['./debit-mandate-form.component.css']
})
export class DebitMandateFormComponent implements OnInit {
  model;
  month:any;
  day:any;
  year:any;
  maxDate:any;

  debitMandateData = new DebitMandateModel(0, '', '', '', '', '', '', '', '', 0, '', '', '', '', '','','','', false,'','','','','','','','');
  constructor(private _formService: FormserviceService, private _utils: Utilities ) { }

  ngOnInit(): void {
    var dtToday = new Date();

    this.month = dtToday.getMonth() + 1;
    this.day = dtToday.getDate();
    this.year = dtToday.getFullYear();
    if(this.month < 10)
    this.month = '0' + this.month.toString();
    if(this.day < 10)
    this.day = '0' + this.day.toString();

    this.debitMandateData.from = this.year + '-' + this.month + '-' + this.day;
    this.debitMandateData.to = this.year + '-' + this.month + '-' + this.day;

    document.getElementById("from").setAttribute("min", this.debitMandateData.from);
    document.getElementById("to").setAttribute("min", this.debitMandateData.to);
    document.getElementById("accountNumber").setAttribute("max", "10");
    document.getElementById("telephone").setAttribute("max", "11");
  }

  selected(files: FileList)
  {
    let theFile = files[0];
    this._utils.ResizeImage(theFile, 128, 128)
    .subscribe(
      (file : File) =>{
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event) => {
          this.debitMandateData.src= reader.result.toString()
          this.debitMandateData.filenameId= file.name
          this.debitMandateData.filetypeId= file.type
          this.debitMandateData.filestringId=this.debitMandateData.src.substr(this.debitMandateData.src.indexOf(',')+ 1)
        }
      }
    )

  }
  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  //for Decimal you can use this as

  onlyDecimalNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
        && (charCode < 48 || charCode > 57))
        return false;
    return true;
  }


    submitForm() {
      var email = this.debitMandateData.emailAddress;
      var phonenumber = this.debitMandateData.telephone;
      var accountNumber =this.debitMandateData.accountNumber;

      var filestringId = this.debitMandateData.filestringId;
      var regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;


    if(!regex.test(email)) {
      alert("Your email is not correct");
      this._formService.stopLoading();
    }

    else if(filestringId==''){
      alert("Upload your signature");
      this._formService.stopLoading();
    }

    else{

     this._formService.startLoading();
     this._formService.sendDebitMandateForm(this.debitMandateData)
     .subscribe(
       (data) => {
         console.log(data);
         if (data.statusCode == "00") {
          alert("Your message is submitted. We will contact you shortly");
          localStorage.clear();
         } else {
           alert(data.statusMessage);
         }
         this._formService.stopLoading();
         this.debitMandateData = new DebitMandateModel(0, '', '', '', '', '', '', '', '', 0, '', '', '', '', '','','','',false, '','','','','','','','');
       },
       (error) => {
         console.log(error);
        alert("Internal server error occurred");
         this._formService.stopLoading();
       }
     )
     }
  }

}
