
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;">RENEWAL IN THE NEW YEAR</p>
              </strong>
           </h3>
           <br/>
           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/renewal.jpeg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>
                    
    The start of a new year is a great time to reevaluate and renew your insurance policies, as changing circumstances may lead to different insurance needs. Did you have a baby? Get married? Purchase a new home or car? If so, you'll want to check whether you have the right fit in policy protection. The renewal date of your insurance policy is based on the date your policy took effect and the length of your policy period.
Take some time to talk with your agent to review your policies and see if they meet your current needs. Your agent or company can help determine whether your current policies provide adequate coverage or if you might need more or less. 
Your renewal is a good time to review your policy to verify discounts or make changes to coverage. It helps to have a reminder because insurance can be easy to forget about if you haven’t needed to use it recently. It's a good time to contact your insurance agent or your insurance company to review your policy. You may want to make changes to your coverage if your situation has changed. You may want to shop for another policy if you're not happy with your rates or service.
The global insurance industry has seen substantial changes over the past decade. The availability of data has skyrocketed. Advancements in digital and mobile technology have raised the bar on transparency and service quality as customers can now file claims and access agents, insurance quotes, and policy information with a few taps on a screen. Several areas now offer opportunities for personalization that can strengthen customer relationships. 
If you plan to continue your coverage, it's important to continue making your payments. Making your payment notifies the insurance company that you want to continue the policy. It's especially important to pay your renewal fees on time because some carriers don't allow any grace period on renewals. It's equally important to cancel your insurance policy if you don't want to continue your policy. This is because some companies do extend a grace period and you may be charged for the grace period if they are not notified of the cancellation.
Let us help you begin this year right. Talk to us today on 09048418896 to get started on the renewal process. 

                </p>
                 <p></p>

              </div>
           </div>
           <br/>
           <br/>
           <br/>

          <div class="row justify-content-between">
            
              <div class="col-md-12">
            
        </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>