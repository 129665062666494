import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormControl, FormGroup } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutusComponent } from './Web/aboutus/aboutus.component';
import { SidebarComponent } from './Templates/sidebar/sidebar.component';
import { NavBarComponent } from './Templates/nav-bar/nav-bar.component';
import { QuoteComponent } from './Templates/quote/quote.component';
import { MainserviceService } from './Services/mainservice.service';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TabComponent } from './Templates/tab/tab.component';
import { TabsComponent } from './Templates/tabs/tabs.component';
import { NgProgressModule } from 'ngx-progressbar';
import { AccidentInsuranceComponent } from './Web/accident-insurance/accident-insurance.component';
import { AgricInsuranceComponent } from './Web/agric-insurance/agric-insurance.component';
import { AllRiskProposalFormComponent } from './Web/all-risk-proposal-form/all-risk-proposal-form.component';
import { AlwaysReadYourPolicyDocumentsComponent } from './Web/Blog/always-read-your-policy-documents/always-read-your-policy-documents.component';
import { AnnuityComponent } from './Web/annuity/annuity.component';
import { AnticipatedComponent } from './Web/anticipated/anticipated.component';
import { AutoInsuranceComponent } from './Web/auto-insurance/auto-insurance.component';
import { AwardsComponent } from './Web/awards/awards.component';
import { WonderComponent } from './Web/wonder-woman/wonder-woman.component';
import { BasicInsuranceTerminologiesAndTheirMeaningComponent } from './Web/Blog/basic-insurance-terminologies-and-their-meaning/basic-insurance-terminologies-and-their-meaning.component';
import { BeneficiaryFormComponent } from './Web/beneficiary-form/beneficiary-form.component';
import { BenefitsOfATermAssurancePlanComponent } from './Web/Blog/benefits-of-a-term-assurance-plan/benefits-of-a-term-assurance-plan.component';
import { BlogComponent } from './Web/blog/blog.component';
import { BoardMembersComponent } from './Web/board-members/board-members.component';
import { BrainExercisesThatBoostMemoryComponent } from './Web/Blog/brain-exercises-that-boost-memory/brain-exercises-that-boost-memory.component';
import { BreastCancerHealthInsuranceComponent } from './Web/Blog/breast-cancer-health-insurance/breast-cancer-health-insurance.component';
import { BurglaryInsuranceComponent } from './Web/burglary-insurance/burglary-insurance.component';
import { BurglaryInsuranceProposalFormComponent } from './Web/burglary-insurance-proposal-form/burglary-insurance-proposal-form.component';
import { BusinessInsuranceComponent } from './Web/business-insurance/business-insurance.component';
import { BuyMotorComponent } from './Web/buy-motor/buy-motor.component';
import { CareerComponent } from './Web/career/career.component';
import { CareerAdvancementTipsComponent } from './Web/Blog/career-advancement-tips/career-advancement-tips.component';
import { CertificationComponent } from './Web/certification/certification.component';
import { ClaimProcessMadeEasierComponent } from './Web/claim-process-made-easier/claim-process-made-easier.component';
import { ClaimsComponent } from './Web/claims/claims.component';
import { CommonMistakeInInsuranceComponent } from './Web/Blog/common-mistake-in-insurance/common-mistake-in-insurance.component';
import { ComplaintFormComponent } from './Web/complaint-form/complaint-form.component';
import { ContactFormComponent } from './Web/contact-form/contact-form.component';
import { ContactUsComponent } from './Web/contact-us/contact-us.component';
import { ContractorAllRiskProposalFormComponent } from './Web/Forms/contractor-all-risk-proposal-form/contractor-all-risk-proposal-form.component';
import { CreditLifeComponent } from './Web/credit-life/credit-life.component';
import { CustomerFormComponent } from './Web/Forms/customer-form/customer-form.component';
import { CustomerServiceWeekComponent } from './Web/customer-service-week/customer-service-week.component';
import { CybersecurityBestPracticesComponent } from './Web/Blog/cybersecurity-best-practices/cybersecurity-best-practices.component';
import { DebitMandateFormComponent } from './Web/Forms/debit-mandate-form/debit-mandate-form.component';
import { DebunkingLifeInsuranceMythsComponent } from './Web/Blog/debunking-life-insurance-myths/debunking-life-insurance-myths.component';
import { DeferredAnnuityComponent } from './Web/deferred-annuity/deferred-annuity.component';
import { DeferredAnnuityPlanComponent } from './Web/deferred-annuity-plan/deferred-annuity-plan.component';
import { DistractionDrivingComponent } from './Web/Blog/distraction-driving/distraction-driving.component';
import { DownloadableComponent } from './Web/downloadable/downloadable.component';
import { DstvThanksComponent } from './Web/dstv-thanks/dstv-thanks.component';
import { EducationPlanComponent } from './Web/education-plan/education-plan.component';
import { EndowmentComponent } from './Web/endowment/endowment.component';
import { EnergyInsuranceComponent } from './Web/energy-insurance/energy-insurance.component';
import { EppComponent } from './Web/epp/epp.component';
import { EventComponent } from './Web/Events/events.component';
import { FamilyProtectionComponent } from './Web/family-protection/family-protection.component';
import { FaqComponent } from './Web/faq/faq.component';
import { FidelityComponent } from './Web/fidelity/fidelity.component';
import { FinancialReportComponent } from './Web/financial-report/financial-report.component';
import { FireComponent } from './Web/fire/fire.component';
import { FireInsuranceComponent } from './Web/fire-insurance/fire-insurance.component';
import { FireSpecialProposalFormComponent } from './Web/Forms/fire-special-proposal-form/fire-special-proposal-form.component';
import { FooterComponent } from './Web/Templates/footer/footer.component';
import { FosteringHealthRelationshipsComponent } from './Web/Blog/fostering-health-relationships/fostering-health-relationships.component';
import { GeneralBusinessProposalFormComponent } from './Web/Forms/general-business-proposal-form/general-business-proposal-form.component';
import { GeneralInsuranceComponent } from './Web/general-insurance/general-insurance.component';
import { GoodsProposalComponent } from './Web/goods-proposal/goods-proposal.component';
import { GroupLifeComponent } from './Web/group-life/group-life.component';
import { GroupLifeProposalFormComponent } from './Web/Forms/group-life-proposal-form/group-life-proposal-form.component';
import { GroupPersonalAccidentProposalComponent } from './Web/group-personal-accident-proposal/group-personal-accident-proposal.component';
import { HealthComponent } from './Web/health/health.component';
import { HolidayFireSafteyTipsComponent } from './Web/Blog/holiday-fire-saftey-tips/holiday-fire-saftey-tips.component';
import { HomeInsuranceComponent } from './Web/home-insurance/home-insurance.component';
import { HouseHolderInsuranceComponent } from './Web/house-holder-insurance/house-holder-insurance.component';
import { HouseholdInsuranceComponent } from './Web/household-insurance/household-insurance.component';
import { HowToMaintainYourCarComponent } from './Web/how-to-maintain-your-car/how-to-maintain-your-car.component';
import { ImmediateAnnuityComponent } from './Web/immediate-annuity/immediate-annuity.component';
import { ImportanceOfDataPrivacyInYourBusinessComponent } from './Web/importance-of-data-privacy-in-your-business/importance-of-data-privacy-in-your-business.component';
import { IndexBodyComponent } from './Web/index-body/index-body.component';
import { IndividualProposalFormComponent } from './Web/Forms/individual-proposal-form/individual-proposal-form.component';
import { InnovationsInInsuranceComponent } from './Web/innovations-in-insurance/innovations-in-insurance.component';
import { InsuranceAgricultureComponent } from './Web/insurance-agriculture/insurance-agriculture.component';
import { InsuranceEducationSectorComponent } from './Web/insurance-education-sector/insurance-education-sector.component';
import { InsurancePolicyHelpYouSaveMoneyComponent } from './Web/Blog/insurance-policy-help-you-save-money/insurance-policy-help-you-save-money.component';
import { InsuranceValueSocietyComponent } from './Web/insurance-value-society/insurance-value-society.component';
import { InvestmentPlanComponent } from './Web/investment-plan/investment-plan.component';
import { KeymanComponent } from './Web/keyman/keyman.component';
import { LiabilityInsuranceComponent } from './Web/liability-insurance/liability-insurance.component';
import { LifeInsuranceComponent } from './Web/life-insurance/life-insurance.component';
import { LifeProposalFormComponent } from './Web/life-proposal-form/life-proposal-form.component';
import { LifeQuoteComponent } from './Web/life-quote/life-quote.component';
import { LoadingPageComponent } from './Web/Templates/loading-page/loading-page.component';
import { ManagementTeamComponent } from './Web/management-team/management-team.component';
import { MarineComponent } from './Web/marine/marine.component';
import { MarineCargoProposalFormComponent } from './Web/Forms/marine-cargo-proposal-form/marine-cargo-proposal-form.component';
import { MoneyProposalFormComponent } from './Web/Forms/money-proposal-form/money-proposal-form.component';
import { MortgageComponent } from './Web/mortgage/mortgage.component';
import { MotorInsuranceComponent } from './Web/motor-insurance/motor-insurance.component';
import { MotorInsuranceProposalFormComponent } from './Web/Forms/motor-insurance-proposal-form/motor-insurance-proposal-form.component';
import { MotorResponseComponent } from './Web/motor-response/motor-response.component';
import { NollyWoodConnectComponent } from './Web/Blog/nolly-wood-connect/nolly-wood-connect.component';
import { NsiaAppointsDirectorsComponent } from './Web/Blog/nsia-appoints-directors/nsia-appoints-directors.component';
import { NsiaDeferredAnnuityPlanComponent } from './Web/Blog/nsia-deferred-annuity-plan/nsia-deferred-annuity-plan.component';
import { NsiaEducationProtectionPlanComponent } from './Web/nsia-education-protection-plan/nsia-education-protection-plan.component';
import { NsiaFamilyProtectionPlanComponent } from './Web/nsia-family-protection-plan/nsia-family-protection-plan.component';
import { NsiaInsuranceGoPaperlessComponent } from './Web/Blog/nsia-insurance-go-paperless/nsia-insurance-go-paperless.component';
import { NsiaInsuranceHoldsAgmComponent } from './Web/Blog/nsia-insurance-holds-agm/nsia-insurance-holds-agm.component';
import { NsiaInsurancePlanComponent } from './Web/nsia-insurance-plan/nsia-insurance-plan.component';
import { NsiaPlanLifeInsuranceSavingsComponent } from './Web/nsia-plan-life-insurance-savings/nsia-plan-life-insurance-savings.component';
import { PersonalAccidentComponent } from './Web/personal-accident/personal-accident.component';
import { PersonalAccidentProposalFormComponent } from './Web/Forms/personal-accident-proposal-form/personal-accident-proposal-form.component';
import { PrivacyComponent } from './Web/privacy/privacy.component';
import { ProfessionalIndemityProposalFormComponent } from './Web/Forms/professional-indemity-proposal-form/professional-indemity-proposal-form.component';
import { PromoComponent } from './Web/promo/promo.component';
import { PropertyInsuranceComponent } from './Web/property-insurance/property-insurance.component';
import { PublicLiabilityInsuranceProposalComponent } from './Web/public-liability-insurance-proposal/public-liability-insurance-proposal.component';
import { QuickGuideToHomeownersComponent } from './Web/Blog/quick-guide-to-homeowners/quick-guide-to-homeowners.component';
import { ReasonsYourBusinessNeedsKeymanAssuranceComponent } from './Web/Blog/reasons-your-business-needs-keyman-assurance/reasons-your-business-needs-keyman-assurance.component';
import { ReportClaimComponent } from './Web/report-claim/report-claim.component';
import { RetailProductComponent } from './Web/retail-product/retail-product.component';
import { RiskManagementComponent } from './Web/risk-management/risk-management.component';
import { SatisfyingThe21centuryCustomerComponent } from './Web/Blog/satisfying-the21century-customer/satisfying-the21century-customer.component';
import { SavingsPlanComponent } from './Web/savings-plan/savings-plan.component';
import { ScrollTopComponent } from './Web/scroll-top/scroll-top.component';
import { SevenInterestingFacetsAboutInsuranceComponent } from './Web/Blog/seven-interesting-facets-about-insurance/seven-interesting-facets-about-insurance.component';
import { SimpleWaysToBeMoreProductiveComponent } from './Web/Blog/simple-ways-to-be-more-productive/simple-ways-to-be-more-productive.component';
import { TenTipsForWorkingWithYourInsuranceCompanyComponent } from './Web/Blog/ten-tips-for-working-with-your-insurance-company/ten-tips-for-working-with-your-insurance-company.component';
import { TermAssuranceComponent } from './Web/term-assurance/term-assurance.component';
import { TheBeirutExplosionImportanceOfInsuranceComponent } from './Web/Blog/the-beirut-explosion-importance-of-insurance/the-beirut-explosion-importance-of-insurance.component';
import { ThingsToConsiderBeforeTakingThatTripComponent } from './Web/Blog/things-to-consider-before-taking-that-trip/things-to-consider-before-taking-that-trip.component';
import { ThirdPartyMotorVsComprehensiveMotorComponent } from './Web/Blog/third-party-motor-vs-comprehensive-motor/third-party-motor-vs-comprehensive-motor.component';
import { TipsOnImprovingTeamDynamicsComponent } from './Web/Blog/tips-on-improving-team-dynamics/tips-on-improving-team-dynamics.component';
import { TipsToImproveQualityOfLifeComponent } from './Web/Blog/tips-to-improve-quality-of-life/tips-to-improve-quality-of-life.component';
import { TipsToPreventHomeBurglaryComponent } from './Web/Blog/tips-to-prevent-home-burglary/tips-to-prevent-home-burglary.component';
import { TransportationComponent } from './Web/transportation/transportation.component';
import { TravelInsuranceComponent } from './Web/travel-insurance/travel-insurance.component';
import { TravelSafetyTipsComponent } from './Web/Blog/travel-safety-tips/travel-safety-tips.component';
import { UnderstandingTheExcessOnYourVehicleInsurancePolicyComponent } from './Web/Blog/understanding-the-excess-on-your-vehicle-insurance-policy/understanding-the-excess-on-your-vehicle-insurance-policy.component';
import { UnderstandingThirdPartyInsuranceComponent } from './Web/Blog/understanding-third-party-insurance/understanding-third-party-insurance.component';
import { ValidateAutoInsuranceCertificateComponent } from './Web/validate-auto-insurance-certificate/validate-auto-insurance-certificate.component';
import { WaysToRetainMoreOfEveryBookComponent } from './Web/Blog/ways-to-retain-more-of-every-book/ways-to-retain-more-of-every-book.component';
import { WhatHappensIfAGuestCausesDamageToMyHomeComponent } from './Web/Blog/what-happens-if-a-guest-causes-damage-to-my-home/what-happens-if-a-guest-causes-damage-to-my-home.component';
import { WhatToDoIfYouGetInACarAccidentComponent } from './Web/Blog/what-to-do-if-you-get-in-a-car-accident/what-to-do-if-you-get-in-a-car-accident.component';
import { WhistleBlowerComponent } from './Web/whistle-blower/whistle-blower.component';
import { WholeLifeComponent } from './Web/whole-life/whole-life.component';
import { WhyIsItImportantToInsureYourBusinessComponent } from './Web/Blog/why-is-it-important-to-insure-your-business/why-is-it-important-to-insure-your-business.component';
import { WorkOutFitnessTipsComponent } from './Web/Blog/work-out-fitness-tips/work-out-fitness-tips.component';
import { WorkingRemotelyComponent } from './Web/Blog/working-remotely/working-remotely.component';
import { WorkmenInsuranceProposalFormComponent } from './Web/Forms/workmen-insurance-proposal-form/workmen-insurance-proposal-form.component';
import { YouDonHitMyCarComponent } from './Web/Blog/you-don-hit-my-car/you-don-hit-my-car.component';
import { YoungHealthInsuranceComponent } from './Web/young-health-insurance/young-health-insurance.component';
import { AlphabetOnlyDirective } from './Directives/alphabet-only.directive';
import { OnlynumberDirective } from './Directives/onlynumber.directive';
import { FormserviceService } from './Services/formservice.service';
import { FileDownloadServiceService } from './Services/file-download-service.service';
import { MakeModelService } from './Services/make-model.service';
import { Utilities } from './Helpers/utilities';
import { NgxPicaModule } from 'ngx-tooning-pica';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AnxietyComponent } from './Web/Blog/anxiety/anxiety.component';
import { AutoPolicyNonrenewalVsCancellationComponent } from './Web/Blog/auto-policy-nonrenewal-vs-cancellation/auto-policy-nonrenewal-vs-cancellation.component';
import { InsuranceTipsForSaveSmartComponent } from './Web/Blog/insurance-tips-for-save-smart/insurance-tips-for-save-smart.component';
// import { BlogPostComponent } from './custom-blogs/blog-post/blog-post.component';
import { CustomBlogsModule } from './custom-blogs/custom-blogs.module';
// import { AngularMaterialModule } from './angular-material/angular-material.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AngularMaterialModule } from './angular-material/angular-material.module';
// import { BlogsDetailsComponent } from './blogs-details/blogs-details.component';
// import { BlogsComponent } from './custom-blogs/blogs/blogs.component';
// import  {AngularMaterialModule } from '../../angular-material/angular-material.module';
import { ThreeMoneyRulesComponent } from './Web/three-money-rules/three-money-rules.component';
import { DoItTodayNotTomorrowComponent } from './Web/do-it-today-not-tomorrow/do-it-today-not-tomorrow.component';
import { InsuranceWhatToExpectComponent } from './Web/insurance-what-to-expect/insurance-what-to-expect.component';
import { EffectiveSocialMediaStrategiesForBrandsComponent } from './Web/effective-social-media-strategies-for-brands/effective-social-media-strategies-for-brands.component';
import { NsiaAdsCampaignComponent } from './nsia-ads-campaign/nsia-ads-campaign.component';
import { CampaignAdsComponent } from './campaign-ads/campaign-ads.component';
import { ManageModule } from './manage/manage.module';
import { FlutterwaveModule } from 'flutterwave-angular-v3';
import { PaymentIntegrationModule } from './payment-integration/payment-integration.module';
import { CommonModule } from '@angular/common';
import { PolicyDetailsComponent } from './payment-integration/policy-details/policy-details.component';
import { HouseHolderInsuranceInformationComponent } from './Web/household-insurance/house-holder-insurance-information/house-holder-insurance-information.component';
import { NavBarTabViewComponent } from './Templates/nav-bar-tab-view/nav-bar-tab-view.component';
import { CampignPlanBComponent } from './campign-plan-b/campign-plan-b.component';
import { NaicomUniqueIdComponent } from './Web/naicom-unique-id/naicom-unique-id.component';
import { AddmanagementComponent } from './Web/addmanagement/addmanagement.component';
import { AccessmanagementComponent } from './Web/accessmanagement/accessmanagement.component';
import { EditmanagementComponent } from './Web/editmanagement/editmanagement.component';
import { AccessboardComponent } from './Web/accessboard/accessboard.component';
import { AddboardComponent } from './Web/addboard/addboard.component';
import { EditboardComponent } from './Web/editboard/editboard.component';
import { OptionComponent } from './Web/option/option.component';
import { LoginprivateComponent } from './Web/loginprivate/loginprivate.component';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment.prod';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { AccessSlideComponent } from './Web/access-slide/access-slide.component';
import { AddSlideComponent } from './Web/add-slide/add-slide.component';
import { CampaignComponent } from './Web/campaign/campaign.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EditSliderComponent } from './Web/edit-slider/edit-slider.component';
@NgModule({
  declarations: [
    AppComponent,
    AboutusComponent,
    SidebarComponent,
    NavBarComponent,
    QuoteComponent,
    TabComponent,
    TabsComponent,
    AccidentInsuranceComponent,
    AgricInsuranceComponent,
    AllRiskProposalFormComponent,
    AlwaysReadYourPolicyDocumentsComponent,
    AnnuityComponent,
    AnticipatedComponent,
    AutoInsuranceComponent,
    AwardsComponent,
    BasicInsuranceTerminologiesAndTheirMeaningComponent,
    BeneficiaryFormComponent,
    BenefitsOfATermAssurancePlanComponent,
    BlogComponent,
    BoardMembersComponent,
    BrainExercisesThatBoostMemoryComponent,
    BreastCancerHealthInsuranceComponent,
    BurglaryInsuranceComponent,
    BurglaryInsuranceProposalFormComponent,
    BusinessInsuranceComponent,
    BuyMotorComponent,
    CareerComponent,
    CareerAdvancementTipsComponent,
    CertificationComponent,
    ClaimProcessMadeEasierComponent,
    ClaimsComponent,
    CommonMistakeInInsuranceComponent,
    ComplaintFormComponent,
    ContactFormComponent,
    ContactUsComponent,
    ContractorAllRiskProposalFormComponent,
    CreditLifeComponent,
    CustomerFormComponent,
    CustomerServiceWeekComponent,
    CybersecurityBestPracticesComponent,
    DebitMandateFormComponent,
    DebunkingLifeInsuranceMythsComponent,
    DeferredAnnuityComponent,
    DeferredAnnuityPlanComponent,
    DistractionDrivingComponent,
    DownloadableComponent,
    DstvThanksComponent,
    EducationPlanComponent,
    EndowmentComponent,
    EnergyInsuranceComponent,
    EppComponent,
    EventComponent,
    FamilyProtectionComponent,
    FaqComponent,
    FidelityComponent,
    FinancialReportComponent,
    FireComponent,
    FireInsuranceComponent,
    FireSpecialProposalFormComponent,
    FooterComponent,
    FosteringHealthRelationshipsComponent,
    GeneralBusinessProposalFormComponent,
    GeneralInsuranceComponent,
    GoodsProposalComponent,
    GroupLifeComponent,
    GroupLifeProposalFormComponent,
    GroupPersonalAccidentProposalComponent,
    HealthComponent,
    HolidayFireSafteyTipsComponent,
    HomeInsuranceComponent,
    HouseHolderInsuranceComponent,
    HouseholdInsuranceComponent,
    HowToMaintainYourCarComponent,
    ImmediateAnnuityComponent,
    ImportanceOfDataPrivacyInYourBusinessComponent,
    IndexBodyComponent,
    IndividualProposalFormComponent,
    InnovationsInInsuranceComponent,
    InsuranceAgricultureComponent,
    InsuranceEducationSectorComponent,
    InsurancePolicyHelpYouSaveMoneyComponent,
    InsuranceValueSocietyComponent,
    InvestmentPlanComponent,
    KeymanComponent,
    LiabilityInsuranceComponent,
    LifeInsuranceComponent,
    LifeProposalFormComponent,
    LifeQuoteComponent,
    LoadingPageComponent,
    ManagementTeamComponent,
    MarineComponent,
    MarineCargoProposalFormComponent,
    MoneyProposalFormComponent,
    MortgageComponent,
    MotorInsuranceComponent,
    MotorInsuranceProposalFormComponent,
    MotorResponseComponent,
    NollyWoodConnectComponent,
    NsiaAppointsDirectorsComponent,
    NsiaDeferredAnnuityPlanComponent,
    NsiaEducationProtectionPlanComponent,
    NsiaFamilyProtectionPlanComponent,
    NsiaInsuranceGoPaperlessComponent,
    NsiaInsuranceHoldsAgmComponent,
    NsiaInsurancePlanComponent,
    NsiaPlanLifeInsuranceSavingsComponent,
    PersonalAccidentComponent,
    PersonalAccidentProposalFormComponent,
    PrivacyComponent,
    ProfessionalIndemityProposalFormComponent,
    PromoComponent,
    PropertyInsuranceComponent,
    PublicLiabilityInsuranceProposalComponent,
    QuickGuideToHomeownersComponent,
    ReasonsYourBusinessNeedsKeymanAssuranceComponent,
    ReportClaimComponent,
    RetailProductComponent,
    RiskManagementComponent,
    SatisfyingThe21centuryCustomerComponent,
    SavingsPlanComponent,
    ScrollTopComponent,
    SevenInterestingFacetsAboutInsuranceComponent,
    SimpleWaysToBeMoreProductiveComponent,
    TenTipsForWorkingWithYourInsuranceCompanyComponent,
    TermAssuranceComponent,
    TheBeirutExplosionImportanceOfInsuranceComponent,
    ThingsToConsiderBeforeTakingThatTripComponent,
    ThirdPartyMotorVsComprehensiveMotorComponent,
    TipsOnImprovingTeamDynamicsComponent,
    TipsToImproveQualityOfLifeComponent,
    TipsToPreventHomeBurglaryComponent,
    TransportationComponent,
    TravelInsuranceComponent,
    TravelSafetyTipsComponent,
    UnderstandingTheExcessOnYourVehicleInsurancePolicyComponent,
    UnderstandingThirdPartyInsuranceComponent,
    ValidateAutoInsuranceCertificateComponent,
    WaysToRetainMoreOfEveryBookComponent,
    WhatHappensIfAGuestCausesDamageToMyHomeComponent,
    WhatToDoIfYouGetInACarAccidentComponent,
    WhistleBlowerComponent,
    WholeLifeComponent,
    WhyIsItImportantToInsureYourBusinessComponent,
    WonderComponent,
    WorkOutFitnessTipsComponent,
    WorkingRemotelyComponent,
    WorkmenInsuranceProposalFormComponent,
    YouDonHitMyCarComponent,
    YoungHealthInsuranceComponent,
    AlphabetOnlyDirective,
    OnlynumberDirective,
    AnxietyComponent,
    AutoPolicyNonrenewalVsCancellationComponent,
    InsuranceTipsForSaveSmartComponent,
    // BlogsDetailsComponent,
    // BlogsComponent,
    ThreeMoneyRulesComponent,
    DoItTodayNotTomorrowComponent,
    InsuranceWhatToExpectComponent,
    EffectiveSocialMediaStrategiesForBrandsComponent,
    NsiaAdsCampaignComponent,
    CampaignAdsComponent,
    PolicyDetailsComponent,
    HouseHolderInsuranceInformationComponent,
    NavBarTabViewComponent,
    CampignPlanBComponent,
    NaicomUniqueIdComponent,
    AddmanagementComponent,
    AccessmanagementComponent,
    EditmanagementComponent,
    AccessboardComponent,
    AddboardComponent,
    EditboardComponent,
    OptionComponent,
    LoginprivateComponent,
    AccessSlideComponent,
    AddSlideComponent,
    CampaignComponent,
    EditSliderComponent
  ],
  imports: [
    FontAwesomeModule,
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    BrowserAnimationsModule,
    MatCarouselModule.forRoot(),
    ToastrModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    ManageModule,
    FormsModule,
    ReactiveFormsModule,
    NgProgressModule,
    NgbModule,
    NgxPicaModule,
    CustomBlogsModule,
    AngularMaterialModule,
    MatPaginatorModule,
    FlutterwaveModule,
    PaymentIntegrationModule,
    // PaymentFlutterwaveModule
  ],
  // exports:[TabComponent, TabsComponent],
  providers: [
    MainserviceService,
    FormserviceService,
    FileDownloadServiceService,
    MakeModelService,
    MainserviceService,
    Utilities
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
