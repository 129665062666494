import { Component, OnInit } from '@angular/core';
import { CustomerClass } from 'src/app/Classes/customer-class';
import { FormserviceService } from 'src/app/Services/formservice.service';
import swal from 'sweetalert2';


@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.css']
})
export class CustomerFormComponent implements OnInit {
  model;

  userData = new CustomerClass("", "", "","","");
  constructor(private _formService: FormserviceService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    document.getElementById("phoneno").setAttribute("max", "11");
  }
  OnContactSubmit() {
    this._formService.startLoading();
   this._formService.sendCustomerForm(this.userData)
   .subscribe(
     (data) => {
       console.log(data);
       if (data.statusCode == "00") {
         swal.fire("Success", "Your information has been updated.", "success");
       } else {
         swal.fire("Error", data.statusMessage, "info");
       }
       this._formService.stopLoading();
       this.userData = new CustomerClass("", "", "", "","");
     },
     (error) => {
       console.log(error);
       swal.fire("Fatal", "Internal server error occurred", "error");
       this._formService.stopLoading();
     }
   )
  }

}
