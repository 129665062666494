<div class="container g-pt-100 g-pb-70">
  <div class="row">
    <div class="col-lg-12 g-mb-30">
      <section class="g-bg-gray-light-v5 g-py-20">
        <form novalidate (ngSubmit)="submitForm(beneficiaryForm)" #beneficiaryForm="ngForm" novalidate>
          <div class="container g-py-100 pad-top-50" style="margin-top:-50px">
            <div class="u-shadow-v19 g-brd-around g-brd-gray-light-v4  rounded mx-auto g-pa-30 g-pa-50--md">

              <div class="mb-6">
                <h2 class="mb-4 main-heading">
                  FIDELITY GUARANTEE INSURANCE PROPOSAL FORM
                </h2>

                <section class="step-two" >
                  <div class="form-sub-title">
                    <h4>Personal Information</h4>
                  </div>
                  <div class="row">

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>Name of Proposer</label>
                        <input id="NameOfProposal" name="NameOfProposal" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.NameOfProposal" required>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Address of Proposer
                        </label>
                        <input id="AddressOfProposal" name="AddressOfProposal" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.AddressOfProposal" required >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Trade Or Business
                        </label>
                        <input id="TradeOrBusiness" name="TradeOrBusiness" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.TradeOrBusiness" required >
                      </div>
                    </div>


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          E- mail address
                        </label>
                        <input id="EmailAddress" name="EmailAddress" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.EmailAddress" required type="text" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" >

                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Mobile No
                        </label>
                        <input id="Telephone" name="Telephone" class="form-control form-control-md rounded-0" type="number"
                        placeholder="Required" [(ngModel)]="proposalModel.Telephone" required="required" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==11) return false; return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57));" >

                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                         Alternate Mobile No
                        </label>
                        <input id="AltTelephone" name="AltTelephone" class="form-control form-control-md rounded-0" type="number"
                         [(ngModel)]="proposalModel.AltTelephone" required="required" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==11) return false; return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57));" >

                      </div>
                    </div>


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                         <label>
                          Start Date  (Period of Insurance)
                         </label>
                         <input id="StartDate" name="StartDate" class="form-control form-control-md rounded-0" type="date"
                          placeholder="yyyy-mm-dd" [(ngModel)]="proposalModel.StartDate" >

                      </div>
                   </div>
                   <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                         <label>
                          End Date (Period of Insurance)
                         </label>
                         <input id="EndDate" name="EndDate" class="form-control form-control-md rounded-0" type="date"
                          placeholder="yyyy-mm-dd" [(ngModel)]="proposalModel.EndDate"  >
                      </div>
                   </div>
                  </div>
                </section>

                <section class="step-three">
                  <div class="">
                    <h4>General Questions</h4>
                  </div>
                  <div class="row">

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Type of Cover :
                        </label>
                        <select name="covertype" id="covertype" class="form-control form-control-md rounded-0"
                         [(ngModel)]="proposalModel.covertype">
                            <option >Select your option</option>
                            <option value="Named">Named</option>
                            <option value="Unnamed">Unnamed</option>
                            <option value="Position">Position</option>
                            <option value="Blanket">Blanket</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-6" >
                      <div class="form-group g-mb-20">
                        <label>
                          Categories of Employees:
                        </label>
                        <input id="CategoryOfEmployee" name="CategoryOfEmployee" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.CategoryOfEmployee" >
                      </div>
                    </div>


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Are all paid employees required to take an uninterrupted holiday of at least 2
                          weeks in each calendar year during which they perform no duties and are required
                         to stay away from the Organization’s premises

                        </label>
                        <select name="paidemployees" id="paidemployees" class="form-control form-control-md rounded-0"
                         [(ngModel)]="proposalModel.paidemployees">
                            <option >Select your option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                      </div>
                    </div>


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          How are cash and negotiable instruments handled, recorded and
                          Banked

                        </label>
                        <input id="negotiableinstruments" name="negotiableinstruments" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.negotiableinstruments" >

                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          How are the purchase and sale of goods and property recorded and accounted
                          For?

                       </label>

                       <input id="purchaseandsaleofgoods" name="purchaseandsaleofgoods" class="form-control form-control-md rounded-0" type="text"
                       [(ngModel)]="proposalModel.purchaseandsaleofgoods" >

                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Are employees’ duties so arranged that no one employee is permitted to control a
                          transaction from beginning to the end?

                        </label>
                        <select name="employeeduties" id="employeeduties" class="form-control form-control-md rounded-0"
                         [(ngModel)]="proposalModel.employeeduties">
                            <option >Select your option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                      </div>
                    </div>



                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Please describe your internal Audit Procedures?
                       </label>

                       <input id="internalauditprocedure" name="internalauditprocedure" class="form-control form-control-md rounded-0" type="text"
                       [(ngModel)]="proposalModel.internalauditprocedure" >

                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Please describe your External Audit Procedures?

                       </label>

                       <input id="externalauditprocedure" name="externalauditprocedure" class="form-control form-control-md rounded-0" type="text"
                       [(ngModel)]="proposalModel.externalauditprocedure" >

                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Please provide any other information that will assist in understanding the mode of
          operations and procedures of the organization

                        </label>
                        <input id="otherinformationprocedures" name="otherinformationprocedures" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.otherinformationprocedures" >

                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Is written reference being obtained for the preceding 3 years of employment in
       confirmation of the honesty of each employee (Yes/No)
                        </label>

                        <select name="writtenreference" id="writtenreference" class="form-control form-control-md rounded-0"
                        [(ngModel)]="proposalModel.writtenreference">
                           <option >Select your option</option>
                           <option value="Yes">Yes</option>
                           <option value="No">No</option>
                       </select>

                      </div>
                    </div>

                    <div class="col-lg-6" >
                      <div class="form-group g-mb-20">
                        <label>
                         If No (Please state what enquires are made as to the honesty of each employee)
                        </label>
                        <input id="writtenreference_No" name="writtenreference_No" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.writtenreference_No" >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          What independent system is there to check that all sums received by employees
                          are accounted for ?


                        </label>
                        <input id="independent_system" name="independent_system" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.independent_system" >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Has there been any loss through employee’s fraud and/or dishonesty?

                        </label>
                        <select name="loss_through_employee_fraud" id="loss_through_employee_fraud" class="form-control form-control-md rounded-0"
                        [(ngModel)]="proposalModel.loss_through_employee_fraud">
                           <option >Select your option</option>
                           <option value="Yes">Yes</option>
                           <option value="No">No</option>
                       </select>
                      </div>
                    </div>
                    <!-- *ngIf="proposalModel.loss_through_employee_fraud==Yes" -->
                    <div class="col-lg-6" >
                      <div class="form-group g-mb-20">
                        <label>
                          If Yes (State):

                          (a).the number of occurrence (s)
                        </label>
                        <input id="numberofoccurrence" name="numberofoccurrence" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.numberofoccurrence" >
                      </div>
                    </div>

                    <!-- *ngIf="proposalModel.loss_through_employee_fraud==Yes" -->

                    <div class="col-lg-6" >
                      <div class="form-group g-mb-20">
                        <label>
                         (b) the maximum amount of direct loss per occurrence:
                        </label>
                        <input id="maximuamount" name="maximuamount" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.maximuamount" >
                      </div>
                    </div>


                    <!-- *ngIf="proposalModel.loss_through_employee_fraud==Yes" -->
                    <div class="col-lg-6" >
                      <div class="form-group g-mb-20">
                        <label>
                          (c) total amount of direct losses so far
                        </label>
                        <input id="totalamountofdirectlosses" name="totalamountofdirectlosses" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.totalamountofdirectlosses" >
                      </div>
                    </div>

                  </div>
                </section>

                <section class="step-three">
                  <!-- <div class="">
                    <h6><strong>Ple</strong></h6>
                  </div> -->
                  <div class="row">


                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Limit anyone loss:   =N=
                        </label>
                        <input id="limitanyoneloss" name="limitanyoneloss" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.limitanyoneloss" >
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Limit per person:   =N=
                        </label>
                        <input id="limitperperson" name="limitperperson" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.limitperperson" >
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Aggregate amount Guaranteed for the period of Insurance: =N=

                        </label>
                        <input id="aggregateamount" name="aggregateamount" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.aggregateamount" >
                      </div>
                    </div>

                  </div>
                </section>


               <section class="step-seven" >

                 <div class="container">
                   <h4>Declaration</h4>
                   <p>I/We hereby declare that to the best of my/our knowledge and belief the information herein provided is correct and complete.</p>

                   <!-- <div class="form-group">
                    <input type="checkbox" [(ngModel)]="termAgreed" name="termAgreed" > By checking the box , I agree with the above terms
                  </div> -->

                      <p style="font-size:10px; text-align: justify;">In compliance with NDPR requirements, by completing and submitting this form, you have given consent to NSIA Insurance to receive your data, including your bio data, to enable NSIA Insurance maintain effective communication, send promotional updates, contact you for research purposes and use the data in line with the policy terms.
                      </p>
                      <p style="font-size:10px; text-align: justify;">The data collection may be via hard copies or online forms, validated via JSON WEB TOKEN (JWT). The data will not be accessed by any third party without your consent and any breach of this policy can be addressed legally within the year the breach was reported.</p>

                      <div class="col-lg-4">
                        <div class="form-group g-mb-0">
                          <label style="visibility: hidden;">Frequency</label>
                          <button  type="submit" onclick="return confirm('Are you sure you want to submit this information?')" class="g-brd-main btn-block g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" > Submit
                          </button>
                        </div>

                      </div>

                 </div>

               </section>

              </div>


            </div>
          </div>
        </form>
      </section>
    </div>

    <!-- End Sidebar -->
  </div>
</div>
