import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-insurance-policy-help-you-save-money',
  templateUrl: './insurance-policy-help-you-save-money.component.html',
  styleUrls: ['./insurance-policy-help-you-save-money.component.css']
})
export class InsurancePolicyHelpYouSaveMoneyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
