import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';
import { data } from 'jquery';
import * as $ from 'jquery';
import { NgForm } from '@angular/forms'
import { Router } from '@angular/router';
import {DDInstructions, NextOfKin } from 'src/app/Classes/proposal-form';
import { FormserviceService } from 'src/app/Services/formservice.service';
import { Utilities } from 'src/app/Helpers/utilities';

@Component({
  selector: 'app-individual-proposal-form',
  templateUrl: './individual-proposal-form.component.html',
  styleUrls: ['./individual-proposal-form.component.css']
})
export class IndividualProposalFormComponent implements OnInit {
  month:any;
  day:any;
  year:any;
  maxDate:any;

  public DateOfBirth: any;
  Duration:number;
  //public Age: number;
  dateTemp = new Date();
  step:number = 1;
  diseases: string[] = [];
  otherPolicy: string[] = [];
  ddEnabled:number = 0;
  countries:any;
  states:any;
  banks:any;
  termAgreed:boolean = false;
  showBeneficiaryTable: boolean = false
  ddModel = new DDInstructions("","","",null);
  beneficiaryModel = new Beneficiaries("", "", 0, null, this.dateTemp);
  nextOfKinModel = new NextOfKin("","","","");
  productType:number = 0;
  medicalModel =  new MedicalDetails("","","","","",this.dateTemp,null,"","","",null,null,null,this.dateTemp, [],null,"",null,"");
  proposalModel = new  NewProposalForm(null,null,"","",null,"",null,"","","","","","","",this.dateTemp,null,"",
  "","","","","","","",null,this.medicalModel,"",0,null,null,[],"",[],this.beneficiaryModel,this.nextOfKinModel,"","",this.ddModel, "", "", "", "","","", this.diseases, this.otherPolicy,0,'','','','','','');
  constructor(private _formservice: FormserviceService,private router: Router, private _utils:Utilities) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this._formservice.GetCountries()
      .subscribe(
        (data) => {
          this.countries = data
        },
        (error) => {
          console.log(error);
        }
      );
      this._formservice.GetStates()
        .subscribe(
          (data) => {
            this.states = data
          },
          (error) => {
            console.log(error);
          }
        );
        this._formservice.GetBanks()
        .subscribe(
          (data) => {
            this.banks = data
          },
          (error) => {
            console.log(error);
          }
        );


        var dtToday = new Date();

        this.month = dtToday.getMonth() + 1;
        this.day = dtToday.getDate();
        this.year = dtToday.getFullYear();
        if(this.month < 10)
        this.month = '0' + this.month.toString();
        if(this.day < 10)
        this.day = '0' + this.day.toString();

        this.proposalModel.ComencementDate = this.year + '-' + this.month + '-' + this.day;

        document.getElementById("ComencementDate").setAttribute("min", this.proposalModel.ComencementDate);


        let Surname = JSON.parse(localStorage.getItem("Surname"));
        let ProductType = JSON.parse(localStorage.getItem("ProductType"));
        let telephone = JSON.parse(localStorage.getItem("telephone"));
        let email = JSON.parse(localStorage.getItem("email"));
        let Sex = JSON.parse(localStorage.getItem("Sex"));
        let MaidenName = JSON.parse(localStorage.getItem("MaidenName"));
        let MaritalStatus = JSON.parse(localStorage.getItem("MaritalStatus"));
        let ResidentialAddress = JSON.parse(localStorage.getItem("ResidentialAddress"));
        let PostalAddress = JSON.parse(localStorage.getItem("PostalAddress"));
        let AverageAge = JSON.parse(localStorage.getItem("AverageAge"));
        let DateOfBirth = JSON.parse(localStorage.getItem("DateOfBirth"));
        let Age = JSON.parse(localStorage.getItem("Age"));
        let RICNumber = JSON.parse(localStorage.getItem("RICNumber"));
        let StateOfOrigin = JSON.parse(localStorage.getItem("StateOfOrigin"));
        let Nationality = JSON.parse(localStorage.getItem("Nationality"));
        let Occupation = JSON.parse(localStorage.getItem("Occupation"));
        let Position = JSON.parse(localStorage.getItem("Position"));
        let NatureOfWork = JSON.parse(localStorage.getItem("NatureOfWork"));
        let EmployersAddress = JSON.parse(localStorage.getItem("EmployersAddress"));
        let PaymentFrequency = JSON.parse(localStorage.getItem("PaymentFrequency"));
        let ComencementDate = JSON.parse(localStorage.getItem("ComencementDate"));
        let Duration = JSON.parse(localStorage.getItem("Duration"));
        let SumAssurred = JSON.parse(localStorage.getItem("SumAssurred"));
        let NumberOfMembers = JSON.parse(localStorage.getItem("NumberOfMembers"));
        let IneligibilityFactor = JSON.parse(localStorage.getItem("IneligibilityFactor"));
        let PolicyHolder = JSON.parse(localStorage.getItem("PolicyHolder"));
        let RelationshipToPolicyHolder = JSON.parse(localStorage.getItem("RelationshipToPolicyHolder"));
        let policy = JSON.parse(localStorage.getItem("policy"));
        let ModeOfPayment = JSON.parse(localStorage.getItem("ModeOfPayment"));
        let Tin = JSON.parse(localStorage.getItem("Tin"));
        let title = JSON.parse(localStorage.getItem("title"));
        let Premium = JSON.parse(localStorage.getItem("Premium"));
        let CountryOfResidence = JSON.parse(localStorage.getItem("CountryOfResidence"));
        let OtherNames = JSON.parse(localStorage.getItem("OtherNames"));

        this.proposalModel.Surname = Surname;
        this.proposalModel.ProductType = ProductType;
        this.proposalModel.OtherNames = OtherNames;
        this.proposalModel.EmailAddress = email;

        this.proposalModel.CountryOfResidence = CountryOfResidence;
        this.proposalModel.Premium = Premium;
        this.proposalModel.title = title;
        this.proposalModel.Tin = Tin;
        this.proposalModel.ModeOfPayment = ModeOfPayment;
        this.proposalModel.policy = policy;
        this.proposalModel.RelationshipToPolicyHolder = RelationshipToPolicyHolder;
        this.proposalModel.PolicyHolder = PolicyHolder;
        this.proposalModel.IneligibilityFactor = IneligibilityFactor;
        this.proposalModel.NumberOfMembers = NumberOfMembers;
        this.proposalModel.SumAssurred = SumAssurred;
        this.proposalModel.Duration = Duration;
        this.proposalModel.ComencementDate = ComencementDate;
        this.proposalModel.PaymentFrequency = PaymentFrequency;
        this.proposalModel.EmployersAddress = EmployersAddress;
        this.proposalModel.NatureOfWork = NatureOfWork;
        this.proposalModel.Position = Position;
        this.proposalModel.Occupation = Occupation;
        this.proposalModel.Nationality = Nationality;
        this.proposalModel.StateOfOrigin = StateOfOrigin;
        this.proposalModel.RICNumber = RICNumber;
        this.proposalModel.Age = Age;
        this.proposalModel.DateOfBirth = DateOfBirth;
        this.proposalModel.AverageAge = AverageAge;
        this.proposalModel.PostalAddress = PostalAddress;
        this.proposalModel.ResidentialAddress = ResidentialAddress;
        this.proposalModel.MaritalStatus = MaritalStatus;
        this.proposalModel.MaidenName = MaidenName;
        this.proposalModel.Sex = Sex;
        this.proposalModel.Telephone = telephone;


        let data = JSON.parse(localStorage.getItem("allEntries"));
    for(let i in data){
      this.beneficiaryModel.BenefitProportion = data[i].BenefitProportion;
      this.beneficiaryModel.Relationship = data[i].Relationship;
      this.beneficiaryModel.FullName = data[i].FullName;
      this.beneficiaryModel.Sex = data[i].Sex;
      this.beneficiaryModel.DateOfBirth = data[i].DateOfBirth;
      console.log(this.beneficiaryModel);

      this.proposalModel.BeneficiariesData.push(this.beneficiaryModel);
      this.beneficiaryModel = new Beneficiaries("", "", 0, null, this.dateTemp);

    }

    if (this.proposalModel.BeneficiariesData.length > 0) {
      this.showBeneficiaryTable = true;
    }else{
      this.showBeneficiaryTable = false;
    }

  }

  selected(files: FileList)
  {
    let theFile = files[0];
    this._utils.ResizeImage(theFile, 128, 128)
    .subscribe(
      (file : File) =>{
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event) => {
          this.proposalModel.src= reader.result.toString()
          this.proposalModel.filenameId= file.name
          this.proposalModel.filetypeId= file.type
          this.proposalModel.filestringId =this.proposalModel.src.substr(this.proposalModel.src.indexOf(',')+ 1)
        }
      }
    )


  }

  dob: Date;
  result: string;
  com :number;
  newDate:any;
  policy: string;

  propp: number = 0;
   //event handler for the select element's change event
   focusOutFunction () {
    this.dob = this.proposalModel.DateOfBirth;

    var today = new Date();
    var birthDate = new Date(this.dob);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    this.proposalModel.Age = age;
    if(age < 18){
      alert("Under age, kindly put age 18 & above");
    }
  }


  focusOutFunctionPolicy () {
    this.policy = this.proposalModel.policy;
    if(this.policy == 'Anticipated Endowment Assurance'){
      alert("Anticipated endowment assurance plan allows for 6yrs, 9yrs, 12yrs & 15yrs ");
      this.proposalModel.Premium = 10800;
    }

    if(this.policy == 'Education Endowment Assurance Plan'){
      alert("Tenor for Education Endowment assurance plan should be capped at minimum of 5 years ");
      this.proposalModel.Premium = 10800;
    }

    if(this.policy == 'Endowment Assurance Plan'){
      alert( "Tenor for Endowment Assurance Plan should be capped at minimum of 5 years");
      this.proposalModel.Premium = 10800;
    }

    if(this.policy == 'NSIA Savings Plan'){
      alert( "Tenor for NSIA savings plans is capped at just 3 yrs ");
      this.proposalModel.Premium = 5000;
    }

    if(this.policy == 'NSIA Investment Linked Plan'){
      alert( "Tenor for NSIA Investment Linked Plan is capped at just 1 yr ");
      this.proposalModel.Premium = 10000;
    }

  }

  focusOutFunctionPolicyTenor(){
    this.Duration = this.proposalModel.Duration;
    if(this.proposalModel.policy == "Anticipated Endowment Assurance"){

      if(this.Duration == 6  || this.Duration ==9 || this.Duration ==12 || this.Duration==15){

      }else{
        alert( "Anticipated endowment assurance plan allows for 6yrs, 9yrs, 12yrs & 15yrs ");
      }
    }

    //Education Endowment Assurance Plan
    if(this.proposalModel.policy == "Education Endowment Assurance Plan"){

      if(this.Duration >= 5 ){

      }else{
        alert( "Tenor for Education Endowment assurance plan should be capped at minimum of 5 years ");
      }
    }

    //Endowment Assurance Plan
    if(this.proposalModel.policy == "Endowment Assurance Plan"){

      if(this.Duration >= 5){

      }else{
        alert( "Tenor for Endowment assurance plan should be capped at minimum of 5 years ");
      }
    }
    //NSIA saving plan

    if(this.proposalModel.policy == "NSIA Savings Plan"){

      if(this.Duration >=3){

      }else{
        alert( "Tenor for NSIA savings plans is capped at just 3 yrs");
      }
    }

    //NSIA Investment Linked Plan
    if(this.proposalModel.policy == "NSIA Investment Linked Plan"){

      if(this.Duration == 1){

      }else{
        alert( "Tenor for NSIA Investment Linked Plan is capped at just 1 yr ");
      }
    }
  }

  addPolicy(e){
    let value = e.srcElement.value;
    // let type = true;
    if (e.srcElement.checked) {
      this.otherPolicy.push(value);
    }else{
      const index: number = this.otherPolicy.indexOf(value);
      if (index !== -1) {
        this.otherPolicy.splice(index, 1);
      }
    }
  }
  addDisease(e){
    let value = e.srcElement.value;
    // let type = true;
    if (e.srcElement.checked) {
      this.diseases.push(value);
    }else{
      const index: number = this.diseases.indexOf(value);
      if (index !== -1) {
        this.diseases.splice(index, 1);
      }
    }

  }

  submitForm(myForm: NgForm){

    console.log(this.proposalModel);

    var email = this.proposalModel.EmailAddress;
    var filestringId = this.proposalModel.filestringId;
    var regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if(!regex.test(email)) {
      alert("Your email is not correct");
      this._formservice.stopLoading();
      return;
    }
    var telLength = $('#Telephone').val().toString().length;
    var secTel =$("#AlternativeTel").val().toString().length;

    this.propp = Number(this.propp) + Number(this.beneficiaryModel.BenefitProportion);
    console.log(this.propp);
    if(this.propp > 100){
      alert( "Beneficiary proportion is more than 100");
      return;
    }
    if(this.propp < 100){
      alert( "Beneficiary proportion is less than 100");
      return;
    }
    if(this.proposalModel.BeneficiariesData.length == 0){
      alert( "Beneficiary form is required");
      return;
    }

    if(secTel < 10){
      $("#AlternativeTel").val("Not Applicable");
    }
    if(telLength < 10){
      alert( "Your telephone number is not correct");
      this._formservice.stopLoading();
      return;
    }
    if (this.proposalModel.Age < 18) {
      alert( "Under age, kindly put age 18 & above");
      this._formservice.stopLoading();
      return;
    }

    if (this.proposalModel.PremiumType == 0) {
      alert( "Premium Type is required");
      this._formservice.stopLoading();
      return;
    }
    if (this.proposalModel.Premium == 0) {
      alert( "Premium  is required");
      this._formservice.stopLoading();
      return;
    }

    if (this.proposalModel.ResidentialAddress == "") {
      alert( "Residential Address is required");
      this._formservice.stopLoading();
      return;
    }

    if (this.proposalModel.policy == "") {
      alert( "Policy is required");
      this._formservice.stopLoading();
      return;
    }

    if (this.proposalModel.Sex == "") {
      alert( "Sex is required");
      this._formservice.stopLoading();
      return;
    }

    if (this.proposalModel.MaritalStatus == "") {
      alert( "Marital Status is required");
      this._formservice.stopLoading();
      return;
    }

    if (this.proposalModel.StateOfOrigin == "") {
      alert( "State Of Origin is required");
      this._formservice.stopLoading();
      return;
    }

    if (this.medicalModel.Height == "") {
      alert( "Height is required");
      this._formservice.stopLoading();
      return;
    }
    if (this.medicalModel.Weight == "") {
      alert( "Weight is required");
      this._formservice.stopLoading();
      return;
    }
    if (this.proposalModel.Occupation == "") {
      alert( "Occupation is required");
      this._formservice.stopLoading();
      return;
    }

    if (this.proposalModel.EmployerName == "") {
      alert( "Name Of Business is required");
      this._formservice.stopLoading();
      return;
    }

    if (this.proposalModel.Position == "") {
      alert( "Position is required");
      this._formservice.stopLoading();
      return;
    }

    if (this.proposalModel.EmployersAddress == "") {
      alert( "Business Address is required");
      this._formservice.stopLoading();
      return;
    }

    if (this.proposalModel.PaymentFrequency == 0) {
      alert( "Frequency Payment is required");
      this._formservice.stopLoading();
      return;
    }

    if (this.medicalModel.Name == "") {
      alert( "Hospital Name is required");
      this._formservice.stopLoading();
      return;
    }

    if (this.proposalModel.Duration == 0) {
      alert( " Policy Tenor is required");
      this._formservice.stopLoading();
      return;
    }

    if (this.medicalModel.EmailAddress == "") {
      this.medicalModel.EmailAddress = "Not Applicable";
    }

    if (this.medicalModel.PhoneNumber == "") {
      this.medicalModel.PhoneNumber = "Not Applicable";
    }

    if(this.medicalModel.AttendanceReason==null){
      alert( "Reason is required");
      this._formservice.stopLoading();
      return;
    }

    if(this.medicalModel.AlchoholConsumption==null){
      alert( "Alchohol Consumption is required");
      this._formservice.stopLoading();
      return;
    }

    if(this.medicalModel.ISGoodHealth==null){
      alert( "Good Health is required");
      this._formservice.stopLoading();
      return;
    }

    if(this.medicalModel.TerminalDesease==null){
      alert( "Terminal Desease is required");
      this._formservice.stopLoading();
      return;
    }


    if (this.medicalModel.Address == "") {
      this.medicalModel.Address = "Not Applicable";
    }
    if(this.medicalModel.IsPregnant ==null){
      alert( "Is Pregnant field is required");
      this._formservice.stopLoading();
      return;
    }

    if (this.medicalModel.IsPregnant == 1) {
      this.medicalModel.Is_Pregnant = "Yes";
    }
    if (this.medicalModel.IsPregnant == 2) {
      this.medicalModel.Is_Pregnant = "No";
    }

    if (this.medicalModel.BloodPressure == null) {
      this.medicalModel.BloodPressure = "Not Applicable";
    }
    if(this.proposalModel.AlternativeTel = ""){
     this.proposalModel.AlternativeTel = "Not Applicable";
     $("#AlternativeTel").val("Not Applicable");
    }
    //SumAssurred
    if (this.proposalModel.SumAssurred == 0) {
      alert( "Sum Assurred is required");
      this._formservice.stopLoading();
      return;
    }

    //ModeOfPayment
    if (this.proposalModel.ModeOfPayment == "") {
      alert( "Mode Of Payment is required");
      this._formservice.stopLoading();
      return;
    }

    if (this.proposalModel.BeneficiariesData == null) {
      alert( "Beneficiaries Data is required");
      this._formservice.stopLoading();
      return;

    }

    if (!this.termAgreed) {
      alert( "You need to agree to the terms and agreement");
      this._formservice.stopLoading();
      return;
    }
    else if(filestringId==''){
      alert( "Upload your signature");
      this._formservice.stopLoading();
    }

    else
    {

    this._formservice.startLoading();
    this._formservice.sendProposalFormIndividualForm(this.proposalModel)
      .subscribe(
        (data) => {
          if (data.statusCode == "00") {
            alert("Your form is submitted, Also you will get a copy of the email.");
            localStorage.clear();
            localStorage.removeItem("Surname");
            localStorage.removeItem("OtherNames");
            localStorage.removeItem("ProductType");
            localStorage.removeItem("telephone");
            localStorage.removeItem("email");
            localStorage.removeItem("Sex");
            localStorage.removeItem("MaidenName");
            localStorage.removeItem("MaritalStatus");
            localStorage.removeItem("ResidentialAddress");
            localStorage.removeItem("PostalAddress");
            localStorage.removeItem("AverageAge");
            localStorage.removeItem("DateOfBirth");
            localStorage.removeItem("Age");
            localStorage.removeItem("RICNumber");
            localStorage.removeItem("StateOfOrigin");
            localStorage.removeItem("Nationality");
            localStorage.removeItem("Occupation");
            localStorage.removeItem("Position");
            localStorage.removeItem("NatureOfWork");
            localStorage.removeItem("EmployersAddress");
            localStorage.removeItem("PaymentFrequency");
            localStorage.removeItem("ComencementDate");
            localStorage.removeItem("Duration");
            localStorage.removeItem("SumAssurred");
            localStorage.removeItem("NumberOfMembers");
            localStorage.removeItem("IneligibilityFactor");
            localStorage.removeItem("PolicyHolder");
            localStorage.removeItem("RelationshipToPolicyHolder");
            localStorage.removeItem("policy");
            localStorage.removeItem("ModeOfPayment");
            localStorage.removeItem("Tin");
            localStorage.removeItem("title");
            localStorage.removeItem("Premium");
            localStorage.removeItem("CountryOfResidence");
            localStorage.removeItem("allEntries");
            localStorage.removeItem("detail");
            //allEntries
            if(this.proposalModel.ModeOfPayment == "Direct Debit Mandate"){
            this.router.navigateByUrl('/debit-mandate-form');
            }
            myForm.resetForm();


          } else {
            alert( data.statusMessage);
          }
          this._formservice.stopLoading();
        },
        (error) => {
          console.log(error);
          alert("internal server error occurred");
          this._formservice.stopLoading();
        }
      )

    }
}
  testThis(){
    console.log(this.termAgreed);

  }

  Add(){


    if (this.beneficiaryModel.BenefitProportion == 0) {
      alert( "Beneficiary proportion is required");
      return;
    }
    if (this.beneficiaryModel.Relationship == "") {
      alert( "Beneficiary relationship is required");
      return;
    }
    if (this.beneficiaryModel.FullName == "") {
      alert( "Beneficiary full name is required");
      return;
    }
    if (this.beneficiaryModel.Sex == "") {
      alert( "Beneficiary gender is required");
      return;
    }
    if (!this.beneficiaryModel.DateOfBirth) {
      alert( "Beneficiary Date of birth is required");
      return;
    }
    this.propp = Number(this.propp) + Number(this.beneficiaryModel.BenefitProportion);
   // alert(this.propp);
    if(this.propp > 100){
      alert( "Beneficiary proportion is more than 100");
      this.propp = Number(this.propp) - Number(this.beneficiaryModel.BenefitProportion);
     return;
    }

    console.log(this.propp);

    if(this.beneficiaryModel.Sex == "1"){
      this.beneficiaryModel.Sex = "Male";
    }
    if(this.beneficiaryModel.Sex == "2"){
      this.beneficiaryModel.Sex = "Female";
    }

    this.proposalModel.BeneficiariesData.push(this.beneficiaryModel);

    var existingEntries = JSON.parse(localStorage.getItem("allEntries"));
     if (existingEntries == null)
         existingEntries = [];

         localStorage.setItem("detail", JSON.stringify(this.beneficiaryModel));
         existingEntries.push(this.beneficiaryModel);
         localStorage.setItem("allEntries", JSON.stringify(existingEntries));
         //location.reload();
    this.beneficiaryModel = new Beneficiaries("", "", 0, null, this.dateTemp);
    if (this.proposalModel.BeneficiariesData.length > 0) {
      this.showBeneficiaryTable = true;
    }
    else{

    localStorage.setItem("Surname", JSON.stringify(this.proposalModel.Surname));
    localStorage.setItem("OtherNames", JSON.stringify(this.proposalModel.OtherNames));
    localStorage.setItem("ProductType", JSON.stringify(this.proposalModel.ProductType));
    localStorage.setItem("telephone", JSON.stringify(this.proposalModel.Telephone));
    localStorage.setItem("email", JSON.stringify(this.proposalModel.EmailAddress));
    localStorage.setItem("Sex", JSON.stringify(this.proposalModel.Sex));
    localStorage.setItem("MaidenName", JSON.stringify(this.proposalModel.MaidenName));
    localStorage.setItem("MaritalStatus", JSON.stringify(this.proposalModel.MaritalStatus));
    localStorage.setItem("ResidentialAddress", JSON.stringify(this.proposalModel.ResidentialAddress));
    localStorage.setItem("PostalAddress", JSON.stringify(this.proposalModel.PostalAddress));
    localStorage.setItem("AverageAge", JSON.stringify(this.proposalModel.AverageAge));
    localStorage.setItem("DateOfBirth", JSON.stringify(this.proposalModel.DateOfBirth));
    localStorage.setItem("Age", JSON.stringify(this.proposalModel.Age));
    localStorage.setItem("RICNumber", JSON.stringify(this.proposalModel.RICNumber));
    localStorage.setItem("StateOfOrigin", JSON.stringify(this.proposalModel.StateOfOrigin));
    localStorage.setItem("Nationality", JSON.stringify(this.proposalModel.Nationality));
    localStorage.setItem("Occupation", JSON.stringify(this.proposalModel.Occupation));
    localStorage.setItem("Position", JSON.stringify(this.proposalModel.Position));
    localStorage.setItem("NatureOfWork", JSON.stringify(this.proposalModel.NatureOfWork));
    localStorage.setItem("EmployersAddress", JSON.stringify(this.proposalModel.EmployersAddress));
    localStorage.setItem("PaymentFrequency", JSON.stringify(this.proposalModel.PaymentFrequency));
    localStorage.setItem("ComencementDate", JSON.stringify(this.proposalModel.ComencementDate));
    localStorage.setItem("Duration", JSON.stringify(this.proposalModel.Duration));
    localStorage.setItem("SumAssurred", JSON.stringify(this.proposalModel.SumAssurred));
    localStorage.setItem("NumberOfMembers", JSON.stringify(this.proposalModel.NumberOfMembers));
    localStorage.setItem("IneligibilityFactor", JSON.stringify(this.proposalModel.IneligibilityFactor));
    localStorage.setItem("PolicyHolder", JSON.stringify(this.proposalModel.PolicyHolder));
    localStorage.setItem("RelationshipToPolicyHolder", JSON.stringify(this.proposalModel.RelationshipToPolicyHolder));
    localStorage.setItem("policy", JSON.stringify(this.proposalModel.policy));
    localStorage.setItem("ModeOfPayment", JSON.stringify(this.proposalModel.ModeOfPayment));
    localStorage.setItem("Tin", JSON.stringify(this.proposalModel.Tin));
    localStorage.setItem("title", JSON.stringify(this.proposalModel.title));
    localStorage.setItem("Premium", JSON.stringify(this.proposalModel.Premium));
    localStorage.setItem("CountryOfResidence", JSON.stringify(this.proposalModel.CountryOfResidence));



    }
  }
  editBen(i){
    this.beneficiaryModel.BenefitProportion = this.proposalModel.BeneficiariesData[i].BenefitProportion;
    this.beneficiaryModel.DateOfBirth = this.proposalModel.BeneficiariesData[i].DateOfBirth;
    this.beneficiaryModel.FullName = this.proposalModel.BeneficiariesData[i].FullName;
    this.beneficiaryModel.Relationship = this.proposalModel.BeneficiariesData[i].Relationship;
    this.beneficiaryModel.Sex = this.proposalModel.BeneficiariesData[i].Sex;


    this.propp = Number(this.propp) - Number(this.proposalModel.BeneficiariesData[i].BenefitProportion);
    console.log(this.propp);
    this.proposalModel.BeneficiariesData.splice(i, 1);
    if (this.proposalModel.BeneficiariesData.length > 0) {

      this.showBeneficiaryTable = true;
    }else{
      this.showBeneficiaryTable = false;
    }
    // console.log(i);


  }
  deleteBen(i){

    this.beneficiaryModel.BenefitProportion = this.proposalModel.BeneficiariesData[i].BenefitProportion;
    this.beneficiaryModel.DateOfBirth = this.proposalModel.BeneficiariesData[i].DateOfBirth;
    this.beneficiaryModel.FullName = this.proposalModel.BeneficiariesData[i].FullName;
    this.beneficiaryModel.Relationship = this.proposalModel.BeneficiariesData[i].Relationship;
    this.beneficiaryModel.Sex = this.proposalModel.BeneficiariesData[i].Sex;


    this.propp = Number(this.propp) - Number(this.beneficiaryModel.BenefitProportion);
    console.log(this.propp);
    this.proposalModel.BeneficiariesData.splice(i, 1);
    if (this.proposalModel.BeneficiariesData.length > 0) {

      this.showBeneficiaryTable = true;
    }else{
      this.showBeneficiaryTable = false;
    }
    // console.log(i);


  }

}
export class NewProposalForm {
  constructor(
    public ProductType: number,
    public PremiumType:number,
    public Surname:string,
    public OtherNames:string,
    public Sex:string,
    public MaidenName:string,
    public MaritalStatus:string,
    public ResidentialAddress:string,
    public PostalAddress:string,
    public EmailAddress:string,
    public Telephone:string,
    public OfficeTelephone:string,
    public AlternativeTel:string,
    public AverageAge:string,
    public DateOfBirth:Date,
    public Age:number,
    public RICNumber:string,
    public StateOfOrigin:string,
    public Nationality:string,
    public Occupation:string,
    public EmployerName:string,
    public Position:string,
    public NatureOfWork:string,
    public EmployersAddress:string,
    public PaymentFrequency:number,
    public MedicalData:MedicalDetails,
    public ComencementDate: string,
    public Duration:number,
    public SumAssurred:number,
    public NumberOfMembers:number,
    public OtherPolicy: string[],
    public IneligibilityFactor:string,
    public BeneficiariesData:Beneficiaries[],
    public BeneficiariesTemp:Beneficiaries,
    public NextOfKinData:NextOfKin,
    public PolicyHolder:string,
    public RelationshipToPolicyHolder: string,
    public DebitMandateInstruction:DDInstructions,
    public Agent:string,
    public policy:string,
    public ModeOfPayment: string,
    public Tin :string,
    public title:string,
    public CountryOfResidence:string,
    public diseases:string[],
    public otherPolicy:string[],
    public Premium:number,
    public filenameId:string,
    public filetypeId:string,
    public base64textStringId:string,
    public processedImagesId: any = [],
    public filestringId: string,
    public src:string,

  ) {

  }

}
export class MedicalDetails {
  constructor(
    public Name:string,
    public Address:string,
    public PhoneNumber:string,
    public EmailAddress:string,
    public AttendanceLifeSpan:string,
    public DateLastAttended: Date,
    public AttendanceReason: string,
    public Height: string,
    public Weight: string,
    public BloodPressure: string,
    public AlchoholConsumption: string,
    public IsPregnant: number,
    public Is_Pregnant: string,
    public Edd: Date,
    public RecurringDesease: string[],
    public ISGoodHealth: string,
    public HealthReason:string,
    public TerminalDesease:string,
    public DiseaseDetails:string,


  ) {

  }
}
export class Beneficiaries {
  constructor(
    public FullName:string,
    public Relationship:string,
    public BenefitProportion:number,
    public Sex:string,
    public DateOfBirth:Date,

  ) {

  }
}
