export class MoneyProposalForm {
  constructor(
    public NameOfProposal: string,
    public AddressOfProposal:string,
    public TradeOrBusiness:string,
    public EmailAddress:string,
    public Telephone:string,
    public SecondTelephone:string,
    public contact_person:string,
    public StartDate:Date,
    public EndDate: Date,
    public LossOfMoney:string,
    public InsuranceNature:string,
    public EstimateAmountOfMoney:string,
    public MaximumAmountOfMoney:string,
    public MethodOfTransit:string,
    public DistanceBetweenPremises:string,
    public NumberOfEmployees:string,
    public delegatedEmployees:string,
    public AgainstLostOfMoney:string,
    public TypeOfSafe:string,
    public WhoKeepSafeKey:string,
    public DamageToSafe:string,
    public MoneyInPersonalCustody:string,
    public OtherMoney:string,
    public AdditionalInformation:string



  ) {

  }

}
