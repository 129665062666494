<div class="g-min-height-300 g-flex-centered g-bg-img-hero g-bg-pos-top-center g-bg-size-cover g-pt-80"
  style="background-image: url(assets/img/banners/Inset_Life.jpg);">
  <div class="container g-pos-rel g-z-index-1">
    <h2 class="h1 text-uppercase g-color-primary g-font-size-40--lg mb-0">Retail Products</h2>
    <span class="d-block  g-color-white g-font-weight-1000 g-font-size-20 mb-4">Experience peace of mind.</span>
  </div>
</div>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item g-color-primary">
        <span>Retail Products</span>
      </li>

      <li class="list-inline-item ml-auto">
        <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
          routerLink="/retail-products"></a>
      </li>
    </ul>
  </div>
</section>

<section class="h-e-p-50" >
   <div class="container  g-pt-100 g-pb-70">
    <div class="text-center g-mb-70">
      <h2 class="g-font-weight-400">
         <p>Our Non-Life Products</p>
      </h2>
      <p class="g-font-size-16">Our products are the best and we are the preferred insurance providers.</p>
   </div>
      <div class="row no-gutters">
         <div class="col-lg-3 cta-item g-px-40 g-mb-50 g-mb-0--lg">
            <!-- Icon Blocks -->
            <div class="text-center">
               <span
                  class="d-inline-block u-icon-v3 u-icon-size--xl u-icon-effect-v3--hover g-bg-primary g-color-white rounded-circle g-mb-30">
               <i class="fa fa-car"></i>
               </span>
               <h3 class="h5 g-color-gray-dark-v2 g-font-weight-600 text-uppercase mb-3">Motor Insurance</h3>
               <p class="mb-0">Buy motor insurance
                  at ease and get your certificate.
               </p>
               <br />
               <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                  routerLink="/buymotor">
               Buy now
               </a>
            </div>
            <!-- End Icon Blocks -->
         </div>
         <div class="col-lg-3 cta-item g-brd-left--lg g-brd-gray-light-v4 g-px-40 g-mb-50 g-mb-0--lg">
            <!-- Icon Blocks -->
            <div class="text-center">
               <span
                  class="d-inline-block u-icon-v3 u-icon-size--xl u-icon-effect-v3--hover g-bg-primary g-color-white rounded-circle g-mb-30">
               <i class="fa fa-ship"></i>
               </span>
               <h3 class="h5 g-color-gray-dark-v2 g-font-weight-600 text-uppercase mb-3">Marine Cargo Insurance             </h3>
               <p class="mb-0">Buy marine cargo insurance
                at ease
               </p>
               <br />
               <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                  routerLink="/buymotor">
               Buy now
               </a>
            </div>
            <!-- End Icon Blocks -->
         </div>

         <div class="col-lg-3 cta-item g-brd-left--lg g-brd-gray-light-v4 g-px-40 g-mb-50 g-mb-0--lg">
          <!-- Icon Blocks -->
          <div class="text-center">
             <span
                class="d-inline-block u-icon-v3 u-icon-size--xl u-icon-effect-v3--hover g-bg-primary g-color-white rounded-circle g-mb-30">
             <i class="fa fa-lock"></i>
             </span>
             <h3 class="h5 g-color-gray-dark-v2 g-font-weight-600 text-uppercase mb-3">Public Liability Policy Insurance </h3>
             <p class="mb-0">Buy Public Liability Policy Insurance
             </p>
             <br />
             <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                routerLink="/buymotor">
                Buy now
             </a>
          </div>
          <!-- End Icon Blocks -->
       </div>
         <div class="col-lg-3 cta-item g-brd-left--lg g-brd-gray-light-v4 g-px-40">
            <!-- Icon Blocks -->
            <div class="text-center">
               <span
                  class="d-inline-block u-icon-v3 u-icon-size--xl u-icon-effect-v3--hover g-bg-primary g-color-white rounded-circle g-mb-30">
               <i class="fa fa-briefcase "></i>
               </span>
               <h3 class="h5 g-color-gray-dark-v2 g-font-weight-600 text-uppercase mb-3">Professional Indemity Policy</h3>
               <p class="mb-0">Buy Professional Indemity Policy</p>
               <br />
               <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                routerLink="/buymotor">
                Buy now
             </a>
            </div>
            <!-- End Icon Blocks -->
         </div>

      </div>
   </div>
</section>



<section class="h-e-p-50" >
  <div class="container  g-pt-50 g-pb-70">
   <div class="text-center g-mb-70">
     <h2 class="g-font-weight-400">
        <p>Our Life Products</p>
     </h2>
     <p class="g-font-size-16">Our products are the best and we are the preferred insurance providers.</p>
  </div>
     <div class="row no-gutters">
        <div class="col-lg-3 cta-item g-px-40 g-mb-50 g-mb-0--lg">
           <!-- Icon Blocks -->
           <div class="text-center">
              <span
                 class="d-inline-block u-icon-v3 u-icon-size--xl u-icon-effect-v3--hover g-bg-primary g-color-white rounded-circle g-mb-30">
              <i class="fa fa-hourglass-start "></i>
              </span>
              <h3 class="h5 g-color-gray-dark-v2 g-font-weight-600 text-uppercase mb-3">Term Assurance
              </h3>
              <p class="mb-0">Buy Term Assurance

              </p>
              <br />
              <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                 routerLink="/buymotor">
              Buy now
              </a>
           </div>
           <!-- End Icon Blocks -->
        </div>
        <div class="col-lg-3 cta-item g-brd-left--lg g-brd-gray-light-v4 g-px-40 g-mb-50 g-mb-0--lg">
           <!-- Icon Blocks -->
           <div class="text-center">
              <span
                 class="d-inline-block u-icon-v3 u-icon-size--xl u-icon-effect-v3--hover g-bg-primary g-color-white rounded-circle g-mb-30">
              <i class="fa fa-key"></i>
              </span>
              <h3 class="h5 g-color-gray-dark-v2 g-font-weight-600 text-uppercase mb-3">Keyman Assurance
              </h3>
              <p class="mb-0">Buy Keyman Assurance

              </p>
              <br />
              <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                 routerLink="/buymotor">
              Buy now
              </a>
           </div>
           <!-- End Icon Blocks -->
        </div>

        <div class="col-lg-3 cta-item g-brd-left--lg g-brd-gray-light-v4 g-px-40 g-mb-50 g-mb-0--lg">
         <!-- Icon Blocks -->
         <div class="text-center">
            <span
               class="d-inline-block u-icon-v3 u-icon-size--xl u-icon-effect-v3--hover g-bg-primary g-color-white rounded-circle g-mb-30">
            <i class="fa fa-home"></i>
            </span>
            <h3 class="h5 g-color-gray-dark-v2 g-font-weight-600 text-uppercase mb-3">Mortgage Protection

              </h3>
            <p class="mb-0">Buy Mortgage Protection
            </p>
            <br />
            <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
               routerLink="/buymotor">
            Buy now
            </a>
         </div>
         <!-- End Icon Blocks -->
      </div>
        <div class="col-lg-3 cta-item g-brd-left--lg g-brd-gray-light-v4 g-px-40">
           <!-- Icon Blocks -->
           <div class="text-center">
              <span
                 class="d-inline-block u-icon-v3 u-icon-size--xl u-icon-effect-v3--hover g-bg-primary g-color-white rounded-circle g-mb-30">
              <i class="fa fa-umbrella"></i>
              </span>
              <h3 class="h5 g-color-gray-dark-v2 g-font-weight-600 text-uppercase mb-3">Endowment Assurance
              </h3>
              <p class="mb-0">Buy Endowment Assurance</p>
              <br />
              <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
               routerLink="/buymotor">
              Buy now
              </a>
           </div>
           <!-- End Icon Blocks -->
        </div>

     </div>
  </div>
</section>


