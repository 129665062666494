
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;"><strong>BRAIN EXERCISES THAT BOOST MEMORY</strong></p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/Brain_Exercises.jpeg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>While you might know that you need to exercise your body, did you know that it might also be important to exercise your mind? You've probably heard the old adage "use it or lose it." Many researchers do believe that this maxim applies to your brain health for keeping your mind sharp and preventing memory loss. </p>
                 <p></p>
                       <p>For instance, strength exercises can help build muscle, balance exercises can help prevent falls; and flexibility and stretching exercises can help maintain range of motion to stay limber. Similarly, your brain's cognitive reserve — its ability to withstand neurological damage due to aging and other factors without showing signs of slowing or memory loss — can also benefit from both physical and cognitive exercise.</p>

              </div>
           </div>

          <div class="row justify-content-between">


              <div class="col-md-12">
                <p></p>
                <p>New tricks and strategies to keep your brain young are being developed every day, but for now, here is a list of some methods you can start making a part of your mental fitness routine today! Once you get too good at any of these little tricks, they become routine and no longer challenge your brain. It is important to always switch things up.</p>

                 <p></p>
                  <p><strong>1.	Play Sudoku</strong><br/>
                    A classic brain teaser. It’s challenging and addictive and forces you to think in ways you normally don’t have to which is great for brain training.
                  </p>
                  <p></p>
                 <p><strong>2.	Learn a new sport</strong> <br/>
                  Try taking up an athletic exercise that utilizes both mind and body such as yoga, golf, or tennis.
                 </p>

                 <p></p>
                 <p><strong>3.	Try puzzles</strong> <br/>
                  It may seem childish, but any regular activity that involves fine-motor skills is great for getting your brain to work harder.
                 </p>
                 <p></p>

                 <p><strong>4.	Switch hands</strong> <br/>
                    Try using your non-dominant hand to do things like brushing your teeth or eating, it will be hard at first but greatly increases activity in your brain making it a great workout.
                 </p>
                 <p></p>

                 <p><strong>5.	Chess</strong> <br/>
                  Really any high strategy game is good for your brain, as long as conditions can change in a moment, your brain will get a workout.
                </p>
                <p></p>

                 <p>Additionally, doing things like giving your brain new experiences that combine your physical senses—vision, smell, touch, taste, and hearing—with emotional “sense” may help stimulate more connections between different brain areas, making surrounding cells stronger and more resistant to the effects of aging. </p>

              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>