<section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px">
  <div class="container">
    <div class="d-sm-flex text-center">
      <div class="align-self-center">
        <h2 class="h3 g-font-weight-300 w-100 g-mb-10 g-mb-0--md">
          <p>NSIA Keyman Assurance </p>
        </h2>
      </div>


    </div>
  </div>
</section>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/lifeinsurance">Life Insurance</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item g-color-primary">
        <span>NSIA Keyman Assurance</span>
      </li>

      <li class="list-inline-item ml-auto">
        <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
          routerLink="/lifequote">Get quote</a>
      </li>
    </ul>
  </div>
</section>
<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
    <div class="col-lg-9 g-mb-30">
      <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
        <h4><strong>
            <p>NSIA Keyman Assurance </p>
          </strong> </h4>

        <p>This product is a basic term assurance contract that is specifically designed for business men or women, traders,
        importers/exporters, key officers of organizations, partners in a firm and small business owners:</p>
        <ul>
          <li>Key officers of organizations whose premature death may cause financial difficulties for the organization</li>
          <li>Partners who would require immediate cash to buy out a deceased partner, thus protecting the company’s cash flow from
          the negative effect of the partner’s unexpected death</li>
          <li>Small business owners who want to ensure that their businesses continue to run in the event of death.</li>
        </ul>
        <p></p>
        <p><strong>Key Features</strong></p>
        <p></p>
        <ul>
          <li>Payment of a guaranteed sum of money (sum assured) if death of the life assured occurs within a specific period of time</li>
          <li>Payment is also made in case of total permanent disability or diagnosis of a critical illness</li>
          <li>Duration of cover is flexible and determined by the policyholder</li>
          <li>The benefit payable is specified in advance by the policyholder</li>
          <li>Keyman is usually arranged for large sums of money hence the minimum guaranteed benefit (sum assured) permissible is
          Five Hundred Thousand Naira (N500,000)</li>
          <li>Payment is made to a named beneficiary who could be an individual or an organization</li>
          <li>Benefit serves as collateral for loan, where it is used to secure a loan</li>
          <li>Policyholder pays a single premium (purchase price) or annual payments to NSIA Insurance Limited to secure the
          guaranteed benefit</li>
          <li>As with every other variation of Term Assurance contracts, NSIA Keyman Assurance product is very cheap</li>
          <li>Where used for loans, the lender recoups outstanding loan on death of the life assured and the physical assets secured
          with loan remains with dependents: a win-win situation.</li>
          <li>Medical examinations may be required in certain cases</li>
        </ul>
        <p></p>
        <p><strong>Benefits</strong></p>
        <p></p>
        <ul>
          <li>
            <p><strong>On Death or Disability</strong></p>
            <p>NSIA Life pays the guaranteed sum assured to the named beneficiary/ies in the event of death or total permanent
            disability. In this case, the beneficiary could be  an organization or partnership. The guaranteed sum
            assured could be a fixed amount or a deceased partner’s share in a business
            venture.</p>
          </li>
          <li>
            <p><strong>On Discontinuation</strong></p>
            <p>The policy does not attract a cashback benefit in the event of termination of the cover. However, cover on the life
            assured cease to exist when premium is not paid on the policy. Considering the low-cost nature of the product, it is
            best that single premium option of premium payment is chosen.</p>
          </li>


        </ul>
        <p><strong>Waiting Period</strong> </p>

        <p></p>

        <p>There is a month waiting period during which a client cannot claim on non-accidental death. However, where accidental
        death occurs during this period, total premiums paid would be returned</p>
        <p></p>



      </div>
    </div>


    <!-- Sidebar -->
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <app-sidebar></app-sidebar>
      <!-- End Fast Facts -->
    </div>
    <!-- End Sidebar -->
  </div>
</div>
