
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p>“I’M YOUNG AND HEALTHY, WHY DO I NEED LIFE INSURANCE?”</p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/am_too_young.jpg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>The general rule is that if someone else relies on your income to live, then you need a life insurance policy. Perhaps you fall into the category of people who often wonder: “Why do I need life insurance? I’m young, strong, and healthy, I shouldn’t have to worry about that right now.” It can be hard to balance your social life, your love life and your job, let alone plan for what could happen to you decades from now.
                 </p>
                 <p></p>
                 <p>If you have people depending on you for financial support, you need to be prepared to give it, even if the worst should happen. One of the easiest ways to do that is by purchasing life insurance.
                 </p>
                 <p></p>

                   </div>
           </div>
           <div class="row justify-content-between">
              <div class="col-md-12">

                <p><strong>When should you buy life insurance?</strong>
                </p>
                <p></p>
                <p>The chances are that you’ll start thinking about needing a policy as you hit major life milestones. Whether it’s starting a business, getting married or having your first child, you should purchase life insurance.
                </p>
                <p></p>
                <p><strong>When you’re single: </strong>
                </p>
                <p>Single people often don’t think much of life insurance, but if you help support other family members, what happens if you aren’t capable of covering their expenses? Do they depend on your income? If so, then taking out a policy is a good bet. </p>

              <p><strong>When you’re married:</strong></p>
                 <p>Getting married often prompts couples to have hard financial conversations, and it’s not a bad idea to throw in life insurance while you’re at it. You may want to consider getting a policy if your spouse depends on your income to fund your shared expenses (or vice versa). Plus, if you plan to have children one day, you may want to purchase life insurance before you become a parent.
                 </p>
                 <p></p>
                  <p><strong>When you’re married + kids: </strong></p>
                 <p>Once you have children, you almost always need life insurance. If something happens to you or your spouse, a life insurance policy will help your family handle expenses.
                  When looking to buy life insurance, it is essential to:
                  </p>
                <p></p>
                <p><strong>Shop smart. </strong></p>
                <p>Determine what kind of coverage you need and how it fits into your budget.</p>
              <p></p>
              <p><strong>Look for discounts. </strong></p>
              <p>Once you evaluate your coverage needs, factor in your budget and find ways to save. Ask your insurance agent if there are any discounts on your coverage.</p>

            <p><strong>P.S:</strong> Your life insurance rate is based on your age, medical history and lifestyle choices. It’s generally easier to get a cheaper policy if you’re young and relatively healthy. </p>
          <p>It’s beneficial for you to understand the importance of having the proper life insurance coverage. You never know when the unexpected can happen, so ensuring you and your loved ones are protected with a life insurance policy is a safe way to guarantee a secure future for everyone.</p>
        <p></p>
        <p>Want to know more about our Life Insurance products? Click here <a href="https://nsiainsurance.com/lifeinsurance">(https://nsiainsurance.com/lifeinsurance</a>, and if you have any questions, you can send us an email to <a href="mailto:customerservice@nsiainsurance.com">customerservice@nsiainsurance.com</a>. Better yet, send us a message on WhatsApp to +234 809 720 9218.</p>
        </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>