import { SubRiskModel } from './../Models/sub-risk.model';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import {Flutterwave, InlinePaymentOptions, PaymentSuccessResponse} from "flutterwave-angular-v3"
import { PaymentIntegrationService } from '../../Services/payment-integration.service';
import { PolicyDetailsModel } from '../Models/policy-details.model';
import { PolicyUserDetailsModel } from '../Models/policy-details-output.model';
import { RenewPolicyInputModel } from '../Models/renew-policy.model';
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-policy-details',
  templateUrl: './policy-details.component.html',
  styleUrls: ['./policy-details.component.scss']
})
export class PolicyDetailsComponent implements OnInit {
  policyDetailsFormGroup: FormGroup;
  empForm:any;
  subRisks: SubRiskModel[];
  policyDetails: PolicyUserDetailsModel;
  startDate: number;
  endDate: number;
  expiredDate: number;
  amount: number = 0;


  constructor( private flutterwave: Flutterwave,
     private fb: FormBuilder,
      private paymentService: PaymentIntegrationService ) {
    this.policyDetailsFormGroup = fb.group({
      productType: ['', Validators.required],
      policyId: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.getSubRisks();
  }


  get productType() {
    return this.policyDetailsFormGroup.get('productType');
  }
  get policyId () {
    return this.policyDetailsFormGroup.get('policyId');
  }




  generateReference(): string {
    let date = new Date();
    return date.getTime().toString();
  }

  onPolicySubmit() {
    console.log(this.productType.value);
    this.fetchPolicyDetails();
  }

//// New
makePayment(){

  const publicKey = "FLWPUBK-826e3f35395d2d544f0374ffce5b7c96-X";


 const customerDetails = {
   name: this.policyDetails.insuredName,
  email: this.policyDetails.email,
   }

  const customizations = {
    title: 'Payment for Renewal of Policy for ' + this.policyDetails.insuredName,
   description: 'NSIA Insurance Policy renewal for policy Number' + this.policyDetails.policyNo,
   logo: 'https://flutterwave.com/images/logo-colored.svg'}

  const meta = {
    'counsumer_id': this.policyDetails.policyId,
    'consumer_mac': this.policyDetails.policyNo}


  const paymentData: InlinePaymentOptions = {
    public_key: publicKey,
    tx_ref: this.generateReference(),
    amount: this.policyDetails.grossPremium,
    currency: 'NGN',
    payment_options: 'card',
    redirect_url: '',
    meta: meta,
    customer: customerDetails,
    customizations: customizations,
    callback: this.makePaymentCallback,
    onclose: this.closedPaymentModal,
    callbackContext: this
  }

  console.log(paymentData);
  const response = this.flutterwave.inlinePay(paymentData);
  console.log(response);
}
makePaymentCallback(response: PaymentSuccessResponse): void {
  console.log("Payment callback", response);
  this.renewTransactions(response);

}
closedPaymentModal(): void {
  console.log('payment is closed');
}



/// End




  getSubRisks() {
    this.paymentService.fetchAllsubRisk().subscribe(response => {
      if(response  !=null || response!=undefined) {
        this.subRisks = response;
        console.log(response);
      }
    })
  }
  fetchPolicyDetails() {
    const policyInput: PolicyDetailsModel = {
      productType: this.productType.value,
      policyId: this.policyId.value
    }
    console.log(policyInput);
    this.paymentService.checkIfPolicyExist(policyInput).subscribe(response => {
      if(response  !=null || response!=undefined) {
        console.log(response);
         this.policyDetails = response;
         this.startDate = new Date(this.policyDetails.startDate).getTime();
         this.endDate = new Date(this.policyDetails.endDate).getTime();
         console.log(this.startDate);
         console.log(this.endDate);
         const diff = this.endDate - this.startDate;
         this.expiredDate =  Math.ceil((((diff / 1000) / 60) / 60) / 24);
         return this.policyDetails;
      }
    })
  }


  renewTransactions(response: PaymentSuccessResponse) {
    const renewTransaction: RenewPolicyInputModel =  {
      amountRenewed: this.policyDetails.grossPremium,
      email: this.policyDetails.email,
      flw_ref: response.flw_ref,
      fullName: this.policyDetails.firstName? `${this.policyDetails.firstName} ${this.policyDetails.lastName}` : this.policyDetails.insuredName,
      phoneNumber:  null,
      policyId: +this.policyDetails.policyId,
      policyNo: this.policyDetails.policyNo,
      status: response.status,
      transaction_id: response.transaction_id,
      tx_ref: response.tx_ref,
      subRiskId: this.productType.value
    };

    console.log(renewTransaction);

    this.paymentService.updateRenewTransactions(renewTransaction).toPromise().then( response => {
      if(response!=null || response!=undefined) {
        this.fetchPolicyDetails();
      }
    })


  }

}
