import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-deferred-annuity-plan',
  templateUrl: './deferred-annuity-plan.component.html',
  styleUrls: ['./deferred-annuity-plan.component.css']
})
export class DeferredAnnuityPlanComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
