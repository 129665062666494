import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-simple-ways-to-be-more-productive',
  templateUrl: './simple-ways-to-be-more-productive.component.html',
  styleUrls: ['./simple-ways-to-be-more-productive.component.css']
})
export class SimpleWaysToBeMoreProductiveComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
