<section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px">
    <div class="container">
      <div class="d-sm-flex text-center">
        <div class="align-self-center">
          <h2 class="h3 g-font-weight-300 w-100 g-mb-10 g-mb-0--md">
            <p>Whole Life Insurance (NSIA Living Companion)</p>
          </h2>
        </div>


      </div>
    </div>
  </section>
  <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
      <ul class="d-flex justify-content-between u-list-inline">
        <li class="list-inline-item g-mr-15">
          <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
          <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
        </li>
        <li class="list-inline-item">
          <a class="u-link-v5 g-color-text g-pr-10" routerLink="/lifeinsurance">Life Insurance</a>
          <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
        </li>
        <li class="list-inline-item g-color-primary">
          <span>Whole Life Insurance</span>
        </li>

        <li class="list-inline-item ml-auto">
          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
            routerLink="/lifequote">Get quote</a>
        </li>
      </ul>
    </div>
  </section>
  <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
    <div class="row">
      <div class="col-lg-9 g-mb-30">
        <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
          <h4><strong>
              <p>Whole Life Insurance (NSIA Living Companion)</p>
            </strong> </h4>

          <p>NSIA Living Companion product helps an individual to live life confidently without worrying about any possible financial
          difficulties on death, disability or diagnosis of critical illness. As the name suggests, the product offers life
          insurance throughout the entire lifetime of an individual.</p>

          <p><strong>Key Features</strong></p>
          <p></p>
          <ul>
            <li>
              Payment of a guaranteed sum of money (sum assured) in the event of death, disability or diagnosis of a critical illness.
            </li>
            <li>
              The amount payable is specified in advance by the policyholder
            </li>
            <li>
              Minimum sum assured is N500,000.00 while the maximum benefit is N5,000,000.00 .
            </li>
            <li>
              Payment is made to the person named by the policyholder (e.g. wife, child etc)
            </li>
            <li>
              Policyholder stops payment of premium at age 45, 50, 55, 60 or 65, yet cover continues for life.
            </li>
            <li>
              Policyholder pays a single premium (purchase price) or annual premiums to NSIA Insurance Limited to secure the
              guaranteed benefit
            </li>
            <li>
          A policyholder who purchases the product between ages 60 and 65 can only pay single premium. He cannot pay annual
          premium.
            </li>
            <li>
            Medical examinations may be required in certain cases
            </li>

          </ul>
          <p></p>
          <p><strong>Waiting Period</strong></p>
          <p></p>
          <p>Except for accidental death, the policyholder waits for three months from the effective date of the policy before rights
          to benefits starts to accrue.</p>
          <p></p>
          <p><strong>Benefits</strong></p>
          <p></p>
          <ul>
            <li>
              <p><strong>On Death or Disability</strong></p>
              <ul>
                <li>NSIA Life pays the guaranteed sum assured to the policyholder’s named beneficiary/ies in the event of death after the
                waiting period of three months as in the case of non-accidental deaths.</li>
              </ul>
            </li>
            <li>
              <p><strong>On Discontinuation</strong></p>
              <p>In the unlikely event of the policyholder stopping payment of the premium within the policy period, no benefit is
              payable as the policy is automatically discontinued. Since the product is low-cost, payment of a single premium is
              recommended to immediately obtain valuable life insurance protection for the specified period.</p>
            </li>

          </ul>
          <p><strong>Suitability</strong></p>
          <p></p>
          <p>This product is suitable for anyone who wants a life cover and some level of profit for a lengthy period of time.</p>

          <p></p>



        </div>
      </div>


      <!-- Sidebar -->
      <div class="col-lg-3 g-mb-30">
        <!-- Links -->
        <app-sidebar></app-sidebar>
        <!-- End Fast Facts -->
      </div>
      <!-- End Sidebar -->
    </div>
  </div>
