import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { NgForm } from '@angular/forms'
import { FormserviceService } from 'src/app/Services/formservice.service';
import { Beneficiaries, DDInstructions, MedicalDetails, NextOfKin, } from 'src/app/Classes/proposal-form';
import { Utilities } from 'src/app/Helpers/utilities';


@Component({
  selector: 'app-group-life-proposal-form',
  templateUrl: './group-life-proposal-form.component.html',
  styleUrls: ['./group-life-proposal-form.component.css']
})
export class GroupLifeProposalFormComponent implements OnInit {
  model;
  month:any;
  day:any;
  year:any;
  maxDate:any;

  telephone :string;
  dateTemp = new Date();
  step:number = 1;
  diseases: string[] = [];
  otherPolicy: string[] = [];
  ddEnabled:number = 0;
  countries:any;
  states:any;
  banks:any;
  termAgreed:boolean = false;
  showBeneficiaryTable: boolean = false
  ddModel = new DDInstructions("","","",null);
  beneficiaryModel = new Beneficiaries("", "", "", null, this.dateTemp);
  nextOfKinModel = new NextOfKin("","","","");
  productType:number = 0;
  medicalModel =  new MedicalDetails("","","","","",this.dateTemp,null,"","","",null,null,this.dateTemp, [],null,"",null,"");
 proposalModel = new  ProposalForm(null,null,"","",null,"",null,"","","","","","","",this.dateTemp,null,"",
  "","","","","","","",null,this.medicalModel,"","",null,null,[],"",[],this.beneficiaryModel,this.nextOfKinModel,"","",this.ddModel, "","","","","","","","","","","","", "","","","");
  constructor(private _formservice: FormserviceService, private _utils:Utilities) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this._formservice.GetCountries()
      .subscribe(
        (data) => {
          this.countries = data
        },
        (error) => {
          console.log(error);
        }
      );
      this._formservice.GetStates()
        .subscribe(
          (data) => {
            this.states = data
          },
          (error) => {
            console.log(error);
          }
        );
        this._formservice.GetBanks()
        .subscribe(
          (data) => {
            this.banks = data
          },
          (error) => {
            console.log(error);
          }
        );



    var dtToday = new Date();
    this.month = dtToday.getMonth() + 1;
    this.day = dtToday.getDate();
    this.year = dtToday.getFullYear();
    if(this.month < 10)
    this.month = '0' + this.month.toString();
    if(this.day < 10)
    this.day = '0' + this.day.toString();

    this.proposalModel.ComencementDate = this.year + '-' + this.month + '-' + this.day;

    document.getElementById("ComencementDate").setAttribute("min", this.proposalModel.ComencementDate);
  }
  selected(files: FileList)
  {
    let theFile = files[0];
    this._utils.ResizeImage(theFile, 128, 128)
    .subscribe(
      (file : File) =>{
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event) => {
          this.proposalModel.src= reader.result.toString()
          this.proposalModel.filenameId= file.name
          this.proposalModel.filetypeId= file.type
          this.proposalModel.filestringId=this.proposalModel.src.substr(this.proposalModel.src.indexOf(',')+ 1)
        }
      }
    )

  }

  selected2(files: FileList)
  {
    let theFile = files[0];
    this._utils.ResizeImage(theFile, 128, 128)
    .subscribe(
      (file : File) =>{
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event) => {
          this.proposalModel.src2= reader.result.toString()
          this.proposalModel.filenameId2= file.name
          this.proposalModel.filetypeId2= file.type
          this.proposalModel.filestringId2=this.proposalModel.src.substr(this.proposalModel.src.indexOf(',')+ 1)
        }
      }
    )
  }


  assignProductType(){
    this.productType = this.proposalModel.ProductType;
    if (this.productType > 0) {
      console.log(this.productType);
    this.step = 2;
    }

  }
  Previous(){
    if (this.step == 2) {
      this.proposalModel.ProductType = null;
      this.productType = null;
    }
    this.step--;

    window.scrollTo(0, 0);
  }

  addPolicy(e){
    let value = e.srcElement.value;
    // let type = true;
    if (e.srcElement.checked) {
      this.otherPolicy.push(value);
    }else{
      const index: number = this.otherPolicy.indexOf(value);
      if (index !== -1) {
        this.otherPolicy.splice(index, 1);
      }
    }
  }
  addDisease(e){
    let value = e.srcElement.value;
    // let type = true;
    if (e.srcElement.checked) {
      this.diseases.push(value);
    }else{
      const index: number = this.diseases.indexOf(value);
      if (index !== -1) {
        this.diseases.splice(index, 1);
      }
    }

  //  console.log(e);


  }
  submitForm(myForm: NgForm){
    var email = this.proposalModel.EmailAddress;

    var regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (!this.termAgreed) {
     alert("You need to agree to the terms and agreement");
      return;
    }
    if(!regex.test(email)) {
      alert("Your email is not correct");
      this._formservice.stopLoading();
      return;
    }
    console.log($('#Telephone').val());
    var telLength = $('#Telephone').val().toString().length;
    //var secTel =$("#AlternativeTel").val().toString().length;

    if(telLength < 10){
     alert("Your telephone number is not correct");
      this._formservice.stopLoading();
      return;
    }
     if(this.proposalModel.filestringId==''){
     alert("Upload your cac");
      this._formservice.stopLoading();
      return;
    }
    if(this.proposalModel.filestringId2==''){
     alert("Upload your signature");
      this._formservice.stopLoading();
      return;
    }  else{

    this._formservice.startLoading();
    this._formservice.sendProposalForm(this.proposalModel)
      .subscribe(
        (data) => {
          if (data.statusCode == "00") {
           alert("Your form is submitted");
            myForm.resetForm();
          } else {
           alert(data.statusMessage);
          }
          this._formservice.stopLoading();
        },
        (error) => {
          console.log(error);
         alert("internal server error occurred");
          this._formservice.stopLoading();
        }
      )
    }

  }
  testThis(){
    console.log(this.termAgreed);

  }
  Add(){
    if (this.beneficiaryModel.BenefitProportion == "") {
     alert("Beneficiary proportion is required");
      return;
    }
    if (this.beneficiaryModel.Relationship == "") {
     alert("Beneficiary relationship is required");
      return;
    }
    if (this.beneficiaryModel.FullName == "") {
     alert("Beneficiary full name is required");
      return;
    }
    if (this.beneficiaryModel.Sex <= 0) {
     alert("Beneficiary gender is required");
      return;
    }
    if (!this.beneficiaryModel.DateOfBirth) {
     alert("Beneficiary Date of birth is required");
      return;
    }
    this.proposalModel.BeneficiariesData.push(this.beneficiaryModel);
    this.beneficiaryModel = new Beneficiaries("", "", "", null, this.dateTemp);
    if (this.proposalModel.BeneficiariesData.length > 0) {
      this.showBeneficiaryTable = true;
    }
  }
  editBen(i){
    this.beneficiaryModel.BenefitProportion = this.proposalModel.BeneficiariesData[i].BenefitProportion
    this.beneficiaryModel.DateOfBirth = this.proposalModel.BeneficiariesData[i].DateOfBirth
    this.beneficiaryModel.FullName = this.proposalModel.BeneficiariesData[i].FullName
    this.beneficiaryModel.Relationship = this.proposalModel.BeneficiariesData[i].Relationship
    this.beneficiaryModel.Sex = this.proposalModel.BeneficiariesData[i].Sex
    this.proposalModel.BeneficiariesData.splice(i, 1);
    if (this.proposalModel.BeneficiariesData.length > 0) {
      this.showBeneficiaryTable = true;
    }else{
      this.showBeneficiaryTable = false;
    }

  }
  deleteBen(i){
    this.proposalModel.BeneficiariesData.splice(i, 1);
    if (this.proposalModel.BeneficiariesData.length > 0) {
      this.showBeneficiaryTable = true;
    }else{
      this.showBeneficiaryTable = false;
    }
  }

}
export class ProposalForm {
  constructor(
    public ProductType: number,
    public PremiumType:number,
    public Surname:string,
    public OtherNames:string,
    public Sex:number,
    public MaidenName:string,
    public MaritalStatus:number,
    public ResidentialAddress:string,
    public PostalAddress:string,
    public EmailAddress:string,
    public Telephone:string,
    public OfficeTelephone:string,
    public AlternativeTel:string,
    public AverageAge:string,
    public DateOfBirth:Date,
    public Age:number,
    public RICNumber:string,
    public StateOfOrigin:string,
    public Nationality:string,
    public Occupation:string,
    public EmployerName:string,
    public Position:string,
    public NatureOfWork:string,
    public EmployersAddress:string,
    public PaymentFrequency:number,
    public MedicalData:MedicalDetails,
    public ComencementDate: string,
    public Duration:string,
    public SumAssurred:number,
    public NumberOfMembers:number,
    public OtherPolicy: string[],
    public IneligibilityFactor:string,
    public BeneficiariesData:Beneficiaries[],
    public BeneficiariesTemp:Beneficiaries,
    public NextOfKinData:NextOfKin,
    public PolicyHolder:string,
    public RelationshipToPolicyHolder: string,
    public DebitMandateInstruction:DDInstructions,
    public Agent :string,
    public IndustrySector :string,
    public productclassification:string,
    public Scheme:string,
    public CompanyRep:string,

    public filenameId:string,
    public filetypeId:string,
    public base64textStringId:string,
    public processedImagesId: any = [],
    public filestringId: string,
    public src:string,
    public src2:string,

    public filestringId2: string,
    public filenameId2:string,
    public filetypeId2:string,
    public base64textStringId2:string,


  ) {

  }

}
