
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;">“YOU DON HIT MY CAR!” What now?</p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/you_don_hit.jpg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>“I recall my experience on my way to the office earlier this year, where the Lagos State Traffic Management Authority (LASTMA) officials were trying to arrest a Danfo driver for picking passengers at a point on the road that was not the designated bus-stop. The LASTMA officials ordered me to stop in order to prevent the Danfo driver, whose bus was right behind my car, from speeding off. As my car came to a halt, the bus driver hit my car from behind and broke my rear light. I felt hurt like every Nigerian who does not like scratches or dents on his/her treasured car; so I came down to inspect the extent of the damage. There and then, the LASTMA officials took possession of the bus and requested that the driver should fix my car. The bus driver was on the floor prostrating and begging for mercy because he had no money on him.
                 </p>
                 <p></p>
                 <p>Since I was already close to my office, I requested that we drove over there to settle. We proceeded to my office with the help of the traffic officials, where the bus particulars and keys were handed to me.
                 </p>
                 <p></p>


              </div>
           </div>

          <div class="row justify-content-between">


              <div class="col-md-12">
               <p>I looked through his vehicle particulars and his insurance certificate; I typed his registration number into the NIID (Nigerian Insurance Industry Database) platform: a central record where all insured vehicles in Nigeria are registered. The search returned the registration details of the bus. Hurray! He had a valid insurance certificate but he was not aware. I tried explaining it to him, but he didn’t understand what having a valid third-party insurance certificate meant. I took my time to explain further and his joy knew no bounds because he could not imagine that he would leave my office with his bus without even paying a dime.
               </p>
               <p></p>
                <p>The bus driver said “Insurance is good o. So, Aunty, na insurance go repair your car?” [“Insurance is good. Aunty, is it insurance that will fix your car?”] I said yes reassuringly and made a copy of his insurance certificate since I have a comprehensive insurance coverage on my car.”</p>
                <p></p>
                <p>In this scenario, if the car owner didn’t have a comprehensive auto insurance but a third-party liability, she would have asked the bus driver to lodge a claim on his policy to enable his insurance provider bear the cost of the repairs. </p>
                <p></p>

                 <p>Most vehicle owners with third party insurance cover in Nigeria, just like this bus driver, assume that the insurance certificate is one of their vehicle particulars to avoid any hassles with traffic control officials, when the need arises.
                 </p>
                 <p></p>

                 <p>We hope this piece will be useful enough to sensitise other vehicle owners about the importance and value of your third-party insurance certificate. </p>
                <p></p>

                <p>Feel free to ask us any questions you might have by clicking on this link <a href="https://nsiainsurance.com/contact-form">https://nsiainsurance.com/contact-form</a> or send an email to <a href="mailto:customerservice@nsiainsurance.com">customerservice@nsiainsurance.com</a>. </p>

              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>