export class NewMotorProposalForm {
  constructor(

    public Surname:string,
    public OtherNames:string,
    public Sex:string,
    public RegistrationNumber:string,
    public MaidenName:string,
    public MaritalStatus:string,
    public ResidentialAddress:string,
    public EmailAddress:string,
    public Telephone:string,
    public CompanyName:string,
    public Identification_type:string,
    public DateOfBirth:Date,
    public Age:number,
    public IdRegistrationNumber:string,
    public Occupation:string,
    public EmployerName:string,
    public EmployersAddress:string,
    public title:string,
    public filenameId:string,
    public filetypeId:string,
    public base64textStringId:string,
    public processedImagesId: any = [],
    public filestringId: string,
    public src:string,
    public covertype:string,
    public additionalcover:string,
    public use_of_vehicle:string,
    public Cover:string,
    public VehicelRegNo:string,
    public VehicleMake_Type:string,
    public chasis_no:string,
    public Engine_No:string,
    public Colour:string,
    public body_type:string,
    public authorizedtodrive:string,
    public driving_motor_vehicles: string,
    public offence:string,
    public DeclinedProposal:String
  ) {

  }
}
