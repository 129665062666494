import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
// import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Font from '@ckeditor/ckeditor5-font/src/font';
import { BlogsService } from '../../Services/blog.service';
import { BlogPost } from '../../Classes/blogs-model';
import { ErrorSnackBarconfig, SuccessSnackBarconfig } from '../snackbars/material-snackbar';
// import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';


@Component({
  selector: 'app-blog-post',
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.css']
})
export class BlogPostComponent implements OnInit {
  comment: string;
  url: string;
  blogDetails: string;

  blogForm: FormGroup;
  // public Editor = DecoupledEditor;
  public Editor = ClassicEditor;



  constructor(
    private fb: FormBuilder,
    private blogService: BlogsService,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar) {

      this.blogForm = this.fb.group({
        title: ['', [Validators.required]],

      })


  }

  ngOnInit(): void {

  }

  get title () {
    return this.blogForm.get('title');
  }

 onReady( editor ) {
    console.log(editor);
    editor.ui.getEditableElement().parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()

    );
  }


    onSelectFile(event) {
       // called each time file input changes
        if (event.target.files && event.target.files[0]) {
          var reader = new FileReader();

          reader.readAsDataURL(event.target.files[0]); // read file as data url

          reader.onload = (event) => { // called once readAsDataURL is completed
            this.url = event.target.result.toString();
            console.log(this.url);
          }
        }
    }



    saverange(event) {
      console.log(event.target);
      this.blogDetails =  this.title.value;
    }



  onChange({ editor }: ChangeEvent) {
    console.log(editor);
    const data = editor.getData();
    this.comment = data;
    this.blogDetails =  this.title.value;
  }


  saveBlog() {

    const blogDetailsPost: BlogPost = {
      title: this.title.value,
      value: this.url,
      content: this.comment,
      author: 'Ogunleye Olawale Lawrence'
    };

    console.log(blogDetailsPost);

    this.blogService.createBlogPost(blogDetailsPost).toPromise().then( response => {

      console.log(response);
      if (response !=null) {
        this.snackBar.open('Successful.', 'Done!', SuccessSnackBarconfig);
        this.blogForm.reset();
      }
    }).catch( error=> {
      console.log(error);
      this.snackBar.open('Error.', 'FAILED!', ErrorSnackBarconfig);
    });



  }

}
