import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-importance-of-data-privacy-in-your-business',
  templateUrl: './importance-of-data-privacy-in-your-business.component.html',
  styleUrls: ['./importance-of-data-privacy-in-your-business.component.css']
})
export class ImportanceOfDataPrivacyInYourBusinessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
