<div class="container g-pt-100 g-pb-70">
  <div class="row">
    <div class="col-lg-12 g-mb-30">
      <section class="g-bg-gray-light-v5 g-py-20">
        <form novalidate (ngSubmit)="submitForm(beneficiaryForm)" #beneficiaryForm="ngForm" novalidate>
          <div class="container g-py-100 pad-top-50" style="margin-top:-50px">
            <div class="u-shadow-v19 g-brd-around g-brd-gray-light-v4  rounded mx-auto g-pa-30 g-pa-50--md">

              <div class="mb-6">
                <h2 class="mb-4 main-heading">
                  Group Life Proposal Form
                </h2>

                <section class="step-two" >
                  <div class="form-sub-title">
                    <h4>General Information</h4>
                    <p>Please note that we only accept image format for all our upload. e.g (jpg, png)</p>
                  </div>
                  <div class="row">

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Product Classification
                        </label>
                        <select name="productclassification" id="productclassification" class="form-control form-control-md rounded-0" type="text"
                        placeholder="Required" [(ngModel)]="proposalModel.productclassification" required >
                            <option >Select Product Classification</option>
                            <option value="Corporate">Corporate </option>
                            <option value="Welfare">Welfare</option>
                      </select>
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>Scheme Name</label>

                        <input id="Scheme" name="Scheme" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.Scheme" required>
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Address
                        </label>
                        <input id="ResidentialAddress" name="ResidentialAddress" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.ResidentialAddress" required >
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          E- mail address
                        </label>
                        <input id="EmailAddress" name="EmailAddress" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.EmailAddress" placeholder="johndoe@gmail.com" required type="text" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" >

                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                         Company Representative
                        </label>
                        <input id="CompanyRep" name="CompanyRep" class="form-control form-control-md rounded-0"
                         [(ngModel)]="proposalModel.CompanyRep"  type="text"  >

                      </div>
                    </div>


                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Mobile No
                        </label>
                        <input id="Telephone" name="Telephone" class="form-control form-control-md rounded-0" type="number"
                        placeholder="Required" [(ngModel)]="proposalModel.Telephone" required="required" placeholder="07035265975" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==11) return false; return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57));" >

<!--
                        <input id="Telephone" name="Telephone" class="form-control form-control-md rounded-0" type="number"
                          placeholder="Required" [(ngModel)]="proposalModel.Telephone" required pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==11) return false;" > -->

                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Second Mobile No
                        </label>
                        <input id="AlternativeTel" name="AlternativeTel" class="form-control form-control-md rounded-0" type="number" placeholder="07035265975"
                         [(ngModel)]="proposalModel.AlternativeTel" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==11) return false; return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57));" >


                        <!-- <input id="AlternativeTel" name="AlternativeTel" class="form-control form-control-md rounded-0" type="number"
                       [(ngModel)]="proposalModel.AlternativeTel"  pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==11) return false;"> -->
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          RIC Number
                        </label>
                        <input id="RICNumber" name="RICNumber" class="form-control form-control-md rounded-0" type="text"
                          [(ngModel)]="proposalModel.RICNumber" >
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Industry Sector
                        </label>
                        <input id="IndustrySector" name="IndustrySector" class="form-control form-control-md rounded-0" type="text"
                          [(ngModel)]="proposalModel.IndustrySector" >
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Agent/Broker
                        </label>
                        <input id="Agent" name="Agent" class="form-control form-control-md rounded-0" type="text"
                          [(ngModel)]="proposalModel.Agent" >
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Average Age of Member
                        </label>
                        <input id="AverageAge" name="AverageAge" class="form-control form-control-md rounded-0" type="number"
                          [(ngModel)]="proposalModel.AverageAge" >
                      </div>
                    </div>


                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Upload CAC
                        </label>
                        <br/>
                        <input type="file" (change)="selected($event.target.files)" required />
                      </div>
                    </div>
                  </div>
                </section>
                <!-- Medical Informations -->
                <section class="step-three">
                  <div class="form-sub-title">
                    <h4>Medical Informations</h4>
                  </div>
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Hospital Name
                        </label>
                        <input id="Name" name="proposalModel.MedicalData.Name" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="medicalModel.Name" >
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Hospital Address
                        </label>
                        <input id="hAddress" name="proposalModel.MedicalData.Address" class="form-control form-control-md rounded-0" type="text"
                          [(ngModel)]="medicalModel.Address"  >
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Hospital Mobile number
                        </label>
                        <input id="hPhoneNumber" name="proposalModel.MedicalData.PhoneNumber" class="form-control form-control-md rounded-0" type="number"
                         [(ngModel)]="medicalModel.PhoneNumber"  pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==11) return false; return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57));" >
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Hospital Email Address
                        </label>

                        <input id="hEmailAddress" name="proposalModel.MedicalData.EmailAddress" class="form-control form-control-md rounded-0"
                        [(ngModel)]="medicalModel.EmailAddress"  type="text" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" >

                      </div>
                    </div>



                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                        Policy commencement date
                        </label>
                        <input id="ComencementDate" name="proposalModel.MedicalData.ComencementDate" class="form-control form-control-md rounded-0" type="date"
                          placeholder="Required" [(ngModel)]="proposalModel.ComencementDate" >
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Duration
                        </label>
                        <select name="proposalModel.MedicalData.Duration" id="Duration" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.Duration">
                            <option >Select your option</option>
                            <option value="Yearly">Yearly</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                         Total Sum Assured
                        </label>
                        <input id="SumAssurred" name="proposalModel.MedicalData.SumAssurred" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.SumAssurred" OnlyNumber="true">
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Number of Members
                        </label>
                        <input id="NumberOfMembers" name="proposalModel.MedicalData.NumberOfMembers" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.NumberOfMembers" >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Upload Signature
                        </label>
                        <br/>
                        <input type="file" (change)="selected2($event.target.files)" required />
                      </div>
                    </div>
                  </div>
                </section>

               <section class="step-seven" >
                <div class="form-sub-title">
                  <h4>Terms</h4>
                </div>
                 <div class="container">
                   <h4>Declaration</h4>
                   <p>I, the undersigned, do hereby declare that the above statements are true and complete.</p>
                   <p>I hereby give my consent to the Company, to seek information, if necessary, from any doctor who has ever attended to me from any Life Assurance Office, to which a Proposal on my Life, at any given time, has been made, and the giving of such information is hereby authorized.</p>
                   <p>I further agree that this Proposal, Declaration and the statements made above or to the Medical Examiner acting for the Company (where required) shall be the basis of the proposed life insurance contract; that if anything contrary to the truth be stated or if any information which ought to be made known to the Company with reference to the Proposed Assurance be withheld or concealed, any policy which may be granted in pursuance of this contract shall be null and void.</p>
                   <p>Note:</p>
                   <ul>
                     <li>The Company is bound only by documents bearing the signature of an official of the Company authorized to Sign on its behalf.</li>
                     <li>The Company is not on risk until acceptance of the Proposal has been communicated in writing and the First premium has been received at the Company’ Head Office.</li>
                     <li>Premium should be made through cheques or direct credit into NSIA Insurance Ltd 's account as <b>NSIA would not be liable for premium paid in cash.</b> </li>
                   </ul>
                   <div class="form-group">
                    <input type="checkbox" [(ngModel)]="termAgreed" name="termAgreed" > By checking the box , I agree with the above terms
                  </div>

                      <p>I hereby undertake to ensure that the account is always funded to cover this transaction.</p>

                      <p style="font-size:10px; text-align: justify;">In compliance with NDPR requirements, by completing and submitting this form, you have given consent to NSIA Insurance to receive your data, including your bio data, to enable NSIA Insurance maintain effective communication, send promotional updates, contact you for research purposes and use the data in line with the policy terms.
                      </p>
                      <p style="font-size:10px; text-align: justify;">The data collection may be via hard copies or online forms, validated via JSON WEB TOKEN (JWT). The data will not be accessed by any third party without your consent and any breach of this policy can be addressed legally within the year the breach was reported.</p>

                      <div class="col-lg-4">
                        <div class="form-group g-mb-0">
                          <label style="visibility: hidden;">Frequency</label>
                          <button  type="submit" onclick="return confirm('Are you sure you want to submit this information?')" class="g-brd-main btn-block g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" > Submit
                          </button>
                        </div>

                      </div>

                 </div>

               </section>

              </div>


            </div>
          </div>
        </form>
      </section>
    </div>

    <!-- End Sidebar -->
  </div>
</div>
