
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;"><strong>NSIA FAMILY PROTECTION PLAN</strong></p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/FPP.jpeg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>Funeral expenses aren’t something that most people like to think about, but having a suitable policy in place can give you peace of mind that your family will have the money to cater for funeral costs and other expenses during a distressing time.</p>
                 <p></p>
                 <p>
                  NSIA Family Protection Plan is a form of insurance that pays a specified amount of money in the event of a death by ensuring that the costs of a funeral will be covered so that the family members do not have to struggle financially.
                 </p>

                 <p></p>
                  <p>
                    We can all agree that funerals can be very costly and this expense cannot be carried by a single person. This plan gives families peace of mind to address any financial worries in the event that an individual or a family member passes on. It provides a death benefit to the policy holder to offset the cost of funeral and associated expenses in the event of death of any of the family members covered on the policy. The policy holder pays his premium continuously for five years and stops payment while the policy is in force for life.
                  </p>
                 <p></p>
                 <p>
                  An advantage of NSIA Family Protection Plan is that it provides your family with an immediate benefit. If you were to try and save for these expenses it could take many years to accumulate enough money, whilst an insurance policy gives you immediate financial reprieve regardless of how much you have paid in premiums.
                 </p>
              </div>
           </div>

          <div class="row justify-content-between">

              <div class="col-md-12">

                 <p></p>
                 <p>
                  Beyond the cost of a funeral itself, there are many other associated expenses to consider. These can include paying for a headstone, transporting guests to and from the funeral ceremony venues, accommodating guests for the duration of the ceremony, etc.
                 </p>
                 <p></p>

                 <p>
                  Having a Family Protection Plan in place is important to cushion oneself financially during a very tough time and can help to make the path ahead just a little easier.
                 </p>
                 <p></p>

                 <p>Click here <a href="https://nsiainsurance.com/familyprotection">www.nsiainsurance.com</a> for more information or call customer service on 09048418896.</p>

              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>
