import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import swal from 'sweetalert2';
import { NgForm } from '@angular/forms'
import { NewMarineCargoProposalForm } from 'src/app/Classes/new-marine-cargo-proposal-form';
import { FormserviceService } from 'src/app/Services/formservice.service';
import { Utilities } from 'src/app/Helpers/utilities';


@Component({
  selector: 'app-marine-cargo-proposal-form',
  templateUrl: './marine-cargo-proposal-form.component.html',
  styleUrls: ['./marine-cargo-proposal-form.component.css']
})
export class MarineCargoProposalFormComponent implements OnInit {
  month:any;
  day:any;
  year:any;
  maxDate:any;

  public DateOfBirth: any;
  Duration:number;
  //public Age: number;
  dateTemp = new Date();
  step:number = 1;
  diseases: string[] = [];
  otherPolicy: string[] = [];
  ddEnabled:number = 0;
  countries:any;
  states:any;
  banks:any;
  termAgreed:boolean = false;
  showBeneficiaryTable: boolean = false
  proposalModel = new  NewMarineCargoProposalForm(null,null,"","",null,"",null,"","","","","","",null,null,null,"",
  "","","","","","","","","","","","","","","","","","","","","","","",null,"","");
  constructor(private _formservice: FormserviceService, private _utils: Utilities) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this._formservice.GetCountries()
      .subscribe(
        (data) => {
          this.countries = data
        },
        (error) => {
          console.log(error);
        }
      );
      this._formservice.GetStates()
        .subscribe(
          (data) => {
            this.states = data
          },
          (error) => {
            console.log(error);
          }
        );
        this._formservice.GetBanks()
        .subscribe(
          (data) => {
            this.banks = data
          },
          (error) => {
            console.log(error);
          }
        );


        var dtToday = new Date();

        this.month = dtToday.getMonth() + 1;
        this.day = dtToday.getDate();
        this.year = dtToday.getFullYear();
        if(this.month < 10)
        this.month = '0' + this.month.toString();
        if(this.day < 10)
        this.day = '0' + this.day.toString();
  }

  selected(files: FileList)
  {
    let theFile = files[0];
    this._utils.ResizeImage(theFile, 128, 128)
    .subscribe(
      (file : File) =>{
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event) => {
          this.proposalModel.src= reader.result.toString()
          this.proposalModel.filenameId= file.name
          this.proposalModel.filetypeId= file.type
          this.proposalModel.filestringId =this.proposalModel.src.substr(this.proposalModel.src.indexOf(',')+ 1)
        }
      }
    )

  }


  selected2(files: FileList)
  {
    let theFile = files[0];
    this._utils.ResizeImage(theFile, 128, 128)
    .subscribe(
      (file : File) =>{
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event) => {
          this.proposalModel.src2 = reader.result.toString()
          this.proposalModel.filenameId2 = file.name
          this.proposalModel.filetypeId2 = file.type
          this.proposalModel.filestringId2 =this.proposalModel.src.substr(this.proposalModel.src.indexOf(',')+ 1)
        }
      }
    )

  }
  dob: Date;
  result: string;
  com :number;
  newDate:any;
  policy: string;
   //event handler for the select element's change event
   focusOutFunction () {
    this.dob = this.proposalModel.DateOfBirth;

    var today = new Date();
    var birthDate = new Date(this.dob);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    this.proposalModel.Age = age;
    if(age < 18){
      Swal.fire("Error", "Under age, kindly put age 18 & above", "info");
    }
  }



  submitForm(myForm: NgForm){

    console.log(this.proposalModel);

    var email = this.proposalModel.EmailAddress;
    var filestringId = this.proposalModel.filestringId;
    var regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if(!regex.test(email)) {
      swal.fire("Error", "Your email is not correct", "error");
      this._formservice.stopLoading();
      return;
    }
    var telLength = $('#Telephone').val().toString().length;

    if (this.proposalModel.Occupation == "") {
      Swal.fire("Error", "Occupation is required", "info");
      this._formservice.stopLoading();
      return;
    }

    if (this.proposalModel.EmployerName == "") {
      Swal.fire("Error", "Name Of Business is required", "info");
      this._formservice.stopLoading();
      return;
    }



    if (this.proposalModel.EmployersAddress == "") {
      Swal.fire("Error", "Business Address is required", "info");
      this._formservice.stopLoading();
      return;
    }


    if (!this.termAgreed) {
      Swal.fire("Error", "You need to agree to the terms and agreement", "info");
      this._formservice.stopLoading();
      return;
    }
    else if(filestringId==''){
      swal.fire("Error", "Upload your signature", "error");
      this._formservice.stopLoading();
    }
    else
    {

    this._formservice.startLoading();
    this._formservice.sendMarineCargoProposalForm(this.proposalModel)
      .subscribe(
        (data) => {
          if (data.statusCode == "00") {
            Swal.fire("Success", "Your form is submitted, Also you will get a copy of the email.", "success");


            myForm.resetForm();
          } else {
            Swal.fire("error", data.statusMessage, "info");
          }
          this._formservice.stopLoading();
        },
        (error) => {
          console.log(error);
          Swal.fire("fatal", "internal server error occurred", "error");
          this._formservice.stopLoading();
        }
      )

    }
}

}
