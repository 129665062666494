
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;"><strong>WORKING REMOTELY? HERE ARE 4 WAYS TO BOOST PRODUCTIVITY</strong></p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/wednesday_october_28.jpg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>It is a known saying that you should not bring your work home with you — but it is a little different when your work is at home. Whether you are working remotely on specific days of the week or all 5 working days, it is important to ensure that you are set up to be productive. This includes having a designated workspace with the adequate work tools.</p>
                 <p></p>
                 <p>Here are some strategies and tips to not only be successful at working from home but to also maintain productivity:</p>
                 <p></p>

              </div>
           </div>

          <div class="row justify-content-between">


              <div class="col-md-12">

                <p><strong>Create a dedicated office space</strong><br/>
                  It is critical to have a private, quiet space for your work. This space should only be for work related activities. By dedicating a space to work, you create a clear boundary between personal and work life.
                 </p>

                 <p></p>
                  <p><strong>Stay organized and on a schedule</strong><br/>
                    Keeping track of your daily responsibilities is essential to maintaining productivity and is a lot simpler than you think. Say, for example, your job is done at a computer, just open a brand-new spreadsheet or word document and plan out the rest of your tasks for the week or month. Save the file to your computer’s desktop and remember to keep it open throughout your workday. Always remember to check it periodically to ensure you are completing your tasks. Keeping a daily schedule of when you begin and end work helps you to be focused and motivated.
                  </p>
              <p></p>
                 <p><strong>Minimize distractions</strong> <br/>
                  It is also important to keep distractions out of the way when working remotely. When you take a break, doing something fun can help wipe your mind and refresh it for when you return to work. If your home area is always noisy, consider investing in noise cancelling headphones.
                 </p>

                 <p></p>
                 <p><strong>Do whatever makes you happy </strong> <br/>
                  The importance of finding happiness in what you do cannot be overemphasized. When you are happy, relaxed and motivated in your workspace, it becomes easier to accomplish the tasks you have for the day. This can range from putting on some good music, taking a walk outdoors when you have breaks, or just social communication with friends and family.
                 </p>
                 <p></p>

              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>