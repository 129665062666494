import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nolly-wood-connect',
  templateUrl: './nolly-wood-connect.component.html',
  styleUrls: ['./nolly-wood-connect.component.css']
})
export class NollyWoodConnectComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
