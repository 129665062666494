import { BlogsService } from './../../Services/blog.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BlogModel, BLogsListModel } from '../../Classes/blogs-model';
import { BlogsDataSource } from '../helpers/blog-datasource';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

// import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material/';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css']
})
export class BlogsComponent implements OnInit {

  pageNumber: number = 0;
  pageSize: number = 10;
  blogsDetails: BLogsListModel;
  myBlogList: BlogModel[];
  blogsDataSource: BlogsDataSource;
  @ViewChild('input') input: ElementRef;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  constructor(private _blogService: BlogsService) {



   }

     /**
   * Set the paginator after the view init since this component will
   * be able to query its view for the initialized paginator.
   */
//   ngAfterViewInit() {

//     fromEvent(, 'keyup')
//     .pipe(
//         debounceTime(150),
//         distinctUntilChanged(),
//         tap(() => {
//             this.paginator.pageIndex = 0;
//             this.loadRequestPage();
//         })
//     )
//     .subscribe();
//   //       this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0).
//   //      merge(this.sort.sortChange, this.paginator.page)
//   //  .pipe(
//   //      tap(() => this.loadRequestPage())
//   //  )
//   //  .subscribe();



//  }

  ngOnInit(): void {
    this.getBlogList('', '', '', this.pageNumber,this.pageSize);
    // this.blogsDataSource = new BlogsDataSource(this._blogService);
    // this.blogsDataSource.loadData('', '', '', this.pageNumber,this.pageSize);

  }


  loadRequestPage() {
    this.blogsDataSource.loadData(this.input.nativeElement, '', '', this.pageNumber,this.pageSize);
  }

  getBlogList(searchQuery: string , filter: string,
  sortDirection: string , pageNumber: number, pageSize: number) {

    this._blogService.getBlogList(searchQuery, pageNumber, pageSize).subscribe(result => {
      console.log(result);
      this.blogsDetails = result;
    })
  }
  pageLoad(event) {
    console.log(event);
    // this.blogsDataSource.loadData( '', '', '', event.pageIndex, this.pageSize);
    this.getBlogList('', '', '', event.pageIndex,event.pageSize);
  }

}
