<!-- <section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px">
  <div class="container">
    <div class="d-sm-flex text-center">
      <div class="align-self-center">
        <h2 class="h3 g-font-weight-300 w-100 g-mb-10 g-mb-0--md">
          <p>Awards </p>
        </h2>
      </div>


    </div>
  </div>
</section> -->
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>

      <li class="list-inline-item g-color-primary">
        <span>Awards</span>
      </li>


    </ul>
  </div>
</section>
<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
    <div class="col-lg-9 g-mb-30">
      <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
        <h3 class="mb-0"><strong><p>Awards</p></strong></h3><br/>
        <h4><strong>
            <p>Won</p>
          </strong> </h4>
          <ul>
            <li>The Most Improved Workplace Culture in Nigeria” (2013)</li>
            <li>"Special Recognition Award". September 2013</li>
            <li> The Most Improved Workplace Culture in Nigeria” (2014)</li>
          </ul>


        <p></p>
        <h4><strong>
          <p>Inducted</p>
        </strong> </h4>
        <ul>
          <li>Master Award Hall of Fame (2018)</li>
        </ul>
      <p></p>



      </div>

    </div>


    <!-- Sidebar -->
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <app-sidebar></app-sidebar>
      <!-- End Fast Facts -->
    </div>
    <!-- End Sidebar -->
  </div>
</div>
