import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-quick-guide-to-homeowners',
  templateUrl: './quick-guide-to-homeowners.component.html',
  styleUrls: ['./quick-guide-to-homeowners.component.css']
})
export class QuickGuideToHomeownersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
