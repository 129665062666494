<section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px">
  <div class="container">
    <div class="d-sm-flex text-center">
      <div class="align-self-center">
        <h2 class="h3 g-font-weight-300 w-100 g-mb-10 g-mb-0--md">
          <p>Immediate Annuities </p>
        </h2>
      </div>


    </div>
  </div>
</section>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/lifeinsurance">Life Insurance</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item g-color-primary">
        <span>Immediate Annuities</span>
      </li>

      <li class="list-inline-item ml-auto">
        <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10"
          routerLink="/lifequote">Get quote</a>
      </li>
    </ul>
  </div>
</section>
<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
    <div class="col-lg-9 g-mb-30">
      <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
        <h4><strong>
            <p>Immediate Annuities</p>
          </strong> </h4>

        <p>The immediate annuity plan enables the conversion of lump sum savings into lifetime income. This removes the uncertainty
        of running out of income after retirement. The product is targeted mainly at retirees, and individuals who consider
        annuities as an alternative investment
        </p>

        <p></p>
        <p><strong>Key Features</strong></p>
        <p></p>
        <ul>
          <li>The insured makes a single lump sum payment to purchase the annuity</li>
          <li>The insurer pays the annuity regularly until death of the annuitant</li>
          <li>The annuity payments may be level or increasing, and may be guaranteed for a number of years.</li>
          <li>When the guaranteed option is taken, on death of policyholder before the expiration of the guaranteed period, the
          annuity benefits will continue to be paid to the beneficiary till the end of the guaranteed period, or a lump sum
          equivalent value can be paid</li>
        </ul>
        <p></p>
        <p><strong>Immediate Annuity Options</strong></p>
        <p></p>
        <ul>
          <li>
            <p><strong>Option 1: Level Annuity in payment with no guarantee</strong></p>
            <ul>
              <li>Annuity payments remain at the same level for life.</li>
              <li>If death occurs the annuity payment expires immediately without any further regular/lump sum payment.</li>
            </ul>
          </li>
          <li>
            <p><strong>Option 2: Level Annuity in payment; guaranteed for 5 years</strong></p>
            <ul>
              <li>Annuity payments remain at the same level for life.</li>
              <li>If death occurs in the first 5 years, annuity will continue to be paid to the named beneficiary(ies) until the 5 years
              expire or a lump sum equal to the discounted value of the balance of the guaranteed period annuity becomes payable to
              the named beneficiary(ies)</li>
            </ul>
          </li>
          <li>
            <p><strong>Option 3: Level Annuity in payment; guaranteed for 10 years</strong></p>
            <ul>
              <li>Annuity payments remain at the same level for life.</li>
              <li>If death occurs in the first 10 years, annuity will continue to be paid to the named beneficiary(ies) until the 10 years
              expire or a lump sum equal to the discounted value of the balance of the guaranteed period annuity becomes payable to
              the named beneficiary(ies).</li>
            </ul>
          </li>


        </ul>



      </div>
    </div>


    <!-- Sidebar -->
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <app-sidebar></app-sidebar>
      <!-- End Fast Facts -->
    </div>
    <!-- End Sidebar -->
  </div>
</div>
