<section style="margin-top:70px">
  <section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px" *ngIf="step === 1">
     <div class="container">
        <div class="media g-brd-around g-brd-gray-light-v4 g-pa-30 g-mb-20">

           <div class="media-body">
              <div class="g-mb-15">
               <h2 class="mb-4 main-heading">
                House Holder Proposal Form
                </h2>
              <p style="text-align: justify;">An Insurance Agent who assists an applicant to complete an application or proposal form for insurance shall be deemed to have done so as the agent of the applicant” section 54(2), Insurance Act 2003</p>

              </div>

              <form novalidate (ngSubmit)="submitForm(beneficiaryForm)" #beneficiaryForm="ngForm" novalidate>
                 <div class="form-sub-title">
                    <h4>Personal Information</h4>
                  </div>
              <div class="row">
                 <div class="col-lg-6">
                    <div class="form-group g-mb-20">
                       <label class="g-mb-10">
                          <p>Customer's Name
                          </p>
                       </label>
                       <input id="customer_name" name="HouseHolderForm.Name" class="form-control form-control-md rounded-0" type="text"
                       placeholder="Required" [(ngModel)]="HouseHolderForm.Name" required>
                    </div>
                 </div>
                 <div class="col-lg-6">
                    <div class="form-group g-mb-20">
                       <label class="g-mb-10">
                          <p>Property Address
                          </p>
                       </label>
                       <input id="property_address" name="HouseHolderForm.propertyAddress" class="form-control form-control-md rounded-0" type="text"
                       placeholder="Required" [(ngModel)]="HouseHolderForm.propertyAddress" required>
                    </div>
                 </div>
                 <div class="col-lg-6">
                    <div class="form-group g-mb-20">
                       <label class="g-mb-10">
                          <p>Telephone Number
                          </p>
                       </label>
                       <input id="telephone_number" name="HouseHolderForm.Phonenumber" class="form-control form-control-md rounded-0" type="number"
                       placeholder="Required" [(ngModel)]="HouseHolderForm.Phonenumber" required="required"  required placeholder="07035265975" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==11) return false; return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57));" >
                    </div>
                 </div>
                 <div class="col-lg-6">
                    <div class="form-group g-mb-20">
                       <label class="g-mb-10">
                          <p>Email Address
                          </p>
                       </label>
                       <input id="emailaddress" name="HouseHolderForm.EmailAddress" class="form-control form-control-md rounded-0" type="text"
                       placeholder="Required" [(ngModel)]="HouseHolderForm.EmailAddress" required>
                    </div>
                 </div>

                 <div class="col-lg-6">
                   <div class="form-group g-mb-20">
                      <label class="g-mb-10">
                         <p>Agent
                         </p>
                      </label>
                      <input id="agent" name="HouseHolderForm.agent" class="form-control form-control-md rounded-0" type="text"
                    [(ngModel)]="HouseHolderForm.agent" >
                   </div>
                </div>
                <div class="col-lg-6">
                 <div class="form-group g-mb-20">
                   <label class="g-mb-10">
                     Upload Signature
                   </label>
                   <br/><br/>
                   <input type="file" (change)="selected($event.target.files)" required />
                 </div>
               </div>
              </div>

                <!-- Beneficia`ries -->
                <section class="step-four" >
                 <div class="form-sub-title">
                   <h4>List Of House Holder Items</h4>
                 </div>

                 <div class="row">
                   <div class="col-lg-4">
                     <div class="form-group g-mb-20">
                       <label>
                        Products
                       </label>
                       <select name="houseModel.Products" id="Products" class="form-control form-control-md rounded-0"
                       [(ngModel)]="houseModel.Products">
                            <option value="Select your option">Select your option</option>
                            <option value="Television">Television</option>
                            <option value="Window Air Conditioner">Window Air Conditioner</option>
                            <option value="Split Air Conditioner">Split Air Conditioner</option>
                            <option value="Ceiling Fan">Ceiling Fan</option>
                            <option value="Standing Fan">Standing Fan</option>
                            <option value="Stabilizer">Stabilizer</option>
                            <option value="Speaker">Speaker</option>
                            <option value="Deep Freezer">Deep Freezer</option>
                            <option value="Refrigerator">Refrigerator</option>
                            <option value="Washing Machine">Washing Machine</option>
                            <option value="Generator">Generator</option>
                            <option value="Water Dispenser">Water Dispenser</option>
                            <option value="Inverter">Inverter</option>
                            <option value="Pumping Machine">Pumping Machine</option>
                            <option value="Humidifier">Humidifier</option>
                            <option value="Pressing Iron">Pressing Iron</option>
                            <option value="Water Heater">Water Heater</option>
                            <option value="Vacuum Cleaners">Vacuum Cleaners</option>
                            <option value="UPS">UPS</option>
                            <option value="Printers">Printers</option>
                            <option value="Desktop Computer System">Desktop Computer System</option>
                            <option value="Dvd Player">Dvd Player</option>
                            <option value="Home Theatre System">Home Theatre System</option>
                            <option value="Radio">Radio</option>
                            <option value="Musical Instruments">Musical Instruments</option>
                            <option value="Stereo System">Stereo System</option>
                            <option value="Play Station/Video Games">Play Station/Video Games</option>
                            <option value="Telephones">Telephones</option>
                            <option value="Lamps">Lamps</option>
                            <option value="Sports Equipment">Sports Equipment</option>
                            <option value="Antenna/Satellite Dish">Antenna/Satellite Dish</option>
                            <option value="Hair Dryers">Hair Dryers</option>
                            <option value="Decoders">Decoders</option>
                            <option value="Chandeliers">Chandeliers</option>
                            <option value="Clocks">Clocks</option>
                            <option value="Mirror">Mirror</option>
                            <option value="Art Work">Art Work</option>
                            <option value="Wall Decor">Wall Decor</option>
                            <option value="Aquarium">Aquarium</option>
                            <option value="Glass and Ceramic Decorations">Glass and Ceramic Decorations</option>
                            <option value="Sofas">Sofas</option>
                            <option value="Couches">Couches</option>
                            <option value="Side Tables">Side Tables</option>
                            <option value="Centre Tables">Centre Tables</option>
                            <option value="Bed">Bed</option>
                            <option value="Dining Tables And Chairs">Dining Tables And Chairs</option>
                            <option value="Wine Bar">Wine Bar</option>
                            <option value="Wardrobes">Wardrobes</option>
                            <option value="Cabinet">Cabinet</option>
                            <option value="Shelves">Shelves</option>
                            <option value="Shoe Racks">Shoe Racks</option>
                            <option value="Curtains, Draperies And Blinds">Curtains, Draperies And Blinds</option>
                            <option value="Rugs">Rugs</option>
                            <option value="Pillows">Pillows</option>
                            <option value="Mattress">Mattress</option>
                            <option value="Microwave">Microwave</option>
                            <option value="Blender">Blender</option>
                            <option value="Electric Cooker">Electric Cooker</option>
                            <option value="Deep Fryer">Deep Fryer</option>
                            <option value="Pressure Cooker">Pressure Cooker</option>
                            <option value="Gas Cooker">Gas Cooker</option>
                            <option value="Electric Kettle">Electric Kettle</option>
                            <option value="Dish Washer">Dish Washer</option>
                            <option value="Flasks">Flasks</option>
                            <option value="Toasters">Toasters</option>
                            <option value="Oven">Oven</option>
                            <option value="Waffle Maker">Waffle Maker</option>
                            <option value="Coffee Maker">Coffee Maker</option>
                            <option value="Food Processor">Food Processor</option>
                            <option value="Plates">Plates</option>
                            <option value="Pots And Pans">Pots And Pans</option>
                            <option value="Ceramic wares">Ceramic wares</option>
                            <option value="Cups">Cups</option>
                            <option value="Glass Wares">Glass Wares</option>
                            <option value="Cutleries (Spoon, Fork And Knives)">Cutleries (Spoon, Fork And Knives)</option>
                            <option value="Knife sets">Knife sets</option>
                            <option value="Cooking spoons">Cooking spoons</option>
                            <option value="Drums">Drums</option>
                            <option value="Trays">Trays</option>
                            <option value="Basins and bowls">Basins and bowls</option>
                            <option value="Mixers">Mixers</option>
                            <option value="Baking Equipments">Baking Equipments</option>
                            <option value="Containers">Containers</option>
                            <option value="Gas cylinders">Gas cylinders</option>
                            <option value="Camcorder">Camcorder</option>
                            <option value="Camera">Camera</option>
                            <option value="Laptop">Laptop</option>
                            <option value="Tablets">Tablets</option>
                            <option value="Mobile Phones">Mobile Phones</option>
                            <option value="Chargers">Chargers</option>
                            <option value="Power Bank">Power Bank</option>
                            <option value="External Hard Drives">External Hard Drives</option>
                            <option value="Internet Modems">Internet Modems</option>
                            <option value="Ear Phones/Head Phones">Ear Phones/Head Phones</option>
                            <option value="Portable speakers">Portable speakers</option>
                            <option value="MP3 players">MP3 players</option>
                            <option value="Portable gaming device">Portable gaming device</option>
                            <option value="Small medical devices">Small medical devices</option>
                            <option value="Jewelleries & Trinket">Jewelleries & Trinket</option>
                            <option value="Wristwatches">Wristwatches</option>
                            <option value="Art Work">Art Work</option>
                            <option value="Clothings">Clothings</option>
                            <option value="Beddings">Beddings</option>
                            <option value="Shoes">Shoes</option>
                            <option value="Bags">Bags</option>
                            <option value="Leather Box">Leather Box</option>
                            <option value="Suit cases">Suit cases</option>
                            <option value="Personal Documents">Personal Documents</option>
                            <option value="Books / CDs">Books / CDs</option>
                            <option value="Water Closet">Water Closet</option>
                            <option value="Bath Tub">Bath Tub</option>
                            <option value="Wash Hand Basins">Wash Hand Basins</option>
                            <option value="Fence">Fence</option>
                            <option value="Gate">Gate</option>
                            <option value="Wall Boundaries">Wall Boundaries</option>
                            <option value="Out Buildings">Out Buildings</option>
                            <option value="Car Port Or Any Ancillary Structure">Car Port Or Any Ancillary Structure</option>
                            <option value="Water Tank / Treatment">Water Tank / Treatment</option>
                            <option value="Sewage System">Sewage System</option>
                            <option value="Main Building">Main Building</option>
                            <option value="Boys Quarters">Boys Quarters</option>
                            <option value="Security/Gate House">Security/Gate House</option>
                            <option value="Others">Others</option>

                      </select>

                     </div>
                   </div>
                   <div class="col-lg-4">
                     <div class="form-group g-mb-20">
                       <label>
                        Quantity
                       </label>
                       <input id="bRelationship" name="houseModel.Quantity" class="form-control form-control-md rounded-0" type="text"
                         placeholder="Required" [(ngModel)]="houseModel.Quantity" required>
                     </div>
                   </div>
                   <div class="col-lg-4">
                     <div class="form-group g-mb-20" >
                       <label>
                         Value in Naira (#)
                       </label>
                       <input id="bBenefitProportion" name="houseModel.Value" class="form-control form-control-md rounded-0" type="text"
                         placeholder="Required" [(ngModel)]="houseModel.Value" required OnlyNumber="true">
                     </div>
                   </div>

                   <div class="col-lg-4" *ngIf="houseModel.Products == 'Others'">
                     <div class="form-group g-mb-20">
                       <label>
                         Kindly specify item
                       </label>
                       <input id="OtherProducts" name="houseModel.Products" class="form-control form-control-md rounded-0" type="text"
                       [(ngModel)]="OtherProducts" [attr.required] = "houseModel.Products == 'Others'"  />

                     </div>
                   </div>

                   <div class="col-lg-4">
                    <div class="form-group g-mb-20">
                      <label style="visibility: hidden;">
                        Frequency of payment </label>
                      <button (click) ="Add()" type="button" class="g-brd-main btn-block g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" > Add
                      </button>
                    </div>
                  </div>
                 </div>
                 <div class="nok-table" *ngIf="showBeneficiaryTable">
                   <table class="table table-bordered">
                     <thead>
                       <tr>
                         <th>Products </th>
                         <th>Quantity</th>
                         <th>Value</th>
                         <th></th>
                       </tr>
                     </thead>
                     <tbody>
                       <tr *ngFor="let ben of HouseHolderForm.HouseData; let i = index">

                         <td>{{ben.Products}}</td>
                         <td>{{ben.Quantity}}</td>
                         <td>{{ben.Value}}</td>
                         <td>
                           <button class="btn btn-danger btn-block" (click)="deleteBen(i)" >Delete</button>
                         </td>

                       </tr>

                     </tbody>

                   </table>
                 </div>
                 <!-- <div class="row">
                   <div class="col-lg-4">
                     <div class="row">
                       <div class="col-lg-6">
                         <div class="form-group g-mb-0">
                           <label style="visibility: hidden;">Frequency</label>
                           <button (click) ="Next()" type="button" class="g-brd-main btn-block g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" > Next
                           </button>
                         </div>

                       </div>
                       <div class="col-lg-6">
                         <div class="form-group g-mb-0">
                           <label style="visibility: hidden;">Frequency</label>
                           <button (click) ="Previous()" type="button" class="g-brd-main btn-block g-brd-danger--hover g-bg-main g-bg-danger--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" > Previous
                           </button>
                         </div>

                       </div>
                     </div>

                   </div>
                 </div> -->
                 <section class="step-seven" >
                    <div class="form-sub-title">
                      <h4>Terms</h4>
                    </div>
                     <div class="container">
                       <h4>Declaration</h4>
                       <p>I, the undersigned, do hereby declare that the above statements are true and complete.</p>

                      <div class="form-group">
                          <input type="checkbox" [(ngModel)]="termAgreed" name="termAgreed" > By checking the box , I agree with the above terms
                        </div>
                        <!-- <div class="col-lg-4">
                          <div class="row"> -->

                           <p style="text-align: justify;">An Insurance Agent who assists an applicant to complete an application or proposal form for insurance shall be deemed to have done so as the agent of the applicant” section 54(2), Insurance Act 2003</p>

                            <p style="font-size:10px; text-align: justify;">In compliance with NDPR requirements, by completing and submitting this form, you have given consent to NSIA Insurance to receive your data, including your bio data, to enable NSIA Insurance maintain effective communication, send promotional updates, contact you for research purposes and use the data in line with the policy terms.
                            </p>
                            <p style="font-size:10px; text-align: justify;">The data collection may be via hard copies or online forms, validated via JSON WEB TOKEN (JWT). The data will not be accessed by any third party without your consent and any breach of this policy can be addressed legally within the year the breach was reported.</p>

                            <div class="col-lg-4">
                              <div class="form-group g-mb-0">
                                <label style="visibility: hidden;">Frequency</label>
                                <button  type="submit" class="g-brd-main btn-block g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" > Submit
                                </button>
                              </div>

                            </div>

                          <!-- </div>

                        </div> -->
                     </div>

                   </section>
               </section>
               <!-- Next of Kin -->
              </form>
           </div>
        </div>
     </div>
  </section>


</section>
