import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms'
import * as $ from 'jquery';
import { HouseHolder, HouseHolderForm } from 'src/app/Classes/house-holder-form';
import { Utilities } from 'src/app/Helpers/utilities';
import { FormserviceService } from 'src/app/Services/formservice.service';


@Component({
  selector: 'app-house-holder-insurance',
  templateUrl: './house-holder-insurance.component.html',
  styleUrls: ['./house-holder-insurance.component.css']
})
export class HouseHolderInsuranceComponent implements OnInit {
  showBeneficiaryTable: boolean = false
  houseModel = new HouseHolder("", "", "");

  OtherProducts;
  HouseHolderForm = new HouseHolderForm("",[],"","","","","","","","","");
  step: number = 1

  termAgreed:boolean = false;
  constructor(private _formservice: FormserviceService, private _utils: Utilities) { }

  ngOnInit(): void {
    let data = JSON.parse(localStorage.getItem("allEntries"));
    for(let i in data){
     // console.log('key: ' +  i + ',  value: ' + data[i].Products);
      this.houseModel.Products = data[i].Products;
      this.houseModel.Quantity = data[i].Quantity;
      this.houseModel.Value = data[i].Value;
      console.log(this.houseModel);

      this.HouseHolderForm.HouseData.push(this.houseModel);
      this.houseModel = new HouseHolder("", "", "");

    }

    if (this.HouseHolderForm.HouseData.length > 0) {
      this.showBeneficiaryTable = true;
    }else{
      this.showBeneficiaryTable = false;
    }

    let customername = JSON.parse(localStorage.getItem("customername"));
    let address = JSON.parse(localStorage.getItem("address"));
    let telephone = JSON.parse(localStorage.getItem("telephone"));
    let email = JSON.parse(localStorage.getItem("email"));

    this.HouseHolderForm.Name = customername;
    this.HouseHolderForm.propertyAddress = address;
    this.HouseHolderForm.Phonenumber = telephone;
    this.HouseHolderForm.EmailAddress = email;
  }

  OnPersonalSubmit(myForm: NgForm) {
    if (myForm.valid) {
      this.step = 2
    }
  }

  selected(files: FileList)
  {
    let theFile = files[0];
    this._utils.ResizeImage(theFile, 128, 128)
    .subscribe(
      (file : File) =>{
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event) => {
          this.HouseHolderForm.src = reader.result.toString()
          this.HouseHolderForm.filenameId = file.name
          this.HouseHolderForm.filetypeId = file.type
          this.HouseHolderForm.filestringId =this.HouseHolderForm.src.substr(this.HouseHolderForm.src.indexOf(',')+ 1)
        }
      }
    )

  }


  UserPrevious() {
    this.step = 1
  }
  usagePrevious() {
    this.step = 2
  }

  Add(){

    if (this.houseModel.Quantity == "") {
      alert("Quantity is required");
      return;
    }
    if (this.houseModel.Products == "") {
     alert( "Products is required");
      return;
    }
    if (this.houseModel.Value == "") {
     alert( "Value is required");
      return;
    }

    if(this.houseModel.Products =="Others"){
      this.houseModel.Products = $("#OtherProducts").val().toString();
    }

    localStorage.setItem("customername", JSON.stringify(this.HouseHolderForm.Name));
    localStorage.setItem("address", JSON.stringify(this.HouseHolderForm.propertyAddress));
    localStorage.setItem("telephone", JSON.stringify(this.HouseHolderForm.Phonenumber));
    localStorage.setItem("email", JSON.stringify(this.HouseHolderForm.EmailAddress));

    this.HouseHolderForm.HouseData.push(this.houseModel);

    var existingEntries = JSON.parse(localStorage.getItem("allEntries"));
     if (existingEntries == null)
         existingEntries = [];

     localStorage.setItem("detail", JSON.stringify(this.houseModel));
     existingEntries.push(this.houseModel);
     localStorage.setItem("allEntries", JSON.stringify(existingEntries));

    this.houseModel = new HouseHolder("", "", "");

    if (this.HouseHolderForm.HouseData.length > 0) {
      this.showBeneficiaryTable = true;
    }



  }

  deleteBen(i){
    this.HouseHolderForm.HouseData.splice(i, 1);
    if (this.HouseHolderForm.HouseData.length > 0) {
      this.showBeneficiaryTable = true;
    }else{
      this.showBeneficiaryTable = false;
    }
  }

  submitForm(myForm: NgForm){

    console.log(this.HouseHolderForm);

    var email = this.HouseHolderForm.EmailAddress;
    var regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if(!regex.test(email)) {
     alert( "Your email is not correct");
      this._formservice.stopLoading();
      return;
    } if (!this.termAgreed) {
     alert( "You need to agree to the terms and agreement");
      this._formservice.stopLoading();
      return;
    }
    var telLength = $('#telephone_number').val().toString().length;

    if(telLength < 10){
     alert( "Your telephone number is not correct");
      this._formservice.stopLoading();
      return;
    }
    else{

    this._formservice.startLoading();
    this._formservice.sendHouseHolderForm(this.HouseHolderForm)
      .subscribe(
        (data) => {
          if (data.statusCode == "00") {
            alert("Your form is submitted");
            myForm.resetForm();

          } else {
           alert( data.statusMessage)
          }
          this._formservice.stopLoading();
        },
        (error) => {
          console.log(error);
         alert("internal server error occurred");
          this._formservice.stopLoading();
        }
      )

  }
}

}
