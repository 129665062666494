
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;">THE VALUE OF INSURANCE TO SOCIETY</p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/insurance_value.jpg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>Insurance is of value to society in many ways but the general awareness of the contributions of insurance to the overall economy and society is low. Policymakers, policyholders and the general public only start valuing insurance once a loss has been incurred and compensation is expected from insurance.
                 </p>
                 <p></p>


              </div>
           </div>


          <div class="row justify-content-between">


              <div class="col-md-12">

                <p>The most important contribution of insurance to society is the provision of risk sharing, risk transfer abilities and loss prevention measures. The “peace of mind” it provides is an unconscious fact, which cannot be measured, unlike assets under management and insurers’ contribution to GDP.</p>
                <p></p>

                 <p>This article highlights some areas of insurance that demonstrate its social role.
                 </p>
                 <p></p>

                 <p><strong>How does insurance help consumers, companies and society at large?</strong> </p>

                <p></p>

                <p>Private individuals purchase insurance products to avoid being confronted with the financial consequences of incurring damage resulting from a certain event or when they want to build up a financial reserve for a certain project. </p>

                <p></p>
                <p>The most common non-life product is <strong>Third Party Liability insurance</strong>. If an individual causes an accident, he/she is obliged to pay for the damages which the non-liable third party has incurred. Depending on circumstances of the accident, the individual would need to pay a high amount of money (ranging from hospitals bills to loss of future income, in addition to the material damage). By taking out Third Party Liability insurance, the individual ensures that the third party’s damages are paid, while his/her financial situation is not heavily impacted.</p>

                <p>
                  When an individual takes out life or pension insurance, he/she saves money on a regular basis in order to build a capital stock for a certain aim, e.g. a money reserve for a certain project/risk or increased pension benefits.
                </p>
               <p></p>
               <p><strong>An economic driver and safety net for commercial entities</strong></p>
               <p></p>
               <p>Similar to individuals, commercial entities can be exposed to claims for damages following an unfortunate event. Manufacturers, for example, can be made responsible for a defective product that caused harm to an individual. If a commercial entity were unable to transfer this risk to an insurer by taking out insurance, the company would need to reserve capital for potential liability claims. The company would, therefore, have less capital available to invest in new technologies and product innovation. In this way, insurance supports economic growth by taking on risks, which normally the commercial entity would need to bear.</p>
               <p></p>
               <p>In addition, they support national governments in funding, for example, infrastructure and other projects benefiting the economy and society, in particular by investing in government bonds.</p>
               <p></p>
               <p>Insurance serves as a buffer in the modern economy. It allows filtering out sudden surges in financial needs linked to a disaster hitting many insured players that might otherwise be pushed into bankruptcy. Insurance allows forward planning with more certainty, avoiding or mitigating specific risks. </p>
               <p></p>
               <p>The possibility of transferring risk to the insurer provides private individuals as well as commercial entities with a safety net, which allows them to take calculated risks in their entrepreneurial decisions. </p>


              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>
