
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;"><strong>WORKOUT FITNESS TIPS POST LOCKDOWN</strong></p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/workout_fitness.jpg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>Most of us can relate to the decrease in our physical and fitness activities during this time of restricted movements and partial closure of businesses. Undeniably, there has been a drop in the physical activities over this period. Closing of gyms had a huge role to play in this. It has forced us to jog around our homes and carry out our daily exercises in confined spaces. This has demotivated many from practicing regular exercises. </p>
                 <p></p>

              </div>
           </div>

          <div class="row justify-content-between">


              <div class="col-md-12">
                <p>Now that some gyms are reopening, motivation is back! Here are few tips on how to safely resume your routines, to avoid the risk of severe body pains. It is important to be patient and gradually work your way to continuing your normal fitness routines. </p>
                <p></p>
               <p><strong>1. TAKE GRADUAL STEPS  </strong><br/>It is normal to feel very enthusiastic to work out after a long break of being deprived but it is important to note that you cannot go from zero to hundred. In epidemiological studies of sports-related injuries, it was discovered that there is a higher risk of harm to the body when people hastily increase the intensity of their body exercises. We should take care not to put our bodies under more pressure than it can handle.
               </p>

               <p></p>
                <p><strong>2. WARM UP </strong><br/>
                  Before intense body exercise, it is important to warm up the body. Do warm ups that focus on the joints and muscles, especially after a long break of no exercise. Joint and muscle warm up exercises consist of joint rotations, walking, jogging, skipping, upper and lower body mobility.
                </p>
              <p></p>
                 <p><strong>3. TAKE A STEP BACK </strong> <br/>
                  It is important to give yourself a 48-hour break between sessions. Three days a week for the first two weeks is fine for a start as it will give all of your body tissues time to adapt to intense body exercises.
                 </p>

                 <p></p>
                 <p><strong>4. EAT AND SLEEP WELL</strong> <br/>
                  Sleeping after exercising is very beneficial as it can repair your muscles and aid quick recovery. When you sleep, your pituitary glands release growth hormones to repair and build the body tissues. It is important to get 7-9 hours of sleep. Furthermore, there are no doubts that a couple of pounds may have been gained during the lockdown; always eating a responsible and healthy diet is the best way to live a healthy life.
                 </p>
                 <p></p>

                 <p><strong>5. SEEK PROFESSIONAL HELP IF NEED BE </strong> <br/>
                  If you feel unbearable pains after exercising, it is important that you do not hesitate to seek professional help to get you back on track.
                 </p>
                 <p></p>

              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>