<div class="g-min-height-300 g-flex-centered g-bg-img-hero g-bg-pos-top-center g-bg-size-cover g-pt-80"
  style="background-image: url(assets/img/banners/Inset_Marine.jpg);">
  <div class="container g-pos-rel g-z-index-1">
    <h2 class="h1 text-uppercase g-color-primary g-font-size-40--lg mb-0">Marine Insurance</h2>
    <span class="d-block  g-color-white g-font-weight-1000 g-font-size-20 mb-4">We are here to support you.</span>
  </div>
</div>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item g-color-primary">
        <span>Marine Insurance</span>
      </li>

      <li class="list-inline-item ml-auto">
       <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" routerLink="/getquote">Get quote</a>
      </li>
    </ul>
  </div>
</section>


<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
    <div class="col-lg-9 g-mb-30">
      <p>
        A greater part of global cargo are transported via waterways, but this doesn’t mean that marine transportation
        is perfectly risk-free. Our Marine Insurance product is designed to protect you against financial loss in the
        event of loss of shipped cargo or shipping equipment.
      </p>
      <p></p>
      <br />
      <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
        <!-- <h3 class="mb-0"><strong><p>Marine Insurance</p></strong></h3><br/> -->

        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color: white">
            Marine Cargo Insurance
          </h5>
        </div>
        <p></p>

        <div class="row justify-content-between">
          <!-- About Image -->
          <div class="col-md-3 col-lg-4">
            <img class="img-fluid" src="assets/img/banners/Marine_1.png" alt="Image Description">
          </div>
          <!-- End About Image -->

          <div class="col-md-4 col-lg-8">
            <p></p>
            <p>Marine Cargo Insurance Indemnifies the Insured against financial liability suffered as a result of loss
              or damage to goods being transported from the point of origin to final destination either by sea or air.
            </p>
          </div>
        </div>
        <p></p>
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color: white">
            Marine Hull & Machinery
          </h5>
        </div>
        <p></p>

        <div class="row justify-content-between">
          <!-- About Image -->
          <div class="col-md-3 col-lg-4">
            <img class="img-fluid" src="assets/img/banners/hullcrop.jpg" alt="Image Description">
          </div>
          <!-- End About Image -->

          <div class="col-md-4 col-lg-8">
            <p></p>
            <p>Marine Hull & Machinery insurance reimburses the insured against financial loss suffered as a result of
              physical damage or loss to the hull and machinery which constitutes the ship.</p>
          </div>
        </div>

        <p></p>

      </div>
    </div>


    <!-- Sidebar -->
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <app-sidebar></app-sidebar>
      <!-- End Fast Facts -->
    </div>
    <!-- End Sidebar -->
  </div>
</div>
