
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;">BENEFITS OF A TERM ASSURANCE PLAN</p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/term_assurance.jpg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>
                  People across society are slowly realizing the necessity of having a life insurance cover. As life increases its pace and uncertainties rise by the day, every family needs a life cover that allows them to deal with the loss of a loved one, at least financially. A <strong>Term Assurance Plan</strong> is the simplest of all life insurance policies that provides death benefits if the insured passes away during the term of the plan. If you want to live peacefully without worrying about the future and financial stability of your family (in your absence), a term assurance plan is what you must buy.
                 </p>
                 <p></p>

              </div>
           </div>

          <div class="row justify-content-between">


              <div class="col-md-12">
                <p>

                </p>
                <p>Below are 3 benefits to having a Term Assurance policy:</p>
                <p></p>
               <p><strong>1.	Securing the future:</strong> Every earning member of the family has some responsibilities and obligations towards his/her spouse, children and parents. Death can effectively stop you from meeting those obligations. The payout from a term assurance plan will ensure that your dependants can have a comfortable life without facing any financial hardships.
               </p>

               <p></p>
                <p><strong>2.	Paying off debts:</strong> Almost every individual has some form of loan or the other. It can be a car loan, home loan, personal loan or education loan. The proceeds from the insurance policy pay off your loans along with interest and ensure that the burden of these borrowings does not fall upon your family.</p>
                <p></p>
                <p><strong>3.	Riders</strong> Most term assurance plans offer some additional benefits, known as riders, along with death benefits to the insured. You can choose riders like accidental coverage, critical illness coverage, etc. at a little extra premium cost. These riders ensure that you get the maximum benefits of life cover without burning a hole in your pocket.</p>
                <p></p>


          <p>An alternative to term assurance is Permanent Life insurance. A common type of permanent coverage is the Whole Life insurance. The biggest difference between term and permanent life insurance is that term insurance covers you for a predetermined number of years, while a permanent life insurance policy covers you for the rest of your life. When it comes to term life insurance, you decide how long you anticipate needing the coverage — until the mortgage is paid off, until the children graduate from the university or until you retire — and select a term that corresponds to that length of time.</p>
          <p></p>
          <p>To learn more about the various types of life insurance products we offer, kindly visit <a href="https://nsiainsurance.com/lifeinsurance">https://nsiainsurance.com/lifeinsurance</a> or call customer service on 09048418896.</p>

        </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>