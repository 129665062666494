<div class="container g-pt-100 g-pb-70">
  <div class="row">
    <div class="col-lg-12 g-mb-30">
      <section class="g-bg-gray-light-v5 g-py-20">
        <form novalidate (ngSubmit)="submitForm(beneficiaryForm)" #beneficiaryForm="ngForm" novalidate>
          <div class="container g-py-100 pad-top-50" style="margin-top:-50px">
            <div class="u-shadow-v19 g-brd-around g-brd-gray-light-v4  rounded mx-auto g-pa-30 g-pa-50--md">

              <div class="mb-6">
                <h2 class="mb-4 main-heading" style="font-size:30px;">
                  CONTRACTORS/ERECTION ALL RISK INSURANCE PROPOSAL FORM
                </h2>

                <section class="step-two" >
                  <div class="form-sub-title">
                    <h4>Personal Information</h4>
                  </div>
                  <div class="row">

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>Name of Proposer</label>
                        <input id="NameOfProposal" name="NameOfProposal" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.NameOfProposal" required>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Address of Proposer
                        </label>
                        <input id="AddressOfProposal" name="AddressOfProposal" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.AddressOfProposal" required >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          RC Number
                        </label>
                        <input id="Rcnumber" name="Rcnumber" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.Rcnumber"  >
                      </div>
                    </div>


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                         ID Number
                        </label>
                        <input id="idnumber" name="idnumber" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.idnumber"  >
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Mobile No
                        </label>
                        <input id="Telephone" name="Telephone" class="form-control form-control-md rounded-0" type="number"
                         [(ngModel)]="proposalModel.Telephone" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==11) return false; return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57));" >

                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Alternate Mobile No
                        </label>
                        <input id="AltTelephone" name="AltTelephone" class="form-control form-control-md rounded-0" type="number"
                         [(ngModel)]="proposalModel.AltTelephone" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==11) return false; return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57));" >

                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                         <label>
                          Start Date  (Period of Insurance)
                         </label>
                         <input id="StartDate" name="StartDate" class="form-control form-control-md rounded-0" type="date"
                          placeholder="yyyy-mm-dd" [(ngModel)]="proposalModel.StartDate" >

                      </div>
                   </div>
                   <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                         <label>
                          End Date (Period of Insurance)
                         </label>
                         <input id="EndDate" name="EndDate" class="form-control form-control-md rounded-0" type="date"
                          placeholder="yyyy-mm-dd" [(ngModel)]="proposalModel.EndDate"  >
                      </div>
                   </div>



                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          E- mail address
                        </label>
                        <input id="EmailAddress" name="EmailAddress" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.EmailAddress" required type="text" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" >

                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                         <label>
                         Date Of Incorporation
                         </label>
                         <input id="dateofincorporation" name="dateofincorporation" class="form-control form-control-md rounded-0" type="date"
                          placeholder="yyyy-mm-dd" [(ngModel)]="proposalModel.dateofincorporation" >

                      </div>
                   </div>

                  </div>
                </section>

                <section class="step-three">
                  <div class="form-sub-title">
                    <h4>RISK DETAILS:</h4>
                  </div>
                  <p>PLEASE STATE NAME AND ADDRESS OF THE FOLLOWING:</p>
                  <div class="row">

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          The Principal

                        </label>
                        <input id="theprincipal" name="theprincipal" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.theprincipal">
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Sub-Contractor
                        </label>
                        <input id="SubContractor" name="SubContractor" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.SubContractor">

                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>

                          Location of contract site
                        </label>
                        <input id="locationofcontractsite" name="locationofcontractsite" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.locationofcontractsite">

                      </div>
                    </div>



                    <div class="col-lg-12">
                      <div class="form-group g-mb-20">
                        <label>

                          Description of contract
                        </label>
                        <input id="decriptionofcontract" name="decriptionofcontract" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.decriptionofcontract">

                      </div>
                    </div>
                  </div>

                    <div class="row">
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Does the construction involve any of the following:
                        </label>
                        <select name="proposalModel.Tunnelling" id="proposalModel.Tunnelling" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.Tunnelling">
                            <option >Select your option</option>
                            <option value="Tunnelling">Tunnelling</option>
                            <option value="Demolition">Demolition</option>
                            <option value="Exposure to shipping/aircraft"> Exposure to shipping/aircraft  </option>
                            <option value="Pollution exposure">Pollution exposure</option>
                            <option value="Crossing Roads">Crossing Roads</option>
                            <option value="Underwateroperations">Underwateroperations</option>
                            <option value="Shoring or underpinning">Shoring or underpinning</option>

                        </select>
                      </div>
                    </div>


                  </div>
                </section>

                <section class="step-three">
                  <div class="form-sub-title">
                    <h4>Please Give Details:</h4>
                  </div>

                  <div class="row">


                    <!-- <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          WHAT IS THE HEIGHT AND DEPTH BEING WORKED TO
                        </label>
                        <input id="HEIGHTANDDEPTH" name="HEIGHTANDDEPTH" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.HEIGHTANDDEPTH">
                      </div>
                    </div>
                     -->
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label style="text-transform:lowercase;">
                          IS THE CONTRACT SITE PRONE/EXPOSED TO HAZARDS LIKE FLOOD, TEMPEST, STORM, EARTHQUAKE, ETC?  IF YES, KINDLY SPECIFY AND STATE WHAT PRECAUTIONS HAVE BEEN TAKEN:
                        </label>
                        <input id="CONTRACTSITEPRONE" name="CONTRACTSITEPRONE" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.CONTRACTSITEPRONE">

                      </div>
                    </div>

                    <!-- <div class="col-lg-6" >
                      <div class="form-group g-mb-20">
                        <label>
                          DOES THE CONTRACTOR HAVE EXPERIENCE IN THIS TYPE OF CONTRACT? (YES OR NO):  <span>IF YES, KINDLY SPECIFY AND STATE WHAT PRECAUTIONS HAVE BEEN TAKEN: </span>
                        </label>
                        <input id="CONTRACTORHAVEEXPERIENCE" name="CONTRACTORHAVEEXPERIENCE" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.CONTRACTORHAVEEXPERIENCE" >
                      </div>
                    </div> -->

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label style="text-transform:lowercase;">
                          DOES THE CONTRACTOR HAVE EXPERIENCE IN THIS TYPE OF CONTRACT? (YES OR NO): IF YES, GIVE DETAILS OF SIMILAR PROJECTS CARRIED OUT BY THE CONTRACTOR PREVIOUSLY:
                        </label>
                        <input id="CONTRACTOR_HAVE_EXPERIENCE" name="CONTRACTOR_HAVE_EXPERIENCE" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.CONTRACTOR_HAVE_EXPERIENCE" >
                      </div>
                    </div>


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                         <label style="text-transform:lowercase;">
                          CONSTRUCTION PERIOD: (From)
                         </label>
                         <input id="constructionPeriodStart" name="constructionPeriodStart" class="form-control form-control-md rounded-0" type="date"
                          placeholder="yyyy-mm-dd" [(ngModel)]="proposalModel.constructionPeriodStart" >

                      </div>
                   </div>
                   <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                         <label style="text-transform:lowercase;">
                          CONSTRUCTION PERIOD: (To)
                         </label>
                         <input id="constructionPeriodEnd" name="constructionPeriodEnd" class="form-control form-control-md rounded-0" type="date"
                          placeholder="yyyy-mm-dd" [(ngModel)]="proposalModel.constructionPeriodEnd"  >
                      </div>
                   </div>


                   <div class="col-lg-6">
                    <div class="form-group g-mb-20">
                       <label style="text-transform:lowercase;">
                        MAINTENANCE PERIOD: (From)
                       </label>
                       <input id="maintenancePeriodStart" name="maintenancePeriodStart" class="form-control form-control-md rounded-0" type="date"
                        placeholder="yyyy-mm-dd" [(ngModel)]="proposalModel.maintenancePeriodStart" >

                    </div>
                 </div>
                 <div class="col-lg-6">
                    <div class="form-group g-mb-20">
                       <label style="text-transform:lowercase;">
                        MAINTENANCE PERIOD:(To)
                       </label>
                       <input id="maintenancePeriodEnd" name="maintenancePeriodEnd" class="form-control form-control-md rounded-0" type="date"
                        placeholder="yyyy-mm-dd" [(ngModel)]="proposalModel.maintenancePeriodEnd"  >
                    </div>
                 </div>


                  </div>
                </section>


                <section class="step-three">
                  <div class="form-sub-title">
                    <h4>SUMS TO BE INSURED: </h4>
                  </div>

                  <div class="row">

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Permanent Works
                        </label>
                        <input id="Permanentworks" name="Permanentworks" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.Permanentworks" >
                      </div>
                    </div>


                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Temporary Works
                        </label>
                        <br/>
                        <input id="TemporaryWorks" name="TemporaryWorks" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.TemporaryWorks" >
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Removal of Debris
                        </label>
                        <br/>
                        <input id=" RemovalofDebris" name="RemovalofDebris" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.RemovalofDebris" >
                      </div>
                    </div>


                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Architects, Surveyors fees
                        </label>
                        <br/>
                        <input id="Architects" name="Architects" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.Architects" >
                      </div>
                    </div>


                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Constructional Plant & Equipment
                        </label>
                        <br/>
                        <input id="ConstructionalPlant" name="ConstructionalPlant" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.ConstructionalPlant" >
                      </div>
                    </div>


                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Existing Buildings
                        </label>
                        <br/>
                        <input id="ExistingBuilding" name="ExistingBuilding" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.ExistingBuilding" >
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="form-group g-mb-20">
                        <label style="text-transform:lowercase;">
                          DO YOU REQUIRE COVERAGE FOR THIRD PARTY LIABILITY?  (YES or NO): IF YES PLEASE STATE LIMIT OF LIABILITY:
                        </label>
                        <br/>
                        <input id="COVERAGE_FOR_THIRD_PARTY_LIABILITY" name="COVERAGE_FOR_THIRD_PARTY_LIABILITY" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.COVERAGE_FOR_THIRD_PARTY_LIABILITY">
                      </div>
                    </div>



                    <div class="col-lg-12">
                      <div class="form-group g-mb-20">
                        <label style="text-transform:lowercase;">
                          PLEASE STATE THE MINIMUM DISTANCE OF NEIGHBORING THIRD PARTY PROPERTY:
                        </label>
                        <br/>
                        <input id="MinimumDistanceOfNeigboring" name="MinimumDistanceOfNeigboring" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.MinimumDistanceOfNeigboring">
                      </div>
                    </div>


                    <!-- <div class="col-lg-12">
                      <div class="form-group g-mb-20">
                        <label>
                          IS THERE ANY POSSIBILITY OF A THIRD PARTY CATASTROPHE RESULTING FROM FIRE , EXPLOSION , FLOOD AND COLLAPSE?
                        </label>
                        <br/>
                        <input id="POSSIBILITY_OF_A_THIRD_CATASTROPHE" name="POSSIBILITY_OF_A_THIRD_CATASTROPHE" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.POSSIBILITY_OF_A_THIRD_CATASTROPHE">
                      </div>
                    </div> -->

                  </div>
                </section>


                <section class="step-three">
                  <!-- <div class="form-sub-title">
                    <h5>ARE THE INSURED’S (CONTRACTORS, SUB-CONTRACTORS, PRINCIPLE) TO BE CONSIDERED AS THIRD PARTIES AMONGST EACH OTHER (CROSS LIABILITY); </h5>
                  </div> -->

                  <div class="row">
<!--
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          WITH REGARDS TO PROPERTY DAMAGE?
                        </label>
                        <select name="proposalModel.WITH_REGARDS_TO_PROPERTY_DAMAGE" id="proposalModel.WITH_REGARDS_TO_PROPERTY_DAMAGE" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.WITH_REGARDS_TO_PROPERTY_DAMAGE">
                            <option >Select your option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                      </div>
                    </div> -->


                    <!-- <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          WITH REGARDS TO BODILY INJURY?
                        </label>
                        <br/>
                        <select name="proposalModel.WITH_REGARDS_TO_BODILY_INJURY" id="proposalModel.WITH_REGARDS_TO_BODILY_INJURY" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.WITH_REGARDS_TO_BODILY_INJURY">
                            <option >Select your option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                      </div>
                    </div> -->

                  </div>
                </section>


                <section class="step-three">


                  <div class="row">

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                      Does the proposer have an existing a third party liability policy which also covers the activities for which the present insurances is proposed?
                        </label>
                        <select name="proposalModel.existingathirdparty" id="proposalModel.existingathirdparty" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.existingathirdparty">
                            <option >Select your option</option>
                            <option value="FOR PROPERTY DAMAGE">FOR PROPERTY DAMAGE</option>
                            <option value="FOR BODILY INJURY">FOR BODILY INJURY</option>
                            <option value="FOR COMBINED SINGLE LIMIT">FOR COMBINED SINGLE LIMIT</option>
                        </select>
                      </div>
                    </div>


                    <!-- <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          FOR BODILY INJURY?
                        </label>
                        <br/>
                        <select name="proposalModel.FOR_BODILY_INJURY" id="proposalModel.FOR_BODILY_INJURY" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.FOR_BODILY_INJURY">
                            <option >Select your option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          FOR COMBINED SINGLE LIMIT?
                        </label>
                        <br/>
                        <select name="proposalModel.FOR_COMBINED_SINGLE_LIMIT" id="proposalModel.FOR_COMBINED_SINGLE_LIMIT" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.FOR_COMBINED_SINGLE_LIMIT">
                            <option >Select your option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                      </div>
                    </div> -->



                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label style="text-transform:lowercase;">
                          GIVE DETAILS OF THE CLAIMS EXPERIENCE OF THE CONTRACTOR:
                        </label>
                        <br/>
                        <input id="CLAIMS_EXPERIENCE" name="CLAIMS_EXPERIENCE" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.CLAIMS_EXPERIENCE" >
                      </div>
                    </div>


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label  style="text-transform:lowercase;">
                          HAS ANY INSURER DECLINED TO RENEW OR CANCELLED ANY POLICY DUE TO A BREACH IN TERMS AND CONDITIONS CONTAINED THEREIN?
IF YES, PLEASE GIVE DETAILS:

                        </label>
                        <br/>
                        <input id="INSURER_DECLINED_TO_RENEW" name="INSURER_DECLINED_TO_RENEW" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.INSURER_DECLINED_TO_RENEW" >
                      </div>
                    </div>


                    <!-- <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label  style="text-transform:lowercase;">
                          HAVE YOU OR ANY OF YOUR DIRECTORS OR PARTNERS EVER BEEN CHARGED WITH A CRIMINAL OFFENCE ?  IF YES, PLEASE GIVE DETAILS:

                        </label>
                        <br/>
                        <input id="DIRECTORS_OR_PARTNERS_EVER_BEEN" name="DIRECTORS_OR_PARTNERS_EVER_BEEN" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.DIRECTORS_OR_PARTNERS_EVER_BEEN" >
                      </div>
                    </div> -->


                    <!-- <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label style="text-transform:lowercase;">
                          HAS ANY PARTNER, PRINCIPAL OR DIRECTOR BEEN INVOLVED IN ANY OTHER BUSINESS ENTITIES OR BEEN ENGAGED IN SIMILAR ACTIVITIES TO THE PROPOSED INSURED IN THE LAST FIVE YEARS? IF YES, PLEASE PROVIDE DETAILS;
                        </label>
                        <br/>
                        <input id="DIRECTOR_BEEN_INVOLVED" name="DIRECTOR_BEEN_INVOLVED" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.DIRECTOR_BEEN_INVOLVED" >
                      </div>
                    </div> -->


                    <!-- <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label style="text-transform:lowercase;">
                          DATE FROM WHICH INSURANCE IS REQUIRED:
                        </label>
                        <br/>
                        <input id="DATE_FROM_WHICH_INSURANCE_IS_REQUIRED" name="DATE_FROM_WHICH_INSURANCE_IS_REQUIRED" class="form-control form-control-md rounded-0" type="date"
                        [(ngModel)]="proposalModel.DATE_FROM_WHICH_INSURANCE_IS_REQUIRED" >
                      </div>
                    </div> -->

                  </div>
                  <p style="color:red;"><i>Failure to disclose material facts could result in your policy being invalidated.  Material facts are those facts, which might influence the acceptance or assessment of your proposal.  If you are in any doubt as to whether a fact is material you should disclose it.</i></p>
                </section>

               <section class="step-seven" >

                 <div class="container">
                   <h4>Declaration</h4>
                   <p>I/We hereby declare that to the best of my/our knowledge and belief the information herein provided is correct and complete.</p>

                   <!-- <div class="form-group">
                    <input type="checkbox" [(ngModel)]="termAgreed" name="termAgreed" > By checking the box , I agree with the above terms
                  </div> -->

                      <p style="font-size:10px; text-align: justify;">In compliance with NDPR requirements, by completing and submitting this form, you have given consent to NSIA Insurance to receive your data, including your bio data, to enable NSIA Insurance maintain effective communication, send promotional updates, contact you for research purposes and use the data in line with the policy terms.
                      </p>
                      <p style="font-size:10px; text-align: justify;">The data collection may be via hard copies or online forms, validated via JSON WEB TOKEN (JWT). The data will not be accessed by any third party without your consent and any breach of this policy can be addressed legally within the year the breach was reported.</p>

                      <div class="col-lg-4">
                        <div class="form-group g-mb-0">
                          <label style="visibility: hidden;">Frequency</label>
                          <button  type="submit" onclick="return confirm('Are you sure you want to submit this information?')" class="g-brd-main btn-block g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" > Submit
                          </button>
                        </div>

                      </div>

                 </div>

               </section>

              </div>


            </div>
          </div>
        </form>
      </section>
    </div>

    <!-- End Sidebar -->
  </div>
</div>
