<div class="g-min-height-300 g-flex-centered g-bg-img-hero g-bg-pos-top-center g-bg-size-cover g-pt-80"
  style="background-image: url(assets/img/sliders/auto.jpg);">
  <div class="container g-pos-rel g-z-index-1">
    <h2 class="h1 text-uppercase g-color-primary g-font-size-40--lg mb-0">Motor Insurance</h2>
    <span class="d-block  g-color-white g-font-weight-1000 g-font-size-20 mb-4">We are here to support you.</span>
  </div>
</div>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item g-color-primary">
        <span>Motor Insurance</span>
      </li>

      <li class="list-inline-item ml-auto">
        <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
          routerLink="/buymotor">Buy Motor Insurance</a>
      </li>
    </ul>
  </div>
</section>


<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">

    <div class="col-lg-9 g-mb-30">
        <p>Getting to your destination with your vehicle in one piece isn’t always a certainty when you don’t know how well other road users drive.
          Car insurance protects your investment and keeps you moving so you can get on with life.
        </p>
        <p></p>
        <i>Requesting a quote is easy. Just click on the get a quote button or Call us now on 01-280-5378-9.</i>
        <p></p>
        <br/>
      <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
        <!-- <h3 class="mb-0"><strong><p>Motor Insurance</p></strong></h3><br/> -->



        <!-- <p>Car accidents happen even to the safest drivers. Car repair is costly, and in a total loss, you can be facing
          financial disaster. Car insurance protects your investment and keeps you moving so you can get on with life.
        </p>
        <p></p> -->
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
            <h5 style="color: white">Motor Third Party Insurance</h5>
        </div>
        <p></p>
        <p>Third Party Motor Insurance is one of the compulsory insurance covers in the country, made mandatory by the Insurance Act of 2003. It is the minimum insurance cover that every car user and/or owner must possess. Legally, you are not expected to drive any vehicle on any public road without insurance. The policy provides indemnity to Third Parties for death, bodily injury and  property damage as a result of accident with the insured’s automobile with a Limit of N1,000,000.00.</p>
        <p></p>

        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
            <strong> <h5 style="color: white">Comprehensive Motor Insurance</h5></strong>
        </div>
        <p></p>
        <p>Comprehensive motor insurance policy offers protection to the Policyholder against any accidental loss or damage to the vehicle, risk of fire, theft in addition to the cover provided under the Third Party Only.</p>


      </div>
    </div>


    <!-- Sidebar -->
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <app-sidebar></app-sidebar>
      <!-- End Fast Facts -->
    </div>
    <!-- End Sidebar -->
  </div>
</div>
