
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;"><strong>HOLIDAY FIRE SAFETY TIPS
                </strong></p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/dec/Fire.jpeg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>We know you’re ready for the festivities with your holiday spirit on full blast but before you begin celebrations, take a few minutes to help ensure a safe season.</p>
                 <p></p>

              </div>
           </div>

          <div class="row justify-content-between">


              <div class="col-md-12">
                <p></p>

                  <p><strong>Outdoor lighting</strong><br/>
                    Does your holiday decoration display light up the whole neighbourhood? Make sure you follow all the necessary lighting precautions:

                  </p>
                  <ul>
                     <li>
                                     •	Replace old or frayed wired lights with new energy-saving lights.</li>

                                     <li>•	When replacing bulbs, turn off the main electricity supply to the outdoor lighting.</li>

                                     <li>•	Connect outdoor wires to a grounded, weather-protected outlet.</li>

                                     <li>•	Use extension cords safely and avoid overloading. </li>
                                   </ul>
                  <p></p>
                 <p><strong>Indoor lighting</strong> <br/>
                  When it comes to indoor lighting, similar rules apply. Use certified, high-quality lights and replace frayed wires with new ones to prevent a fire. Always unplug lights before going to bed or when leaving your home, or set them on a timer.
                 </p>

                 <p></p>
                 <p><strong>Cooking and baking </strong> <br/>
                  No holiday celebration would be complete without a feast, but be sure to take precautions against kitchen fires when you’re cooking and baking. That includes keeping children and flammable items such as grocery bags and kitchen towels away from the stove and oven. Clean up greasy spills as you go to remove another fire hazard.
                 </p>
                 <p></p>

                 <p><strong>Fireworks </strong> <br/>
                  If you will be setting off fireworks at home, choose a location away from buildings and trees. Be sure your spectators, including children and pets, stay at a safe distance. Keep a supply of water or fire extinguishers at hand.
                 </p>
                 <p><strong>Hosting a party</strong></p>

                 <p>If you are hosting a party, you are responsible for your guests’ safety and what could happen if your guests leave intoxicated. Remember these simple tips to celebrate safely:
                 </p>
                   We hope this article has been helpful. Have a wonderful celebration!
                   <ul>
                     <li>  •	Stay sober so that you can comfortably control any potential issues.</li>
                     <li>  •	If you are serving alcohol, have plenty of food and non-alcoholic drinks available.</li>
                      <li> •	Plan for potential overnight guests and have taxi numbers handy.</li>
                     <li>  •	If you are not comfortable with your guests driving, don’t give them the option to refuse your help.</li>

                     </ul>

<p></p>





              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>