import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-travel-safety-tips',
  templateUrl: './travel-safety-tips.component.html',
  styleUrls: ['./travel-safety-tips.component.css']
})
export class TravelSafetyTipsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
