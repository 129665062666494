import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { BoardService } from 'src/app/board/board.service';
import { Board } from 'src/app/board/board';
import { ToastrService } from 'ngx-toastr';
declare var window: any;

@Component({
  selector: 'app-accessboard',
  templateUrl: './accessboard.component.html',
  styleUrls: ['./accessboard.component.css']
})
export class AccessboardComponent implements OnInit {
  p: number = 1;
  Board: Board[];
  hideWhenNoStudent: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;
  constructor(public toastr: ToastrService, public boardService: BoardService) {

  }
  ngOnInit(): void {
    this.dataState();
    let s = this.boardService.GetStudentsList();
    s.snapshotChanges().subscribe(data => {
      this.Board = [];
      data.forEach(item => {
        let a = item.payload.toJSON();
        a['$key'] = item.key;
        this.Board.push(a as Board);
      })
    })
  }

  dataState() {
    this.boardService.GetStudentsList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if (data.length <= 0) {
        this.hideWhenNoStudent = false;
        this.noData = true;
      } else {
        this.hideWhenNoStudent = true;
        this.noData = false;
      }
    })
  }



  deletee(board) {
    this.boardService.DeleteStudent(board.$key)
  }

}
