import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tips-to-prevent-home-burglary',
  templateUrl: './tips-to-prevent-home-burglary.component.html',
  styleUrls: ['./tips-to-prevent-home-burglary.component.css']
})
export class TipsToPreventHomeBurglaryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
