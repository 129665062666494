import { Injectable } from "@angular/core";
import { NgxPicaService } from "ngx-tooning-pica";

@Injectable({
  providedIn: 'root'
})

export class Utilities {
 constructor(private _ngxPicaService: NgxPicaService){}

  ValidateFileType(fileType: string):boolean{
    var filterType = /^(?:image\/bmp|image\/gif|image\/jpeg|image\/jpeg|image\/jpeg|image\/png|image\/jpg)$/i;
   return filterType.test(fileType);
  }
  ResizeImage(file: File, width: number, height: number){
   return this._ngxPicaService.resizeImage(file, 128, 128);
  }
}
