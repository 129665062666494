<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
    <div class="col-lg-9 g-mb-30">
      <section class="g-bg-gray-light-v5 g-py-20">

        <div class="container g-py-100" style="margin-top:-50px">
          <div
            class="u-shadow-v19 g-max-width-645 g-brd-around g-brd-gray-light-v4 text-center rounded mx-auto g-pa-30 g-pa-50--md">
            <span class="u-icon-v3 u-icon-size--lg g-color-white g-bg-primary rounded-circle g-pa-15 mb-5">
              <svg width="30" height="30" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 497.5 497.5"
                style="enable-background:new 0 0 497.5 497.5;" xml:space="preserve">
                <g>
                  <path
                    d="M487.75,78.125c-13-13-33-13-46,0l-272,272l-114-113c-13-13-33-13-46,0s-13,33,0,46l137,136
                                                     c6,6,15,10,23,10s17-4,23-10l295-294C500.75,112.125,500.75,91.125,487.75,78.125z"
                    fill="#fff" />
                </g>
              </svg>
            </span>

            <div class="mb-5">
              <h2 class="mb-4">
                <p>Payment details!</p>
              </h2>
              <div class="form-group row g-mb-25">
                <label for="inputEmail3" class="col-sm-4 col-form-label">
                  <p>Transaction Reference</p>
                </label>
                <div class="col-sm-6">
                  <p>{{transref}}</p>
                </div>
              </div>
              <div class="form-group row g-mb-25">
                <label for="inputEmail3" class="col-sm-4 col-form-label">
                  <p>Payment Reference</p>
                </label>
                <div class="col-sm-6">
                  <p>{{payref}}</p>
                </div>
              </div>
              <div class="form-group row g-mb-25">
                <label for="inputEmail3" class="col-sm-4 col-form-label">
                  <p>Retrieval Reference</p>
                </label>
                <div class="col-sm-6">
                  <p>{{retref}}</p>
                </div>
              </div>
              <div class="form-group row g-mb-25">
                <label for="inputEmail3" class="col-sm-4 col-form-label">
                  <p>Payment status</p>
                </label>
                <div class="col-sm-6">
                  <p>{{responsedesc}}</p>
                </div>
              </div>
              <div *ngIf="responsecode=='00'">
                <button class="btn u-btn-primary g-font-size-12 text-uppercase g-py-12 g-px-25"
                  (click)="downloadCert()">Download certificate </button>
              </div>
            </div>

          </div>
        </div>

      </section>
    </div>
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <app-sidebar></app-sidebar>
      <!-- End Fast Facts -->
    </div>
    <!-- End Sidebar -->
  </div>
</div>
