<div class="container g-pt-100 g-pb-70">
  <div class="row">
    <div class="col-lg-12 g-mb-30">
      <section class="g-bg-gray-light-v5 g-py-20">
        <form novalidate (ngSubmit)="submitForm(beneficiaryForm)" #beneficiaryForm="ngForm" novalidate>
          <div class="container g-py-100 pad-top-50" style="margin-top:-50px">
            <div class="u-shadow-v19 g-brd-around g-brd-gray-light-v4  rounded mx-auto g-pa-30 g-pa-50--md">

              <div class="mb-6">
                <h2 class="mb-4 main-heading">
                  GOODS IN TRANSIT INSURANCE PROPOSAL FORM
                </h2>

                <section class="step-two" >
                  <div class="form-sub-title">
                    <h4>Personal Information</h4>
                  </div>
                  <div class="row">

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>Name of Proposer</label>
                        <input id="NameOfProposal" name="NameOfProposal" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.NameOfProposal" required>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Address of Proposer
                        </label>
                        <input id="AddressOfProposal" name="AddressOfProposal" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.AddressOfProposal" required >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Trade Or Business
                        </label>
                        <input id="TradeOrBusiness" name="TradeOrBusiness" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.TradeOrBusiness" required >
                      </div>
                    </div>


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          E- mail address
                        </label>
                        <input id="EmailAddress" name="EmailAddress" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.EmailAddress" required type="text" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" >

                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Mobile No
                        </label>
                        <input id="Telephone" name="Telephone" class="form-control form-control-md rounded-0" type="number"
                        placeholder="Required" [(ngModel)]="proposalModel.Telephone" required="required" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==11) return false; return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57));" >

                      </div>
                    </div>


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                         Alternate Mobile No
                        </label>
                        <input id="AltTelephone" name="AltTelephone" class="form-control form-control-md rounded-0" type="number"
                        placeholder="Required" [(ngModel)]="proposalModel.AltTelephone" required="required" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==11) return false; return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57));" >

                      </div>
                    </div>


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                         <label>
                          Start Date  (Period of Insurance)
                         </label>
                         <input id="StartDate" name="StartDate" class="form-control form-control-md rounded-0" type="date"
                          placeholder="yyyy-mm-dd" [(ngModel)]="proposalModel.StartDate" >

                      </div>
                   </div>
                   <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                         <label>
                          End Date (Period of Insurance)
                         </label>
                         <input id="EndDate" name="EndDate" class="form-control form-control-md rounded-0" type="date"
                          placeholder="yyyy-mm-dd" [(ngModel)]="proposalModel.EndDate"  >
                      </div>
                   </div>
                  </div>
                </section>

                <section class="step-three">
                  <div class="">
                    <h4>General Questions</h4>
                  </div>
                  <div class="row">

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Please state fully the nature and description of goods to be insured
                        </label>
                        <input id="NatureOfGoods" name="NatureOfGoods" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.NatureOfGoods" />
                      </div>
                    </div>

                    <!-- <div class="col-lg-6" >
                      <div class="form-group g-mb-20">
                        <label>
                         	Mode of Conveyance and Transportation
                        </label>
                        <input id="modeoftransportation" name="modeoftransportation" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.modeoftransportation" >
                      </div>
                    </div>
 -->

                    <div class="col-lg-6" >
                      <div class="form-group g-mb-20">
                        <label>
                          Have you any vehicles other than those mentioned in the above
                          Schedule?

                        </label>
                        <input id="scheduleanyvehicles" name="scheduleanyvehicles" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.scheduleanyvehicles" >
                      </div>
                    </div>



                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Is the carriage of goods on owned or hired vehicle
                        </label>

                        <select name="proposalModel.carriageowmedgoods" id="carriageowmedgoods" class="form-control form-control-md rounded-0"
                        [(ngModel)]="proposalModel.carriageowmedgoods">
                            <option value="">Select your option</option>
                            <option value="Hired">Hired </option>
                            <option value="Owned">Owned</option>
                      </select>


                      </div>
                    </div>
                    <!-- *ngIf="proposalModel.carriageowmedgoods==Hired" -->
                    <div class="col-lg-6" >
                      <div class="form-group g-mb-20">
                        <label>
                          Enter make and registration number
                        </label>

                         <input id="RegistrationNumber" name="RegistrationNumber" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.RegistrationNumber" >

                      </div>
                    </div>
                </div>
                </section>

                <section class="step-three">
                  <div class="">
                    <h6><strong>If a declaration / annual policy  is required: please state</strong></h6>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          the estimated aggregate value of goods which will be sent during the next 12 months:
                       </label>

                       <input id="estimated_aggregate_value_of_goods" name="estimated_aggregate_value_of_goods" class="form-control form-control-md rounded-0" type="text"
                       [(ngModel)]="proposalModel.estimated_aggregate_value_of_goods" >

                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          the maximum value of any one consignment:
                       </label>

                       <input id="maximum_value_of_any_one" name="maximum_value_of_any_one" class="form-control form-control-md rounded-0" type="text"
                       [(ngModel)]="proposalModel.maximum_value_of_any_one" >

                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="form-group g-mb-20">
                        <label>
                          the Conditions of Carriage applicable to goods sent by contractor’s vehicles
                          (briefly):

                       </label>

                       <input id="Conditions_of_Carriage" name="Conditions_of_Carriage" class="form-control form-control-md rounded-0" type="text"
                       [(ngModel)]="proposalModel.Conditions_of_Carriage" >

                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="form-group g-mb-20">
                        <label>
                          If cover is required for a Single Transit (please give details of the goods, destination and duration of transit:
                        </label>
                        <input id="Single_Transit_Cover" name="Single_Transit_Cover" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.Single_Transit_Cover" >

                      </div>
                    </div>



                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                         Does the driver or an attendant remain with a loaded vehicle at all times when it is not  within securely locked building? If not, what steps are taken to protect the goods?

                        </label>
                        <input id="attendant_remain" name="attendant_remain" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.attendant_remain" >
                      </div>
                    </div>


                    <!-- <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          (e) Have any of your drivers ever had their licenses suspended or endorsed? (Yes / No)

                        </label>
                        <input id="vehicles_fitted" name="vehicles_fitted" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.vehicles_fitted" >
                      </div>
                    </div> -->
                    <!-- <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          c) If all vehicles are fitted with immobilizer alarms or other security devices? If not, are
      any vehicles so fitted?

                        </label>
                        <input id="vehicles_fitted" name="vehicles_fitted" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.vehicles_fitted" >
                      </div>
                    </div> -->
                    <!-- <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          c) If all vehicles are fitted with immobilizer alarms or other security devices? If not, are
      any vehicles so fitted?

                        </label>
                        <input id="vehicles_fitted" name="vehicles_fitted" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.vehicles_fitted" >
                      </div>
                    </div> -->
                    <!-- <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Does the driver or an attendant remain with a loaded vehicle at all times when it is not  within securely locked building? If not, what steps are taken to protect the load?


                        </label>
                        <select name="driver_or_an_attendant " id="driver_or_an_attendant" class="form-control form-control-md rounded-0"
                         [(ngModel)]="proposalModel.driver_or_an_attendant">
                            <option >Select your option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                      </div>
                    </div>
                     -->

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Have any of your drivers ever had their licenses suspended or endorsed? (Yes / No)
                        </label>
                        <select name="licenses_suspended" id="licenses_suspended" class="form-control form-control-md rounded-0"
                         [(ngModel)]="proposalModel.licenses_suspended">
                            <option >Select your option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                      </div>
                    </div>






                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Do the vehicles carry fire extinguishers? If so, please state make
                        </label>
                        <input id="carry_fire_extinguishers" name="carry_fire_extinguishers" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.carry_fire_extinguishers" >
                      </div>
                    </div>







                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Has renewal ever been declined

                        </label>
                        <input id="renewal_ever_been_declined" name="renewal_ever_been_declined" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.renewal_ever_been_declined" >
                      </div>
                    </div>



                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Has an increased rate been required?
                        </label>
                        <input id="increased_rate" name="increased_rate" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.increased_rate" >
                      </div>
                    </div>




                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Please state complete record of Loss of Damage to Goods in Transit during the past
                          3 years:-

                        </label>
                        <input id="loss_damage_to_goods" name="loss_damage_to_goods" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.loss_damage_to_goods" >
                      </div>
                    </div>




                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Limit anyone carrying: N

                        </label>
                        <input id="limit_anyone_carrying" name="limit_anyone_carrying" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.limit_anyone_carrying" >
                      </div>
                    </div>


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Estimated annual carrying: N

                        </label>
                        <input id="estimated_annual_carrying" name="estimated_annual_carrying" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.estimated_annual_carrying" >
                      </div>
                    </div>




                  </div>
                </section>

               <section class="step-seven" >

                 <div class="container">
                   <h4>Declaration</h4>
                   <p>I/We hereby declare that to the best of my/our knowledge and belief the information herein provided is correct and complete.</p>

                   <!-- <div class="form-group">
                    <input type="checkbox" [(ngModel)]="termAgreed" name="termAgreed" > By checking the box , I agree with the above terms
                  </div> -->

                      <p style="font-size:10px; text-align: justify;">In compliance with NDPR requirements, by completing and submitting this form, you have given consent to NSIA Insurance to receive your data, including your bio data, to enable NSIA Insurance maintain effective communication, send promotional updates, contact you for research purposes and use the data in line with the policy terms.
                      </p>
                      <p style="font-size:10px; text-align: justify;">The data collection may be via hard copies or online forms, validated via JSON WEB TOKEN (JWT). The data will not be accessed by any third party without your consent and any breach of this policy can be addressed legally within the year the breach was reported.</p>

                      <div class="col-lg-4">
                        <div class="form-group g-mb-0">
                          <label style="visibility: hidden;">Frequency</label>
                          <button  type="submit" onclick="return confirm('Are you sure you want to submit this information?')" class="g-brd-main btn-block g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" > Submit
                          </button>
                        </div>

                      </div>

                 </div>

               </section>

              </div>


            </div>
          </div>
        </form>
      </section>
    </div>

    <!-- End Sidebar -->
  </div>
</div>
