<section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px">
    <div class="container" >
      <div class="d-sm-flex text-center">
        <div class="align-self-center">
          <h2 class="h3 g-font-weight-300 w-100 g-mb-10 g-mb-0--md" ><p>Term Assurance</p></h2>
        </div>


      </div>
    </div>
  </section>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
      <ul class="d-flex justify-content-between u-list-inline">
        <li class="list-inline-item g-mr-15">
          <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
          <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
        </li>
        <li class="list-inline-item">
                <a class="u-link-v5 g-color-text g-pr-10" routerLink="/lifeinsurance">Life Insurance</a>
                <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
              </li>
              <li class="list-inline-item g-color-primary">
                    <span>Term Assurance</span>
                  </li>

        <li class="list-inline-item ml-auto">
          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" routerLink="/getquote">Get quote</a>
        </li>
      </ul>
    </div>
  </section>
  <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
        <div class="row">
          <div class="col-lg-9 g-mb-30">
                <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
                        <h4><strong><p>Term Assurance</p></strong> </h4>

              <p>This product provides a lump sum of money in the event of death of the policyholder within a specified period of time.</p>

         <p><strong>Key Features</strong></p>
         <p></p>
         <ul>
                <li><p>Payment of a guaranteed sum of money (i.e. sum assured) if death occurs within a specified period of time</p></li>
                <li><p>Payment is also made in the event of total permanent disability or diagnosis of a critical illness</p></li>
                <li><p>The amount payable is specified in advance by the policyholder</p></li>
                <li><p>Minimum sum assured is Five Hundred Thousand Naira (N500,000)</p></li>
                <li><p>Payment is made to the person named by the policyholder (e.g. wife, child etc)</p></li>
                <li><p>Policyholder pays a single premium (purchase price) or annual payments to NSIA Life Assurance Limited to secure the guaranteed benefit</p></li>
                <li><p>Term Assurance is the cheapest form of life insurance an individual can obtain.</p></li>
                <li><p>Medical examinations may be required in certain cases (e.g. exceptionally huge benefits or old age)</p></li>
             </ul>
             <p></p>
             <p><strong>Benefits</strong></p>
              <p></p>
              <ul>
                  <li><p>On Death or Disability</p></li>

              </ul>
              <p>NSIA Life pays the guaranteed sum assured to the policyholder’s named beneficiary/ies in the event of death, total permanent disability or diagnosis of a critical illness)</p>

              <p></p>
              <p><strong>On Discontinuation</strong></p>
              <p></p>
              <p>In the unlikely event of the policyholder stopping payment of annual premium, no benefit is payable as the product is automatically discontinued. Since the product is very cheap, we recommend payment of a single premium to immediately obtain valuable life insurance protection for the specified period.</p>

              <p></p>
              <p><strong>On Maturity</strong></p>
              <p></p>
              <p>On survival of the policyholder till the end of the specified period of cover, nothing is paid as the period of cover would have elapsed and he would have enjoyed a life insurance protection for the chosen period.</p>

              <p></p>
              <p><strong>Suitability</strong></p>
              <p></p>
              <p>Term assurance is mostly used to cover periods in an individual’s life when the consequences of an early death would be very severe. Examples of such circumstances are:</p>

              <p></p>

              <ul>
                  <li><p>when a young family is growing</p></li>
                  <li><p>when a mortgage or other loan is being repaid</p></li>
                  <li><p>when dependants need income on death of the breadwinner</p></li>
              </ul>
              <p><strong>Waiting Period</strong> </p>

              <p></p>

              <p>There is a month waiting period during which a client cannot claim on non-accidental death. However, where accidental
              death occurs during this period, total premiums paid would be returned</p>
              <p></p>
                        </div>
                        </div>


          <!-- Sidebar -->
          <div class="col-lg-3 g-mb-30">
            <!-- Links -->
            <app-sidebar></app-sidebar>
            <!-- End Fast Facts -->
          </div>
          <!-- End Sidebar -->
        </div>
      </div>







