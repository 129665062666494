<div class="container g-pt-100 g-pb-70">
  <div class="row">
    <div class="col-lg-12 g-mb-30">
      <section class="g-bg-gray-light-v5 g-py-20">
        <form novalidate (ngSubmit)="submitForm(beneficiaryForm)" #beneficiaryForm="ngForm" novalidate>
          <div class="container g-py-100 pad-top-50" style="margin-top:-50px">
            <div class="u-shadow-v19 g-brd-around g-brd-gray-light-v4  rounded mx-auto g-pa-30 g-pa-50--md">

              <div class="mb-6">
                <h2 class="mb-4 main-heading">
                 MONEY PROPOSAL FORM
                </h2>

                <section class="step-two" >
                  <div class="form-sub-title">
                    <h4>Personal Information</h4>
                  </div>
                  <div class="row">

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>Name of Proposer</label>
                        <input id="NameOfProposal" name="NameOfProposal" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.NameOfProposal" required>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Address of Proposer
                        </label>
                        <input id="AddressOfProposal" name="AddressOfProposal" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.AddressOfProposal" required >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Trade Or Business
                        </label>
                        <input id="TradeOrBusiness" name="TradeOrBusiness" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.TradeOrBusiness" required >
                      </div>
                    </div>


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          E- mail address
                        </label>
                        <input id="EmailAddress" name="EmailAddress" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.EmailAddress" required type="text" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" >

                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Mobile No
                        </label>
                        <input id="Telephone" name="Telephone" class="form-control form-control-md rounded-0" type="number"
                        placeholder="Required" [(ngModel)]="proposalModel.Telephone" required="required" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==11) return false; return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57));" >

                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                         <label>
                         Period Of Insurance Start Date
                         </label>
                         <input id="StartDate" name="StartDate" class="form-control form-control-md rounded-0" type="date"
                          placeholder="yyyy-mm-dd" [(ngModel)]="proposalModel.StartDate" >

                      </div>
                   </div>
                   <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                         <label>
                          Period Of Insurance End Date
                         </label>
                         <input id="EndDate" name="EndDate" class="form-control form-control-md rounded-0" type="date"
                          placeholder="yyyy-mm-dd" [(ngModel)]="proposalModel.EndDate"  >
                      </div>
                   </div>
                  </div>
                </section>

                <section class="step-three">
                  <div class="form-sub-title">
                    <h4>General Questions</h4>
                  </div>
                  <div class="row">

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Have you ever sustained loss of money from the Risks now to be covered? If so,
                          giver particulars

                        </label>
                        <input id="LossOfMoney" name="LossOfMoney" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.LossOfMoney">
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Have you ever had an insurance of this nature cancelled or has renewal been
            declined or not invited

                        </label>
                        <input id="InsuranceNature" name="InsuranceNature" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.InsuranceNature">

                      </div>
                    </div>


                  </div>
                </section>

                <section class="step-three">
                  <div class="form-sub-title">
                    <h4>Please Give Details:</h4>
                  </div>

                  <div class="row">


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Estimated amount of money likely to be in transit any one time/or carrying:
                        </label>
                        <input id="EstimateAmountOfMoney" name="EstimateAmountOfMoney" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.EstimateAmountOfMoney">
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Maximum amount of money likely to be in transit any one year:
                        </label>
                        <input id="MaximumAmountOfMoney" name="MaximumAmountOfMoney" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.MaximumAmountOfMoney">

                      </div>
                    </div>

                    <div class="col-lg-6" >
                      <div class="form-group g-mb-20">
                        <label>
                          Method of transit and precautionary measures being employed:
                        </label>
                        <input id="MethodOfTransit" name="MethodOfTransit" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.MethodOfTransit" >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Approximate distance between your premises and the bank(s)
                        </label>
                        <input id="DistanceBetweenPremises" name="DistanceBetweenPremises" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.DistanceBetweenPremises" >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Number of employees engaged in handling money at any one time
                        </label>
                        <br/>
                        <select name="proposalModel.NumberOfEmployees" id="proposalModel.NumberOfEmployees" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.NumberOfEmployees">
                            <option >Select your option</option>
                            <option value="1">3 years</option>
                            <option value="2">4 years</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          How frequent is money carried by you or the delegated employees?
                        </label>
                        <br/>
                        <select name="proposalModel.delegatedEmployees" id="proposalModel.delegatedEmployees" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.delegatedEmployees">
                            <option >Select your option</option>
                            <option value="Repatriation Expenses">Repatriation Expenses</option>
                            <option value="Motorcycle and SRRC">Motorcycle and SRRC </option>
                        </select>
                      </div>
                    </div>


                  </div>
                </section>


                <section class="step-three">
                  <div class="form-sub-title">
                    <h4>Do you wish to insure: </h4>
                  </div>

                  <div class="row">

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Against loss of money in safe or strong room?
                        </label>
                        <select name="proposalModel.AgainstLostOfMoney" id="proposalModel.AgainstLostOfMoney" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.AgainstLostOfMoney">
                            <option >Select your option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                      </div>
                    </div>


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          •	Type of safe
                        </label>
                        <br/>
                        <input id="TypeOfSafe" name="TypeOfSafe" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.TypeOfSafe" >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          •	Who keeps the safe key?
                        </label>
                        <br/>
                        <input id="WhoKeepSafeKey" name="WhoKeepSafeKey" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.WhoKeepSafeKey" >
                      </div>
                    </div>


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Damage to safe?
                        </label>
                        <br/>
                        <input id="DamageToSafe" name="DamageToSafe" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.DamageToSafe" >
                      </div>
                    </div>


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Money in personal custody of the Insured
                        </label>
                        <br/>
                        <input id="MoneyInPersonalCustody" name="MoneyInPersonalCustody" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.MoneyInPersonalCustody" >
                      </div>
                    </div>


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Other money
                        </label>
                        <br/>
                        <input id="OtherMoney" name="OtherMoney" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.OtherMoney" >
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="form-group g-mb-20">
                        <label>
                        Additional Information
                        </label>
                        <br/>
                        <input id="AdditionalInformation" name="AdditionalInformation" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.AdditionalInformation">
                      </div>
                    </div>

                  </div>
                </section>


               <section class="step-seven" >

                 <div class="container">
                   <h4>Declaration</h4>
                   <p>I/We hereby declare that to the best of my/our knowledge and belief the information herein provided is correct and complete.</p>

                   <!-- <div class="form-group">
                    <input type="checkbox" [(ngModel)]="termAgreed" name="termAgreed" > By checking the box , I agree with the above terms
                  </div> -->

                      <p style="font-size:10px; text-align: justify;">In compliance with NDPR requirements, by completing and submitting this form, you have given consent to NSIA Insurance to receive your data, including your bio data, to enable NSIA Insurance maintain effective communication, send promotional updates, contact you for research purposes and use the data in line with the policy terms.
                      </p>
                      <p style="font-size:10px; text-align: justify;">The data collection may be via hard copies or online forms, validated via JSON WEB TOKEN (JWT). The data will not be accessed by any third party without your consent and any breach of this policy can be addressed legally within the year the breach was reported.</p>

                      <div class="col-lg-4">
                        <div class="form-group g-mb-0">
                          <label style="visibility: hidden;">Frequency</label>
                          <button  type="submit" onclick="return confirm('Are you sure you want to submit this information?')" class="g-brd-main btn-block g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" > Submit
                          </button>
                        </div>

                      </div>

                 </div>

               </section>

              </div>


            </div>
          </div>
        </form>
      </section>
    </div>

    <!-- End Sidebar -->
  </div>
</div>
