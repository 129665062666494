import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-innovations-in-insurance',
  templateUrl: './innovations-in-insurance.component.html',
  styleUrls: ['./innovations-in-insurance.component.css']
})
export class InnovationsInInsuranceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
