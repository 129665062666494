<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
   <div class="container">
      <ul class="d-flex justify-content-between u-list-inline">
         <li class="list-inline-item g-mr-15">
            <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
            <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
         </li>
         <li class="list-inline-item g-color-primary">
            <span>Events</span>
         </li>
         <li class="list-inline-item ml-auto">

         </li>
      </ul>
   </div>
</section>
<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
   <!-- Heading -->
   <div class="g-max-width-550 text-center mx-auto g-mb-70">
      <h1 class="h3 mb-5">
         <p class="g-font-weight-600">Events</p>
      </h1>
   </div>
   <!-- End Heading -->



   <div class="row">
      <div class="col-lg-12 g-mb-30">
         <div class="row g-mx-minus-25 g-mb-50">

            <div class="col-lg-6 g-px-25 g-mb-50">
               <!-- Events Grid Modern Blocks -->
               <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

                  <div class="col-sm-6 g-bg-white g-rounded-left-5">
                     <div class="g-pa-35">
                        <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                           <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer"
                              routerLink="/womens-month-2023">
                              <p>Women's Month 2023<br /><span style="font-size:12px;">01/03/2023</span></p>
                           </a>
                        </h2>
                        <p class="">
                           This month of March, we're celebrating in two folds to honour our #NSIAwomen and women all
                           over the world.
                           First, we will be marking International Women's Day (Wednesday, March 8) in style, followed
                           by Mother's Day (Sunday, March 19).
                           As usual, we have a series of activities for you to participate in, and win awesome prizes!
                        </p>

                        <br />
                        <ul class="list-inline g-font-size-12 mb-0">
                           <li class="list-inline-item g-mb-10">

                              <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                                 routerLink="/womens-month-2023">Read more</a>
                           </li>
                        </ul>
                     </div>
                  </div>
                  <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-max-height-200 g-rounded-right-5 eventimg ">
                  </div>
               </article>

               <!-- End Blog Grid Modern Blocks -->
            </div>

            <div class="col-lg-6 g-px-25 g-mb-50">
               <!-- Events Grid Modern Blocks -->
               <article class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

                  <div class="col-sm-6 g-bg-white g-rounded-left-5">
                     <div class="g-pa-35">
                        <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                           <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer"
                              routerLink="/itsitimecampaign">
                              <p>NSIA INSURANCE 2023 CAMPAIGN - IT’s I TIME<br /><span
                                    style="font-size:12px;">08/05/2023</span></p>
                           </a>
                        </h2>
                        <p class="">
                           2023 started on a great note with the launch of a new campaign: “It’s I Time”. The phrase –
                           It’s high time – is most used when something has either become an emergency of some sort, is
                           unbearable, or is being described as overdue. Simply put, “It’s Insurance Time”.
                        </p>

                        <br />
                        <ul class="list-inline g-font-size-12 mb-0">
                           <li class="list-inline-item g-mb-10">

                              <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                                 routerLink="/itsitimecampaign">Read more</a>
                           </li>
                        </ul>
                     </div>
                  </div>
                  <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-max-height-200 g-rounded-right-5 eventimg1 ">
                  </div>
               </article>

               <!-- End Blog Grid Modern Blocks -->
            </div>


         </div>
      </div>
   </div>
</div>