<div class="container g-pt-100 g-pb-70">
  <div class="row">
    <div class="col-lg-12 g-mb-30">
      <section class="g-bg-gray-light-v5 g-py-20">
        <form novalidate (ngSubmit)="submitForm()" #lifeQuoteForm="ngForm" novalidate>
          <div class="container g-py-100" style="margin-top:-50px">
            <div class="u-shadow-v19 g-brd-around g-brd-gray-light-v4  rounded mx-auto g-pa-30 g-pa-50--md">

              <div class="mb-6">
                <h2 class="mb-4">
                  <p>Get a Quote</p>
                </h2>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group g-mb-20">
                      <label>
                        Email Address
                      </label>
                      <input id="EmailAddress" name="EmailAddress" class="form-control form-control-md rounded-0"
                        type="text" placeholder="Required" [(ngModel)]="formData.EmailAddress" required>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group g-mb-20">
                      <label>
                        Phone Number
                      </label>
                      <input id="PhoneNumber" name="PhoneNumber" class="form-control form-control-md rounded-0"
                        type="text" placeholder="Required" [(ngModel)]="formData.PhoneNumber" required>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group g-mb-20">
                      <label>
                        Product Type
                      </label>
                      <select name="ProductType"  [(ngModel)]="formData.ProductType"
                        class="form-control form-control-md rounded-0" id="ProductType" required>
                        <option value="Term Assurance">Term Assurance</option>
                        <option value="Whole Life Insurance">Whole Life Insurance</option>
                        <option value="Keyman Assurance">Keyman Assurance</option>
                        <option value="NSIA Education Protection Plan (EPP)">NSIA Education Protection Plan (EPP)</option>
                        <option value="Group Life Insurance Scheme">Group Life Insurance Scheme</option>
                        <option value="Credit Life (NSIA Super Protector)">Credit Life (NSIA Super Protector)</option>
                        <option value="Mortgage Protection Assurance (NSIA Family Shield)">Mortgage Protection Assurance (NSIA Family Shield)</option>
                        <option value="NSIA Savings Plan">NSIA Savings Plan</option>
                        <option value="NSIA Investment Linked Plan">NSIA Investment Linked Plan</option>
                        <option value="Endowment Assurance Plan">Endowment Assurance Plan</option>
                        <option value="Education Endowment Assurance Plan">Education Endowment Assurance Plan</option>
                        <option value="Anticipated Endowment Assurance">Anticipated Endowment Assurance</option>
                        <option value="Immediate Annuity">Immediate Annuity</option>
                        <option value="Deferred Annuity">Deferred Annuity</option>
                      </select>

                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group g-mb-20">
                      <label>
                        Tenor
                      </label>
                      <input id="Tenor" name="Tenor" class="form-control form-control-md rounded-0" type="text"
                        placeholder="Required" [(ngModel)]="formData.Tenor" required>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group g-mb-20">
                      <label>
                        Date of Birth
                      </label>
                      <div class="input-group">
                        <ngb-datepicker #dp10  name="DateOfBirth" [(ngModel)]="formData.DateOfBirth" (navigate)="date = $event.next"></ngb-datepicker>
                      </div>


                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group g-mb-20">
                      <label>
                        Sum Assured
                      </label>
                      <input id="SumAssured" name="SumAssured" class="form-control form-control-md rounded-0"
                        type="text" placeholder="Required" [(ngModel)]="formData.SumAssured" required>
                    </div>
                  </div>

                </div>

                <button
                  class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                  type="submit"> Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>

    <!-- End Sidebar -->
  </div>
</div>
