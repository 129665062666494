import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';;
import { BoardService } from 'src/app/board/board.service';

import { AngularFireDatabase } from '@angular/fire/database';
@Component({
  selector: 'app-addboard',
  templateUrl: './addboard.component.html',
  styleUrls: ['./addboard.component.css']
})
export class AddboardComponent implements OnInit {
  public boardForm: FormGroup;

  constructor(private router: Router, private boardService: BoardService, public fb: FormBuilder, private db: AngularFireDatabase) {
  }

  ngOnInit(): void {
    this.boardService.GetStudentsList();

    this.boarForm()
  }

  boarForm() {

    this.boardForm = this.fb.group({
      image: "",
      name: "",
      title: "",
      abouta: "",
      aboutb: "",
      aboutc: "",
      aboutd: "",
      aboute: ""
    })
  }
  get image() {
    return this.boardForm.get('image')
  }
  get name() {
    return this.boardForm.get('name')
  }
  get title() {
    return this.boardForm.get('title')
  }
  get abouta() {
    return this.boardForm.get('abouta')
  }
  get aboutb() {
    return this.boardForm.get('aboutb')
  }
  get aboutc() {
    return this.boardForm.get('aboutc')
  }
  get aboutd() {
    return this.boardForm.get('aboutd')
  }
  get aboute() {
    return this.boardForm.get('aboute')
  }

  create() {

    this.boardService.addBo(this.boardForm.value)

    this.router.navigate(["/access-to-board"])

  }


}
