<div class="container g-pt-100 g-pb-70">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <section class="g-bg-gray-light-v5 g-py-20">
           <form novalidate (ngSubmit)="submitForm()" #beneficiaryForm="ngForm" novalidate>
           <div class="container g-py-100 pad-top-50" style="margin-top:-50px">
              <div class="u-shadow-v19 g-brd-around g-brd-gray-light-v4  rounded mx-auto g-pa-30 g-pa-50--md">
                 <div class="mb-6">
                    <h2 class="mb-4 main-heading">
                       Direct Debit Mandate Form
                    </h2>
                    <section >
                       <div class="row">
                         <div class="container">
                           <h4>Personal Information</h4>

                        </div>
                          <div class="col-lg-6">
                             <div class="form-group g-mb-20">
                                <label>
                                Surname
                                </label>
                                <input id="surName" name="surName" class="form-control form-control-md rounded-0" type="text"
                                placeholder="Required" [(ngModel)]="debitMandateData.surName" required>
                                <!-- <input   formControlName="surName"  class="form-control form-control-md rounded-0" type="text"
                                   placeholder="Required"  required> -->
                             </div>
                          </div>
                          <div class="col-lg-6">
                             <div class="form-group g-mb-20">
                                <label>
                                Firstname
                                </label>
                                <input id="firstName" name="firstName" class="form-control form-control-md rounded-0" type="text"
                                placeholder="Required" [(ngModel)]="debitMandateData.firstName" >
                                <!-- <input  formControlName="firstName" class="form-control form-control-md rounded-0" type="text"
                                   placeholder="Required"  > -->
                             </div>
                          </div>
                          <div class="col-lg-6" >
                             <div class="form-group g-mb-20">
                                <label>
                                Other Names
                                </label>
                                <input id="otherName" name="otherName" class="form-control form-control-md rounded-0" type="text" placeholder="Required"
                                [(ngModel)]="debitMandateData.otherName">
                                <!-- <input formControlName="otherName"  class="form-control form-control-md rounded-0" type="text" placeholder="Required"
                                   > -->
                             </div>
                          </div>
                          <div class="col-lg-6">
                             <div class="form-group g-mb-20">
                                <label>
                                E- mail address
                                </label>
                                <input id="emailAddress" name="emailAddress" class="form-control form-control-md rounded-0"
                                placeholder="Required" [(ngModel)]="debitMandateData.emailAddress" required type="text" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" >

                             </div>
                          </div>
                          <div class="col-lg-6">
                             <div class="form-group g-mb-20">
                                <label>
                                Address
                                </label>
                                <input id="Address" name="Address" class="form-control form-control-md rounded-0" type="text"
                                placeholder="Required" [(ngModel)]="debitMandateData.Address"   >

                             </div>
                          </div>
                          <div class="col-lg-6">
                             <div class="form-group g-mb-20">
                                <label>
                                Mobile No
                                </label>
                                <input id="telephone" name="telephone" class="form-control form-control-md rounded-0" type="number"
                                placeholder="Required" [(ngModel)]="debitMandateData.telephone" required="required" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==11) return false; return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57));" >
                                <!-- <input  formControlName="telephone"  class="form-control form-control-md rounded-0" type="number"
                                   placeholder="Required"  required pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==11) return false;" > -->
                             </div>
                          </div>

                          <div class="col-lg-6">
                           <div class="form-group g-mb-20">
                              <label>
                              Agent Name
                              </label>
                              <input id="agentName" name="agentName" class="form-control form-control-md rounded-0" type="text"
                               [(ngModel)]="debitMandateData.agentName" >
                              <!-- <input  formControlName="telephone"  class="form-control form-control-md rounded-0" type="number"
                                 placeholder="Required"  required pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==11) return false;" > -->
                           </div>
                        </div>

                       </div>
                       <div class="row">
                          <div class="container">
                             <h4>Declaration</h4>
                             <p>I hereby authorize you to debit my account described below into NSIA Insurance Limited’s account</p>
                          </div>
                          <div class="col-lg-6" >
                             <div class="form-group g-mb-20">
                                <label>
                                (Amount In Figure)
                                </label>
                                <input id="amountInFigure" name="amountInFigure" class="form-control form-control-md rounded-0" type="text" placeholder="Required"
                                [(ngModel)]="debitMandateData.amountInFigure"   OnlyNumber="true">
                                <!-- <input formControlName="amountInWords" class="form-control form-control-md rounded-0" type="text" placeholder="Required"
                                   > <input  formControlName="amountInFigure" class="form-control form-control-md rounded-0" type="text" placeholder="Required" -->
                             </div>
                          </div>
                          <div class="col-lg-6" >
                             <div class="form-group g-mb-20">
                                <label>
                                Policy
                                </label>
                               <select name="policy" id="policy" class="form-control form-control-md rounded-0"
                                placeholder="Required" [(ngModel)]="debitMandateData.policy" >
                                <option value="" selected disabled>Select your option</option>
                                <option value="Term Assurance">Term Assurance</option>
                                <option value="Keyman Assurance">Keyman Assurance</option>
                                <option value="Credit Life (NSIA Super Protector)">Credit Life (NSIA Super Protector)</option>
                                <option value="Endowment Assurance Plan">Endowment Assurance Plan </option>
                                <option value="Anticipated Endowment Assurance">Anticipated Endowment Assurance </option>
                                <option value="Education Endowment Assurance Plan">Education Endowment Assurance Plan </option>
                                <option value="NSIA Education Protection Plan (EPP)">NSIA Education Protection Plan (EPP) </option>
                                <option value="NSIA Deferred Annuity Plan">NSIA Deferred Annuity Plan </option>
                                <option value="Whole Insurance  (NSIA Living Companion)">Whole Insurance (NSIA Living Companion) </option>
                                <option value="NSIA Investment Linked Plan ">NSIA Investment Linked Plan  </option>
                                <option value="NSIA Savings Plan">NSIA Savings Plan </option>
                                <option value="Group Life">Group Life </option>
                                <option value="Health">Health </option>

                                </select>

                             </div>
                          </div>
                          <div class="col-lg-6" >
                             <div class="form-group g-mb-20">
                                <label>
                                Policy Number
                                </label>
                                <input id="policyNumber" name="policyNumber" class="form-control form-control-md rounded-0" type="text" placeholder="Required"
                                [(ngModel)]="debitMandateData.policyNumber">

                             </div>
                          </div>
                          <div class="col-lg-6" >
                             <div class="form-group g-mb-20">
                                <label>
                                (Amount In Words)
                                </label>
                                <input id="amountInWords" name="amountInWords" class="form-control form-control-md rounded-0" type="text" placeholder="Required"
                                [(ngModel)]="debitMandateData.amountInWords" appAlphabetOnly>

                             </div>
                          </div>
                          <div class="col-lg-3">
                             <div class="form-group g-mb-20">
                                <label>
                                Frequency of  payment
                                </label>
                                <select name="PaymentFrequency" id="PaymentFrequency" class="form-control form-control-md rounded-0"
                                placeholder="Required" [(ngModel)]="debitMandateData.PaymentFrequency" >
                                <option value="" selected disabled>Select your option</option>
                                <option value="Monthly">Monthly</option>
                                <option value="Quarterly">Quarterly</option>
                                <option value="Half Yearly">Half Yearly </option>
                                <option value="Yearly">Yearly </option>
                                </select>
                             </div>
                          </div>
                          <div class="col-lg-2">
                             <div class="form-group g-mb-20">
                                <label>
                                This mandate which commences on
                                </label>
                             </div>
                          </div>
                          <div class="col-lg-3">
                             <div class="form-group g-mb-20">
                                <label>
                                From
                                </label>
                                <input id="from" name="from" class="form-control form-control-md rounded-0" type="date"
                                 placeholder="yyyy-mm-dd" [(ngModel)]="debitMandateData.from" >

                             </div>
                          </div>
                          <div class="col-lg-4">
                             <div class="form-group g-mb-20">
                                <label>
                                To
                                </label>
                                <input id="to" name="to" class="form-control form-control-md rounded-0" type="date"
                                 placeholder="yyyy-mm-dd" [(ngModel)]="debitMandateData.to"  >
                             </div>
                          </div>

                       </div>
                       <div class="row">
                       <div class="container">
                         <p>is to continue unless I cancel same in writing.</p>
                         <p> understand that the deductions hereby authorized will be processed by electronic fund transfer and I
                            also understand that the details of each deduction will be printed on my bank statement.
                            I agree that charges will apply as appropriate.
                         </p>
                         <p>I understand that I shall not be entitled to any refund which may have already been withdrawn while
                            this mandate was in force if such amount was legally owed NSIA insurance Limited.
                         </p>
                         <p>I understand that if any direct debit instruction is paid which breaches the term of this mandate, my
                            bank shall not be liable to me in any way or manner whatsoever; my recourse shall be limited to NSIA
                            insurance limited.
                         </p>
                       </div>
                     </div>
                       <div class="row">
                         <div class="container">
                            <h4>Bank Details</h4>

                         </div>
                         <div class="col-lg-6" >
                            <div class="form-group g-mb-20">
                               <label>
                               Bank Name
                               </label>
                               <!-- <input id="bankName" name="bankName" class="form-control form-control-md rounded-0" type="text" placeholder="Required"
                               > -->
                               <select id="bankName" name="bankName" class="form-control form-control-md rounded-0" [(ngModel)]="debitMandateData.bankName">
                                <option value=""  selected disabled>Choose</option>
                                <option value="Access Bank">Access Bank</option>
                                <option value="citibank">Citibank</option>
                                <option value="Diamond Bank">Diamond Bank</option>
                                <option value="Ecobank">Ecobank</option>
                                <option value="Fidelity Bank">Fidelity Bank</option>
                                <option value="First City Monument Bank (FCMB)">First City Monument Bank (FCMB)</option>
                                <option value="FSDH Merchant Bank">FSDH Merchant Bank</option>
                                <option value="Guarantee Trust Bank (GTB)">Guarantee Trust Bank (GTB)</option>
                                <option value="Heritage Bank">Heritage Bank</option>
                                <option value="Keystone Bank">Keystone Bank</option>
                                <option value="Rand Merchant Bank">Rand Merchant Bank</option>
                                <option value="Skye Bank">Skye Bank</option>
                                <option value="Stanbic IBTC Bank">Stanbic IBTC Bank</option>
                                <option value="Standard Chartered Bank">Standard Chartered Bank</option>
                                <option value="Sterling Bank">Sterling Bank</option>
                                <option value="Suntrust Bank">Suntrust Bank</option>
                                <option value="Union Bank">Union Bank</option>
                                <option value="United Bank for Africa (UBA)">United Bank for Africa (UBA)</option>
                                <option value="Unity Bank">Unity Bank</option>
                                <option value="Wema Bank">Wema Bank</option>
                                <option value="Zenith Bank">Zenith Bank</option>
                                </select>

                             </div>
                         </div>
                         <div class="col-lg-6" >
                            <div class="form-group g-mb-20">
                               <label>
                               Account Name
                               </label>
                               <input id="accountName" name="accountName" class="form-control form-control-md rounded-0" type="text" placeholder="Required"
                               [(ngModel)]="debitMandateData.accountName" appAlphabetOnly>
                               <!-- <input formControlName="policy" class="form-control form-control-md rounded-0" type="text" placeholder="Required"
                                  > -->
                            </div>
                         </div>
                         <div class="col-lg-6" >
                            <div class="form-group g-mb-20">
                               <label>
                              Account Number
                               </label>
                               <input id="accountNumber" name="accountNumber" class="form-control form-control-md rounded-0" type="number" placeholder="Required"
                               [(ngModel)]="debitMandateData.accountNumber"  pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==10) return false; return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57));">
                               <!-- <input formControlName="amountInWords" class="form-control form-control-md rounded-0" type="text" placeholder="Required"
                                  > <input  formControlName="amountInFigure" class="form-control form-control-md rounded-0" type="text" placeholder="Required"
                                  > -->
                            </div>

                         </div>
                         <div class="col-lg-6">
                            <div class="form-group g-mb-20">
                               <label>
                               Branch
                               </label>
                               <input id="branch" name="branch" class="form-control form-control-md rounded-0" type="text"
                               [(ngModel)]="debitMandateData.branch">
                            </div>
                         </div>
                         <div class="col-lg-6">
                           <div class="form-group g-mb-20">
                              <label>
                              Type Of Account
                              </label>
                              <select name="accountType" id="accountType" class="form-control form-control-md rounded-0"
                              placeholder="Required" [(ngModel)]="debitMandateData.accountType" >
                              <option value=""  selected disabled>Select</option>
                              <option value="Current">Current</option>
                              <option value="Saving">Saving</option>
                              <option value="domiciliary">Domiciliary</option>
                              <option value="Others">Others</option>
                              </select>
                           </div>
                          </div>

                          <div class="col-lg-6">
                             <div class="form-group g-mb-20">
                                <label>
                                If Others (Enter the type of account)
                                </label>
                                <input id="accountType" name="accountType" class="form-control form-control-md rounded-0" type="text"
                                [(ngModel)]="debitMandateData.accountType">
                             </div>
                            </div>


                           <div class="col-lg-6">
                             <div class="form-group g-mb-20">
                               <label>
                                 Upload Signature
                               </label>
                               <br/>
                               <input type="file" (change)="selected($event.target.files)" required />
                             </div>
                           </div>

                      </div>
                     <div class="row">
                       <div class="container">
                         <p>I hereby undertake to ensure that the account is always funded to cover this transaction.</p>

                         <div class="form-group">
                            <input type="checkbox" [(ngModel)]="debitMandateData.termAgreed" name="termAgreed" > By checking the box , I agree with the above terms
                         </div>
                         <p style="font-size:10px; text-align: justify;">In compliance with NDPR requirements, by completing and submitting this form, you have given consent to NSIA Insurance to receive your data, including your bio data, to enable NSIA Insurance maintain effective communication, send promotional updates, contact you for research purposes and use the data in line with the policy terms.
                       </p>
                       <p style="font-size:10px; text-align: justify;">The data collection may be via hard copies or online forms, validated via JSON WEB TOKEN (JWT). The data will not be accessed by any third party without your consent and any breach of this policy can be addressed legally within the year the breach was reported.</p>

                         <div class="col-lg-4">
                            <div class="form-group g-mb-0 ">
                               <button    type="submit" class="g-brd-main btn-block g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" > Submit
                               </button>
                            </div>
                         </div>
                      </div></div>
                    </section>
                 </div>
              </div>
           </div>
           </form>
        </section>
     </div>
     <!-- End Sidebar -->
  </div>
</div>
