import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms'
import swal from 'sweetalert2';
import * as $ from 'jquery';
import { Staff, WorkmenInsuranceProposalForm } from 'src/app/Classes/workmen-insurance-proposal-form';
import { FormserviceService } from 'src/app/Services/formservice.service';

@Component({
  selector: 'app-workmen-insurance-proposal-form',
  templateUrl: './workmen-insurance-proposal-form.component.html',
  styleUrls: ['./workmen-insurance-proposal-form.component.css']
})
export class WorkmenInsuranceProposalFormComponent implements OnInit {
  showBeneficiaryTable: boolean = false
  StaffModel = new Staff("", "", "");

  OtherProducts;
  proposalModel = new WorkmenInsuranceProposalForm("",[],"","","","","","","","",null,null,"","","","","","","","","","","");
  step: number = 1

  termAgreed:boolean = false;
  constructor(private _formservice: FormserviceService) { }

  ngOnInit(): void {
  }
  OnPersonalSubmit(myForm: NgForm) {
    if (myForm.valid) {
      this.step = 2
    }

  }


  UserPrevious() {
    this.step = 1
  }
  usagePrevious() {
    this.step = 2
  }

  Add(){
console.log(this.StaffModel);
    this.proposalModel.StaffModel.push(this.StaffModel);
    this.StaffModel = new Staff("", "", "");
    if (this.proposalModel.StaffModel.length > 0) {
      this.showBeneficiaryTable = true;
    }
  }
  deleteBen(i){
    this.proposalModel.StaffModel.splice(i, 1);
    if (this.proposalModel.StaffModel.length > 0) {
      this.showBeneficiaryTable = true;
    }else{
      this.showBeneficiaryTable = false;
    }
  }

  submitForm(myForm: NgForm){

    console.log(this.proposalModel);

    var email = this.proposalModel.EmailAddress;
    var regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if(!regex.test(email)) {
      swal.fire("Error", "Your email is not correct", "error");
      this._formservice.stopLoading();
      return;
    } if (!this.termAgreed) {
      swal.fire("Error", "You need to agree to the terms and agreement", "info");
      this._formservice.stopLoading();
      return;
    }
    var telLength = $('#telephone_number').val().toString().length;

    if(telLength < 10){
      swal.fire("Error", "Your telephone number is not correct", "error");
      this._formservice.stopLoading();
      return;
    }
    else{

    this._formservice.startLoading();
    this._formservice.sendWorkMenInsuranceForm(this.proposalModel)
      .subscribe(
        (data) => {
          if (data.statusCode == "00") {
            swal.fire("Success", "Your form is submitted", "success");
            myForm.resetForm();

          } else {
            swal.fire("Success", "Your form is submitted", "success");
           // Swal.fire("error", data.statusMessage, "info");
          }
          this._formservice.stopLoading();
        },
        (error) => {
          console.log(error);
          swal.fire("Success", "Your form is submitted", "success");
         // Swal.fire("fatal", "internal server error occurred", "error");
          this._formservice.stopLoading();
        }
      )

  }
}

}
