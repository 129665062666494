
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;"><strong>ESSENTIAL PLANS GUARANTEED TO GIVE YOU PEACE OF MIND</strong></p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/nsia_insurance_plan.jpg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p><strong>1. Life Insurance </strong><br/> It is totally understandable that the thought of passing away is terrifying, but have we ever stopped to ask ourselves from where the real fear comes? Could it be that you're the breadwinner of your family and you're worried about your children's school fees after you pass away or even after retirement? Or are you worried about any of your loved ones being stranded because of the debts you may be owing?
                 </p>
                 <p></p>
                 <p>Getting life insurance is one step to turning that fear into peace of mind. Research has shown that finding ways to reduce financial stress promotes peace of mind, which is why creating financial security through life insurance is a step to having a less stressful life.
                 </p>
                 <p></p>


              </div>
           </div>

          <div class="row justify-content-between">


              <div class="col-md-12">
               <p>Did you know that getting life insurance is considered as an act of showing that you care about the wellbeing of your loved ones?
               </p>
               <p></p>
                <p>It may interest you to know that NSIA Insurance provides plans/products that will guarantee you peace of mind and they are:</p>
                <p>1. Term Assurance <br/>
                  2. NSIA Education Endowment plan <br/>
                  3. NSIA Investment Linked plan <br/>
                  You can explore our website <a href="https://nsiainsurance.com/lifeinsurance">https://nsiainsurance.com/lifeinsurance</a> for other plans that may suit you better.
                  </p>
                <p><strong>2. Motor Insurance</strong><br/>
                  Everyone dreads the idea of having to get out of their cars after getting hit by another car to argue about whose faults it was, especially in traffic. But did you know that it is less stressful and less time consuming if a motor insurance policy is in place? However, you can achieve this goal by taking up our NSIA motor insurance policy which are: Motor Third Party Insurance & Comprehensive Motor Insurance.
                </p>
                <p>Click here to learn more: <a href="https://nsiainsurance.com/motorinsurance">https://nsiainsurance.com/motorinsurance</a> </p>

                 <p><strong>3.  Fire, Burglary, and Special Perils </strong> <br/>
                  The recent fire outbreaks in various states in Nigeria is a cause for concern and is enough reason to immediately take measures to mitigate major losses you may face in your homes and businesses. These issues certainly creep into our minds from time to time and can cause uneasiness and anxiety. The presence of an insurance policy to cover losses in the event of such mishaps is important as it can protect you from unforeseen circumstances.
                 </p>
                 <p>Other options you may want to consider that NSIA has to offer are: <br/>
                  1. Householder Insurance <br/>
                  2. Fire and Perils Insurance <br/>
                  3. Business Insurance <br/>

                </p>

                 <p>Visit our website at <a href="https://nsiainsurance.com/homeinsurance">https://nsiainsurance.com/homeinsurance</a> to learn more.</p>
                <p></p>

                <p><strong>A PATH TO GOOD HEALTH IS THROUGH PEACE OF MIND </strong></p>

              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>
