import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-term-assurance',
  templateUrl: './term-assurance.component.html',
  styleUrls: ['./term-assurance.component.css']
})
export class TermAssuranceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    window.scrollTo(0, 0);
 }
}
