<div class="container g-pt-100 g-pb-70">
  <div class="row">
    <div class="col-lg-12 g-mb-30">
      <section class="g-bg-gray-light-v5 g-py-20">
        <form novalidate (ngSubmit)="submitForm(beneficiaryForm)" #beneficiaryForm="ngForm" novalidate>
          <div class="container g-py-100 pad-top-50" style="margin-top:-50px">
            <div class="u-shadow-v19 g-brd-around g-brd-gray-light-v4  rounded mx-auto g-pa-30 g-pa-50--md">

              <div class="mb-6">
                <h2 class="mb-4 main-heading">
                 GROUP PERSONAL ACCIDENT PROPOSAL FORM
                </h2>

                <section class="step-two" >
                  <div class="form-sub-title">
                    <h4>Personal Information</h4>
                  </div>
                  <div class="row">

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>Name of Proposer</label>
                        <input id="NameOfProposal" name="NameOfProposal" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.NameOfProposal" required>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Address of Proposer
                        </label>
                        <input id="AddressOfProposal" name="AddressOfProposal" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.AddressOfProposal" required >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Trade Or Business
                        </label>
                        <input id="TradeOrBusiness" name="TradeOrBusiness" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.TradeOrBusiness" required >
                      </div>
                    </div>


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          E- mail address
                        </label>
                        <input id="EmailAddress" name="EmailAddress" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.EmailAddress" required type="text" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" >

                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Mobile No
                        </label>
                        <input id="Telephone" name="Telephone" class="form-control form-control-md rounded-0" type="number"
                        placeholder="Required" [(ngModel)]="proposalModel.Telephone" required="required" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==11) return false; return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57));" >

                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Alternate Mobile No
                        </label>
                        <input id="SecondTelephone" name="SecondTelephone" class="form-control form-control-md rounded-0" type="number"
                         [(ngModel)]="proposalModel.SecondTelephone"  pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==11) return false; return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57));" >

                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                         <label>
                         Period Of Insurance Start Date
                         </label>
                         <input id="StartDate" name="StartDate" class="form-control form-control-md rounded-0" type="date"
                          placeholder="yyyy-mm-dd" [(ngModel)]="proposalModel.StartDate" >

                      </div>
                   </div>
                   <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                         <label>
                          Period Of Insurance End Date
                         </label>
                         <input id="EndDate" name="EndDate" class="form-control form-control-md rounded-0" type="date"
                          placeholder="yyyy-mm-dd" [(ngModel)]="proposalModel.EndDate"  >
                      </div>
                   </div>
                  </div>
                </section>

                <section class="step-three">
                  <div class="form-sub-title">
                    <h4>General Questions</h4>
                  </div>
                  <div class="row">
                    <p style="padding-left:15px;">Description of activities/business or occupation Please give number of employees /
                      workers/ members in the following categories
                  </p>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Administrative and Clerical (Non-manual labour):
                        </label>
                        <input id="NatureOfContract" name="NatureOfContract" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.NatureOfContract">
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Supervisory roles
                        </label>
                        <input id="SupervisoryRole" name="SupervisoryRole" class="form-control form-control-md rounded-0" type="text"
                        placeholder="Required" [(ngModel)]="proposalModel.SupervisoryRole">

                      </div>
                    </div>

                    <div class="col-lg-6" >
                      <div class="form-group g-mb-20">
                        <label>
                          Working roles (manual labour)
                        </label>
                        <input id="WorkingRole" name="WorkingRole" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.WorkingRole" >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Others (occupations to be described)
                        </label>
                        <input id="Others" name="Others" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.Others" >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          What sport do you engage in during past time ?
                        </label>
                        <br/>
                        <input id="SportEngage" name="SportEngage" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.SportEngage" >
                      </div>
                    </div>



                  </div>
                </section>

                <section class="step-three">
                  <div class="form-sub-title">
                    <h4>Scale of Benefits Required (Capital benefits)</h4>
                  </div>
                  <p style="padding-left:5px;">Please state hereunder the level of benefits you require
                  </p>
                  <div class="row">


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Death Benefit:
                        </label>
                        <input id="DeathBenefit" name="DeathBenefit" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.DeathBenefit">
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Permanent Disability:
                        </label>
                        <input id="PermanentDisability" name="PermanentDisability" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.PermanentDisability">

                      </div>
                    </div>

                    <div class="col-lg-6" >
                      <div class="form-group g-mb-20">
                        <label>
                          Temporary Total Disablement:
                        </label>
                        <input id="TemporaryDisablement" name="TemporaryDisablement" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.TemporaryDisablement" >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Medical Expenses: -
                        </label>
                        <input id="MedicalExpenses" name="MedicalExpenses" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.MedicalExpenses" >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Limt of cover (Based on Annual Earning)
                        </label>
                        <br/>
                        <select name="proposalModel.limitcover" id="proposalModel.limitcover" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.limitcover">
                            <option >Select your option</option>
                            <option value="3">3 years</option>
                            <option value="4">4 years</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Additional cover
                        </label>
                        <br/>
                        <select name="proposalModel.additionalCover" id="proposalModel.additionalCover" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.additionalCover">
                            <option >Select your option</option>
                            <option value="Repatriation Expenses">Repatriation Expenses</option>
                            <option value="Motorcycle and SRRC">Motorcycle and SRRC </option>
                        </select>
                      </div>
                    </div>


                  </div>
                </section>


                <section class="step-three">
                  <div class="form-sub-title">
                    <h4>Who is considered the beneficiary in the event of claim:</h4>
                  </div>

                  <div class="row">


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          The applicant/principal/association/company?
                        </label>
                        <select name="proposalModel.applicant" id="proposalModel.applicant" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.applicant">
                            <option >Select your option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                      </div>
                    </div>


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          The Insured or in the case of death the legal representative?
                        </label>
                        <br/>
                        <select name="proposalModel.legalRepresentative" id="proposalModel.legalRepresentative" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.legalRepresentative">
                            <option >Select your option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No </option>
                        </select>
                      </div>
                    </div>


                  </div>
                </section>


               <section class="step-seven" >

                 <div class="container">
                   <h4>Declaration</h4>
                   <p>I/We hereby declare that to the best of my/our knowledge and belief the information herein provided is correct and complete.</p>

                   <!-- <div class="form-group">
                    <input type="checkbox" [(ngModel)]="termAgreed" name="termAgreed" > By checking the box , I agree with the above terms
                  </div> -->

                      <p style="font-size:10px; text-align: justify;">In compliance with NDPR requirements, by completing and submitting this form, you have given consent to NSIA Insurance to receive your data, including your bio data, to enable NSIA Insurance maintain effective communication, send promotional updates, contact you for research purposes and use the data in line with the policy terms.
                      </p>
                      <p style="font-size:10px; text-align: justify;">The data collection may be via hard copies or online forms, validated via JSON WEB TOKEN (JWT). The data will not be accessed by any third party without your consent and any breach of this policy can be addressed legally within the year the breach was reported.</p>

                      <div class="col-lg-4">
                        <div class="form-group g-mb-0">
                          <label style="visibility: hidden;">Frequency</label>
                          <button  type="submit" onclick="return confirm('Are you sure you want to submit this information?')" class="g-brd-main btn-block g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" > Submit
                          </button>
                        </div>

                      </div>

                 </div>

               </section>

              </div>


            </div>
          </div>
        </form>
      </section>
    </div>

    <!-- End Sidebar -->
  </div>
</div>
