
<div class="container g-pt-100 g-pb-70">
  <div class="row">
    <div class="col-lg-12 g-mb-30">
      <section class="g-bg-gray-light-v5 g-py-20">
        <form novalidate (ngSubmit)="submitForm(beneficiaryForm)" #beneficiaryForm="ngForm" novalidate>
          <div class="container g-py-100 pad-top-50" style="margin-top:-50px">
            <div class="u-shadow-v19 g-brd-around g-brd-gray-light-v4  rounded mx-auto g-pa-30 g-pa-50--md">

              <div class="mb-6">
                <h2 class="mb-4 main-heading">
                  ALL RISK PROPOSAL FORM
                </h2>

                <section class="step-two" >
                  <div class="form-sub-title">
                    <h4>Personal Information</h4>
                  </div>
                  <div class="row">

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>NAME OF PROPOSER</label>
                        <input id="NameOfProposal" name="NameOfProposal" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.NameOfProposal" required>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          ADDRESS
                        </label>
                        <input id="AddressOfProposal" name="AddressOfProposal" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.AddressOfProposal" required >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          BUSINESS OR PROFESSION
                        </label>
                        <input id="TradeOrBusiness" name="TradeOrBusiness" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.TradeOrBusiness" required >
                      </div>
                    </div>


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          HOW LONG ESTABLISHED
                        </label>
                        <input id="long_established" name="long_established" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.long_established" required >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          EMAIL ADDRESS
                        </label>
                        <input id="EmailAddress" name="EmailAddress" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.EmailAddress" required type="text" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" >

                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          TELEPHONE NO
                        </label>
                        <input id="Telephone" name="Telephone" class="form-control form-control-md rounded-0" type="number"
                        placeholder="Required" [(ngModel)]="proposalModel.Telephone" required="required" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==11) return false; return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57));" >

                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          NAME AND ADDRESS OF CONTACT PERSON
                        </label>
                        <input id="contact_person" name="contact_person" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.contact_person"  >

                      </div>
                    </div>


                  </div>
                </section>

                <section class="step-three">
                  <div class="form-sub-title">
                    <h4>General Questions</h4>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          WHERE IS THE PROPERTY TO BE INSURED USUALLY PLACED WHEN NOT IN USE?
                        </label>
                        <input id="AddressOfPropertyToBeInsured" name="AddressOfPropertyToBeInsured" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.AddressOfPropertyToBeInsured">
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          a. What security measure is in place whilst the items are in the premises?
                        </label>
                        <input id="SecurityMeasure" name="SecurityMeasure" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.SecurityMeasure" required >
                      </div>
                    </div>

                    <div class="col-lg-6" >
                      <div class="form-group g-mb-20">
                        <label>
                          Give details of any property contained in a locked safe and mobile gadgets
                        </label>
                        <input id="LockedSafeMobileGadgets" name="LockedSafeMobileGadgets" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.LockedSafeMobileGadgets" >
                      </div>
                    </div>

                  </div>
                </section>


                <section class="step-three">
                  <div class="form-sub-title">
                    <h4>Insurance History</h4>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          a: Are there any insurance in force on fire and burglary insurance?
                        </label>
                        <input id="FireBurglaryInsurance" name="FireBurglaryInsurance" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.FireBurglaryInsurance">
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                         b. Have you ever made a claim from any Insurer for theft or burglary?
                        </label>
                        <input id="claimfromanyinsurer" name="claimfromanyinsurer" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.claimfromanyinsurer">
                      </div>
                    </div>

                    <div class="col-lg-6" >
                      <div class="form-group g-mb-20">
                        <label>
                          c. Has any company decline your proposal for burglary or theft risk?
                        </label>
                        <input id="companydecline" name="companydecline" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.companydecline" >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          d. Cancelled or refused to renew your policy?
                        </label>
                        <input id="RenewPolicy" name="RenewPolicy" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.RenewPolicy">
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          e. Imposed any special terms?
                        </label>
                        <br/>
                        <input id="ImposedSpecialTerms" name="ImposedSpecialTerms" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.ImposedSpecialTerms">
                      </div>
                    </div>



                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          f. To what extent you intend to travel during the next twelve months?
                        </label>

                        <input id="twelveMonths" name="twelveMonths" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.twelveMonths">
                      </div>
                    </div>

                  </div>
                </section>

                <section class="step-three">
                  <div class="form-sub-title">
                    <h4>Schedule of Property to be Insured</h4>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group g-mb-20">
                        <label>
                          Please give detailed description, serial number and value of each item on the list.
                        </label>
                        <textarea id="DetailDescription" name="DetailDescription" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.DetailDescription" rows="5"> </textarea>
                      </div>
                    </div>

                  </div>
                </section>

               <section class="step-seven" >

                 <div class="container">
                   <h4>Declaration</h4>
                   <p>I/We hereby declare that to the best of my/our knowledge and belief the information herein provided is correct and complete.</p>

                   <!-- <div class="form-group">
                    <input type="checkbox" [(ngModel)]="termAgreed" name="termAgreed" > By checking the box , I agree with the above terms
                  </div> -->

                      <p style="font-size:10px; text-align: justify;">In compliance with NDPR requirements, by completing and submitting this form, you have given consent to NSIA Insurance to receive your data, including your bio data, to enable NSIA Insurance maintain effective communication, send promotional updates, contact you for research purposes and use the data in line with the policy terms.
                      </p>
                      <p style="font-size:10px; text-align: justify;">The data collection may be via hard copies or online forms, validated via JSON WEB TOKEN (JWT). The data will not be accessed by any third party without your consent and any breach of this policy can be addressed legally within the year the breach was reported.</p>

                      <div class="col-lg-4">
                        <div class="form-group g-mb-0">

                          <button  type="submit" onclick="return confirm('Are you sure you want to submit this information?')" class="g-brd-main btn-block g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" > Submit
                          </button>
                        </div>

                      </div>

                 </div>

               </section>

              </div>


            </div>
          </div>
        </form>
      </section>
    </div>

    <!-- End Sidebar -->
  </div>
</div>
