<section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px">
  <div class="container">
    <div class="d-sm-flex text-center">
      <div class="align-self-center">
        <h2 class="h3 g-font-weight-300 w-100 g-mb-10 g-mb-0--md">
          <p>NSIA Education Protection Plan (EPP) </p>
        </h2>
      </div>


    </div>
  </div>
</section>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/lifeinsurance">Life Insurance</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item g-color-primary">
        <span>NSIA Education Protection Plan</span>
      </li>

      <li class="list-inline-item ml-auto">
        <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10"
          routerLink="/lifequote">Get quote</a>
      </li>
    </ul>
  </div>
</section>
<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
    <div class="col-lg-9 g-mb-30">
      <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
        <h4><strong>
            <p>NSIA Education Protection Plan (EPP)</p>
          </strong> </h4>

        <p>This Plan is specifically designed to protect group of families and individuals against the ongoing cost of their child’s education upon the death of any of the two parents or accidental permanent physical incapacitation of the fee paying parents. It is a simple way of guaranteeing a child’s education irrespective of death or disability of the parent or guardian.
</p>

<p></p>
<p><strong>Scope of Cover</strong></p>
<p></p>
<p>NSIA EPP is in two types, for individual and group. The individual and the group types are targeted at protecting the pupils/students of participating schools from premature stoppage of schooling as a result of the following:</p>

<p></p>
<ul>
  <li>death of parent, guardian or sponsor;</li>
  <li>total permanent disability of parent, guardian or sponsor;</li>

</ul>

<p></p>
<p><strong>Features</strong></p>
<ul>
  <li>It is annually renewable plan.</li>
  <li>The individual product type is offered in four (4) variants namely : bronze,</li>
  <li>Sum assured is based on the plan type chosen by the parent</li>
  <li>The policy can be taken by a single parent or both parents </li>
  <li>The premium paid is tax exempt</li>
  <li>The death benefits administered through a trust fund set up and managed by NSIA. </li>
  <li>The minimum age at entry is 18 years and the maximum age at entry is 59 years</li>
  <li>NSIA pays the sum assured on the option chosen in the event of death of the policyholder, which can be any of the parents</li>
  <li>There is a one month waiting period before a death claim can be made.</li>

</ul>
<p></p>
<p><strong>Benefit to parents</strong></p>
<ul>
  <li>It assists to plan towards quality education of the child, even if the parents are no longer here.</li>
  <li>It guarantees peace of mind to the life assured</li>
  <li>It ensures your child/ward’s education is not interrupted</li>
  <li>It is price-friendly </li>
  <li>Premium can be embedded in the school fees thus ensuring ease of payment.</li>

</ul>
<p></p>
<p><strong>Individual Education Protection Plan Types</strong></p>
<table class="table table-striped table-bordered">
  <thead>
    <tr>
      <th></th>
      <th>Plan Variants</th>
      <th>Death Benefits</th>
      <th>Single Life Premium</th>
      <th>Joint Life Premium</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1</td>
      <td>Bronze</td>
      <td>N500,000.00</td>
      <td>N4,000.00</td>
      <td>N6,000.00</td>
    </tr>

    <tr>
      <td>2</td>
      <td>Silver</td>
      <td>N1,000,000.00</td>
      <td>N7,000.00</td>
      <td>N12,000.00</td>
    </tr>


    <tr>
      <td>3</td>
      <td>Gold</td>
      <td>N2,000,000.00</td>
      <td>N14,000.00</td>
      <td>N20,000.00</td>
    </tr>


    <tr>
      <td>4</td>
      <td>Platinum</td>
      <td>N5,000,000.00</td>
      <td>N35,000.00</td>
      <td>N50,000.00</td>
    </tr>

  </tbody>
</table>
<p></p>
<p><strong>Trust Fund</strong></p>
<p></p>

  <ul>
    <li>The benefits will be administered by a Trust Fund set up and managed by NSIA Life. This serves as an additional security as it ensures that accrued benefits are strictly utilized for the education of the affected children. The Trust Fund will invest all benefits and pay all future school fees as they fall due.</li>

  </ul>


    <p><strong>Withdrawal/Surrender Benefit</strong></p>
    <p>EPP does not attract a surrender value as it is a basic term assurance cover.</p>

    <p><strong>Suitability</strong> </p>

    <p></p>
    <p><strong>NSIA</strong> Life Education Protection Plan is suitable for:</p>
    <ul>
      <li>Private Nursery/Primary and Secondary schools;</li>
      <li>Private Universities and polytechnics;</li>
      <li>Public universities and polytechnics;</li>
      <li>Individual parents/guardians or sponsors and</li>
      <li>Any other institution of learning.</li>

    </ul>

    <p></p>

  </div>
</div>


    <!-- Sidebar -->
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <app-sidebar></app-sidebar>
      <!-- End Fast Facts -->
    </div>
    <!-- End Sidebar -->
  </div>
</div>
