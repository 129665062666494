
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p>NSIA INSURANCE HOLDS ANNUAL GENERAL MEETING, GROWS GROSS PREMIUM INCOME TO N9.19BN</p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/chairman.jpg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>We successfully concluded our Annual General Meeting (AGM) on Tuesday, June 30, 2020 via teleconferencing.
                 </p>
                 <p></p>
                 <p>The AGM was attended by the shareholders and other stakeholders of the company. The shareholders adopted all the resolutions submitted and approved a dividend payment of 1.45k (N0.0145k) per share for the 2019 financial year.
                 </p>
                 <p></p>
                 <p>Gross premium income increased by 32.9% from N6.91bn in 2018 to N9.19bn in 2019. The Net Premium Income improved by 25% from N3.16bn reported for the year ended December 31, 2018, to N5.06bn in 2019.
                </p>
                <p></p>
                <p>Interestingly, Profit After Tax (PAT) improved significantly by 67% from N670.45m reported in 2018 to N781.87m in 2019. There was also an improvement in the Earnings per share by 75% from 7k in 2018 to 9k in 2019.
                </p>
                <p></p>
                <p>As a company that places high value on integrity and professionalism, paying Claims promptly is part of our commitment to our clients. Proof of this is the growth in Claims payout of 25.8% from N2.28bn in 2018 to N2.87bn in 2019.
                </p>
                <p></p>
              </div>
           </div>
           <div class="row justify-content-between">
              <div class="col-md-12">



                 <p>Total Assets for the year ended 2019 increased by 8.4% from N17.92bn reported for the year ended December 31, 2018, to N19.43bn. Shareholders’ Fund in 2019 grew from N10.9bn to N11.84bn marking an 8.7% year on year growth.
                 </p>
                 <p></p>

                 <p>Addressing Shareholders at the meeting, the <strong>Chairman of the Board, Ituah Ighodalo,</strong> said <i>“NSIA Insurance had made good its promise of maximizing the returns of its shareholders’ investments in the business, lending credence to the company’s values of Integrity, Care, Innovation and Professionalism.”</i></p>
                <p></p>

                <p>The resolutions adopted by the shareholders included the acceptance of the Audited Financial Statements, the re – appointment of Ernst & Young as its Independent Auditors and the appointment of Directors in place of those retiring. Prior to the AGM, Mr. Apollos Ikpobe, Mrs. Hélène Konian, and Mrs. Mansan Diagou Ehilé as Non- Executive Directors.</p>

              </div>

           </div>

           <div class="row justify-content-between">
            <!-- About Image -->

            <div class="col-md-3 col-lg-4">
               <img class="img-fluid" src="assets/img/md.jpg" alt="Image Description">
            </div>
            <!-- End About Image -->
            <div class="col-md-8 col-lg-8">
               <!-- About Info -->
               <p>
                According to the MD/CEO of NSIA Insurance, <strong>Ebelechukwu Nwachukwu,</strong> “We are pleased to have met the set goals for the financial year of 2019 and we expect to significantly grow our income for better competitive edge. We look forward to growing capacity by 25% before the end of 2020 even as we plan for a 30% growth in 2021.”
               </p>
               <p></p>
               <p>
                Ebelechukwu Nwachukwu further stated, “Our primary objective is to continually grow our business, satisfy our customers and maximize return on investments for our stakeholders.”
               </p>
               <p></p>

            </div>
         </div>


        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>