import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-understanding-the-excess-on-your-vehicle-insurance-policy',
  templateUrl: './understanding-the-excess-on-your-vehicle-insurance-policy.component.html',
  styleUrls: ['./understanding-the-excess-on-your-vehicle-insurance-policy.component.css']
})
export class UnderstandingTheExcessOnYourVehicleInsurancePolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
