import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ten-tips-for-working-with-your-insurance-company',
  templateUrl: './ten-tips-for-working-with-your-insurance-company.component.html',
  styleUrls: ['./ten-tips-for-working-with-your-insurance-company.component.css']
})
export class TenTipsForWorkingWithYourInsuranceCompanyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
