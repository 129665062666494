
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;">INSURANCE TIPS FOR A SAFE & SMART NEW YEAR</p>
              </strong>
           </h3>
           <br/>
           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/renewal.jpeg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>
                    
                    <strong>
                        <p> 1. Fine-tune your auto insurance policy.</p>
                     </strong>
                    Now is a good time to compare the car’s value to what you pay for auto insurance and determine if your policy still fits your needs. Seek the help of your insurance provider to compare your premiums to the actual cash value of your car and determine whether these optional coverages are still cost effective.
                    
                </p>

                 <p>
                   
                    <strong>
                        <p> 2. Update your inventory.</p>
                     </strong>
                     January is a perfect time to update the inventory of your belongings. If disaster strikes and you need to make a homeowner’s insurance claim, you’ll need to ensure it will cover your valuable holiday gifts and other items. Having a video inventory of your belongings could help you recover the true worth of your belongings. Learn more about how to inventory your personal possessions for insurance.
                   
                </p>

                <p>
                    
                    <strong>
                        <p> 3. Make sure your life insurance grows with you.</p>
                     </strong>
                     Many policyholders forget to update their insurance coverage as their needs change. If you have a life insurance policy, you should review it annually.
                As major life events occur, you may find your insurance needs change. For example, you may need to update your coverage as you have children, get married, or increase your personal wealth.
                Your annual policy review should assess the benefit amount, term, loans and cash value.
                
                </p>

                <p>
                    
                    <strong>
                        <p> 4. Brush up on your health insurance knowledge.</p>
                     </strong>
                     Many people do not pay attention to changes in their health insurance plans, which can lead to costly surprises. If you receive health insurance through your employer, it’s almost certain that a contract is already in place for the entirety of the new year. 
                     Make sure to review your health plan carefully before your next visit to avoid surprise out-of-network expenses or unexpected copayment increases.
                     
                </p>
               
               
                
                
                 <p></p>

              </div>
           </div>
           <br/>
           <br/>
           <br/>

          <div class="row justify-content-between">
            
              <div class="col-md-12">
            
        </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>