
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;"><strong>COMMON INSURANCE MISTAKES TO AVOID</strong></p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/common_mistake.jpg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>Sometimes the best things in life come with risks, which is why it is important to purchase insurance cover to protect you from such risks. It is also important to ensure that you have the right type and amount of coverage to cover for financial losses resulting from an unfortunate event. </p>
                 <p></p>
                 <p>Here are some simple mistakes to avoid when looking to purchase an insurance cover:
                  </p>



              </div>
           </div>

          <div class="row justify-content-between">


              <div class="col-md-12">
               <p><strong>1. Underinsuring yourself  </strong><br/> This can be quite costly when you realize that your basic insurance will not cover needed expenses. You should consider the maximum amount your insurance will pay for each accident.
               </p>

               <p></p>
                <p><strong>2. Not knowing your policy</strong><br/>
                  Sometimes, customers do not bother to know what is in the fine print of their policy documents; what their deductibles are, and what is not covered until disaster strikes. We advise that you talk to your agents and do an evaluation each year.
                </p>
              <p></p>
                 <p><strong>3.	Not updating your coverage</strong> <br/>
                  Evaluate your coverage whenever you go through a major change, such as childbirth or marriage, or at least once annually. For instance, if you move from an apartment to a house you now own, consider getting house owner’s comprehensive insurance to protect your building and belongings against fire or theft.
                 </p>

                 <p></p>
                 <p><strong>4.	Not shopping around for a new policy</strong> <br/>
                  It is important that you shop around for a basic insurance policy. You can save money by switching to a new policy. Additionally, you should look at policies that offer discounts.
                 </p>
                 <p>Additionally, deciding to save money by not having insurance is a costly mistake that you do not want to make. You should have motor vehicle insurance that covers accidents and thefts. You should have householder or house owner’s insurance to cover thefts and fire. Additionally, you absolutely need basic health insurance. Medical bills can add up quickly, and can be financially crippling.</p>

                 <p>NSIA Insurance offers all the tools and resources available to make finding the right coverage a smoother process. Ready to get started? Visit us at <a href="https://nsiainsurance.com/homeinsurance">https://nsiainsurance.com/homeinsurance</a> or call customer service on 09048418896. .</p>
                <p></p>

              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>