import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormserviceService } from 'src/app/Services/formservice.service';

@Component({
  selector: 'app-loading-page',
  templateUrl: './loading-page.component.html',
  styleUrls: ['./loading-page.component.css']
})
export class LoadingPageComponent implements OnInit {
  showLoading: boolean = false;
  loadingSubscription: Subscription;

  constructor(private _formService: FormserviceService) { }

  ngOnInit(): void {
    this.loadingSubscription = this._formService.loadingStatus.subscribe((value) => {
      this.showLoading = value;
    });
  }
  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe();
  }
  }


