<section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px">
  <div class="container" >
    <div class="d-sm-flex text-center">
      <div class="align-self-center">
        <h2 class="h3 g-font-weight-300 w-100 g-mb-10 g-mb-0--md" ><p>Burglary Insurance</p></h2>
      </div>


    </div>
  </div>
</section>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item g-color-primary">
        <span>Burglary Insurance </span>
      </li>

      <li class="list-inline-item ml-auto">
       <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
           routerLink="/getquote">Get quote</a>
      </li>
    </ul>
  </div>
</section>


<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
    <div class="col-lg-9 g-mb-30">

      <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
        <!-- <h3 class="mb-0"><strong><p>BUSINESS INSURANCE</p></strong></h3><br/> -->

        <div style="padding: 10px 0px 0.05px 10px ; background-color:#efb225;">
          <h5 style="color:white">Burglary Insurance</h5>
        </div>
        <p></p>
        <p class="mb-0">This class of insurance provides indemnity to the Insured for loss of or damage to insured property following from theft accompanied with forcible or violent entry or exit. It provides compensation for misfortune suffered as a result of theft of any of the insured items.</p>

        <!-- <p class="mb-0">•	Accidental bodily injury to or illness of any person (whether or not death results).</p> -->


      </div>
    </div>

    <!-- Sidebar -->
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <app-sidebar></app-sidebar>
      <!-- End Fast Facts -->
    </div>
    <!-- End Sidebar -->
  </div>
</div>






