import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { NgForm } from '@angular/forms'
import { NewMotorProposalForm } from 'src/app/Classes/new-motor-proposal-form';
import { FormserviceService } from 'src/app/Services/formservice.service';


@Component({
  selector: 'app-motor-insurance-proposal-form',
  templateUrl: './motor-insurance-proposal-form.component.html',
  styleUrls: ['./motor-insurance-proposal-form.component.css']
})
export class MotorInsuranceProposalFormComponent implements OnInit {
  month:any;
  day:any;
  year:any;
  maxDate:any;

  public DateOfBirth: any;
  Duration:number;
  //public Age: number;
  dateTemp = new Date();
  step:number = 1;
  diseases: string[] = [];
  otherPolicy: string[] = [];
  ddEnabled:number = 0;
  countries:any;
  states:any;
  banks:any;
  termAgreed:boolean = false;
  showBeneficiaryTable: boolean = false;
  dob: Date;
  result: string;
  com :number;
  newDate:any;
  policy: string;
  proposalModel = new  NewMotorProposalForm(null,null,"","",null,"",null,"","","","",null,null,null,null,null,"",
  "","","","","","","","","","","","","","","","","","","","","");
  constructor(private _formservice: FormserviceService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this._formservice.GetCountries()
      .subscribe(
        (data) => {
          this.countries = data
        },
        (error) => {
          console.log(error);
        }
      );
      this._formservice.GetStates()
        .subscribe(
          (data) => {
            this.states = data
          },
          (error) => {
            console.log(error);
          }
        );
        this._formservice.GetBanks()
        .subscribe(
          (data) => {
            this.banks = data
          },
          (error) => {
            console.log(error);
          }
        );


        var dtToday = new Date();

        this.month = dtToday.getMonth() + 1;
        this.day = dtToday.getDate();
        this.year = dtToday.getFullYear();
        if(this.month < 10)
        this.month = '0' + this.month.toString();
        if(this.day < 10)
        this.day = '0' + this.day.toString();
  }
  focusOutFunction () {
    this.dob = this.proposalModel.DateOfBirth;

    var today = new Date();
    var birthDate = new Date(this.dob);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    this.proposalModel.Age = age;
    if(age < 18){
     alert("Under age, kindly put age 18 & above");
    }
  }



  submitForm(myForm: NgForm){

    console.log(this.proposalModel);

    var email = this.proposalModel.EmailAddress;
    var filestringId = this.proposalModel.filestringId;
    var regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if(!regex.test(email)) {
     alert("Your email is not correct");
      this._formservice.stopLoading();
      return;
    }
    var telLength = $('#Telephone').val().toString().length;

    if (this.proposalModel.Occupation == "") {
     alert("Occupation is required");
      this._formservice.stopLoading();
      return;
    }

    if (this.proposalModel.EmployerName == "") {
     alert("Name Of Business is required");
      this._formservice.stopLoading();
      return;
    }



    if (this.proposalModel.EmployersAddress == "") {
      alert("Business Address is required");
      this._formservice.stopLoading();
      return;
    }


    if (!this.termAgreed) {
     alert("You need to agree to the terms and agreement");
      this._formservice.stopLoading();
      return;
    }
    else if(filestringId==''){
      alert("Upload your signature");
      this._formservice.stopLoading();
    }
    else
    {

    this._formservice.startLoading();
    this._formservice.sendMotorProposalForm(this.proposalModel)
      .subscribe(
        (data) => {
          if (data.statusCode == "00") {
            alert("Your form is submitted, Also you will get a copy of the email.");


            myForm.resetForm();
          } else {
            alert( data.statusMessage);
          }
          this._formservice.stopLoading();
        },
        (error) => {
          console.log(error);
          alert("internal server error occurred");
          this._formservice.stopLoading();
        }
      )

    }
}
}
