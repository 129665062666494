export class CustomerClass {
  constructor(
    public fullname: string,
    public email: string,
    public phoneno: string,
    public address: string,
    public dob: string
  ) {

  }
}
