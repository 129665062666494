import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import swal from 'sweetalert2';
import { NewsletterClass } from 'src/app/Classes/newsletter-class';
import { FormserviceService } from 'src/app/Services/formservice.service';
import { ToastrService } from 'ngx-toastr';
import { SliderService } from 'src/app/slider/slider.service';
import { Slider } from 'src/app/slider/slider';
import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel/public_api';

@Component({
  selector: 'app-index-body',
  templateUrl: './index-body.component.html',
  styleUrls: ['./index-body.component.css']
})
export class IndexBodyComponent implements OnInit {
  model;
  Slider: Slider[];
  hideWhenNoStudent: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;
  userData = new NewsletterClass("", "");
  constructor(private router: Router, @Inject(DOCUMENT) private document: Document, private _formService: FormserviceService, public toastr: ToastrService, public sliderService: SliderService) { }

  ngOnInit(): void {
    let win = (window as any);
    if (win.location.search == '?loaded=') {
      win.location.href = window.location.origin + "";
    }

    this.dataState();
    let s = this.sliderService.GetStudentsList();
    s.snapshotChanges().subscribe(data => {
      this.Slider = [];
      console.log(this.Slider)
      data.forEach(item => {
        let a = item.payload.toJSON();
        a['$key'] = item.key;
        this.Slider.push(a as Slider);
      })
    })
  }
  dataState() {
    this.sliderService.GetStudentsList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if (data.length <= 0) {
        this.hideWhenNoStudent = false;
        this.noData = true;
      } else {
        this.hideWhenNoStudent = true;
        this.noData = false;
      }
    })
  }
  OnContactSubmit() {
    console.log(this.userData);

    this._formService.startLoading();
    this._formService.sendNewsletterForm(this.userData)
      .subscribe(
        (data) => {
          console.log(data);
          if (data.statusCode == "00") {
            swal.fire("Success", "Thank you for subscribing to our newsletter", "success");
          } else {
            swal.fire("Error", data.statusMessage, "info");
          }
          this._formService.stopLoading();
          this.userData = new NewsletterClass("", "");
        },
        (error) => {
          console.log(error);
          swal.fire("Fatal", "Internal server error occurred", "error");
          this._formService.stopLoading();
        }
      )
  }
  goToUrl(): void {
    window.open('https://nsiainsurance.us18.list-manage.com/subscribe/post?u=66e763194a167dbd985b3893f&amp;id=75a11cad7f', '_blank');
    // this.document.location.href = 'https://nsiainsurance.us18.list-manage.com/subscribe/post?u=66e763194a167dbd985b3893f&amp;id=75a11cad7f';
  }

}
