import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import 'rxjs/Rx';
import * as FileSaver from 'file-saver'
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class FileDownloadServiceService {

  constructor(private http:HttpClient) { }
  private baseUrl = environment.baseUrl + "/NSIAMobile/api";
  public getFile(pathParam:string,tnxref):Observable<Blob>
  {
    let headers = new HttpHeaders({
      'responseType': 'blob'
    });
    let options = {
      headers:headers
    };


    return this.http.get(this.baseUrl+ "/" + pathParam + tnxref  ,options).pipe(
    map((response:Response)=>
    {
    return <Blob> <unknown>response.blob()
    }))


  }
}
