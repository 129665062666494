<section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px">
  <div class="container" >
    <div class="d-sm-flex text-center">
      <div class="align-self-center">
        <h2 class="h3 g-font-weight-300 w-100 g-mb-10 g-mb-0--md" ><p>Group Life Insurance Scheme</p></h2>
      </div>
    </div>
  </div>
</section>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item">
              <a class="u-link-v5 g-color-text g-pr-10" routerLink="/lifeinsurance">Life Insurance</a>
              <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
            </li>
            <li class="list-inline-item g-color-primary">
                  <span>Group Life Insurance Scheme</span>
                </li>

      <li class="list-inline-item ml-auto">
        <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a>
      </li>
    </ul>
  </div>
</section>
<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
      <div class="row">
        <div class="col-lg-9 g-mb-30">
              <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
                      <h4><strong><p>Group Life Insurance Scheme</p></strong> </h4>
                      <p>A group life insurance contract covers members of a group. The group could be employees, members of a club, society, association, church, mosque etc. It provides financial compensation in the event of death of a member of the group. Death, for this purpose, includes natural or accidental death. The scheme operates on a 24-hour, world-wide basis.</p>
                      <p></p>
                      <p>Section 4(5) of PRA 2014 makes it mandatory for employers of labour to provide life insurance cover for their employees. The minimum benefit specified by the Act is <strong>three times</strong> the employee’s total emolument. NSIA group life insurance scheme fulfils this requirement perfectly.</p>
                      <p><strong>Aims of the Scheme</strong></p>
                      <p></p>
                      <p>The main aims of a group life scheme are as follows:</p>

                      <p><strong>Employer/Employee</strong></p>
                      <ul>
                          <li><p>To provide specified benefits on death of an employee (minimum of 3 times total emolument)</p></li>
                          <li><p>To relieve the employer of financial commitments associated with employee’s death (e.g. compulsory payments, burial cost etc)</p></li>
                          <li><p>To attract and retain good employees</p></li>
                          <li><p>To create goodwill between the employer and the employees</p></li>
                          <li><p>To give peace of mind to the employees while working</p></li>
                          <li><p>To comply with the provisions of the law</p></li>

                      </ul>
                      <p></p>
                      <p><strong>Clubs, Associations etc.</strong></p>
                      <ul>
                          <li><p>To provide<strong> specified benefits on death of a member (e.g. fixed amount of N5m)</strong></p></li>
                          <li><p>To relieve the group of financial burden associated with a member’s death (eg burial cost, child education etc)</p></li>
                          <li><p>To encourage membership</p></li>
                          <li><p>To promote the concept of brotherhood and social responsibility</p></li>

                      </ul>
                      <p></p>
                      <p><strong>Key Features</strong></p>
                     <p></p>
                      <ul>
                          <li><p>The scheme is arranged primarily to provide benefits in the event of <strong>death only</strong> whether natural or due to accident.</p></li>
                          <li><p>Benefits are usually in form of <strong>Lump sum.</strong></p></li>
                          <li><p>It is very <strong>cheap</strong> and, indeed, the cheapest form of life insurance</p></li>
                          <li><p><strong>Medical</strong> evidence may be required in certain cases</p></li>
                          <li><p>It is arranged for a period of <strong>1 year</strong> but <strong> renewed </strong> annually thereafter</p></li>
                          <li><p>Cost is usually met solely by the Employer. Employees do not contribute towards the cost of the scheme.</p></li>
                          <li><p>It operates on a 24-hour, world-wide basis.</p></li>

                      </ul>

                      <p></p>
                      <p><strong>Our Requirements</strong></p>
                      <p>The following data are required for necessary computation of benefits and the corresponding premium:</p>
                      <p></p>
                      <ul>
                          <li><p>Names of employees/members</p></li>
                          <li><p>Sex</p></li>
                          <li><p>Date of Birth</p></li>
                          <li><p>Required level of benefits (i.e Sum Assured)</p></li>
                          <li><p>Commencement Date</p></li>

                      </ul>

                      <p></p>
                      </div>
                      </div>
        <!-- Sidebar -->
        <div class="col-lg-3 g-mb-30">
          <!-- Links -->
          <app-sidebar></app-sidebar>
          <!-- End Fast Facts -->
        </div>
        <!-- End Sidebar -->
      </div>
    </div>
