// import { AngularMaterialModule } from './../angular-material/angular-material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { BlogPostComponent } from './blog-post/blog-post.component';
import { BlogsService } from '../Services/blog.service';
import { BlogsDetailsComponent } from './blogs-details/blogs-details.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CustomBlogsRoutingModule } from './custom-blogs-routing.module';
import { BlogPostComponent } from './blog-post/blog-post.component';
import { BlogsComponent } from './blogs/blogs.component';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { BlogPostComponent } from './blog-post/blog-post.component';



@NgModule({
  declarations: [BlogPostComponent, BlogsComponent, BlogsDetailsComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    CKEditorModule,
    CustomBlogsRoutingModule,
    AngularMaterialModule
  ],
  providers: [BlogsService]
})
export class CustomBlogsModule { }
