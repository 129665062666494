<div class="g-min-height-300 g-flex-centered g-bg-img-hero g-bg-pos-top-center g-bg-size-cover g-pt-80"
  style="background-image: url(assets/img/banners/Inset_Business.jpg);">
  <div class="container g-pos-rel g-z-index-1">
    <h2 class="h1 text-uppercase g-color-primary g-font-size-40--lg mb-0"><b>Property Insurance</b></h2>
    <span class="d-block  g-color-white g-font-weight-1000 g-font-size-20 mb-4"> Protecting the key assets of your
      livelihood.</span>

  </div>
</div>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item g-color-primary">
        <span>Property Insurance</span>
      </li>

      <li class="list-inline-item ml-auto">
        <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
           routerLink="/getquote">Get quote</a>
      </li>
    </ul>
  </div>
</section>


<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
    <div class="col-lg-9 g-mb-30">

      <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
        <!-- <h3 class="mb-0"><strong><p>BUSINESS INSURANCE</p></strong></h3><br/> -->
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white;">Fire & Special Perils Insurance</h5>
        </div>
        <p></p>
        <p class="mb-0">Fire & Special Perils Insurance policy provides indemnity for losses or damage resulting from
          fire or Special perils such as Storm, Flood, Riots and Strikes, Malicious damage, Impact damage and Falling
          Aircrafts or Aerial Devices.</p>
        <p>This cover can be taken by individuals, business and corporate entities. Assets that can be covered include
          Buildings, Plant and Machinery, Stock or inventory held for sale, home and office contents.</p>
        <br />
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white;">Burglary Insurance</h5>
        </div>
        <p></p>
        <p class="mb-0">
          This class of insurance provides indemnity to the Insured for loss of or damage to insured property following
          theft accompanied with forcible or violent entry or exit. It provides compensation for misfortune
          suffered as a result of theft of any of the insured items.
        </p>


        <p></p>
        <br />
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white">Business Interruption Insurance</h5>
        </div>
        <p></p>
        <p class="mb-0">
          Business interruption insurance (formerly consequential loss insurance) is a type of insurance that covers
          loss of income that a business suffers after a disaster. The income loss covered may be due to
          disaster-related closing of the business facility due to the rebuilding process after a disaster or damage to
          machinery.
        </p>
        <br />

        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white;">Plant All Risk Insurance</h5>
        </div>
        <p></p>
        <p class="mb-0">
          The Plant All Risks Insurance is designed to cover Plants and Machines against physical loss or damage
          resulting from fire, explosion, theft and other unforeseen perils.
        </p>


        <p></p>
        <br />

        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white;">Computer & Electronics Insurance</h5>
        </div>
        <p></p>
        <p class="mb-0">
          The policy covers loss or damage to Computers and other Electronic Equipment from causes such as Fire,
          Lightning, Explosion, Burglary, Theft, Water damage and other accidental causes. <br>
          The policy may be extended to cover cost or reinstalling lost data, income loss or increased cost of working
          due to business interruption resulting from damage to the computer or electronic equipment.

        </p>


        <p></p>
        <br />

        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white;">Machinery Breakdown</h5>
        </div>
        <p></p>
        <p class="mb-0">
          The policy covers all those who own and use different types of plant machinery and mechanical equipment. This is a policy designed to cover damages caused by internal forces, such as power surges, electrical shorts, mechanical breakdowns and motor burnout.
        </p>
        <p>The insurance policy covers the loss due to sudden and accidental machinery damage emerging from internal causes. Some of the causes can be short circuit, structural defects, loosening of parts, excessive speed and lack of lubrication. </p>


        <p></p>
        <br />
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white;">Construction All Risk Insurance </h5>
        </div>
        <p></p>
        <p class="mb-0">
          The policy covers contract work (both permanent and temporary works) including all materials, it also protects the insured against all sums they may become liable to pay for in respect to:
        </p>
        <ul>
          <li>death or bodily injury or illness or disease of any third party</li>
          <li>loss or damage to third party’s property</li>
          <li>loss of amenities, as a result of obstruction to third parties trespass or nuisance</li>
          <li>denial of access, interference with light, air, water way and any other form of third party liabilities.</li>
        </ul>


        <p></p>
        <br />

        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white;">Erection All Risks Insurance</h5>
        </div>
        <p></p>
        <p class="mb-0">
          The policy is  designed to cover the risk of loss arising from the erection, installation, commissioning and testing of machinery, plant and steel structures, including physical damage to the contract works, equipment and machinery, and liability for third-party bodily injury or property damage (PD) arising from these operations.
        </p>
        <p></p>
        <br />


      </div>
    </div>

    <!-- Sidebar -->
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <app-sidebar></app-sidebar>
      <!-- End Fast Facts -->
    </div>
    <!-- End Sidebar -->
  </div>
</div>