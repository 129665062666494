import {  Component, OnInit,ViewChild,ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgProgressComponent } from 'ngx-progressbar';
import * as FileSaver from 'file-saver'
import { MainserviceService } from 'src/app/Services/mainservice.service';
import { FileDownloadServiceService } from 'src/app/Services/file-download-service.service';
@Component({
  selector: 'app-motor-response',
  templateUrl: './motor-response.component.html',
  styleUrls: ['./motor-response.component.css']
})
export class MotorResponseComponent implements OnInit {
  transref:string
  payref:string
  retref:string
  responsecode:string
  responsedesc:string
  fileURL :any
  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;
  constructor(public route:ActivatedRoute,public downloadService:FileDownloadServiceService,public mainService:MainserviceService,public alert:ToastrService,viewContainerRef:ViewContainerRef) { }

  ngOnInit(): void {
    this.transref=this.route.snapshot.queryParams['txnRef'];
    this.querypaydetail()
  }
  querypaydetail()
  {
   let paymentparam=
    {
       txtref:this.transref,
       pay:'',
       ret:''

    }


    this.mainService.processpayment(paymentparam).
    subscribe(result=>
    {
      result.then(
        (data) => {
          this.responsecode=data.responseCode;
          this.responsedesc=data.responsedescription;
          this.transref=data.txtref;
          this.payref=data.payref;
          this.retref=data.retref;
         console.log(this.responsecode)
        }
      )


    },
     error=>
      {

        // this.alert.showError(error.responseDescription,"Error!")
        // this.ngProgress.done();

     },

    )
  }
  downloadCert()
  {

    this.progressBar.start();
    this.downloadService.getFile("api/getCertificate/",this.transref)
    .subscribe(fileData=>
      {
       FileSaver.saveAs(fileData,"Certificate")
       this.progressBar.complete();
       this.alert.success("Sucessfully downloaded","Success")

     // this.fileURL = URL.createObjectURL(fileData);
     }
   )

  }

}
