<div class="container g-pt-100 g-pb-70">
  <div class="row">
    <div class="col-lg-12 g-mb-30">
      <section class="g-bg-gray-light-v5 g-py-20">
        <form novalidate (ngSubmit)="submitForm(beneficiaryForm)" #beneficiaryForm="ngForm" novalidate>
          <div class="container g-py-100 pad-top-50" style="margin-top:-50px">
            <div class="u-shadow-v19 g-brd-around g-brd-gray-light-v4  rounded mx-auto g-pa-30 g-pa-50--md">

              <div class="mb-6">
                <h2 class="mb-4 main-heading" style="font-size: 30px;">

                  PROFESSIONAL INDEMNITY INSURANCE PROPOSAL FORM

                </h2>

                <section class="step-two" >
                  <div class="form-sub-title">
                    <h4>Personal Information</h4>
                  </div>
                  <div class="row">

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>Name of Firm</label>
                        <input id="NameOfFirm" name="NameOfFirm" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.NameOfFirm" required>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Nature of Business
                        </label>
                        <input id="NatureOfBusiness" name="NatureOfBusiness" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.NatureOfBusiness" required >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Address
                        </label>
                        <input id="Address" name="Address" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.Address" required >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Date Of Incorporation
                        </label>
                        <input id="DateOfIncorporation" name="DateOfIncorporation" class="form-control form-control-md rounded-0" type="date"
                          placeholder="yyyy-mm-dd" [(ngModel)]="proposalModel.DateOfIncorporation" required >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          RC Number
                        </label>
                        <input id="rcnumber" name="rcnumber" class="form-control form-control-md rounded-0" type="text"
                          [(ngModel)]="proposalModel.rcnumber" required >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Mode Of Identification of Directors/Partners
                        </label>
                        <select name="proposalModel.modeofId" id="proposalModel.modeofId" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.modeofId">
                            <option >Select your option</option>
                            <option value="Tax ID">Tax ID</option>
                            <option value="National ID">National ID</option>
                            <option value="International Passport">International Passport</option>
                            <option value="Driver's License">Driver's License</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Identification No.
                        </label>
                        <input id="idnumber" name="idnumber" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.idnumber" required >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Office Telephone No
                        </label>
                        <input id="Telephone" name="Telephone" class="form-control form-control-md rounded-0" type="number"
                         [(ngModel)]="proposalModel.Telephone" required="required" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==11) return false; return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57));" >

                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Mobile No
                        </label>
                        <input id="mobileno" name="mobileno" class="form-control form-control-md rounded-0" type="number"
                         [(ngModel)]="proposalModel.mobileno" required="required" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==11) return false; return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57));" >

                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Total number of Professionals & Staff
                        </label>
                        <input id="totalnumberofstaff" name="totalnumberofstaff" class="form-control form-control-md rounded-0" type="number"
                         [(ngModel)]="proposalModel.totalnumberofstaff" >

                      </div>
                    </div>



                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                         <label>
                          Which Professional Association or Body is your firm a Fellow, Associate or Member
                         </label>
                         <input id="professionalassociationbody" name="professionalassociationbody" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.professionalassociationbody" >

                      </div>
                   </div>
                   <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                         <label>
                          Class of Membership
                         </label>
                         <input id="classofmembership" name="classofmembership" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.classofmembership"  >
                      </div>
                   </div>
                  </div>
                </section>

                <section class="step-three">
                  <div class="form-sub-title">
                    <h4>General Questions</h4>
                  </div>
                  <div class="row">

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Have you previously held or do you now hold Professional Indemnity Policy? If YES give details

                        </label>
                        <input id="holdProfessionalIndemnityPolicy" name="holdProfessionalIndemnityPolicy" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.holdProfessionalIndemnityPolicy">
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Have you recently discharged or are you contemplating discharging any of your staff for any omission, neglect or error? If YES give details
                        </label>
                        <input id="discharged" name="discharged" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.discharged">

                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Has any claim been made against your firm in the past? (If so give particulars)
                        </label>
                        <input id="claimbeenmade" name="claimbeenmade" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.claimbeenmade">

                      </div>
                    </div>




                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                           Describe the type of professional service or advice that you provide to clients
                        </label>
                        <input id="professionalservice" name="professionalservice" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.professionalservice">

                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Is there any claim outstanding or any circumstances which might give rise to a claim against this Practice?
                        </label>
                        <input id="claimoutstanding" name="claimoutstanding" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.claimoutstanding">

                      </div>
                    </div>


<!--
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Limit of Liability
                        </label>

                        <select name="proposalModel.limitofliability" id="proposalModel.limitofliability" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.limitofliability">
                            <option >Select your option</option>
                            <option value="Limit per occurrence">Limit per occurrence</option>
                            <option value="Aggregate anyone period of Insurance">Aggregate anyone period of Insurance</option>

                        </select>

                      </div>
                    </div> -->




                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Gross Fees Earned during last Financial Year:
                        </label>
                        <input id="GrossFees" name="GrossFees" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.GrossFees">

                      </div>
                    </div>

                  </div>
                </section>



                <section class="step-three">
                  <div class="">
                    <h6><strong>Limit Of Liability</strong></h6>
                  </div>
                  <div class="row">


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Limit per occurrence

                        </label>
                        <input id="limitperoccurrence" name="limitperoccurrence" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.limitperoccurrence" >

                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                         Aggregate anyone period
                       </label>

                       <input id="aggregateanyoneperiod" name="aggregateanyoneperiod" class="form-control form-control-md rounded-0" type="text"
                       [(ngModel)]="proposalModel.aggregateanyoneperiod" >

                      </div>
                    </div>

                  </div>
                </section>

               <section class="step-seven" >

                 <div class="container">
                   <h4>Declaration</h4>
                   <p>I/We hereby declare that to the best of my/our knowledge and belief the information herein provided is correct and complete.</p>

                   <!-- <div class="form-group">
                    <input type="checkbox" [(ngModel)]="termAgreed" name="termAgreed" > By checking the box , I agree with the above terms
                  </div> -->

                      <p style="font-size:10px; text-align: justify;">In compliance with NDPR requirements, by completing and submitting this form, you have given consent to NSIA Insurance to receive your data, including your bio data, to enable NSIA Insurance maintain effective communication, send promotional updates, contact you for research purposes and use the data in line with the policy terms.
                      </p>
                      <p style="font-size:10px; text-align: justify;">The data collection may be via hard copies or online forms, validated via JSON WEB TOKEN (JWT). The data will not be accessed by any third party without your consent and any breach of this policy can be addressed legally within the year the breach was reported.</p>

                      <div class="col-lg-4">
                        <div class="form-group g-mb-0">
                          <label style="visibility: hidden;">Frequency</label>
                          <button  type="submit" onclick="return confirm('Are you sure you want to submit this information?')" class="g-brd-main btn-block g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" > Submit
                          </button>
                        </div>

                      </div>

                 </div>

               </section>

              </div>


            </div>
          </div>
        </form>
      </section>
    </div>

    <!-- End Sidebar -->
  </div>
</div>
