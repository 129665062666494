<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
  </section>
  <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
    <div class="row">
       <div class="col-lg-12 g-mb-30">
          <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
             <h3 class="mb-0">
                <strong>
                   <p style="text-align:center;">RISK MANAGEMENT</p>
                </strong>
             </h3>
             <br/>
             <div class="row justify-content-between">
                <!-- About Image -->
                <div class="col-md-3 col-lg-4">
                   <img class="img-fluid" src="assets/img/risk_management.jpg" alt="Image Description">
                </div>
                <!-- End About Image -->
                <div class="col-md-8 col-lg-8">
                   <!-- About Info -->
                   <p>International Organisation for Standardization (ISO 31000), defines risk as “the effect of uncertainty on objectives”; this means the deviation from the expected objective which can be either positive or negative. It defines Risk Management as “Coordinated activities to direct and control an organisation with regards to risk.”
                   </p>
                   <p></p>
                   <p>Risk management has also been defined as “the process of making and implementing decisions that enable an organisation to optimize its level of risk”. Hence it can be inferred that risk management is the act of controlling the outcome of risk.
                   </p>
                   <p></p>
                </div>
             </div>
             <div class="row justify-content-between">
                <p>There are four main risk categories. They are: Hazard Risks, Operational Risks, Financial Risks and Strategic Risks.
                </p>
                <p></p>
                <p> <strong>Benefits of Risk Management</strong>
                </p>
                <p></p>
                <ul>
                   <li>It reduces the overall cost of risk for an organisation.</li>
                   <li>It minimizes the fallout from future accidental losses by making these losses less frequent, less severe and more foreseeable.</li>
                   <li>It provides the organisation with a framework to analyse the risks associated with an opportunity and then to manage those risks.</li>
                   <li>It helps to achieve the optimal risk-adjusted return on capital</li>
                </ul>
                <p></p>
                <p> <strong>What are the goals of risk management?</strong></p>
                <p></p>
                <ul style="float: left;">
                   <li>To ensure compliance with legal and regulatory obligations.</li>
                   <li>To ensure the longevity of an organisation by identifying threats and managing those risks appropriately.</li>
                   <li>To ensure business continuity.</li>
                   <li>To ensure profitability and growth.</li>
                   <li>To ensure stability and sustainability of earnings.</li>
                </ul>
                <p></p>
                <p><strong>Traditional Risk Management</strong> is concerned with an organisation’s pure risk and primarily hazard risk while <strong>Enterprise Risk Management (ERM)</strong> is concerned with managing all the organisation’s risks, including operational, financial and strategic risks. Pure risk is a type of risk that cannot be controlled or predicted. A good example is natural disaster and death.</p>
                <p></p>
                <p><strong>Enterprise Risk Management</strong></p>
                <p></p>
                <p>Risk and Insurance Management Society (RIMS) defined ERM as “a strategic business discipline that supports the achievement of an organisation’s objectives by addressing the full spectrum of its risks and managing the combined impact of those risks as an interrelated risk portfolio.”</p>
                <p></p>
                <p>ERM framework encompasses all stakeholders by making the entire organisation at all levels responsible for risk management. Also, effective communication is important for a successful ERM program, the risk management professionals must have access to data from all organisational areas and levels to identify and assess the organisation’s risks. </p>
                <p></p>
                <p>ERM identifies operational, financial, strategic, and hazard risks, develops understanding of their relationships; and evaluates the potential effect of the risk portfolio on an organisation’s ability to achieve its objectives. ERM seeks to optimize a risk management strategy that is integrated into the entire organisation.</p>
                <p></p>
                <p><strong>Operational Risk</strong></p>
                <p></p>
                <p>Operational risk is the risk of loss resulting from inadequate or failed internal processes, people and system or from external events. It is essential that each organisation defines its operational risk tolerance. An organisation can develop a framework for managing its operational risk by categorising operational risk into people, process, system and external events.</p>
                <p></p>
                <p><strong>Financial Risk</strong></p>
                <p></p>
                <p>Financial risk has two characteristics. First, it is an external risk with the potential to affect an organisation’s objectives. Second, the risk can be reduced through financial contracts, such as derivatives. There are three major types of financial risk: Market risk, Credit risk and Price risk.</p>
                <p></p>
                <p><strong>Strategic Risk</strong></p>
                <p></p>
                <p>Strategic Risk is defined as “risk that is common to all securities of the same general class and that therefore cannot be eliminated by diversification.” Strategic risks are external to an organisation. They are systemic and speculative risks that are outside the control of any individual organisation. </p>
                <p></p>
                <p>Although organisations cannot control these risks, risk management professionals can identify and understand these risks to help their organisations minimize negative risks while maximizing opportunity risks. The major strategic risks are: Economic environment, Demographics, and Political environment.</p>
                <p></p>
                <p>You can also reach us on 09048418896 or through <a href="mailto:customerservicesg@nsiainsurance.com">customerservicesg@nsiainsurance.com</a> for enquiries.</p>
             </div>
          </div>
          <!-- End About Info -->
       </div>
    </div>
  </div>
