import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-things-to-consider-before-taking-that-trip',
  templateUrl: './things-to-consider-before-taking-that-trip.component.html',
  styleUrls: ['./things-to-consider-before-taking-that-trip.component.css']
})
export class ThingsToConsiderBeforeTakingThatTripComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
