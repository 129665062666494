
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;"><strong>BREAST CANCER & HEALTH INSURANCE</strong></p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/oct19.jpg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>It is true that the incident rate of cancer has decreased over the past two decades, and early diagnosis have helped reduce the death toll associated with breast cancer. This month being Breast Cancer Awareness Month, consider what you could do to help spread awareness and fight the disease. </p>
                 <p></p>

              </div>
           </div>

          <div class="row justify-content-between">


              <div class="col-md-12">
                <p>One major cause of delayed breast cancer screening and treatment among women is insufficient health insurance coverage. We know that navigating the health insurance world can be stressful. Educating yourself about options and policies will help you make the best decisions for you or your loved one who may require it.

               </p>
                <p></p>
                <p>Health insurance fortifies your finances from taking a major dip during critical illnesses such as breast cancer. If you have a regular health insurance plan, the same may or may not provide sufficient coverage against breast cancer. </p>
                <p></p>

                <p>NSIA Health Insurance policy provides health insurance for companies who want to insure their key resources, and a solution for multinationals looking to harmonize their health insurance benefits across Africa and worldwide.
               </p>
              <p></p>

                 <p>As an employer, you can give your employees the opportunity to purchase health insurance at affordable group rates. It gives employees confidence in knowing that they will not have to jeopardize their financial position if they are ever faced with a critical illness such as cancer.</p>
               <p></p>
                 <p>As an employee, it is important to learn about the specifics of your policy by reviewing the coverage given by your employer. It’s a good idea to meet with someone from the human resources department to go over the specifics. Learning more about what your policy does and does not cover will help you better work with your insurer to make sure you receive all the benefits and coverage to which you are entitled. You will also be better prepared to deal with any questions or disputes you may encounter.</p>
                <p></p>

                <p>Have specific questions about health insurance that we didn’t cover? Need help picking a plan? Feel free to contact our Customer Service Team anytime on 09048418896 or visit our website at <a href="https://www.nsiainsurance.com">www.nsiainsurance.com</a> </p>


              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>