
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;">HERE’S WHAT YOU NEED TO KNOW ABOUT AUTO INSURANCE</p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/auto_insurance.jpg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>Experts often tell you to buy the car insurance you can afford, but that's not helpful unless you know the basics of how auto insurance works. Awareness of the insurance-buying process will save you time, effort and money throughout the transaction.
                 </p>
                 <p></p>
                 <p>Typically, auto insurance companies use many different criteria when evaluating an insurance application during a process called underwriting. During this process, auto insurance applicants are placed in a group based on how much money and how many claims the insurance company believes it may have to pay.
                 </p>
                 <p></p>


              </div>
           </div>
           <div class="row justify-content-between">
              <div class="col-md-12">
                <p>Auto insurance rates differ significantly from one insurance company to another. That's because each insurance company uses its own formula to assess risk and decide what you pay for coverage. The best plan is to decide what coverage and options you need and compare costs so you can find cheap auto insurance that still provides sufficient coverage.
                </p>
                <p></p>
                <p>Most insurance companies view drivers who are licensed but don't have insurance as risky, which may be why you end up paying more when you go back to buy auto insurance, if you let your policy expire lapse.
                </p>
                <p></p>
                <p>Generally, there are two types of coverage one can purchase when looking to purchase auto insurance:
                </p>
                <p></p>
                <p><strong>Third-Party Liability:</strong> Third Party Motor Insurance is one of the compulsory insurance covers in the country, made mandatory by the Insurance Act of 2003. It is the minimum insurance cover that every car owner must possess. Legally, you are not expected to drive on any public road without insurance. This policy provides indemnity to Third Parties for death, bodily injury and property damage resulting from an accident with the automobile of the insured. It has a limit of N1, 000,000.</p>
                <p></p>
                <p>
                  <strong>Comprehensive Liability:</strong> Comprehensive auto insurance policy offers protection to the policyholder against any accidental loss or damage to the vehicle, risk of fire, theft, and the cover provided under the Third-Party Liability.
                </p>
                <p></p>

                 <p>It is essential to know the difference between these two covers. In the case of third-party liability, damage to your vehicle or injury to you in case of an accident is not covered. The policy only covers damage to other vehicles and injury to the other vehicle's passenger(s). In comparison, comprehensive liability covers damage to your vehicle and injuries to you and your passenger(s), as well as damage against injury to driver and passenger(s) of the other vehicle(s) in the event of an accident.
                 </p>
                 <p></p>
                <p>Want to know more about our Auto Insurance products? Click here (<a href="https://nsiainsurance.com/motorinsurance">https://nsiainsurance.com/motorinsurance</a>), and if you have any questions, you can send us an email to <a href="mailto:customerservice@nsiainsurance.com">customerservice@nsiainsurance.com</a>. Better yet, send us a message on WhatsApp to +234 809 720 9218. </p>

              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>
