import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-savings-plan',
  templateUrl: './savings-plan.component.html',
  styleUrls: ['./savings-plan.component.css']
})
export class SavingsPlanComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
