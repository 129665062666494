
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;"><strong>NSIA DEFERRED ANNUITY PLAN</strong></p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/DAP.jpeg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>Sometimes we worry about our future and one major concern is life after retirement. If you’re looking for regular and guaranteed income in your sunset years, now is the best time to invest in a deferred annuity plan. In this plan, you can pay a lump sum money or in instalments to buy the annuity.</p>
                 <p></p>
                 <p>
                  “An annuity plan covers the financial risk of ‘living too long’ without adequate money for comfortable living,” - Dr. P. Nandagopal
                 </p>


              </div>
           </div>

          <div class="row justify-content-between">

              <div class="col-md-12">
                <p></p>
                <p>
                  NSIA Deferred Annuity Plan is an insurance contract designed for long-term savings that provides you with an avenue through which you can save towards a regular income in retirement. It also serves as an avenue to convert lump sums into regular income that help you to enjoy a luxurious life after your retirement.
                </p>
               <p></p>
               <p>
                The term “annuity” refers to a series of payments, and “defer” refers to the act of waiting to annuitize or take action on the annuity. If you eventually decide to annuitize, you can select a payment option from your insurance company’s list of choices. For example, you might choose income to be payable for your lifetime or your spouse’s lifetime (whichever is longer), or you might prefer to have payments continue subject to a five or ten-year guaranteed period.
               </p>
                 <p></p>
                 <p>
                  An annuity plan can cover one life (say, husband) or two lives (husband and wife) where after the death of one person, the other person would continue to receive annuity payments for his/her lifetime. In the unfortunate event of death of the policyholder, all premiums paid will be refunded - plus interest, if death occurs before the retirement date.
                 </p>
                 <p></p>

                 <p>
                  If the annuity has entered the payout phase, however, the insurer may simply keep the remaining money unless the contract includes a provision to keep paying benefits to the owner's heirs for a certain number of years.
                 </p>
                 <p></p>

                 <p>For more information, click here <a href="https://nsiainsurance.com/deferredannuity">www.nsiainsurance.com</a> or give us a call on 09048418896.</p>

              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>