<section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px">
    <div class="container">
      <div class="d-sm-flex text-center">
        <div class="align-self-center">
          <h2 class="h3 g-font-weight-300 w-100 g-mb-10 g-mb-0--md">
            <p>NSIA Savings Plan </p>
          </h2>
        </div>


      </div>
    </div>
  </section>
  <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
      <ul class="d-flex justify-content-between u-list-inline">
        <li class="list-inline-item g-mr-15">
          <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
          <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
        </li>
        <li class="list-inline-item">
          <a class="u-link-v5 g-color-text g-pr-10" routerLink="/lifeinsurance">Life Insurance</a>
          <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
        </li>
        <li class="list-inline-item g-color-primary">
          <span>NSIA Savings Plan</span>
        </li>

        <li class="list-inline-item ml-auto">
          <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
            routerLink="/lifequote">Get quote</a>
        </li>
      </ul>
    </div>
  </section>
  <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
    <div class="row">
      <div class="col-lg-9 g-mb-30">
        <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
          <h4><strong>
              <p>NSIA Savings Plan </p>
            </strong> </h4>

          <p>NSIA savings plan offers prospective policyholders a vehicle through which they can accumulate fund to meet future
          needs. This product is managed through a dedicated investment account into which policyholder’s contributions and
          attractive interest are credited. This policy also offers a life insurance policy as an attending feature which affords
          the beneficiary of a policy holder to be paid a certain guaranteed sum in the event of death of the policy holder. Here
          are some of the features:</p>
          <ul>
            <li>Policy Duration is three (3) years.</li>
            <li>Minimum contribution per month is N5,000.00</li>
            <li>The minimum sum assured is =N=100,000.00 whist the maximum sum assured is =N=2,000,000.00</li>
            <li>Minimum age at entry is 18 years whist the maximum age at entry is 60 years.</li>
            <li>The modal contribution less the cost of insurance is invested in the policyholder’s fund.</li>
            <li>Attractive interest is daily credited</li>
            <li>Waiting period is one month, if death occurs within this period, only the account balance will be paid and the policy
            ceases.</li>
            <li>The life cover on the policy lapses when premium is not paid after due date</li>
          </ul>

          <p></p>
          <p><strong>Benefits</strong></p>
          <p></p>
          <ul>
            <li>
              <p><strong>Maturity Benefit</strong></p>
             <p>At the end of the policy term, the policyholder receives account value of investment.</p>
            </li>
            <li>
              <p><strong>Death Benefit</strong></p>
              <p>The chosen sum in addition to the accumulated fund value is paid in the event of death of the policyholder during the
              policy term</p>
            </li>
            <li>
              <p><strong>Surrender Value Benefit</strong></p>
              <p>The balance in the savings account is payable on surrender subject to the following surrender charges:</p>
              <table class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>Duration</th>
                    <th>Surrender Charge (% of accumulated interest)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>100%</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>50%</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>0%</td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>
              <p><strong>Permanent Disability Cover –Optional Benefit</strong></p>
              <p>The chosen sum assured in addition to the accumulated fund value becomes payable where the policyholder suffered a
              permanent disability.</p>
            </li>
          </ul>

          <p></p>



        </div>
      </div>


      <!-- Sidebar -->
      <div class="col-lg-3 g-mb-30">
        <!-- Links -->
        <app-sidebar></app-sidebar>
        <!-- End Fast Facts -->
      </div>
      <!-- End Sidebar -->
    </div>
  </div>
