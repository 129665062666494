import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-brain-exercises-that-boost-memory',
  templateUrl: './brain-exercises-that-boost-memory.component.html',
  styleUrls: ['./brain-exercises-that-boost-memory.component.css']
})
export class BrainExercisesThatBoostMemoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
