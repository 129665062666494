
<div   style="background-color: #eee; margin:0px; padding-top: 0px;">
    <footer class="container g-pt-30 g-pb-10"  style="padding-top:0px;; text-decoration-color: white" >

                    <div class="row" style="padding-top:0px;;">
                            <div class="col-sm-6 col-md g-mb-10">
                              <a class="d-inline-block mb-4" href="#!">
                                <img class="g-width-160 g-height-auto" src="assets/img/NSIA-new-website-logo_2.png" alt="Image description">
                              </a>

                              <p class="g-color-gray-dark-v4 g-font-size-13">2021 &copy; All Rights Reserved.
                              Authorised and Regulated by the National Insurance Commission, RIC NO. 003.
    </p>
                            </div>
                            <div class="col-sm-6 col-md g-mb-10">
                              <h3 class="h6 g-color-gray-v1 g-font-weight-600 text-uppercase mb-4">About</h3>

                              <!-- Links -->
                              <ul class="list-unstyled g-color-gray-dark-v4 g-font-size-13">
                                <li class="my-3"><i class="mr-2 fa fa-angle-right"></i>
                                  <a class="u-link-v5 g-color-gray-dark-v4 g-color-primary--hover" routerLink="/aboutus">About</a>
                                </li>
                                <li class="my-3"><i class="mr-2 fa fa-angle-right"></i>
                                  <a class="u-link-v5 g-color-gray-dark-v4 g-color-primary--hover" routerLink="/contactus">Contact us</a>
                                </li>
                                <li class="my-3"><i class="mr-2 fa fa-angle-right"></i>
                                  <a class="u-link-v5 g-color-gray-dark-v4 g-color-primary--hover" routerLink="/career">Careers</a>
                                </li>
                                <li class="my-3"><i class="mr-2 fa fa-angle-right"></i>
                                  <a class="u-link-v5 g-color-gray-dark-v4 g-color-primary--hover" routerLink="/faq">FAQ</a>
                                </li>
                                <li class="my-3"><i class="mr-2 fa fa-angle-right"></i>
                                  <a class="u-link-v5 g-color-gray-dark-v4 g-color-primary--hover" routerLink="/privacy">Privacy Notice</a>
                                </li>
                              </ul>
                              <!-- End Links -->
                            </div>
                            <div class="col-sm-6 col-md g-mb-10">
                              <h3 class="h6 g-color-gray-v1 g-font-weight-600 text-uppercase mb-4">Forms</h3>

                              <!-- Links -->
                              <ul class="list-unstyled g-color-gray-dark-v4 g-font-size-13">
                                <!-- <li class="my-3"><i class="mr-2 fa fa-angle-right"></i>
                                  <a class="u-link-v5 g-color-gray-dark-v4 g-color-primary--hover" routerLink="/beneficiary-form">Grouplife Beneficiary Form</a>
                                </li> -->
                                <li class="my-3"><i class="mr-2 fa fa-angle-right"></i>
                                  <a class="u-link-v5 g-color-gray-dark-v4 g-color-primary--hover" routerLink="/proposal-form">Life Proposal Form</a>
                                </li>
                                <li class="my-3"><i class="mr-2 fa fa-angle-right"></i>
                                  <a class="u-link-v5 g-color-gray-dark-v4 g-color-primary--hover" routerLink="/general-proposal-form">General Proposal Form</a>
                                </li>
                                 <li class="my-3"><i class="mr-2 fa fa-angle-right"></i>
                                  <a class="u-link-v5 g-color-gray-dark-v4 g-color-primary--hover" routerLink="/debit-mandate-form">Debit Mandate Form</a>
                                </li>
                                <li class="my-3"><i class="mr-2 fa fa-angle-right"></i>
                                  <a class="u-link-v5 g-color-gray-dark-v4 g-color-primary--hover" routerLink="/house-holder-insurance">House Holder Insurance Form</a>
                                </li>
                                <li class="my-3"><i class="mr-2 fa fa-angle-right"></i>
                                  <a class="u-link-v5 g-color-gray-dark-v4 g-color-primary--hover" routerLink="/customer-form">Customer Update Form</a>
                                </li>
                                <li class="my-3"><i class="mr-2 fa fa-angle-right"></i>
                                  <a class="u-link-v5 g-color-gray-dark-v4 g-color-primary--hover" routerLink="/downloadable">Download Forms</a>
                                </li>
                                <li class="my-3"><i class="mr-2 fa fa-angle-right"></i>
                                  <a class="u-link-v5 g-color-gray-dark-v4 g-color-primary--hover" routerLink="/whistle-blower">Whistle Blower</a>
                                </li>

                              </ul>
                               <h3 class="h6 g-color-gray-v1 g-font-weight-600 text-uppercase mb-4">Group Life Portal</h3>
                              <ul class="list-unstyled g-color-gray-dark-v4 g-font-size-13">
                                <li class="my-3"><i class="mr-2 fa fa-angle-right"></i>
                                  <a class="u-link-v5 g-color-gray-dark-v4 g-color-primary--hover" href="https://Grouplife.nsiainsurance.com">Grouplife Beneficiary Portal</a>
                                </li>
                               </ul>
                              <!-- End Links -->
                            </div>
                            <div class="col-sm-6 col-md g-mb-10">
                              <h3 class="h6 g-color-black g-font-weight-600 text-uppercase mb-4">Contacts</h3>

                              <!-- Links -->
                              <ul class="list-unstyled g-color-gray-dark-v4 g-font-size-13">
                                <li class="media mb-4">
                                  <i class="d-flex mt-1 icon-hotel-restaurant-235 u-line-icon-pro"></i>
                                  <div class="media-body">
                                      <i class="fa fa-map-marker"></i>  &nbsp;   3, Elsie Femi Pearse Street,
                                    <br>Victoria Island. Lagos.
                                  </div>
                                </li>
                                <li class="media mb-4">
                                  <i class="d-flex mt-1 icon-communication-062 u-line-icon-pro"></i>
                                  <div class="media-body">
                                    <a class="u-link-v5 g-color-gray-dark-v4 g-color-primary--hover" href="#!"><i class="fa fa-envelope"></i> &nbsp; enquiry@nsiainsurance.com</a>
                                  </div>
                                </li>
                                <li class="media mb-4">
                                  <i class="d-flex mt-1 icon-communication-033 u-line-icon-pro"></i>
                                  <div class="media-body">
                                      <i class="fa fa-mobile"></i> &nbsp;      01 280 5378 - 9.
                                  </div>
                                </li>
                                <li class="media mb-4">
                                  <i class="d-flex mt-1 icon-communication-033 u-line-icon-pro"></i>
                                  <div class="media-body">
                                    <i class="fa fa-mobile"></i> &nbsp;  Tel: 08097209218.
                                </div>
                                </li>
                                <li class="media mb-4">
                                  <i class="d-flex mt-1 icon-communication-033 u-line-icon-pro"></i>
                                <div class="media-body">
                                  <i class="fa fa-mobile"></i> &nbsp;WhatsApp: 09048418896.
                              </div>
                                </li>
                              </ul>
                              <!-- End Links -->
                            </div>
                            <div class="col-sm-6 col-md g-mb-10">
                              <h3 class="h6 g-color-black g-font-weight-600 text-uppercase mb-4">Follow Us</h3>

                              <!-- Links -->
                              <!-- <ul class="list-unstyled g-color-gray-dark-v4 g-font-size-13">
                                <li class="my-3"><i class="mr-2 fa fa-angle-right"></i>
                                  <a class="u-link-v5 g-color-gray-dark-v4 g-color-primary--hover" href="https://www.facebook.com/NSIA-Insurance-Limited-596640117043430/">Facebook</a>
                                </li>
                                <li class="my-3"><i class="mr-2 fa fa-angle-right"></i>
                                  <a class="u-link-v5 g-color-gray-dark-v4 g-color-primary--hover" href="https://twitter.com/NSIAInsurance">Twitter</a>
                                </li>

                                <li class="my-3"><i class="mr-2 fa fa-angle-right"></i>
                                  <a class="u-link-v5 g-color-gray-dark-v4 g-color-primary--hover" href="https://www.linkedin.com/company/nsia-insurance-ltd">Linkedin</a>
                                </li>
                              </ul> -->
                              <!-- End Links -->
                               <!-- Social Icons -->
            <ul class="list-inline g-pos-rel g-top-minus-3 g-py-20 g-py-6--lg g-pr-5 g-mr-60 g-mr-0--lg ml-auto ml-lg-0 mb-0">
              <li class="list-inline-item g-mx-0">
                <a class="u-icon-v3 u-icon-size--xs g-bg-transparent g-bg-main--hover rounded" href="https://www.facebook.com/NSIA-Insurance-Limited-596640117043430/"><i class="fa fa-facebook"></i></a>
              </li>
              <li class="list-inline-item g-mx-0">
                <a class="u-icon-v3 u-icon-size--xs g-bg-transparent g-bg-main--hover rounded" href="https://twitter.com/nsia_insure"><i class="fa fa-twitter"></i></a>
              </li>
              <li class="list-inline-item g-mx-0">
                <a class="u-icon-v3 u-icon-size--xs g-bg-transparent g-bg-main--hover rounded" href="https://www.linkedin.com/company/11203703/admin/"><i class="fa fa-linkedin"></i></a>
              </li>
              <li class="list-inline-item g-mx-0">
                <a class="u-icon-v3 u-icon-size--xs g-bg-transparent g-bg-main--hover rounded" href="https://www.instagram.com/nsia_insure/"><i class="fa fa-instagram"></i></a>
              </li>
            </ul>
            <!-- End Social Icons -->
                            </div>
                          </div>


                        </footer>
    </div>

