<!-- <section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px">
        <div class="container" >
          <div class="d-sm-flex text-center">
            <div class="align-self-center">
              <h2 class="h3 g-font-weight-300 w-100 g-mb-10 g-mb-0--md" ><p>About Us</p></h2>
            </div>
          </div>
        </div>
      </section> -->

      <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
        <div class="container">
          <ul class="d-flex justify-content-between u-list-inline">
            <li class="list-inline-item g-mr-15">
              <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
              <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
            </li>
            <li class="list-inline-item g-color-primary">
                    <span>About Us</span>
                  </li>

            <li class="list-inline-item ml-auto">
              <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get Quote</a>
            </li>
          </ul>
        </div>
      </section>
      <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
            <div class="row">
              <div class="col-lg-9 g-mb-30">
                  <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
                        <h3 class="mb-0"><strong><p>About Us</p></strong></h3><br/>

                <div class="row justify-content-between">
                  <!-- About Image -->
                  <div class="col-md-3 col-lg-4">
                    <img class="img-fluid" src="assets/img/sliders/office1.jpg" alt="Image Description">
                  </div>
                  <!-- End About Image -->
                  <div class="col-md-8 col-lg-8">
                    <!-- About Info -->
                    <p>At Groupe NSIA, we believe that providing the best risk management for companies and individuals comes from our expert
                    knowledge of the industries in which we operate combined with our dedication to looking out for our clients' interest,
                    always.</p>
                    <p></p>
                    <p>Following our successful acquisition of ADIC Insurance Ltd., one of Nigeria's leading distributors of diversified
                    insurance products and services, NSIA has continued ADIC's tradition of providing services that consistently meet
                    customer and regulatory requirements.
                    </p>
                </div>
                </div>
                <div class="row justify-content-between">
             <div class="col-md-12">
                    <p>Groupe NSIA's Holding companies currently operate throughout Africa including Cameroon, Congo, Gabon Benin, Cote d'ivoire,
                    Ghana, Guinea, Guinea Bissau, Mali, Nigeria, Togo, Senegal. We are driven by our commitment to innovation,
                    professionalism, reliability and rates that are highly competitive and matched only by the excellence of the products and
                    services provided.</p>
                    <p></p>
                    <p>So whether you need to protect your business, the investment you have in your home or automobile, or your family in case
                    of tragedy, NSIA is here to help you manage risk wisely and invest in peace of mind.</p>
                    <p></p>
                    <p>Call or email us today to let our professional team guide you through your options and help you decide what cover is the
                    best for your needs.</p>
                    <p></p>
                    <h4><strong><p>Our Mission</p></strong> </h4>
                    <p></p>
                    <p>To Create, Protect and Preserve Wealth.</p>
                    <p></p>
                    <h4><strong><p>Our Vision</p></strong> </h4>
                    <p></p>
                    <p>To distinguish NSIA as the trusted and preferred financial services partner in every home and enterprise in Nigeria.</p>
                    <p></p>
                    <h4><strong><p>Core Values</p></strong> </h4>
                    <p></p>
                    <ul>
                        <!-- <li style="color:#bbb"><p>Values<br>
                          Assume the consequences of his actions and those of the collaborators for whom he is responsible.
                        </p></li> -->
                        <li style="color:#bbb"><p>Care <br>
                          We are concerned about the wellbeing of our stakeholders and because satisfaction is our ultimate goal, we go the extra mile.
                        </p>
                      </li>
                        <li style="color:#bbb"><p>Innovation
                          <br>
                          We are continuously creative in meeting the different needs of our customers.
                        </p> </li>
                      <li style="color:#bbb"><p>Integrity
                          <br>
                          Transparency is our watchword. We have strong moral principles and maintain consistent standards in all our dealings.
                        </p>
                      </li>
                        <li style="color:#bbb"><p>Professionalism
                          <br>
                          We pride ourselves in the competence and skills of our people, which is evident in the quality of our work.
                        </p>
                        </li>

                    </ul>
                    <p></p>
                    <div class="headline">
                       <h4><strong><p>NSIA Quality Objectives</p></strong> </h4>
                      </div>
                    <p></p>
                    <ol>
                        <li style="color:#bbb"><p>To pay claims on all classes of business within 48 hours following the receipt of the signed discharged vouchers.</p></li>
                        <li style="color:#bbb"><p>To ensure zero-infraction in compliance with statutory requirements.</p></li>
                        <li style="color:#bbb"><p>To achieve Customer Satisfaction Index of 90%. </p></li>
                        <li style="color:#bbb"><p>To achieve Employee Satisfaction Index of 90%.</p></li>
                        <li style="color:#bbb"><p>To ensure improvement of QMS in compliance with ISO 9001:2015 standard by conducting annual quality audit and ensuring
                        all identified non-conformance are remediated within three months.</p></li>

                    </ol>
                    <p></p>
                    <div class="headline">
                       <h4><strong><p>NSIA Quality Policy</p></strong> </h4>
                      </div>
                    <p></p>
                    <p>At NSIA Insurance, we are committed to the continuous improvement of the effectiveness of our Quality Management System. In order
                    to achieve this, we review and monitor the validity of our strategic direction whilst assessing internal and external
                    influencers to determine the effect on our business. NSIA strives to operate transparently and comply with relevant
                    regulations and statutory requirements; we review our products and services to ensure that they meet customers'
                    requirements and encourage quality consciousness among all interested parties.</p>
                    <p></p>
                </div>
                </div>
            </div>
                  <!-- End About Info -->
              </div>

              <!-- Sidebar -->
              <div class="col-lg-3 g-mb-30">
                <!-- Links -->
                <app-sidebar></app-sidebar>
                <!-- End Fast Facts -->
              </div>
              <!-- End Sidebar -->
            </div>
          </div>
