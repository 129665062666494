<div class="container g-pt-100 g-pb-70">
  <div class="row">
    <div class="col-lg-12 g-mb-30">
      <section class="g-bg-gray-light-v5 g-py-20">
        <form novalidate (ngSubmit)="submitForm(beneficiaryForm)" #beneficiaryForm="ngForm" novalidate>
          <div class="container g-py-100 pad-top-50" style="margin-top:-50px">
            <div class="u-shadow-v19 g-brd-around g-brd-gray-light-v4  rounded mx-auto g-pa-30 g-pa-50--md">

              <div class="mb-6">
                <h2 class="mb-4 main-heading">
                 Individual Life Proposal Form
                </h2>
              <p style="text-align: center;">An Insurance Agent who assists an applicant to complete an application or proposal form for insurance shall be deemed to have done so as the agent of the applicant” section 54(2), Insurance Act 2003</p>

                <!-- General Information -->
                <section class="step-two" >
                  <div class="form-sub-title">
                    <h4>General Information</h4>
                  </div>
                  <div class="row">

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                         Type of cover
                        </label>
                        <select name="PremiumType" id="PremiumType" class="form-control form-control-md rounded-0" type="text"
                        placeholder="Required" [(ngModel)]="proposalModel.PremiumType" required >
                            <option value="" >Select premium type</option>
                            <option value="1">Life to be assured</option>
                            <option value="2">Policy Holder</option>
                            <option value="3">Both</option>
                      </select>
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label >Title</label>
                        <select name="title" id="title" class="form-control form-control-md rounded-0" type="text"
                        placeholder="Required" [(ngModel)]="proposalModel.title" required [attr.required] = "productType == 1">
                            <option >Select title</option>
                            <option value="Mr">Mr</option>
                            <option value="Mrs">Mrs</option>
                            <option value="Dr">Dr</option>
                            <option value="Prof">Prof</option>
                            <option value="Chief">Chief</option>
                            <option value="Engineer">Engineer</option>
                      </select>
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label >Surname</label>
                        <input id="Surname" name="Surname" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.Surname" required>
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          OtherNames
                        </label>
                        <input id="OtherNames" name="OtherNames" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.OtherNames"  >
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Maiden Name
                        </label>
                        <input id="MaidenName" name="MaidenName" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.MaidenName" >
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20" >
                        <label>
                          Sex
                        </label>
                        <select name="Sex" id="Sex" class="form-control form-control-md rounded-0" type="text"
                        placeholder="Required" [(ngModel)]="proposalModel.Sex" >
                            <option value="">Select your gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                      </select>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Marital Status
                        </label>
                        <select name="MaritalStatus" id="MaritalStatus" class="form-control form-control-md rounded-0" type="text"
                        placeholder="Required" [(ngModel)]="proposalModel.MaritalStatus" >
                            <option value="">Select your marital status</option>
                            <option value="Married">Married</option>
                            <option value="Single">Single</option>
                            <option value="Widowed">Widowed </option>
                            <option value="Divorced4">Divorced </option>
                      </select>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Residential address
                        </label>
                        <input id="ResidentialAddress" name="ResidentialAddress" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.ResidentialAddress" required >
                      </div>
                    </div>
                    <!-- <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Postal Address
                        </label>
                        <input id="PostalAddress" name="PostalAddress" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.PostalAddress" required>
                      </div>
                    </div> -->
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          E- mail address
                        </label>
                        <input id="EmailAddress" name="EmailAddress" class="form-control form-control-md rounded-0" type="email"
                          placeholder="Required" [(ngModel)]="proposalModel.EmailAddress" required >
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Mobile no
                        </label>
                        <input id="Telephone" name="Telephone" class="form-control form-control-md rounded-0" type="number"
                          placeholder="Required" [(ngModel)]="proposalModel.Telephone" required placeholder="07035265975" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==11) return false; return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57));" >
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Second  Mobile no
                        </label>
                        <input id="AlternativeTel" name="AlternativeTel" class="form-control form-control-md rounded-0" type="number"
                       [(ngModel)]="proposalModel.AlternativeTel"  placeholder="07035265975" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==11) return false; return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57));" >
                      </div>
                    </div>
                    <!-- <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Industry Sector
                        </label>
                        <input id="OfficeTelephone" name="OfficeTelephone" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.OfficeTelephone" required>
                      </div>
                    </div> -->
                    <div class="col-lg-4" >
                      <div class="form-group g-mb-20">
                        <label>
                          Date of Birth
                        </label>
                        <input id="DateOfBirth" name="DateOfBirth" class="form-control form-control-md rounded-0" type="date"
                          placeholder="Required" [(ngModel)]="proposalModel.DateOfBirth" (focusout)="focusOutFunction()">
                      </div>
                    </div>


                    <div class="col-lg-4" >
                      <div class="form-group g-mb-20">
                        <label>
                          Age
                        </label>
                        <input id="Age" name="Age" class="form-control form-control-md rounded-0" type="number"
                          placeholder="Required" [(ngModel)]="proposalModel.Age">
                      </div>
                    </div>


                    <div class="col-lg-4" >
                      <div class="form-group g-mb-20">
                        <label>
                          State of Origin
                        </label>
                        <select name="StateOfOrigin" id="StateOfOrigin" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.StateOfOrigin" >
                            <option >Select your state</option>
                            <option *ngFor="let state of states; let i = index" value="{{state}}">{{state}}</option>
                      </select>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Nationality
                        </label>
                        <select name="Nationality" id="Nationality" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.Nationality" >
                            <option >Select your nationality</option>
                            <option *ngFor="let country of countries; let i = index" value="{{country.name}}">{{country.name}}</option>
                      </select>
                      </div>
                    </div>
                    <div class="col-lg-4" >
                      <div class="form-group g-mb-20">
                        <label>
                          Occupation
                        </label>
                        <input id="Occupation" name="Occupation" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.Occupation" >
                      </div>
                    </div>
                    <div class="col-lg-4" >
                      <div class="form-group g-mb-20">
                        <label>
                          Name of Business
                        </label>
                        <input id="EmployerName" name="EmployerName" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.EmployerName">
                      </div>
                    </div>
                    <div class="col-lg-4" >
                      <div class="form-group g-mb-20">
                        <label>
                          Position/Designation
                        </label>
                        <input id="Position" name="Position" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.Position">
                      </div>
                    </div>
                    <!-- <div class="col-lg-4" [style.display] = "productType == 1? 'block':'none'">
                      <div class="form-group g-mb-20">
                        <label>
                          Nature of work
                        </label>
                        <input id="NatureOfWork" name="NatureOfWork" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.NatureOfWork" [attr.required] = "productType == 1" >
                      </div>
                    </div> -->
                    <div class="col-lg-4" >
                      <div class="form-group g-mb-20">
                        <label>
                         Address of Business
                        </label>
                        <input id="EmployersAddress" name="EmployersAddress" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.EmployersAddress">
                      </div>
                    </div>

                    <div class="col-lg-4" >
                      <div class="form-group g-mb-20">
                        <label>
                         Tin
                        </label>
                        <input id="Tin" name="Tin" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.Tin">
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Country of residence
                        </label>
                        <select name="CountryOfResidence" id="CountryOfResidence" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.CountryOfResidence" >
                            <option >Select your nationality</option>
                            <option *ngFor="let country of countries; let i = index" value="{{country.name}}">{{country.name}}</option>
                      </select>
                      </div>
                    </div>

                    <div class="col-lg-4" >
                      <div class="form-group g-mb-20">
                        <label>
                          Type Of Policy
                        </label>
                          <select name="policy" id="policy" class="form-control form-control-md rounded-0"
                          placeholder="Required" [(ngModel)]="proposalModel.policy" (focusout)="focusOutFunctionPolicy()">
                          <option value="" selected disabled>Select your option</option>
                          <option value="Term Assurance">Term Assurance</option>
                          <option value="Keyman Assurance">Keyman Assurance</option>
                          <option value="Credit Life (NSIA Super Protector)">Credit Life (NSIA Super Protector)</option>
                          <option value="Endowment Assurance Plan">Endowment Assurance Plan </option>
                          <option value="Anticipated Endowment Assurance">Anticipated Endowment Assurance </option>
                          <option value="Education Endowment Assurance Plan">Education Endowment Assurance Plan </option>
                          <option value="NSIA Education Protection Plan (EPP)">NSIA Education Protection Plan (EPP) </option>
                          <option value="NSIA Deferred Annuity Plan">NSIA Deferred Annuity Plan </option>
                          <option value="Whole Insurance  (NSIA Living Companion)">Whole Insurance (NSIA Living Companion) </option>
                          <option value="NSIA Investment Linked Plan">NSIA Investment Linked Plan  </option>
                          <option value="NSIA Savings Plan">NSIA Savings Plan </option>
                          <option value="Group Life">Group Life </option>
                          <option value="Health">Health </option>

                          </select>
                  </div>
                </div>


                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                        Policy commencement date
                        </label>
                        <input id="ComencementDate" name="proposalModel.MedicalData.ComencementDate" class="form-control form-control-md rounded-0" type="date"
                          placeholder="Required" [(ngModel)]="proposalModel.ComencementDate" >
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Policy Tenor
                        </label>
                        <select name="proposalModel.MedicalData.Duration" id="Duration" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.Duration" (focusout)="focusOutFunctionPolicyTenor()">
                            <option value="0">Select your option</option>
                            <option value="1">1 year</option>
                            <option value="2">2 years</option>
                            <option value="3">3 years</option>
                            <option value="4">4 years</option>
                            <option value="5">5 years</option>
                            <option value="6">6 years</option>
                            <option value="7">7 years</option>
                            <option value="8">8 years</option>
                            <option value="9">9 years</option>
                            <option value="10">10 years</option>
                            <option value="11">11 years</option>
                            <option value="12">12 years</option>
                            <option value="13">13 years</option>
                            <option value="14">14 years</option>
                            <option value="15">15 years</option>
                            <option value="16">16 years</option>
                            <option value="17">17 years</option>
                            <option value="18">18 years</option>
                            <option value="19">19 years</option>
                            <option value="20">20 years</option>

                        </select>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                         Total Sum Assured
                        </label>
                        <input id="SumAssurred" name="proposalModel.SumAssurred" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.SumAssurred" OnlyNumber="true" >
                      </div>
                    </div>
                    <div class="col-lg-4" >
                      <div class="form-group g-mb-20">
                        <label>
                        Premium
                        </label>
                        <input id="Premium" name="Premium" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.Premium" OnlyNumber="true" >
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Frequency of  payment
                        </label>
                        <select name="PaymentFrequency" id="PaymentFrequency" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.PaymentFrequency"  >
                            <option value="0">Select your option</option>
                            <option value="1">Monthly</option>
                            <option value="2">Quarterly</option>
                            <option value="3">Half Yearly </option>
                            <option value="4">Yearly </option>
                            <option value="5">Single Premium </option>
                      </select>
                      </div>
                    </div>


                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Mode of payment
                        </label>
                        <select name="proposalModel.ModeOfPayment" id="ModeOfPayment" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.ModeOfPayment">
                            <option value="NIL">Select your option</option>
                            <option value="Cash">Cash</option>
                            <option value="Transfer">Transfer</option>
                            <option value="Direct Debit Mandate">Direct Debit Mandate</option>

                        </select>
                      </div>
                    </div>

                    <div class="col-lg-4" >
                      <div class="form-group g-mb-20">
                        <label>
                         Agent/Broker
                        </label>
                        <input id="Agent" name="Agent" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.Agent">
                      </div>
                    </div>

                    <!-- <div class="col-lg-4">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group g-mb-0">
                            <label style="visibility: hidden;">Frequency</label>
                            <button (click) ="Next()" type="button" class="g-brd-main btn-block g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" > Next
                            </button>
                          </div>

                        </div>
                        <div class="col-lg-6">
                          <div class="form-group g-mb-0">
                            <label style="visibility: hidden;">Frequency</label>
                            <button (click) ="Previous()" type="button" class="g-brd-main btn-block g-brd-danger--hover g-bg-main g-bg-danger--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" > Previous
                            </button>
                          </div>

                        </div>
                      </div>

                    </div> -->

                  </div>
                </section>
                <!-- Medical Informations -->
                <section class="step-three">
                  <div class="form-sub-title">
                    <h4>Medical Informations</h4>
                  </div>
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Hospital Name
                        </label>
                        <input id="Name" name="proposalModel.MedicalData.Name" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="medicalModel.Name" placeholder="Name of hospital / NIL">
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Hospital Address
                        </label>
                        <input id="hAddress" name="proposalModel.MedicalData.Address" class="form-control form-control-md rounded-0" type="text"
                          [(ngModel)]="medicalModel.Address" placeholder="Hospital Address / NIL"  >
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Hospital Phone Number
                        </label>
                        <input id="hPhoneNumber" name="proposalModel.MedicalData.PhoneNumber" class="form-control form-control-md rounded-0" type="number"
                         [(ngModel)]="medicalModel.PhoneNumber"  placeholder="07035265975" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==11) return false; return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57));" >
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Hospital Email Address
                        </label>
                        <input id="hEmailAddress" name="proposalModel.MedicalData.EmailAddress" class="form-control form-control-md rounded-0" type="email"
                          [(ngModel)]="medicalModel.EmailAddress" placeholder="Hospital Email / NIL" >
                      </div>
                    </div>
                    <div class="col-lg-4" >
                      <div class="form-group g-mb-20">
                        <label>
                          How long have you been attending the hospital?
                        </label>
                        <input id="hAttendanceLifeSpan" name="proposalModel.MedicalData.AttendanceLifeSpan" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="medicalModel.AttendanceLifeSpan" >
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Date Last Attended
                        </label>
                        <input id="hDateLastAttended" name="proposalModel.MedicalData.DateLastAttended" class="form-control form-control-md rounded-0" type="date"
                          [(ngModel)]="medicalModel.DateLastAttended" >
                      </div>
                    </div>
                    <div class="col-lg-4" >
                      <div class="form-group g-mb-20">
                        <label>
                          Reason
                        </label>
                        <select name="AttendanceReason" id="proposalModel.MedicalData.AttendanceReason" class="form-control form-control-md rounded-0"
                       [(ngModel)]="medicalModel.AttendanceReason">
                            <option value="">Select your option</option>
                            <option value="None">None</option>
                            <option value="Admission">Admission</option>
                            <option value="Treatment">Treatment</option>
                      </select>
                      </div>
                    </div>
                    <div class="col-lg-4" >
                      <div class="form-group g-mb-20">
                        <label>
                          Height
                        </label>
                        <input id="hHeight" name="proposalModel.MedicalData.Height" class="form-control form-control-md rounded-0" type="text"
                          [(ngModel)]="medicalModel.Height"  OnlyNumber="true" placeholder="50" required="">
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Weight
                        </label>
                        <input id="hWeight" name="proposalModel.MedicalData.Weight" class="form-control form-control-md rounded-0" type="text"
                          [(ngModel)]="medicalModel.Weight" OnlyNumber="true" placeholder="50" required="">
                      </div>
                    </div>
                    <div class="col-lg-4" >
                      <div class="form-group g-mb-20">
                        <label>
                          Blood Pressure
                        </label>
                        <input id="hBloodPressure" name="proposalModel.MedicalData.BloodPressure" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="medicalModel.BloodPressure"  placeholder="120/80">
                      </div>
                    </div>
                    <div class="col-lg-4" >
                      <div class="form-group g-mb-20">
                        <label>
                          Alcohol consumption
                        </label>
                        <select name="proposalModel.MedicalData.AlchoholConsumption" id="hAlchoholConsumption" class="form-control form-control-md rounded-0"
                       [(ngModel)]="medicalModel.AlchoholConsumption" >
                            <option value="NOT APPLICABLE" selected>Select your option</option>
                            <option value="None">None </option>
                            <option value="Occasionally">Occasionally</option>
                            <option value="Daily">Daily</option>
                      </select>
                      </div>
                    </div>
                    <div class="col-lg-4" >
                      <div class="form-group g-mb-20">
                        <label>
                         Are you Pregnant?
                        </label>
                        <select name="proposalModel.MedicalData.IsPregnant" id="hIsPregnant" class="form-control form-control-md rounded-0"
                        [(ngModel)]="medicalModel.IsPregnant">
                            <option value="">Select your option</option>
                            <option value="1">Yes </option>
                            <option value="2">No</option>
                      </select>
                      </div>
                    </div>
                    <div class="col-lg-4" *ngIf="proposalModel.MedicalData.IsPregnant == 1">
                      <div class="form-group g-mb-20">
                        <label>
                          when is the EDD
                        </label>
                        <input id="hEdd" name="proposalModel.MedicalData.Edd" class="form-control form-control-md rounded-0" type="date"
                           [(ngModel)]="medicalModel.Edd" [attr.required] = " proposalModel.MedicalData.IsPregnant == 1" >
                      </div>
                    </div>
                    <div class="col-lg-4" >
                      <div class="form-group g-mb-20">
                        <label>
                          Have you suffered or suffering from:
                        </label>
                        <input  name="proposalModel.MedicalData.RecurringDesease"  type="checkbox"
                          (change)="addDisease($event)"  [(ngModel)]="medicalModel.RecurringDesease[0]" value="tuberculosis" > tuberculosis
                          <input  name="proposalModel.MedicalData.RecurringDesease"  type="checkbox"
                          (change)="addDisease($event)" [(ngModel)]="medicalModel.RecurringDesease[1]" value="epilepsy" > epilepsy
                          <input  name="proposalModel.MedicalData.RecurringDesease"  type="checkbox"
                          (change)="addDisease($event)" [(ngModel)]="medicalModel.RecurringDesease[2]" value="heart disease" > heart disease
                          <input  name="proposalModel.MedicalData.RecurringDesease"  type="checkbox"
                          (change)="addDisease($event)" [(ngModel)]="medicalModel.RecurringDesease[3]" value="Diabetes"  > Diabetes
                      </div>
                    </div>
                    <!-- <div class="col-lg-4" >
                      <div class="form-group g-mb-20">
                        <label>
                          Any other (please specify)
                        </label>
                        <input id="hRecurringDesease" name="proposalModel.MedicalData.RecurringDesease" class="form-control form-control-md rounded-0" type="text"
                          [(ngModel)]="medicalModel.RecurringDesease[4]" >
                      </div>
                    </div> -->
                    <div class="col-lg-4" >
                      <div class="form-group g-mb-20">
                        <label>
                          Do you usually enjoy good health?
                        </label>
                        <select name="proposalModel.MedicalData.ISGoodHealth" id="hISGoodHealth" class="form-control form-control-md rounded-0"
                         [(ngModel)]="medicalModel.ISGoodHealth" >
                            <option value="">Select your option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                      </select>

                      </div>
                    </div>
                    <div class="col-lg-4" *ngIf="proposalModel.MedicalData.ISGoodHealth =='Yes'">
                      <div class="form-group g-mb-20">
                        <label>
                          Please give details
                        </label>
                        <input id="hHealthReason" name="proposalModel.MedicalData.HealthReason" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="medicalModel.HealthReason" [attr.required] = "proposalModel.MedicalData.ISGoodHealth == 1">
                      </div>
                    </div>
                    <div class="col-lg-4" >
                      <div class="form-group g-mb-20">
                        <label>
                          Are you suffering from any terminal disease?
                        </label>
                        <select name="proposalModel.MedicalData.TerminalDesease" id="hTerminalDesease" class="form-control form-control-md rounded-0"
                       [(ngModel)]="medicalModel.TerminalDesease">
                            <option value="">Select your option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                      </select>

                      </div>
                    </div>
                    <div class="col-lg-4" *ngIf="proposalModel.MedicalData.TerminalDesease == 'Yes'">
                      <div class="form-group g-mb-20">
                        <label>
                          Please give details
                        </label>
                        <input id="hDiseaseDetails" name="proposalModel.MedicalData.DiseaseDetails" class="form-control form-control-md rounded-0" type="text"
                          [(ngModel)]="medicalModel.DiseaseDetails" [attr.required] = "proposalModel.MedicalData.TerminalDesease == 1" >
                      </div>
                    </div>


                    <!-- <div class="col-lg-4" [style.display] = "productType == 2? 'block':'none'">
                      <div class="form-group g-mb-20">
                        <label>
                          Number of Members
                        </label>
                        <input id="NumberOfMembers" name="proposalModel.MedicalData.NumberOfMembers" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.NumberOfMembers" [attr.required] = "productType == 2" >
                      </div>
                    </div> -->
                    <!-- <div class="col-lg-4">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group g-mb-0">
                            <label style="visibility: hidden;">Frequency</label>
                            <button (click) ="Next()" type="button" class="g-brd-main btn-block g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" > Next
                            </button>
                          </div>

                        </div>
                        <div class="col-lg-6">
                          <div class="form-group g-mb-0">
                            <label style="visibility: hidden;">Frequency</label>
                            <button (click) ="Previous()" type="button" class="g-brd-main btn-block g-brd-danger--hover g-bg-main g-bg-danger--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" > Previous
                            </button>
                          </div>

                        </div>
                      </div>

                    </div> -->

                  </div>
                </section>
                <!-- Beneficiaries -->
                <section class="step-four" >
                  <div class="form-sub-title">
                    <h4>Beneficiaries</h4>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group g-mb-20">
                        <label>
                          Check if you have any policy/scheme with NSIA or other Companies
                        </label>
                        <input  name="proposalModel.OtherPolicy"  type="checkbox"
                          (change)="addPolicy($event)"  [(ngModel)]="proposalModel.OtherPolicy[0]" value="NSIA" > NSIA
                          <input  name="proposalModel.OtherPolicy"  type="checkbox"
                          (change)="addPolicy($event)" [(ngModel)]="proposalModel.OtherPolicy[1]" value="Another Insurance Company" > Another Insurance Company
                          <input  name="proposalModel.OtherPolicy"  type="checkbox"
                          (change)="addPolicy($event)" [(ngModel)]="proposalModel.OtherPolicy[2]" value="NILL" >NILL
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                         Full Name
                        </label>
                        <input id="bFullName" name="proposalModel.BeneficiariesTemp.FullName" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="beneficiaryModel.FullName" required>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                         Relationship
                        </label>
                        <input id="bRelationship" name="proposalModel.BeneficiariesTemp.Relationship" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="beneficiaryModel.Relationship" required>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Benefit Proportion
                        </label>
                        <input id="bBenefitProportion" name="proposalModel.BeneficiariesTemp.BenefitProportion" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="beneficiaryModel.BenefitProportion" OnlyNumber="true" placeholder="50" required="">
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Sex
                        </label>
                        <select name="proposalModel.BeneficiariesTemp.Sex" id="bSex" class="form-control form-control-md rounded-0" type="text"
                        placeholder="Required" [(ngModel)]="beneficiaryModel.Sex" required>
                            <option >Select your gender</option>
                            <option value="1">Male</option>
                            <option value="2">Female</option>
                      </select>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Date of Birth
                        </label>
                        <input id="bDateOfBirth" name="proposalModel.BeneficiariesTemp.DateOfBirth" class="form-control form-control-md rounded-0" type="date"
                          placeholder="Required" [(ngModel)]="beneficiaryModel.DateOfBirth" required>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label style="visibility: hidden;">
                          Frequency of  payment </label>
                        <button (click) ="Add()" type="button" class="g-brd-main btn-block g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" > Add
                        </button>
                      </div>
                    </div>

                  </div>
                  <div class="nok-table" *ngIf="showBeneficiaryTable">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Full Name </th>
                          <th>Relationship</th>
                          <th>Benefit Proportion</th>
                          <th>Sex</th>
                          <th>Date of Birth</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let ben of proposalModel.BeneficiariesData; let i = index">
                          <!-- <th scope="row">#</th> -->
                          <td>{{ben.FullName}}</td>
                          <td>{{ben.Relationship}}</td>
                          <td>{{ben.BenefitProportion}}</td>
                          <td>{{ben.Sex}}</td>
                          <td>{{ben.DateOfBirth}}</td>
                          <td>
                            <button class="btn btn-danger btn-block" (click)="deleteBen(i)" >Delete</button>
                          </td>

                        </tr>

                      </tbody>

                    </table>
                  </div>
                  <!-- <div class="row">
                    <div class="col-lg-4">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group g-mb-0">
                            <label style="visibility: hidden;">Frequency</label>
                            <button (click) ="Next()" type="button" class="g-brd-main btn-block g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" > Next
                            </button>
                          </div>

                        </div>
                        <div class="col-lg-6">
                          <div class="form-group g-mb-0">
                            <label style="visibility: hidden;">Frequency</label>
                            <button (click) ="Previous()" type="button" class="g-brd-main btn-block g-brd-danger--hover g-bg-main g-bg-danger--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" > Previous
                            </button>
                          </div>

                        </div>
                      </div>

                    </div>
                  </div> -->

                </section>
                <!-- Next of Kin -->
                <section class="step-five" >
                  <div class="form-sub-title">
                    <h4>Next of Kin</h4>
                  </div>
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                         Full Name
                        </label>
                        <input id="nFullName" name="proposalModel.NextOfKinData.FullName" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="nextOfKinModel.FullName" required>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                        Address
                        </label>
                        <input id="nAddress" name="proposalModel.NextOfKinData.Address" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="nextOfKinModel.Address" required>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                        Relationship
                        </label>
                        <input id="nRelationship" name="proposalModel.NextOfKinData.Relationship" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="nextOfKinModel.Relationship" required>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                        Mobile no
                        </label>
                        <input id="nPhoneNumber" name="proposalModel.NextOfKinData.PhoneNumber" class="form-control form-control-md rounded-0" type="number"
                          placeholder="Required" [(ngModel)]="nextOfKinModel.PhoneNumber" required pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==11) return false;">
                      </div>

                    </div>
                    <div class="col-lg-4" >
                      <div class="form-group g-mb-20">
                        <label>
                        Policy Holder
                        </label>
                        <input id="PolicyHolder" name="PolicyHolder" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.PolicyHolder"  >
                      </div>

                    </div>
                    <div class="col-lg-4" >

                      <div class="form-group g-mb-20">
                        <label>
                          Relationship of the Policy Holder to the Assured
                        </label>
                        <input id="RelationshipToPolicyHolder" name="RelationshipToPolicyHolder" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.RelationshipToPolicyHolder" >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Upload Signature
                        </label>
                        <br/>
                        <input type="file" (change)="selected($event.target.files)" required />
                      </div>
                    </div>

                  </div>
                </section>

               <section class="step-seven" >
                <div class="form-sub-title">
                  <h4>Terms</h4>
                </div>
                 <div class="container">
                   <h4>Declaration</h4>
                   <p>I, the undersigned, do hereby declare that the above statements are true and complete.</p>
                   <p>I hereby give my consent to the Company, to seek information, if necessary, from any doctor who has ever attended to me from any Life Assurance Office, to which a Proposal on my Life, at any given time, has been made, and the giving of such information is hereby authorized.</p>
                   <p>I further agree that this Proposal, Declaration and the statements made above or to the Medical Examiner acting for the Company (where required) shall be the basis of the proposed life insurance contract; that if anything contrary to the truth be stated or if any information which ought to be made known to the Company with reference to the Proposed Assurance be withheld or concealed, any policy which may be granted in pursuance of this contract shall be null and void.</p>
                   <p>Note:</p>
                   <ul>
                     <li>The Company is bound only by documents bearing the signature of an official of the Company authorized to Sign on its behalf.</li>
                     <li>The Company is not on risk until acceptance of the Proposal has been communicated in writing and the First premium has been received at the Company’ Head Office.</li>
                     <li>Premium should be made through cheques or direct credit into NSIA Insurance Ltd 's account as <b>NSIA would not be liable for premium paid in cash.</b> </li>
                   </ul>
                   <!-- <h4>Debit Mandate Instruction</h4>
                   <p>I hereby undertake to ensure that the account is always funded to cover this transaction</p>
                   <p>I understand that the deductions hereby authorized will be processed by electronic fund transfer and   I also understand that the details of each deduction will be printed on my bank statement. I agree that charges will apply as appropriate</p>
                   <p> I understand that I shall not be entitled to any refund which may have already been withdrawn while this mandate was in force if such amount was legally owed NSIA insurance Limited.</p>
                   <p>I understand that if any direct debit instruction is paid which breaches the term of this mandate,
                    my bank shall not be liable to me in any way or manner whatsoever; my recourse shall be limited to NSIA insurance limited. </p> -->
                    <div class="form-group">
                      <input type="checkbox" [(ngModel)]="termAgreed" name="termAgreed" > By checking the box , I agree with the above terms
                    </div>
                    <!-- <div class="col-lg-4">
                      <div class="row"> -->



                        <p>I hereby undertake to ensure that the account is always funded to cover this transaction.</p>


                        <p style="font-size:10px; text-align: justify;">In compliance with NDPR requirements, by completing and submitting this form, you have given consent to NSIA Insurance to receive your data, including your bio data, to enable NSIA Insurance maintain effective communication, send promotional updates, contact you for research purposes and use the data in line with the policy terms.
                        </p>
                        <p style="font-size:10px; text-align: justify;">The data collection may be via hard copies or online forms, validated via JSON WEB TOKEN (JWT). The data will not be accessed by any third party without your consent and any breach of this policy can be addressed legally within the year the breach was reported.</p>

                        <div class="col-lg-4">
                          <div class="form-group g-mb-0">
                            <label style="visibility: hidden;">Frequency</label>
                            <button  type="submit" class="g-brd-main btn-block g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" > Submit
                            </button>
                          </div>

                        </div>

                      <!-- </div>

                    </div> -->
                 </div>

               </section>

              </div>
            </div>
          </div>
        </form>
      </section>
    </div>

    <!-- End Sidebar -->
  </div>
</div>
