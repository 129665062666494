export class ProposalForm {
  constructor(
    public ProductType: number,
    public PremiumType:number,
    public Surname:string,
    public OtherNames:string,
    public Sex:number,
    public MaidenName:string,
    public MaritalStatus:number,
    public ResidentialAddress:string,
    public PostalAddress:string,
    public EmailAddress:string,
    public Telephone:string,
    public OfficeTelephone:string,
    public AlternativeTel:string,
    public AverageAge:string,
    public DateOfBirth:Date,
    public Age:number,
    public RICNumber:string,
    public StateOfOrigin:string,
    public Nationality:string,
    public Occupation:string,
    public EmployerName:string,
    public Position:string,
    public NatureOfWork:string,
    public EmployersAddress:string,
    public PaymentFrequency:number,
    public MedicalData:MedicalDetails,
    public ComencementDate: string,
    public Duration:string,
    public SumAssurred:number,
    public NumberOfMembers:number,
    public OtherPolicy: string[],
    public IneligibilityFactor:string,
    public BeneficiariesData:Beneficiaries[],
    public BeneficiariesTemp:Beneficiaries,
    public NextOfKinData:NextOfKin,
    public PolicyHolder:string,
    public RelationshipToPolicyHolder: string,
    public DebitMandateInstruction:DDInstructions


  ) {

  }

}
export class MedicalDetails {
  constructor(
    public Name:string,
    public Address:string,
    public PhoneNumber:string,
    public EmailAddress:string,
    public AttendanceLifeSpan:string,
    public DateLastAttended: Date,
    public AttendanceReason: number,
    public Height: string,
    public Weight: string,
    public BloodPressure: string,
    public AlchoholConsumption: number,
    public IsPregnant: number,
    public Edd: Date,
    public RecurringDesease: string[],
    public ISGoodHealth: number,
    public HealthReason:string,
    public TerminalDesease:number,
    public DiseaseDetails:string,


  ) {

  }
}
export class Beneficiaries {
  constructor(
    public FullName:string,
    public Relationship:string,
    public BenefitProportion:string,
    public Sex:number,
    public DateOfBirth:Date,

  ) {

  }
}
export class NextOfKin {
  constructor(
    public FullName:string,
    public Address:string,
    public Relationship:string,
    public PhoneNumber:string

  ) {

  }
}
export class DDInstructions {
  constructor(
    public AccountName:string,
    public AccountNumber:string,
    public Bank:string,
    public AccountType: number

  ) {

  }
}
