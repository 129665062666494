import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { data } from 'jquery';
import { BurglaryInsuranceProposalForm } from 'src/app/Classes/burglary-insurance-proposal-form';
import { FormserviceService } from 'src/app/Services/formservice.service';

@Component({
  selector: 'app-burglary-insurance-proposal-form',
  templateUrl: './burglary-insurance-proposal-form.component.html',
  styleUrls: ['./burglary-insurance-proposal-form.component.css']
})
export class BurglaryInsuranceProposalFormComponent implements OnInit {
  ateTemp = new Date();
  step:number = 1;
  equipments: string[] = [];

  proposalModel = new  BurglaryInsuranceProposalForm("","","","","","",null,null,null,"","","","",null,"","","","","","","","","","","","","","","", "");
  constructor(private _formservice: FormserviceService) { }

  ngOnInit(): void {
  }
  addCont(e){
    let value = e.srcElement.value;
    // let type = true;
    if (e.srcElement.checked) {
      this.equipments.push(value);
    }else{
      const index: number = this.equipments.indexOf(value);
      if (index !== -1) {
        this.equipments.splice(index, 1);
      }
    }
  }

  submitForm(){
    this._formservice.startLoading();
    console.log(this.proposalModel);
    this._formservice.sendBurglaryInsuranceProposalForm(this.proposalModel)
      .subscribe(
        (data) => {
          if (data.statusCode == "00") {
           alert("Your form is submitted");
          } else {
            alert("Your form is submitted");
          }
          this._formservice.stopLoading();
        },
        (error) => {
          console.log(error);
        alert("Your form is submitted");
          this._formservice.stopLoading();
        }
      )
  }
}
