<section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px">
    <div class="container" >
      <div class="d-sm-flex text-center">
        <div class="align-self-center">
          <h2 class="h3 g-font-weight-300 w-100 g-mb-10 g-mb-0--md" ><p>Privacy & Security</p></h2>
        </div>


      </div>
    </div>
  </section>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
<div class="container">
<ul class="d-flex justify-content-between u-list-inline">
  <li class="list-inline-item g-mr-15">
    <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
    <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
  </li>
  <li class="list-inline-item g-color-primary">
    <span>Privacy & Security</span>
  </li>

  <li class="list-inline-item ml-auto">
    <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
  </li>
</ul>
</div>
</section>


<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
<div class="row">
<div class="col-lg-9 g-mb-30">
  <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
    <h3 class="mb-0"><strong>
        <p>Introduction</p>
      </strong></h3><br />

    <!-- Clients Block-->

    <p></p>
    <p>Any references to 'our', 'us', 'we' or 'company' within this policy are deemed to refer to NSIA Insurance, its subsidiaries, and affiliates.</p>
    <p></p>
    <p>We ensure protection of your personal privacy. This privacy statement is provided to help you understand what we may do with any personal information obtained from you. By providing your personal information to us, you have signified your acceptance of our Privacy Statement and agree that we may collect, use and disclose your personal information as described in this Privacy Statement. If you do not agree to this statement, please do not provide your personal details to us. This Privacy Statement is incorporated into, and part of, the terms and conditions which govern your use of the site in general. We will use your information only for the purposes set out below.</p>
    <p></p>
    <p>We may need to update this Privacy Statement from time to time. We recommend that you regularly check this page to ensure that you have read the most recent version.</p>

  </div>
  <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
    <h3 class="mb-0"><strong>
        <p>Personal Information</p>
      </strong></h3><br />

    <!-- Clients Block-->

    <p></p>
    <p>Personal information is information about a specific individual, as defined by applicable law or statute. It does not include aggregated information that does not allow specific identification, nor does it include business contact information such as your name, title, and business address and business phone number.</p>
    <p></p>
  </div>
  <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
    <h3 class="mb-0"><strong>
        <p>Collection and Processing of personal information’</p>
      </strong></h3><br />

    <!-- Clients Block-->

    <p></p>
    <p>You can determine the personal information you provide to us. We will only collect personal information that you have provided. Where possible, we will enable you to select how we will use this information (for example, by choosing to opt-in or opt-out of receiving special promotional offers).</p>
    <p></p>
    <p>You may choose to provide us with personal information if, for example, you: contact us with an enquiry; register on the site, fill out a proposal form with your personal information included; request us to provide you with information; request a quote.</p>
    <p></p>
    <p>You have the option to decide whether or not to receive communications from us, except communications as required by law or in furtherance of any quote or
 
      transaction requested by you, at any point where we request information about you.
      </p>
    <p></p>
    <p>By furnishing us with any personal information, it is noted that you fully understand and clearly consent to the transfer of such personal information to, and the collection and processing of such information in, other countries or jurisdictions. Any such transfer and processing by us will be in accordance with this Privacy Statement.
    </p>

    <p></p>
    <p>
      For more information on NSIA’s Data Protection and Processing practices, please check our <a routerLink="/downloadable"><strong>Download Forms</strong></a> section to access the information on NSIA’s Data Subject Access Request Procedure, Data Subject Access Form, and Data Protection Impact Assessment Policy
    </p>
  </div>

  <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
    <h3 class="mb-0"><strong>
        <p>Consent Withdrawal</p>
      </strong></h3><br />
      <p></p>
      <p>You may choose to withdraw your consent at any time by sending a Data Subject Consent Withdrawal mail to <a href="mailto:enquiry@nsiainsurance.com">enquiry@nsiainsurance.com</a></p>
  </div>

</div>
<!-- Sidebar -->
<div class="col-lg-3 g-mb-30">
  <!-- Links -->
  <app-sidebar></app-sidebar>
  <!-- End Fast Facts -->
</div>
<!-- End Sidebar -->
</div>
</div>