<div class="g-min-height-300 g-flex-centered g-bg-img-hero g-bg-pos-top-center g-bg-size-cover g-pt-80"
  style="background-image: url(assets/img/banners/Inset_Life.jpg);">
  <div class="container g-pos-rel g-z-index-1">
    <h2 class="h1 text-uppercase g-color-primary g-font-size-40--lg mb-0">Life Insurance</h2>
    <span class="d-block  g-color-white g-font-weight-1000 g-font-size-20 mb-4">Experience peace of mind.</span>
  </div>
</div>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item g-color-primary">
        <span>Life Insurance</span>
      </li>

      <li class="list-inline-item ml-auto">
        <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
          routerLink="/lifequote">Get a Quote</a>
      </li>
    </ul>
  </div>
</section>


<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
    <div class="col-lg-9 g-mb-30">
<p>Life insurance is a good way to provide financial security for yourself and loved ones. Experience the peace of mind
that comes from knowing that your loved ones are financially secured no matter the unforeseen incident.</p>
<p></p>
<i>Requesting a quote is easy. Just click on the get a quote button or Call us now on 09048418896</i>
<p></p>
<br/>

      <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
        <!-- <h3 class="mb-0"><strong><p>Life Insurance</p></strong></h3><br/>  -->
        <!-- <div class="row" style=" padding-top:30px">
          <div class="col-lg-4 g-mb-30">
            <ul list-unstyled mb-0>
              <li style="color:#bbb"> <a routerLink="/termassurance"><b>Term Assurance</b></a></li>
              <li style="color:#bbb"> <a routerLink="/creditlife"><b>Credit Life (NSIA Super Protector)</b></a></li>
              <li style="color:#bbb"> <a routerLink="/endownment"><b>Endowment Assurance Plan</b></a></li>
              <li style="color:#bbb"> <a routerLink="/educationplan"><b>Education Endowment Assurance Plan</b></a></li>
              <li style="color:#bbb"> <a routerLink="/annuity"><b>NSIA Annuity Plans</b></a></li>
            </ul>
          </div>
          <div class="col-lg-5 g-mb-30">
            <ul list-unstyled mb-0>
              <li style="color:#bbb"> <a routerLink="/grouplife"><b>Group Life Insurance Scheme</b></a></li>
              <li style="color:#bbb"> <a routerLink="/keyman"><b>Keyman Assurance</b></a></li>
              <li style="color:#bbb"> <a routerLink="/mortgage"><b>Mortgage Protection Assurance (NSIA Family
                    Shield)</b></a></li>
              <li style="color:#bbb"> <a routerLink="/anticipated"><b>Anticipated Endowment Assurance</b></a></li>
              <li style="color:#bbb"><a routerLink="/educationplan"><b>NSIA Education Protection Plan (EPP)</b></a></li>
            </ul>
          </div>
        </div>
        <br /> -->
        <div class="life-heading" style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
            <h5 style="color: white">Protection Policies
             </h5>
            <!-- <div class="float-right">
              <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                routerLink="/lifequote">Get a Quote</a>
            </div> -->
        </div>
        <p></p>
        <div class="row justify-content-between">
          <!-- About Image -->
          <div class="col-md-3 col-lg-4">
            <!-- <img class="img-fluid" src="assets/img/banners/Marine_1.png" alt="Image Description"> -->
          </div>
          <!-- End About Image -->

          <div class="col-md-4 col-lg-8">
            <p></p>
              <ul>
                        <li style="color:#bbb"> <a routerLink="/term-assurance-insurance-company-in-lagos-nigeria" style="color:#000"><b>Term Assurance</b></a></li>
                        <li style="color:#bbb"> <a routerLink="/whole-life-insurance-company-in-lagos-nigeria" style="color:#000"><b>Whole Life Insurance (NSIA Living Companion)</b></a></li>
                        <li style="color:#bbb"> <a routerLink="/keyman-insurance-company-in-lagos-nigeria" style="color:#000"><b>Keyman Assurance</b></a></li>
                        <li style="color:#bbb"><a routerLink="/epp-insurance-company-in-lagos-nigeria" style="color:#000"><b>NSIA Education Protection Plan (EPP)</b></a></li>
                        <li style="color:#bbb"> <a routerLink="/group-life-insurance-company-in-lagos-nigeria" style="color:#000"><b>Group Life Insurance Scheme</b></a></li>
                        <li style="color:#bbb"> <a routerLink="/family-protection-insurance-company-in-lagos-nigeria" style="color:#000"><b> NSIA Family Protection Plan</b></a></li>


                      </ul>

          </div>
        </div>
        <p></p>
        <div class="life-heading" style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color: white">Decreasing Term Policies
          </h5>
          <!-- <div class="float-right">
            <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
              routerLink="/lifequote">Get a Quote</a>
          </div> -->
        </div>
        <p></p>
        <div class="row justify-content-between">
          <!-- About Image -->
          <div class="col-md-3 col-lg-4">
            <!-- <img class="img-fluid" src="assets/img/banners/Marine_1.png" alt="Image Description"> -->
          </div>
          <!-- End About Image -->

          <div class="col-md-4 col-lg-8">
            <p></p>
            <ul>
              <li style="color:#bbb"> <a routerLink="/credit-life-insurance-company-in-lagos-nigeria" style="color:#000"><b>Credit Life (NSIA Super Protector)</b></a></li>
              <li style="color:#bbb"> <a routerLink="/mortgage-insurance-company-in-lagos-nigeria" style="color:#000"><b>Mortgage Protection Assurance (NSIA Family
                    Shield)</b></a></li>

            </ul>

          </div>
        </div>
        <p></p>
        <div class="life-heading" style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color: white">Investment Policies
          </h5>
          <!-- <div class="float-right">
            <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
              routerLink="/lifequote">Get a Quote</a>
          </div> -->
        </div>
        <p></p>
        <div class="row justify-content-between">
          <!-- About Image -->
          <div class="col-md-3 col-lg-4">
            <!-- <img class="img-fluid" src="assets/img/banners/Marine_1.png" alt="Image Description"> -->
          </div>
          <!-- End About Image -->

          <div class="col-md-4 col-lg-8">
            <p></p>
            <ul>
              <li style="color:#bbb"> <a routerLink="/savings-plan-insurance-company-in-lagos-nigeria" style="color:#000"><b>NSIA Savings Plan</b></a></li>
              <li style="color:#bbb"> <a routerLink="/investment-plan-insurance-company-in-lagos-nigeria" style="color:#000"><b>NSIA Investment Linked Plan</b></a></li>
              <li style="color:#bbb"> <a routerLink="/deferred-annuity-insurance-company-in-lagos-nigeria" style="color:#000"><b>NSIA Deferred Annuity Plan</b></a></li>

            </ul>

          </div>
        </div>
        <p></p>
        <div class="life-heading" style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color: white">Endowment Policies
          </h5>
          <!-- <div class="float-right">
            <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
              routerLink="/lifequote">Get a Quote</a>
          </div> -->
        </div>
        <p></p>
        <div class="row justify-content-between">
          <!-- About Image -->
          <div class="col-md-3 col-lg-4">
            <!-- <img class="img-fluid" src="assets/img/banners/Marine_1.png" alt="Image Description"> -->
          </div>
          <!-- End About Image -->

          <div class="col-md-4 col-lg-8">
            <p></p>
            <ul>
              <li style="color:#bbb"> <a routerLink="/endowment-insurance-company-in-lagos-nigeria" style="color:#000"><b>Endowment Assurance Plan</b></a></li>
              <li style="color:#bbb"> <a routerLink="/education-plan-insurance-company-in-lagos-nigeria" style="color:#000"><b>Education Endowment Assurance Plan</b></a></li>
              <li style="color:#bbb"> <a routerLink="/anticipated-insurance-company-in-lagos-nigeria" style="color:#000"><b>Anticipated Endowment Assurance</b></a></li>
            </ul>

          </div>
        </div>
        <p></p>
        <!-- <div class="life-heading" style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color: white">Annuity
          </h5> -->
          <!-- <div class="float-right">
            <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
              routerLink="/lifequote">Get a Quote</a>
          </div> -->
        <!-- </div>
        <p></p> -->
        <!-- <div class="row justify-content-between"> -->
          <!-- About Image -->
          <!-- <div class="col-md-3 col-lg-4"> -->
            <!-- <img class="img-fluid" src="assets/img/banners/Marine_1.png" alt="Image Description"> -->
          <!-- </div> -->
          <!-- End About Image -->
<!--
          <div class="col-md-4 col-lg-8">
            <p></p>
            <ul>
              <li style="color:#bbb"> <a routerLink="/immediateannuity" style="color:#000"><b>Immediate Annuity</b></a></li>
              <li style="color:#bbb"> <a routerLink="/deferredannuity" style="color:#000"><b>Deferred Annuity</b></a></li>
            </ul>

          </div>
        </div>
        <p></p> -->
        <!-- <p></p> -->
        <div class="life-heading" style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color: white">Health Insurance
          </h5>
          <!-- <div class="float-right">
            <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
              routerLink="/health">Learn More</a>
          </div> -->
        </div>
        <p></p>



        <p>Call or e-mail us today for a professional guide through available health insurance options and to help you decide what cover is best for you and your family. <a routerLink="/health-insurance-company-in-lagos-nigeria">Learn More</a></p>
          <p></p>

      </div>
      <!-- <div class="row" style=" padding-top:30px">
          <div class="col-lg-4 g-mb-30">
            <ul list-unstyled mb-0>
              <li style="color:#bbb"> <a routerLink="/annuity"><b>NSIA Annuity Plans</b></a></li>
            </ul>
          </div>
          <br/>
          <div class="col-lg-5 g-mb-30">
            <ul list-unstyled mb-0>

            </ul>
          </div>
        </div>  -->
        <br />
    </div>


    <!-- Sidebar -->
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <app-sidebar></app-sidebar>
      <!-- End Fast Facts -->
    </div>
    <!-- End Sidebar -->
  </div>
</div>
