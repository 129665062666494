<!-- <section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px">
  <div class="container">
    <div class="d-sm-flex text-center">
      <div class="align-self-center">
        <h2 class="h3 g-font-weight-300 w-100 g-mb-10 g-mb-0--md">
          <p>Awards </p>
        </h2>
      </div>


    </div>
  </div>
</section> -->
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>

      <li class="list-inline-item g-color-primary">
        <span>Women</span>
      </li>


    </ul>
  </div>
</section>
<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
    <div class="col-lg-9 g-mb-30 colll">
      <div class=" container-fluid d-flex flex-column align-items-center justify-content-center text-black div1">
        <h1 class="fw-bolder wm ">NSIA INSURANCE 2023 CAMPAIGN - IT’s I TIME</h1>
        <div class="container">
          <h2>2023 started on a great note with the launch of a new campaign: “It’s I Time”.
          </h2>
          <img src="../../../assets/svgs/slides/roadshow.jpg" alt="Image 1 goes here">
          <h2>The purpose of this campaign is to highlight the importance of having insurance and the seriousness with
            which
            it must be addressed. It is a play on the word high to the first letter in Insurance, giving it a new look,
            with
            similar sounds and the same meaning but with relevance to Insurance.</h2>
          <h2>The phrase – It’s high time – is most used when something has either become an emergency of some sort, is
            unbearable, or is being described as overdue. Simply put, <b>“It’s Insurance Time”</b>.</h2>
          <h2>To further intensify the reach of the campaign, the Brand has commenced its 2023 roadshow, beginning in
            Lagos.
            The roadshow is aimed at increasing brand awareness and engagement with a broad spectrum of potential
            clients. It
            also restates the brand strategy for the year, <b>"It’s I-Time</b> and serves as a means to convey the
            benefits of
            NSIA Insurance product line and custom services.</h2>
          <h2>For the next month, NSIA will be present across the following locations; Lagos, Ibadan, Abuja, Port
            Harcourt,
            Warri, and Benin. Customers who purchase one or more policies from NSIA Insurance will receive freebies at
            these
            locations. The roadshow will run from May 8-June 9, 2023.</h2>
          <h2>You can track our spots in real time via our social media channels</h2>
          <ul class="alis">
            <li><a href="https://www.instagram.com/nsia_insure/"><i class="fa fa-instagram"></i> Instagram</a></li>
            <li><a href="https://www.facebook.com/NSIAInsuranceLimited/"><i class="fa fa-facebook"></i> Facebook</a>
            </li>
            <li><a href="https://twitter.com/nsia_insure"><i class="fa fa-twitter"></i> Twitter</a></li>
            <li><a href="https://www.linkedin.com/company/nsia-insurance-limited/"><i class="fa fa-linkedin"></i>
                LinkedIn</a>
            </li>
            <li><a href="https://www.youtube.com/channel/UC2RRGT5hYGRJ3IECV7m3HWw"><i class="fa fa-youtube"></i>
                YouTube</a>
            </li>
          </ul>
          <h2>Its I time to get a plan that suits your needs, reach out to us on 09048418896/01-280 5378. Alternatively,
            send
            an email to <a href="mailto:customerservice@nsiainsurance.com">customerservice@nsiainsurance.com</a></h2>
          <h2>NSIA…the true face of insurance</h2>
        </div>
      </div>



    </div>


    <!-- Sidebar -->
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <app-sidebar></app-sidebar>
      <!-- End Fast Facts -->
    </div>
    <!-- End Sidebar -->
  </div>
</div>