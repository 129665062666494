<section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px">
  <div class="container">
    <div class="d-sm-flex text-center">
      <div class="align-self-center">
        <h2 class="h3 g-font-weight-300 w-100 g-mb-10 g-mb-0--md">
          <p>Education Endowment Assurance Plan</p>
        </h2>
      </div>


    </div>
  </div>
</section>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/lifeinsurance">Life Insurance</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item g-color-primary">
        <span>Education Endowment Assurance Plan</span>
      </li>

      <li class="list-inline-item ml-auto">
        <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10"
          routerLink="/getquote">Get quote</a>
      </li>
    </ul>
  </div>
</section>
<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
    <div class="col-lg-9 g-mb-30">
      <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
        <h4><strong>
            <p>Education Endowment Assurance Plan</p>
          </strong> </h4>

        <p>It is the desire of every parent to provide their children with quality education. NSIA education endowment
          product
          (NSIA Education Support) serves as a vehicle for saving towards future education of the children. The policy
          also
          supports the child in the event of early death of the parent.</p>
        <p></p>
        <p><strong>Key Features</strong></p>
        <p></p>
        <ul>
          <li>
            <p>The original guaranteed sum assured is specified in advance by the parent/guardian (policyholder)</p>
          </li>
          <li>
            <p>Minimum sum assured is Five Hundred Thousand Naira (N500,000)</p>
          </li>
          <li>
            <p>Maturity date (term) of the policy will usually coincide with commencement date of the child’s education
            </p>
          </li>
          <li>
            <p>The product is available at policy term of 5 - 20 years</p>
          </li>
          <li>
            <p>The minimum age at entry is 18 years while the maximum age at maturity is 70 years.</p>
          </li>
          <li>
            <p>A simple reversionary bonus (profit) which begins to accrue from the beginning of the second year is
              added to the sum
              assured, this is currently guaranteed at 2% of sum assured per annum.</p>
          </li>
          <li>
            <p>Policy can be used as collateral for loan</p>
          </li>
          <li>
            <p>Policyholder pays single, annually, half-yearly, quarterly or monthly to NSIA Insurance Limited to secure
              the guaranteed
              benefit and bonuses</p>
          </li>
          <li>
            <p>Medical examinations may be required in certain cases</p>
          </li>
          <li>
            <p>The policy will lapsed if premiums are not paid within 60 days after due date</p>
          </li>
        </ul>
        <p></p>

        <p><strong>Benefits</strong></p>
        <p></p>
        <p><b>On Maturity Date</b></p>

        <p></p>
        <p>Payment of the guaranteed sum assured and all accrued bonuses to fund child’s education</p>
        <p></p>
        <p>Payment could be spread over the period of education</p>

        <p></p>
        <p><b>On death of the Policyholder (parent)</b></p>

        <p></p>

        <p>In the event of death of the policyholder before maturity,10% of the guaranteed sum assured is payable as an
          annual
          family income benefit. This is payable to the named beneficiary from the date of death to maturity year
          subject to a
          maximum of 100% of the sum assured</p>
        <p></p>
        <p>Also, the guaranteed sum assured is payable to the named beneficiary at the maturity of the policy</p>
        <p></p>
        <p><b>All outstanding premiums will be waived</b></p>

        <p></p>
        <!-- <p><b>On death of the child</b></p> -->
        <ul>
          <li>If the child dies before the maturity date, he can be replaced with another child.</li>
          <li>If there is no other child, NSIA will refund the total premium paid.</li>
        </ul>

        <!-- <p></p> -->
        <p></p>
        <p></p>
        <p><b> On Discontinuation</b></p>

        <p></p>

        <p>If the policyholder discontinues the policy after two complete years’ of premium payment and two years of
          being in
          force, NSIA Life will pay a reduced benefit known as surrender value.</p>

        <p></p>
        <p><b> Policy Loan</b></p>

        <p></p>

        <p>On acquiring a surrender value after two years, the policyholder can apply to NSIA for a loan of up to 90% of
          the
          surrender value on the policy</p>
        <p></p>

      <!-- <p><b>Waiting Period</b></p>

        <p>There is a month waiting period during which a client cannot claim on non-accidental death. However, where
          accidental
          death occurs during this period, total premiums paid would be returned</p> -->
        <p><b>Termination</b></p>

        <p>No payment is made if the policy is terminated within two years of its inception.</p>
        <p></p>

        <p><b>Suitability</b></p>

        <p></p>

        <p>The Education Endowment product is suitable for:</p>
        <p></p>
        <ul>
          <li>
            <p>An individual who wants to save for future education of the children</p>
          </li>
          <li>
            <p>An individual who wants to ensure that his/her children’s future education is not disrupted in the event
              of early death</p>
          </li>
          <li>
            <p>Educational institutions who want to make their schools more attractive to parents/children</p>
          </li>
          <li>
            <p>Financial institutions granting children education loans</p>
          </li>
          <li>
            <p>Any individual who wants to save towards his/her future education</p>
          </li>

        </ul>
        <!-- <p><strong>Waiting Period</strong> </p>

        <p></p>

        <p>There is a month waiting period during which a client cannot claim on non-accidental death. However, where accidental
        death occurs during this period, total premiums paid would be returned</p> -->
        <p></p>
      </div>
    </div>


    <!-- Sidebar -->
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <app-sidebar></app-sidebar>
      <!-- End Fast Facts -->
    </div>
    <!-- End Sidebar -->
  </div>
</div>
