import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nsia-insurance-plan',
  templateUrl: './nsia-insurance-plan.component.html',
  styleUrls: ['./nsia-insurance-plan.component.css']
})
export class NsiaInsurancePlanComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
