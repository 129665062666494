<section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px">
  <div class="container">
    <div class="d-sm-flex text-center">
      <div class="align-self-center">
        <h2 class="h3 g-font-weight-300 w-100 g-mb-10 g-mb-0--md">
          <p>NSIA Investment Link Product</p>
        </h2>
      </div>


    </div>
  </div>
</section>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/lifeinsurance">Life Insurance</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item g-color-primary">
        <span>NSIA Investment Link Product</span>
      </li>

      <li class="list-inline-item ml-auto">
        <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
          routerLink="/lifequote">Get quote</a>
      </li>
    </ul>
  </div>
</section>
<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
    <div class="col-lg-9 g-mb-30">
      <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
        <h4><strong>
            <p>NSIA Investment Link Product</p>
          </strong> </h4>

        <p>NSIA Investment linked plan offers prospective policyholders a vehicle through which they can accumulate fund over a
        short period of time to meet future needs. The product is managed through a dedicated investment account into which
        policyholder’s contributes and attractive interest are credited. This policy also offers a life insurance policy as an
        attending feature which affords the beneficiary of a policy holder to be paid a certain guaranteed sum in the event of
        death of the policy holder while the policy is running.
        <br>
        Here are some of the features:
</p>
        <ul>
          <li>Policy Duration is one (1) year, however, policy holder can choose to roll over the fund value at expiration date</li>
          <li>A dedicated investment account is opened for each policyholder
          </li>
          <li>All contributions (premiums) are allocated into the investment account after deducting the life cover premium from the
          first contribution</li>
          <li>The minimum age at entry is 18 years whilst the maximum age at entry is 60 years</li>
          <li>Attractive daily interest is credited into the account on a monthly basis</li>
          <li>The minimum sum assured is N100,000.00 while the maximum sum assured is N1,000,000.00</li>
          <li>Minimum contribution per month is N10,000.00</li>
          <li>Frequency of premium payment can be single, annually, half-yearly, quarterly or monthly</li>
          <li>There is a waiting period of one month in the event of claim. If death occurs within this period, only the account
          balance will be paid and the policy ceases.</li>
        </ul>
        <p></p>

        <p><strong>Benefits</strong></p>
        <p></p>
        <ul>
          <li>
            <p><strong>Maturity</strong></p>
            <p>At the end of the policy term, the policyholder receives the total amount credited into his investment account plus
            interest.</p>
          </li>
          <li>
            <p><strong>On Death</strong></p>
            <p>NSIA pays the chosen sum assured in addition to the accumulated fund value in the event of death of the policyholder
            during the policy term.</p>
          </li>
          <li>
            <p><strong>On Discontinuation</strong></p>
            <p>The balance in the savings account less the accrued interest is payable on surrender.
            </p>

          </li>

        </ul>

        <p></p>



      </div>
    </div>


    <!-- Sidebar -->
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <app-sidebar></app-sidebar>
      <!-- End Fast Facts -->
    </div>
    <!-- End Sidebar -->
  </div>
</div>
