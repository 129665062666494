export class Formclass {
  constructor(
    public EmployerName: string,
    public Surname: string,
    public Firstname: string,
    public Othername: string,
    public StaffNumber: string,
    public DateOfBirth: dateFormat,
    public EmailAddress: string,
    public PhoneNumber: string,
    public Gender: string,
    public MnSurname: string,
    public MnFirstname: string,
    public MnTrustee: string,
    public MnDateOfBirth: dateFormat,
    public MnPhoneNumber: string,
    public MnEmailAddress: string,
    public NkSurname: string,
    public NkFirstname: string,
    public NkRelationship: string,
    public NkProportion: string,
    public NkDateOfBirth: dateFormat,
    public NkPhoneNumber: string,
    public NkEmailAddress: string,
  ) { }
}
export class dateFormat{
  constructor(
    public year: number,
    public month: number,
    public day: number
  ){}
}
