<div class="g-min-height-300 g-flex-centered g-bg-img-hero g-bg-pos-top-center g-bg-size-cover g-pt-80"
  style="background-image: url(assets/img/banners/Inset_Life.jpg);">
  <div class="container g-pos-rel g-z-index-1">
    <h2 class="h1 text-uppercase g-color-primary g-font-size-40--lg mb-0">Proposal Form</h2>
    <span class="d-block  g-color-white g-font-weight-1000 g-font-size-20 mb-4">Experience peace of mind.</span>
  </div>
</div>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item g-color-primary">
        <span>Proposal Form</span>
      </li>

      <li class="list-inline-item ml-auto">
        <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
          routerLink="/lifequote"></a>
      </li>
    </ul>
  </div>
</section>


<div class="container g-pt-100 g-pb-70">
  <div class="row">
    <div class="col-lg-12 g-mb-30">
      <section class="g-bg-gray-light-v5 g-py-20">
        <div class="container">
          <h2 style="text-align: center;">LIFE PROPOSAL FORM</h2>
          <br/><br/>
          <div class="row no-gutters">
             <div class="col-lg-5 cta-item g-px-40 g-mb-50 g-mb-0--lg">
                <!-- Icon Blocks -->
                <div class="text-center">
                   <span
                      class="d-inline-block u-icon-v3 u-icon-size--xl u-icon-effect-v3--hover g-bg-primary g-color-white rounded-circle g-mb-30">
                   <i class="fa fa-user"></i>
                   </span>
                   <h3 class="h5 g-color-gray-dark-v2 g-font-weight-600 text-uppercase mb-3">Individual Life Proposal Form</h3>
                   <p class="mb-0">Fill the Individual Life Proposal Form
                   </p>
                   <br />
                   <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                      routerLink="/Individual-life-proposal-form">
                      Individual Life Proposal Form
                   </a>
                </div>
                <!-- End Icon Blocks -->
             </div>
             <div class="col-lg-2 g-mb-50 g-mb-0--lg">

             </div>
             <div class="col-lg-5 cta-item g-brd-left--lg g-brd-gray-light-v4 g-px-40 g-mb-50 g-mb-0--lg">
                <!-- Icon Blocks -->
                <div class="text-center">
                   <span
                      class="d-inline-block u-icon-v3 u-icon-size--xl u-icon-effect-v3--hover g-bg-primary g-color-white rounded-circle g-mb-30">
                   <i class="fa fa-users"></i>
                   </span>
                   <h3 class="h5 g-color-gray-dark-v2 g-font-weight-600 text-uppercase mb-3">Group Life Proposal Form</h3>
                   <p class="mb-0">Fill the Group Life Proposal Form
                   </p>
                   <br />
                   <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                      routerLink="/group-life-proposal-form">
                Group Life Proposal Form
                   </a>
                </div>
                <!-- End Icon Blocks -->
             </div>

          </div>



       </div>
      </section>
    </div>
  </div>
</div>
