import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-investment-plan',
  templateUrl: './investment-plan.component.html',
  styleUrls: ['./investment-plan.component.css']
})
export class InvestmentPlanComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
