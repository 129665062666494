
<div class="container g-pt-100 g-pb-70">
  <div class="row">
    <div class="col-lg-12 g-mb-30">
      <section class="g-bg-gray-light-v5 g-py-20">
        <form novalidate (ngSubmit)="submitForm(beneficiaryForm)" #beneficiaryForm="ngForm" novalidate>
          <div class="container g-py-100 pad-top-50" style="margin-top:-50px">
            <div class="u-shadow-v19 g-brd-around g-brd-gray-light-v4  rounded mx-auto g-pa-30 g-pa-50--md">

              <div class="mb-6">
                <h2 class="mb-4 main-heading">
                  PERSONAL ACCIDENT INSURANCE PROPOSAL FORM
                </h2>

                <section class="step-two" >
                  <div class="form-sub-title">
                    <h4>Personal Information</h4>
                  </div>
                  <div class="row">

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>Name of Proposer</label>
                        <input id="NameOfProposal" name="NameOfProposal" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.NameOfProposal" required>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Address of Proposer
                        </label>
                        <input id="AddressOfProposal" name="AddressOfProposal" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.AddressOfProposal" required >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Trade Or Business
                        </label>
                        <input id="TradeOrBusiness" name="TradeOrBusiness" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.TradeOrBusiness" required >
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Means of Identification (CAC, Int’l Passport, etc)
                        </label>
                        <input id="means_of_id" name="means_of_id" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.means_of_id" required >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          E- mail address
                        </label>
                        <input id="EmailAddress" name="EmailAddress" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.EmailAddress" required type="text" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" >

                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Mobile No
                        </label>
                        <input id="Telephone" name="Telephone" class="form-control form-control-md rounded-0" type="number"
                        placeholder="Required" [(ngModel)]="proposalModel.Telephone" required="required" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==11) return false; return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57));" >

                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                         <label>
                          Start Date  (Period of Insurance)
                         </label>
                         <input id="StartDate" name="StartDate" class="form-control form-control-md rounded-0" type="date"
                          placeholder="yyyy-mm-dd" [(ngModel)]="proposalModel.StartDate" >

                      </div>
                   </div>
                   <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                         <label>
                          End Date (Period of Insurance)
                         </label>
                         <input id="EndDate" name="EndDate" class="form-control form-control-md rounded-0" type="date"
                          placeholder="yyyy-mm-dd" [(ngModel)]="proposalModel.EndDate"  >
                      </div>
                   </div>

                   <div class="col-lg-6">
                    <div class="form-group g-mb-20">
                      <label>
                      Date Of Birth
                      </label>
                      <input id="dob" name="dob" class="form-control form-control-md rounded-0" type="date"
                        placeholder="Required" [(ngModel)]="proposalModel.dob">
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group g-mb-20">
                      <label>
                        Estimated Annual Income/Salary
                      </label>
                      <input id="estimatedannualincome" name="estimatedannualincome" class="form-control form-control-md rounded-0" type="number"
                      placeholder="Required" [(ngModel)]="proposalModel.estimatedannualincome" required="required"  >

                    </div>
                  </div>

                  </div>
                </section>

                <section class="step-three">
                  <div class="">
                    <h4>General Questions</h4>
                  </div>
                  <div class="row">


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Description of activities/business or occupation
                        </label>
                        <input id="activities_business" name="activities_business" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.activities_business" >
                      </div>
                    </div>

                    <div class="col-lg-6" >
                      <div class="form-group g-mb-20">
                        <label>
                          Are you engaged in any hazardous pastimes or sport?
                        </label>
                        <select name="typeOfSport" id="typeOfSport" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.typeOfSport">
                            <option>Select your option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>

                        <!-- <input id="typeOfSport" name="typeOfSport" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.typeOfSport" > -->
                      </div>
                    </div>

                    <!-- *ngIf="proposalModel.typeOfSport ==Yes" -->

                    <div class="col-lg-6" >
                      <div class="form-group g-mb-20">
                        <label>
                        If Yes, please state

                        </label>
                        <input id="typeOfSport" name="typeOfSport" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.typeOfSport" >

                      </div>
                    </div>

                    <div class="col-lg-12" >
                      <div class="form-group g-mb-20">
                        <label>
                        Please state any Pre-Existing illness as at time of completing this proposal form.

                        </label>
                        <input id="preexisting" name="preexisting" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.preexisting" >

                      </div>
                    </div>


                    <div class="col-lg-12">
                      <div class="form-group g-mb-20">
                        <label>
                          Are you expecting to be engaged in air transit other
                          than a fare paying passenger?
                    </label>

                        <select name="payingpassenger" id="payingpassenger" class="form-control form-control-md rounded-0"
                         [(ngModel)]="proposalModel.payingpassenger">
                            <option >Select your option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>

                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="form-group g-mb-20">
                        <label>
                          Do you have similar policy with other insurance Company?
                    </label>

                        <select name="similarpolicy" id="similarpolicy" class="form-control form-control-md rounded-0"
                         [(ngModel)]="proposalModel.similarpolicy">
                            <option >Select your option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>

                      </div>
                    </div>



                    <div class="form-sub-title">
                      <h6 style="margin-left: 15px;">SCALE OF BENEFITS REQUIRED</h6> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                    </div>
                    <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span>Please state hereunder the level of benefits you require</span>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Death Benefit: -
                        </label>
                        <input id="DeathBenefit" name="DeathBenefit" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.DeathBenefit" >

                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Permanent Disability
                        </label>

                        <input id="PermanentDisability" name="PermanentDisability" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.PermanentDisability" >

                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Temporary Total Disablement:
                        </label>
                        <input id="TemporaryTotalDisablement" name="TemporaryTotalDisablement" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.TemporaryTotalDisablement" >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Medical Expenses:
                        </label>
                        <input id="MedicalExpenses" name="MedicalExpenses" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.MedicalExpenses" >
                      </div>
                    </div>

                    <h6 style="margin-left: 15px;">Who is considered the beneficiary(ies) in the event of claim:</h6>
                    <div class="col-lg-12">
                      <div class="form-group g-mb-20">
                        <label>
                          Name
                        </label>
                        <input id="Name" name="Name" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.Name" >
                      </div>
                    </div>


                    <div class="col-lg-12">
                      <div class="form-group g-mb-20">
                        <label>
                         Relationship
                        </label>
                        <input id="Relationship" name="Relationship" class="form-control form-control-md rounded-0" type="text"
                        [(ngModel)]="proposalModel.Relationship" >
                      </div>
                    </div>


                  </div>
                </section>

               <section class="step-seven" >

                 <div class="container">
                   <h4>Declaration</h4>
                   <p>I/We hereby declare that to the best of my/our knowledge and belief the information herein provided is correct and complete.</p>

                   <!-- <div class="form-group">
                    <input type="checkbox" [(ngModel)]="termAgreed" name="termAgreed" > By checking the box , I agree with the above terms
                  </div> -->

                      <p style="font-size:10px; text-align: justify;">In compliance with NDPR requirements, by completing and submitting this form, you have given consent to NSIA Insurance to receive your data, including your bio data, to enable NSIA Insurance maintain effective communication, send promotional updates, contact you for research purposes and use the data in line with the policy terms.
                      </p>
                      <p style="font-size:10px; text-align: justify;">The data collection may be via hard copies or online forms, validated via JSON WEB TOKEN (JWT). The data will not be accessed by any third party without your consent and any breach of this policy can be addressed legally within the year the breach was reported.</p>

                      <div class="col-lg-4">
                        <div class="form-group g-mb-0">
                          <label style="visibility: hidden;">Frequency</label>
                          <button  type="submit" onclick="return confirm('Are you sure you want to submit this information?')" class="g-brd-main btn-block g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" > Submit
                          </button>
                        </div>

                      </div>

                 </div>

               </section>

              </div>


            </div>
          </div>
        </form>
      </section>
    </div>

    <!-- End Sidebar -->
  </div>
</div>
