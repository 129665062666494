<!-- <div class="modal-body"> -->
<div class="login-home-wrapper">
  <div class="login-header">
    Customer Portal
  </div>
  <div class="modal-tabs">
    <app-tabs>
      <app-tab [tabTitle]="lifeModel" [active]="!isLifehidden">
        <div class="login-portal">
          <!-- hidden="isLifehidden" -->
          <a href="http://portal.nsiainsurance.com/Account/LifeLogin" target="_blank"
            class="btn btn-block btn-login">Login</a>
        </div>
      </app-tab>
      <app-tab [tabTitle]="generalModel" (change)="changeFn($event)" [active]="isLifehidden">
        <div class="login-portal">
          <a href="http://portal.nsiainsurance.com/Account/NonLifeLogin" target="_blank"
            class="btn btn-block btn-login">Login</a>
        </div>
      </app-tab>
    </app-tabs>
  </div>
</div>
<!-- </div> -->