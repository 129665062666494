<div class="g-min-height-300 g-flex-centered g-bg-img-hero g-bg-pos-top-center g-bg-size-cover g-pt-80">
  <div class="container g-pos-rel g-z-index-1">
   <img style="display: block;max-width: 82%;margin: auto;margin-top: 50px;" src="assets/img/dstv-slides/blue.jpg" alt="">
  </div>
</div>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item g-color-primary">
        <span>OUR DSTV THANKS PRODUCT OFFER</span> &nbsp;&nbsp; <a target="_blank" href="https://nsiadstv.azurewebsites.net/verify"><b>Buy Now</b></a>
      </li>

      <li class="list-inline-item ml-auto">
       <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10"
           routerLink="/getquote">Get quote</a> -->
      </li>
    </ul>
  </div>
</section>


<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
    <div class="col-lg-9 g-mb-30">
        <p>We are pleased to present NSIA Value Deal to DSTV Subscribers, a product combination that will provide protection for
        your lives/assets at affordable rates and with great value.</p>
        <p></p>

        <p>To be part of the DStv Thanks campaign and qualify for the discounted NSIA Insurance products, you must be on any of the
        DStv packages (Family, Access, Compact, Compact+ or Premium), stay connected and have renewed your account on time.</p>
        <p></p>
        <p>The discounted NSIA Insurance products are in the following categories:</p>
        <p></p>
        <br/>
        <div id="accordion-12" class="u-accordion u-accordion-color-primary" role="tablist" aria-multiselectable="true">
            <!-- Card  <div style="padding-left : 5px; background-color:#efb225;">-->
            <div class="card g-brd-none rounded-0 g-mb-15">
                <div id="accordion-12-heading-01" class="u-accordion__header g-pa-0" style=" background-color:#efb225;" role="tab">
                    <h6 class="mb-0">
                      <a class="d-flex g-color-main g-text-underline--none--hover g-brd-around g-brd-gray-light-v4 g-rounded-5 g-pa-10-15" href="#accordion-12-body-01" data-toggle="collapse" data-parent="#accordion-12" aria-expanded="true" aria-controls="accordion-12-body-01">
                        <span class="u-accordion__control-icon g-mr-10">
                          <i class="fa fa-angle-down"></i>
                          <i class="fa fa-angle-up"></i>
                        </span>
                       <p style="color:white"> A. NSIA Householder Insurance</p>
                      </a>
                    </h6>
                </div>
                <div id="accordion-12-body-01" class="collapse" role="tabpanel" aria-labelledby="accordion-12-heading-01" data-parent="#accordion-12">
                    <div class="u-accordion__body g-color-gray-dark-v5">
                      <p class="mb-0">The NSIA Householder insurance provides cover for your home (building) and its contents in the event of loss or damage resulting from the following:    </p>
                      <ul>
                        <li>Fire, explosion, lightning, earthquake, the impact of aircraft, vehicles or animals</li>
                        <li>Theft or attempted theft</li>
                        <li>Damage caused by riot and malicious persons</li>
                        <li>Storm, flood, burst pipes and tanks</li>
                        <li>Loss or Rent/ Cost of Alternative Accommodation </li>
                        <li>Personal liability for bodily injury to Third Parties </li>
                        <li>Compensation for Death / Cost of Medical Expenses to the insured or Members of his household for injuries sustained.</li>

                      </ul>
                      <p>The following can be covered: Buildings, Household contents and Personal Belongings (excluding Laptops and Mobile phones; though we can cover these under a different policy type). </p>

                    </div>
                </div>
            </div>
            <div class="card g-brd-none rounded-0 g-mb-15">
                <div id="accordion-12-heading-02" class="u-accordion__header g-pa-0" style=" background-color:#efb225;" role="tab">
                    <h6 class="mb-0">
                      <a class="d-flex g-color-main g-text-underline--none--hover g-brd-around g-brd-gray-light-v4 g-rounded-5 g-pa-10-15" href="#accordion-12-body-02" data-toggle="collapse" data-parent="#accordion-12" aria-expanded="true" aria-controls="accordion-12-body-02">
                        <span class="u-accordion__control-icon g-mr-10">
                          <i class="fa fa-angle-down"></i>
                          <i class="fa fa-angle-up"></i>
                        </span>
                       <p style="color:white">B. NSIA Personal Accident Insurance- Pay as low as N1,000 in a year and get up to N250,000 in benefits</p>
                      </a>
                    </h6>
                </div>
                <div id="accordion-12-body-02" class="collapse" role="tabpanel" aria-labelledby="accordion-12-heading-02" data-parent="#accordion-12">
                    <div class="u-accordion__body g-color-gray-dark-v5">
                      <p class="mb-0">
                        The NSIA Personal Accident Insurance provides compensation in the event of injuries, death and disablement resulting from physical accidents. It also provides medical expenses for treatment of injuries. The cover is for 24 hours and is also applicable worldwide.  The benefits are payable in the event of:
                      </p>
                      <ul>
                        <li>Death</li>
                        <li>Permanent Disability</li>
                        <li>Physical Accidents (Medical Expenses)</li>
                      </ul>

                      <p class="mb-0">Below is a schedule of benefits to Subscribers per band.</p>
                      <p></p>
                      <div class="form-row text-center col-md-12">
                        <table class="container table table-striped mt-3">
                          <thead>
                            <tr>
                              <th scope="col">Tier</th>
                              <th scope="col">Subscriber Annual Payment(Premium)</th>
                              <th scope="col">Benefits(Medical Expense)</th>
                              <th scope="col">Benefits (Death/Permanent Disability)
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Bronze </td>
                              <td>#1,000.00</td>
                              <td>#25,000.00 </td>
                              <td> #250,000.00</td>
                            </tr>
                            <tr>
                              <td>Silver </td>
                              <td>#2,000.00 </td>
                              <td>#50,000.00 </td>
                              <td>#500,000.00 </td>
                            </tr>
                            <tr>
                              <td> Gold</td>
                              <td>#4,000.00 </td>
                              <td>#100,000.00 </td>
                              <td>#1,000,000.00 </td>
                            </tr>

                          </tbody>
                        </table>
                    </div>
                </div>
            </div>
            </div>
            <div class="card g-brd-none rounded-0 g-mb-15">
                <div id="accordion-12-heading-03" class="u-accordion__header g-pa-0" style=" background-color:#efb225;" role="tab">
                    <h6 class="mb-0">
                      <a class="d-flex g-color-main g-text-underline--none--hover g-brd-around g-brd-gray-light-v4 g-rounded-5 g-pa-10-15" href="#accordion-12-body-03" data-toggle="collapse" data-parent="#accordion-12" aria-expanded="true" aria-controls="accordion-12-body-03">
                        <span class="u-accordion__control-icon g-mr-10">
                          <i class="fa fa-angle-down"></i>
                          <i class="fa fa-angle-up"></i>
                        </span>
                       <p style="color:white">C. NSIA Comprehensive Motor Insurance</p>
                      </a>
                    </h6>
                </div>
                <div id="accordion-12-body-03" class="collapse" role="tabpanel" aria-labelledby="accordion-12-heading-03" data-parent="#accordion-12">
                    <div class="u-accordion__body g-color-gray-dark-v5">
                      <p class="mb-0">
                        The Motor Insurance Plan proposed is a Comprehensive Motor Insurance product which offers immense benefits especially to you as a DSTV Subscriber.
                      </p>
                      <p class="mb-0">Exclusive Rates & Special Benefits for Multichoice Subscribers.</p>
                      <ul>
                        <li><b>10% discount on premium</b></li>
                        <li>Cost of repairs/Pre-accidental value paid in the event of accidental damage, fire and theft to the vehicle </li>
                        <li>Cost of replacement paid for Vandalism of Car</li>
                        <li>Towing – up to N25,000 will be paid for towing services </li>
                        <li>Up to N50, 000 paid for Medical Bill (Cost of medical treatment for driver in the event of an accident.</li>
                        <li>Up to N300,000 (75,000 per passenger) paid in the event of death/bodily injury to Passenger(s) </li>
                        <li>Up to N1,000,000.00 paid in the event of damage to Third Party Property </li>
                        <li>Unlimited liability in the event of  Injury/Death of Third Party </li>
                      </ul>
                      <p></p>
                      <p class="mb-0">Additional Benefits</p>
                      <ul>
                      <li>Excess Free Policy (Applies to Cars of N20M value and above)</li>
                      <li>Free tracking device on vehicle (Applies to Cars of N7M value)</li>
                      <li>Cost of repairs/ Pre-accidental Car value  paid in the event of damages from Flood, Storm and Tempest </li>
                      <li>Cost of repairs/ Pre-accidental Car value  paid in the event of Strike, Riot & Civil commotion</li>
                      </ul>
                      <p></p>
                      <p class="mb-0">Subscribers will enjoy the following additional benefits:</p>
                      <ul>
                        <li>Unique NSIA Car fragrance (coming soon)</li>
                        <li>Driving and safety tips</li>
                      </ul>
                    </div>
                </div>
            </div>
            <div class="card g-brd-none rounded-0 g-mb-15">
                <div id="accordion-12-heading-04" class="u-accordion__header g-pa-0" style=" background-color:#efb225;" role="tab">
                    <h6 class="mb-0">
                      <a class="d-flex g-color-main g-text-underline--none--hover g-brd-around g-brd-gray-light-v4 g-rounded-5 g-pa-10-15" href="#accordion-12-body-04" data-toggle="collapse" data-parent="#accordion-12" aria-expanded="true" aria-controls="accordion-12-body-04">
                        <span class="u-accordion__control-icon g-mr-10">
                          <i class="fa fa-angle-down"></i>
                          <i class="fa fa-angle-up"></i>
                        </span>
                       <p style="color:white">D. NSIA Motor Insurance- Third Party Fire & Theft</p>
                      </a>
                    </h6>
                </div>
                <div id="accordion-12-body-04" class="collapse" role="tabpanel" aria-labelledby="accordion-12-heading-04" data-parent="#accordion-12">
                    <div class="u-accordion__body g-color-gray-dark-v5">
                      <p class="mb-0">The NSIA Value Insurance Cover is a Third Party Fire and Theft Insurance plan. Subscribers will be covered against fire and theft as well as damage to third party vehicle or property.  This is in case they damage someone else’s vehicle or property.  </p>

                      <p class="mb-0">Exclusive Rates & Special Benefits for Multichoice Subscribers</p>
                      <ul>
                        <li>Pre-accidental value paid in the event of theft of the vehicle </li>
                        <li>Cost of repairs/Pre-accidental value paid in the event of fire damage to Car</li>
                        <li>Up to N1,000,000.00 paid in the event of damage to Third Party Property </li>
                        <li>Unlimited liability in the event of  Injury/Death of Third Party </li>
                      </ul>
                      <p class="mb-0">Subscribers will enjoy the following additional benefits:</p>
                      <ul>
                        <li>Unique NSIA Car fragrance (coming soon)</li>
                        <li>Driving and safety tips </li>
                      </ul>
                    </div>
                </div>
            </div>
            <div class="card g-brd-none rounded-0 g-mb-15">
                <div id="accordion-12-heading-05" class="u-accordion__header g-pa-0" style=" background-color:#efb225;" role="tab">
                    <h6 class="mb-0">
                      <a class="d-flex g-color-main g-text-underline--none--hover g-brd-around g-brd-gray-light-v4 g-rounded-5 g-pa-10-15" href="#accordion-12-body-05" data-toggle="collapse" data-parent="#accordion-12" aria-expanded="true" aria-controls="accordion-12-body-05">
                        <span class="u-accordion__control-icon g-mr-10">
                          <i class="fa fa-angle-down"></i>
                          <i class="fa fa-angle-up"></i>
                        </span>
                       <p style="color:white">Others</p>
                      </a>
                    </h6>
                </div>
                <div id="accordion-12-body-05" class="collapse" role="tabpanel" aria-labelledby="accordion-12-heading-04" data-parent="#accordion-12">
                    <div class="u-accordion__body g-color-gray-dark-v5">

                      <ul>
                        <li>Other Insurance</li>
                      </ul>
                    </div>
                </div>
            </div>

      </div>
        <!-- <h3 class="mb-0"><strong><p>BUSINESS INSURANCE</p></strong></h3><br/> -->
        <h6>Get in touch with us today to claim your special 10% discount through any of the following channels:</h6>
        <p></p>
        <ul>
          <li>Email us at dstvthanks@nsiainsurance.com</li>
          <li>Call us on 234 1 2805378 or 234 1 2718199</li>
          <li>Visit any of our<a routerLink="/contactus"> branches</a></li>
          <li>Through our website. Click<a target="_blank" href="https://nsiadstv.azurewebsites.net/verify"> here </a> to buy
          </li>

        </ul>
        <p></p>




        <div style="padding: 10px 0px 0.05px 10px ; background-color:#efb225;">
          <h5 style="color:white">NSIA CLAIMS PROCESS </h5>
        </div>
        <p></p>
        <p class="mb-0">NSIA claims process is seamless. We require not more than three documents where all pre-insurance requirements have been submitted. It is our commitment to issue Discharge Voucher not more than 48 hours after full documentation.  Also, we are committed to payment of claims not more than 48 working hours after receipt of Discharge Voucher from Subscribers. </p>
        <p></p>
        <!-- <p class="mb-0">•	Accidental bodily injury to or illness of any person (whether or not death results).</p> -->
        <div style="padding: 10px 0px 0.05px 10px ; background-color:#efb225;">
          <h5 style="color:white">OUR SERVICE PROMISE </h5>
        </div>
        <p></p>
        <p class="mb-0">Our promise at NSIA is to ensure that we provide excellent services in all classes of insurance and to take good care of you. It is our goal to become the leading insurance provider and advisor to our clients.</p>
        <p></p>

      </div>
      <div class="col-lg-3 g-mb-30">
        <!-- Links -->
        <app-sidebar></app-sidebar>
      </div>
    </div>

    <!-- Sidebar -->
