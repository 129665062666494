import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class NsiaServicesService {
  token = localStorage.getItem('token');
  httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'Bearer ' + this.token
  })
};
  constructor(private http: HttpClient,
    private _router: Router) { }

    createAdmin(user) {
      return this.http.post( environment.baseUrl + '/api/users', JSON.stringify(user), this.httpOptions );
    }

    loginAdmin(user) {
      return this.http.post( environment.baseUrl +'/api/authenticate', JSON.stringify(user), this.httpOptions);

    }

    logout() {
      localStorage.removeItem('token');
      this._router.navigate(['/admin/login']);
    }

    isLoggedIn() {

    }


    publishPromo(promo) {
      return this.http.post('/api/promos', JSON.stringify(promo), this.httpOptions );
    }

    getNewPromo() {
      return this.http.get('/api/newpromo', this.httpOptions );
    }
}
