import { Injectable } from '@angular/core';
import { Slider } from './slider';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
@Injectable({
  providedIn: 'root'
})
export class SliderService {
  slide: AngularFireList<any>
  slider: AngularFireObject<any>
  constructor(private db: AngularFireDatabase) { }
  GetStudent(id: string) {
    this.slider = this.db.object('slider/' + id);
    return this.slider;
  }
  GetStudentsList() {
    this.slide = this.db.list('slider');
    return this.slide;
  }
  ref = this.db.list("slider");

  addMa(slider: Slider) {
    this.slide.push({
      image: slider.image

    })
  }

  editMa(slider: Slider) {
    this.slider.update({
      image: slider.image
    })
  }

  DeleteStudent(id: string) {
    this.slider = this.db.object('slider/' + id);
    this.slider.remove();
  }
}
