<div class="g-min-height-300 g-flex-centered g-bg-img-hero g-bg-pos-top-center g-bg-size-cover g-pt-80"
  style="background-image: url(assets/img/banners/Inset_Marine.jpg);">
  <div class="container g-pos-rel g-z-index-1">
    <h2 class="h1 text-uppercase g-color-primary g-font-size-40--lg mb-0"><b>Energy & Special Risk Insurance</b></h2>
    <span class="d-block  g-color-white g-font-weight-1000 g-font-size-20 mb-4">We are here to support you.</span>

  </div>
</div>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item g-color-primary">
        <span>Energy & Special Risk Insurance</span>
      </li>

      <li class="list-inline-item ml-auto">
         <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
           routerLink="/getquote">Get quote</a>
      </li>
    </ul>
  </div>
</section>


<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
    <div class="col-lg-9 g-mb-30">

      <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
        <!-- <h3 class="mb-0"><strong><p>BUSINESS INSURANCE</p></strong></h3><br/> -->
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white;">Oil & Gas Insurance</h5>
        </div>
        <p></p>
        <p class="mb-0">Oil and Gas Insurance is an insurance policy that covers human life, the environment and property from any accidents and oil and gas organization activities.
        </p>


        <br />
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white;">Bonds & Guarantees</h5>
        </div>
        <p></p>
        <p class="mb-0">
          Bond is a guarantee by one party often referred to as the Guarantor or Surety to another party who is requesting for the bond.
        </p>
        <p>It ensures that the party applying for the bond will meet its contractual obligations. </p>


        <p></p>
        <br />
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white">Construction All Risk Insurance </h5>
        </div>
        <p></p>
        <p class="mb-0">
          The policy covers contract work (both permanent and temporary works) including all material, it also protects the insured against all sums they may become liable to pay for damage in respect to:
        </p>
        <ul>
          <li>Death or bodily injury or illness or disease of any third party</li>
          <li>Loss or damage to third party’s property</li>
          <li>Loss of amenities, as a result of obstruction to third parties trespass or nuisance</li>
          <li>Denial of access, interference with light, air, water way and any other form of third party liabilities.</li>
        </ul>

        <br />
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white">Erection All Risks Insurance</h5>
        </div>
        <p></p>
        <p class="mb-0">
          The policy is  designed to cover the risk of loss arising from the erection, installation, commissioning and testing of machinery, plant and steel structures, including physical damage to the contract works, equipment and machinery, and liability for third-party bodily injury or property damage (PD) arising from of these operations.
        </p>

        <br />
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white">Marine Hull</h5>
        </div>
        <p></p>
        <p class="mb-0">
          The policy provides protection for your vessel or fleet against physical damage caused by a peril of the sea or other covered perils while the vessel is in transit over water.
        </p>

        <br />

      </div>
    </div>

    <!-- Sidebar -->
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <app-sidebar></app-sidebar>
      <!-- End Fast Facts -->
    </div>
    <!-- End Sidebar -->
  </div>
</div>
