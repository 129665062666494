import { Injectable } from '@angular/core';
import { Manage } from './manage';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
@Injectable({
  providedIn: 'root'
})
export class ManageService {
  managel: AngularFireList<any>
  managell: AngularFireObject<any>
  constructor(private db: AngularFireDatabase) { }
  GetStudent(id: string) {
    this.managell = this.db.object('management/' + id);
    return this.managell;
  }
  GetStudentsList() {
    this.managel = this.db.list('management');
    return this.managel;
  }
  ref = this.db.list("management");

  addMa(manage: Manage) {
    this.managel.push({
      image: manage.image,
      name: manage.name,
      title: manage.title,
      abouta: manage.abouta,
      aboutb: manage.aboutb,
      aboutc: manage.aboutc,
      aboutd: manage.aboutd
    })
  }

  editMa(manage: Manage) {
    this.managell.update({
      image: manage.image,
      name: manage.name,
      title: manage.title,
      abouta: manage.abouta,
      aboutb: manage.aboutb,
      aboutc: manage.aboutc,
      aboutd: manage.aboutd
    })
  }
  DeleteStudent(id: string) {
    this.managell = this.db.object('management/' + id);
    this.managell.remove();
  }
}