<div class="g-min-height-300 g-flex-centered g-bg-img-hero g-bg-pos-top-center g-bg-size-cover g-pt-80"
  style="background-image: url(assets/img/banners/Inset_Health.jpg);">
  <div class="container g-pos-rel g-z-index-1">
    <h2 class="h1 text-uppercase g-color-primary g-font-size-40--lg mb-0"><b>NSIA Health Insurance</b></h2>
    <span class="d-block  g-color-white g-font-weight-1000 g-font-size-20 mb-4"> Protecting the key assets of your
      livelihood.</span>

  </div>
</div>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item g-color-primary">
        <span>Health Insurance</span>
      </li>

      <li class="list-inline-item ml-auto">
        <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
          routerLink="/getquote">Get quote</a>
      </li>
    </ul>
  </div>
</section>


<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
    <div class="col-lg-9 g-mb-30">
      <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
        <!-- <h3 class="mb-0"><strong><p>BUSINESS INSURANCE</p></strong></h3><br/> -->
        <div style="padding: 0; background-color:#FFFFFF;">
          <h5 style="color:#052c52; font-weight: 900; font-size: 13px;">NSIA HEALTH INSURANCE </h5>
        </div>
        <p></p>
        <p class="mb-0" style="text-align: justify">NSIA Health Insurance in collaboration with a leading African
          insurance company and one of the
          largest health insurers in the world, provides health insurance for companies who want to insure their key
          resources and a solution for multinationals looking to harmonize their health insurance benefits across
          Africa.</p>
        <p></p>
        <p class="mb-0" style="text-align: justify">We know that people are the most important assets of an
          organization, and that a healthy team is
          a productive team. Having us as your Health Insurance partner shows that you are passionate about investing in
          the power, efficacy and well-being of your people.</p>
        <br />
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white; letter-spacing: 2px; "><b>Why Choose NSIA HEALTH INSURANCE?</b></h5>
        </div>
        <p></p>
        <p></p>
        <p class="mb-0" style="text-align: justify">
          NSIA Health Insurance offers you a combination of health insurance plans that provides you with optimised
          coverage for your budget. From plans that provide expatriates with cover wherever they are in the world, to
          plans combining local and best-in-Africa cover, our premium product suite covers all eventualities when it
          comes to addressing your unique health insurance requirements.
        </p>
        <p></p>
        <p></p>
        <p class="mb-0" style="text-align: justify">
          Regardless of what combination you choose, you’ll always get access to experienced and committed health care
          professionals in Africa. We go one step further and make it easy for your employees through quick and simple
          resolution of claims, access to a 24/7 toll-free call Centre and an industry-leading set of digital tools to
          deal with any queries, we ensure quality service as your health insurer.
        </p>
        <p></p>
        <br />
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white; letter-spacing: 2px;"><b>YOU CAN CHOOSE FROM 4 AREAS OF COVER</b></h5>
        </div>
        <p></p>
        <ul>
          <li>Area of cover 1: Africa and India</li>
          <li>Area of cover 2: Africa, India and Europe</li>
          <li>Area of cover 3: Worldwide excluding US</li>
          <li>Area of cover 5: Worldwide* </li>
        </ul>
        <p></p>

        <br />
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white;  letter-spacing: 2px;"><b>YOU CAN CHOOSE FROM 6 PLANS WITHIN THE FOLLOWING STEPS:</b>
          </h5>
        </div>
        <p></p>
        <img src="assets/img/healthplans.jpg" alt="health-plans" class="imgresponsive">
        <p></p>
        <br>
        <p></p>
        <p class="mb-0" style="padding: 0px 10px; text-align: justify;">
          You can choose <b>Step 1(Basic Plan) </b>only, which will give you access to inpatient care, condition
          management,
          wellness and preventive care, evacuation and out-of-country care.
        </p>
        <p></p>
        <p class="mb-0" style="padding: 0px 10px; text-align: justify">
          Depending on your need per time, you can decide to add <b>Step 2 (Enhanced Plan) </b>which gives you all the
          benefits
          of the Basic Plan in addition to outpatient care.
        </p>
        <p></p>
        <p class="mb-0" style="padding: 0px 10px; text-align: justify">
          Finally, you could add all 3 steps, which is the <b>Complete Plan</b>, and gives you all the benefits of the
          Basic
          and Enhanced plan, with an additional benefit of Dental and Vision.
        </p>
        <p></p>
        <p class="mb-0" style="padding: 0px 10px; text-align: justify">
          Within each step, you can choose from Options 1 to 5, depending on your budget. Please note that, you cannot
          skip a step, but you can <b>mix and match</b> your options. </p>
        <p></p>
        <p></p>
        <p class="mb-0"><b>Global Wellbeing Solutions </b></p>

        <p class="mb-0" style="text-align: justify">Peace of mind may be hard to come by when helping your employees
          balance the demands of their
          work and personal lives. That’s where we create a balance with our health and wellbeing programmes. We offer a
          comprehensive, integrated wellness approach to create a healthier, more engaged and more productive workforce.
        </p>

        <ul>
          <li>
            Employee Assistance Programme
          </li>
          <li>Telehealth</li>
          <li>
            Online Health Risk Assessment
          </li>
          <li>
            Online Coaching targeting identified Health Risks
          </li>
          <li>
            Treatment Decision Support Services
          </li>
          <li>
            Chronic Condition Management
          </li>
          <li>Palliative Treatment</li>
          <li>Kidney failure</li>
          <li>HIV/AIDS</li>
          <li>Cancer</li>
        </ul>

        <p></p>
        <p class="mb-0">Call <b>08097209218</b> or send an email to <a href="mailto: customerservice@nsiainsurance.com"
            style="text-decoration: underline; color: #052c52;">customerservice@nsiainsurance.com</a> for more
          information
          today! </p>


      </div>
    </div>

    <!-- Sidebar -->
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <app-sidebar></app-sidebar>
      <!-- End Fast Facts -->
    </div>
    <!-- End Sidebar -->
  </div>
</div>