export class HouseHolderForm {
  constructor(
    public Name: string,
    public HouseData:HouseHolder[],
    public propertyAddress:string,
    public Phonenumber: string,
    public EmailAddress: string,
    public agent:string,
    public filenameId:string,
    public filetypeId:string,
    public base64textStringId:string,
    public filestringId: string,
    public src:string
  ) {

  }
}


export class HouseHolder {
  constructor(
    public Products:string,
    public Quantity:string,
    public Value:string
  ) {

  }
}
