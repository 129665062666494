import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms'
import { ContractorAllRiskProposalForm } from 'src/app/Classes/contractor-all-risk-proposal-form';
import { FormserviceService } from 'src/app/Services/formservice.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-contractor-all-risk-proposal-form',
  templateUrl: './contractor-all-risk-proposal-form.component.html',
  styleUrls: ['./contractor-all-risk-proposal-form.component.css']
})
export class ContractorAllRiskProposalFormComponent implements OnInit {
  model;
  dateTemp = new Date();

 proposalModel = new  ContractorAllRiskProposalForm("","","","","","",
  "",this.dateTemp,"","",null,"", "","",null,null,null,null,"","", "","",
  "","","","","","","", "", this.dateTemp, this.dateTemp,"","");
  constructor(private _formservice: FormserviceService) { }

  ngOnInit(): void {
  }
  submitForm(myForm: NgForm){
    var email = this.proposalModel.EmailAddress;

    var regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;


    if(!regex.test(email)) {
     alert("Your email is not correct");
      this._formservice.stopLoading();
      return;
    }
    console.log($('#Telephone').val());
    var telLength = $('#Telephone').val().toString().length;
    //var secTel =$("#AlternativeTel").val().toString().length;

    if(telLength < 10){
     alert("Your telephone number is not correct");
      this._formservice.stopLoading();
      return;
    }
 console.log(this.proposalModel);
    this._formservice.startLoading();
    this._formservice.sendContractorAllRiskProposalForm(this.proposalModel)
      .subscribe(
        (data) => {
          if (data.statusCode == "00") {
           alert("Your form is submitted");
            myForm.resetForm();
          } else {

            alert(data.statusMessage) ;
          }
          this._formservice.stopLoading();
        },
        (error) => {
          console.log(error);

          this._formservice.stopLoading();
        }
      )

  }
}
