import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fire-insurance',
  templateUrl: './fire-insurance.component.html',
  styleUrls: ['./fire-insurance.component.css']
})
export class FireInsuranceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
