export class ProfessionalIndemityProposalForm {
  constructor(
    public NameOfFirm: string,
    public NatureOfBusiness:string,
    public Address:string,
    public DateOfIncorporation:string,
    public rcnumber:string,
    public modeofId:string,
    public idnumber:string,
    public Telephone:string,
    public mobileno:string,
    public totalnumberofstaff:string,
    public professionalassociationbody:string,
    public classofmembership:string,
    public holdProfessionalIndemnityPolicy:string,
    public discharged:string,
    public professionalservice:string,
    public AmountofIndemnity:string,
    public GrossFees:string,
    public limitofliability:string,
    public claimoutstanding:string,
    public claimbeenmade:string,
    public limitperoccurrence:string,
    public aggregateanyoneperiod:string



  ) {

  }
}
