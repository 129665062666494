<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
     <ul class="d-flex justify-content-between u-list-inline">
        <li class="list-inline-item g-mr-15">
           <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
           <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
        </li>
        <li class="list-inline-item g-color-primary">
           <span>Blog</span>
        </li>
        <li class="list-inline-item ml-auto">

        </li>
     </ul>
  </div>
</section>
<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
<!-- Heading -->
<div class="g-max-width-550 text-center mx-auto g-mb-70">
  <h1 class="h3 mb-5">
     <p>Blog</p>
  </h1>
</div>
<!-- End Heading -->



<div class="row">
  <div class="col-lg-12 g-mb-30">
     <div class="row g-mx-minus-25 g-mb-50">

      <div *ngFor=" let blog of blogsDetails?.data" class="col-lg-6 g-px-25 g-mb-50">
         <!-- Blog Grid Modern Blocks -->
         <article  class="product-item row align-items-stretch no-gutters u-shadow-v29 g-transition-0_3">

            <div class="col-sm-6 g-bg-white g-rounded-left-5">
               <div class="g-pa-35">
                  <h2 class="h5 g-color-black g-font-weight-600 mb-4">
                     <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" routerLink="/blog/{{blog?.id}}/blog-details">
                        <p>{{blog?.title}}<br/><span style="font-size:12px;"></span></p>
                     </a>
                  </h2>
                  <!-- {{blogDetails.length >100 ? (blogDetails: slice: 100) + '...' : blogDetails}} -->
                  <p class="" [innerHTML]="blog?.content.length> 100 ?  blog?.content.slice(0,150) + ' ...' : blog?.content">
                    <!-- <div [innerHTML]="comment"></div> -->

                   </p>


                   <br/>
                  <ul class="list-inline g-font-size-12 mb-0">
                     <li class="list-inline-item g-mb-10">

                        <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                           routerLink="/blog/{{blog?.id}}/blog-details">Read more</a>
                     </li>
                  </ul>
               </div>
            </div>
            <!-- <div
               data-bg-img-src="{{blog?.image}}">

              </div> -->
              <img class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5" src="{{blog.image}}"/>

            <!-- <div class="col-sm-6 g-bg-size-cover g-bg-pos-center g-min-height-300 g-rounded-right-5"  data-bg-img-src="assets/img/anxiety.jpeg"   >
            </div> -->
         </article>

         <!-- End Blog  data-bg-img-src="blog?.image" Grid Modern Blocks -->

      </div>

     </div>
  </div>
</div>
<mat-paginator class="mat-elevation-z8" [length]="blogsDetails?.totalRecords" [showFirstLastButtons]="true" [pageSize]="pageSize"  (page)="pageLoad($event)">
</mat-paginator>
</div>



