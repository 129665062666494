import { Injectable } from '@angular/core';
import { Board } from './board';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
@Injectable({
  providedIn: 'root'
})
export class BoardService {
  boardl: AngularFireList<any>
  boardll: AngularFireObject<any>
  constructor(private db: AngularFireDatabase) { }
  GetStudent(id: string) {
    this.boardll = this.db.object('board/' + id);
    return this.boardll;
  }
  GetStudentsList() {
    this.boardl = this.db.list('board');
    return this.boardl;
  }
  ref = this.db.list("board");

  addBo(board: Board) {
    this.boardl.push({
      image: board.image,
      name: board.name,
      title: board.title,
      abouta: board.abouta,
      aboutb: board.aboutb,
      aboutc: board.aboutc,
      aboutd: board.aboutd,
      aboute: board.aboute
    })
  }

  editBo(board: Board) {
    this.boardll.update({
      image: board.image,
      name: board.name,
      title: board.title,
      abouta: board.abouta,
      aboutb: board.aboutb,
      aboutc: board.aboutc,
      aboutd: board.aboutd,
      aboute: board.aboute
    })
  }
  DeleteStudent(id: string) {
    this.boardll = this.db.object('board/' + id);
    this.boardll.remove();
  }

}
