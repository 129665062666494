import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { BlogPostComponent } from "./blog-post/blog-post.component";
import { BlogsComponent } from "./blogs/blogs.component";

const routes: Routes = [
  // {path: 'blogs/post', component: BlogPostComponent}
  {path: 'blogs-list', component: BlogsComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class CustomBlogsRoutingModule { }
