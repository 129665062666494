import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-anticipated',
  templateUrl: './anticipated.component.html',
  styleUrls: ['./anticipated.component.css']
})
export class AnticipatedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
