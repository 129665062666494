import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ManageService } from 'src/app/manage/manage.service';
@Component({
  selector: 'app-editmanagement',
  templateUrl: './editmanagement.component.html',
  styleUrls: ['./editmanagement.component.css']
})
export class EditmanagementComponent implements OnInit {
  editForm: FormGroup;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private manageService: ManageService, private fb: FormBuilder) { }

  ngOnInit() {
    this.updateStudentData();
    const id = this.route.snapshot.paramMap.get('id');
    this.manageService
      .GetStudent(id)
      .valueChanges()
      .subscribe((data) => {
        this.editForm.setValue(data);
      });
  }

  get image() {
    return this.editForm.get('image')
  }
  get name() {
    return this.editForm.get('name')
  }
  get title() {
    return this.editForm.get('title')
  }
  get abouta() {
    return this.editForm.get('abouta')
  }
  get aboutb() {
    return this.editForm.get('aboutb')
  }
  get aboutc() {
    return this.editForm.get('aboutc')
  }
  get aboutd() {
    return this.editForm.get('aboutd')
  }


  updateStudentData() {
    this.editForm = this.fb.group({
      image: "",
      name: "",
      title: "",
      abouta: "",
      aboutb: "",
      aboutc: "",
      aboutd: ""
    });
  }
  updateForm() {
    this.manageService.editMa(this.editForm.value);

    this.router.navigate(['/access-to-management']);
  }

}
