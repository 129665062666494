
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;">FOSTERING HEALTHY RELATIONSHIPS IN THE WORK PLACE</p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/team.jpg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>Life experiences through time have led us to believe that the type of relationship an individual maintains goes a long way to determine the extent of our network of influence.
                 </p>
                 <p></p>
                 <p>Though the workplace is an environment that requires a high level of professionalism, it is also important to foster cordial and healthy relationships that encourage mutual respect among employees, irrespective of hierarchy.


                 </p>
                 <p></p>


              </div>
           </div>


          <div class="row justify-content-between">


              <div class="col-md-12">
               <p>We spend the better part of our daily lives at work thus the environment has an impact on staff commitment, directly or indirectly, as well as an impact on the organization. Encouraging healthy relationships in the work environment helps employees carry out their duties without unnecessary bottlenecks due to friction. It boosts confidence, helps to reduce tension and encourages positive thinking.
               </p>
               <p></p>
                <p>Some healthy work relationships come naturally, while others take more effort. Some turn into friendships, while others remain strictly professional. Whatever the case, keep these suggestions in mind when nurturing a rapport with your coworkers:</p>
                <p></p>
                <p><strong>Take the first step</strong></p>
                <p></p>

                 <p>If you are new on the job, it may be hard to start up a conversation but you can ask a trusted friend for tips. Start a daily conversation with the person who sits next to you. Small interactions will help you learn about those around you, and help you begin to form connections with certain people.
                 </p>
                 <p></p>
                <p><strong>Make your connections positive</strong></p>
                 <p>You’re fortunate when you find people you really connect with at work, but it’s important to look at what’s bringing you together. Maybe you collaborate effectively with your boss because you listen to one another well. Or maybe you get along with your team member because you often exchange stories about your favourite TV shows. These are healthy bonds built on what you both enjoy doing. </p>

                <p></p>

                <p><strong>Offer what you want to receive</strong></p>
<p>Treat everyone as you would like to be treated. When it comes to interacting with people at work, offering what you would want out of the relationship—respect, thoughtfulness, optimism—is the best approach.</p>

<p></p>
<p><strong>Manage your expectations</strong></p>
<p>It’s not everyone’s goal to get along with their coworkers. It’s important to be conscious of those situations so you can be realistic in your approach and response to interactions.</p>

<p></p>
<p><strong>Communicate</strong></p>

<p>Being clear about your feelings is key to maintaining any relationship. Oftentimes we misinterpret the tone of an email or body language during a meeting, and we take it personally. Communicating these concerns makes understanding possible.</p>


                <p><strong><i>“Teamwork makes the dream work”</i></strong> – John C. Maxwell </p>

              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>