<div class="container g-pt-100 g-pb-70">
  <div class="row">
    <div class="col-lg-12 g-mb-30">
      <section class="g-bg-gray-light-v5 g-py-20">
        <form novalidate (ngSubmit)="submitForm(beneficiaryForm)" #beneficiaryForm="ngForm" novalidate>
          <div class="container g-py-100 pad-top-50" style="margin-top:-50px">
            <div class="u-shadow-v19 g-brd-around g-brd-gray-light-v4  rounded mx-auto g-pa-30 g-pa-50--md">

              <div class="mb-6">
                <h3 class="mb-4 main-heading" style="font-size: 30px;">
                  WORKMEN COMPENSATION INSURANCE PROPOSAL FORM
                </h3>

                <section class="step-two" >
                  <div class="form-sub-title">
                    <h4>Personal Information</h4>
                  </div>
                  <div class="row">

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>Name of Proposer</label>
                        <input id="NameOfProposal" name="NameOfProposal" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.NameOfProposal" required>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Address of Proposer
                        </label>
                        <input id="AddressOfProposal" name="AddressOfProposal" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.AddressOfProposal" required >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Trade Or Business
                        </label>
                        <input id="TradeOrBusiness" name="TradeOrBusiness" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.TradeOrBusiness" required >
                      </div>
                    </div>


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          E- mail address
                        </label>
                        <input id="EmailAddress" name="EmailAddress" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.EmailAddress" required type="text" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" >

                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Mobile No
                        </label>
                        <input id="Telephone" name="Telephone" class="form-control form-control-md rounded-0" type="number"
                        placeholder="Required" [(ngModel)]="proposalModel.Telephone" required="required" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==11) return false; return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57));" >

                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Alternate Number
                        </label>
                        <input id="SecondTelephone" name="SecondTelephone" class="form-control form-control-md rounded-0" type="number"
                         [(ngModel)]="proposalModel.SecondTelephone"  pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==11) return false; return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57));" >

                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                         <label>
                          Start Date
                         </label>
                         <input id="StartDate" name="StartDate" class="form-control form-control-md rounded-0" type="date"
                          placeholder="yyyy-mm-dd" [(ngModel)]="proposalModel.StartDate" >

                      </div>
                   </div>
                   <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                         <label>
                          End Date
                         </label>
                         <input id="EndDate" name="EndDate" class="form-control form-control-md rounded-0" type="date"
                          placeholder="yyyy-mm-dd" [(ngModel)]="proposalModel.EndDate"  >
                      </div>
                   </div>
                  </div>
                </section>


              <section class="step-four" >
                <div class="form-sub-title">
                  <h4>Schedule of Staff </h4>
                </div>

                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-group g-mb-20">
                      <label>
                        Estimated Number of Employees
                      </label>

                      <input id="estimatednumberofemployees" name="StaffModel.estimatednumberofemployees" class="form-control form-control-md rounded-0" type="text"
                      placeholder="Required" [(ngModel)]="StaffModel.estimatednumberofemployees" required>

                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group g-mb-20">
                      <label>
                        Categories of Employees
                      </label>
                      <input id="categoriesofemployees" name="StaffModel.categoriesofemployees" class="form-control form-control-md rounded-0" type="text"
                        placeholder="Required" [(ngModel)]="StaffModel.categoriesofemployees" required>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group g-mb-20" >
                      <label>
                        Estimated Annual Earning
                      </label>
                      <input id="estimatedannualearning" name="StaffModel.estimatedannualearning" class="form-control form-control-md rounded-0" type="text"
                        placeholder="Required" [(ngModel)]="StaffModel.estimatedannualearning" required >
                    </div>
                  </div>



                    <div class="col-lg-4">
                    <div class="form-group g-mb-20">
                      <label style="visibility: hidden;">
                        Frequency of payment </label>
                      <button (click) ="Add()" type="button" class="g-brd-main btn-block g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" > Add
                      </button>
                    </div>
                  </div>
                  </div>
                  <div class="nok-table" *ngIf="showBeneficiaryTable">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Estimated Number of Employees  </th>
                          <th>Categories of Employees</th>
                          <th>Estimated Annual Earning (Wages , Salaries and Others)</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let ben of proposalModel.StaffModel; let i = index">
                          <!-- <th scope="row">#</th> -->
                          <td>{{ben.estimatednumberofemployees}}</td>
                          <td>{{ben.categoriesofemployees}}</td>
                          <td>{{ben.estimatedannualearning}}</td>
                          <td>
                            <button class="btn btn-danger btn-block" (click)="deleteBen(i)" >Delete</button>
                          </td>

                        </tr>

                        </tbody>

                      </table>
                    </div>

                  </section>

                  <section class="step-three">
                    <div class="">
                      <h4>General Questions</h4>
                    </div>
                    <div class="row">



                      <div class="col-lg-6" >
                        <div class="form-group g-mb-20">
                          <label>
                            Description of activities/business or occupation. Are you involved in

                          </label>
                          <!-- <input id="descriptionofactivities" name="descriptionofactivities" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.descriptionofactivities" > -->

                           <select name="descriptionofactivities" id="descriptionofactivities" class="form-control form-control-md rounded-0"
                           [(ngModel)]="proposalModel.descriptionofactivities">
                              <option >Select your option</option>
                              <option value="All">All</option>
                              <option value="Manufacturing">Manufacturing</option>
                              <option value="Filling">filling</option>
                              <option value="Breaking down of gun-powder">Breaking down of gun-powder</option>
                              <option value="nitro-glycerine">Nitro-glycerine or any other
                                explosive or toxic material</option>
                              <option value="others">Others</option>
                          </select>

                        </div>
                      </div>
                      <!-- *ngIf="proposalModel.descriptionofactivities == Yes" -->
                      <div class="col-lg-6"  >
                        <div class="form-group g-mb-20">
                          <label>
                            Others (occupations to be described)

                          </label>
                          <input id="othersoccupation" name="othersoccupation" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.othersoccupation" >
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group g-mb-20">
                          <label>
                            Are your employee engaged in any hazardous pastimes or sport? If so please state:

                          </label>
                          <input id="employeeengaged" name="employeeengaged" class="form-control form-control-md rounded-0" type="text"
                          [(ngModel)]="proposalModel.employeeengaged" >

                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group g-mb-20">
                          <label>
                            Are all machines equipped with emergency/caution signs?
                         </label>

                         <input id="areallmachinesequipped" name="areallmachinesequipped" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.areallmachinesequipped" >

                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group g-mb-20">
                          <label>
                            Do all employees get conversant with standard safety procedures?

                          </label>
                          <input id="allemployeesgetconversant" name="allemployeesgetconversant" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.allemployeesgetconversant" >
                        </div>
                      </div>



                      <div class="col-lg-6">
                        <div class="form-group g-mb-20">
                          <label>
                            Do you have similar policy with other insurance Company? If yes  give details
                         </label>

                         <input id="similarpolicy" name="similarpolicy" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.similarpolicy" >

                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group g-mb-20">
                          <label>
                            Have you ever claimed upon any Insurer for the contingency now to be insured? If so,
        state when and give the name of the insurer and the amount of the loss.


                         </label>

                         <input id="claimeduponinsurer" name="claimeduponinsurer" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.claimeduponinsurer" >

                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group g-mb-20">
                          <label>
                            Is there any incidence of injury/ accident including death to workers/ employee for the last 3 years? Even though not reported to insurance company or there is no insurance.

                          </label>
                          <input id="incidence" name="incidence" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.incidence">

                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group g-mb-20">
                          <label>
                            Who is considered the beneficiary in the event of claim:
                            (The applicant/principal/association/company?)
                          </label>

                          <select name="beneficiary_event_claim" id="beneficiary_event_claim" class="form-control form-control-md rounded-0"
                        [(ngModel)]="proposalModel.beneficiary_event_claim">
                           <option >Select your option</option>
                           <option value="The applicant">The applicant</option>
                           <option value="Principal">Principal</option>
                           <option value="Association">Association</option>
                           <option value="Company">Company</option>
                           <option value="Legal Representative">Legal Representative</option>
                       </select>
                        </div>
                      </div>

                      <!-- <div class="col-lg-6">
                        <div class="form-group g-mb-20">
                          <label>
                            Who is considered the beneficiary in the event of claim:
                            (The Insured or in the case of death the legal representative?)
                          </label>

                          <select name="legal_representative" id="legal_representative" class="form-control form-control-md rounded-0"
                          [(ngModel)]="proposalModel.legal_representative">
                             <option >Select your option</option>
                             <option value="Yes">Yes</option>
                             <option value="No">No</option>
                         </select>

                        </div>
                      </div> -->

                    </div>
                  </section>


               <section class="step-seven" >

                 <div class="container">
                   <h4>Declaration</h4>
                   <p>I/We hereby declare that to the best of my/our knowledge and belief the information herein provided is correct and complete.</p>

                   <!-- <div class="form-group">
                    <input type="checkbox" [(ngModel)]="termAgreed" name="termAgreed" > By checking the box , I agree with the above terms
                  </div> -->

                      <p style="font-size:10px; text-align: justify;">In compliance with NDPR requirements, by completing and submitting this form, you have given consent to NSIA Insurance to receive your data, including your bio data, to enable NSIA Insurance maintain effective communication, send promotional updates, contact you for research purposes and use the data in line with the policy terms.
                      </p>
                      <p style="font-size:10px; text-align: justify;">The data collection may be via hard copies or online forms, validated via JSON WEB TOKEN (JWT). The data will not be accessed by any third party without your consent and any breach of this policy can be addressed legally within the year the breach was reported.</p>

                      <div class="col-lg-4">
                        <div class="form-group g-mb-0">
                          <label style="visibility: hidden;">Frequency</label>
                          <button  type="submit" onclick="return confirm('Are you sure you want to submit this information?')" class="g-brd-main btn-block g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" > Submit
                          </button>
                        </div>

                      </div>

                 </div>

               </section>

              </div>


            </div>
          </div>
        </form>
      </section>
    </div>

    <!-- End Sidebar -->
  </div>
</div>
