
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;">HOW TO MANAGE NEW YEAR ANXIETY</p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/anxiety.jpeg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>
                    The start of a New Year can sometimes make us anxious because it’s the start of a whole year and even though technically, it is not any different from any other day, it still can feel like you’re ending one chapter in your life and preparing to pen the next. When you look at it that way, that’s a lot of pressure to put on yourself. Here are a few small steps you can take during this new year.
                 </p>
                 <p></p>

              </div>
           </div>

          <div class="row justify-content-between">
            
              <div class="col-md-12">
            <p><strong>Commit selectively
                <br/>
                </strong> Over-commitment can be a huge hurdle towards achieving our goals and resolutions. It’s hard to say no and risk being the ‘bad guy’, but when we put too much pressure on ourselves to be and do everything, it leaves little to no space for us to grow and develop in the ways that matter most to us. When we take on too much we are, in essence, setting ourselves up for failure.

            </p>
               
            <p><strong>Stop procrastinating, start today
                <br/>
                </strong>  You don’t need to wait until a specific time of year to start making the changes you want to see in your life. Sure, the idea of having a new start in the new year is a nice one, but who says you have to wait to get started? Start today!
               
            </p>

            <p><strong> Set healthy boundaries
                <br/>
                </strong>  Setting healthy, sustainable boundaries can not only help you avoid taking on too much, but it can also help shape your focus, career, and goals. If you’re worried you may overload yourself with too many expectations for the upcoming year, take the time to sit down and figure out what matters most to you. Make a list of the things you want to have achieved by this time next year.
               
            </p>

            <p><strong> Set healthy boundaries
                <br/>
                </strong>  Setting healthy, sustainable boundaries can not only help you avoid taking on too much, but it can also help shape your focus, career, and goals. If you’re worried you may overload yourself with too many expectations for the upcoming year, take the time to sit down and figure out what matters most to you. Make a list of the things you want to have achieved by this time next year.
               
            </p>
           
           
            
            <p><strong>  Keep an eye out for burnout
                <br/>
                </strong>  We all experience stress from time to time, but do you know the differences between stress and burnout? Often presenting with similar symptoms, while stress tends to be more short-term and manageable, burnout can lead to some serious problems for your health, mood, motivation, home and work life. Find a balance and make self-care a priority. 
            
            </p>

            <p><strong> Take care of your mind and body
                <br/>
                </strong> If worry and anxiety about the upcoming year are starting to take their toll, take this chance to step back and focus on putting you first. Health and wellbeing can take a backseat when we are feeling overwhelmed, stressed, anxious or nervous; making small changes to help you address these feelings can help you to develop ongoing positive coping mechanisms that will support you throughout the new year and beyond.
            
            </p>
        </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>