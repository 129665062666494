import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SliderService } from 'src/app/slider/slider.service';
@Component({
  selector: 'app-edit-slider',
  templateUrl: './edit-slider.component.html',
  styleUrls: ['./edit-slider.component.css']
})
export class EditSliderComponent implements OnInit {
  editForm: FormGroup;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private sliderService: SliderService, private fb: FormBuilder) { }

  ngOnInit() {
    this.updateStudentData();
    const id = this.route.snapshot.paramMap.get('id');
    this.sliderService
      .GetStudent(id)
      .valueChanges()
      .subscribe((data) => {
        this.editForm.setValue(data);
      });
  }

  get image() {
    return this.editForm.get('image');
  }

  updateStudentData() {
    this.editForm = this.fb.group({
      image: ""
    });
  }
  updateForm() {
    this.sliderService.editMa(this.editForm.value);

    this.router.navigate(['/access-to-slider']);
  }

}
