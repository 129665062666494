import { Component, OnInit, ViewContainerRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgProgressComponent } from 'ngx-progressbar';
import { ToastrService } from 'ngx-toastr';
import { Utilities } from 'src/app/Helpers/utilities';
import {
  FileUplaod,
  SendClaimsModel,
} from 'src/app/payment-integration/Models/claims-file-upload.model';
import { SubRiskModel } from 'src/app/payment-integration/Models/sub-risk.model';
import { MainserviceService } from 'src/app/Services/mainservice.service';
import { PaymentIntegrationService } from 'src/app/Services/payment-integration.service';

@Component({
  selector: 'app-report-claim',
  templateUrl: './report-claim.component.html',
  styleUrls: ['./report-claim.component.css'],
})
export class ReportClaimComponent implements OnInit {
  model;
  subRisks: SubRiskModel[];
  claimFormGroup: FormGroup;
  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;
  claimData = {
    email: '',
    phoneno: '',
    bizclassId: '',
    bizclass: '',
    policyno: '',
    regNo: '',
    location: '',
    description: '',
    dateofloss: '',
    filestringId: '',
    filetypeId: '',
    filenameId: '',
    filestringdoc: '',
    filetypedoc: '',
    filenamedoc: '',
    filestringId2: '',
    filetypeId2: '',
    filenameId2: '',
    filestringdoc2: '',
    filetypedoc2: '',
    filenamedoc2: '',
  };
  sendClaims: SendClaimsModel;

  fileUpload: FileUplaod[] = [];
  fileProgressArray: number[];
  filenameId: string;
  filetypeId: string;
  base64textStringId: string;
  processedImagesId: any = [];
  filestringId: string;
  step: number = 1;
  src: string = '';

  constructor(
    public mainService: MainserviceService,
    public alert: ToastrService,
    private fb: FormBuilder,
    private paymentService: PaymentIntegrationService,
    viewContainerRef: ViewContainerRef,
    private _utils: Utilities
  ) {

    this.claimFormGroup = fb.group({
      productType: ['', Validators.required],
      description: ['', Validators.required],
      policyId: ['', [Validators.required]]
    })
  }

  ngOnInit(): void {
    this.getSubRisks();
    this.fileProgressArray = [];
  }

  closeFileItem(index) {
    this.fileProgressArray.splice(index, 1);
    this.fileUpload.splice(index, 1);
  }


  get policyId() {
    return   this.claimFormGroup.get("policyId");
  }
  get description() {
    return   this.claimFormGroup.get("description");
  }

  get productType() {
    return   this.claimFormGroup.get("productType");
  }

  onFileChange(event) {
    this.set_upReader(event.target.files, 4);
  }

  selected(files) {
    console.log(files)
    let theFile = files;
    this._utils.ResizeImage(theFile, 128, 128).subscribe((file: File) => {
      var reader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onload = (_event) => {
        this.src = reader.result.toString();
        const fileTest: FileUplaod = {
          fileBinary: this.src.substr(this.src.indexOf(',') + 1),
          extensions: file.type,
          fileName: file.name,
          fileType: file.type,
        };
        this.fileUpload.push(fileTest);
        console.log(this.fileUpload);
        console.log(reader.result);
        console.log(reader);

        // this.claimData.filenameId= file.name
        // this.claimData.filetypeId= file.type
        // this.claimData.filestringId=this.src.substr(this.src.indexOf(',')+ 1)
      };
    });
  }

  set_upReader(files, i) {
    let uploadFiles;
    this.fileUpload = [];
    console.log(files);
    if(files ) {
      
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileName = files[i].name;
        const fileSize = files[i].size;
        const fileType = files[i].type;
        if(files[i] >25597152) {
          this.alert.error(
          'File attached ' +
          file.name +
          '  with file size greater than 10MB is not allowed',
          // tslint:disable-next-line: no-unused-expression
          'Error! ');
          }else {
            const fileType = files[i].type;
            const previewReader = new FileReader();
            previewReader.onprogress = (e) => {
              this.fileProgressArray.push(e.loaded / e.total * 100);
            }
            previewReader.readAsDataURL(files[i]);

            previewReader.onload = (e:any) => {
              uploadFiles = this.readerLoaded(e, files, i, fileName, fileSize, fileType);
              this.fileUpload.push(uploadFiles);
              console.log(uploadFiles);
              console.log(this.fileUpload);
              
            };
          }
      }
      
    }
  }

  readerLoaded(e, files, i, fileName, fileSize, fileType) {

    const file = new FileReader();
    let fileUploaded = [];
    // this.fileUpload = [];
    const uploaded = new FileUplaod();
    
    uploaded.fileBinary = (<string>e.target.result).split(',')[1];



    uploaded.extensions=fileType;
    uploaded.fileType =fileType;
    uploaded.fileName = fileName;

    console.log(uploaded);
    
    // this.fileUpload = fileUploaded;

    console.log(fileUploaded);
    console.log(this.fileUpload );
    return uploaded;
  }


  
  OnclaimSubmit() {
    this.progressBar.start();
    console.log(this.fileUpload);
    const files  = this.fileUpload;
    
      let claimsDetails: SendClaimsModel = {
        claimDescriptions:this.description.value,
        policyNo: this.policyId.value,
        productType: this.productType.value,
        uploadPictures: files
      }

      console.log(claimsDetails);

      this.mainService.sendClaimsEndpoint(claimsDetails).subscribe(response => {
        if(response !=null) {
          this.alert.success('Sucessfully Submitted', 'Success');
          this.progressBar.complete();
          this.claimFormGroup.reset();
        } else {
          this.alert.error('Claim cannot be profile because the policy number does not exist!');
          this.progressBar.complete();
          this.claimFormGroup.reset();
        }
      });
  }

  getSubRisks() {
    this.paymentService.fetchAllsubRisk().subscribe((response) => {
      if (response != null || response != undefined) {
        this.subRisks = response;
        console.log(response);
      }
    });
  }
}
