import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tips-to-improve-quality-of-life',
  templateUrl: './tips-to-improve-quality-of-life.component.html',
  styleUrls: ['./tips-to-improve-quality-of-life.component.css']
})
export class TipsToImproveQualityOfLifeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
