<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">

          </li>
       </ul>
    </div>
  </section>
  <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
    <div class="row">
       <div class="col-lg-12 g-mb-30">
          <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
             <h3 class="mb-0">
                <strong>
                   <p style="text-align:center;">HOW TO VALIDATE YOUR AUTO INSURANCE CERTIFICATE</p>
                </strong>
             </h3>
             <br/>
             <div class="row justify-content-between">
                <!-- About Image -->
                <div class="col-md-3 col-lg-4">
                   <img class="img-fluid" src="assets/img/validate-insurance.jpg" alt="Image Description">
                </div>
                <!-- End About Image -->
                <div class="col-md-8 col-lg-8">

                   <p>I was on my way to my sister’s house for tête-à-tête on a Saturday morning. As I made a U-turn at the first Lekki roundabout, a saloon car hit my bumper from behind and broke it. I was furious and I remember asking myself this question which was not answered “can we drive in this country without another vehicle or motorcycle carelessly bashing your car?” I requested we park off the road to discuss the cost implication of the accident.
                   </p>
                   <p></p>
                   <p>After parking off the road, the man apologised, asserting that he was not concentrating. I asked for his insurance certificate. “Why?” he asked, I explained that I wanted to know if he had a valid insurance certificate so that my car could be repaired. No doubt, my question freaked out this gentleman and he reacted saying “Madam, don’t worry about my insurance, my auto engineer is around this area, I will call him and he will fix your car”.
                   </p>
                   <p></p>
                </div>
             </div>
             <div class="row justify-content-between">
                <p>Amidst my shock, it occurred to me that the man may have had two major concerns:
                </p>
                <p></p>
                <ul>
                   <li>1.	He wasn’t sure whether or not I was a security operative.</li>
                   <li>2.	He wasn’t sure of the validity of his insurance certificate.</li>

                </ul>
                <p></p>

                <p>I stated my profession and asked for his insurance certificate again, which he then willingly gave to me. Using the NIID (Nigerian Insurance Industry Database) platform, I quickly checked his registration details and believe me, he had a valid insurance certificate. So, what was all the fuss about? If he had known that he had a valid insurance certificate, he wouldn’t have bothered calling his auto mechanic or incurred the expenses of fixing the car on himself.”</p>
                <p></p>
                <p>In here is a lesson for every driver. It is not just about having an insurance certificate for your vehicle; you need to validate the certificate to ensure that you are fully covered against any loss or damages as you journey to your various destinations.</p>

             <p></p>
             <p>When you get your insurance certificate from the insurer or any vehicle registration centres, visit <a href="http://www.askniid.org/CheckPolicy.aspx">http://www.askniid.org/CheckPolicy.aspx</a> to check the validity of your certificate. </p>

             <p></p>
             <p>However, it is important to note that sometimes, the registration details are not uploaded immediately. So, don’t expect an instant upload. But the good news is that all insurers are instructed to upload the details of all originally insured vehicles within the earliest possible time.</p>
             <p></p>
             <p>For more on motor vehicle insurance, please visit <a href="https://nsiainsurance.com/motorinsurance">https://nsiainsurance.com/motorinsurance</a></p>
             <p></p>
             <p style="font-style: italic;margin-top: 50px;"><strong>Feature post by Funmi Ogunbiyi.</strong></p>
             </div>
          </div>
          <!-- End About Info -->
       </div>
    </div>
  </div>