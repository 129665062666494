
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;">INSURANCE AND THE EDUCATION SECTOR</p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/insurance_education.jpg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>Growing up as a child I was privileged to be exposed to teachers who used popular quotes while teaching. They would say things like: “Education is the passport to the future, for tomorrow belongs to those who prepare for it today.” “If you think education is expensive, try ignorance.”
                 </p>
                 <p></p>
                 <p>Believe it or not, those quotes spurred students to engage in healthy competitions to be the best among their peers. Schools competed to have the best of students with the highest grades in the West African Senior School Certificate Examinations. There were several quiz competitions to showcase the intelligence of students. All these are instrumental in the quest to ensure that students are poised to identify and utilize opportunities, to yield the best outcomes.

                 </p>
                 <p></p>


              </div>
           </div>


          <div class="row justify-content-between">


              <div class="col-md-12">
               <p>However, as time passed, there was a gradual decline in the Nigerian Education system and this is partly due to technological advancements. While our young ones embraced change readily, this has not been the case with the older generation. Many government parastatals and educational organizations were lackadaisical in their effort to improve their mode of operation in line with the advancements in technology as a means to educate students. Most government-owned schools in Nigeria used black chalkboards at a time some African countries had switched to white boards, computers and projectors for learning in secondary/high school classrooms.
               </p>
               <p></p>
                <p>Relating it to our present situation in light of the pandemic, private educational institutions have made provisions for their students to take online classes, write exams and even have their graduation ceremonies online. They have made visible effort to adapt to the changes we see around us today, all in a bid to ensure that the students get quality education. Sadly, the same cannot be said about their counterparts in government-owned institutions.</p>
                <p></p>

                 <p>One important question we must ask is: <strong>What options do average parents have to ensure their children get the education they deserve?  </strong>
                 </p>
                 <p></p>

                 <p>Once again, we can liken Insurance to a superhero in comic books who steps in to save the day.  “How?” you may ask.  Life Assurance offers benefits that will assist parents in meeting the financial requirements for their children's school fees and associated educational expenses. For instance, the <strong>NSIA Education Endowment Product</strong> (NSIA Education Support) serves as a vehicle for saving towards the future education of the children. The policy also supports the child in the event of the early death of the parent. </p>

                <p></p>

<p>The NSIA Anticipated Endowment Plan is also another viable option. It is a unique product that provides a combination of life insurance protection and savings for a rainy day. The policyholder withdraws a certain percentage of the guaranteed benefit (sum assured) during the currency of the policy and still collects 100% of the original sum assured at the end of the policy term. There also is the NSIA Savings Plan, which offers prospective policyholders a vehicle through which they can accumulate funds to meet future needs. </p>

<p></p>

<p>You can read more about these products here <a href="https://nsiainsurance.com/lifeinsurance">(https://nsiainsurance.com/lifeinsurance).</a></p>


                <p>Feature story by Ijioma IGIRI.</p>

              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>
