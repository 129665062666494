export class WorkmenInsuranceProposalForm {
  constructor(
    public NameOfProposal:string,
    public StaffModel:Staff[],
    public propertyAddress:string,
    public Phonenumber: string,
    public EmailAddress: string,
    public agent:string,
    public AddressOfProposal:string,
    public TradeOrBusiness:string,
    public Telephone:string,
    public SecondTelephone:string,
    public StartDate: Date,
    public EndDate: Date,
    public limitliability:string,
    public Scheduleofemployees:string,
    public descriptionofactivities:string,
    public othersoccupation:string,
    public employeeengaged:string,
    public areallmachinesequipped : string,
    public allemployeesgetconversant: string,
    public similarpolicy: string,
    public claimeduponinsurer: string,
    public incidence: string,
    public beneficiary_event_claim: string
  ) {

  }
}


export class Staff {
  constructor(
    public estimatednumberofemployees:string,
    public categoriesofemployees:string,
    public estimatedannualearning:string
  ) {

  }
}
