import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Manage } from 'src/app/manage/manage';
import { ManageService } from 'src/app/manage/manage.service';
import { ToastrService } from 'ngx-toastr';
declare var window: any;

@Component({
  selector: 'app-accessmanagement',
  templateUrl: './accessmanagement.component.html',
  styleUrls: ['./accessmanagement.component.css']
})
export class AccessmanagementComponent implements OnInit {
  p: number = 1;
  Manage: Manage[];
  hideWhenNoStudent: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;

  constructor(public toastr: ToastrService, public manageService: ManageService) {

  }
  ngOnInit(): void {
    this.dataState();
    let s = this.manageService.GetStudentsList();
    s.snapshotChanges().subscribe(data => {
      this.Manage = [];
      data.forEach(item => {
        let a = item.payload.toJSON();
        a['$key'] = item.key;
        this.Manage.push(a as Manage);
      })
    })
  }

  dataState() {
    this.manageService.GetStudentsList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if (data.length <= 0) {
        this.hideWhenNoStudent = false;
        this.noData = true;
      } else {
        this.hideWhenNoStudent = true;
        this.noData = false;
      }
    })
  }



  deletee(manage) {
    this.manageService.DeleteStudent(manage.$key)
  }

}

