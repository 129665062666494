export class GoodsInTransitProposalForm {
  constructor(

    public NameOfProposal:string,
    public AddressOfProposal:string,
    public TradeOrBusiness:string,
    public EmailAddress:string,
    public Telephone:string,
    public StartDate: Date,
    public EndDate: Date,
    public NatureOfGoods:string,
    public scheduleanyvehicles:string,
    public RegistrationNumber:string,
    public estimated_aggregate_value_of_goods:string,
    public maximum_value_of_any_one:string,
    public Conditions_of_Carriage : string,
    public Single_Transit_Cover: string,
    public attendant_remain: string,
    public licenses_suspended: string,
    public carry_fire_extinguishers:string,
    public renewal_ever_been_declined:string,
    public increased_rate:string,
    public loss_damage_to_goods: string,
    public estimated_annual_carrying: string,
    public limit_anyone_carrying:string,
    public AltTelephone:string,
    public carriageowmedgoods:string

  ) {

  }
}
