<section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px">
  <div class="container">
    <div class="d-sm-flex text-center">
      <div class="align-self-center">
        <h2 class="h3 g-font-weight-300 w-100 g-mb-10 g-mb-0--md">
          <p>Mortgage Protection Assurance (NSIA Family Shield)</p>
        </h2>
      </div>


    </div>
  </div>
</section>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/lifeinsurance">Life Insurance</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item g-color-primary">
        <span>Mortgage Protection Assurance (NSIA Family Shield)</span>
      </li>

      <li class="list-inline-item ml-auto">
        <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
          routerLink="/getquote">Get quote</a>
      </li>
    </ul>
  </div>
</section>
<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
    <div class="col-lg-9 g-mb-30">
      <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
        <h4><strong>
            <p>Mortgage Protection Assurance (NSIA Family Shield)</p>
          </strong> </h4>

        <p>Everyone wishes to have a home. While a few manage to save towards a new home, majority obtain mortgage loans
          to meet their needs. When a breadwinner obtains a mortgage loan he brings home a bounty of happiness. However,
          if he dies while the mortgage loan is still outstanding, the family faces the problem of repayment and this
          could lead to the repossession or sale of the house at a critical point when they indeed need such a home.</p>
        <p></p>

        <p>NSIA Family Shield is a mortgage protection product that shields away the dependants from the hassles of
          mortgage debts. It keeps the home in the family.</p>

        <p></p>

        <p><strong>Key Features</strong></p>
        <p></p>
        <ul>
          <li>
            <p>Payment of outstanding mortgage loan in the event of death or total permanent disability within the
              duration period of the loan</p>
          </li>
          <li>
            <p>Covers outstanding mortgage loans</p>
          </li>
          <li>
            <p>Policy duration is equal to the term of the loan taken.</p>
          </li>
          <li>
            <p>Maximum age at entry is 65 years</p>
          </li>
          <li>
            <p>Guaranteed benefit (sum assured) is the original loan taken from the mortgage institution </p>
          </li>
          <li>
            <p>Medical examinations are not required where the sum assured is below a certain amount</p>
          </li>
          <li>
            <p>Policyholder pays a single premium (purchase price) or annual premiums to NSIA Life Assurance Limited to
              secure the guaranteed benefit (sum assured)</p>
          </li>

        </ul>

        <p></p>
        <p><strong>Benefits</strong></p>
        <p></p>
        <p><b> On Death or Disability</b></p>

        <p></p>
        <p>In the event of death or total permanent disability of the policyholder (life assured) within the policy
          term, NSIA Life pays the outstanding loan to the lender (mortgage institution). With this, the policyholder’s
          dependants will be able to retain the house as the lender would be restricted from taking possession or
          selling the house.</p>

        <p></p>
        <p><b> On Maturity/Discontinuation</b></p>

        <p></p>

        <p>On discontinuation or survival of the policyholder till the end of the loan duration, no payment is made.</p>
        <p></p>
        <p><strong>Suitability</strong> </p>

        <p></p>

        <p>NSIA Family Shield is suitable for aspiring house owners who want to protect their family against the
          consequences of huge debts in the event of death. It is also suitable for mortgage institutions, banks and
          other lenders who want to protect themselves against the financial risk of death before a mortgage loan is
          fully repaid.</p>
        <p></p>
        <p><strong>Loss of Job Cover</strong> </p>

        <p></p>

        <p>NSIA Life also provides a Loss of Job Cover as a rider (supplementary) to the main Mortgage Protection Plan.
          In the event of retrenchment or disability of the life assured which prevents him from meeting his mortgage
          loan repayment obligations, NSIA Life takes over the responsibility of such repayments for a maximum period of
          six (6) months.</p>
          <p><strong>Waiting Period</strong> </p>

          <p></p>

          <p>
            Varies based on the loan tenor</p>

          <p></p>
      </div>
    </div>


    <!-- Sidebar -->
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <app-sidebar></app-sidebar>
      <!-- End Fast Facts -->
    </div>
    <!-- End Sidebar -->
  </div>
</div>
