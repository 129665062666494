import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-understanding-third-party-insurance',
  templateUrl: './understanding-third-party-insurance.component.html',
  styleUrls: ['./understanding-third-party-insurance.component.css']
})
export class UnderstandingThirdPartyInsuranceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
