export class PublicLiabilityProposalForm {
  constructor(

    public NameOfProposal:string,
    public AddressOfProposal:string,
    public TradeOrBusiness:string,
    public EmailAddress:string,
    public Telephone:string,
    public SecondTelephone:string,
    public StartDate: Date,
    public EndDate: Date,
    public NatureOfContract:string,
    public TenantOrOwner:string,
    public TenantExtent:string,
    public occupier : string,
    public WorkAtPremises: string,
    public Additional:string[],
    public EstimatedAnnualValue: string,
    public LimitLiaibility: string,
    public PreviousLosses: string,
    public ProposalDecline: string

  ) {

  }
}
