
<div class="container g-pt-100 g-pb-70">
  <div class="row">
    <div class="col-lg-12 g-mb-30">
      <section class="g-bg-gray-light-v5 g-py-20">
        <form novalidate (ngSubmit)="submitForm(beneficiaryForm)" #beneficiaryForm="ngForm" novalidate>
          <div class="container g-py-100 pad-top-50" style="margin-top:-50px">
            <div class="u-shadow-v19 g-brd-around g-brd-gray-light-v4  rounded mx-auto g-pa-30 g-pa-50--md">

              <div class="mb-6">
                <h2 class="mb-4 main-heading">
                  Marine Cargo Proposal Form
                 </h2>
               <p style="text-align: center;">An Insurance Agent who assists an applicant to complete an application or proposal form for insurance shall be deemed to have done so as the agent of the applicant” section 54(2), Insurance Act 2003</p>


                <section class="step-two" >
                  <div class="form-sub-title">
                    <h4>General Information</h4>
                  </div>
                  <div class="row">

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label >Title</label>
                        <select name="title" id="title" class="form-control form-control-md rounded-0" type="text"
                        placeholder="Required" [(ngModel)]="proposalModel.title" >
                            <option >Select title</option>
                            <option value="Mr">Mr</option>
                            <option value="Mrs">Mrs</option>
                            <option value="Dr">Dr</option>
                            <option value="Prof">Prof</option>
                            <option value="Chief">Chief</option>
                            <option value="Engineer">Engineer</option>
                      </select>
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label >Surname</label>
                        <input id="Surname" name="Surname" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.Surname" required>
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          OtherNames
                        </label>
                        <input id="OtherNames" name="OtherNames" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.OtherNames"  >
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Maiden Name
                        </label>
                        <input id="MaidenName" name="MaidenName" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.MaidenName" >
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Company Name
                        </label>
                        <input id="CompanyName" name="CompanyName" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.CompanyName" >
                      </div>
                    </div>


                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Residential address
                        </label>
                        <input id="ResidentialAddress" name="ResidentialAddress" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.ResidentialAddress" required >
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          E- mail address
                        </label>
                        <input id="EmailAddress" name="EmailAddress" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.EmailAddress" placeholder="johndoe@gmail.com" required type="text" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" >

                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Mobile No
                        </label>
                        <input id="Telephone" name="Telephone" class="form-control form-control-md rounded-0" type="number"
                        placeholder="Required" [(ngModel)]="proposalModel.Telephone" required="required" placeholder="07035265975" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==11) return false; return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57));" >
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Occupation
                        </label>
                        <input id="Occupation" name="Occupation" class="form-control form-control-md rounded-0" type="number"
                        placeholder="Required" [(ngModel)]="proposalModel.Occupation">
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Date Of Birth / Date Of Incorporation
                        </label>
                        <input id="DateOfBirth" name="DateOfBirth" class="form-control form-control-md rounded-0" type="text"
                          [(ngModel)]="proposalModel.DateOfBirth" >
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                         ID Registration Number
                        </label>
                        <input id="IdRegistrationNumber" name="IdRegistrationNumber" class="form-control form-control-md rounded-0" type="text"
                          [(ngModel)]="proposalModel.IdRegistrationNumber" >
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label >Identification Type</label>
                        <select name="Identification_type" id="Identification_type" class="form-control form-control-md rounded-0" type="text"
                        placeholder="Required" [(ngModel)]="proposalModel.Identification_type">
                            <option >Select </option>
                            <option value="Drivers Licenses">Drivers Licenses</option>
                            <option value="International Passport">International Passport</option>
                            <option value="National Id">National Id</option>
                            <option value="Permanent Voters card">Permanent Voters card</option>
                            <option value="Certificate of Incorporation">Certificate of Incorporation</option>
                          </select>
                      </div>
                    </div>

                  </div>
                </section>


                <section class="step-three">
                  <div class="form-sub-title">
                    <h4>Marine Cargo Details</h4>
                  </div>
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                         Type Of Cover
                        </label>
                        <select name="covertype" id="covertype" class="form-control form-control-md rounded-0" type="text"
                        placeholder="Required" [(ngModel)]="proposalModel.covertype">
                            <option >Select </option>
                            <option value="Single Transit">Single Transit</option>
                            <option value="Open Cover">Open Cover</option>
                          </select>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                        Proforma Invoice No
                        </label>
                        <input id="ProformaInvoiceNo" name="proposalModel.ProformaInvoiceNo" class="form-control form-control-md rounded-0" type="text"
                          [(ngModel)]="proposalModel.ProformaInvoiceNo"  >
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Proforma Invoice Date
                        </label>
                        <input id="ProformaInvoiceDate" name="proposalModel.ProformaInvoiceDate" class="form-control form-control-md rounded-0" type="date"
                         [(ngModel)]="proposalModel.ProformaInvoiceDate"   >
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                         Tin No
                        </label>

                        <input id="Tin_No" name="proposalModel.Tin_No" class="form-control form-control-md rounded-0"
                        [(ngModel)]="proposalModel.Tin_No"  type="text" >

                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Conveyance
                        </label>
                        <select name="proposalModel.Coneyance" id="Coneyance" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.Coneyance">
                            <option >Select your option</option>
                            <option value="Sea">Sea</option>
                            <option value="Air">Air</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                       Description Of Goods
                        </label>
                        <input id="DescriptionOfGoods" name="proposalModel.DescriptionOfGoods" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.DescriptionOfGoods">
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                          Type Of Goods
                        </label>
                        <select name="proposalModel.typeofGoods" id="typeofGoods" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.typeofGoods">
                            <option >Select your option</option>
                            <option value="Machinery">Machinery</option>
                            <option value="General Merchandise">General Merchandise</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                         Proforma Value
                        </label>
                        <input id="ProformaValue" name="proposalModel.ProformaValue" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.ProformaValue" >
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                         Currency
                        </label>
                        <input id="Currency" name="proposalModel.Currency" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.Currency" >
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                         Lien
                        </label>
                        <input id="Lien" name="proposalModel.Lien" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.Lien" >
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-group g-mb-20">
                        <label>
                         Cover Required
                        </label>
                        <select name="proposalModel.Cover" id="Cover" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.Cover">
                            <option >Select your option</option>
                            <option value="Institute Cargo Clause 'A'">Institute Cargo Clause 'A'</option>
                            <option value="Institute Cargo Clause 'A'">Institute Cargo Clause 'C'</option>
                            <option value="Institute Bulk Oil">Institute Bulk Oil</option>
                            <option value="Institute Frozen Foods">Institute Frozen Foods</option>
                        </select>
                      </div>
                    </div>


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Upload Proforma Invoice
                        </label>
                        <br/>
                        <input type="file" (change)="selected2($event.target.files)" required />
                      </div>
                    </div>
                  </div>
                </section>

               <section class="step-seven" >
                <div class="form-sub-title">
                  <h4>Terms</h4>
                </div>
                 <div class="container">
                   <h4>Declaration</h4>
                   <p>I, the undersigned, do hereby declare that the above statements are true and complete.</p>
                   <p>I hereby give my consent to the Company, to seek information, if necessary, from any doctor who has ever attended to me from any Life Assurance Office, to which a Proposal on my Life, at any given time, has been made, and the giving of such information is hereby authorized.</p>
                   <p>I further agree that this Proposal, Declaration and the statements made above or to the Medical Examiner acting for the Company (where required) shall be the basis of the proposed life insurance contract; that if anything contrary to the truth be stated or if any information which ought to be made known to the Company with reference to the Proposed Assurance be withheld or concealed, any policy which may be granted in pursuance of this contract shall be null and void.</p>
                   <p>Note:</p>
                   <ul>
                     <li>The Company is bound only by documents bearing the signature of an official of the Company authorized to Sign on its behalf.</li>
                     <li>The Company is not on risk until acceptance of the Proposal has been communicated in writing and the First premium has been received at the Company’ Head Office.</li>
                     <li>Premium should be made through cheques or direct credit into NSIA Insurance Ltd 's account as <b>NSIA would not be liable for premium paid in cash.</b> </li>
                   </ul>
                   <div class="form-group">
                    <input type="checkbox" [(ngModel)]="termAgreed" name="termAgreed" > By checking the box , I agree with the above terms
                  </div>
                  <!-- <div class="col-lg-4">
                    <div class="row"> -->



                      <p>I hereby undertake to ensure that the account is always funded to cover this transaction.</p>


                      <p style="font-size:10px; text-align: justify;">In compliance with NDPR requirements, by completing and submitting this form, you have given consent to NSIA Insurance to receive your data, including your bio data, to enable NSIA Insurance maintain effective communication, send promotional updates, contact you for research purposes and use the data in line with the policy terms.
                      </p>
                      <p style="font-size:10px; text-align: justify;">The data collection may be via hard copies or online forms, validated via JSON WEB TOKEN (JWT). The data will not be accessed by any third party without your consent and any breach of this policy can be addressed legally within the year the breach was reported.</p>

                      <div class="col-lg-4">
                        <div class="form-group g-mb-0">
                          <label style="visibility: hidden;">Frequency</label>
                          <button  type="submit" onclick="return confirm('Are you sure you want to submit this information?')" class="g-brd-main btn-block g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" > Submit
                          </button>
                        </div>

                      </div>




                 </div>

               </section>

              </div>


            </div>
          </div>
        </form>
      </section>
    </div>

    <!-- End Sidebar -->
  </div>
</div>
