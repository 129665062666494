import { Component, OnInit } from '@angular/core';
import { FormserviceService } from 'src/app/Services/formservice.service';

@Component({
  selector: 'app-personal-accident',
  templateUrl: './personal-accident.component.html',
  styleUrls: ['./personal-accident.component.css']
})
export class PersonalAccidentComponent implements OnInit {

  constructor(private _formservice: FormserviceService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

}
