<section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px">
  <div class="container" >
    <div class="d-sm-flex text-center">
      <div class="align-self-center">
        <h2 class="h3 g-font-weight-300 w-100 g-mb-10 g-mb-0--md" ><p>NSIA Family Protection Plan</p></h2>
      </div>
    </div>
  </div>
</section>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item">
              <a class="u-link-v5 g-color-text g-pr-10" routerLink="/lifeinsurance">Life Insurance</a>
              <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
            </li>
            <li class="list-inline-item g-color-primary">
                  <span>NSIA Family Protection Plan </span>
                </li>

      <li class="list-inline-item ml-auto">
        <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" routerLink="/getquote">Get quote</a>
      </li>
    </ul>
  </div>
</section>
<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
      <div class="row">
        <div class="col-lg-9 g-mb-30">
              <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
                      <h4><strong><p>NSIA Family Protection Plan</p></strong> </h4>

            <p>
              NSIA Family Protection Plan is a Funeral Plan designed to address any financial worries in the event that an individual or their family members pass on. It provides a death benefit to the policy holder to defray the cost of funeral and associated expenses in the event of death of any of the family members covered on the policy. The following family members can be covered on the policy - spouse, parents and parents-in-laws.
            </p>
            <p>
              <strong>Product Features </strong><br/>
        <ul>
          <li>
            It is a life product that product cover the policyholder and can also cover the following policyholder’s family members:
             <ul>
               <li>o	Spouse(s) (multiple spouses allowed) </li>
               <li>o	Parents (up to age 70)</li>
               <li>o	Parents in law (up to age 70) </li>
             </ul>
          </li>

          <li>The sum assured for the policyholder is between <strong>N1M – N5M.</strong></li>
          <li>
            The spouse’s sum assured is maximum of 100% of policyholder’s sum assured
          </li>
          <li>The other lives’ sum assured is maximum of 50% of the policyholder’s sum assured (subject to a maximum of N2M).</li>
          <li>The age at entry is 18-60 years. However, the maximum age at entry for parents and parents in law is 70. </li>
          <li>Frequency of premium payments are monthly, quarterly, bi-annually and annually</li>
          <li>The policy holder pays his premium continuously for five years and stops payment while the policy is in force for life. </li>
          <li>•	Where a premium is missed, the cover will remain effective for a grace period of 30 days, however, after these days, the cover will cease to be effective but reinstatement is possible within 6 months on the same terms</li>
        </ul>

       <p><strong>Benefit Payable </strong></p>
       <p></p>
       <p><strong>Death Benefit </strong></p>
       <ul>
              <li><p>The sum assured becomes payable in the event of death of the covered lives</p></li>
              <li><p>Where death benefit is paid on a life other than the main policyholder, the policy continues but the premium for that life will no longer be paid. </p></li>
              <li><p>Where death benefit is paid on the death of the main policyholder, the policy ceases.  </p></li>

           </ul>


           <p></p>
           <p><strong>Waiting Periods </strong></p>
           <p></p>
            <p>There is a waiting period of 6 months. The policy will not pay any benefit to the policyholder or policyholder’s beneficiary in the event of death during the waiting period. However, in case of death due to an accident, the benefit will be paid. </p>

            <p></p>
            <p><strong>Lapsation </strong></p>
            <p></p>

            <p>Where the premium is not paid within the grace period of 30 days, the policy will be in a reinstatement period for another 6 months during which it can be reinstated by paying the premiums.  However, if the premium is still not paid during the six month period, the policy lapses. Where the policy lapses, it will not be possible to accept premiums into this policy and the policyholder will have to take out a new policy if cover is required. The policy will also lapsed if it has entered the reinstatement period for more than three times</p>
            <p><strong>Riders</strong></p>
            <p></p>
            <p><strong>Family Support Benefit </strong></p>
            <p></p>
            <p>
              This is a 3-month benefit of N150, 000 in each month payable to the beneficiaries upon the death of the policyholder where this option is chosen in addition to the basic cover
            </p>

            <p><strong>Accidental Death Benefit  </strong></p>
            <p></p>
            <p>
              In case of death due to an accident, 150% of the sum assured becomes payable. This benefit is payable where the policyholder chooses this plan as a rider to the basic cover.
            </p>

                      </div>
                      </div>


        <!-- Sidebar -->
        <div class="col-lg-3 g-mb-30">
          <!-- Links -->
          <app-sidebar></app-sidebar>
          <!-- End Fast Facts -->
        </div>
        <!-- End Sidebar -->
      </div>
    </div>






