<section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px">
  <div class="container" >
    <div class="d-sm-flex text-center">
      <div class="align-self-center">
        <h2 class="h3 g-font-weight-300 w-100 g-mb-10 g-mb-0--md" ><p>Anticipated Endowment Assurance</p></h2>
      </div>


    </div>
  </div>
</section>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item">
              <a class="u-link-v5 g-color-text g-pr-10" routerLink="/lifeinsurance">Life Insurance</a>
              <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
            </li>
            <li class="list-inline-item g-color-primary">
                  <span>Anticipated Endowment Assurance</span>
                </li>

      <li class="list-inline-item ml-auto">
        <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a>
      </li>
    </ul>
  </div>
</section>
<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
      <div class="row">
        <div class="col-lg-9 g-mb-30">
              <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
                      <h4><strong><p>Anticipated Endowment Assurance</p></strong> </h4>


                      <p>NSIA Anticipated Endowment Plan is a unique product that provides a combination of life insurance protection and savings
                      in a flexible manner. The policyholder withdraws certain percentage of the guaranteed benefit (sum assured) during the
                      currency of the policy and still collects 100% of the original sum assured at the end of the policy term.</p>
                      <p></p>


                      <p><strong>Key Features</strong></p>
                      <p></p>
                      <ul>
                          <li><p>The original guaranteed sum assured is specified in advance by the policyholder</p></li>
                          <li><p>Minimum sum assured is Five Hundred Thousand Naira (N500,000)</p></li>
                          <li><p>Payment of the full sum assured and bonus at the maturity date of the policy despite the period payments earlier made</p></li>
                          <!-- <li><p>The maximum age at entry is 64 years and the maximum age at maturity is 70 years.</p></li> -->
                          <li><p>Policy terms are 6, 9, 12 and 15 years</p></li>
                          <li><p>A simple reversionary bonus (profit) which begins to accrue from the beginning of the second year is added to the sum
                          assured, this is currently guaranteed at 2% of sum assured per annum.</p></li>
                          <li><p>Policy can be used as collateral for loan</p></li>
                          <li><p>Maximum age at inception is 64 while the maximum maturity age is 70 years.</p></li>
                          <li><p>Policyholder pays single, annually, half-yearly, quarterly or monthly to NSIA Insurance Limited to secure the guaranteed
                          benefit and bonuses</p></li>
                          <li><p>Medical examinations may be required in certain cases</p></li>
                          <li><p>The policy would naturally lapse if premiums are not paid within 60 days after due date.</p></li>
                      </ul>

                      <p></p>
                      <p><strong>Benefits</strong></p>
                      <p></p>
                      <p><b>During the period of cover</b></p>

                      <p></p>
                      <ul>
                          <li><p>Payment of 25% of the guaranteed sum assured at the end of one-third of the policy term, if the policyholder is alive</p></li>
                          <li><p>Payment of another 25% of the guaranteed sum assured at the end of two-third of the policy term, if the policyholder is
                          alive</p></li>
                          <li><p>Payment of 100% of the guaranteed sum assured together with accrued bonuses at the maturity of the policy</p></li>

                      </ul>

                      <p></p>
                      <p><b> On Death</b></p>

                      <p></p>

                      <p>NSIA Life pays 100% of the guaranteed sum assured and all accrued bonuses to the policyholder’s named beneficiary/ies.</p>
                      <p></p>

                      <p><b>On Maturity</b></p>

                      <p></p>

                      <p>If the policyholder lives till the end of the policy term, he receives the sum assured together with all accrued
                      bonuses.</p>
                      <p></p>

                      <p><b> On Discontinuation</b></p>

                      <p></p>

                      <p>If the policyholder discontinues the policy after two complete years’ of premium payment and two years of being in
                      force, NSIA Life will pay a reduced benefit known as surrender value.</p>
                      <p></p>

                      <p><b> Policy Loan</b></p>

                      <p></p>

                      <p>On acquiring a surrender value after two years, the policyholder can apply to NSIA for a loan of up to 90% of the
                      surrender value on the policy.</p>
                      <p></p>


                      <p><strong>Termination</strong> </p>

                      <p></p>

                      <p>No payment is made if the policy is terminated within two years of its inception.</p>
                      <p></p>
                      <p><strong>Waiting Period</strong> </p>

                      <p></p>

                      <p>There is a month waiting period during which a client cannot claim on non-accidental death. However, where accidental
                      death occurs during this period, total premiums paid would be returned</p>
                      <p></p>

                      <p><strong>Suitability</strong> </p>

                      <p>The Anticipated Endowment product is suitable for an individual who:</p>
                      <ul>
                          <li><p>wants a combination of life insurance cover and savings</p></li>
                          <li><p>wants to enjoy part of the guaranteed benefit before the agreed maturity date</p></li>
                          <li><p>who needs emergency fund</p></li>

                      </ul>

                      </div>
                      </div>


        <!-- Sidebar -->
        <div class="col-lg-3 g-mb-30">
          <!-- Links -->
          <app-sidebar></app-sidebar>
          <!-- End Fast Facts -->
        </div>
        <!-- End Sidebar -->
      </div>
    </div>




