export class NewMarineCargoProposalForm {
  constructor(
    public ProductType: number,
    public PremiumType:number,
    public Surname:string,
    public OtherNames:string,
    public Sex:string,
    public RegistrationNumber:string,
    public MaidenName:string,
    public MaritalStatus:string,
    public ResidentialAddress:string,
    public EmailAddress:string,
    public Telephone:string,
    public CompanyName:string,
    public Identification_type:string,
    public DateOfBirth:Date,
    public Age:number,
    public IdRegistrationNumber:string,
    public Occupation:string,
    public EmployerName:string,
    public EmployersAddress:string,
    public title:string,
    public filenameId:string,
    public filetypeId:string,
    public base64textStringId:string,
    public processedImagesId: any = [],
    public filestringId: string,
    public src:string,
    public covertype:string,
    public ProformaInvoiceNo:string,
    public ProformaInvoiceDate:string,
    public Tin_No:string,
    public Coneyance:string,
    public DescriptionOfGoods:string,
    public typeofGoods:string,
    public ProformaValue:string,
    public Currency:string,
    public Lien:string,
    public Cover:string,
    public filenameId2:string,
    public filetypeId2:string,
    public base64textStringId2:string,
    public processedImagesId2: any = [],
    public filestringId2: string,
    public src2:string,

  ) {

  }
}
