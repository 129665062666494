<div
  class="g-min-height-300 g-flex-centered g-bg-img-hero g-bg-pos-top-center g-bg-size-cover g-pt-80"
  style="background-image: url(assets/img/banners/insurance-cover.jpg)"
>
  <div class="container g-pos-rel g-z-index-1">
    <h2 class="h1 text-uppercase g-color-primary g-font-size-40--lg mb-0">
      <b>NSIA Travel Insurance</b>
    </h2>
    <span class="d-block g-color-white g-font-weight-1000 g-font-size-20 mb-4">
      We are here to support you.</span
    >
  </div>
</div>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item g-color-primary">
        <span>Travel Insurance</span>
      </li>

      <li class="list-inline-item ml-auto">
        <a
          class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
          routerLink="/getquote"
          >Get quote</a
        >
      </li>
    </ul>
  </div>
</section>

<div class="container g-pt-100 g-pb-70" style="margin-top: -50px">
  <div class="row">
    <div class="col-lg-9 g-mb-30">
      <div>
        <p>
          Traveling, whether for work, education or leisure comes with
          associated risks. NSIA Insurance provides International Travel
          Insurance to protect you from those risks, ensuring you have a
          stress-free trip
        </p>
        <p></p>
        <p>
          Medical emergencies such as sudden sickness, accidents and dental
          emergencies or trip cancellations may ruin a vacation. NSIA Travel
          Insurance provides cover for medical emergencies and unforeseen
          expenses that may arise during foreign trips.
        </p>
        <p></p>
        <p>
          NSIA Travel Insurance provides a comprehensive range of benefits at an
          affordable cost to ensure a stress-free trip.
        </p>
        <br />
        <p><strong>BENEFITS</strong></p>
        <ul>
          <li>
            Medical Expenses for Sickness and Injuries, whether hospitalized or
            as out-patient
          </li>
          <li>Treatment for Dental Emergencies resulting from an accident</li>
          <li>
            Emergency medical Evacuation to your home country or another
            location for treatment
          </li>
          <li>
            Convalescence expenses such as additional cost of accommodation to
            enable you recover after hospitalization
          </li>
          <li>
            Should you be injured in a terrorist attack, we provide for medical
            treatment for injuries;
          </li>
        </ul>

        <p><strong>We also provide a range of non-medical benefits</strong></p>
        <ul>
          <li>Compensation for Loss of baggage, trip cancellations</li>
          <li>
            Where catastrophic events such as floods, hurricane or fire makes
            your accommodation un-inhabitable, we provide the cost of
            alternative accommodation
          </li>
          <li>Replacement of Lost of passport or travel documents</li>
          <li>
            Payment of the fees of a lawyer or translator where the insured
            requires legal assistance
          </li>
        </ul>

        <p>
          NSIA TRAVEL Insurance offers three plans to suit your need depending
          on your travel destination.
        </p>
        <p>
          Schengen Travel Plan <br />

          Worldwide Excluding USA, Canada, Australia and Japan Travel Plan
          <br />

          Worldwide Travel Plan <br />

          See below the full range of benefits and limits offered in each
          category.
        </p>
      </div>
      <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
        <!-- <h3 class="mb-0"><strong><p>Home Insurance</p></strong></h3><br/> -->

        <div style="padding: 10px 0px 0.05px 10px; background-color: #efb225">
          <strong>
            <h5 style="color: white">
              SCOPE OF COVER FOR THE NSIA TRAVEL INSURANCE POLICY
            </h5>
          </strong>
        </div>
        <p></p>
        <div class="form-row text-center col-md-12">
          <table class="container table table-striped mt-3">
            <thead>
              <tr>
                <th scope="col">Cover 1 – (Sections 1 – 7)</th>
                <th scope="col">MEDICAL SERVICES</th>
                <th scope="col">World Wide</th>
                <th scope="col">
                  Worldwide Excluding USA, Canada, Australia & Japan
                </th>
                <th scope="col">Schengen</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Section 1</td>
                <td>
                  <u>Medical Expenses for Sickness and Injuries</u> <br />
                  Medical expenses from injury or sickness suffered while
                  overseas
                </td>
                <td>$100,000</td>
                <td>$80,000</td>
                <td>$45,000</td>
              </tr>
              <tr>
                <td>Section 2</td>
                <td>
                  <u>Emergency Medical Evacuation</u> <br />
                  Movement from one location to another for medical care
                </td>
                <td>Real cost</td>
                <td>Real cost</td>
                <td>Real cost</td>
              </tr>
              <tr>
                <td>Section 3</td>
                <td>
                  <u>Emergency Medical Repatriation </u> <br />
                  Return to country of residence, if you become sick or injured
                </td>
                <td>Real cost</td>
                <td>Real cost</td>
                <td>Real cost</td>
              </tr>
              <tr>
                <td>Section 4</td>
                <td>
                  <u>Repatriation of Mortal Remains to Home Country </u> <br />
                  Transfer of remains of the one with the cover to usual country
                  of residence
                </td>
                <td>Real cost</td>
                <td>Real cost</td>
                <td>Real cost</td>
              </tr>
              <tr>
                <td>Section 5</td>
                <td>
                  <u>Compassionate Visit </u> <br />
                  Carters for relative or friend during final arrangements for
                  repatriation in event of death
                </td>
                <td>$10,000</td>
                <td>$10,000</td>
                <td>$10,000</td>
              </tr>
              <tr>
                <td>Section 6</td>
                <td>
                  <u>Convalescence Expense </u> <br />
                  Additional accommodation due to hospitalisation & emergency
                  evacuation
                </td>
                <td>$1,000</td>
                <td>$1,000</td>
                <td>$1,000</td>
              </tr>
              <tr>
                <td>Section 7</td>
                <td>Dental Emergency Treatment Due to Accident</td>
                <td>$500</td>
                <td>$400</td>
                <td>$300</td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <u>Personal Accident Cover Paid </u> <br />
                  Benefits to legal beneficiaries for accidental death
                </td>
                <td>$50,000</td>
                <td>$30,000</td>
                <td>$25,000</td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <u>Personal Liability</u> <br />
                  Enables you pay compensation to another person for injury,
                  death or loss of/damage to property
                </td>
                <td>$25,000</td>
                <td>$15,000</td>
                <td>N/A</td>
              </tr>
            </tbody>
          </table>
        </div>

        <p></p>
        <div class="form-row text-center col-md-12">
          <table class="container table table-striped mt-3">
            <thead>
              <tr>
                <th scope="col">Cover 2 – (Sections 1 – 7)</th>
                <th scope="col">TRAVEL ASSISTANCE</th>
                <th scope="col">World Wide</th>
                <th scope="col">
                  Worldwide Excluding USA, Canada, Australia & Japan
                </th>
                <th scope="col">Schengen</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Section 1</td>
                <td>
                  <strong>Terrorism Cover</strong><br />
                  Pays for medical and evacuation expenses following an act of
                  terrorism*
                </td>
                <td>$20,000</td>
                <td>$15,000</td>
                <td>$10,000</td>
              </tr>
              <tr>
                <td>Section 2</td>
                <td>
                  <strong>Catastrophe Cover</strong> <br />
                  Pays for travel expenses and accommodation in the event of
                  catastrophe*
                </td>
                <td>$1,000</td>
                <td>$750</td>
                <td>$500</td>
              </tr>
              <tr>
                <td>Section 3</td>
                <td>
                  <strong>Sea & Mountain Search and Rescue </strong> <br />
                  Pays for search and rescue cost
                </td>
                <td>$20,000</td>
                <td>$15,000</td>
                <td>$10,000</td>
              </tr>
              <tr>
                <td>Section 4</td>
                <td>Loss of Passport & Travel Documents</td>
                <td>$400</td>
                <td>$300</td>
                <td>$200</td>
              </tr>
              <tr>
                <td>Section 5</td>
                <td>
                  <u>Loss of Luggage</u> <br />
                  Pays the difference of what the airline would pay within the
                  limit due to the customer
                </td>
                <td>$1,000</td>
                <td>$800</td>
                <td>$600</td>
              </tr>
              <tr>
                <td>Section 6</td>
                <td>
                  <strong>Trip Cancelation (Per flight)</strong> <br />
                  Reimburses you for tickets and accommodation when flights are
                  cancelled, if you buy the ticket alongside your travel
                  Insurance*
                </td>
                <td>$250</td>
                <td>$200</td>
                <td>$200</td>
              </tr>
              <tr>
                <td>Section 7</td>
                <td>
                  <strong>Legal Fees</strong> <br />
                  Pays for any legal issues requiring a legal defense or
                  translator
                </td>
                <td>$3,000</td>
                <td>$2,000</td>
                <td>$1,000</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p></p>
        <div class="form-row text-center col-md-12">
          <table class="container table table-striped mt-3">
            <thead>
              <tr>
                <th scope="col">Cover 3</th>
                <th scope="col">ADDITIONAL FREE MEDICAL ASSISTANCE</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>
                  <strong><u>24 HOURS Assistance service</u></strong
                  ><br />
                  We are available round the clock to assist with flight and
                  hotel accommodation, in the event of an emergency
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>
                  <strong><u>Delivery of Medicines</u></strong> <br />
                  If prescribed medicines cannot be found at your location of
                  travel, we will work with our partners to deliver the
                  medicines to you
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>
                  <strong><u>Relay of Urgent Messages</u></strong> <br />
                  We will assist in contacting family or friends in event of
                  emergency
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>
                  <strong
                    ><u>Long Distance Medical Information Services </u></strong
                  >
                  <br />

                  We work with our partners to ensure a specialised medical Team
                  is at your disposal when required
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td>
                  <strong
                    ><u>Medical Referral to Local Medical Specialist</u></strong
                  >
                  <br />
                  We will make an appointment with a local medical specialist on
                  your behalf, if you require such services
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p></p>
        <p><strong>*Terms and conditions apply</strong></p>
      </div>
    </div>

    <!-- Sidebar -->
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <app-sidebar></app-sidebar>
      <!-- End Fast Facts -->
    </div>
    <!-- End Sidebar -->
  </div>
</div>
