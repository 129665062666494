import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-what-happens-if-a-guest-causes-damage-to-my-home',
  templateUrl: './what-happens-if-a-guest-causes-damage-to-my-home.component.html',
  styleUrls: ['./what-happens-if-a-guest-causes-damage-to-my-home.component.css']
})
export class WhatHappensIfAGuestCausesDamageToMyHomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
