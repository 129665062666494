<div class="g-min-height-300 g-flex-centered g-bg-img-hero g-bg-pos-top-center g-bg-size-cover g-pt-80"
  style="background-image: url(assets/img/banners/Inset_Life.jpg);">
  <div class="container g-pos-rel g-z-index-1">
    <h2 class="h1 text-uppercase g-color-primary g-font-size-40--lg mb-0">Proposal Form</h2>
    <span class="d-block  g-color-white g-font-weight-1000 g-font-size-20 mb-4">Experience peace of mind.</span>
  </div>
</div>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item g-color-primary">
        <span>Proposal Form</span>
      </li>

      <li class="list-inline-item ml-auto">
        <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
          routerLink="/lifequote"></a>
      </li>
    </ul>
  </div>
</section>


<div class="container g-pt-100 g-pb-70">
  <div class="row">
    <div class="col-lg-12 g-mb-30">
      <section class="g-bg-gray-light-v5 g-py-20">
        <div class="container">

          <h2 style="text-align: center;">GENERAL BUSINESS PROPOSAL FORM</h2>
          <br/><br/>
          <div class="row no-gutters">
             <div class="col-lg-4 cta-item g-px-40 g-mb-50 g-mb-0--lg">
                <!-- Icon Blocks -->
                <div class="text-center">
                   <span
                      class="d-inline-block u-icon-v3 u-icon-size--xl u-icon-effect-v3--hover g-bg-primary g-color-white rounded-circle g-mb-30">
                   <i class="fa fa-user"></i>
                   </span>
                   <h3 class="h5 g-color-gray-dark-v2 g-font-weight-400 text-uppercase mb-3">Public Liability Proposal Form</h3>

                   <br />
                   <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                      routerLink="/public-liability-proposal-form">
                   Fill Now
                   </a>
                </div>
                <!-- End Icon Blocks -->
             </div>

             <div class="col-lg-4 cta-item g-brd-left--lg g-brd-gray-light-v4 g-px-40 g-mb-50 g-mb-0--lg">
                <!-- Icon Blocks -->
                <div class="text-center">
                   <span
                      class="d-inline-block u-icon-v3 u-icon-size--xl u-icon-effect-v3--hover g-bg-primary g-color-white rounded-circle g-mb-30">
                   <i class="fa fa-users"></i>
                   </span>
                   <h3 class="h5 g-color-gray-dark-v2 g-font-weight-400 text-uppercase mb-3">All Risk Proposal Form</h3>

                   <br />
                   <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                      routerLink="/all-risk-proposal-form">
                      Fill Now
                   </a>
                </div>
                <!-- End Icon Blocks -->
             </div>

             <div class="col-lg-4 cta-item g-brd-left--lg g-brd-gray-light-v4 g-px-40 g-mb-50 g-mb-0--lg">
              <!-- Icon Blocks -->
              <div class="text-center">
                 <span
                    class="d-inline-block u-icon-v3 u-icon-size--xl u-icon-effect-v3--hover g-bg-primary g-color-white rounded-circle g-mb-30">
                 <i class="fa fa-users"></i>
                 </span>
                 <h3 class="h5 g-color-gray-dark-v2 g-font-weight-400 text-uppercase mb-3">Group Personal Accident Proposal</h3>

                 <br />
                 <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                    routerLink="/group-personal-accident-proposal-form">
                    Fill Now
                 </a>
              </div>
              <!-- End Icon Blocks -->
             </div>


          </div>

          <hr/>

          <br/><br/>
          <div class="row no-gutters">
             <div class="col-lg-4 cta-item g-px-40 g-mb-50 g-mb-0--lg">
                <!-- Icon Blocks -->
                <div class="text-center">
                   <span
                      class="d-inline-block u-icon-v3 u-icon-size--xl u-icon-effect-v3--hover g-bg-primary g-color-white rounded-circle g-mb-30">
                   <i class="fa fa-user"></i>
                   </span>
                   <h3 class="h5 g-color-gray-dark-v2 g-font-weight-400 text-uppercase mb-3">MONEY PROPOSAL FORM</h3>

                   <br />
                   <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                      routerLink="/money-proposal-form">

                      Fill Now
                   </a>
                </div>
                <!-- End Icon Blocks -->
             </div>

             <div class="col-lg-4 cta-item g-brd-left--lg g-brd-gray-light-v4 g-px-40 g-mb-50 g-mb-0--lg">
                <!-- Icon Blocks -->
                <div class="text-center">
                   <span
                      class="d-inline-block u-icon-v3 u-icon-size--xl u-icon-effect-v3--hover g-bg-primary g-color-white rounded-circle g-mb-30">
                   <i class="fa fa-users"></i>
                   </span>
                   <h3 class="h5 g-color-gray-dark-v2 g-font-weight-400 text-uppercase mb-3">Professional Indemnity Proposal Form</h3>

                   <br />
                   <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                      routerLink="/professional-indemity-proposal-form">
                      Fill Now
                   </a>
                </div>
                <!-- End Icon Blocks -->
             </div>

             <div class="col-lg-4 cta-item g-brd-left--lg g-brd-gray-light-v4 g-px-40 g-mb-50 g-mb-0--lg">
              <!-- Icon Blocks -->
              <div class="text-center">
                 <span
                    class="d-inline-block u-icon-v3 u-icon-size--xl u-icon-effect-v3--hover g-bg-primary g-color-white rounded-circle g-mb-30">
                 <i class="fa fa-users"></i>
                 </span>
                 <h3 class="h5 g-color-gray-dark-v2 g-font-weight-400 text-uppercase mb-3">Contractors/Erection All Risk Proposal Form</h3>

                 <br />
                 <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                    routerLink="/contractor-all-risk-proposal-form">
                    Fill Now
                 </a>
              </div>
              <!-- End Icon Blocks -->
             </div>

          </div>
          <hr/>


          <br/><br/>
          <div class="row no-gutters">
             <div class="col-lg-4 cta-item g-px-40 g-mb-50 g-mb-0--lg">
                <!-- Icon Blocks -->
                <div class="text-center">
                   <span
                      class="d-inline-block u-icon-v3 u-icon-size--xl u-icon-effect-v3--hover g-bg-primary g-color-white rounded-circle g-mb-30">
                   <i class="fa fa-user"></i>
                   </span>
                   <h3 class="h5 g-color-gray-dark-v2 g-font-weight-400 text-uppercase mb-3">MARINE CARGO PROPOSAL FORM</h3>

                   <br />
                   <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                      routerLink="/marine-cargo-proposal-form">

                      Fill Now
                   </a>
                </div>
                <!-- End Icon Blocks -->
             </div>

             <div class="col-lg-4 cta-item g-brd-left--lg g-brd-gray-light-v4 g-px-40 g-mb-50 g-mb-0--lg">
                <!-- Icon Blocks -->
                <div class="text-center">
                   <span
                      class="d-inline-block u-icon-v3 u-icon-size--xl u-icon-effect-v3--hover g-bg-primary g-color-white rounded-circle g-mb-30">
                   <i class="fa fa-users"></i>
                   </span>
                   <h3 class="h5 g-color-gray-dark-v2 g-font-weight-400 text-uppercase mb-3">MOTOR PROPOSAL FORM</h3>

                   <br />
                   <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                      routerLink="/motor-proposal-form">
                      Fill Now
                   </a>
                </div>
                <!-- End Icon Blocks -->
             </div>

             <div class="col-lg-4 cta-item g-brd-left--lg g-brd-gray-light-v4 g-px-40 g-mb-50 g-mb-0--lg">
              <!-- Icon Blocks -->
              <div class="text-center">
                 <span
                    class="d-inline-block u-icon-v3 u-icon-size--xl u-icon-effect-v3--hover g-bg-primary g-color-white rounded-circle g-mb-30">
                 <i class="fa fa-users"></i>
                 </span>
                 <h3 class="h5 g-color-gray-dark-v2 g-font-weight-400 text-uppercase mb-3">FIDELITY GUARANTEE INSURANCE PROPOSAL FORM</h3>

                 <br />
                 <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                    routerLink="/fidelity-gurantee-proposal-form">
                    Fill Now
                 </a>
              </div>
              <!-- End Icon Blocks -->
             </div>

          </div>

          <hr/>

          <br/><br/>
          <div class="row no-gutters">
             <div class="col-lg-4 cta-item g-px-40 g-mb-50 g-mb-0--lg">
                <!-- Icon Blocks -->
                <div class="text-center">
                   <span
                      class="d-inline-block u-icon-v3 u-icon-size--xl u-icon-effect-v3--hover g-bg-primary g-color-white rounded-circle g-mb-30">
                   <i class="fa fa-user"></i>
                   </span>
                   <h3 class="h5 g-color-gray-dark-v2 g-font-weight-400 text-uppercase mb-3">FIRE & SPECIAL PERILS INSURANCE PROPOSAL FORM</h3>

                   <br />
                   <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                      routerLink="/fire-special-peril-proposal-form">
                      Fill Now
                   </a>
                </div>
                <!-- End Icon Blocks -->
             </div>

             <div class="col-lg-4 cta-item g-brd-left--lg g-brd-gray-light-v4 g-px-40 g-mb-50 g-mb-0--lg">
                <!-- Icon Blocks -->
                <div class="text-center">
                   <span
                      class="d-inline-block u-icon-v3 u-icon-size--xl u-icon-effect-v3--hover g-bg-primary g-color-white rounded-circle g-mb-30">
                   <i class="fa fa-users"></i>
                   </span>
                   <h3 class="h5 g-color-gray-dark-v2 g-font-weight-400 text-uppercase mb-3">PERSONAL ACCIDENT INSURANCE PROPOSAL FORM   </h3>

                   <br />
                   <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                      routerLink="/personal-accident-proposal-form">
                      Fill Now
                   </a>
                </div>
                <!-- End Icon Blocks -->
             </div>

             <div class="col-lg-4 cta-item g-brd-left--lg g-brd-gray-light-v4 g-px-40 g-mb-50 g-mb-0--lg">
              <!-- Icon Blocks -->
              <div class="text-center">
                 <span
                    class="d-inline-block u-icon-v3 u-icon-size--xl u-icon-effect-v3--hover g-bg-primary g-color-white rounded-circle g-mb-30">
                 <i class="fa fa-users"></i>
                 </span>
                 <h3 class="h5 g-color-gray-dark-v2 g-font-weight-400 text-uppercase mb-3">GOODS IN TRANSIT INSURANCE PROPOSAL FORM</h3>

                 <br />
                 <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                    routerLink="/goods-in-transit-proposal-form">
                    Fill Now
                 </a>
              </div>
              <!-- End Icon Blocks -->
             </div>
          </div>
          <hr/>
         <br/><br/>
          <div class="row no-gutters">

             <div class="col-lg-4 cta-item g-brd-left--lg g-brd-gray-light-v4 g-px-40 g-mb-50 g-mb-0--lg">
               <!-- Icon Blocks -->
               <div class="text-center">
                  <span
                     class="d-inline-block u-icon-v3 u-icon-size--xl u-icon-effect-v3--hover g-bg-primary g-color-white rounded-circle g-mb-30">
                  <i class="fa fa-users"></i>
                  </span>
                  <h3 class="h5 g-color-gray-dark-v2 g-font-weight-400 text-uppercase mb-3">BURGLARY INSURANCE PROPOSAL FORM</h3>

                  <br />
                  <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                     routerLink="/burglary-proposal-form">
                     Fill Now
                  </a>
               </div>
               <!-- End Icon Blocks -->
              </div>

              <div class="col-lg-4 cta-item g-brd-left--lg g-brd-gray-light-v4 g-px-40 g-mb-50 g-mb-0--lg">
               <!-- Icon Blocks -->
               <div class="text-center">
                  <span
                     class="d-inline-block u-icon-v3 u-icon-size--xl u-icon-effect-v3--hover g-bg-primary g-color-white rounded-circle g-mb-30">
                  <i class="fa fa-users"></i>
                  </span>
                  <h3 class="h5 g-color-gray-dark-v2 g-font-weight-400 text-uppercase mb-3">PLANT ALL RISK INSURANCE PROPOSAL FORM</h3>

                  <br />
                  <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                     routerLink="/plant-all-risk-proposal-form">
                     Fill Now
                  </a>
               </div>
               <!-- End Icon Blocks -->
              </div>

              <div class="col-lg-4 cta-item g-brd-left--lg g-brd-gray-light-v4 g-px-40 g-mb-50 g-mb-0--lg">
               <!-- Icon Blocks -->
               <div class="text-center">
                  <span
                     class="d-inline-block u-icon-v3 u-icon-size--xl u-icon-effect-v3--hover g-bg-primary g-color-white rounded-circle g-mb-30">
                  <i class="fa fa-users"></i>
                  </span>
                  <h3 class="h5 g-color-gray-dark-v2 g-font-weight-400 text-uppercase mb-3">EMPLOYERS LIABILITY INSURANCE PROPOSAL FORM</h3>

                  <br />
                  <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                     routerLink="/employers-all-risk-proposal-form">
                     Fill Now
                  </a>
               </div>
               <!-- End Icon Blocks -->
              </div>

          </div>
          <hr/>
          <br/><br/>

          <div class="row no-gutters">

            <div class="col-lg-4 cta-item g-brd-left--lg g-brd-gray-light-v4 g-px-40 g-mb-50 g-mb-0--lg">
            <!-- Icon Blocks -->
            <div class="text-center">
               <span
                  class="d-inline-block u-icon-v3 u-icon-size--xl u-icon-effect-v3--hover g-bg-primary g-color-white rounded-circle g-mb-30">
               <i class="fa fa-users"></i>
               </span>
               <h3 class="h5 g-color-gray-dark-v2 g-font-weight-400 text-uppercase mb-3">WORKMEN COMPENSATION INSURANCE PROPOSAL FORM</h3>

               <br />
               <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
                  routerLink="/workmen-insurance-proposal-form">
                  Fill Now
               </a>
            </div>
            <!-- End Icon Blocks -->
            </div>

          </div>

       </div>
      </section>
    </div>
  </div>
</div>
