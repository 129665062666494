
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p>THE NSIA PLAN THAT GIVES YOU LIFE INSURANCE AND SAVINGS IN ONE PACKAGE</p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/life_saving.jpg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>Do you indulge in impulse buying and then find yourself unable to save for your future needs? If that is the case, we have the right product for you.
                 </p>
                 <p></p>
                 <p>The <strong>NSIA Endowment Assurance Plan</strong> takes care of two needs - life insurance protection against early death and saving towards a lump sum.
                 </p>
                 <p></p>
                 <p>The plan offers you a flexible pattern of premium payment which could be monthly, quarterly, half yearly or annually. You decide how much to save and for how long.
                </p>
                <p></p>
                <p>What are the key features of the <strong>NSIA ENDOWMENT ASSURANCE PLAN? </strong>
                </p>
                <p></p>
                <ul>
                  <li>Payment of a guaranteed sum of money at a chosen maturity date or in event of death within the duration of the policy</li>
                  <li>The minimum guaranteed amount payable is Five Hundred Thousand Naira (N500,000.00)</li>
                  <li>A bonus is added every year to the guaranteed amount you will be paid and it starts to count from the second year</li>
                  <li>Medical examinations may be required in certain cases</li>
                </ul>
                <p></p>
                <p>Who should buy the <strong>NSIA ENDOWMENT ASSURANCE PLAN?</strong></p>


              </div>
           </div>
           <div class="row justify-content-between">

           <ul style="float: left;">
              <li>Salary earners</li>
              <li>Small business owners</li>
              <li>Professionals such as doctors, lawyers, engineers, etc</li>
              <li>Government workers</li>
            </ul>
            <p></p>
            <p>If the policy is discontinued after two years, NSIA Life will pay a reduced benefit known as <strong>surrender value</strong>. </p>

            <p></p>
            <p>The surrender value is the difference between the premium paid and the termination charges.</p>
            <p></p>
            <p>If a surrender value is acquired after two years, you can apply to NSIA Life for a loan of up to 90% of the surrender value on the policy. </p>
            <p></p>
            <p>Upon the policyholder’s demise, NSIA Life pays the guaranteed amount and all accrued bonuses to the policyholder’s named beneficiary/ies. </p>
          <p></p>
          <p>Would you like to learn more about the <strong>NSIA Endowment Assurance Plan?</strong> Click <a routerLink="/endowment">here</a> to learn more or request for a quote.</p>
        <p></p>
        <p>You can also reach us on 09048418896 or through <a href="mailto:customerservicesg@nsiainsurance.com">customerservicesg@nsiainsurance.com</a> for enquiries.</p>
           </div>

        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>
