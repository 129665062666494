
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;"><strong>TIPS TO PREVENT HOME BURGLARY
                </strong></p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/dec/Burglary.jpeg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>Burglary is the worst nightmare of many homeowners. The highest percentage of break-ins happen while people are away on vacation, or begin to leave their windows and doors open more often. It is important to protect your house from burglars who might try to steal your belongings. To prevent a home burglary, check out the following tips.</p>
                 <p></p>

              </div>
           </div>

          <div class="row justify-content-between">


              <div class="col-md-12">
                <p></p>

                  <p><strong>Consider a home security system:</strong><br/>
                    Most burglars rely on hiding, speed and force to gain entry to a home. Getting a home security system from a reputable company can help you deter burglars and protect your house. As an added bonus, you might be able to get a home insurance discount if you have a security system.
                  </p>
                  <p></p>
                 <p><strong>Keep your windows and doors locked</strong> <br/>
                  Yes, windows can also be a possible point of entry to your house. Lock your windows and doors at all times; even when you leave briefly, or are outside on your property. Also, keep your valuables in a safe when you go out and change passcodes on electronic locks and garage doors from time to time. Never hide your keys outside.

                 </p>

                 <p></p>
                 <p><strong>Give your trees, bushes and shrubs a trim: </strong> <br/>
                  Here’s the logic for this one – by keeping your shrubs and bushes neatly trimmed, you can eliminate potential hiding spots for a burglar. Also, it’s easier to see someone creeping around if there aren’t leaves and branches in the way.
                 </p>
                 <p></p>

                 <p><strong>Make sure your surroundings are well lit: </strong> <br/>
                  Having exterior lights can help deter burglars. Typically, they don’t want people to see them sneaking around. It’s a good idea to have plenty of exterior lights around your home. You can get motion-sensor lights, too. It’s also a good idea to consider getting timers for your lights so that they come on and go off. This can help give the impression that someone’s home.

                 </p>
                 <p></p>

                 <p>If you’re going to be out of town, make sure your home still looks lived-in. Have a trusted neighbor cut the grass and pick up newspapers from the driveway.
                </p>
<p></p>

                <p>It is possible to apply all these tips and unfortunately still get burgled. In that case, homeowner’s insurance is another great way to protect your home. Get started with quotes by filling out our online form here, or giving us a call on 09048418896.
                </p>



              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>