<div class="g-min-height-300 g-flex-centered g-bg-img-hero g-bg-pos-top-center g-bg-size-cover g-pt-80"
  style="background-image: url(assets/img/fire.jpg);">
  <div class="container g-pos-rel g-z-index-1">
    <h2 class="h1 text-uppercase g-color-white g-font-size-40--lg mb-0">Fire and Burglary Insurance</h2>
    <span class="d-block  g-color-white g-font-weight-1000 g-font-size-20 mb-4">We are here to support you.</span>
  </div>
</div>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item g-color-primary">
        <span>Fire and Burglary Insurance</span>
      </li>

    <li class="list-inline-item ml-auto">
          <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10"
          routerLink="/getquote">Get quote</a> -->
      </li>
    </ul>
  </div>
</section>


<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
    <div class="col-lg-9 g-mb-30">
        <p>Burglary Insurance cover provides financial protection to the Insured as a result of loss or damage to its
            properties through theft or attempted theft. There must be a forceful entry or exit from the building before
            the policy can be activated.</p>
          <p></p>
          <br/>
      <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
        <!-- <h3 class="mb-0"><strong><p>Fire and Burglary Insurance</p></strong></h3><br/> -->

        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
            <h5 style="color: white">
              FIRE & SPECIAL PERILS INSURANCE
           </h5>
        </div>
        <p></p>
        <div class="row justify-content-between">
          <!-- About Image -->
          <div class="col-md-3 col-lg-4">
            <img class="img-fluid" src="assets/img/banners/Fire.png" alt="Image Description">
          </div>
          <!-- End About Image -->

          <div class="col-md-4 col-lg-8">
            <p></p>
        <p>The Fire and Special Perils policy is designed to provide financial compensation to the Insured as a result of
          loss or damage to an insured building or stock by fire and some other named perils like riot and civil
          commotion, flood, burst pipe, earthquake, landslide, subsidence, bush burning.</p>
        </div>
      </div>

    <p></p>
      </div>
    </div>


    <!-- Sidebar -->
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <app-sidebar></app-sidebar>
      <!-- End Fast Facts -->
    </div>
    <!-- End Sidebar -->
  </div>
</div>
