
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;">THE BEIRUT EXPLOSION AND THE IMPORTANCE OF INSURANCE</p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/Beirut.jpg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>The catastrophic nature a bomb explosion leaves much to be desired. Such dastardly acts give us reason to worry about what the future holds. It also begs the question; do the perpetrators of these cowardly and inhumane acts have blood running through their veins as normal people do?
                 </p>
                 <p></p>
                 <p>Online videos of the recent explosion in Beirut, Lebanon that went viral on Tuesday 4th of August 2020, showed at least 137 people dead and about 5,000 wounded; a major disaster especially at this time when the Covid-19 spread is yet to be curtailed. Hundreds have also been reported missing, raising fears that the death toll will rise. Reports also show that more than 300,000 people have been displaced from their homes. It is still unclear at this time, the cause of the explosion, but questions swirled over whether the authorities had failed to act on warning signs.
                 </p>
                 <p></p>


              </div>
           </div>

           <div class="row justify-content-between">
            <!-- About Image -->

            <div class="col-md-3 col-lg-4">
               <img class="img-fluid" src="assets/img/Beiru2.jpg" alt="Image Description">
            </div>
            <!-- End About Image -->
            <div class="col-md-8 col-lg-8">
               <!-- About Info -->
               <p>According to the prime minister of Lebanon, investigations would focus on a warehouse storing an estimated 2,750 metric tons of the explosive ammonium nitrate as a possible source of the blast.
               </p>
               <p></p>
               <p>The debilitating effects of this event on the economy of Beirut, and Lebanon at large, raises questions as regards how dependents will survive after the loss of their breadwinners; what will be done to  rebuild  the affected infrastructures? What will happen to those injured and hospitalized? Are they capable of paying the hospital bills? There is also the issue of those who are presently out of a job, and how they would survive.  These and more questions will linger in the minds of many, if not all of us.
               </p>
               <p></p>


            </div>
         </div>

          <div class="row justify-content-between">


              <div class="col-md-12">
               <p>In situations like this, the importance of insurance cannot be overstated. In as much as the world mourns and sympathizes with Lebanon, it is important that we begin to take preemptive steps to ensure that the fallout from disasters like this is mitigated as much as possible.
               </p>
               <p></p>
                <p>The onus falls on various governments and the international community to consider the level and importance of Insurance awareness in the world, thus implement strategies and legislation to mitigate losses of this nature. What affects one individual or group of individuals may in the long run, directly or indirectly affect us all, be it economically, financially, emotionally, and/or otherwise.</p>
                <p></p>
                <p>Bringing it home to Nigeria, some people may raise eyebrows and say that such catastrophic events rarely happen to us in this part of the world, some may even go “spiritual” at the thought or sound of it, but suffice it to mention that a few months back we experienced a catastrophic explosion that shook the ground and roofs of several houses, shattered the windows of houses and cars parked in the area and destroyed a popular secondary school building amidst many others, killing several pupils, teachers, and passers-by in Abule Ado area, around Festac Town in AmuwoOdofin Local Government Area of Lagos. Asides this there have been reports of gas and petrol tanker explosions in recent times claiming the lives of people and properties.</p>
                <p></p>

                 <p>In light of this, it is pertinent to reiterate the importance of Insurance in our everyday living. For instance, a simple Life Assurance policy will cater for a child who may have lost a parent, or family members who may have lost a breadwinner. Also, a Fire and Special perils insurance policy will cover the loss suffered after a building is destroyed, and a Motor Insurance policy will cover for the loss and damage to vehicles. These are a few examples of Insurance policies that mitigate losses that may occur in our everyday living.
                 </p>
                 <p></p>

                 <p>Though the pain of losing a loved one can be severe knowing that human life cannot be replaced but one should not suffer both ways by failing to have a “cushion” to fall back on; <strong>let us be your cushion here at NSIA Insurance.</strong></p>
                <p></p>

                <p>For more information on our products, please visit <a href="https://nsiainsurance.com/">https://nsiainsurance.com</a> or send an email to <a href="mailto:customerservice@nsiainsurance.com">customerservice@nsiainsurance.com</a>. </p>

              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>