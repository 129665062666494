<ng-progress [color]="'green'"></ng-progress>



<div class="container g-pt-100 g-pb-70">
  <div class="row">
    <div class="col-lg-12 g-mb-30">
      <section class="g-bg-gray-light-v5 g-py-20">
        <form novalidate (ngSubmit)="submitForm(beneficiaryForm)" #beneficiaryForm="ngForm" novalidate>
          <div class="container g-py-100 pad-top-50" style="margin-top:-50px">
            <div class="u-shadow-v19 g-brd-around g-brd-gray-light-v4  rounded mx-auto g-pa-30 g-pa-50--md">

              <div class="mb-6">
                <h2 class="mb-4 main-heading">
                  Public Liability Proposal Form
                </h2>

                <section class="step-two" >
                  <div class="form-sub-title">
                    <h4>Personal Information</h4>
                  </div>
                  <div class="row">

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>Name of Proposer</label>
                        <input id="NameOfProposal" name="NameOfProposal" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.NameOfProposal" required>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Address of Proposer
                        </label>
                        <input id="AddressOfProposal" name="AddressOfProposal" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.AddressOfProposal" required >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Trade Or Business
                        </label>
                        <input id="TradeOrBusiness" name="TradeOrBusiness" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.TradeOrBusiness" required >
                      </div>
                    </div>


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          E- mail address
                        </label>
                        <input id="EmailAddress" name="EmailAddress" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.EmailAddress" required type="text" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" >

                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Mobile No
                        </label>
                        <input id="Telephone" name="Telephone" class="form-control form-control-md rounded-0" type="number"
                        placeholder="Required" [(ngModel)]="proposalModel.Telephone" required="required" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==11) return false; return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57));" >

                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Alternate Mobile No
                        </label>
                        <input id="SecondTelephone" name="SecondTelephone" class="form-control form-control-md rounded-0" type="number"
                         [(ngModel)]="proposalModel.SecondTelephone"  pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==11) return false; return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57));" >

                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                         <label>
                          Start Date
                         </label>
                         <input id="StartDate" name="StartDate" class="form-control form-control-md rounded-0" type="date"
                          placeholder="yyyy-mm-dd" [(ngModel)]="proposalModel.StartDate" >

                      </div>
                   </div>
                   <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                         <label>
                          End Date
                         </label>
                         <input id="EndDate" name="EndDate" class="form-control form-control-md rounded-0" type="date"
                          placeholder="yyyy-mm-dd" [(ngModel)]="proposalModel.EndDate"  >
                      </div>
                   </div>
                  </div>
                </section>

                <section class="step-three">
                  <div class="form-sub-title">
                    <h4>General Questions</h4>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Nature of Contract
                        </label>
                        <input id="NatureOfContract" name="NatureOfContract" class="form-control form-control-md rounded-0" type="text"
                          placeholder="Required" [(ngModel)]="proposalModel.NatureOfContract">
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Are you the owner or a tenant  of The Premises
                        </label>
                        <select name="proposalModel.TenantOrOwner" id="proposalModel.TenantOrOwner" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.TenantOrOwner">
                            <option >Select your option</option>
                            <option value="1">Yes</option>
                            <option value="2">No</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-6" >
                      <div class="form-group g-mb-20">
                        <label>
                          To which Extent
                        </label>
                        <input id="TenantExtent" name="TenantExtent" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.TenantExtent" >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Are you the only occupier of the building/premises?
                        </label>
                        <select name="occupier" id="occupier" class="form-control form-control-md rounded-0"
                        placeholder="Required" [(ngModel)]="proposalModel.occupier">
                            <option>Select your option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Do you or will you use any the followings?
                        </label>
                        <br/>
                        <input  name="equipments"  type="checkbox"
                          (change)="addCont($event)"  [(ngModel)]="proposalModel.Additional[0]" value="Hoists">Hoists
                          <input  name="equipments"  type="checkbox"
                          (change)="addCont($event)" [(ngModel)]="proposalModel.Additional[1]" value="lifts">Lifts
                          <input  name="equipments"  type="checkbox"
                          (change)="addCont($event)" [(ngModel)]="proposalModel.Additional[2]" value="passenger’s lifts">Passenger’s lifts
                          <input  name="equipments"  type="checkbox"
                          (change)="addCont($event)" [(ngModel)]="proposalModel.Additional[3]" value="escalators">Escalators
                      </div>
                    </div>



                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Do you engage in work at other premises?
                        </label>

                        <select name="WorkAtPremises" id="WorkAtPremises" class="form-control form-control-md rounded-0"
                         [(ngModel)]="proposalModel.WorkAtPremises">
                            <option >Select your option</option>
                            <option value="1">Yes</option>
                            <option value="2">No</option>
                        </select>

                      </div>
                    </div>


                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          What is the estimated annual value of contract implemented by your company?
                        </label>
                        <input id="EstimatedAnnualValue" name="EstimatedAnnualValue" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.EstimatedAnnualValue" >

                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Limit of Liability
                        </label>

                        <select name="LimitLiaibility" id="LimitLiaibility" class="form-control form-control-md rounded-0"
                         [(ngModel)]="proposalModel.LimitLiaibility">
                            <option >Select your option</option>
                            <option value="Any One occurrence">Any One occurrence</option>
                            <option value="Any One Period of Insurance">Any One Period of Insurance</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Please state any previous losses incurred arising out of the mode of your operation (stating the years and extent of losses)
                        </label>
                        <input id="PreviousLosses" name="PreviousLosses" class="form-control form-control-md rounded-0" type="text"
                         [(ngModel)]="proposalModel.PreviousLosses" >
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Has any proposal by you for Public Liability Insurance ever been declined or cancelled (if so give details):
                        </label>
                        <input id="ProposalDecline" name="ProposalDecline" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.ProposalDecline" >
                      </div>
                    </div>



                  </div>
                </section>

               <section class="step-seven" >

                 <div class="container">
                   <h4>Declaration</h4>
                   <p>I/We hereby declare that to the best of my/our knowledge and belief the information herein provided is correct and complete.</p>

                   <!-- <div class="form-group">
                    <input type="checkbox" [(ngModel)]="termAgreed" name="termAgreed" > By checking the box , I agree with the above terms
                  </div> -->

                      <p style="font-size:10px; text-align: justify;">In compliance with NDPR requirements, by completing and submitting this form, you have given consent to NSIA Insurance to receive your data, including your bio data, to enable NSIA Insurance maintain effective communication, send promotional updates, contact you for research purposes and use the data in line with the policy terms.
                      </p>
                      <p style="font-size:10px; text-align: justify;">The data collection may be via hard copies or online forms, validated via JSON WEB TOKEN (JWT). The data will not be accessed by any third party without your consent and any breach of this policy can be addressed legally within the year the breach was reported.</p>

                      <div class="col-lg-4">
                        <div class="form-group g-mb-0">
                          <label style="visibility: hidden;">Frequency</label>
                          <button  type="submit" onclick="return confirm('Are you sure you want to submit this information?')" class="g-brd-main btn-block g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" > Submit
                          </button>
                        </div>

                      </div>

                 </div>

               </section>

              </div>


            </div>
          </div>
        </form>
      </section>
    </div>

    <!-- End Sidebar -->
  </div>
</div>
