export const environment = {
  production: true,
  baseUrl: "https://api.nsiainsurance.com",
  firebaseConfig: {
    apiKey: "AIzaSyCQYiIqaa-2XBO0_efgTv533TpXjKiQGV0",
    authDomain: "nsia-web.firebaseapp.com",
    databaseURL: "https://nsia-web-default-rtdb.firebaseio.com",
    projectId: "nsia-web",
    storageBucket: "nsia-web.appspot.com",
    messagingSenderId: "296710376456",
  }
};
