import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-energy-insurance',
  templateUrl: './energy-insurance.component.html',
  styleUrls: ['./energy-insurance.component.css']
})
export class EnergyInsuranceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
