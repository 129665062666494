import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nsia-ads-campaign',
  templateUrl: './nsia-ads-campaign.component.html',
  styleUrls: ['./nsia-ads-campaign.component.css']
})
export class NsiaAdsCampaignComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
