
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;"><strong>NSIA EDUCATION PROTECTION PLAN</strong></p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/EPP.jpeg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>One major benefit of life insurance is to ensure the future well-being of our loved ones. Children are the most vulnerable when unfortunate events occur since they do not have the capacity to financially support themselves. Failing to prepare for your child’s educational future goes on to affect important aspects of their lives. </p>
                 <p></p>
                 <p>
                  Various situations have disrupted the schooling of many children, such as the loss of the breadwinner in a family. Certain events could place the dreams of a loved one on hold, this makes it necessary to seek measures to help protect their dreams even if the worst were to happen.
                 </p>
                 <p></p>
                <p>
                  NSIA Education Protection Plan is specifically designed to protect group of families and individuals against the ongoing cost of their child’s education upon the death of any of the two parents or physical incapacitation of the fee-paying parents. It is a safety net that would help your child focus without worrying about financial challenges in your absence. The returns will be sufficient enough to help your child meet future needs even when you’re not around.
                </p>
              </div>
           </div>

          <div class="row justify-content-between">

              <div class="col-md-12">

               <p></p>
               <p>
                NSIA Education Protection Plan comes in two variations: Individual and Group. The individual plan is taken up by individual parents on behalf of one or more of their children, and the group plan is targeted at protecting the pupils/students of participating schools from premature stoppage of schooling.
               </p>
                 <p></p>
                 <p>
                  NSIA Education Protection Plan is price-friendly and is suitable for Private Nursery, Primary and Secondary schools; Private Universities and polytechnics; Public universities and polytechnics; Individual parents/guardians or sponsors and any other institution of learning.
                 </p>
                 <p></p>

                 <p>Plan towards quality education for your child today, partner with NSIA Insurance. Click here <a href="https://nsiainsurance.com/epp">www.nsiainsurance.com</a> to learn more, or give us a call on 09048418896.</p>

              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>
