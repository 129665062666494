import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-do-it-today-not-tomorrow',
  templateUrl: './do-it-today-not-tomorrow.component.html',
  styleUrls: ['./do-it-today-not-tomorrow.component.css']
})
export class DoItTodayNotTomorrowComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
