import { Component, OnInit } from '@angular/core';
//import Swal from 'sweetalert2';
import { data } from 'jquery';
import * as $ from 'jquery';
//import swal from 'sweetalert2';
import { NgForm } from '@angular/forms'
import { FormserviceService } from 'src/app/Services/formservice.service';
import { AllRiskProposalForm } from 'src/app/Classes/all-risk-proposal-form';

@Component({
  selector: 'app-all-risk-proposal-form',
  templateUrl: './all-risk-proposal-form.component.html',
  styleUrls: ['./all-risk-proposal-form.component.css']
})
export class AllRiskProposalFormComponent implements OnInit {
  model;
  constructor(private _formservice: FormserviceService) { }
  proposalModel = new  AllRiskProposalForm("","","","","","",
  "","","","","","","",null,"", "","","");
  ngOnInit(): void {
  }

  submitForm(myForm: NgForm){
    var email = this.proposalModel.EmailAddress;
    var regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if(!regex.test(email)) {
     alert("Your email is not correct");
      this._formservice.stopLoading();
      return;
    }
    console.log($('#Telephone').val());
    var telLength = $('#Telephone').val().toString().length;
    //var secTel =$("#AlternativeTel").val().toString().length;

    if(telLength < 10){
     alert("Your telephone number is not correct");
      this._formservice.stopLoading();
      return;
    }

    this._formservice.startLoading();
    this._formservice.sendAllRiskProposalForm(this.proposalModel)
      .subscribe(
        (data) => {
          if (data.statusCode == "00") {
            alert("Your form is submitted");
            myForm.resetForm();
          } else {
           // Swal.fire("error", data.statusMessage, "info");
          alert("Your form is submitted");
          }
          this._formservice.stopLoading();
        },
        (error) => {
          console.log(error);
         // Swal.fire("fatal", "internal server error occurred", "error");
        alert("Your form is submitted");
          this._formservice.stopLoading();
        }
      )

  }


}
