<section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px">
  <div class="container">
    <div class="d-sm-flex text-center">
      <div class="align-self-center">
        <h2 class="h3 g-font-weight-300 w-100 g-mb-10 g-mb-0--md">
          <p>Endowment Assurance Plan</p>
        </h2>
      </div>


    </div>
  </div>
</section>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/lifeinsurance">Life Insurance</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item g-color-primary">
        <span>Endowment Assurance Plan</span>
      </li>

      <li class="list-inline-item ml-auto">
        <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10"
          routerLink="/getquote">Get quote</a>
      </li>
    </ul>
  </div>
</section>
<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
    <div class="col-lg-9 g-mb-30">
      <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
        <h4><strong>
            <p>Endowment Assurance Plan</p>
          </strong> </h4>

        <p>This product fulfills two needs – life insurance protection against early death during the chosen term of
          cover and savings towards a lump sum at the end of the term. Based on his needs and financial capability, the
          policyholder chooses a suitable guaranteed benefit (sum assured) and the period of cover. In addition, the
          product offers a flexible pattern of premium payment which could be monthly, quarterly, half yearly or
          annually.</p>
        <p></p>

        <p><strong>Key Features</strong></p>
        <p></p>
        <ul>
          <li>
            <p>Payment of a guaranteed sum of money (sum assured) at a chosen future maturity date (say 5, 10, 20 years
              etc) or earlier death within the duration of the policy.</p>
          </li>
          <li>
            <p>The amount payable is specified in advance by the policyholder</p>
          </li>
          <li>
            <p>Minimum sum assured is Five Hundred Thousand Naira (N500,000)</p>
          </li>
          <li>
            <p>A simple reversionary bonus (profit) is added to the sum assured every year. This is currently guaranteed
              at 2% of sum assured per annum.</p>
          </li>
          <li>
            <p>Policy can be used as collateral for loan</p>
          </li>
          <li>
            <p>Policyholder can apply for policy loan after the policy has been actively maintained for two consecutive
              years</p>
          </li>
          <li>
            <p>Maximum age at inception is 60 while the maximum maturity age is 65 years.</p>
          </li>
          <li>
            <p>Policyholder pays annually, half-yearly, quarterly or monthly to NSIA Life Assurance Limited to secure
              the guaranteed benefit and bonuses</p>
          </li>
          <li>
            <p>Medical examinations may be required in certain cases</p>
          </li>
        </ul>
        <p></p>

        <p><strong>Benefits</strong></p>
        <p></p>
        <p><b>On Death</b></p>

        <p></p>
        <p>NSIA Life pays the guaranteed sum assured and all accrued bonuses to the policyholder’s named
          beneficiary/ies.</p>

        <p></p>
        <p><b>On Maturity</b></p>

        <p></p>

        <p>If the policyholder lives till the end of the policy term, he receives the sum assured together with all
          accrued bonuses.</p>
        <p></p>
        <p><b>On Discontinuation</b></p>

        <p></p>

        <p>If the policyholder discontinues the policy after two complete years premiums have been paid and the policy
          has been in force for two years, NSIA Life will pay a reduced benefit known as surrender value.</p>


        <p></p>
        <p> <b>Policy Loan</b></p>

        <p></p>

        <p>On acquiring a surrender value after two years, the policyholder can apply to NSIA Life for a loan of up to
          90% of the surrender value on the policy.</p>
        <p></p>

        <p><b>Termination</b></p>

        <p></p>

        <p>No payment is made if the policy is terminated within two years of its inception.</p>
        <p></p>

        <p><b>Suitability</b></p>

        <p></p>

        <p>Endowment product is suitable for an individual who wants a combination of life insurance cover and savings.
        </p>
        <!-- <p><strong>Waiting Period</strong> </p>

        <p></p>

        <p>There is a month waiting period during which a client cannot claim on non-accidental death. However, where accidental
        death occurs during this period, total premiums paid would be returned</p> -->
        <p></p>
      </div>
    </div>


    <!-- Sidebar -->
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <app-sidebar></app-sidebar>
      <!-- End Fast Facts -->
    </div>
    <!-- End Sidebar -->
  </div>
</div>
