<!-- <section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px">
        <div class="container" >
          <div class="d-sm-flex text-center">
            <div class="align-self-center">
              <h2 class="h3 g-font-weight-300 w-100 g-mb-10 g-mb-0--md" ><p>Management Team</p></h2>
            </div>
          </div>
        </div>
      </section> -->

<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item g-color-primary">
        <span>About Us</span>
      </li>

      <li class="list-inline-item ml-auto">
        <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10"
          routerLink="/getquote">Get quote</a>
      </li>
    </ul>
  </div>
</section>
<div class="container g-pt-100 g-pb-70" style="margin-top: -50px">
  <div class="row">
    <div class="col-lg-9 g-mb-30">
      <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
        <h3 class="mb-0">
          <strong>
            <p>Management Team</p>
          </strong>
        </h3>
        <br />

        <br />
        <div class="row justify-content-between ">
          <br />
          <div class="row justify-content-between movdow" *ngFor="let manage of allManage">
            <!-- About Image -->
            <div class="col-md-3 col-lg-3">
              <img class="img-fluid" src="{{manage.image}}" alt="Image Description" />
            </div>
            <!-- End About Image -->
            <div class="col-md-8 col-lg-9">
              <!-- About Info -->
              <h3>
                <p class="fbold">{{manage.name}}</p>
              </h3>
              <h6 class="h6 g-color-primary size">{{manage.title}}</h6>
              <p>
                {{manage.abouta}}
              </p>
              <p>
                {{manage.aboutb}}
              </p>
              <p>
                {{manage.aboutc}}
              </p>
              <p>{{manage.aboutd}}</p>
            </div>
          </div>
          <br />
          <br />



        </div>
        <br /><br />
      </div>
      <!-- End About Info -->

      <!-- Sidebar -->
      <div class="col-lg-3 g-mb-30">
        <!-- Links -->
        <app-sidebar></app-sidebar>
        <!-- End Fast Facts -->
      </div>
      <!-- End Sidebar -->
    </div>
  </div>