import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';;
import { ManageService } from 'src/app/manage/manage.service';
@Component({
  selector: 'app-addmanagement',
  templateUrl: './addmanagement.component.html',
  styleUrls: ['./addmanagement.component.css']
})
export class AddmanagementComponent implements OnInit {

  public manageForm: FormGroup;

  constructor(private manageService: ManageService, private router: Router, public fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.manageService.GetStudentsList();
    this.managForm()
  }
  managForm() {
    this.manageForm = this.fb.group({
      image: "",
      name: "",
      title: "",
      abouta: "",
      aboutb: "",
      aboutc: "",
      aboutd: ""
    })
  }


  get image() {
    return this.manageForm.get('image')
  }
  get name() {
    return this.manageForm.get('name')
  }
  get title() {
    return this.manageForm.get('title')
  }
  get abouta() {
    return this.manageForm.get('abouta')
  }
  get aboutb() {
    return this.manageForm.get('aboutb')
  }
  get aboutc() {
    return this.manageForm.get('aboutc')
  }
  get aboutd() {
    return this.manageForm.get('aboutd')
  }

  create() {

    this.manageService.addMa(this.manageForm.value)

    this.router.navigate(["/access-to-management"])

  }

}
