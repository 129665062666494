<div class="g-min-height-300 g-flex-centered g-bg-img-hero g-bg-pos-top-center g-bg-size-cover g-pt-80"
  style="background-image: url(assets/img/banners/agric.jpg);">
  <div class="overlay-purple">

  </div>
  <div class="container g-pos-rel g-z-index-1">
    <h2 class="h1 text-uppercase g-color-primary g-font-size-40--lg mb-0" style="color:#FFF"><b style="color:#FFF">NSIA Agricultural Insurance Products</b>
    </h2>
    <!-- <span class="d-block  g-color-white g-font-weight-1000 g-font-size-20 mb-4"> Protecting the key assets of your
      livelihood.</span> -->

  </div>
</div>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item g-color-primary">
        <span>Agric Insurance</span>
      </li>

      <li class="list-inline-item ml-auto">
        <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15"
           routerLink="/getquote">Get quote</a>
      </li>
    </ul>
  </div>
</section>


<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
    <div class="col-lg-9 g-mb-30">
      <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
        <!-- <h3 class="mb-0"><strong><p>BUSINESS INSURANCE</p></strong></h3><br/> -->
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white;">Poultry Farm Insurance </h5>
        </div>
        <p></p>
        <p class="mb-0">This product covers birds (broilers, layers, parent stock, grandparents stock, hatchery stock,
          cockerel, ornamental birds) against death as a result of fire, lightning, windstorm damage, flood,
          uncontrollable disease and accident. Premium rate is dependent on the risk exposure, standard of risk
          management, biosecurity, size of the farm, and the type of birds to be insured. The sum insured is based on
          the declared market value of the bird at table size, and layers at point of lay. </p>
        <p></p>
        <br />
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white;">Livestock Insurance Policy </h5>
        </div>
        <p></p>
        <p class="mb-0">This policy insures livestock (e.g. cattle, sheep, goats, rabbits, and pigs) against death
          caused by fire, lightning, windstorm, flood, accident, outbreak of uncontrollable disease. The coverage
          provided by this policy indemnifies based on the value of insured animals in the event of death. Premium rate
          is dependent on the risk exposure, standard of risk management, biosecurity, size of the farm, and the type of
          animal to be insured. The sum insured is dependent on the declared, and/or projected, market value of the
          animal. </p>
        <p></p>
        <br />
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white;">Fish Farm Insurance </h5>
        </div>
        <p></p>
        <p class="mb-0">This policy insures fish against death and fish pond against collapse as a result of fire, lightning, windstorm damage, flood, uncontrollable disease and accident.
          The sum insured is based on the declared, and/or projected market value of the fish. Premium rate is dependent on the risk exposure, standard of risk management, biosecurity, size of the farm, and the type of fish to be insured.
          </p>
        <p></p>
        <br />
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white;">Plantation Fire Insurance </h5>
        </div>
        <p></p>
        <p class="mb-0">This policy covers cocoa, sugar cane, palm oil and other plantation farms against loss or damage as a result of fire, lightning, flood, windstorm, and aircraft perils. The sum insured is based on the declared market value of the crops on maturity. Premium rate is dependent on the risk exposure, standard of risk management, biosecurity, size of the farm, and the type of crop(s) to be insured.
          </p>
        <p></p>
        <br />
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white;">Crop Insurance</h5>
        </div>
        <p></p>
        <p class="mb-0">This policy covers food crops and cash crop farms against loss or damage as a result of fire, lightning, flood, windstorm, pest/diseases and aircraft perils. The sum insured is based on the declared market value of the crops on maturity. Premium rate is dependent on the risk exposure, standard of risk management, biosecurity, size of the farm, and the type of crop(s) to be insured.
          </p>
        <p></p>
        <p><strong>Below are the requirements for Poultry farm, Livestock, Fish farm, Plantation fire and Crop Insurance (All five policies have the same requirements) </strong></p>
        <ul>
          <li>Completion of our proposal form</li>
          <li>Physical inspection/pre-loss survey of your farm</li>
          <li>Standard farm management and Good Agronomy Practice (GAP)</li>
        </ul>
        <br />
        <div style="padding: 10px 0px 0.05px 10px; background-color:#efb225;">
          <h5 style="color:white;">Farm Property & Produce Insurance</h5>
        </div>
        <p></p>
        <p class="mb-0">
          This policy provides cover against loss or damage to farm property (e.g. warehouse and farm equipment) and agricultural produce as a result of fire, burglary/housebreaking, lightning, flood, windstorm, explosion, aircraft, earthquake, and machines/machinery damage or breakdown and impact risks.  Premium rate is dependent on the risk exposure, standard of risk management measures implemented in order to protect the property/produce.
          </p>
          <p><strong>Requirements for Farm property & produce Insurance </strong></p>
          <ul>
            <li>Inventory of items to be insured</li>
            <li>Completion of our proposal form</li>
            <li>Physical inspection/pre-loss survey of the items to be insured</li>
            <li>Standard management and housekeeping of the items </li>
          </ul>
        <p></p>
        <br />






      </div>
    </div>

    <!-- Sidebar -->
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <app-sidebar></app-sidebar>
      <!-- End Fast Facts -->
    </div>
    <!-- End Sidebar -->
  </div>
</div>
