<!-- <section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px">
        <div class="container" >
          <div class="d-sm-flex text-center">
            <div class="align-self-center">
              <h2 class="h3 g-font-weight-300 w-100 g-mb-10 g-mb-0--md" ><p>Career</p></h2>
            </div>


          </div>
        </div>
      </section> -->
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item g-color-primary">
        <span>Careers</span>
      </li>

      <li class="list-inline-item ml-auto">
        <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
      </li>
    </ul>
  </div>
</section>

<div class="container g-pt-100 g-pb-70" style="margin-top: -50px">
  <div class="row">
    <div class="col-lg-9 g-mb-30">
      <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
        <h3 class="mb-0">
          <strong><p>CAREERS</p></strong>
        </h3>
        <br />

        <!-- Clients Block-->

        <p>
          At NSIA, we rank among the best workplaces in Nigeria by the Great
          Place to Work Institute Nigeria for having a positive workplace
          culture that values employees as the heart of our organisation.
        </p>
        <p></p>
        <p>
          If you envision yourself building a career in a workplace with
          progressive work-balance policies, low turnover rate, commitment to a
          gender-balanced talent pool and strong level of support for employee
          innovation, NSIA could be the perfect fit for you.
        </p>
        <p></p>
        <p>
          Please see below for current vacancies
        </p>

        <div class="page-wrap">
          <div class="article-grid">
            <div class="job_details" *ngFor="let item of claims">
              <div class="row">
                <div class="col-lg-6 col-md-12">
                  <div class="job_details_box">
                      <div class="job_details_box_company_img">
                        <a><img src="images/about/company-logo-1.png" class="img-responsive" alt=""></a>
                      </div>
                      <div class="job_details_box_position">
                        <h3><a href="javascript:void();">{{item.name.toUpperCase()}}</a></h3>
                        <span class="span-bold">JOB CODE: </span><span class="job_details_sallery">{{item.jobCode}}</span>
                        <p>
                          <!-- <span>JOB CODE: </span><span class="job_details_sallery">{{item.jobCode}}</span> -->
                          <!-- <span class="job_type job-success bg-job-success">Full Time</span>
                          <span class="job_type job-success bg-job-success">Full Time</span> -->
                        </p>
                      </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 align-self-center">
                  <div class="job_box_location">
                    <div class="job_details_location">
                      <p>{{item.jobDetails}}</p>
                    </div>
                    <div class="job_apply_btnn"> <a >Location: {{item.location}}</a> </div>
                    <div class="job_apply_btnn"> <a > Application Deadline: {{item.applicationDeadline}}</a> </div>
                    <div class="button__read-more" > <button class="button-text" (click)="readMoreClick(onClick, item.id)" >READ MORE</button>
                      <div *ngIf="onClick && myId == item.id" class="job_details_location">
                        <h2>Requirements</h2>
                        <div *ngFor="let req of item.requirements">
                          <p> <span>&#8226;</span>  {{ req }}</p>


                        </div>
                        <p>If you are ready to take this exciting and bold step, kindly send your CV and Application to
                          <a href="mailto:humancapitalmgt@nsiainsurance.com">humancapitalmgt@nsiainsurance.com</a>.
                        </p>
                      </div>
                    </div>

                  </div>

                     </div>







              </div>
            </div>

            <!-- <article class="" >

              <div class="" >
                <br/>
                <h3>{{item.name}}</h3>
                <h5>{{item.jobCode}}</h5>
              </div>
              <p style="text-align:justify;" >{{item.jobDetails}}</p>
              <span class="align-button">
                <button (click)="readMoreClick(onClick)" class="button-text">
                  Read More
                </button>
              </span>
                <br/>
              <div *ngIf="onClick">
                <p>Location: {{ item.location }}</p>
                <p></p>
                <ul *ngFor="let req of item.requirements">
                  <li>{{ req }}</li>
                </ul>

               </div>
            </article> -->
          </div>
           </div>
           <!-- <p>
            If you’re ready to take this exciting and bold step, kindly send your
            CV & application using the job code and role as the subject to:
            humancapitalmgt@nsiainsurance.com, bearing each specific deadline
            listed above.
          </p> -->
          <h3>NOTE: ONLY SHORTLISTED CANDIDATES WILL BE CONTACTED</h3>
        </div>
        </div>
        <div class="col-lg-3 g-mb-30">
          <app-sidebar></app-sidebar>
        </div>
    </div>

    <!-- Sidebar -->

    <!-- End Sidebar -->
  </div>
