import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-campign-plan-b',
  templateUrl: './campign-plan-b.component.html',
  styleUrls: ['./campign-plan-b.component.css']
})
export class CampignPlanBComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
