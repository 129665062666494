
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;"><strong>GENERAL INSURANCE</strong></p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/General_Insurance.jpg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>General insurance, also known as non-life insurance, covers financial loss suffered due to the loss or damage of an asset. This category of insurance covers all forms of insurance except life. Other covers may include insurance against errors and omissions for professionals, credit insurance etc. Common forms of general insurance are motor, fire, home, marine, health, travel, accident and other forms of non-life insurance.

                </p>
                <p>Unlike life insurance policies, the tenure of general insurance policies is normally durational or for a given period of time. Most general insurance products are annual contracts. Also, general insurance is typically known as property and casualty insurance and it can be classified as follows: Motor Insurance, Fire and Special Perils, Burglary Insurance, Money-in-transit Insurance, Goods-in-transit Insurance, Aviation Insurance, Marine cargo and hull insurance, Insurance of employees, Fidelity guarantee Insurance, Product liability Insurance and Oil insurance, etc.</p>
                <p></p>

              </div>
           </div>

          <div class="row justify-content-between">


              <div class="col-md-12">

              <p>When looking to buy general insurance plans, here are some points which you should always keep in mind:</p>
               <p>1.	Choose a plan you require. If you have a vehicle you definitely need motor insurance. Travel insurance plans are relevant when you are going on a trip. So, understand your coverage requirements and then buy a plan to suit your needs.
               </p>

               <p></p>
                <p>
                  2.	Ensure that the coverage level is optimal to cover your financial losses. You should give adequate thought to this by taking into consideration the projected growth of your assets and liabilities, and the financial requirements that you foresee in the future.
                </p>
              <p></p>
                 <p>
                  3.	The premiums of the plan should be affordable so that you don’t face a financial strain in paying for the policy.
                 </p>

                 <p></p>
                 <p>
                  4.	Always compare before buying. There are different policies available in the market and each policy offers something better than the other. When you compare you would be able to find the best general insurance policy which not only provides extensive coverage but also comes at a lower premium.
                 </p>
                 <p></p>

                 <p>General insurance plans are the ideal solution for covering your financial risks. The different types of general insurance policies provide coverage for all possible types of financial risks that you might face.</p>
                <p></p>
                <p>Let us help you get started. Visit our website today at <a href="https://nsiainsurance.com/">https://nsiainsurance.com/</a>" for more information or give us a call on 09048418896. </p>


              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>
