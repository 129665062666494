
import {throwError as observableThrowError, Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import 'rxjs/Rx';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SendClaimsModel } from '../payment-integration/Models/claims-file-upload.model';
import { DefaultEndPointer } from '../payment-integration/Models/default-endpoints.model';


@Injectable({
  providedIn: 'root'
})
export class MainserviceService {
   endPoint: DefaultEndPointer = new DefaultEndPointer() ;
  private successMesage:string
  private baseUrl = environment.baseUrl + "/NSIAMobile/api";
  constructor(private http:HttpClient) { }

  setMessage(message) {
    this.successMesage = message
  }
  getMessage() {
    let temp = this.successMesage
    this.clearMessage()
    return temp
  }
  clearMessage() {
    this.successMesage = undefined
  }

  Postmotor(motorbody) {
    let serviceBody =
    {
      MotorClass: motorbody.MotorClass,
      duration: motorbody.carduration,
      HasTracker: motorbody.HasTracker,
      UsageType: motorbody.UsageType,
      VehicleValue: motorbody.VehicleValue,
      VehicleMake: motorbody.VehicleMake,
      VehicleModel: motorbody.VehicleModel,
      VehicleDate: motorbody.VehicleDate,
      VehicleType: motorbody.vehicletype,
      RegNo: motorbody.RegNo,
      ChasisNo: motorbody.ChasisNo,
      EngineNo: motorbody.EngineNo,
      InsuredName: motorbody.InsuredName,
      InsuredAddress: motorbody.InsuredAddress,
      filenameID: motorbody.filenameID,
      filetypeID: motorbody.filetypeID,
      filebase64ID: motorbody.filebase64ID,
      filenamedoc: motorbody.filenamedoc,
      filetypedoc: motorbody.filetypedoc,
      filebase64doc: motorbody.filebase64doc,
      Email: motorbody.Email,
      PhoneNo: motorbody.PhoneNo,
      UserType: motorbody.UserType,
      Surname: motorbody.Surname,
      Firstname: motorbody.Firstname,
      companyName: motorbody.companyName,
      premium: motorbody.premium,
      state: motorbody.state,
      lga: motorbody.lga

    }


    let headers = new HttpHeaders({
      'content-type': 'application/json'
    });
    let requestOptions = {
      headers: headers
    };
    let body = JSON.stringify(serviceBody);
    // return this.http.post(this.url.getUrl() + "api/BuyMotorWeb",body, requestOptions)
    return this.http.post(this.baseUrl + "/BuyMotorWeb", body, requestOptions).pipe(
      map(this.onSuccess),
      catchError(this.onError))
  }

  processpayment(paymentParam) {
    let serviceBody =
    {
      txnRef: paymentParam.txtref,
      payRef: paymentParam.pay,
      retRef: paymentParam.ret
    };
    let headers = new HttpHeaders({
      'content-type': 'application/json'
    });
    let requestOptions = {
      headers: headers
    };
    let body = JSON.stringify(serviceBody);
    //return this.http.post(this.url.getUrl() + "api/retrievedetail",body,requestOptions)
    return this.http.post<any>(this.baseUrl + "/retrievedetail", body, requestOptions).pipe(
      map(this.onSuccess),
      catchError(this.onError))
  }
  private onSuccess(res: Response) {
    return res.json();
  }

  private onError(res: Response) {
    return observableThrowError(res.json() || 'Server Error');
  }

  PostClaim(claimsbody) {
    let serviceBody =
    {
      email: claimsbody.email,
      phoneno: claimsbody.phoneno,
      bizclassId: claimsbody.businessclass,
      bizclass: claimsbody.businessclassname,
      policyno: claimsbody.policyno,
      registrationNo: claimsbody.registrationNo,
      location: claimsbody.location,
      description: claimsbody.description,
      dateofloss: claimsbody.dateofloss,
      filenameID: claimsbody.filenameID,
      filetypeID: claimsbody.filetypeID,
      filebase64ID: claimsbody.filebase64ID,
      filenamedoc: claimsbody.filenamedoc,
      filetypedoc: claimsbody.filetypedoc,
      filebase64doc: claimsbody.filebase64doc,
      filenameID2: claimsbody.filenameId2,
      filetypeID2: claimsbody.filetypeId2,
      filebase64ID2: claimsbody.filestringId2,
      filenamedoc2: claimsbody.filenamedoc2,
      filetypedoc2: claimsbody.filetypedoc2,
      filebase64doc2: claimsbody.filestringdoc2

    }
    console.log(serviceBody)
    let headers = new HttpHeaders({
      'content-type': 'application/json'
    });
    let requestOptions = {
      headers: headers
    };
    let body = JSON.stringify(serviceBody);
    return this.http.post(this.baseUrl + "/ReportWebClaim", body, requestOptions).pipe(
      //  return this.http.post("https://ips.nsiainsurance.com/NSIAMobile/api/ReportWebClaim",body, requestOptions)
      map(this.onSuccess),
      catchError(this.onError))
  }

  Postquote(quotebody) {
    let serviceBody =
    {
      Email: quotebody.email,
      PhoneNumber: quotebody.phoneno,
      InsuranceType: quotebody.businessclass,
      Code: quotebody.businessclass,

    }

    let headers = new HttpHeaders({
      'content-type': 'application/json'
    });
    let requestOptions = {
      headers: headers
    };
    let body = JSON.stringify(serviceBody);
    // return "https://ips.nsiainsurance.com/NSIAMobile/"
    return this.http.post(this.baseUrl + "/RequestQuote", body, requestOptions).pipe(
      // return this.http.post("https://ips.nsiainsurance.com/NSIAMobile/Quote/websitegeneral",body, requestOptions)
      map(this.onSuccess),
      catchError(this.onError))
  }


  postCode(codebody) {
    let serviceBody =
    {

      code: codebody

    }

    let headers = new HttpHeaders({
      'content-type': 'application/json'
    });
    let requestOptions = {
      headers: headers
    };
    let body = JSON.stringify(serviceBody);
    // return "https://ips.nsiainsurance.com/NSIAMobile/"
    //return this.http.post(this.url.getUrl() + "Quote/websitegeneral",body, requestOptions)
    return this.http.post(this.baseUrl + "/confirmdstvcode", body, requestOptions).pipe(
      map(this.onSuccess),
      catchError(this.onError))
  }


  sendClaimsEndpoint(sendClaim: SendClaimsModel ): Observable<any> {
    const url = `${this.endPoint.liveEndpoint}claims/send-claims`;
    return this.http.post<any>(url, sendClaim);

  }
}
