
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;">3 REASONS YOUR BUSINESS NEEDS KEYMAN ASSURANCE</p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/keymanassurance.jpg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>Most of us are familiar with how the life insurance policy of a family's breadwinner helps out in case of an unexpected occurrence. However, the fact that life insurance can play a similar role for businesses, in the form of <strong>Keyman assurance</strong>, is not so well known.
                 </p>
                 <p></p>
                 <p>When starting out a business, you have a lot on your plate, and it seems like you spend all your time working on the urgent things like; trying to get your product or service ready, hiring people, figuring out how to increase sales, paying the bills and so on. It's hard to find the time to consider something that isn’t seemingly urgent but that can be incredibly important, such as insurance – specifically, "key man" assurance.</p>
                 <p></p>
                 <p><strong>NSIA Keyman Assurance</strong> is a policy taken out by a business to insure their most valuable employees. It helps to think of it as life and critical illness cover for those who are crucial to your company’s success – whether that’s the CEO, your top sales guy, or someone with a specialist skill set. <strong>NSIA Keyman assurance</strong> policy helps a business take care of immediate or pending expenses, pay off debts, distribute money to investors or keep the business thriving until the key man is replaced.</p>
                 <p></p>
              </div>
           </div>


          <div class="row justify-content-between">


              <div class="col-md-12">
              <p><strong>Three main reasons to have Key man Assurance:</strong></p>
                <p></p>

            <p><strong>1.	To help your business thrive</strong><br/>Are you in the services industry? Key partners such as attorneys or physicians would surely affect your business performance, in the event of a tragedy. How fast can your key employees be replaced? Consider the revenue impact in the event of the demise of these individuals, and insure for that level of coverage to get your business back on track.</p>

            <p></p>

            <p><strong>2.	As a business continuity plan </strong>If there is no business continuation plan in place, who will take over the business? If it is a family business, is the spouse willing and able to resume the role? Perhaps they simply want to be bought out and not have anything to do with the business moving forward. This insurance policy could take care of the partner buy/sell issues when a tragedy occurs.</p>

            <p></p>

            <p><strong>3.	Future growth or financing needs </strong>Banks or financing institutions may require key man insurance coverage to be in place before extending any financing or credit to the company. This ensures the stability and longevity of the business plan, therefore reduces the risk for the lending institution.</p>

            <p></p>

                <p>To learn more about <strong>NSIA Keyman Assurance</strong>, please visit <a href="https://nsiainsurance.com/keyman">https://nsiainsurance.com/keyman</a> or call customer service on 09048418896.</p>

              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>