import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-burglary-insurance',
  templateUrl: './burglary-insurance.component.html',
  styleUrls: ['./burglary-insurance.component.css']
})
export class BurglaryInsuranceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
