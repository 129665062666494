import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PolicyDetailsModel } from '../payment-integration/Models/policy-details.model';
import { PolicyUserDetailsModel } from '../payment-integration/Models/policy-details-output.model';
import { SubRiskModel } from '../payment-integration/Models/sub-risk.model';
import { DefaultEndPointer } from '../payment-integration/Models/default-endpoints.model';
import { RenewPolicyInputModel } from '../payment-integration/Models/renew-policy.model';
import { NaicomModel } from '../Classes/naicom.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentIntegrationService {
   endPoint: DefaultEndPointer = new DefaultEndPointer() ;
  constructor(private http: HttpClient) { }

  checkIfPolicyExist(policy: PolicyDetailsModel): Observable<any> {
    const url = `${this.endPoint.liveEndpoint}policy/get-policy-details`;
    console.log(url);
    console.log(policy);
    return this.http.post<PolicyUserDetailsModel>(url, policy);
  }

  fetchAllsubRisk(): Observable<any> {
    const url = `${this.endPoint.liveEndpoint}subrisk/lite-sub-risk-combined`;
    return this.http.get<SubRiskModel[]>(url);
  }
  // fecthPolicyDetails()
  updateRenewTransactions(renew: RenewPolicyInputModel): Observable<any> {
    const url = `${this.endPoint.liveEndpoint}policy/renew-policy`;
    return this.http.put<any>(url, renew);
  }

  getLifeRiskList(): Observable<any> {
    const url =`${this.endPoint.liveEndpoint}mobile/life/get-all-risk`;
    return this.http.get<any>(url);
  }

  getLifeSubRisk(riskId: any): Observable<any> {
    const url =`${this.endPoint.liveEndpoint}mobile/life/risk/sub-risk/${riskId}`;
    return this.http.get<any> (url);
  }

  getLifeNaicomUniqueID(policyNo: string):Observable<any> {
    const url =`${this.endPoint.liveEndpoint}mobile/Naicom/life/${policyNo}`;
    return this.http.get<any> (url);
  }

  getRiskList(): Observable<any> {
    const url =`${this.endPoint.liveEndpoint}mobile/non-life/get-all-risk`;
    return this.http.get<any>(url);
  }

  getSubRisk(riskId: any): Observable<any> {
    const url =`${this.endPoint.liveEndpoint}mobile/non-life/risk/sub-risk/${riskId}`;
    return this.http.get<any> (url);
  }
  getNaicomUniqueID(policy: NaicomModel):Observable<any> {
    const url =`${this.endPoint.liveEndpoint}mobile/Naicom/non-life`;
    return this.http.post<any>(url, policy);
  }

}
