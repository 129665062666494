<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
  </section>
  <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
    <div class="row">
       <div class="col-lg-12 g-mb-30">
          <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
             <h3 class="mb-0">
                <strong>
                   <p style="text-align:center;">NSIA INSURANCE GOES PAPERLESS!</p>
                </strong>
             </h3>
             <br/>
             <div class="row justify-content-between">
                <!-- About Image -->
                <div class="col-md-3 col-lg-4">
                   <img class="img-fluid" src="assets/img/dec/Paperless.jpeg" alt="Image Description">
                </div>
                <!-- End About Image -->
                <div class="col-md-8 col-lg-8">
                   <!-- About Info -->
                   <p>At NSIA Insurance, we remain committed to improving your experience with us and providing you with quality service. It is for this reason that we are consistently working on innovations to make this a reality, which is why we are going paperless.
                   </p>
                   <p></p>
                   <p>"Going Paperless" means that we are adapting most of our paper documents to a digital version.  It is not possible for us to eliminate paper completely, but we are changing our processes so that the majority of our documents are now sent digitally rather than using paper. You can now complete product proposal forms, direct debit mandate form and make premium payment with just a CLICK to help reduce our organization’s carbon footprint.</p>
                   <p></p>
                </div>
             </div>
             <div class="row justify-content-between">
                <div class="col-md-12">
                   <p></p>
                   <p><strong>=>	Life – <a href="https://nsiainsurance.com/proposal-form">https://nsiainsurance.com/proposal-form</a></strong><br/>
                   </p>
                   <p></p>
                   <p><strong>=> Direct Debit Mandate  – <a href="https://nsiainsurance.com/debit-mandate-form">https://nsiainsurance.com/debit-mandate-form </a></strong><br/>
                   </p>
                   <p></p>
                   <p><strong>=> Householder Insurance Policy  – <a href="https://nsiainsurance.com/house-holder-insurance">https://nsiainsurance.com/house-holder-insurance </a></strong><br/>
                   </p>
                   <p></p>
                   <p><strong>=> Non-Life Proposal Forms   – <a href="https://nsiainsurance.com/general-proposal-form">https://nsiainsurance.com/general-proposal-form </a></strong><br/>
                   </p>
                   <p>You can also update your personal information with us <a href="https://nsiainsurance.com/customer-form">Here</a></p>
                   <p></p>
                   <p>Please be reminded that NSIA Insurance Limited operates a <strong>NO CASH POLICY</strong> and under no circumstance will we instruct you to pay your premium/contribution into a personal account of any of our staff members or agents. Kindly notify us if you receive such a request by giving us a call on 09048418896. </p>
                   <p></p>
                   <p><strong>KINDLY NOTE THAT OUR BANK ACCOUNT DETAILS HAVE NOT CHANGED. WE WILL NOT CHANGE OUR BANK DETAILS VIA EMAIL.</strong>  Make sure to check account details with us in person or through our authorized channel(s) if in doubt. NSIA Insurance will not be held responsible for accidental payments made to third-party accounts. </p>
                   <p></p>
                   <p>You can also follow us on Facebook and LinkedIn: <strong>NSIA Insurance Limited</strong>, Instagram and Twitter: <strong>@nsia_insure.</strong></p>
                   <p></p>
                   <p>Thank you for choosing NSIA Insurance</p>
                </div>
             </div>
          </div>
          <!-- End About Info -->
       </div>
    </div>
  </div>