<ng-progress [color]="'green'"></ng-progress>



<div class="container g-pt-100 g-pb-70">
  <div class="row">
    <div class="col-lg-9 g-mb-30">
      <section class="g-bg-gray-light-v5 g-py-20">
        <form novalidate #f="ngForm" (ngSubmit)="OnFormSubmit()">
          <div class="container g-py-100" style="margin-top:-50px">
            <div
              class="u-shadow-v19 g-max-width-645 g-brd-around g-brd-gray-light-v4 text-center rounded mx-auto g-pa-30 g-pa-50--md">
              <span class="u-icon-v3 u-icon-size--lg g-color-white g-bg-primary rounded-circle g-pa-15 mb-5">
                <svg width="30" height="30" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 497.5 497.5"
                  style="enable-background:new 0 0 497.5 497.5;" xml:space="preserve">
                  <g>
                    <path
                      d="M487.75,78.125c-13-13-33-13-46,0l-272,272l-114-113c-13-13-33-13-46,0s-13,33,0,46l137,136
                                                     c6,6,15,10,23,10s17-4,23-10l295-294C500.75,112.125,500.75,91.125,487.75,78.125z"
                      fill="#fff" />
                  </g>
                </svg>
              </span>

              <div class="mb-6">
                <h2 class="mb-4">
                  <p>Whistle Blower Form!</p>
                </h2>
                <div class="row">
                  <div class="col-lg-12">

                    <div class="form-group g-mb-20">
                      <label class="g-mb-10">
                        <p>Your Name</p>
                      </label>
                      <input id="fullname" name="fullname" class="form-control form-control-md rounded-0" type="email" placeholder="Required"
                        [(ngModel)]="formData.fullname" required>
                    </div>
                  </div>
                  <div class="col-lg-12">

                    <div class="form-group g-mb-20">
                      <label class="g-mb-10">
                        <p>Email</p>
                      </label>
                      <input id="email" name="email" class="form-control form-control-md rounded-0" type="email"
                        placeholder="Required" [(ngModel)]="formData.email" required>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group g-mb-20">
                      <label class="g-mb-10">
                        <p>Phone Number</p>
                      </label>
                      <input id="phoneno" name="phoneno" class="form-control form-control-md rounded-0" type="text"
                        placeholder="Required" [(ngModel)]="formData.phoneno" required>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group g-mb-20">
                      <label class="g-mb-10">
                        <p>Name Of Officer</p>
                      </label>
                      <input id="officer" name="officer" class="form-control form-control-md rounded-0" type="text"
                        placeholder="Required" [(ngModel)]="formData.officer" required>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group g-mb-20">
                      <label class="g-mb-10">
                        <p>Complaint Type</p>
                      </label>
                      <select  [(ngModel)]="formData.complaintType" name="complaintType" id="complaintType" class="form-control form-control-md rounded-0" placeholder="required" required>

                        <option value="Customer Fraudulent Behaviour">Customer Fraudulent Behaviour</option>
                        <option value="Staff Misconduct">Staff Misconduct</option>
                        <option value="Service Provider Malpractice">Service Provider Malpractice</option>
                        <option value="Other">Other</option>
                      </select>

                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group g-mb-20">
                      <label class="g-mb-10">
                        <p>Nature of Conduct</p>
                      </label>
                      <input id="conduct" name="conduct" class="form-control form-control-md rounded-0" type="text"
                        placeholder="Required" [(ngModel)]="formData.conduct" required>
                    </div>
                  </div>
                </div>


                <div class="form-group g-mb-20">
                  <label class="g-mb-10">
                    <p>Remarks</p>
                  </label>
                  <textarea class="form-control form-control-md rounded-0" id="remarks" name="remarks" rows="6"
                    [(ngModel)]="formData.remarks"></textarea>

                </div>
              </div>

              <p style="font-size:10px;">In compliance with NDPR requirements, by completing and submitting this form, you have expressed your consent to NSIA Insurance to receive your data which includes bio data, to enable NSIA maintain effective communication, send promotional update, contact you for research purpose and use the data in line with the policy terms.
                                    <br/>The data collection may be via hard copies or web forms inputs, validated via JWT and the data will not be accessed by any third party without your consent.
                                    <br/>
                                    Any breach of this policy can be addressed legally within the year the breach was reported.</p>
              <button class="btn u-btn-primary g-font-size-12 text-uppercase g-py-12 g-px-25" type="submit"
                [disabled]="!f.valid"> Submit </button>
            </div>
          </div>
        </form>
      </section>
    </div>
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <app-sidebar></app-sidebar>
      <!-- End Fast Facts -->
    </div>
    <!-- End Sidebar -->
  </div>
</div>
