
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;"><strong>HOW TO PREVENT DISTRACTIONS WHILE DRIVING </strong></p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/image_october_23.jpg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>The number one reason for collisions is distracted driving. The advancements in technology over the years has made it increasingly harder to stay off the phone. Most new cars have screens where you can see a text or call come in which makes it harder to resist. Here are a few tips to help avoid distractions:</p>
                 <p></p>

              </div>
           </div>

          <div class="row justify-content-between">


              <div class="col-md-12">
               <p><strong>1.	Phones </strong><br/>
                  Calling, texting, or switching a song on your phone can be dangerous. Nowadays, you see electronic billboards on the side of the highways say “Don’t Text and Drive.” It has become too common that drivers do not take the time to pull over to answer a call or text. If it is necessary to make a call be sure to use the hands-free option in your vehicle, if this is not an option then take the time to pull over and make the call.
                 </p>

                 <p></p>
                  <p><strong>2.	Music </strong><br/>
                    Most drivers enjoy listening to the radio or music when on the road. As it turns out music can be a distraction just as much as holding a conversation with a passenger. When your favorite song comes on you may not have your full attention on the road and listening to fast paced music may lead to you speeding. You are at a higher risk of an accident when you are speeding due to the fact that it is harder to stop when needed. It is a good idea to set up your music before you start driving. This way you can focus on getting to your destination instead of adjusting controls.
                  </p>
             <p></p>
             <p>Similarly, eating or drinking while driving may cause you to lose focus and lead to a collision. It is advisable to finish eating or drinking before you take off on your journey. In some cases, having pets in the vehicle causes a major distraction while driving. If you absolutely need to have a pet in the car, ensure you keep them in the back seat. </p>
             <p></p>
             <p>In conclusion, being distracted when driving can lead to dangerous and even fatal events. When behind the wheel be responsible by giving your undivided attention to the road. Make sure you are keeping your eyes on the road, not taking your hands off the wheel and keeping your mind on track when operating a motor vehicle. Take the right step and encourage others to do the same.</p>

            <p></p>
            <p>Drive safe!</p>

              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>