import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PaymentIntegrationRoutingModule } from './payment-integration-routing.module';
import { CommonModule } from '@angular/common';


@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    CommonModule,
    ReactiveFormsModule,
    PaymentIntegrationRoutingModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule
  ]
})
export class PaymentIntegrationModule { }
