<section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px">
  <div class="container" >
    <div class="d-sm-flex text-center">
      <div class="align-self-center">
        <h2 class="h3 g-font-weight-300 w-100 g-mb-10 g-mb-0--md" ><p>NSIA Annuity Plans</p></h2>
      </div>


    </div>
  </div>
</section>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item">
              <a class="u-link-v5 g-color-text g-pr-10" routerLink="/lifeinsurance">Life Insurance</a>
              <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
            </li>
            <li class="list-inline-item g-color-primary">
                  <span>Annuity Plans</span>
                </li>

      <li class="list-inline-item ml-auto">
        <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a>
      </li>
    </ul>
  </div>
</section>
<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
      <div class="row">
        <div class="col-lg-9 g-mb-30">
              <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
                      <h4><strong><p>NSIA Annuity Plans</p></strong> </h4>

            <p>NSIA Annuity product provides a sustainable vehicle through which policy holders can save towards a <strong>deferred regular income in retirement.</strong>  It also serves as an avenue to <strong> convert lump sums</strong> into immediate regular income at retirement (i.e. pension).</p>
            <p></p>
            <p>The product is suitable for.</p>
            <p></p>
       <ul>
          <li> <p>Those who want to make regular contributions for a certain period in return for a future stream of income (pension).</p></li>
          <li><p>Those who have a lump sum available and want to use it to secure regular income (pension) at old age.</p></li>
          <li><p>Employees who have opted for annuity payment under the Pension Reform Act 2004.</p></li>
       </ul>
       <p><strong>Key Features</strong></p>
       <p></p>
       <ul>
              <li><p>Policyholder chooses either an immediate annuity or deferred annuity</p></li>
              <li><p>For a deferred annuity, policyholder pays regular premium for a period (say, till age 50/60) and starts to receive regular pension (annuity) from NSIA Life at a later date</p></li>
              <li><p>For immediate annuity, policyholder pays a lump sum to NSIA Life today and starts to collect regular pension (income) from the company thereafter (i.e in retirement).</p></li>
              <li><p>Policyholder decides on a target income (annuity) level in retirement which can be flat or increased at an annual percentage of say 5%.</p></li>
              <li><p>Optional retirement ages of 45, 50, 55, 60 or 65 are available</p></li>
           </ul>
           <p></p>
           <p><strong>Retirement Benefit</strong></p>
           <p></p>
           <ul>
                  <li><p>Payment of regular income (pension/annuity) to the policyholder from retirement age till death occurs.</p></li>
                  <li><p>Payment is guaranteed for a number of years, in any event (for a period of 5 or 10 years).</p></li>

              </ul>
               <p></p>
              <p><strong>Retirement Benefit</strong></p>
              <p></p>

              <ul>
                  <li><p>Payment of regular income (pension/annuity) to the policyholder from retirement age till death occurs.</p></li>
                  <li><p>Payment is guaranteed for a number of years, in any event (for a period of 5 or 10 years).</p></li>

              </ul>
              <p></p>
              <p><strong>Retirement Benefit</strong></p>
              <p></p>

              <ul>
                  <li><p>Payment of regular income (pension/annuity) to the policyholder from retirement age till death occurs.</p></li>
                  <li><p>Payment is guaranteed for a number of years, in any event (for a period of 5 or 10 years).</p></li>

              </ul>
              <p></p>
              <p><strong> Death Benefit</strong></p>

              <p></p>

              <p>The following benefits are paid on death:</p>
              <p></p>
              <ul>
                  <li><p>Refund of all premiums paid for a deferred annuity plus interest if death occurs before the maturity/retirement date.</p></li>
                  <li><p>If death occurs in retirement before the guaranteed period of say 5 or 10 years, the difference between total income payable for the guaranteed period and total income drawn up to the time of death would be paid to dependants</p></li>

              </ul>

              <p></p>

              <p><strong> Discontinuation</strong></p>

              <p></p>

              <p>The following benefits are paid on death:</p>
              <p></p>
              <ul>
                  <li><p>On discontinuation of a deferred annuity plan within three years of its commencement, the premiums paid to date will be refunded with interest but subject to certain penalties. No penalty will be imposed if discontinuation takes place after three (3) years.</p></li>
                  <li><p>No benefit is paid on discontinuation of an immediate annuity</p></li>

              </ul>

                      </div>
                      </div>


        <!-- Sidebar -->
        <div class="col-lg-3 g-mb-30">
          <!-- Links -->
          <app-sidebar></app-sidebar>
          <!-- End Fast Facts -->
        </div>
        <!-- End Sidebar -->
      </div>
    </div>

