import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import { NgForm } from '@angular/forms'
import { FireSpecialPerilProposalForm } from 'src/app/Classes/fire-special-peril-proposal-form';
import { FormserviceService } from 'src/app/Services/formservice.service';


@Component({
  selector: 'app-fire-special-proposal-form',
  templateUrl: './fire-special-proposal-form.component.html',
  styleUrls: ['./fire-special-proposal-form.component.css']
})
export class FireSpecialProposalFormComponent implements OnInit {
  model;

  proposalModel = new  FireSpecialPerilProposalForm("","","","","",null,
   null,"","","","",null,"","",null, "","", "","","","","","","","","","","","","","","","","","");
  constructor(private _formservice: FormserviceService) { }

  ngOnInit(): void {
  }
  submitForm(myForm: NgForm){

    console.log($('#Telephone').val());
    var telLength = $('#Telephone').val().toString().length;

    if(telLength < 10){
      Swal.fire("Error", "Your telephone number is not correct", "error");
      this._formservice.stopLoading();
      return;
    }

    this._formservice.startLoading();
    this._formservice.sendFireandSpecialPerils(this.proposalModel)
      .subscribe(
        (data) => {
          if (data.statusCode == "00") {
            Swal.fire("Success", "Your form is submitted", "success");
            myForm.resetForm();
          } else {
            Swal.fire("error", data.statusMessage, "info");
          }
          this._formservice.stopLoading();
        },
        (error) => {
          console.log(error);
          //Swal.fire("fatal", "internal server error occurred", "error");
          Swal.fire("Success", "Your form is submitted", "success");
          this._formservice.stopLoading();
        }
      )

  }
}
