
<ul class="list-unstyled g-hidden-md-down mb-5">


  <li class="my-1">
      <!-- <a class="d-block u-link-v5 g-brd-left g-brd-3 g-brd-transparent g-brd-primary--hover g-brd-primary--active g-color-main g-color-primary--hover g-color-primary--parent-active g-bg-secondary g-font-weight-600 g-px-20 g-pl-25--hover g-pl-25--active g-py-12" routerLink="/getquote">
       Get quote
        <i class="align-middle float-right fa fa-graduation-cap u-line-icon-pro"></i>
      </a> -->
      <app-quote></app-quote>
    </li>
    <li class="my-1">
        <a class="active d-block u-link-v5 g-brd-left g-brd-3 g-brd-transparent g-brd-primary--hover g-brd-primary--active g-color-main g-color-primary--hover g-color-primary--parent-active g-bg-secondary g-font-weight-600 g-px-20 g-pl-25--hover g-pl-25--active g-py-12"  routerLink="/buymotor">
          Buy Car Insurance
          <i class="align-middle float-right fa fa-car u-line-icon-pro"></i>
        </a>
      </li>
  <li class="my-1">
    <a style="cursor: pointer;" class="d-block u-link-v5 g-brd-left g-brd-3 g-brd-transparent g-brd-primary--hover g-brd-primary--active g-color-main g-color-primary--hover g-color-primary--parent-active g-bg-secondary g-font-weight-600 g-px-20 g-pl-25--hover g-pl-25--active g-py-12" (click)="open()">
      Download Certificate
      <i class="align-middle float-right fa fa-certificate u-line-icon-pro"></i>
    </a>
  </li>


  <li class="my-1">
    <a class="d-block u-link-v5 g-brd-left g-brd-3 g-brd-transparent g-brd-primary--hover g-brd-primary--active g-color-main g-color-primary--hover g-color-primary--parent-active g-bg-secondary g-font-weight-600 g-px-20 g-pl-25--hover g-pl-25--active g-py-12" routerLink="/reportclaim">
      Report Claim
      <i class="align-middle float-right fa fa-file u-line-icon-pro"></i>
    </a>
  </li>
  <li class="my-1">
        <!-- <a class="d-block u-link-v5 g-brd-left g-brd-3 g-brd-transparent g-brd-primary--hover g-brd-primary--active g-color-main g-color-primary--hover g-color-primary--parent-active g-bg-secondary g-font-weight-600 g-px-20 g-pl-25--hover g-pl-25--active g-py-12" href="https://www.quickteller.com/NSIAUnreg?option=" >
          Quick teller
          <i class="align-middle float-right fa fa-credit-card u-line-icon-pro"></i>
        </a> -->

        <div id="accordion-12" class="u-accordion u-accordion-color-primary" role="tablist" aria-multiselectable="true">
            <!-- Card  <div style="padding-left : 5px; background-color:#efb225;">-->
            <div class="card g-brd-none rounded-0 g-mb-15">
              <div id="acc-12-heading-01" class="u-accordion__header g-pa-0"  role="tab">
                <h6 class="mb-0" >
                  <a class="d-flex g-color-main g-text-underline--none--hover g-brd-around g-brd-gray-light-v4 g-rounded-5 g-pa-10-15" href="#acc-12-body-01" data-toggle="collapse" data-parent="#acc-12"  aria-controls="accn-12-body-01">
                    <span class="u-accordion__control-icon g-mr-10">
                      <i class="fa fa-angle-down"></i>
                      <i class="fa fa-angle-up"></i>
                    </span>
                   <p class="d-block  g-brd-left g-brd-3 g-brd-transparent g-brd-primary--hover g-brd-primary--active g-color-main g-color-primary--hover g-color-primary--parent-active g-bg-secondary g-font-weight-600 g-px-20 g-pl-25--hover g-pl-25--active g-py-12"><b>Payment Options</b></p>
                  </a>
                </h6>
              </div>
              <div id="acc-12-body-01" class="collapse" role="tabpanel" aria-labelledby="acc-12-heading-01" data-parent="#acc-12">
                <div class="u-accordion__body g-color-gray-dark-v5">
                        <ul>
                            <li class="my-1">
                                <a class="d-block u-link-v5 g-brd-left g-brd-3 g-brd-transparent g-brd-primary--hover g-brd-primary--active g-color-main g-color-primary--hover g-color-primary--parent-active g-bg-secondary g-font-weight-600 g-px-20 g-pl-25--hover g-pl-25--active g-py-12" href="https://www.quickteller.com/NSIAUnreg?option=" >
                                  Quickteller
                                  <i class="align-middle float-right fa fa-credit-card u-line-icon-pro"></i>
                                </a>
                              </li>
                            <li class="my-1"><a class="d-block u-link-v5 g-brd-left g-brd-3 g-brd-transparent g-brd-primary--hover g-brd-primary--active g-color-main g-color-primary--hover g-color-primary--parent-active g-bg-secondary g-font-weight-600 g-px-20 g-pl-25--hover g-pl-25--active g-py-12" href="#">Payment via NSIA Website</a></li>
                            <li class="my-1"><a class="d-block u-link-v5 g-brd-left g-brd-3 g-brd-transparent g-brd-primary--hover g-brd-primary--active g-color-main g-color-primary--hover g-color-primary--parent-active g-bg-secondary g-font-weight-600 g-px-20 g-pl-25--hover g-pl-25--active g-py-12" href="#">Transfer into our Access bank account</a></li>
                            <li class="my-1"><a class="d-block u-link-v5 g-brd-left g-brd-3 g-brd-transparent g-brd-primary--hover g-brd-primary--active g-color-main g-color-primary--hover g-color-primary--parent-active g-bg-secondary g-font-weight-600 g-px-20 g-pl-25--hover g-pl-25--active g-py-12" href="#">Payment via cheque</a></li>
                            <li class="my-1"><a class="d-block u-link-v5 g-brd-left g-brd-3 g-brd-transparent g-brd-primary--hover g-brd-primary--active g-color-main g-color-primary--hover g-color-primary--parent-active g-bg-secondary g-font-weight-600 g-px-20 g-pl-25--hover g-pl-25--active g-py-12" href="#">Transfer to our Access bank from Internet/Mobile banking</a></li>
                        </ul>
                </div>
              </div>
    </div>
    </div>

      </li>

</ul>




<!-- End Links -->

<!-- Helpful Resources -->
<!-- <article class="g-bg-primary g-hidden-md-down g-px-25 g-py-30 mb-5"> -->
  <!-- <div class="media align-items-center mb-2">
    <div class="d-flex g-ml-minus-4 mr-2">
      <span class="d-inline-block text-center g-font-size-35 g-color-white"><i class="icon-finance-082 u-line-icon-pro"></i></span>
    </div>
    <div class="media-body">
      <h4 class="h6 g-color-white text-uppercase"><b>Useful links</b></h4>
    </div>
  </div> -->

  <!-- Links -->
  <!-- <ul class="list-unstyled mb-0">
    <li><i class="g-color-white mr-2 fa fa-angle-right"></i><a class="u-link-v5 g-color-white g-pl-7--hover" routerLink="/aboutus">About us</a></li>
    <li><i class="g-color-white mr-2 fa fa-angle-right"></i><a class="u-link-v5 g-color-white g-pl-7--hover" routerLink="/financialreport">Financial statements</a></li>
    <li><i class="g-color-white mr-2 fa fa-angle-right"></i><a class="u-link-v5 g-color-white g-pl-7--hover" routerLink="/career">Careers</a></li>
  </ul> -->
  <!-- End Links -->
<!-- </article> -->
<!-- End Helpful Resources -->

<!-- Fast Facts -->
<!-- <h3 class="h4 mb-3">Latest News</h3>

<ul class="list-unstyled">
  <li class="g-brd-y g-brd-gray-light-v3 py-3">

    <p>Nsia latest news post</p>
  </li>
  <li class="g-brd-bottom g-brd-gray-light-v3 py-3">

        <p>Nsia latest news post</p>
  </li>
  <li class="g-brd-bottom g-brd-gray-light-v3 py-3">
        <p>Nsia latest news post</p>
  </li>
</ul> -->
