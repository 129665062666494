
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;">DEBUNKING LIFE INSURANCE MYTHS</p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/debunking.jpg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>There are a lot of misconceptions about insurance, especially in this part of the world. This article will throw some light on these myths and pave a way to better living.
                 </p>
                 <p></p>
                 <p><strong>1. “I do not need life insurance because I am not the breadwinner of my family”</strong><br/>

                 </p>

                  <p></p>

                  <p>This statement is mostly made by unmarried people and stay-at-home mothers. Single people probably do not start thinking of purchasing life insurance until there is the prospect of starting a family. You should take out a life insurance policy even before you have dependents. It is even more advisable as life insurance policies are cheaper when you’re young and healthy. </p>

              </div>
           </div>


          <div class="row justify-content-between">


              <div class="col-md-12">

                <p></p>

            <p>Stay-at-home mothers have the idea that they do not need life insurance because they do not earn a monthly salary. They also think that if they pass away, there would be minimal issues because their husbands are financially in charge. A family with only one working parent may think they only have one income to protect because duties which are usually overlooked in terms of monetary value. However, should the stay-at-home parent suddenly pass, there are a number of costs that the family would incur, just to handle the tasks that the stay-at-home parent was accomplishing on a daily basis. In such a case, the breadwinner may have to rely on child care or housekeepers to cater for such needs, which is not cheap. </p>

            <p><strong>2. “I would rather invest my money than purchasing a life insurance”</strong><br/>
               <p></p>
              <p>Before you invest your money, it is important to know that you won't make profit until you're able to break-even. It is advisable to have a form of life insurance coverage. It will be a huge risk to depend solely on your investment, especially when you have a family that is dependent on you. </p>
              <p><strong>3. “My employer-provided insurance is enough for me”</strong><br/>
                <p></p>
                <p>Yes, your employer-provided life insurance is at a low rate or even free but the truth is that the value of your policy may not be enough for you. It may not be extended to cover your spouse and children in case of an unfortunate event. Also, what happens in a case where you lose your job, change jobs or if your employer goes out of business? These are things to think about in other to avoid being stranded in situations where you need an insurance policy the most. </p>
          <p>Visit our website today at <a href="https://nsiainsurance.com/lifeinsurance">https://nsiainsurance.com/lifeinsurance</a> or call customer service on 09048418896, and let us get you a life insurance plan to help secure the future of your loved ones.</p>


              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>