export class GroupPersonalAccidentProposalForm {
  constructor(
    public NameOfProposal: string,
    public AddressOfProposal:string,
    public TradeOrBusiness:string,
    public EmailAddress:string,
    public Telephone:string,
    public SecondTelephone:string,
    public contact_person:string,
    public StartDate:Date,
    public EndDate: Date,
    public NatureOfContract:string,
    public SupervisoryRole:string,
    public WorkingRole:string,
    public Others:string,
    public SportEngage:string,
    public DeathBenefit:string,
    public PermanentDisability:string,
    public TemporaryDisablement:string,
    public MedicalExpenses:string,
    public limitcover:string,
    public additionalCover:string,
    public applicant:string,
    public legalRepresentative:string



  ) {

  }
}
