import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-third-party-motor-vs-comprehensive-motor',
  templateUrl: './third-party-motor-vs-comprehensive-motor.component.html',
  styleUrls: ['./third-party-motor-vs-comprehensive-motor.component.css']
})
export class ThirdPartyMotorVsComprehensiveMotorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
