<section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px">
  <div class="container">
    <div class="d-sm-flex text-center">
      <div class="align-self-center">
        <h2 class="h3 g-font-weight-300 w-100 g-mb-10 g-mb-0--md">
          <p>NSIA Deferred Annuity Plan </p>
        </h2>
      </div>


    </div>
  </div>
</section>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/lifeinsurance">Life Insurance</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item g-color-primary">
        <span>NSIA Deferred Annuity Plan</span>
      </li>

      <li class="list-inline-item ml-auto">
        <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10"
          routerLink="/lifequote">Get quote</a>
      </li>
    </ul>
  </div>
</section>
<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
    <div class="col-lg-9 g-mb-30">
      <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
        <h4><strong>
            <p>NSIA Deferred Annuity Plan</p>
          </strong> </h4>

        <p>NSIA Deferred Annuity plan provides a sustainable vehicle through which policyholders can save towards a regular income
        at retirement. The plan is designed for you to contribute according to your preferred frequency of payment for
        retirement and upon maturity, the savings accumulated can be collected as a lump sum or used to buy an Immediate Annuity
        plan.
        <br>
        The product is suitable for:
</p>
        <ul>
          <li>Those who want to make regular contributions for a certain period in return for a future stream of income (pension).</li>
          <li>Those who have a lump sum available and want to use it to secure regular income (pension) later at old age.</li>
          <li>Self –employed person wishing to save towards retirement</li>
          <li>Employed individual who are willing to augment income from their employer(s) sponsored Retirement Savings Account (RSA).</li>
          <li>Young couples who intend to save toward their retirement</li>
        </ul>
        <p></p>
        <p><strong>Key Features</strong></p>
        <p></p>
        <ul>
          <li>Deferred annuity have an accumulation phase and an income phase</li>
          <li>Premiums are paid during the deferment/accumulation period or as a lump sum to receive regular annuity(pension) from NSIA
          Life immediately after retirement</li>
          <li>Optional retirement ages are available from age 50 to age 65</li>
          <li>Provision of financial security for dependents in the event of your demise</li>
          <li>Interest is credited on the premium paid during the accumulation period</li>
          <li>Upon survival to the end of the policy term, the accumulated amount in the policy holder’s account is paid automatically
          into the bank account provided by the Policy holder at inception of policy, policyholder has the option to convert
          accumulated fund into an immediate annuity or collect a lump sum payment.</li>
          <li>Policyholders can choose income payable for their lifetime after the accumulation period for annuity payment subject
          to a minimum of a nil, five (5), or ten (10) years guaranteed period.</li>
          <li>No medical examination required.</li>
        </ul>
        <p></p>
        <p><strong>Age at Inception</strong></p>
        <ul>
          <li>Minimum age at inception is 25years</li>
          <li>Maximum age at inception is 64years.</li>
        </ul>
        <p></p>

        <p><strong>Retirement Benefit</strong></p>
        <p></p>
        <ul>
          <li>
            Payment of regular income (pension/annuity) to the policyholder from retirement age till death occurs
          </li>
          <li>
            Death Benefit Payment is guaranteed for a number of years, in any event for a period of 5 or 10 years where this option
            is chosen
          </li>


        </ul>
        <p></p>
        <p><strong>Death Benefit</strong></p>
        <p>The following benefits are paid on death:</p>
        <ul>
          <li>
            Refund of all premiums paid for the deferred annuity plus interest if death occurs before the maturity/retirement date
          </li>
          <li>
            If death occurs in retirement during the guaranteed period of say 5 or 10 years, the present value of guaranteed
            outstanding annuity is paid to the annuitant’s beneficiary
          </li>
          <li>Death benefit is payable if the option is chosen by the policyholder.</li>
        </ul>
        <p></p>
        <p><strong>Discontinuation</strong></p>
        <p>On discontinuation of a deferred annuity plan within three years of its commencement, the premiums paid to date will be
        refunded with interest but subject to certain penalties. No penalty will be imposed if discontinuation takes place after
        three (3) years.</p>
        <p></p>
        <p><strong>Immediate Annuity Options</strong></p>
        <ul>
          <li>
           <p><strong>Option 1: Level Annuity in payment with no guarantee</strong></p>
           <ul>
             <li>Annuity payments remain at the same level for life.</li>
             <li>If death occurs the annuity payment expires immediately without any further regular/lump sum payment.</li>
           </ul>
          </li>
          <li>
            <p><strong>Option 2: Level Annuity in payment; guaranteed for 5 years</strong></p>
            <ul>
              <li>Annuity payments remain at the same level for life.</li>
              <li>If death occurs in the first 5 years, annuity will continue to be paid to the named beneficiary(s) until the 5 years
              expire or a lump sum equal to the discounted value of the balance of the guaranteed period annuity becomes payable to
              the named beneficiary(s)</li>
            </ul>
          </li>
          <li>
            <p><strong>Option 3: Level Annuity in payment; guaranteed for 10 years</strong></p>
            <ul>
              <li>Annuity payments remain at the same level for life.</li>
              <li>If death occurs in the first 10 years, annuity will continue to be paid to the named beneficiary(s) until the 10 years
              expire or a lump sum equal to the discounted value of the balance of the guaranteed period annuity becomes payable to
              the named beneficiary(s).</li>
            </ul>
          </li>
        </ul>
        <p><strong>Waiting Period</strong> </p>

        <p></p>

        <p>There is a month waiting period during which a client cannot claim on non-accidental death. However, where accidental
        death occurs during this period, total premiums paid would be returned</p>
        <p></p>



      </div>
    </div>


    <!-- Sidebar -->
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <app-sidebar></app-sidebar>
      <!-- End Fast Facts -->
    </div>
    <!-- End Sidebar -->
  </div>
</div>
