<div
  class="g-min-height-300 g-flex-centered g-bg-img-hero g-bg-pos-top-center g-bg-size-cover g-pt-80"
>
  <div class="container g-pos-rel g-z-index-1">
    <img
      style="display: block; max-width: 82%; margin: auto; margin-top: 50px"
      src="../../../assets/img/banners/campaign-plan-b.jpg"
      alt=""
    />
  </div>
</div>

<div class="container g-pt-100 g-pb-70" style="margin-top: -50px">
  <div class="row">
    <div class="col-lg-9 g-mb-30">
      <div _ngcontent-wur-c135="">
        <div>
          <p><strong>PLAN i IS THE NEW PLAN B</strong></p>
          <p>
            We’re starting 2022 on a great note, with the launch of a new
            campaign: <strong>“Plan i is the new plan B”.&nbsp;</strong>
          </p>
          <p>
            The purpose of this campaign is to highlight the importance of
            having a backup plan, which is often referred to as plan B, but in
            this case, a Plan i. It is a play on the first letter of the word
            <i>insurance</i>, as a replacement for the B in Plan B. Meaning
            that, for maximum guarantee and peace of mind, your plan B should be
            insurance.
          </p>
          <p>
            The idea is to juxtapose the usual contingency plans we all make,
            with a Plan i, showing how insurance is more reliable than a plan B
            could ever be.&nbsp;
          </p>
          <p>
            Simply put,
            <strong>“Insurance is Life’s Backup Plan”.</strong>
          </p>
          <p>
            To further intensify the reach of the campaign, the Brand will be
            present on traditional media, e.g. radio, outdoor and the likes, in
            the locations where NSIA offices operate; Lagos, Abuja, Ibadan,
            Warri, Onitsha, Port Harcourt, Kaduna and Kano.&nbsp;
          </p>
          <p>There are 5 elements of the radio campaign:</p>
          <p>1. Spot Adverts (April 4 – May 11) – 6 weeks</p>
          <p>2. Time Check (May 2 – July 6) – 10 weeks</p>
          <p>3. Hypes (June 14 – July 19) – 6 weeks</p>
          <p>4. Live Appearances (July 5 – July 26) – 4 weeks</p>
          <p>5. Sponsorship (July 18 – October 3) – 12 weeks</p>
          <p>&nbsp;</p>
          <p>
            The first 2 elements focus on creating increased awareness for the
            Brand, after which the hypes will dwell more on Plan i as life’s
            backup plan. Then there will be Live Appearances featuring
            #NSIApeople which will focus on its business operations and product
            offerings.
          </p>
          <p>
            We kicked off our Radio Campaign on Monday, April 4, with the first
            element: Spot Adverts!&nbsp;
          </p>
          <p>
            Spot Advert is a multimedia advertisement that airs at a specific
            time. It usually airs between radio programs or during breaks, also
            known as the spot. This will help us to broaden our reach to a wide
            range of potential consumers, who listen to the radio.&nbsp;
          </p>
          <p>Take a look at the schedule:</p>
          <!-- <figure class="table">
                  <table>
                    <tbody>
                      <tr>
                        <td rowspan="2">
                          <strong>Location/Radio Station</strong>
                        </td>
                        <td colspan="3">
                          <strong>Airing Days &amp; Times (am | pm)</strong>
                        </td>
                      </tr>
                      <tr>
                        <td><strong>Monday</strong></td>
                        <td><strong>Tuesday</strong></td>
                        <td><strong>Wednesday</strong></td>
                      </tr>
                      <tr>
                        <td>
                          <p><strong>Lagos</strong></p>
                          <p><strong>Lagos Talks (91.3)</strong></p>
                          <p><strong>Inspiration FM (92.3)</strong></p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                          <p><strong>6.45am, 7.45am | 5.45pm</strong></p>
                          <p>
                            <strong>8.30am, 9.20am | 4.50pm, 5.50pm</strong>
                          </p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                          <p><strong>7.45am | 5.45pm</strong></p>
                          <p><strong>8.30am | 4.50pm</strong></p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                          <p><strong>7.15am | 5.45pm</strong></p>
                          <p><strong>8.30am | 4.50pm</strong></p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p><strong>Warri</strong></p>
                          <p><strong>Melody FM (88.6)</strong></p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                          <p><strong>7.25am | 4.45pm</strong></p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                          <p><strong>7.25am | 4.45pm</strong></p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                          <p><strong>7.25am | 4.45pm</strong></p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p><strong>Asaba</strong></p>
                          <p><strong>Trend FM (100.9)</strong></p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                          <p><strong>8.35am | 4.35pm</strong></p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                          <p><strong>8.35am | 3.35pm</strong></p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                          <p><strong>8.35am | 3.35pm</strong></p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p><strong>Enugu</strong></p>
                          <p><strong>Family Love (99.9)</strong></p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                          <p><strong>8.05am | 4.05pm</strong></p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                          <p><strong>8.05am | 4.05pm</strong></p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                          <p><strong>8.05am | 4.05pm</strong></p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p><strong>Onitsha</strong></p>
                          <p><strong>Radio Sapientia (95.3)</strong></p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                          <p><strong>8.45am | 4.45pm</strong></p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                          <p><strong>8.45am | 4.45pm</strong></p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                          <p><strong>8.45am | 4.45pm</strong></p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p><strong>Kaduna</strong></p>
                          <p><strong>Liberty FM (91.7)</strong></p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                          <p><strong>8.30am | 4.30pm</strong></p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                          <p><strong>8.30am | 4.30pm</strong></p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                          <p><strong>8.30am | 4.30pm</strong></p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p><strong>Port-Harcourt</strong></p>
                          <p><strong>Cool FM (95.9)</strong></p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                          <p><strong>6.30 - 7am | 5pm - 6pm</strong></p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                          <p><strong>6.30am - 7am | 5pm - 6pm</strong></p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                          <p><strong>6.30am - 7am | 5pm - 6pm</strong></p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p><strong>Abuja</strong></p>
                          <p><strong>Wazobia FM (99.5)</strong></p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                          <p><strong>6.25am-6.55am | 5pm - 6pm</strong></p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                          <p><strong>6.25am-6.55am | 5pm - 6pm</strong></p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                          <p><strong>6.25am-6.55am | 5pm - 6pm</strong></p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p><strong>Kano</strong></p>
                          <p><strong>Wazobia FM (95.1)</strong></p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                          <p><strong>6.25am-6.55am | 5pm - 6pm</strong></p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                          <p><strong>6.25am-6.55am | 5pm - 6pm</strong></p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                          <p><strong>6.25am-6.55am | 5pm - 6pm</strong></p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p><strong>Ibadan</strong></p>
                          <p><strong>Splash FM (100.5)</strong></p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                          <p>
                            <strong>7.40am - 7.45am | 5.30 - 5.35pm</strong>
                          </p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                          <p>
                            <strong>7.40am - 7.45am | 5.30 - 5.35pm</strong>
                          </p>
                        </td>
                        <td>
                          <p>&nbsp;</p>
                          <p>
                            <strong>7.40am - 7.45am | 5.30 - 5.35pm</strong>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </figure> -->
          <p>
            <img src="../../assets/img/alpha-page.jpg" alt="" class="display-image">
          </p>
          <!-- <figure class="table">
            <table>
              <tbody>
                <tr>
                  <td rowspan="2"><strong>Location/Radio Station</strong></td>
                  <td colspan="3">
                    <strong>Airing Days &amp; Times (am | pm)</strong>
                  </td>
                </tr>
                <tr>
                  <td><strong>Monday</strong></td>
                  <td><strong>Tuesday</strong></td>
                  <td><strong>Wednesday</strong></td>
                </tr>
                <tr>
                  <td>
                    <p><strong>Lagos</strong></p>
                    <p><strong>Lagos Talks (91.3)</strong></p>
                    <p><strong>Inspiration FM (92.3)</strong></p>
                  </td>
                  <td>
                    <p>&nbsp;</p>
                    <p><strong>7.00am | 7.00pm</strong></p>
                  </td>
                  <td>
                    <p>&nbsp;</p>
                    <p><strong>7.00am | 7.00pm</strong></p>
                  </td>
                  <td>
                    <p>&nbsp;</p>
                    <p><strong>7.00am | 7.00pm</strong></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><strong>Warri</strong></p>
                    <p><strong>Melody FM (88.6)</strong></p>
                  </td>
                  <td><strong>7.00am | 7.00pm</strong></td>
                  <td><strong>7.00am | 7.00pm</strong></td>
                  <td><strong>7.00am | 7.00pm</strong></td>
                </tr>
                <tr>
                  <td>
                    <p><strong>Asaba</strong></p>
                    <p><strong>Trend FM (100.9)</strong></p>
                  </td>
                  <td><strong>7.00am | 7.00pm</strong></td>
                  <td><strong>7.00am | 7.00pm</strong></td>
                  <td><strong>7.00am | 7.00pm</strong></td>
                </tr>
                <tr>
                  <td>
                    <p><strong>Enugu</strong></p>
                    <p><strong>Family Love (99.9)</strong></p>
                  </td>
                  <td><strong>7.00am | 7.00pm</strong></td>
                  <td><strong>7.00am | 7.00pm</strong></td>
                  <td><strong>7.00am | 7.00pm</strong></td>
                </tr>
                <tr>
                  <td>
                    <p><strong>Onitsha</strong></p>
                    <p><strong>Radio Sapientia (95.3)</strong></p>
                  </td>
                  <td><strong>7.00am | 7.00pm</strong></td>
                  <td><strong>7.00am | 7.00pm</strong></td>
                  <td><strong>7.00am | 7.00pm</strong></td>
                </tr>
                <tr>
                  <td>
                    <p><strong>Kaduna</strong></p>
                    <p><strong>Liberty FM (91.7)</strong></p>
                  </td>
                  <td><strong>7.00am | 7.00pm</strong></td>
                  <td><strong>7.00am | 7.00pm</strong></td>
                  <td><strong>7.00am | 7.00pm</strong></td>
                </tr>
                <tr>
                  <td>
                    <p><strong>Port-Harcourt</strong></p>
                    <p><strong>Cool FM (95.9)</strong></p>
                  </td>
                  <td><strong>7.00am | 7.00pm</strong></td>
                  <td><strong>7.00am | 7.00pm</strong></td>
                  <td><strong>7.00am | 7.00pm</strong></td>
                </tr>
                <tr>
                  <td>
                    <p><strong>Abuja</strong></p>
                    <p><strong>Wazobia FM (99.5)</strong></p>
                  </td>
                  <td><strong>7.00am | 7.00pm</strong></td>
                  <td><strong>7.00am | 7.00pm</strong></td>
                  <td><strong>7.00am | 7.00pm</strong></td>
                </tr>
                <tr>
                  <td>
                    <p><strong>Kano</strong></p>
                    <p><strong>Wazobia FM (95.1)</strong></p>
                  </td>
                  <td><strong>7.00am | 7.00pm</strong></td>
                  <td><strong>7.00am | 7.00pm</strong></td>
                  <td><strong>7.00am | 7.00pm</strong></td>
                </tr>
                <tr>
                  <td>
                    <p><strong>Ibadan</strong></p>
                    <p><strong>Splash FM (100.5)</strong></p>
                  </td>
                  <td><strong>7.00am | 7.00pm</strong></td>
                  <td><strong>7.00am | 7.00pm</strong></td>
                  <td><strong>7.00am | 7.00pm</strong></td>
                </tr>
              </tbody>
            </table>
          </figure> -->
          <p>
            Don’t forget to inform your family/friends, and remember to look out
            for social media materials showing these airing times and
            stations.&nbsp;
          </p>
          <p>
            There are loads of other exciting activities coming your way which
            would attract some juicy rewards for our customers.
          </p>
          <p>
            To be a part of this journey, follow our social media platforms to
            see how it all unfolds.
          </p>

          <p>
            Get a Plan i today that suits your needs, reach out to us on
            09048418896/01-280 5378. Alternatively, send an email to
            customerservice@nsiainsurance.com.
          </p>

          <p>NSIA…the true face of insurance.</p>
          <p>
            Facebook:&nbsp;<a href="https://www.facebook.com/596640117043430/"
              >NSIA Insurance Limited</a
            >
          </p>
          <p>
            Instagram:&nbsp;<a
              href="https://instagram.com/nsia_insure?utm_medium=copy_link"
              >nsia_insure</a
            >
          </p>
          <p>
            LinkedIn:&nbsp;<a
              href="https://www.linkedin.com/company/nsia-insurance-limited"
              >NSIA Insurance Limited</a
            >
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p><strong>Happy listening!</strong></p>
          <p>
            <a href="https://nsiainsurance.com/blog/116/blog-details"
              ><strong
                >Protection For Specific Risks In Your Profession -
                12/05/2021</strong
              ></a
            ><strong>&nbsp;</strong><br /><a
              href="https://nsiainsurance.com/blog/115/blog-details"
              ><strong
                >What Type Of Insurance Does Your Business Need? -
                &nbsp;07/05/2021</strong
              ></a
            >
          </p>
          <p>
            <a href="https://nsiainsurance.com/blog/114/blog-details"
              ><strong
                >Productive Ways To Pass Time Before You Clock Out -
                03/05/2021</strong
              ></a
            >
          </p>
          <p>
            <a href="https://nsiainsurance.com/blog/113/blog-details"
              ><strong
                >NSIA Insurance Launches Value Campaign - 28/04/2021</strong
              ></a
            >
          </p>
          <p>
            <a href="https://nsiainsurance.com/blog/112/blog-details"
              ><strong
                >How Much Sleep Do You Really Need? - 26/04/2021</strong
              ></a
            >
          </p>
        </div>
      </div>
    </div>
    <div class="col-lg-3 g-mb-30">
      <!-- Links -->
      <app-sidebar></app-sidebar>
    </div>
  </div>

  <!-- Sidebar -->
</div>
