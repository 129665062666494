
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;"><strong>WHY IS IT IMPORTANT TO INSURE YOUR BUSINESS?</strong></p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/insure_important.jpg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>A lot of business owners start off their businesses thinking that they do not need an insurance policy. This conception is not only wrong, it is also very risky. Business insurance is purchased by businesses to cover potential damages to property and protect themselves against law suits or contractual disputes. It is important to note that when running a business, your employees and customers are your responsibility because your business affects them in one way or another.  </p>
                 <p></p>

              </div>
           </div>

          <div class="row justify-content-between">


              <div class="col-md-12">
                <p>NSIA Business Insurance is simplified into four categories depending on the needs of your business; <strong>Transportation, Property, Accident</strong> and <strong>Liability</strong>.</p>
                <p></p>
                <p>Why purchase business insurance? </p>
                <p></p>
               <p><strong>1. It ensures that the business is in constant operation  </strong><br/>Business insurance protects your business from major losses that could have a significant impact on your day-to-day business operations. For example, a flood that causes damage to plants and machineries or even a fire outbreak that causes death or severe injuries to employees. Business insurance provides compensation for the injured on behalf of the insured.
               </p>

               <p></p>
                <p><strong>2. To cover legal fees </strong><br/>
                  Perhaps you are of the opinion that you do not need business insurance because you believe that you run your business in total compliance with operational laws and regulations, but issues may arise, leading to costly legal fees. In such a case, the lack of business insurance causes you to cover these fees out-of-pocket, resulting in a major setback for your business.
                </p>
              <p></p>
                 <p><strong>3.  Protect your employees</strong> <br/>
                  You cannot build your brand without your employees or even carry out the daily operations of your business without their help. This is why protecting them in the event of an accident is important. It is also a way of protecting yourself against any lawsuits or liability claims from a disgruntled employee.
                 </p>

                 <p></p>
                 <p><strong>4. It is required by law</strong> <br/>
                  According to Section 9(3) of the Pension Reform Act 2004, it is compulsory for every employer of labour, with five employees or more, to maintain a life insurance policy in favour of the employee. Failure to do this attracts a penalty of N250,000 or possible jail time.
                 </p>
                 <p></p>

                <p>Protect what you have built. Click on these links <a href="https://nsiainsurance.com/liability-insurance">https://nsiainsurance.com/liability-insurance</a>, <a href="https://nsiainsurance.com/property-insurance">https://nsiainsurance.com/property-insurance</a>, or call customer service on 09048418896 to get started today!</p>


              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>