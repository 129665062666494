import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-year-for-your-insurance',
  templateUrl: './new-year-for-your-insurance.component.html',
  styleUrls: ['./new-year-for-your-insurance.component.css']
})
export class NewYearForYourInsuranceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
