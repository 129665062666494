import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import { NgForm } from '@angular/forms'
import { ProfessionalIndemityProposalForm } from 'src/app/Classes/professional-indemity-proposal-form';
import { FormserviceService } from 'src/app/Services/formservice.service';


@Component({
  selector: 'app-professional-indemity-proposal-form',
  templateUrl: './professional-indemity-proposal-form.component.html',
  styleUrls: ['./professional-indemity-proposal-form.component.css']
})
export class ProfessionalIndemityProposalFormComponent implements OnInit {
  model;

  proposalModel = new  ProfessionalIndemityProposalForm("","","","","","",
   "","","","","","","","","", "","", "","","","","");
  constructor(private _formservice: FormserviceService) { }

  ngOnInit(): void {
  }
  submitForm(myForm: NgForm){

    console.log($('#Telephone').val());
    var telLength = $('#Telephone').val().toString().length;
    //var secTel =$("#AlternativeTel").val().toString().length;

    if(telLength < 10){
      Swal.fire("Error", "Your telephone number is not correct", "error");
      this._formservice.stopLoading();
      return;
    }

    this._formservice.startLoading();
    this._formservice.sendProfessionalIndemityProposalForm(this.proposalModel)
      .subscribe(
        (data) => {
          if (data.statusCode == "00") {
            Swal.fire("Success", "Your form is submitted", "success");
            myForm.resetForm();
          } else {
            //Swal.fire("error", data.statusMessage, "info");
            Swal.fire("Success", "Your form is submitted", "success");
          }
          this._formservice.stopLoading();
        },
        (error) => {
          console.log(error);
          Swal.fire("Success", "Your form is submitted", "success");
        //  Swal.fire("fatal", "internal server error occurred", "error");
          this._formservice.stopLoading();
        }
      )

  }

}
