<div class="container g-pt-100 g-pb-70">
  <div class="row">
    <div class="col-lg-12 g-mb-30">
      <section class="g-bg-gray-light-v5 g-py-20">
        <form novalidate (ngSubmit)="submitForm(beneficiaryForm)" #beneficiaryForm="ngForm" novalidate>
          <div class="container g-py-100 pad-top-50" style="margin-top:-50px">
            <div class="u-shadow-v19 g-brd-around g-brd-gray-light-v4  rounded mx-auto g-pa-30 g-pa-50--md">

              <div class="mb-6">
                <h2 class="mb-4 main-heading" style="text-align: center;">
                  Customer Service Week Trivia
                </h2>
              <p style="text-align: center;">Thank you for visiting our website, kindly answer the questions below for a chance to win a reward by ticking the Check Box of your preferred option (A,B,C or D).</p>

                <!-- General Information -->
                <section class="step-two" >
                  <div class="form-sub-title">
                    <h4>Questions</h4>
                  </div>
                  <div class="row">

                    <div class="col-lg-12">
                      <div class="form-group g-mb-20">
                        <label>
                          Question 1. Which of these Insurance policies is compulsory in Nigeria?
                        </label>
                        <br/>   <input  name="proposalModel.Question1"  type="radio"
                          [(ngModel)]="proposalModel.Question1" value="A. Education Endowment Insurance" > A.	Education Endowment Insurance
                       <br/> <input  name="proposalModel.Question1"  type="radio"
                         [(ngModel)]="proposalModel.Question1" value="B. Motor Insurance" > B.	Motor Insurance
                         <br/><input  name="proposalModel.Question1"  type="radio"
                         [(ngModel)]="proposalModel.Question1" value="C. Burglary Insurance" > C.	Burglary Insurance
                         <br/> <input  name="proposalModel.Question1"  type="radio"
                         [(ngModel)]="proposalModel.Question1" value="D. All Risk Insurance"  > D.	All Risk Insurance

                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="form-group g-mb-20">
                        <label >Question 2. What is the full meaning of NSIA?</label>
                        <br/>    <input  name="proposalModel.Question2"  type="radio"
                          [(ngModel)]="proposalModel.Question2" value="A. Nigerian Social Insurance Association" > A.	Nigerian Social Insurance Association
                          <br/> <input  name="proposalModel.Question2"  type="radio"
                         [(ngModel)]="proposalModel.Question2" value="B. Nouvelle Société Interafricaine d'Assurance" > B. Nouvelle Société Interafricaine d'Assurance
                         <br/>  <input  name="proposalModel.Question2"  type="radio"
                         [(ngModel)]="proposalModel.Question2" value="C. Nouvelle Société Internationalle d’Assurance" > C.	Nouvelle Société Internationalle d’Assurance
                         <br/> <input  name="proposalModel.Question2"  type="radio"
                        [(ngModel)]="proposalModel.Question2" value="D. Nigerian Security Insurance Association"  > D.	Nigerian Security Insurance Association

                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="form-group g-mb-20">
                        <label >Question 3. What is the Head Office address of NSIA Insurance in Nigeria?</label>
                        <br/>   <input  name="proposalModel.Question3"  type="radio"
                          [(ngModel)]="proposalModel.Question3" value="A. 18, Djibouti Crescent, Off Freetown Street, Wuse II, Abuja." > A.	18, Djibouti Crescent, Off Freetown Street, Wuse II, Abuja.
                          <br/> <input  name="proposalModel.Question3"  type="radio"
                         [(ngModel)]="proposalModel.Question3" value="B. 161, Awolowo Road, Ikeja, Lagos State." > B.	161, Awolowo Road, Ikeja, Lagos State.
                         <br/> <input  name="proposalModel.Question3"  type="radio"
                         [(ngModel)]="proposalModel.Question3" value="C. 3, Elsie Femi Pearse Street, Victoria Island, Lagos." > C.	3, Elsie Femi Pearse Street, Victoria Island, Lagos.
                         <br/> <input  name="proposalModel.Question3"  type="radio"
                         [(ngModel)]="proposalModel.Question3" value="D. 375, Civic Centre Road, J.B.S Plaza, Kano State."  > D.	375, Civic Centre Road, J.B.S Plaza, Kano State.

                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="form-group g-mb-20">
                        <label>
                          Question 4. What is the name of the Vice-Chairman of the Nigerian Insurers Association (NIA)?
                        </label>
                        <br/>  <input  name="proposalModel.Question4"  type="radio"
                          [(ngModel)]="proposalModel.Question4" value="A. Ebelechukwu B. Nnachukwu" > A.	Ebelechukwu B. Nnachukwu
                          <br/> <input  name="proposalModel.Question4"  type="radio"
                         [(ngModel)]="proposalModel.Question4" value="B. Eberechi B. Nwachukwu" > B.	Eberechi B. Nwachukwu
                         <br/> <input  name="proposalModel.Question4"  type="radio"
                         [(ngModel)]="proposalModel.Question4" value="C. Eberechi D. Nwachukwu" > C.	Eberechi D. Nwachukwu
                         <br/> <input  name="proposalModel.Question4"  type="radio"
                         [(ngModel)]="proposalModel.Question4" value="D. Ebelechukwu B. Nwachukwu"  > D.	Ebelechukwu B. Nwachukwu

                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group g-mb-20">
                        <label>

                        Question 5. What would you say contributes to the success of a company?

                        </label>
                        <br/>  <input  name="proposalModel.Question5"  type="radio"
                          [(ngModel)]="proposalModel.Question5" value="Employee Satisfaction" > A.	Employee Satisfaction
                          <br/> <input  name="proposalModel.Question5"  type="radio"
                         [(ngModel)]="proposalModel.Question5" value="Customer Satisfaction" > B.	Customer Satisfaction
                         <br/>  <input  name="proposalModel.Question5"  type="radio"
                         [(ngModel)]="proposalModel.Question5" value="C.	Board Satisfaction " > C.	Board Satisfaction
                         <br/>  <input  name="proposalModel.Question5"  type="radio"
                         [(ngModel)]="proposalModel.Question5" value="D.	All of the Above"  > D.	All of the Above

                      </div>
                    </div>

                  </div>
                </section>
                <!-- Medical Informations -->
                <section class="step-three">
                  <div class="">
                    <h4>Please also fill the details below and follow us on all our Social Media platforms for a chance to win a reward.</h4>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Full Name
                        </label>
                        <input id="Name" name="proposalModel.fullname" class="form-control form-control-md rounded-0" type="text"
                           [(ngModel)]="proposalModel.fullname" placeholder="Name">
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Phone Number
                        </label>
                        <input id="PhoneNumber" name="proposalModel.PhoneNumber" class="form-control form-control-md rounded-0" type="number"
                         [(ngModel)]="proposalModel.PhoneNumber"  placeholder="07035265975" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==11) return false; return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57));" >
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          Email Address
                        </label>
                        <input id="hEmailAddress" name="proposalModel.EmailAddress" class="form-control form-control-md rounded-0" type="email"
                          [(ngModel)]="proposalModel.EmailAddress" placeholder="EmailAddress" >
                      </div>
                    </div>
                    <div class="col-lg-6" >
                      <div class="form-group g-mb-20">
                        <label>
                          Do you have an Insurance Policy with NSIA Insurance?
                        </label>
                        <select name="Policy" id="proposalModel.Policy" class="form-control form-control-md rounded-0"
                        [(ngModel)]="proposalModel.Policy">
                             <option value="NIL">Select your option</option>
                             <option value="Yes">Yes</option>
                             <option value="No">No</option>
                       </select>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group g-mb-20">
                        <label>
                          NSIA Insurance Staff Code<br/>
                          (Get this Code from the NSIA Insurance staff that told you about this activity)
                        </label>
                        <input id="staffcode" name="proposalModel.staffcode" class="form-control form-control-md rounded-0" type="text"
                          [(ngModel)]="proposalModel.staffcode" >
                      </div>
                    </div>



                  </div>
                </section>

               <section class="step-seven" >
                  <div class="container">

                        <p style="font-size:10px; text-align: justify;">In compliance with NDPR requirements, by completing and submitting this form, you have given consent to NSIA Insurance to receive your data, including your bio data, to enable NSIA Insurance maintain effective communication, send promotional updates, contact you for research purposes and use the data in line with the policy terms.
                        </p>
                        <p style="font-size:10px; text-align: justify;">The data collection may be via hard copies or online forms, validated via JSON WEB TOKEN (JWT). The data will not be accessed by any third party without your consent and any breach of this policy can be addressed legally within the year the breach was reported.</p>

                        <div class="col-lg-4">
                          <div class="form-group g-mb-0">
                            <label style="visibility: hidden;">Frequency</label>
                            <button  type="submit" class="g-brd-main btn-block g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" > Submit
                            </button>
                          </div>

                        </div>

                      <!-- </div>

                    </div> -->
                 </div>

               </section>

              </div>
            </div>
          </div>
        </form>
      </section>
    </div>

    <!-- End Sidebar -->
  </div>
</div>
