import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BlogPost } from '../Classes/blogs-model';

@Injectable({
  providedIn: 'root'
})
export class BlogsService {
 defaultEndPoint: string = 'https://blogapi.nsiainsurance.com/api/';
  constructor(private httpClient:HttpClient) {

    // super();

  }

  createBlogPost(blog: BlogPost): Observable<any> {
    const baseApi = `${this.defaultEndPoint}Blog/PostBlog`;
    return this.httpClient.post<any>(baseApi, blog);
  }

  getBlogById(id: number): Observable<any> {
    const baseApi = `${this.defaultEndPoint}Blog/GetBlogDetail/${id}`;
    return this.httpClient.get<any>(baseApi);
  }

  getBlogList(searchQuery: string, pageNumber: number, pageSize: number): Observable<any> {
    pageNumber = pageNumber + 1;
    // const baseApi = `${this.defaultEndPoint}Blog/GetAllBlog?PageNumber=${pageNumber}&PageSize=${pageSize}&searchQuery=${searchQuery}`;
    const baseApi = `${this.defaultEndPoint}Blog/GetAllBlog?PageNumber=${pageNumber}&PageSize=${pageSize}`;
    return this.httpClient.get<any>(baseApi);
  }

}
