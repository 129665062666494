
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;">INSURANCE AS A MEANS TO PROTECT AGRICULTURE IN NIGERIA</p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/insurance_agric.jpg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>The importance of agriculture cannot be overemphasized as more than 80% of Nigerians rely on food from the local markets, which are highly sourced from the local farms. 	This shows that agriculture is one of the most profitable businesses in Nigeria, which is why farmers take great risks, targeting high returns on investment from Agriculture.
                 </p>
                 <p></p>


              </div>
           </div>


          <div class="row justify-content-between">


              <div class="col-md-12">
               <p><strong>Why agriculture should be protected?</strong>
               </p>

               <p></p>
                <p>It is clear that agriculture is important to the Nigerian economy and the people's day to day lives. This is why strategies should be put in place to protect the agricultural sector and ensure that many are not severely affected by eventualities. These eventualities may include windstorms, lighting, floods, etc., which may lead to loss of crops and livestock.</p>
                <p></p>

                 <p>Every year during the rainy season, a large number of Nigerian farmers suffer great loss as a result of floods. This affects the country as a whole as it causes a shortage in the production of food and cash crops, therefore, causing inflation in the purchase price of food items.
                 </p>
                 <p></p>

                 <p>This emphasizes the need for some sort of protection to cushion any losses that may occur from unforeseen circumstances. </p>

                <p></p>

                <p><strong>How can agriculture be protected?</strong></p>

                <p></p>
                <p>Agric Insurance is one of the best ways to protect farmers from losses they might incur throughout their course of investment. It is important to take up insurance policies to protect investments such as their crops, livestock and farm machineries; which is the equivalent of buying peace of mind. </p>

                <p>
                  NSIA Insurance offers Agriculture insurance that covers a wide range of possible losses and they are:

                </p>
                <ul>
                  <li>	Poultry farm insurance</li>
                  <li>	Livestock insurance</li>
                  <li>	Fish farm insurance</li>
                  <li>	Plantation fire insurance</li>
                  <li>	Crop insurance</li>
                  <li>	Farm properties and produce insurance</li>

                </ul>
                <p>You can check out our website at  <a href="https://nsiainsurance.com/agric-insurance">(https://nsiainsurance.com/agric-insurance).</a>to get started on your journey to peace of mind.</p>

              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>
