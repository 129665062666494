<section class="g-bg-gray-light-v5 g-py-50" style="margin-top:50px">
  <div class="container" >
    <div class="d-sm-flex text-center">
      <div class="align-self-center">
        <h2 class="h3 g-font-weight-300 w-100 g-mb-10 g-mb-0--md" ><p>NSIA Deferred Annuity Plan </p></h2>
      </div>


    </div>
  </div>
</section>
<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
  <div class="container">
    <ul class="d-flex justify-content-between u-list-inline">
      <li class="list-inline-item g-mr-15">
        <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
        <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
      </li>
      <li class="list-inline-item">
              <a class="u-link-v5 g-color-text g-pr-10" routerLink="/lifeinsurance">Life Insurance</a>
              <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
            </li>
            <li class="list-inline-item g-color-primary">
                  <span>NSIA Deferred Annuity Plan </span>
                </li>

      <li class="list-inline-item ml-auto">
        <a class="g-brd-main g-brd-primary--hover g-bg-main g-bg-primary--hover text-white u-tags-v1 g-rounded-50 g-py-4 g-px-15" routerLink="/getquote">Get quote</a>
      </li>
    </ul>
  </div>
</section>
<div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
      <div class="row">
        <div class="col-lg-9 g-mb-30">
              <div class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
                      <h4><strong><p>NSIA Deferred Annuity Plan </p></strong> </h4>

            <p>
              NSIA Deferred Annuity Plan provides a sustainable vehicle through which policyholders can save towards a <strong>regular income in retirement.</strong> It also serves as an avenue to convert lump sums into regular income at retirement (i.e. pension)
            </p>
            <p>
              The product is suitable for: <br/>
        <ul>
          <li>
            Those who want to make regular contributions for a certain period in return for a future stream of income (pension).
          </li>
          <li>Those who have a lump sum available and want to use it to secure regular income (pension) at old age.</li>
          <li>
            Employees who want to argument their Retirement Saving Account Benefits   under the Pension Reform Act 2004.
          </li>
        </ul>

       <p><strong>Key Features</strong></p>
       <p></p>
       <ul>
              <li><p>Policyholder pays regular premium or a lump sum for a period for a regular pension (annuity) from NSIA Life at a later date.</p></li>
              <li><p>Interest is accumulated on the contributed premium  during the contribution phase before the annuity payment phase </p></li>
              <li><p>Policyholder decides on a target income (annuity) in retirement. </p></li>
              <li><p>Frequency of premium payments are monthly, quarterly, bi-annually and annually</p></li>
              <li><p>The policy provides a compulsory funeral benefit cover of N500,000.00 at a reduced annual premium rate.</p></li>
              <li><p>Policyholders can choose income to be payable for their lifetime at retirement subject to nil , five (5), or ten (10) years guaranteed period.  </p></li>
              <li><p>Amount contributed can be withdrawn before the annuity phase of payment.</p></li>
              <li><p>The minimum and maximum age at entry are ages 25 and 64.</p></li>
              <li><p>Optional retirement ages of 50, 55, 60 or 65 are available</p></li>
           </ul>
           <p></p>
           <p><strong>Death Benefit</strong></p>
            <p>The following benefits are paid on death:</p>
            <ul>
                <li><p>Refund of all premiums paid plus interest if death occurs before the retirement date</p></li>
                <li><p>The sum of N500,000.00 as the funeral benefit </p></li>
                <li><p>If death occurs in retirement before the guaranteed period of say 5 or 10 years is over,  the difference between total income payable for the guaranteed period and total income paid as at the time of death would be paid to policyholder’s dependents

                </p></li>
            </ul>

            <p></p>
            <p><strong>Retirement Benefit</strong></p>
            <p></p>
           <ul>
             <li>Payment of regular income (annuity) to the policyholder from retirement age till death occurs</li>
             <li>
              Payment is guaranteed for a number of years depending on the policyholder’s option (for a period of 5 or 10 years)
             </li>
           </ul>
            <p></p>
            <p><strong>Discontinuation</strong></p>
            <p></p>
            <ul>
              <li>On discontinuation of a deferred annuity plan within three years after commencement, the premiums paid will be refunded and subject   to   termination charges.  </li>
              <li>No   termination charges will   be   imposed   if discontinuation takes place after three (3) years.</li>
            </ul>
            <p></p>

                      </div>
                      </div>


        <!-- Sidebar -->
        <div class="col-lg-3 g-mb-30">
          <!-- Links -->
          <app-sidebar></app-sidebar>
          <!-- End Fast Facts -->
        </div>
        <!-- End Sidebar -->
      </div>
    </div>






