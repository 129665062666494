
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;">CLAIMS PROCESS MADE EASIER</p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/claim_process.jpg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>Losses are stressful, but they shouldn’t be confusing. At NSIA Insurance, the goal of our claims unit is to promptly pay all properly documented claims covered by the policy within the set time limit.
                 </p>
                 <p></p>
                 <p>When it is time to make an insurance claim, the more prepared you are, the more smoothly it will go. You can prepare for the process by gathering relevant documents (receipts, original invoices, proof of ownership, etc.), photos and accounts of the event or damage. It is important that we are notified immediately a loss occurs as we may need to carry out an on-the-spot assessment depending on the nature and size of the claim. There are various channels through which you can report your claim for easy processing:


                 </p>
                 <p></p>


              </div>
           </div>


          <div class="row justify-content-between">


              <div class="col-md-12">
               <p><strong>1.	Phone call</strong> to the following numbers: 09061237623,08188639774, 0128053789 <br/>
                <strong>2.	Email</strong> to claimsunit_ng@nsiainsurance.com <br/>
               <strong> 3.	Online</strong> via our website (https://nsiainsurance.com/reportclaim) or customer portal (https://portal.nsiainsurance.com/Account/LifeLogin).

               </p>
               <p>The claims process involves the following:</p>
                <p><strong>Documentation: </strong>Once we receive notification of your claim, our team would contact you within one business day for further information. You are responsible for providing documentation and evidence to prove the nature and amount of loss suffered. Kindly note that delays in providing the required documentation may lead to delays in the claims process.</p>
                <p></p>
                <p><strong>Claim assessment:</strong> Depending on the type and size of your claim, we may appoint an adjuster to assess your loss and verify the circumstances surrounding the loss. Once the investigation is complete, the adjuster will go through your policy to determine what is and isn’t covered, and inform you of any applicable deductibles that may apply.
                 </p>
                <p></p>
                 <p><strong>Claim settlement:</strong> An offer for settlement is communicated via a discharge voucher to be signed by the insured or their representatives. On receipt of the signed discharge voucher, we would settle the claim within 72 hours.</p>

                <p></p>

<p>Every claim is different, and although the claims process can vary slightly according to the situation, we advise that you refer to the policy document to familiarize yourself with the terms and conditions of the policy so as to ensure a seamless process.</p>

<p></p>

<p>For more information on our products, please visit <a href="https://nsiainsurance.com/">https://nsiainsurance.com</a> or send an email to <a href="mailto:customerservice@nsiainsurance.com">customerservice@nsiainsurance.com</a>. </p>


              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>
