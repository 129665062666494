
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;"><strong>7 INTERESTING FACTS ABOUT INSURANCE</strong></p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/friday_october_30.jpg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>Usually when you bring up the word ‘insurance’ to anyone but a broker or agent, you might get a yawn or two. Sure, it’s not the most exciting subject on the planet, but it’s necessary in many walks of life! Insurance policies help to protect us when we need a safety net underneath the things that matter to us the most.</p>
                 <p></p>
                 <p>
                  The world of insurance is far more interesting than you probably think. Here's a look at 7 fun facts about insurance:
                 </p>

                 <p></p>

              </div>
           </div>

          <div class="row justify-content-between">


              <div class="col-md-12">
                <p>1.	The word ‘insurance’ comes from the old-world French term ‘ensurer’, meaning, engaged to marry!
                </p>

              <p></p>
                 <p>2.	Insurance has been a facet of society since the 14th Century. The first insurance contract was signed back in 1347 – and from there, people often sought to insure their estates in line with financial backing.
                 </p>

                 <p></p>
                 <p>3.	There is such a thing as body part insurance. It is not unheard of for famous people such as actors and singers to insure their bodies. Ben Turpin happened to be the first – though this was as a result of the star not wanting to lose his crossed eyes, which was his signature look! Turpin took out a $25,000 policy to protect them in 1920.
                 </p> <p></p>
                 <p>4.	People used to take out insurance policies against dying of laughter when comedy movies first started heading to the cinema.

                </p> <p></p>
                 <p>5.	Steven Spielberg has one of the most expensive life insurance policy in the world at $1.2 billion.
                <p></p>
                 <p>6.	In 2003, Pepsi hosted a lottery with a grand prize of $1 billion and paid $10 million in insurance in case anyone actually won the grand prize.</p>
                <p></p>
                <p>7.	Believe it or not, the very concept of a fire department has ties to insurance. That’s because they were originally set up purely to put out the fires of specific households and policyholders!</p>
                <p></p>

                <p></p>
                <p>Give us a call today on 09048418896 or visit <a href="https://nsiainsurance.com">www.nsiainsurance.com</a> let us help you protect what’s most important to you and keep you feeling secure in the face of uncertain situations.</p>
              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>