export class FidelityProposalForm {
  constructor(

    public NameOfProposal:string,
    public AddressOfProposal:string,
    public TradeOrBusiness:string,
    public EmailAddress:string,
    public Telephone:string,
    public StartDate: Date,
    public EndDate: Date,
    public covertype:string,
    public CategoryOfEmployee:string,
    public paidemployees:string,
    public negotiableinstruments:string,
    public purchaseandsaleofgoods:string,
    public employeeduties : string,
    public internalauditprocedure: string,
    public externalauditprocedure: string,
    public otherinformationprocedures: string,
    public writtenreference: string,
    public writtenreference_No: string,
    public independent_system: string,
    public loss_through_employee_fraud:string,
    public numberofoccurrence:string,
    public maximuamount:string,
    public totalamountofdirectlosses:string,
    public limitanyoneloss:string,
    public limitperperson:string,
    public aggregateamount: string,
    public AltTelephone:string

  ) {

  }
}
