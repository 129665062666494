export class UserClass {
  constructor(
    public fullname: string,
    public email: string,
    public phoneno: string,
    public message: string
  ) {

  }
}
