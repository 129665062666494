import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-claim-process-made-easier',
  templateUrl: './claim-process-made-easier.component.html',
  styleUrls: ['./claim-process-made-easier.component.css']
})
export class ClaimProcessMadeEasierComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
