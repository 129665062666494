import { Component, Input, OnInit, OnChanges } from '@angular/core';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.css']
})
export class TabComponent implements OnChanges {

  constructor() {
    console.log(this.tabTitle);
    console.log(this.active);
   }
  @Input('tabTitle') tabTitle: string;
  @Input() active = false;
  onchangeNew() {
    this.active = !this.active;
    console.log(this.active)
  }

  ngOnChanges(changes): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    console.log(changes);

  }

}
