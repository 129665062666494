<header>
   <section class="laptopView">
      <div class="tabCont">
         <a class="navbar-brand g-pl-15" routerLink="/">
            <img loading="lazy" class="g-width-150"
               src="https://res.cloudinary.com/heazyconcept/image/upload/v1613638695/Nsia/NSIA-new-website-logo_2_lrney5.png"
               alt="Logo">
         </a>
         <div class="tabs">
            <a href="{{baseUrl}}/?loaded" id="home">Home</a>
            <div class="dropdown" id="product">
               <button class="dropbtn" id="product">Products <h1>▼</h1></button>
               <div class="dropdown-content">
                  <a routerLink="/home-insurance-company-in-lagos-nigeria">Personal Insurance</a>
                  <a routerLink="/house-holder-insurance">House Holder Insurance Form</a>
                  <div class="dropdownB" id="busI">
                     <button class="dropbtn" id="busI">Business Insurance <h1>></h1></button>
                     <div class="dropdown-contentb">
                        <a routerLink="/transportation-insurance-company-in-lagos-nigeria">Transportation</a>
                        <a routerLink="/property-insurance-company-in-lagos-nigeria">Property Insurance</a>
                        <a routerLink="/accident-insurance-company-in-lagos-nigeria">Accident Insurance</a><a
                           routerLink="/liability-insurance-company-in-lagos-nigeria">Lability Insurance Insurance</a>
                     </div>
                  </div>
                  <a routerLink="/life-insurance-company-in-lagos-nigeria">Life Assurance</a>
                  <a routerLink="/energy-insurance-company-in-lagos-nigeria">Energy & Special risks Insurance</a>
                  <a routerLink="/health-insurance-company-in-lagos-nigeria">Health Insurance</a>
                  <a routerLink="/agric-insurance-company-in-lagos-nigeria">Agric Insurance</a>
                  <a routerLink="/travel-insurance-company-in-lagos-nigeria">Travel Insurance</a>
               </div>
            </div>
            <a routerLink="/claims" id="claimP">Claims Process</a>
            <div class="dropdown" id="about">
               <button class="dropbtn" id="about">About Us <h1>▼</h1></button>
               <div class="dropdown-content">
                  <a routerLink="/aboutus">About NSIA</a>
                  <a routerLink="/board">Board Members</a>
                  <a routerLink="/management">Management Team</a>
                  <a routerLink="/financialreport">Financial Report</a>
                  <a routerLink="/certification">Certifications</a>
                  <a routerLink="/award">Awards</a>
               </div>
            </div>
            <a (click)="goToCareer()" id="caree">Careers</a>
            <div class="dropdown" id="contact">
               <button class="dropbtn" id="contact">Contact <h1>▼</h1></button>
               <div class="dropdown-content">
                  <a routerLink="/contactus">Offices</a>
                  <a routerLink="/contact-form">Enquires</a>
                  <a routerLink="/complaint-form">Complaints</a>
               </div>
            </div>
            <a routerLink="/events" id="even" style="color: #efb225 ;">Events</a>
            <a routerLink="/blogs/list" id="blog" style="color: #efb225 ;">Blog</a>
            <a (click)="open()" id="logi">LOGIN</a>
         </div>
      </div>
   </section>
   <section class="mobileView">
      <div class="tabC">
         <a class="navbar-brand g-pl-15" routerLink="/">
            <img loading="lazy" class="g-width-150 logoo"
               src="https://res.cloudinary.com/heazyconcept/image/upload/v1613638695/Nsia/NSIA-new-website-logo_2_lrney5.png"
               alt="Logo">
         </a>
         <button (click)="toggleMenu()"> <fa-icon class="faIco" [icon]=" faBars"></fa-icon> </button>
      </div>
      <div class="menu">
         <div class="menT">
            <a class="navbar-brand g-pl-15" routerLink="/">
               <img loading="lazy" class="g-width-150 logoo"
                  src="https://res.cloudinary.com/heazyconcept/image/upload/v1613638695/Nsia/NSIA-new-website-logo_2_lrney5.png"
                  alt="Logo">
            </a>
            <button (click)="toggleMenu()">
               <fa-icon class="faIco" [icon]=" faTimes"></fa-icon>
            </button>
         </div>
         <ul class="menD">
            <li><a href="{{baseUrl}}/?loaded" id="home">Home</a></li>
            <li><button (click)="toggleMenuA()">Products <h1>▼</h1></button></li>
            <ul class="detail prD">

               <li><a routerLink="/home-insurance-company-in-lagos-nigeria">Personal Insurance</a></li>
               <li><a routerLink="/house-holder-insurance">House Holder Insurance Form</a></li>
               <li><button (click)="toggleMenuD()">Business Insurance <h1>></h1></button></li>
               <ul class="detail fBus">
                  <li><a routerLink="/transportation-insurance-company-in-lagos-nigeria">Transportation</a></li>
                  <li><a routerLink="/property-insurance-company-in-lagos-nigeria">Property Insurance</a></li>
                  <li><a routerLink="/accident-insurance-company-in-lagos-nigeria">Accident Insurance</a></li>
                  <li><a routerLink="/liability-insurance-company-in-lagos-nigeria">Lability Insurance Insurance</a>
                  </li>
               </ul>
               <li><a routerLink="/life-insurance-company-in-lagos-nigeria">Life Assurance</a></li>
               <li><a routerLink="/energy-insurance-company-in-lagos-nigeria">Energy & Special risks Insurance</a>
               </li>
               <li><a routerLink="/health-insurance-company-in-lagos-nigeria">Health Insurance</a></li>
               <li><a routerLink="/agric-insurance-company-in-lagos-nigeria">Agric Insurance</a></li>
               <li><a routerLink="/travel-insurance-company-in-lagos-nigeria">Travel Insurance</a></li>

            </ul>
            <li><a routerLink="/claims" id="claimP">Claims Process</a></li>
            <li><button (click)="toggleMenuB()">About Us <h1>▼</h1></button></li>
            <ul class="detail aBou">
               <li><a routerLink="/aboutus">About NSIA</a></li>
               <li><a routerLink="/board">Board Members</a></li>
               <li><a routerLink="/management">Management Team</a></li>
               <li><a routerLink="/financialreport">Financial Report</a></li>
               <li><a routerLink="/certification">Certifications</a></li>
               <li><a routerLink="/award">Awards</a></li>
            </ul>
            <li><a (click)="goToCareer()" id="caree">Careers</a></li>
            <li><button (click)="toggleMenuC()">Contact <h1>▼</h1></button></li>
            <ul class="detail coNT">
               <li> <a routerLink="/contactus">Offices</a></li>
               <li> <a routerLink="/contact-form">Enquires</a></li>
               <li><a routerLink="/complaint-form">Complaints</a></li>
            </ul>
            <li><a routerLink="/events" id="even" style="color: #efb225 ;">Events</a></li>
            <li><a routerLink="/blogs/list" id="blog" style="color: #efb225 ;">Blog</a></li>
            <li style="background-color: #FFFFFF;"><a (click)="open()" id="logi">LOGIN</a></li>
         </ul>
      </div>
   </section>
</header>