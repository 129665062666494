
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;">4 WAYS TO RETAIN MORE OF EVERY BOOK YOU READ</p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/dec/Book.jpeg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>A good book can give you a new way to interpret your past experiences. Gaining knowledge is not the only reason to read. Reading for pleasure or entertainment can be a wonderful use of time, but this article is about reading to learn. Here are some of the best reading comprehension strategies.
                 </p>


                  <p></p>


              </div>
           </div>


          <div class="row justify-content-between">


              <div class="col-md-12">

                <p></p>
            <p><strong>1. Quit More Books</strong><br/>
            </p>
               <p></p>
              <p>
                It doesn't take long to figure out if something is worth reading. Skilled writing and high-quality ideas stick out. As a result, most people should probably start more books than they do. This doesn't mean you need to read each book page-by-page. You can skim the table of contents, chapter titles, and subheadings. Pick an interesting section and dive in for a few pages. Maybe flip through the book and glance at any bolded points or tables. In ten minutes, you'll have a reasonable idea of how good it is.

                <br/>Start more books. Quit most of them. Read the great ones twice.
              </p>
              <p></p>
              <p><strong>2. Choose Books You Can Use Instantly</strong><br/>
              </p>
                <p></p>
                <p>One way to improve reading comprehension is to choose books that contain knowledge you can immediately apply. Putting the ideas you read into action is one of the best ways to secure them in your mind. Practice is a very effective form of learning. Choosing a book that has implications to your everyday life also provides a strong incentive to pay attention and remember the material. That’s particularly true when something important hangs in the balance. If you’re starting a business, for example, then you have a lot of motivation to get everything you can out of the sales book you’re reading.</p>
                <p></p>
                <p><strong>3. Create Searchable Notes</strong></p>
                <p></p>
                <p>Keep notes on what you read. You can do this however you like. It doesn't need to be a big production or a complicated system. Just do something to emphasize the important points and passages.</p>
                <p></p>
                <p>You can do this in different ways depending on the format of the book you are reading. Highlight passages when reading on Kindle, type out interesting quotes as you listen to audiobooks, dog-ear pages and transcribe notes when reading a print book.</p>
              <p></p>
              <p>But here's the real key: store your notes in a searchable format.</p>
              <p></p>
              <p><strong>4. Read It Twice</strong></p>
              <p></p>
              <p>Revisiting great books is helpful because the problems you deal with change over time. Sure, when you read a book twice maybe you'll catch some stuff you missed the first time around, but it's more likely that new passages and ideas will be relevant to you. It's only natural for different sentences to leap out at you depending on the point you are at in life. You read the same book, but you never read it the same way.</p>


              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>