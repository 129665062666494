
   <section class="g-brd-bottom g-brd-gray-light-v4 g-py-30 breadcrumbs">
    <div class="container">
       <ul class="d-flex justify-content-between u-list-inline">
          <li class="list-inline-item g-mr-15">
             <a class="u-link-v5 g-color-text g-pr-10" routerLink="/">Home</a>
             <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
          </li>
          <li class="list-inline-item g-color-primary">
             <span>Blog</span>
          </li>
          <li class="list-inline-item ml-auto">
             <!-- <a class="u-link-v5 g-brd-around g-brd-gray-light-v4 g-brd-primary--hover g-color-text g-color-primary--hover g-font-weight-500 rounded g-px-30 g-py-10" routerLink="/getquote">Get quote</a> -->
          </li>
       </ul>
    </div>
 </section>

 <div class="container g-pt-100 g-pb-70" style="margin-top:-50px">
  <div class="row">
     <div class="col-lg-12 g-mb-30">
        <div  class="u-shadow-v1-5 g-line-height-2 g-pa-40 g-mb-30" role="alert">
           <h3 class="mb-0">
              <strong>
                 <p style="text-align:center;"><strong>WHY IS IT IMPORTANT TO INSURE YOUR BUSINESS?</strong></p>
              </strong>
           </h3>
           <br/>

           <div class="row justify-content-between">
              <!-- About Image -->

              <div class="col-md-3 col-lg-4">
                 <img class="img-fluid" src="assets/img/INSURANCE_POLICY.jpg" alt="Image Description">
              </div>
              <!-- End About Image -->
              <div class="col-md-8 col-lg-8">
                 <!-- About Info -->
                 <p>I was driving on the highway, in terrible traffic when a motorbike from nowhere rode carelessly past me and broke my side mirror in the process. The rider just said, “Oh, sorry”. Before I could come down from my car to confront him, he had driven off. I was left with no choice than to move my car off the road to assess the damage. At that point, the only consolation that came to mind was “Thank God! I have insurance”.  When I got home, I took a picture of the car and reported the claim the next day. </p>
                 <p></p>

              </div>
           </div>

          <div class="row justify-content-between">


              <div class="col-md-12">
                <p>I filled out a claim form where I narrated the incident and how the bike man escaped. I got a repair estimate from an authorised garage, and then I scanned the completed claim form, estimate of repair and picture of my car, and sent it to my insurer.

               </p>
                <p></p>
                <p>Within 24hours, I got my discharge voucher with value lower than the cost of repair. Surprised, I called my account handler to draw her attention to the error committed but she categorically told me that it wasn’t an error. ‘So, why the reduction?’ I asked.  She then explained that <strong>EXCESS</strong> was deducted from the benefit. </p>
                <p></p>

                <p>Many policyholders struggle to understand what an insurance excess is. They don’t understand why they have to pay this, especially if they were not to blame for the accident resulting in the insurance claim! Hopefully this article will provide you with some clarity.
               </p>
              <p></p>
                 <p><strong>What is excess?</strong> <br/>
                  An excess is a contribution you are required to pay towards a claim you make on your car insurance policy. It is an agreed amount of money that you (the policyholder) are liable to pay in the event of a claim being settled. It’s a way of you accepting a small portion of the risk yourself. Most times, the excess is used to reduce the number of claims being processed by the insurer and to consciously encourage accident-free driving by the insured.
                 </p>
                 <p></p>
                <p>Many policies include an excess but not every type of policy has the same kind and level of excess. Some excesses will apply whenever you make a claim, others will depend on the circumstances of your claim. </p>
                   <p></p>
                  <p>However, there is a way to soften the blow that the high excess inflicts on your finances when you have to make a claim by buying an excess protection policy, sometimes called <strong>Excess Buy Back</strong>, that reimburses you the paid excess.</p>
               <p></p>
                <p>For more information, please call customer service on 09048418896.</p>


              </div>

           </div>
        </div>
        <!-- End About Info -->
     </div>

  </div>
</div>