

export class SendClaimsModel  {
  policyNo: string;
  productType: string;
  claimDescriptions: string;
  uploadPictures :FileUplaod []
}

export class FileUplaod {
  fileName: string;
    fileType: string;
    fileBinary: string;
    extensions : string;
}
